import React, { FC } from 'react';
import { Box } from '@mui/material';

import { Loaders } from 'components';

const ConnectLoader: FC = () => (
  <Box height="100vh">
    <Loaders.Standard />
  </Box>
);

export default ConnectLoader;
