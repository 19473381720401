import React, { FC } from 'react';
import { TooltipProps } from 'recharts';
import { Typography } from '@mui/material';
import moment from 'moment';

import { PaddedPaper } from 'components';
import { formatStat } from 'helpers';

const Tooltip: FC<TooltipProps<number, string>> = props => {
  const { active, payload, label } = props;

  if (!active || !payload) {
    return null;
  }

  const date = moment(label).format('ll');

  const getDate = () => {
    const { dataKey } = payload[0];
    if (
      dataKey === 'active_groups' ||
      dataKey === 'active_users' ||
      dataKey === 'active_groups_count' ||
      dataKey === 'daily_active_users'
    ) {
      const startDate = moment(label).subtract(1, 'months').format('ll');

      return `${startDate} - ${date}`;
    }

    return date;
  };
  return (
    <PaddedPaper>
      <Typography color="textSecondary">{getDate()}</Typography>
      {payload.map(({ name, value }) => (
        <Typography key={name} variant="body2">
          {formatStat(value, '')} {name}
        </Typography>
      ))}
    </PaddedPaper>
  );
};

export default Tooltip;
