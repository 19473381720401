import React, { CSSProperties, FC } from 'react';
import { Box, ButtonProps, Unstable_Grid2 as Grid } from '@mui/material';
import theme from 'theme';

const styles = {
  pulse: {
    position: 'relative',
    left: '-9999px',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    boxShadow: '9999px 0 0 -10px',
    animation: 'dot-Standard 1.5s infinite linear',
    animationDelay: '0.25s',

    '&::before, &::after': {
      content: "''",
      display: 'inline-block',
      position: 'absolute',
      top: '0',
      width: '10px',
      height: '10px',
      borderRadius: '5px',
    },
    '&::before': {
      boxShadow: '9980px 0 0 -10px',
      animation: 'dot-Standard-before 1.5s infinite linear',
      animationDelay: '0s',
    },
    '&::after': {
      boxShadow: '10010px 0 0 -10px',
      animation: 'dot-Standard-after 1.5s infinite linear',
      animationDelay: '0.5s',
    },

    '@keyframes dot-Standard-before': {
      '0%': {
        boxShadow: '9980px 0 0 -10px',
      },
      '30%': {
        boxShadow: '9980px 0 0 2px',
      },
      '60%, 100%': {
        boxShadow: '9980px 0 0 -10px',
      },
    },
    '@keyframes dot-Standard': {
      '0%': {
        boxShadow: '9999px 0 0 -10px',
      },
      '30%': {
        boxShadow: '9999px 0 0 2px',
      },
      '60%, 100%': {
        boxShadow: '9999px 0 0 -10px',
      },
    },
    '@keyframes dot-Standard-after': {
      '0%': {
        boxShadow: '10010px 0 0 -10px',
      },
      '30%': {
        boxShadow: '10010px 0 0 2px',
      },
      '60%, 100%': {
        boxShadow: '10010px 0 0 -10px',
      },
    },
  },
} as const;

interface Props {
  color?: 'white' | ButtonProps['color'];
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
}

const Standard: FC<Props> = ({ color, height, width }) => {
  function getColor() {
    switch (color) {
      case 'white':
        return theme.palette.common.white;
      case 'primary':
        return theme.palette.primary.main;
      case 'secondary':
        return theme.palette.secondary.main;
      case 'error':
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  }

  const backgroundColor = getColor();
  return (
    <Grid container alignItems="center" justifyContent="center" sx={{ width, height }}>
      <Box component="div" sx={{ ...styles.pulse, backgroundColor, color: backgroundColor }} />
    </Grid>
  );
};

Standard.defaultProps = {
  color: 'primary',
  height: 'inherit',
  width: 'inherit',
};

export default Standard;
