import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Button, Dialog, DialogActions } from '@mui/material';
import moment from 'moment';

import { ShareType } from 'api';
import { Loaders } from 'components';
import * as DatePickers from 'components/DatePickers';
import theme from 'theme';

import { Share } from '../types';

const styles = {
  button: {
    borderRadius: 3,
  },
  actions: {
    margin: theme.spacing(2, 0, 0, 0),
  },
} as const;

interface Props {
  open: boolean;
  onClose: () => void;
}

const ScheduleShare: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const { values, setFieldValue, submitForm, setSubmitting, isSubmitting } =
    useFormikContext<Share>();

  const time = moment().add('1', 'hour').unix();
  const options: DatePickers.Options = {
    minDate: moment().format(),
    enableTime: true,
    title: t('sharing:scheduleYourShare'),
    subtitle: t('sharing:chooseTime'),
  };

  async function handleScheduleShare(time: number) {
    values.items.forEach((item, index) => {
      setFieldValue(`items[${index}].share_type`, ShareType.Scheduled);
      setFieldValue(`items[${index}].share_at`, time);
    });

    setSubmitting(true);
    await submitForm();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DatePickers.DateTime
        value={time}
        onClose={onClose}
        onSave={handleScheduleShare}
        {...options}
      >
        <DialogActions sx={styles.actions}>
          <Button onClick={onClose} size="large">
            {t('common:cancel')}
          </Button>
          <Button
            sx={styles.button}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <Loaders.Standard height={26} /> : t('sharing:scheduleShare')}
          </Button>
        </DialogActions>
      </DatePickers.DateTime>
    </Dialog>
  );
};

export default ScheduleShare;
