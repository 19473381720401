import React, { FC, MouseEvent, ReactNode } from 'react';
import { darken } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import i18n from 'config/i18n';
import palette from 'theme/palette';

const defaultProps = {
  message: i18n.t('components:alertMessage'),
  cancel: i18n.t('common:cancel'),
  confirm: i18n.t('common:confirm'),
};

interface IProps {
  open: boolean;
  onConfirm: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancel: () => void;
  message?: string | ReactNode;
  cancel?: string | ReactNode;
  confirm?: string | ReactNode;
  warning?: boolean;
  destructive?: boolean;
}

const styles = {
  paper: {
    padding: 2,
  },
  warningContent: {
    border: `10px solid red`,
  },
  destructive: {
    backgroundColor: palette.red,
    '&:hover': {
      backgroundColor: darken(palette.red, 0.1),
    },
  },
  message: {
    whiteSpace: 'pre-line',
  },
  actions: {
    marginBottom: 0,
  },
} as const;

const Alert: FC<IProps> = ({
  open,
  onConfirm,
  onCancel,
  message,
  cancel,
  confirm,
  warning,
  destructive,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog sx={{ paper: styles.paper }} open={open} maxWidth="sm" onClose={onCancel}>
      <DialogContent sx={warning ? styles.warningContent : {}}>
        <Typography paragraph align="center" variant="subtitle2" sx={styles.message}>
          {message}
        </Typography>
        {warning && (
          <Typography paragraph align="center" variant="subtitle2">
            {t('admin:warningMessage')}
          </Typography>
        )}
        <DialogActions sx={{ justifyContent: 'center', marginBottom: 0 }} disableSpacing>
          <Grid xs={12} container justifyContent="center" alignItems="stretch" spacing={2}>
            <Grid xs={6}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                size="large"
                onClick={onCancel}
              >
                {cancel}
              </Button>
            </Grid>
            <Grid xs={6}>
              <Button
                fullWidth
                color={destructive ? 'error' : 'primary'}
                variant="contained"
                size="large"
                onClick={onConfirm}
                autoFocus
                sx={destructive ? styles.destructive : {}}
              >
                {confirm}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

Alert.defaultProps = defaultProps;

export default Alert;
