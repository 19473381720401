import React, { FC } from 'react';
import { Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { CapabilityFragment } from 'api';

import Loader from '../Client/Loader';
import { updateCapability } from '../../Links';

interface IProps {
  capabilities: CapabilityFragment[];
  isLoading: boolean;
}

const CapabilityTable: FC<IProps> = ({ isLoading, capabilities }) => {
  function renderHeadCell(text: string) {
    return (
      <Typography title={text} noWrap variant="body2">
        {text}
      </Typography>
    );
  }

  function renderRow(capability: CapabilityFragment) {
    return (
      <TableRow key={capability.capability_id}>
        <TableCell>
          <Link underline={'always'} component={updateCapability(capability.capability_id)}>
            {capability.description}
          </Link>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{renderHeadCell('Description')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading && <Loader rows={4} />}
        {capabilities.map(renderRow)}
      </TableBody>
    </Table>
  );
};

export default CapabilityTable;
