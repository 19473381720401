import React, { FC, Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CardContent, LinearProgress, Typography } from '@mui/material';
import { noop } from 'lodash';

import { S3VideoObject } from 'api';
import { Video } from 'components';
import { useContent } from 'hooks';

import { getVideo } from '../helpers';

interface Props {
  video: S3VideoObject;
  onClickReaction?: () => void;
  autoPlay?: boolean;
}

const VideoWidget: FC<Props> = ({ video, autoPlay, onClickReaction }) => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const content = useContent();
  const videoForStatus = getVideo(content);
  const isPending = videoForStatus.status !== 'complete';

  useEffect(() => {
    videoRef.current?.addEventListener('play', onClickReaction);
    return () => videoRef.current?.removeEventListener('play', onClickReaction);
  }, []);

  return (
    <Fragment>
      {isPending && (
        <Fragment>
          <CardContent>
            <Typography variant="subtitle2">{t('posting:processingInProgress')}</Typography>
          </CardContent>
          <LinearProgress variant="indeterminate" />
        </Fragment>
      )}
      <Video ref={videoRef} processing={isPending} video={video} autoPlay={autoPlay} />
    </Fragment>
  );
};

VideoWidget.defaultProps = {
  autoPlay: false,
  onClickReaction: noop,
};

export default VideoWidget;
