import React, { CSSProperties, FC } from 'react';
import numeral from 'numeral';

import { NULL } from 'app-constants';
import { Box } from '@mui/material';

interface Props {
  value: number | string;
  format?: string;
  decimals?: number;
  sx?: CSSProperties;
}

const Numeric: FC<Props> = props => {
  const { format, decimals, value, sx } = props;

  function addDecimals() {
    if (!decimals) {
      return format;
    }

    const suffix = '0'.repeat(decimals);
    return format.concat(`.${suffix}`);
  }

  const withDecimals = addDecimals();
  const formattedValue = numeral(value).format(withDecimals);

  return <Box sx={sx}>{formattedValue}</Box>;
};

Numeric.defaultProps = {
  value: NULL,
  format: '0,0',
  decimals: 0,
};

export default Numeric;
