import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';

import {
  AuthenticationType,
  ClientProtectedFragment,
  useCreateLoginTokenForCurrentUserInClientMutation,
  useCreateUserProfileForCurrentUserInClientMutation,
} from 'api';
import { Buttons, Loaders, PaddedPaper } from 'components';
import { getSubdomainUrl } from 'helpers';
import { useNotify } from 'hooks';

interface Props {
  client: ClientProtectedFragment;
}

const AvailableWorkspace: FC<Props> = ({ client }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const url = getSubdomainUrl(client.subdomain);
  const [launchWindow, setLaunchWindow] = useState<Window>(null);

  const { mutate: createLoginTokenMutate, isLoading: createLoginTokenIsLoading } =
    useCreateLoginTokenForCurrentUserInClientMutation({
      onError: notify.mutationError,
      onSuccess: ({ createLoginTokenForCurrentUserInClient }) => {
        launchWindow.location.href = `${url}/?token=${createLoginTokenForCurrentUserInClient}`;
        setLaunchWindow(null);
      },
    });

  const { mutate: createProfileMutate, isLoading: createProfileIsLoading } =
    useCreateUserProfileForCurrentUserInClientMutation({
      onError: notify.mutationError,
      onSuccess: () => {
        createLoginTokenMutate({ client_id: client.id });
      },
    });

  useEffect(() => {
    if (launchWindow) {
      createProfileMutate({ client_id: client.id });
    }
  }, [launchWindow]);

  function handleLaunchClick() {
    setLaunchWindow(window.open());
  }

  const buttonProps =
    client.authentication_type === AuthenticationType.Sso
      ? { target: '_blank', href: url }
      : { onClick: handleLaunchClick };

  return (
    <Grid>
      <PaddedPaper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container xs spacing={2} alignItems="center">
            <Grid>
              <Typography variant="h6">{client.title}</Typography>
              <Typography color="textSecondary">{client.subdomain}</Typography>
            </Grid>
          </Grid>
          <Grid>
            <Buttons.Primary
              target="_blank"
              href={url}
              size="large"
              variant="contained"
              {...buttonProps}
            >
              {createLoginTokenIsLoading || createProfileIsLoading ? (
                <Loaders.Standard height={26} width={60} />
              ) : (
                t('common:join')
              )}
            </Buttons.Primary>
          </Grid>
        </Grid>
      </PaddedPaper>
    </Grid>
  );
};

export default AvailableWorkspace;
