import React, { FC, ReactNode } from 'react';
import { Unstable_Grid2 as Grid, useMediaQuery } from '@mui/material';
import theme from 'theme';
import { columnSplitToGridSizes } from '../../helpers';

interface Props {
  sidebar: ReactNode;
  main: ReactNode;
  desktopSplit?: string;
  mainWidth?: 'standard' | 'wide';
}

const styles = {
  container: {
    padding: theme.custom.bodyPadding,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
} as const;

const App2Column: FC<Props> = ({ desktopSplit, sidebar, main, mainWidth }) => {
  const isLarge = useMediaQuery(theme.breakpoints.up('xl'));

  const [desktopLeft, desktopMain] = columnSplitToGridSizes(desktopSplit);

  const boxStyle = {};
  const containerStyle = {};

  if (mainWidth === 'standard') {
    boxStyle['maxWidth'] = theme.custom.layoutTwoColumnMainMaxWidth;
    boxStyle['margin'] = '0 auto';
  }

  if (isLarge && mainWidth === 'standard') {
    containerStyle['maxWidth'] = 1440;
  }

  if (mainWidth === 'wide') {
    boxStyle['maxWidth'] = theme.custom.layoutTwoColumnMainMaxWidthWide;
    boxStyle['margin'] = '0 auto';
  }

  if (isLarge && mainWidth === 'wide') {
    containerStyle['maxWidth'] = 1600;
  }

  return (
    <Grid container spacing={2} sx={{ ...containerStyle, ...styles.container }}>
      <Grid xs={12} lg={desktopLeft}>
        {sidebar}
      </Grid>
      <Grid xs={12} lg={desktopMain} sx={boxStyle}>
        {main}
      </Grid>
    </Grid>
  );
};

App2Column.defaultProps = {
  desktopSplit: '3/9',
  mainWidth: undefined,
};

export default App2Column;
