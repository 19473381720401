import useInfiniteScroll from 'react-infinite-scroll-hook';
import { UseInfiniteQueryOptions } from 'react-query';

import {
  ExpressActivatedUsersFragment,
  GetExpressActivatedUsersQuery,
  GetExpressActivatedUsersQueryVariables,
  useGetExpressActivatedUsersQuery,
} from 'api';
import { InfiniteQuery } from 'types/app';
import { useInfiniteQuery } from 'app-react-query/hooks';
import { useNotify } from 'hooks';
import { PAGINATION_ROOT_MARGIN } from 'app-constants';

type Query = GetExpressActivatedUsersQuery;
type Variables = GetExpressActivatedUsersQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = InfiniteQuery<ExpressActivatedUsersFragment> & { refetch: () => void };

const useGetExpressActivatedUsers = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();

  const queryResult = useInfiniteQuery<Query, Variables>(
    useGetExpressActivatedUsersQuery,
    ({ pageParam = '' }: { pageParam: string }) => ({ ...variables, next_id: pageParam }),
    {
      initialData: {
        pages: [
          {
            getExpressActivatedUsers: { message: '', total: 0, users: [], last_evaluated_key: '' },
          },
        ],
        pageParams: [],
      },
      onError: notify.queryError,
      getNextPageParam: lastPage => {
        const { getExpressActivatedUsers } = lastPage;
        const endOfList = !getExpressActivatedUsers.last_evaluated_key;
        const noResults = !getExpressActivatedUsers.users.length;

        if (noResults || endOfList) {
          return false;
        }

        return getExpressActivatedUsers.last_evaluated_key;
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = queryResult;
  const results = data?.pages?.map(page => page.getExpressActivatedUsers.users).flat() ?? [];
  const totalCount = data?.pages?.[0]?.getExpressActivatedUsers.total ?? 0;

  const [sentinelRef] = useInfiniteScroll({
    hasNextPage,
    loading: isFetchingNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: PAGINATION_ROOT_MARGIN,
  });

  return {
    sentinelRef,
    results,
    count: totalCount,
    isEmpty: !results.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !results.length && isFetching,
    refetch,
  };
};

export default useGetExpressActivatedUsers;
