import React, { ElementType, FC, ReactNode } from 'react';
import { Tab } from '@mui/material';
import { TabOwnProps } from '@mui/material/Tab/Tab';

import { Capability } from './index';
import theme, { pxToRem } from 'theme';

interface Props extends TabOwnProps {
  capability?: string;
  component?: ElementType<any> | ReactNode;
}

const styles = {
  root: {
    fontSize: pxToRem(20),
    '@media (min-width: 960px)': {
      padding: theme.spacing(2, 5, 3),
    },
  },
} as const;

const LargeTab: FC<Props> = props => {
  if (!props.capability) {
    return <Tab {...props} sx={styles.root} />;
  }

  return (
    <Capability name={props.capability}>
      <Tab {...props} sx={styles.root} />
    </Capability>
  );
};

export default LargeTab;
