import { object, string } from 'yup';
import i18n from 'config/i18n';

const invalidEmail: string = i18n.t('auth:invalidEmail');
const required: string = i18n.t('common:required');

export interface LandingSchema {
  username: string;
}

export const landingSchema = object().shape({
  username: string().required(required).email(invalidEmail),
});

export const clientCreateSchema = object().shape({
  title: string().required(required),
  full_name: string().required(required),
  email_address: string().required(required).email(invalidEmail),
  cognito_user_name: string().required(required),
});
