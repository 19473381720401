import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Unstable_Grid2 as Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import theme from 'theme';
import { CsUpdateClientInput } from 'api';
import { ValidatedTextFieldNext } from 'components';
import { ClientInput } from '../../types';

import { DOMAIN } from 'settings';

const Information: FC = () => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<ClientInput>();
  const clientValues = values as CsUpdateClientInput;
  const suffix = `.${DOMAIN}`;

  function handleSubdomainValue(value: string) {
    return value.split('.')[0];
  }

  function handleSubdomainChange(event: any) {
    setFieldValue('subdomain', `${event.target.value.toLowerCase()}${suffix}`);
  }

  return (
    <Grid xs={12} container>
      <Grid xs={3}>
        <Typography variant="subtitle2">Client Information</Typography>
      </Grid>
      <Grid xs={9} container spacing={3}>
        <Grid xs={12}>
          <ValidatedTextFieldNext
            fullWidth
            name="title"
            placeholder={t('clientSuccess:clientName')}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid xs={12}>
          <ValidatedTextFieldNext
            fullWidth
            name="subdomain"
            placeholder={t('clientSuccess:subdomain')}
            valueFn={handleSubdomainValue}
            onChange={handleSubdomainChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" variant="filled" style={{ marginRight: 8 }}>
                  <Typography variant="subtitle2">{suffix}</Typography>
                </InputAdornment>
              ),
              sx: { backgroundColor: theme.palette.grey[100] },
            }}
          />
        </Grid>
        {!!clientValues.id && (
          <Grid xs={12}>
            <TextField
              variant="standard"
              label="Client ID"
              fullWidth
              placeholder="Client ID"
              value={clientValues.id}
              sx={{
                Input: {
                  backgroundColor: theme.palette.grey[100],
                  padding: theme.spacing(2),
                },
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Information;
