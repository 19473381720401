import { INotify, INotifyTypes } from 'types/app';
import { DEFAULT_NOTIFY_OPTIONS } from 'app-constants';

import eventManager, { Event } from './event-manager';

function dispatchToast(options: INotify) {
  eventManager.emit(Event.Show, options);
}

const notify = {
  info(options: INotify) {
    dispatchToast({
      ...DEFAULT_NOTIFY_OPTIONS,
      type: INotifyTypes.Info,
      show: true,
      ...options,
    });
  },

  error(options: INotify) {
    dispatchToast({
      ...DEFAULT_NOTIFY_OPTIONS,
      type: INotifyTypes.Error,
      show: true,
      duration: null,
      ...options,
    });
  },

  mutationError(errorObject: Error) {
    dispatchToast({
      ...DEFAULT_NOTIFY_OPTIONS,
      type: INotifyTypes.MutationError,
      show: true,
      duration: null,
      message: errorObject?.message,
    });
  },

  queryError(errorObject: Error) {
    dispatchToast({
      ...DEFAULT_NOTIFY_OPTIONS,
      type: INotifyTypes.QueryError,
      show: true,
      duration: null,
      message: errorObject?.message,
    });
  },

  subscriptionError(errorObject: Error) {
    dispatchToast({
      ...DEFAULT_NOTIFY_OPTIONS,
      type: INotifyTypes.SubscriptionError,
      show: true,
      duration: null,
      message: errorObject?.message,
    });
  },
};

export default notify;
