import React, { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { DistributiveOmit } from '@mui/types';

import * as paths from 'paths';

export const generateLink = (path: string) => {
  return forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => {
    return <RouterLink ref={ref} to={path} {...props} />;
  });
};

export const Account = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.account} {...props} />
));
export const Activity = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.activity} {...props} />
));
export const Index = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.index} {...props} />
));
export const Groups = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.groups} {...props} />
));
export const GroupsIOwn = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.groupsIOwn} {...props} />
));
export const GroupsModerating = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.groupsModerating} {...props} />
);
export const GroupsFollowing = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.groupsFollowing} {...props} />
);
export const GroupsAll = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.groupsAll} {...props} />
));
export const GroupCreate = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.groupCreate} {...props} />
);
export const ForgotPassword = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.forgotPassword} {...props} />
);
export const ForgotPasswordSubmit = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.forgotPasswordSubmit} {...props} />
);
export const ExpressActivation = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.expressActivation} {...props} />
);
export const Leaderboard = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.leaderboard} {...props} />
);
export const Login = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.login} {...props} />
));
export const Logout = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.logout} {...props} />
));
export const Notifications = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.notifications} {...props} />
);
export const SignUp = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.signUp} {...props} />
));
export const SocialMediaPolicy = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.socialMediaPolicy} {...props} />
);
export const OnboardingCreate = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.onboardingCreate} {...props} />
);
export const OnboardingIdentity = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.onboardingIdentity} {...props} />
);
export const OnboardingSocials = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.onboardingSocials} {...props} />
);
export const Team = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.team} {...props} />
));
export const TeamEveryone = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.teamEveryone} {...props} />
);
export const AdminPostManagement = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminPostManagement} {...props} />
);
export const AdminUserManagement = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminUserManagement} {...props} />
);
export const AdminInvite = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminInvite} {...props} />
);
export const AdminGroups = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminGroups} {...props} />
);
export const AdminInvitations = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminInvitations} {...props} />
);
export const AdminMessages = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminMessages} {...props} />
);
export const AdminLeaderboards = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminLeaderboards} {...props} />
);
export const AdminLeaderboardsCreate = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminLeaderboardsCreate} {...props} />
);
export const AdminContentEmails = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminContentEmails} {...props} />
);
export const AdminContentEmailCreate = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminContentEmailCreate} {...props} />
);
export const AdminSettings = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettings} {...props} />
);
export const AdminSettingsAccount = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsAccount} {...props} />
);
export const AdminSettingsBlocklist = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsBlocklist} {...props} />
);
export const AdminSettingsLocations = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsLocations} {...props} />
);
export const AdminSettingsDisclosures = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsDisclosures} {...props} />
);
export const AdminSettingsPolicies = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsPolicies} {...props} />
);
export const AdminSettingsReferrals = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsReferrals} {...props} />
);
export const AdminSettingsIntegrations = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsIntegrations} {...props} />
);
export const AdminSettingsCompanyPages = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsCompanyPages} {...props} />
);
export const AdminSettingsAssignments = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsAssignments} {...props} />
);
export const AdminSettingsAssignmentsCreate = forwardRef<
  any,
  DistributiveOmit<RouterLinkProps, 'to'>
>((props, ref) => <RouterLink ref={ref} to={paths.adminSettingsAssignmentsCreate} {...props} />);
export const AdminSettingsAssignmentsEdit = forwardRef<
  any,
  DistributiveOmit<RouterLinkProps, 'to'>
>((props, ref) => <RouterLink ref={ref} to={paths.adminSettingsAssignmentsEdit} {...props} />);
export const AdminSettingsUserSegments = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsUserSegments} {...props} />
);
export const AdminSettingsShareTracking = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsShareTracking} {...props} />
);
export const AdminSettingsSharing = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsSharing} {...props} />
);
export const AdminSettingsStickers = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminSettingsStickers} {...props} />
);
export const ClientSuccess = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.clientSuccess} {...props} />
);
export const AdminAnalytics = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminAnalytics} {...props} />
);
export const AdminAnalyticsOverview = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminAnalyticsOverview} {...props} />
);
export const AdminAnalyticsGroups = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminAnalyticsGroups} {...props} />
);
export const AdminAnalyticsUsers = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminAnalyticsUsers} {...props} />
);
export const AdminAnalyticsPosts = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminAnalyticsPosts} {...props} />
);
export const AdminAnalyticsShares = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminAnalyticsShares} {...props} />
);
export const AdminAnalyticsLeaderboards = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminAnalyticsLeaderboards} {...props} />
);
export const AdminUpgrade = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminUpgrade} {...props} />
);
export const AdminBilling = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.adminBilling} {...props} />
);
export const Insights = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.insights} {...props} />
));
export const InsightsCompanies = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.insightsCompanies} {...props} />
);
export const InsightsPeople = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.insightsPeople} {...props} />
);
export const PostCreate = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.postCreate} {...props} />
));
export const PostEdit = (id: string) => {
  const path = paths.postEdit.replace(':content_id', id);
  return forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => {
    return <RouterLink ref={ref} to={path} {...props} />;
  });
};
export const Share = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.share} {...props} />
));
export const ConfirmSignup = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.confirmSignup} {...props} />
);
export const Workspaces = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.workspaces} {...props} />
));
export const SetupProfile = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.setupProfile} {...props} />
);
export const SetupWorkspace = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.setupWorkspace} {...props} />
);
export const SetupWorkspaceGroups = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.setupWorkspaceGroups} {...props} />
);
export const SetupWorkspaceInvites = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.setupWorkspaceInvites} {...props} />
);
export const Network = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={paths.network} {...props} />
));
export const WorkspaceLauncher = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={paths.workspaceLauncher} {...props} />
);
