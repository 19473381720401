import React, { ChangeEvent, FC, useState } from 'react';
import { useDebounce } from 'use-debounce/lib';

import { ClientProtectedFragment, ClientStatus, useGetClientQuery } from 'api';
import useSearchClients from 'clientSuccess/hooks/useSearchClients';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';

interface IParams {
  clientId: string;
  onChangeClient: (client?: ClientProtectedFragment) => void;
}

const ClientSelect: FC<IParams> = ({ clientId, onChangeClient }) => {
  const [client, setClient] = useState<ClientProtectedFragment>(null);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 250);
  const searchClientsQuery = useSearchClients(
    {
      search_filter: {
        query: debouncedQuery,
        client_status__eq: ClientStatus.Active,
      },
      limit: 10,
    },
    {
      enabled: !!query,
    }
  );
  const needsGetClient = !!clientId && !searchClientsQuery.results.find(c => c.id == clientId);
  useGetClientQuery(
    { id: clientId },
    {
      enabled: needsGetClient,
      onSuccess: data => {
        setClient(data.getClient);
        onChangeClient(data.getClient);
      },
    }
  );
  const clients = [...(searchClientsQuery.results || [])];
  if (needsGetClient && client) {
    clients.splice(0, 0, client);
  }

  return (
    <Autocomplete
      fullWidth
      disablePortal
      options={clients}
      getOptionLabel={option => (typeof option == 'string' ? 'None' : option.subdomain)}
      value={client}
      onChange={(
        event: ChangeEvent<HTMLSelectElement>,
        client: ClientProtectedFragment | string
      ) => {
        if (typeof client == 'string') {
          client = null;
          return;
        }
        setClient(client);
        onChangeClient(client);
      }}
      loading={searchClientsQuery.isInitialLoad}
      renderInput={params => (
        <TextField
          variant="outlined"
          sx={{ Input: { padding: 1 } }}
          {...params}
          placeholder={client?.subdomain || 'Select Client'}
          value={query}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
        />
      )}
    />
  );
};

export default ClientSelect;
