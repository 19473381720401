import React, { FC, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import { ES_SHARE_URL_QUERY_PARAM } from 'app-constants';
import { useCognitoUser } from 'hooks';
import {
  RootOAuthIntegrationClient,
  RootWorkspaceLauncher,
  RootWorkspaces,
  RootClientCreate,
  RootSharingSandbox,
} from 'features/root';
import Logout from 'startup/protected/Logout';
import cookieStorage, { NEXT_PATH_COOKIE } from 'cookie-storage';
import * as paths from 'paths';

const RoutesComponent: FC = () => {
  const navigate = useNavigate();
  const shareUrl = new URLSearchParams(location.search).get(ES_SHARE_URL_QUERY_PARAM);
  const cognitoUser = useCognitoUser();

  if (shareUrl) {
    cookieStorage.shareUrl = shareUrl;
  }

  useEffect(() => {
    const nextPath = cookieStorage.nextPath;

    if (nextPath) {
      cookieStorage.remove(NEXT_PATH_COOKIE);
      navigate(nextPath);
    }

    window.analytics?.identify(cognitoUser.id, {
      email: cognitoUser.authenticatedUser.getUsername(),
    });
  }, []);

  return (
    <Routes>
      <Route path={paths.index} element={<RootWorkspaces />} />
      <Route path={paths.workspaces} element={<RootWorkspaces />} />
      <Route path={paths.workspaceLauncher} element={<RootWorkspaceLauncher />} />
      <Route path={paths.clientCreate} element={<RootClientCreate />} />
      <Route path={paths.integrationsProvider} element={<RootOAuthIntegrationClient />} />
      <Route path={paths.sharingSandbox} element={<RootSharingSandbox />} />
      <Route path={paths.logout} element={<Logout />} />
      <Route path="*" element={<Navigate to={paths.workspaces} replace />} />
    </Routes>
  );
};

export default RoutesComponent;
