import React, { FC } from 'react';
import { Button } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useTranslation } from 'react-i18next';

import { UploadInput } from 'components';
import { useCurrentUser } from 'hooks';
import { generateS3CSV } from 'helpers';
import { S3ObjectInput } from 'api';

interface IProps {
  s3Object?: S3ObjectInput;
  onS3Object?: (input: S3ObjectInput) => void;
}

const CSVUpload: FC<IProps> = ({ s3Object, onS3Object }) => {
  const { t } = useTranslation();
  const user = useCurrentUser();

  function handleUpload(file: File) {
    onS3Object(generateS3CSV(file, user));
  }

  if (s3Object) {
    return (
      <Button color="primary" component="span" variant="contained" onClick={() => onS3Object(null)}>
        {t('components:clearCSV')}
      </Button>
    );
  }

  return (
    <UploadInput accept="text/csv" onUpload={handleUpload}>
      <Button
        component="span"
        variant="contained"
        color="primary"
        startIcon={<InsertDriveFileIcon />}
      >
        {t('components:uploadCSV')}
      </Button>
    </UploadInput>
  );
};

export default CSVUpload;
