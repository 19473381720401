import React, { FC, ReactNode } from 'react';

import { LoaderTypes } from 'types/app';
import { SearchAggregatesFragment, SearchDocuments, useSearchAggregatesQuery } from 'api';
import { QueryManager } from 'components';

interface IProps {
  loader?: LoaderTypes;
  document: SearchDocuments;
  children: (data: SearchAggregatesFragment) => ReactNode;
}

const SearchAggregatesQuery: FC<IProps> = ({ children, document, loader }) => {
  const queryResult = useSearchAggregatesQuery({ document });

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.searchAggregates) {
          return children({ total: 0, departments: [], locations: [] });
        }

        return children(data.searchAggregates);
      }}
    </QueryManager>
  );
};

export default SearchAggregatesQuery;
