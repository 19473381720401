import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@mui/material';

import { IndividualStat } from 'api';
import { ConditionalNumberTooltip } from 'components';
import { formatStat } from 'helpers';

import Change from './Change';

interface Props {
  i18nKey: string;
  tooltipI18nKey?: string;
  stat: IndividualStat;
}

const TabLabel: FC<Props> = ({ i18nKey, stat, tooltipI18nKey }) => {
  const { t } = useTranslation();

  const title = tooltipI18nKey ? (
    <Tooltip title={t(tooltipI18nKey)}>
      <Typography variant="body2">{t(i18nKey)}</Typography>
    </Tooltip>
  ) : (
    <Typography variant="body2">{t(i18nKey)}</Typography>
  );

  return (
    <Fragment>
      {title}
      <ConditionalNumberTooltip value={stat.count}>
        <Typography variant="h2">{formatStat(stat.count)}</Typography>
      </ConditionalNumberTooltip>
      <Change countChange={stat.change} />
    </Fragment>
  );
};

TabLabel.defaultProps = {
  tooltipI18nKey: '',
};

export default TabLabel;
