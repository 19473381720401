import { UseMutationOptions, useQueryClient } from 'react-query';

import {
  BatchUpdateSocialConnectionMutation,
  BatchUpdateSocialConnectionMutationVariables,
  BootstrapQuery,
} from 'api';
import { useBootstrapQueryKey, useNotify } from 'hooks';
import produce from 'immer';
import { findIndex, flatMap } from 'lodash';

type QueryData = BootstrapQuery;
type Mutation = BatchUpdateSocialConnectionMutation;
type MutationVariables = BatchUpdateSocialConnectionMutationVariables;

const useUpdateSocialConnection = () => {
  const queryClient = useQueryClient();
  const queryKey = useBootstrapQueryKey();
  const notify = useNotify();

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: ({ data: { items } }) => {
      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const { bootstrap } = draftState;

          const hasLinkedInPages = items.some(item => item.linkedin_pages);
          if (hasLinkedInPages) {
            const linkedInPages = flatMap(items, item => item.linkedin_pages || []);
            if (linkedInPages) {
              bootstrap.user_pages = linkedInPages;
            }
          }

          // social account update
          items.forEach(item => {
            const socialAccountIdx = findIndex(
              bootstrap.user_social_accounts,
              account => account.id === item.token_id
            );

            if (item.smart_schedule !== undefined) {
              bootstrap.user_social_accounts[socialAccountIdx].smart_schedule = item.smart_schedule;
            }
          });
        });

      if (baseState) {
        queryClient.setQueryData(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useUpdateSocialConnection;
