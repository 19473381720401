import React, { ChangeEvent, FC, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Unstable_Grid2 as Grid,
  Input,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  ClientProtectedFragment,
  UserProfileSearchFragment,
  UserStatus,
  useSetImpersonationMutation,
} from 'api';
import { useCognitoUser, useNotify } from 'hooks';
import theme from 'theme';

import ClientSelect from 'clientSuccess/components/Client/ClientSelect';
import * as csPaths from 'clientSuccess/paths';
import { CreateDialog, UserTable } from 'clientSuccess/components/Users';

const styles = {
  inputAdornment: {
    marginLeft: theme.spacing(),
    marginRight: 0,
  },
  clearButton: {
    color: 'common.white',
    background: 'error.main',
    '&:hover': {
      background: alpha(theme.palette.error.main, 0.7),
      borderColor: 'error.main',
    },
  },
} as const;

const UserList: FC = () => {
  const urlParams = new URLSearchParams(location.search);
  const clientId = urlParams.get('client-id');
  const userStatus = urlParams.get('user-status') || UserStatus.Active;
  const [query, setQuery] = useState(urlParams.get('query') || '');
  const navigate = useNavigate();
  const auth = useCognitoUser();
  const notify = useNotify();
  const { t } = useTranslation();
  const { mutate: setImpersonation } = useSetImpersonationMutation({
    onError: notify.mutationError,
  });

  // Derived / internal state..
  const [client, setClient] = useState<ClientProtectedFragment>();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [numUsers, setNumUsers] = useState<number | null>(null);
  const shouldSearch = !!(query || client);

  // The search will only return a max of 10000 users
  const numUsersText = getNumUsersText();

  function getNumUsersText() {
    if (!shouldSearch || !numUsers) {
      return '';
    }
    if (numUsers == 10000) {
      return 'More than 10,000 People'; // The search only returns 10000 even if there are more matches than this
    }
    return t('components:xPerson', { count: numUsers });
  }

  function handleHistoryUpdate(
    client: ClientProtectedFragment | null | undefined,
    userStatus: string,
    query: string
  ) {
    const params = new URLSearchParams();
    if (client) {
      params.set('client-id', client.id);
    }
    if (userStatus) {
      params.set('user-status', userStatus);
    }
    if (query) {
      params.set('query', query);
    }
    setQuery(query); // Updating this directly because the changes based on the URL are debounced, and do not happen fast enough
    const paramString = params.toString();
    navigate(`${csPaths.userList}${paramString ? '?' : ''}${paramString}`, { replace: true });
  }

  function handleSetImpersonation(user?: UserProfileSearchFragment) {
    const userId = user?.id || null;
    setImpersonation(
      { id: auth.id, impersonate_user_id: userId },
      {
        onSuccess: () => (user?.id ? location.assign('/') : location.reload()),
      }
    );
  }

  function renderUserQuery() {
    return (
      <Input
        sx={{ padding: 1, border: `1px solid ${theme.palette.grey[300]}` }}
        value={query}
        fullWidth
        placeholder="Query..."
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleHistoryUpdate(client, userStatus, event.target.value);
        }}
        startAdornment={
          <InputAdornment sx={styles.inputAdornment} position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    );
  }

  function renderUserStatus() {
    return (
      <Select
        variant="standard"
        fullWidth
        value={userStatus}
        onChange={(event: SelectChangeEvent<string>) => {
          handleHistoryUpdate(client, event.target.value, query);
        }}
      >
        <MenuItem value={UserStatus.Active}>Active</MenuItem>
        <MenuItem value={UserStatus.Disabled}>Disabled</MenuItem>
        <MenuItem value={UserStatus.PreInitialized}>Pre-Initialized</MenuItem>
      </Select>
    );
  }

  function renderFilterOptions() {
    return (
      <Box p={1}>
        <Grid container alignItems="center" spacing={2}>
          <Grid xs={4}>
            <ClientSelect
              clientId={clientId}
              onChangeClient={(client: ClientProtectedFragment) => {
                setClient(client);
                handleHistoryUpdate(client, userStatus, query);
              }}
            />
          </Grid>
          <Grid xs={4}>{renderUserStatus()}</Grid>
          <Grid xs={4}>{renderUserQuery()}</Grid>
        </Grid>
      </Box>
    );
  }

  function renderUserControls() {
    return (
      <Box p={1}>
        <Grid container alignItems="center" spacing={2}>
          <Grid xs>{!!numUsers && <Typography variant="body2">{numUsersText}</Typography>}</Grid>
          <Grid>
            {!!client && (
              <CreateDialog
                clientId={client.id}
                open={createDialogOpen}
                setOpen={setCreateDialogOpen}
              />
            )}
            <Button
              color="primary"
              variant="contained"
              endIcon={<AddIcon />}
              disabled={!client}
              onClick={() => setCreateDialogOpen(true)}
            >
              Create User
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderTable() {
    return (
      <UserTable
        query={query}
        client={client}
        userStatus={userStatus as UserStatus}
        onSetImpersonation={handleSetImpersonation}
        onNumUsersFound={setNumUsers}
      />
    );
  }

  function renderNoSearch() {
    return (
      <Box p={2}>
        <Typography align="center" variant="h6">
          Please Select a Client or enter a Query to Continue
        </Typography>
      </Box>
    );
  }

  function renderMain() {
    return (
      <Paper>
        {renderFilterOptions()}
        <Divider />
        {renderUserControls()}
        <Divider />
        {shouldSearch ? renderTable() : renderNoSearch()}
      </Paper>
    );
  }

  return <Box p={2}>{renderMain()}</Box>;
};

export default UserList;
