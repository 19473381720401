import React, { FC, Fragment, useState } from 'react';
import { IconButton, Tooltip, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeleteRounded, EditRounded } from '@mui/icons-material';

import { SharesItemFragment, useDeleteScheduledSharesMutation } from 'api';
import { Alert } from 'components';
import { useNotify } from 'hooks';

import { useDeleteSharesItems } from '../updaters';
import { Action } from '../types';
import Dialog from './EditShare/Dialog';

interface Props {
  shareItem: SharesItemFragment;
}

const SharesActions: FC<Props> = ({ shareItem }) => {
  const { id } = shareItem;
  const { t } = useTranslation();
  const notify = useNotify();
  const [action, setAction] = useState<Action>(Action.None);

  function handleCancel() {
    setAction(Action.None);
  }

  const deleteSharesItemsOptions = useDeleteSharesItems({
    scheduled: true,
    group_by_session: false,
    data: {
      limit: 25,
    },
  });

  const { mutate: deleteShare } = useDeleteScheduledSharesMutation({
    ...deleteSharesItemsOptions,
    onSuccess: () => notify.info({ message: t('posting:deleteScheduledShareSuccess') }),
  });

  function handleDelete() {
    deleteShare({ ids: [id] });
    setAction(Action.None);
  }

  return (
    <Fragment>
      <Alert
        destructive
        open={action === Action.Delete}
        onConfirm={handleDelete}
        onCancel={handleCancel}
        message={t('sharing:deleteScheduledShares', { count: 1 })}
        confirm={t('common:delete')}
      />
      <Dialog open={action === Action.Edit} shareItem={shareItem} setAction={setAction} />
      <Grid wrap="nowrap" container alignItems="center" justifyContent="flex-end">
        <Grid>
          <Tooltip title={t('common:edit')}>
            <IconButton onClick={() => setAction(Action.Edit)} size="large">
              <EditRounded fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('common:delete')}>
            <IconButton onClick={() => setAction(Action.Delete)} size="large">
              <DeleteRounded fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SharesActions;
