import React, { FC, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, MenuItem, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Clipboard from 'clipboard';

import { useContent, useNotify, useProtectedClient } from 'hooks';
import * as paths from 'paths';

const CopyLink: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const client = useProtectedClient();
  const content = useContent();
  const notify = useNotify();

  const esClipboard = useRef<Clipboard>(null);
  const esTextRef = useRef<HTMLSpanElement>(null);
  const esButtonRef = useRef<HTMLLIElement>(null);

  const { content_id } = content;
  const part = paths.content.replace(':content_id', content_id);
  const contentPath = `${window.location.protocol}//${client.subdomain}${part}`;

  useEffect(() => {
    if (esButtonRef.current) {
      esClipboard.current = new Clipboard(esButtonRef.current, {
        target: () => esTextRef.current,
      });

      esClipboard.current.on('success', () => notify.info({ i18n: { i18nKey: 'sharing:copied' } }));
    }

    return () => esClipboard.current?.destroy();
  }, [contentPath]);

  return (
    <Grid>
      <Box sx={theme.mixins.visuallyHidden} ref={esTextRef}>
        {contentPath}
      </Box>
      <MenuItem ref={esButtonRef}>{t('content:copyLink')}</MenuItem>
    </Grid>
  );
};

export default CopyLink;
