export enum Events {
  ShareInitiated = 'Share Initiated',
  ClickLikeCount = 'click_like_count',
  ClickShareCount = 'click_share_count',
  ClickEngage = 'click_engage',
  PublicPostImpression = 'Public Post Impression',
  PublicPostShareInitiated = 'Public Post Share Initiated',
  PublicPostShareCompleted = 'Public Post Share Completed',
  PublicPostEngageClicked = 'Public Post Engage Clicked',
  PublicPostClicked = 'Public Post Clicked',
  PublicPostLogoClicked = 'Public Post Logo_Clicked',
  PostReadMoreClicked = 'Post Read More Clicked',
  RepostClicked = 'Repost Clicked',
  FormSubmitted = 'Form Submitted',
  CopyEmbedCode = 'Copy Embed Code',
}
