import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { useCurrentUser, useProtectedClient } from 'hooks';
import { Logout } from 'Links';
import { ClientLogo, Layouts } from 'components';

const LocationBlock: FC = () => {
  const currentUser = useCurrentUser();
  const client = useProtectedClient();
  const { t } = useTranslation();

  function renderMain() {
    return (
      <Layouts.OnboardingBox>
        <Box textAlign="center">
          <ClientLogo client={client} />
        </Box>
        <Typography align="center">{currentUser.location.block_message}</Typography>
        <Box paddingTop={3}>
          <Button
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            component={Logout}
          >
            {t('common:logout')}
          </Button>
        </Box>
      </Layouts.OnboardingBox>
    );
  }

  return <Layouts.Onboarding main={renderMain()} />;
};

export default LocationBlock;
