import useInfiniteScroll from 'react-infinite-scroll-hook';
import { UseInfiniteQueryOptions } from 'react-query';

import {
  ClientInvitationFragment,
  SearchClientInvitationsQuery,
  SearchClientInvitationsQueryVariables,
  useSearchClientInvitationsQuery,
} from 'api';
import { InfiniteQuery } from 'types/app';
import { useInfiniteQuery } from 'app-react-query/hooks';
import { useNotify } from 'hooks';
import { PAGINATION_ROOT_MARGIN } from 'app-constants';

type Query = SearchClientInvitationsQuery;
type Variables = SearchClientInvitationsQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = InfiniteQuery<ClientInvitationFragment>;

const useGetClientInvitationsByClient = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();

  const queryResult = useInfiniteQuery<Query, Variables>(
    useSearchClientInvitationsQuery,
    ({ pageParam }: { pageParam: Variables }) => ({ ...variables, ...pageParam }),
    {
      initialData: {
        pages: [
          {
            searchClientInvitations: {
              count: 0,
              items: [],
              last_evaluated_key: null,
            },
          },
        ],
        pageParams: [],
      },
      onError: notify.queryError,
      getNextPageParam: lastPage => {
        const { searchClientInvitations } = lastPage;
        const { items, last_evaluated_key } = searchClientInvitations;

        const endOfList = !last_evaluated_key;
        const noResults = !items.length;

        if (noResults || endOfList) {
          return false;
        }

        return { last_evaluated_key, count: false };
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = queryResult;
  const results = data?.pages?.map(page => page.searchClientInvitations.items).flat() ?? [];
  const totalCount = data?.pages?.[0]?.searchClientInvitations.count ?? 0;

  const [sentinelRef] = useInfiniteScroll({
    hasNextPage,
    loading: isFetchingNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: PAGINATION_ROOT_MARGIN,
  });

  return {
    sentinelRef,
    results,
    count: totalCount,
    isEmpty: !results.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !results.length && isFetching,
  };
};

export default useGetClientInvitationsByClient;
