import React, { FC, Fragment, ReactElement } from 'react';
import { Tooltip } from '@mui/material';

import { SHORTENED_NUMBER_CUTOFF } from 'app-constants';
import { formatStat } from 'helpers';

interface Props {
  prefix?: string;
  value: number;
  children: ReactElement;
}

const ConditionalNumberTooltip: FC<Props> = ({ prefix, value, children }) => {
  if (value < SHORTENED_NUMBER_CUTOFF) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Tooltip title={`${prefix}${formatStat(value, '')}`} enterDelay={200}>
      {children}
    </Tooltip>
  );
};

ConditionalNumberTooltip.defaultProps = {
  prefix: '',
};

export default ConditionalNumberTooltip;
