import { UseInfiniteQueryOptions, InfiniteData } from 'react-query';
import { last } from 'lodash';

import {
  GetFollowedQuery,
  GetFollowedQueryVariables,
  UserFeedFollowFragment,
  useGetFollowedQuery,
} from 'api';
import { useInfiniteQuery } from 'app-react-query/hooks';
import { useCurrentUser, useNotify } from 'hooks';

type Query = GetFollowedQuery;
type Variables = GetFollowedQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = UserFeedFollowFragment[];

const useFollowedAllResultsQuery = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();
  const currentUser = useCurrentUser();

  const { data, fetchNextPage, isFetching } = useInfiniteQuery<Query, Variables>(
    useGetFollowedQuery,
    ({ pageParam = {} }: { pageParam: Record<string, unknown> }) => ({
      ...variables,
      ...pageParam,
    }),
    {
      initialData: {
        pages: [{ getPaginatedUserFeedFollows: { count: 0, feed_follows: [] } }],
        pageParams: [],
      },
      onError: notify.queryError,
      onSuccess: handleSuccess,
      ...options,
    }
  );

  async function handleSuccess({ pages }: InfiniteData<GetFollowedQuery>) {
    const { getPaginatedUserFeedFollows } = last(pages);
    const { title_lower, client_id__feed__id, feed_follows } = getPaginatedUserFeedFollows;

    const endOfList = !client_id__feed__id || !title_lower;
    const noResults = !feed_follows.length;

    if (noResults || endOfList) {
      return;
    }

    await fetchNextPage({
      pageParam: { data: { ...variables.data, title_lower, client_id__feed__id } },
    });
  }

  const results =
    data.pages.map(page => page.getPaginatedUserFeedFollows.feed_follows).flat() ?? [];
  const following_group_count = results.filter(item => item.group).length;

  if (!isFetching) {
    window.analytics?.identify(currentUser.id, {
      following_group_count,
    });
  }

  return results;
};

export default useFollowedAllResultsQuery;
