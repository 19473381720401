import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyTypeMap } from '@mui/material';

import { useContent } from 'hooks';

interface IProp {
  variant?: TypographyTypeMap['props']['variant'];
  color?: TypographyTypeMap['props']['color'];
}

const ShareCount: FC<IProp> = ({ variant, color, ...rest }) => {
  const { t } = useTranslation();
  const { share_count } = useContent();

  return (
    <Typography variant={variant} color={color} {...rest}>
      {t('content:xShare', { count: share_count })}
    </Typography>
  );
};

ShareCount.defaultProps = {
  variant: 'body1',
};

export default ShareCount;
