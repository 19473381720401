import React, { FC, useState } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import moment from 'moment';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

import { Content, ContentApproval } from 'api';
import { DatePickers } from 'components';

import { usePostContext } from '../../PostContext';
import { Switch } from '../';
import Widget from './WidgetContainer';

const ScheduleExpirePost: FC = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<Content>();
  const { state, dispatch } = usePostContext();

  const { schedule, group_id_approvals, content_id } = values;
  const isDeleted = content_id && !group_id_approvals?.length;
  const { publish, expire } = schedule;
  const [originalSchedule] = useState(schedule);
  const approval = (group_id_approvals && group_id_approvals[0]?.approval) || '';
  const isPublishable = ['', ContentApproval.Submitted, ContentApproval.Pending].includes(approval);
  const isExpirable = ![ContentApproval.Deleted, ContentApproval.Removed].includes(
    approval as ContentApproval
  );

  const minExpireDate = getMinExpiresDate();

  function onChangeSwitch() {
    if (state.schedule) {
      setFieldValue('schedule.publish', null);
      setFieldValue('schedule.expire', null);
    }

    dispatch({ type: 'schedule' });
  }

  function onChangeDate(type: string, time: number) {
    if (time) {
      if (type === 'publish' && expire) {
        const publishMoment = moment.unix(time);
        const expireMoment = moment.unix(expire);
        const exceedsExpiration = publishMoment.isAfter(expireMoment);

        if (exceedsExpiration) {
          setFieldValue('schedule.expire', null);
        }
      } else if (type === 'expire' && publish) {
        const publishMoment = moment.unix(publish);
        const expireMoment = moment.unix(time);
        const exceedsExpiration = publishMoment.isAfter(expireMoment);

        if (exceedsExpiration) {
          time = publish + 600;
        }
      }
    }

    setFieldValue(`schedule[${type}]`, time);
  }

  function getMinExpiresDate() {
    let min = 'today';
    if (publish) {
      const now = moment();
      const _min = moment.unix(publish);
      if (!now.isAfter(_min)) {
        min = _min.format('YYYY-MM-DD');
      }
    }
    return min;
  }

  function renderBody() {
    return (
      <Grid container marginTop={2}>
        <Grid xs={1} />
        <Grid xs={10} container>
          <Grid xs marginRight={2}>
            <DatePickers.Select
              enableTime
              label={t('sharing:scheduled')}
              value={publish || values.schedule.publish}
              setValue={time => onChangeDate('publish', time)}
              fullWidth
              minDate="today"
              defaultValue={t('posting:selectTime')}
              disabled={isDeleted || !isPublishable}
              allowNull={!values.content_id && isPublishable}
            />
          </Grid>
          <Grid xs>
            <DatePickers.Select
              enableTime
              label={t('posting:expire')}
              value={expire}
              setValue={time => onChangeDate('expire', time)}
              fullWidth
              minDate={minExpireDate}
              defaultValue={t('posting:never')}
              disabled={isDeleted || !isExpirable}
              allowNull={isExpirable}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Widget
      titleKey="posting:scheduleExpire"
      subtitleKey="posting:guidelineScheduleExpire"
      icon={<QueryBuilderIcon color={state.schedule ? 'inherit' : 'disabled'} />}
      toggle={
        <Switch
          data-testid="post-radio-schedule"
          checked={state.schedule}
          disabled={!!(isDeleted || originalSchedule.publish || originalSchedule.expire)}
          onChange={onChangeSwitch}
          color="primary"
        />
      }
    >
      {state.schedule && renderBody()}
    </Widget>
  );
};

export default ScheduleExpirePost;
