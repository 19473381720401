import React, { FC } from 'react';
import { TableCell, TableSortLabel } from '@mui/material';

import { SortDirections } from 'api';
import { ISortableHeadCell, SortTypes } from '../types';

interface IProps extends ISortableHeadCell {
  sortValue: SortTypes;
  sortDirection: SortDirections;
  onSort: (id: SortTypes, direction: SortDirections) => void;
}

const SortableHeadCell: FC<IProps> = props => {
  const { id, align, label, initialSort, width, onSort, sortValue, sortDirection } = props;

  const active = sortValue === id;
  const cellSortDirection = active ? sortDirection : false;
  const labelSortDirection = active ? sortDirection : initialSort;

  function nextSortDirection() {
    if (active) {
      return sortDirection === SortDirections.Asc ? SortDirections.Desc : SortDirections.Asc;
    }
    return initialSort;
  }

  return (
    <TableCell
      align={align}
      key={id}
      sortDirection={cellSortDirection}
      style={{ width }}
      size="small"
    >
      <TableSortLabel
        active={active}
        direction={labelSortDirection}
        onClick={() => onSort(id, nextSortDirection())}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );
};

export default SortableHeadCell;
