import React, { FC, Fragment } from 'react';
import { darken } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

import * as paths from 'clientSuccess/paths';
import { useCognitoUser, useCurrentUser } from 'hooks';
import { useSetImpersonationMutation } from 'api';
import { Loaders } from 'components';
import theme from 'theme';

const styles = {
  container: {
    position: 'fixed',
    left: 10,
    bottom: 10,
  },
  button: {
    background: 'error.main',
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.1),
    },
  },
} as const;

const AccountMarker: FC = () => {
  const auth = useCognitoUser();
  const currentUser = useCurrentUser();
  const isImpersonating = auth.id !== currentUser.id;
  const { mutate, isLoading } = useSetImpersonationMutation();

  function handleClearImpersonation() {
    mutate(
      { id: auth.id, impersonate_user_id: null },
      {
        onSuccess: () =>
          location.assign(
            `${paths.userList}?client-id=${currentUser.client_id}&user-status=active`
          ),
      }
    );
  }

  if (!isImpersonating) {
    return null;
  }
  // Change favicon as an additional indicator to be careful
  const icon = document.querySelector('link[rel="shortcut icon"]');
  icon.setAttribute('href', icon.getAttribute('href').replace('favicon.ico', 'favicon-danger.png'));

  return (
    <Fragment>
      <Box sx={styles.container}>
        {isLoading ? (
          <Loaders.Standard height={24} />
        ) : (
          <Button
            onClick={handleClearImpersonation}
            variant="contained"
            color="primary"
            sx={styles.button}
          >
            Clear Impersonation
          </Button>
        )}
      </Box>
    </Fragment>
  );
};

export default AccountMarker;
