import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

import * as Loaders from '../Loaders';
import theme from 'theme';

const styles = {
  button: {
    display: 'flex',
    width: 180,
    padding: theme.spacing(1.5, 5),
    margin: `${4} auto`,
    border: `1px solid ${theme.palette.text.disabled}`,
    backgroundColor: 'common.white',
    color: 'text.primary',
    borderRadius: 'inherit',
    fontWeight: theme.typography.fontWeightRegular,

    '&:hover': {
      color: 'text.primary',
      backgroundColor: 'common.white',
    },
  },
} as const;

interface IProps {
  loading: boolean;
  onClick: () => void;
}

const LoadMoreButton: FC<IProps> = ({ loading, ...rest }) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <Button size="large" variant="contained" sx={styles.button}>
        <Loaders.Standard height={26} />
      </Button>
    );
  }

  return (
    <Button
      size="large"
      variant="contained"
      sx={styles.button}
      endIcon={<KeyboardArrowDown />}
      {...rest}
    >
      {t('components:loadMore')}
    </Button>
  );
};

export default LoadMoreButton;
