import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyTypeMap } from '@mui/material';

import { useContent } from 'hooks';

interface IProps {
  variant?: TypographyTypeMap['props']['variant'];
  color?: TypographyTypeMap['props']['color'];
}

const ReactionCount: FC<IProps> = ({ variant, color, ...rest }) => {
  const { reaction_count } = useContent();
  const { t } = useTranslation();

  return (
    <Typography variant={variant} color={color} {...rest}>
      {t('content:xLike', { count: reaction_count })}
    </Typography>
  );
};

ReactionCount.defaultProps = {
  variant: 'body1',
};

export default ReactionCount;
