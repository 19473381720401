import React, { FC, Fragment } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { LoaderComponents } from 'components';
import { times } from 'lodash';

import useStyles from '../analyticsStyles';

interface IProps {
  rows?: number;
  colSpan?: number;
}

const TableLoader: FC<IProps> = ({ rows = 5, colSpan = 5 }) => {
  const classes = useStyles;
  const { Content } = LoaderComponents;

  return (
    <Fragment>
      {times(rows, rowIndex => (
        <TableRow sx={classes.tableBodyRow} key={rowIndex}>
          <TableCell colSpan={colSpan}>
            <Content index={rowIndex} height={15} maxWidth={400} />
          </TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
};
export default TableLoader;
