import React, { FC } from 'react';
import { Typography } from '@mui/material';
import palette from '../theme/palette';

const styles = {
  safe: {
    color: 'grey.600',
  },
  warning: {
    color: 'warning.main',
  },
  error: {
    color: palette.red,
  },
} as const;

interface Props {
  length: number;
  limit: number;
}

const TextCounter: FC<Props> = ({ length, limit }) => {
  const difference = limit - length;
  const classes = {
    ...(difference >= 21 && styles.safe),
    ...(difference <= 20 && styles.warning),
    ...(difference < 0 && styles.error),
  };

  return (
    <Typography sx={classes} variant="overline">
      {length}/{limit}
    </Typography>
  );
};

export default TextCounter;
