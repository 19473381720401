import React, { FC, ReactNode } from 'react';

import { LoaderTypes } from 'types/app';
import { ClientUrlParamsFragment, useGetAllClientUrlParamsQuery } from 'api';
import { QueryManager } from 'components';

interface IProps {
  loader?: LoaderTypes;
  children: (data: ClientUrlParamsFragment[]) => ReactNode;
}

const GetClientUrlParams: FC<IProps> = ({ children, loader }) => {
  const queryResult = useGetAllClientUrlParamsQuery();

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.getAllClientUrlParams) {
          return null;
        }

        return children(data.getAllClientUrlParams);
      }}
    </QueryManager>
  );
};

export default GetClientUrlParams;
