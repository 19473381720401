import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import {
  AuthenticationType,
  ClientProtectedFragment,
  useCreateLoginTokenForCurrentUserInClientMutation,
} from 'api';
import { useNotify } from 'hooks';
import { Loaders, PaddedPaper } from 'components';
import { getSubdomainUrl } from 'helpers';
import * as paths from 'paths';
import cookieStorage from 'cookie-storage';

interface Props {
  client: ClientProtectedFragment;
  autoLaunch?: boolean;
}

const ExistingWorkspace: FC<Props> = ({ client, autoLaunch }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const url = getSubdomainUrl(client.subdomain);
  const [launchWindow, setLaunchWindow] = useState<Window>(null);

  const isSSOClient = client.authentication_type === AuthenticationType.Sso;

  const { mutate, isLoading } = useCreateLoginTokenForCurrentUserInClientMutation({
    onError: notify.mutationError,
    onSuccess: ({ createLoginTokenForCurrentUserInClient }) => {
      navigateToClient(createLoginTokenForCurrentUserInClient);
    },
  });

  useEffect(() => {
    if (launchWindow || autoLaunch) {
      // Don't waste time trying to generate a login token as the server will reject it anyway
      isSSOClient ? navigateToClient(null) : mutate({ client_id: client.id });
    }
  }, [launchWindow, autoLaunch]);

  function handleLaunchClick() {
    setLaunchWindow(window.open());
  }

  function navigateToClient(token?: string) {
    const targetWindow = launchWindow || window;
    let targetUrl = url;

    if (cookieStorage.shareUrl) {
      targetUrl = `${url}${paths.postCreate}`;
    }

    if (token) {
      targetUrl = `${targetUrl}?token=${token}`;
    }

    targetWindow.location.href = targetUrl;
    setLaunchWindow(null);
  }

  const buttonProps =
    client.authentication_type === AuthenticationType.Sso
      ? { target: '_blank', href: url }
      : { onClick: handleLaunchClick };

  return (
    <PaddedPaper sx={{ marginBottom: 2 }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid>
          <Typography variant="h6">{client.title}</Typography>
          <Typography color="textSecondary">{client.subdomain}</Typography>
        </Grid>
        <Grid>
          <Button
            sx={{ width: 100 }}
            size="large"
            variant="contained"
            color="primary"
            {...buttonProps}
          >
            {isLoading ? <Loaders.Standard height={26} width={60} /> : t('auth:workspaceButton')}
          </Button>
        </Grid>
      </Grid>
    </PaddedPaper>
  );
};

export default ExistingWorkspace;
