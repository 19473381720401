import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ValidatedCheckbox } from 'components';

import { usePostContext } from '../../PostContext';

const BrandedCheckbox: FC = () => {
  const { t } = useTranslation();
  const { state } = usePostContext();

  return (
    <ValidatedCheckbox
      disabled={state.brandedPost}
      name="is_branded"
      label={t('posting:branded')}
      color="primary"
    />
  );
};

export default BrandedCheckbox;
