import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { compact } from 'lodash';

import { ContentInput, GroupFragment, UserFeedFollowGroupFragment } from 'api';
import { useFollowed } from 'hooks';
import { GroupAvatar, PrivateGroupIcon } from 'components';
import { USER_FEED_ID } from 'app-constants';

const styles = {
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'text.primary',
    },
    '&$selected': {
      backgroundColor: 'transparent',
      color: 'text.primary',
      '&:focus': {
        backgroundColor: 'transparent',
        color: 'text.primary',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'text.primary',
      },
    },
  },
  selected: {},
  title: {
    maxWidth: 250,
  },
  privateIcon: {
    marginRight: 0.5,
  },
} as const;

interface Props {
  currentlySelected: string[];
}

const PinnedGroupDropdown: FC<Props> = ({ currentlySelected }) => {
  const { t } = useTranslation();
  const { values, setFieldValue, isSubmitting } = useFormikContext<ContentInput>();
  const { postable } = useFollowed();

  const { list } = postable;
  const { pinned_end_at } = values;
  const [pinOptions, setPinOptions] = useState({
    options: getOptions(),
    selectedOptions: currentlySelected,
  });

  useEffect(() => {
    setPinOptions({
      options: getOptions(),
      selectedOptions: pinOptions.selectedOptions.filter(group =>
        currentlySelected.includes(group)
      ),
    });
  }, [currentlySelected]);

  useEffect(() => {
    const selected = pinOptions.selectedOptions;
    const nextPinnedToGroups = selected.map(groupId => ({
      pinned_end_at,
      group_id: groupId,
    }));
    setFieldValue('pinned_to_groups', nextPinnedToGroups);
  }, [isSubmitting]);

  function getOptions() {
    return compact(
      currentlySelected.map(group => {
        if (group === USER_FEED_ID) {
          return false;
        }
        return list.find(item => item.id === group);
      })
    );
  }

  function handleGroupToggle(event: SelectChangeEvent<string[]>) {
    const value = event.target.value as unknown as string[];
    setPinOptions({ ...pinOptions, selectedOptions: value });
  }

  function isGroupSelected(group: UserFeedFollowGroupFragment) {
    return pinOptions.selectedOptions.includes(group.id);
  }

  function renderValue(value: string[]) {
    if (!value.length) {
      return t('posting:selectGroupPinning');
    }

    const matchingGroups = compact(
      value.map(id => {
        return list.find(item => item.id === id);
      })
    );

    if (!matchingGroups.length) {
      return t('posting:selectGroupPinning');
    }

    const leadingGroup = matchingGroups[0];
    const count = matchingGroups.length - 1;

    return (
      <Fragment>
        {leadingGroup.title} {!!count && `+${count}`}
      </Fragment>
    );
  }

  return (
    <Select
      variant="standard"
      disabled={!pinOptions.options.length}
      multiple
      displayEmpty
      renderValue={renderValue}
      value={pinOptions.selectedOptions}
      onChange={handleGroupToggle}
    >
      {pinOptions.options.map(group => (
        <MenuItem
          dense
          key={group.id}
          value={group.id}
          sx={{ root: styles.root, selected: styles.selected }}
        >
          <ListItemIcon>
            <GroupAvatar group={group as GroupFragment} />
          </ListItemIcon>
          {group.is_private && (
            <ListItemIcon sx={styles.privateIcon}>
              <PrivateGroupIcon group={group} type="list" />
            </ListItemIcon>
          )}
          <ListItemText disableTypography>
            <Typography title={group.title} noWrap sx={styles.title}>
              {group.title}
            </Typography>
          </ListItemText>
          <Checkbox checked={isGroupSelected(group)} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default PinnedGroupDropdown;
