import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';

import theme from 'theme';

interface Props {
  size?: number;
}

const styles = {
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -theme.spacing(1.5),
    marginLeft: -theme.spacing(1.5),
  },
} as const;

const ButtonLoader: FC<Props> = ({ size }) => {
  return <CircularProgress size={size} sx={styles.loader} />;
};

ButtonLoader.defaultProps = {
  size: 24,
};

export default ButtonLoader;
