import React, { FC } from 'react';

interface Props {
  transform: 'uppercase' | 'lowercase' | 'capitalize';
}

const TextTransform: FC<Props> = ({ children, transform }) => {
  return <span style={{ textTransform: transform }}>{children}</span>;
};

export default TextTransform;
