import React, { FC, FormEvent, Fragment, HTMLAttributes, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  lighten,
  ListSubheader,
  Portal,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { alpha } from '@mui/material/styles';
import { Formik, FormikProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { includes, orderBy } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';

import {
  ContentFragment,
  ContentInput,
  CreateGroupInput,
  CreateGroupMutation,
  Group,
  useCreateGroupMutation,
  UserFeedFollowGroupFragment,
} from 'api';
import { useCapabilities, useFollowed, useNotify, useProtectedClient } from 'hooks';
import { GroupAvatar, PrivateGroupIcon } from 'components';
import { getCreateGroupInitialValues } from 'features/groups/helpers';
import { useCreateGroup } from 'features/groups/updaters';
import theme from 'theme';

import { usePostContext } from '../../PostContext';
import { GroupCreateAlert } from './index';
import { CreateGroupDialogSchema } from '../../validations';

const styles = {
  tag: {
    padding: theme.spacing(0, 0.5, 0, 1),
  },
  doneButton: {
    padding: 0,
    minWidth: 'auto',
    '&:hover': {
      background: 'transparent',
      color: 'primary.main',
    },
  },
  listSubheader: {
    padding: theme.spacing(0.5, 1, 0, 1),
    textTransform: 'none',
  },
  divider: {
    margin: theme.spacing(0.5, 0),
  },
  createGroupButton: {
    backgroundColor: lighten(theme.palette.primary.main, 0.1),
    border: 'none',
    borderRadius: 0.5,
    padding: theme.spacing(1.5, 2),
    marginRight: 1.5,
  },
  listOption: {
    //paddingRight: 3,
    padding: theme.spacing(0.25, 0.5, 0.25, 0),
    cursor: 'pointer',
    margin: theme.spacing(1.5, 1.5),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      borderRadius: 8,
    },
  },
} as const;

const AutocompleteStyles = {
  '.MuiAutocomplete-paper': {
    margin: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  '.MuiAutocomplete-inputRoot': {
    backgroundColor: theme.palette.background.paper,
    flexWrap: 'nowrap',
  },
  '.MuiAutocomplete-input': {},
  '.MuiAutocomplete-popupIndicator': {},
  '.MuiAutocomplete-option': {
    padding: theme.spacing(0.5, 0, 0.5, 1),

    '&:hover, &[aria-selected="true"]': {
      background: 'transparent',
    },

    '& > button:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.4),
    },
  },
  '.MuiAutocomplete-listbox': {
    margin: 0,
    padding: 0,
    '& li[data-focus="true"]': {
      background: 'transparent',
    },
  },
} as const;

interface Props {
  disabled: boolean;
}

const filter = createFilterOptions();

const SingleGroupDropdown: FC<Props> = ({ disabled }) => {
  const { isAdmin, isModerator } = useCapabilities();
  const { allow_group_creation } = useProtectedClient();

  const { t } = useTranslation();
  const { state, dispatch } = usePostContext();
  const { postable, moderated, owned, followedOnly, requiresApprovalGroups } = useFollowed();
  const notify = useNotify();
  const { values, setFieldValue } = useFormikContext<ContentInput | ContentFragment>();

  const createGroupFormikRef = useRef<FormikProps<CreateGroupInput>>(
    {} as FormikProps<CreateGroupInput>
  );

  const { recent, list } = postable;

  const [isOpen, setIsOpen] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [sortedGroups, setSortedGroups] = useState(getSortedGroups());
  const [renderGroupCreateModal, setRenderGroupCreatModal] = useState(false);
  const selectedGroup: UserFeedFollowGroupFragment | null = getSelectedGroup();

  const createGroupData = {
    id: 'create-group',
    title: t('groups:createGroup'),
    color_map: {
      label: '',
      hex: '',
    },
    allow_user_posts: false,
    is_private: false,
  };

  useEffect(() => {
    setSortedGroups(getSortedGroups());
  }, [list]);

  useEffect(() => {
    if (!selectedGroup?.id) {
      return;
    }
    const isModeratingGroup = [...moderated, ...owned].filter(
      group => group.id === selectedGroup.id
    ).length;
    const isNeedApprovalGroup = requiresApprovalGroups.filter(
      group => group.id === selectedGroup.id
    ).length;
    const isUserOnlyGroups = followedOnly.filter(group => group.id === selectedGroup.id).length;

    const isModeratorOrAdmin = !!isModeratingGroup || isAdmin;
    const requiresApproval = !!isNeedApprovalGroup;

    dispatch({ type: 'moderatorMode', value: isModeratorOrAdmin });
    dispatch({ type: 'requiresApproval', value: requiresApproval });

    dispatch({
      type: 'showShareWidget',
      value: isModeratorOrAdmin || (!!isUserOnlyGroups && !requiresApproval),
    });
  }, [selectedGroup]);

  const createGroupMutationOptions = useCreateGroup();
  const { mutate: createGroupMutation } = useCreateGroupMutation({
    ...createGroupMutationOptions,
    onError: handleCreateGroupError,
    onSuccess: handleCompleted,
  });

  function handleCreateGroupError(error: Error) {
    setRenderGroupCreatModal(false);

    if (error.message.includes('duplicate')) {
      notify.error({ i18n: t('groups:groupExistErrorMessage') });
    }

    notify.mutationError();
  }

  function handleCompleted({ createGroup }: CreateGroupMutation) {
    setRenderGroupCreatModal(false);

    const message = t('groups:createGroupSuccess', { name: createGroup.title });

    notify.info({ message });

    setFieldValue('primary_group_id', createGroup.id);
  }

  function isGroupSelected(group: UserFeedFollowGroupFragment) {
    return selectedGroup?.id === group.id;
  }

  function getGroupsForModerator() {
    if (!state.repost) {
      return list;
    }
    return state.moderatorMode ? [...moderated, ...owned] : list;
  }

  function getSortedGroups() {
    let nextSortedGroups = list;

    if (isModerator && !isAdmin) {
      nextSortedGroups = getGroupsForModerator();
    }

    return orderBy(nextSortedGroups, group => values.group_ids[0] === group.id, ['desc']);
  }

  /**
   * Legacy Single Posts when edited and Unpublished Multi Posts will not have a primary group set.
   * We need to choose the first item of the group_ids returned in these cases as a fallback
   */
  function getSelectedGroup(): UserFeedFollowGroupFragment | null {
    const hasPrimaryGroup = sortedGroups.find(group => group.id === values.primary_group_id);
    const getFirstGroup = sortedGroups.find(group => group.id === values.group_ids[0]);

    return hasPrimaryGroup || getFirstGroup;
  }

  function getPlaceholder() {
    if (isOpen) {
      return t('posting:searchGroups');
    }

    if (!selectedGroup?.id) {
      return t('posting:selectGroup');
    }

    return '';
  }

  function onToggleCreateGroupDialog() {
    setRenderGroupCreatModal(!renderGroupCreateModal);
  }

  function onClickToggle() {
    setIsOpen(!isOpen);
    setInputValue('');
    setSortedGroups(getSortedGroups());
  }

  function onClickGroupItem(group: UserFeedFollowGroupFragment) {
    setIsOpen(!isOpen);
    setFieldValue('primary_group_id', group.id);
  }

  function onSubmitCreateGroup() {
    setRenderGroupCreatModal(false);
    const { title } = createGroupFormikRef.current.values;

    if (!title) {
      return;
    }

    // TODO: This is a hack that needs to be fixed
    const initialValues = getCreateGroupInitialValues();
    initialValues.title = title;

    createGroupMutation({ data: initialValues });
  }

  function renderAvatar(group: UserFeedFollowGroupFragment) {
    return <GroupAvatar group={group as Group} />;
  }

  function renderCreateGroupOption() {
    const onClick = () => {
      createGroupFormikRef.current.values.title = inputValue;
      const groupTitles = list.map(item => item.title);
      if (inputValue !== '' && !includes(groupTitles, inputValue)) {
        return onSubmitCreateGroup();
      }
      return setRenderGroupCreatModal(true);
    };

    return (
      <Button
        key="create"
        color="primary"
        variant="contained"
        size="large"
        onClick={onClick}
        fullWidth
      >
        <Typography variant="body2">
          {inputValue ? `${t('groups:createGroup')}: "${inputValue}"` : t('groups:createGroup')}
        </Typography>
      </Button>
    );
  }

  function renderOptions(props: HTMLAttributes<HTMLLIElement>, group: UserFeedFollowGroupFragment) {
    const isSelected = isGroupSelected(group);

    if (group.id === 'create-group') {
      if (!isAdmin && !allow_group_creation) {
        return null;
      }
      return renderCreateGroupOption();
    }

    return (
      <Grid
        data-testid="dropdownOption"
        key={group.id}
        container
        alignItems="center"
        onClick={() => onClickGroupItem(group)}
        sx={styles.listOption}
      >
        <Grid xs={2}>{renderAvatar(group)}</Grid>
        <Grid xs={9} container>
          {group.is_private && (
            <Box mr={0.5}>
              <PrivateGroupIcon group={group} type="list" />
            </Box>
          )}
          <Grid xs>
            <Typography title={group.title} noWrap variant={isSelected ? 'body2' : 'body1'}>
              {group.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    let { startAdornment, endAdornment } = params.InputProps;
    if (isOpen) {
      startAdornment = <SearchIcon />;
      endAdornment = (
        <Button sx={styles.doneButton} onClick={onClickToggle} fullWidth={false}>
          {t('common:done')}
        </Button>
      );
    }

    return (
      <TextField
        {...params}
        variant="outlined"
        placeholder={getPlaceholder()}
        fullWidth
        InputProps={{
          ...params.InputProps,
          sx: {
            input: {
              color: values.primary_group_id ? 'common.black' : 'primary.main',
              '&::placeholder': {
                opacity: 1,
              },
              fontWeight: values.primary_group_id
                ? theme.typography.fontWeightBold
                : theme.typography.fontWeightRegular,
            },
          },
          startAdornment,
          endAdornment,
        }}
      />
    );
  }

  function groupBy(group: UserFeedFollowGroupFragment) {
    const isSelected = group.id === selectedGroup?.id;
    const isRecentGroup = recent.includes(group);

    if (isSelected) {
      return t('posting:postingTo');
    }

    if (isRecentGroup) {
      return t('posting:recentGroups');
    }

    return t('posting:otherGroups');
  }

  function renderGroup(params: AutocompleteRenderGroupParams) {
    return (
      <Box key={params.key}>
        <ListSubheader disableSticky sx={styles.listSubheader}>
          {params.group}
        </ListSubheader>
        {params.children}
        <Divider sx={styles.divider} />
      </Box>
    );
  }

  return (
    <Fragment>
      <Autocomplete
        sx={AutocompleteStyles}
        open={isOpen}
        onOpen={onClickToggle}
        onClose={onClickToggle}
        openOnFocus
        fullWidth
        freeSolo
        disableClearable
        disabled={disabled}
        forcePopupIcon={!isOpen}
        options={sortedGroups}
        getOptionLabel={(group: UserFeedFollowGroupFragment | string) => {
          if (typeof group === 'string') {
            return group;
          } else {
            return group?.title || '';
          }
        }}
        renderOption={renderOptions}
        renderInput={renderInput}
        value={selectedGroup || null}
        groupBy={groupBy}
        renderGroup={renderGroup}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return [...filtered, createGroupData] as UserFeedFollowGroupFragment[];
        }}
      />

      <Portal>
        <Formik
          validateOnMount
          initialValues={getCreateGroupInitialValues()}
          onSubmit={onSubmitCreateGroup}
          validationSchema={CreateGroupDialogSchema(list)}
          innerRef={ref => (createGroupFormikRef.current = ref)}
        >
          {() => (
            <form
              onSubmit={(event: FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                return false;
              }}
            >
              <GroupCreateAlert
                isOpen={renderGroupCreateModal}
                onCancel={onToggleCreateGroupDialog}
              />
            </form>
          )}
        </Formik>
      </Portal>
    </Fragment>
  );
};

export default SingleGroupDropdown;
