import { useQueryClient, UseMutationOptions, InfiniteData } from 'react-query';
import produce from 'immer';

import {
  useGetFollowedQuery,
  FollowMutation,
  FollowMutationVariables,
  GetFollowedQuery,
  GroupRole,
  UserFeedFollowFragment,
  FeedKey,
  UserFeedFollowGroupFragment,
  PublicUserProfileFragment,
} from 'api';
import { useCurrentUser, useNotify } from 'hooks';
import { FOLLOW_QUERIES_LIMIT } from 'app-constants';

type Mutation = FollowMutation;
type MutationVariables = FollowMutationVariables;
type QueryData = InfiniteData<GetFollowedQuery>;

type FollowSource = UserFeedFollowGroupFragment | PublicUserProfileFragment;
type FollowType = FeedKey.Group | FeedKey.User;

interface IOptions {
  type: FollowType;
  source: FollowSource;
}

const useFollow = ({ type, source }: IOptions) => {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const notify = useNotify();

  const queryKey = useGetFollowedQuery.getKey({
    data: { user_id: currentUser.id, limit: FOLLOW_QUERIES_LIMIT },
  });
  const nullType = type === FeedKey.User ? 'group' : 'user';

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async mutationArgs => {
      await queryClient.cancelQueries(queryKey);

      const newFollow: UserFeedFollowFragment = {
        created_at: -1,
        [nullType]: null,
        [type]: source,
        feed: type,
        postable: false,
        required: false,
        group_role: GroupRole.Member,
        followed_by_user: null,
        user_id: currentUser.id,
        ...mutationArgs,
      };

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.pages[0].getPaginatedUserFeedFollows.feed_follows.unshift(newFollow);
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useFollow;
