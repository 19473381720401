import React, { FC } from 'react';
import { TooltipProps } from 'recharts';
import { Grid, Typography } from '@mui/material';

import { PaddedPaper } from 'components';
import { formatStat } from 'helpers';

import moment from 'moment';

const ROITooltip: FC<TooltipProps<number, string>> = props => {
  const { active, payload } = props;

  if (!active || !payload || payload.length == 0) {
    return null;
  }

  const current_payload = payload[0].payload;

  return (
    <PaddedPaper>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Grid container>
            <Grid>
              <svg height="25" width="25">
                <circle cx="10" cy="10" r="5" fill="#389AFF" />
              </svg>
            </Grid>
            <Grid>
              <Typography color="textSecondary">
                {moment(current_payload.calendar_day).format('ll')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Typography variant="body2">
            ${formatStat(current_payload.currency as number, '')}
          </Typography>
        </Grid>
        <Grid>
          <Grid container>
            <Grid>
              <svg height="25" width="25">
                <circle cx="10" cy="10" r="5" stroke="#389AFF" strokeWidth="2" fill="white" />
              </svg>
            </Grid>
            <Grid>
              <Typography color="textSecondary">
                {moment(current_payload.previous_calendar_day).format('ll')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Typography variant="body2">
            ${formatStat(current_payload.previous_currency as number, '')}
          </Typography>
        </Grid>
      </Grid>
    </PaddedPaper>
  );
};

export default ROITooltip;
