import React, { FC, useEffect, Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Notify, ScrollToTop } from 'components';

import * as paths from 'paths';

import { GlobalClientProvider } from 'providers';
import TokenCheck from 'features/auth/containers/TokenCheck';

const SSO_ERROR_PREFIX = 'Error in SAML response processing: PreSignUp failed with error ';

/*
 * The shared layout for all routes
 */
export const GlobalLayout: FC = ({ children }) => {
  return (
    <Fragment>
      <ScrollToTop />
      <Notify />
      {children}
    </Fragment>
  );
};

/*
 * Shared layout for all subdomain routes
 */
export const SubdomainLayout: FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ssoError = urlParams.get('error_description');
    if (ssoError) {
      let authError = ssoError;
      if (authError.startsWith(SSO_ERROR_PREFIX)) {
        authError = authError.split(SSO_ERROR_PREFIX)[1];
        authError = authError.substring(0, authError.length - 2);
      }
      sessionStorage.setItem('authError', authError);
      navigate(paths.authError, { replace: true });
    }
  }, []);

  useEffect(() => {
    window?.analytics?.page();
  }, [location]);

  return (
    <GlobalClientProvider>
      <TokenCheck>
        <Fragment>{children}</Fragment>
      </TokenCheck>
    </GlobalClientProvider>
  );
};
