import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Unstable_Grid2 as Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PAGINATION_LIMIT } from 'features/admin/constants';
import useSearchClientBillingReportAggregate from 'clientSuccess/hooks/useSearchClientBillingReportAggregate';
import ReportTable from 'clientSuccess/components/ClientBillingReportAggregate/ReportTable';
import moment from 'moment';
import { useGenerateClientBillingReportAggregateDownloadMutation } from 'api';
import { useNotify } from 'hooks';

const ClientList: FC = () => {
  const [minPercentage, setMinPercentage] = useState('');
  const [maxPercentage, setMaxPercentage] = useState('');
  const [variables, setVariables] = useState({
    search_filter: {
      calendar_month__eq: moment().format('YYYY-MM'),
      client_subdomain__contains: null,
      usage_rate__gte: null,
      usage_rate__lte: null,
    },
    limit: PAGINATION_LIMIT,
    count: true,
  });
  const { t } = useTranslation();
  const { isExhausted, isInitialLoad, results, count, sentinelRef } =
    useSearchClientBillingReportAggregate(variables);
  useEffect(() => {
    const minUsageRate = minPercentage ? parseFloat(minPercentage) / 100 : null;
    const maxUsageRate = maxPercentage ? parseFloat(maxPercentage) / 100 : null;
    setVariables({
      ...variables,
      search_filter: {
        ...variables.search_filter,
        usage_rate__gte: minUsageRate,
        usage_rate__lte: maxUsageRate,
      },
    });
  }, [minPercentage, maxPercentage]);
  const notify = useNotify();
  const { mutate: downloadReport } = useGenerateClientBillingReportAggregateDownloadMutation({
    onError: notify.mutationError,
  });

  function handleDownload() {
    notify.info({ message: 'Preparing report...' });
    downloadReport({ search_filter: variables.search_filter });
  }

  function renderMonthSelect() {
    const months = [];
    const current = moment();
    for (let i = 0; i < 24; i++) {
      months.push(current.format('YYYY-MM'));
      current.add(-1, 'month');
    }
    return (
      <Select
        variant="standard"
        value={variables.search_filter.calendar_month__eq}
        onChange={event =>
          setVariables({
            ...variables,
            search_filter: {
              ...variables.search_filter,
              calendar_month__eq: event.target.value,
            },
          })
        }
      >
        {months.map(m => (
          <MenuItem key={m} value={m}>
            {m}
          </MenuItem>
        ))}
      </Select>
    );
  }

  function renderHeader() {
    return (
      <Box p={1}>
        <Grid container alignItems="center" spacing={2}>
          <Grid>{renderMonthSelect()}</Grid>
          <Grid>
            <TextField
              variant="outlined"
              sx={{ Input: { padding: 1 } }}
              placeholder="Client Subdomain"
              value={variables.search_filter.client_subdomain__contains}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setVariables({
                  ...variables,
                  search_filter: {
                    ...variables.search_filter,
                    client_subdomain__contains: event.target.value || null,
                  },
                });
              }}
            />
          </Grid>
          <Grid>
            <TextField
              variant="outlined"
              sx={{ Input: { padding: 1 } }}
              placeholder="Min Percentage Usage"
              value={minPercentage}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setMinPercentage(event.target.value)
              }
            />
          </Grid>
          <Grid>
            <TextField
              variant="outlined"
              sx={{ Input: { padding: 1 } }}
              placeholder="Max Percentage Usage"
              value={maxPercentage}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setMaxPercentage(event.target.value)
              }
            />
          </Grid>
          <Grid xs>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              <Grid>
                <Box pr={2}>
                  {!isInitialLoad && (
                    <Typography variant="body2">{t('components:xResult', { count })}</Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Button variant="contained" color="primary" onClick={handleDownload}>
              Download
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderMain() {
    return (
      <Paper>
        {renderHeader()}
        <Divider />
        <ReportTable
          results={results}
          isExhausted={isExhausted}
          isInitialLoad={isInitialLoad}
          sentinelRef={sentinelRef}
        />
      </Paper>
    );
  }

  return <Box p={2}>{renderMain()}</Box>;
};

export default ClientList;
