import React, { FC, useEffect } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { Box, Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  useCreateLoginTokenForCurrentUserInClientMutation,
  useCreateReadmeLoginJwtMutation,
} from 'api';
import { useCognitoUser, useCurrentUser, useNotify } from 'hooks';
import { README_SUCCESS_MESSAGE } from 'app-constants';
import { Loaders } from 'components';
import { AUTH_DOMAIN } from 'settings';
import { queryString } from 'utilities';
import * as paths from 'paths';

type ConnectParams = { network: string };

const ConnectNetwork: FC = () => {
  const { network } = useParams<ConnectParams>();
  const currentUser = useCurrentUser();
  const auth = useCognitoUser();
  const isImpersonatingUser = auth.id !== currentUser.id;
  const notify = useNotify();

  const { mutate: createLoginToken } = useCreateLoginTokenForCurrentUserInClientMutation({
    onError: notify.mutationError,
    onSuccess: ({ createLoginTokenForCurrentUserInClient }) => {
      const params = {
        user_id: currentUser.id,
        token: createLoginTokenForCurrentUserInClient,
        success_callback_url: `${window.location.origin}${paths.connectionSuccess}`,
        failure_callback_url: `${window.location.origin}${paths.connectionFailure}`,
        source: 'web sharing',
      };

      const url = `${AUTH_DOMAIN}/connect/${network}?${queryString(params)}`;
      location.replace(url);
    },
  });

  /**
   * Readme.com is our external developer documentation portal. We're simply leveraging
   * our frontend integration infrastructure to allow users to log in to Readme.com.
   * Unfortunately, at this point, Readme doesn't support OAuth2, so we're using JWTs
   * to authenticate. When Readme supports OAuth2, we'll do the connection on the backend
   */
  const { mutate: createReadmeToken } = useCreateReadmeLoginJwtMutation({
    onError: notify.mutationError,
    onSuccess: ({ createReadmeLoginJWT }) => {
      const { jwt } = createReadmeLoginJWT;
      window?.opener?.postMessage({ token: jwt, message: README_SUCCESS_MESSAGE }, '*');
      window.close();
    },
  });

  useEffect(() => {
    if (isImpersonatingUser) {
      return;
    }

    if (network === 'readme') {
      createReadmeToken({});
      return;
    }

    createLoginToken({ client_id: currentUser.client_id });
  }, [network]);

  if (isImpersonatingUser) {
    return (
      <Grid container direction="column" alignItems="center">
        <Grid>
          <Box p={2}>
            <Typography>
              Connecting Social Accounts is not supported in impersonation mode.
            </Typography>
          </Box>
        </Grid>
        <Grid>
          <Button color="primary" variant="contained" onClick={() => window.close()}>
            Close Window
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box height="100vh">
      <Loaders.Standard />
    </Box>
  );
};

export default ConnectNetwork;
