import React, { FC, ReactNode } from 'react';
import { List, Typography } from '@mui/material';

import PaddedPaper from './PaddedPaper';

interface Props {
  title: ReactNode;
}

const ListWidget: FC<Props> = ({ title, children }) => {
  return (
    <PaddedPaper padding="24px 12px">
      <Typography paragraph color="textSecondary" variant="subtitle2">
        {title}
      </Typography>
      <List>{children}</List>
    </PaddedPaper>
  );
};

export default ListWidget;
