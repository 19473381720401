import React, { FC } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';

import { ContentInput } from 'api';

import { GroupDropdown } from './index';

const GroupSelection: FC = () => {
  const { values } = useFormikContext<ContentInput>();

  const { group_ids } = values;

  function omitPrimaryGroup(groupIds: string[]) {
    return groupIds.filter(group => group !== values.primary_group_id);
  }

  return (
    <Grid container>
      <FieldArray name="group_ids">
        {helpers => (
          <GroupDropdown
            selectedIds={omitPrimaryGroup(group_ids)}
            helpers={helpers}
            primaryGroupId={values.primary_group_id}
          />
        )}
      </FieldArray>
    </Grid>
  );
};

export default GroupSelection;
