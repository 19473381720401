import React, { FC, Fragment, lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { Loaders } from 'components';

import { useCurrentUser } from 'hooks';
import { isClientSuccessUser } from 'helpers';
import * as appPaths from 'paths';

const LazyBar = lazy(() => import('clientSuccess/components/Bar'));
const LazyController = lazy(() => import('clientSuccess/Controller'));

export const Bar = () => (
  <Suspense fallback={<Loaders.Standard />}>
    <LazyBar />
  </Suspense>
);

export const ClientSuccessController = () => (
  <Suspense fallback={<Loaders.Standard />}>
    <LazyController />
  </Suspense>
);

const Routes: FC = () => {
  const currentUser = useCurrentUser();

  if (!isClientSuccessUser(currentUser)) {
    return <Navigate to={appPaths.index} replace />;
  }

  return (
    <Fragment>
      <Bar />
      <ClientSuccessController />
    </Fragment>
  );
};

export default Routes;
