import useInfiniteScroll from 'react-infinite-scroll-hook';
import { UseInfiniteQueryOptions } from 'react-query';

import {
  GetUserSearchesV2Query,
  GetUserSearchesV2QueryVariables,
  useGetUserSearchesV2Query,
  UserProfileSearchFragment,
} from 'api';
import { InfiniteQuery } from 'types/app';
import { useInfiniteQuery } from 'app-react-query/hooks';
import { useNotify } from 'hooks';
import { PAGINATION_ROOT_MARGIN } from 'app-constants';

type Query = GetUserSearchesV2Query;
type Variables = GetUserSearchesV2QueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = InfiniteQuery<UserProfileSearchFragment>;

const useGetUserSearchesV2 = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();

  const queryResult = useInfiniteQuery<Query, Variables>(
    useGetUserSearchesV2Query,
    ({ pageParam = '' }: { pageParam: string }) => ({ ...variables, next_id: pageParam }),
    {
      initialData: {
        pages: [{ getUserSearchesV2: { total_count: 0, results: [] } }],
        pageParams: [],
      },
      onError: notify.queryError,
      getNextPageParam: lastPage => {
        const { getUserSearchesV2 } = lastPage;
        const endOfList = !getUserSearchesV2.next_id;
        const noResults = !getUserSearchesV2.results.length;

        if (noResults || endOfList) {
          return false;
        }

        return getUserSearchesV2.next_id;
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = queryResult;
  const results = data?.pages?.map(page => page.getUserSearchesV2.results).flat() ?? [];
  const totalCount = data?.pages?.[0]?.getUserSearchesV2.total_count ?? 0;

  const [sentinelRef] = useInfiniteScroll({
    hasNextPage,
    loading: isFetchingNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: PAGINATION_ROOT_MARGIN,
  });

  return {
    sentinelRef,
    results,
    count: totalCount,
    isEmpty: !results.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !results.length && isFetching,
  };
};

export default useGetUserSearchesV2;
