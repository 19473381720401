import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Input, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';

import { StatsGrouping } from 'api';
import { IVariables } from './types';

interface IProps extends WithStyles<typeof styles> {
  variables: IVariables;
  onChange: (variables: IVariables) => void;
}

const styles = () =>
  createStyles({
    input: {
      color: 'inherit',
      backgroundColor: 'transparent',
    },
    icon: {
      color: 'inherit',
    },
  });

const PeriodSelect: FC<IProps> = ({ classes, variables, onChange }) => {
  function getValue(grouping: StatsGrouping) {
    switch (grouping) {
      case StatsGrouping.Week:
        return moment().week();
      case StatsGrouping.Month:
        return moment().month() + 1;
      case StatsGrouping.Year:
        return moment().year();
    }
  }

  function handleChange(event: SelectChangeEvent<StatsGrouping>) {
    const grouping = event.target.value as StatsGrouping;

    onChange({
      grouping,
      value: getValue(grouping),
    });
  }

  return (
    <Select
      variant="standard"
      input={<Input className={classes.input} />}
      classes={{ icon: classes.icon }}
      value={variables.grouping}
      onChange={handleChange}
    >
      <MenuItem value={StatsGrouping.Week}>
        <Trans i18nKey="common:week" />
      </MenuItem>
      <MenuItem value={StatsGrouping.Month}>
        <Trans i18nKey="common:month" />
      </MenuItem>
      <MenuItem value={StatsGrouping.Year}>
        <Trans i18nKey="common:year" />
      </MenuItem>
    </Select>
  );
};

export default withStyles(styles)(PeriodSelect);
