import React, { FC } from 'react';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { Box } from '@mui/material';
import theme from 'theme';

const WIDTH = 300;
const BUTTON_WIDTH = 40;

const styles = {
  root: {
    '.flatpickr-input': {
      display: 'none',
    },
    '.flatpickr-calendar': {
      width: WIDTH,
      fontFamily: theme.typography.fontFamily,
      borderRadius: 0,
      boxShadow: 'none',
      '&.inline': {
        top: 0,
      },
    },
    '.flatpickr-months': {
      '& .flatpickr-month': {
        fill: 'common.white',
      },
      '& .flatpickr-prev-month, & .flatpickr-next-month': {
        '&:hover svg': {
          fill: 'primary.main',
        },
      },
    },
    '.flatpickr-current-month': {
      '& .flatpickr-monthDropdown-months': {
        marginLeft: -theme.spacing(),
      },
      '& .numInputWrapper': {
        marginLeft: 2,
      },
    },
    '.flatpickr-days': {
      width: WIDTH,

      '& .dayContainer': {
        width: WIDTH,
        minWidth: WIDTH,
        maxWidth: WIDTH,
      },
    },
    '.flatpickr-day': {
      maxWidth: BUTTON_WIDTH,
      height: BUTTON_WIDTH,
      lineHeight: `${BUTTON_WIDTH}px`,

      '&.today': {
        backgroundColor: 'transparent',
        borderColor: 'primary.main',
        color: 'inherit',

        '&:hover, &:focus': {
          backgroundColor: 'primary.light',
          borderColor: 'primary.main',
          color: 'inherit',
        },
      },

      '&.prevMonthDay, &.nextMonthDay': {
        '&.inRange': {
          backgroundColor: 'secondary.main',
          borderColor: 'secondary.main',
          boxShadow: `-5px 0 0 ${theme.palette.secondary.main}, 5px 0 0 ${theme.palette.secondary.main}`,
          '-webkit-box-shadow': `-5px 0 0 ${theme.palette.secondary.main}, 5px 0 0 ${theme.palette.secondary.main}`,
        },
        '&.selected, &.endRange': {
          backgroundColor: 'primary.main',
          borderColor: 'primary.main',
          boxShadow: 'none',
        },
      },

      '&.inRange': {
        backgroundColor: 'secondary.main',
        borderColor: 'secondary.main',
        boxShadow: `-5px 0 0 ${theme.palette.secondary.main}, 5px 0 0 ${theme.palette.secondary.main}`,
        '-webkit-box-shadow': `-5px 0 0 ${theme.palette.secondary.main}, 5px 0 0 ${theme.palette.secondary.main}`,
      },

      '&.selected, &.startRange, &.endRange': {
        backgroundColor: 'primary.main',
        borderColor: 'primary.main',

        '&.today': {
          backgroundColor: 'primary.main',
          color: 'common.white',

          '&:hover, &:focus': {
            backgroundColor: 'primary.main',
            color: 'common.white',
          },
        },

        '&:hover, &:focus': {
          backgroundColor: 'primary.main',
          borderColor: 'primary.main',
          color: 'common.white',
        },
      },
    },
  },
} as const;

const FlatpickrWrapper: FC<DateTimePickerProps> = (props: DateTimePickerProps) => {
  return (
    <Box component="span" sx={styles.root}>
      <Flatpickr {...props} />
    </Box>
  );
};

export default FlatpickrWrapper;
