import useInfiniteScroll from 'react-infinite-scroll-hook';
import { UseInfiniteQueryOptions } from 'react-query';

import {
  ContentEmailSpecFragment,
  GetContentEmailSpecsQuery,
  GetContentEmailSpecsQueryVariables,
  useGetContentEmailSpecsQuery,
} from 'api';
import { InfiniteQuery } from 'types/app';
import { useInfiniteQuery } from 'app-react-query/hooks';
import { useNotify } from 'hooks';
import { PAGINATION_ROOT_MARGIN } from 'app-constants';

type Query = GetContentEmailSpecsQuery;
type Variables = GetContentEmailSpecsQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = InfiniteQuery<ContentEmailSpecFragment>;

const useContentEmailSpecs = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();
  const queryResult = useInfiniteQuery<Query, Variables>(
    useGetContentEmailSpecsQuery,
    ({ pageParam }: { pageParam: Variables }) => ({ data: { ...variables.data, ...pageParam } }),
    {
      initialData: {
        pages: [{ getContentEmailSpecs: { items: [], paginate: true } }],
        pageParams: [],
      },
      onError: notify.queryError,
      getNextPageParam: lastPage => {
        const { getContentEmailSpecs } = lastPage;
        const { items, id, created_at } = getContentEmailSpecs;

        const endOfList = !id && !created_at;
        const noResults = !items.length;

        if (noResults || endOfList) {
          return false;
        }

        return { id, created_at };
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = queryResult;
  const results = data?.pages?.map(page => page.getContentEmailSpecs.items).flat() ?? [];

  const [sentinelRef] = useInfiniteScroll({
    hasNextPage,
    loading: isFetchingNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: PAGINATION_ROOT_MARGIN,
  });

  return {
    sentinelRef,
    results,
    count: null,
    isEmpty: !results.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !results.length && isFetching,
  };
};

export default useContentEmailSpecs;
