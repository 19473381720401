import React, { FC } from 'react';
import { Box, Unstable_Grid2 as Grid, useTheme } from '@mui/material';

const styles = {
  '@global body': {
    backgroundColor: '#FBFBFB',
  },
  animation: {
    animation: 'animation-shimmering 1.5s linear infinite',
    backgroundColor: 'grey.100',
    backgroundImage: `linear-gradient(-90deg, ${'grey.100'} 0%, ${'grey.200'} 50%, ${'grey.100'} 100%)`,
    backgroundPosition: '-300px 0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '300px 100%',
  },
  avatar: {
    width: 100,
    height: 100,
  },
  icon: {
    width: 24,
    height: 24,
  },
} as const;

const PartnerShareLoader: FC = () => {
  const theme = useTheme();

  function renderPreview() {
    return (
      <Grid container>
        <Grid>
          <Grid>
            <Box sx={{ ...styles.avatar, ...styles.animation }} />
          </Grid>
        </Grid>
        <Grid xs>
          <Box marginY={2} marginX={2} height={40} sx={styles.animation} />
          <Box marginX={2} height={16} sx={styles.animation} />
        </Grid>
      </Grid>
    );
  }

  function renderNetwork() {
    return (
      <Box padding={3}>
        <Grid container alignItems="center" spacing={2}>
          <Grid>
            <Box sx={{ ...styles.icon, ...styles.animation }} />
          </Grid>
          <Grid xs>
            <Box height={24} width={300} sx={styles.animation} />
          </Grid>
          <Grid>
            <Box height={24} width={100} sx={styles.animation} />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box padding={2}>
      <Grid container direction="column" spacing={3}>
        <Grid>
          <Box bgcolor={theme.palette.common.white}>{renderPreview()}</Box>
        </Grid>
        <Grid>
          <Box bgcolor={theme.palette.common.white}>{renderNetwork()}</Box>
        </Grid>
        <Grid>
          <Box bgcolor={theme.palette.common.white}>{renderNetwork()}</Box>
        </Grid>
        <Grid>
          <Box bgcolor={theme.palette.common.white}>{renderNetwork()}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartnerShareLoader;
