import React, { FC, useState } from 'react';
import { Box, Button, Unstable_Grid2 as Grid, TextField } from '@mui/material';
import {
  ClientDepartment,
  ClientLocation,
  useGetClientDepartmentsByStatusQuery,
  useGetClientLocationsByStatusQuery,
  useGetUserMappingsForClientQuery,
  UserMappings,
  useUpdateUserMappingsForClientMutation,
} from 'api';
import { useNotify } from 'hooks';
import { Loaders } from 'components';

interface IProps {
  client_id: string;
  onCompleted: () => void;
}

interface Mapping {
  id: string;
  title: string;
  value: string;
}

const AttrMappingForm: FC<IProps> = ({ client_id, onCompleted }) => {
  const [userMappings, setUserMappings] = useState<UserMappings>(null);
  const [userTypeMappings, setUserTypeMappings] = useState<Mapping[]>(null);
  const [departmentMappings, setDepartmentMappings] = useState<Mapping[]>(null);
  const [locationMappings, setLocationMappings] = useState<Mapping[]>(null);
  const notify = useNotify();
  useGetUserMappingsForClientQuery(
    { client_id },
    {
      onError: notify.queryError,
      onSuccess: data => {
        const { getUserMappingsForClient } = data;
        setUserMappings(getUserMappingsForClient);
        const userTypeMappingObj = getUserMappingsForClient.external_user_mappings.user_type || [];
        setUserTypeMappings(
          ['user', 'contributor', 'admin'].map(type => {
            return {
              id: type,
              title: type,
              value: userTypeMappingObj.find(m => m.internal == type)?.external || '',
            };
          })
        );
        mergeDepartments(
          getUserMappingsForClient,
          departmentsData?.getClientDepartmentsByStatus?.client_departments
        );
        mergeLocations(
          getUserMappingsForClient,
          locationsData?.getClientLocationsByStatus?.client_locations
        );
      },
    }
  );
  const { data: departmentsData } = useGetClientDepartmentsByStatusQuery(
    { override: { client_id } },
    {
      onError: notify.queryError,
      onSuccess: data =>
        mergeDepartments(userMappings, data.getClientDepartmentsByStatus.client_departments),
    }
  );
  const { data: locationsData } = useGetClientLocationsByStatusQuery(
    { override: { client_id } },
    {
      onError: notify.queryError,
      onSuccess: data =>
        mergeLocations(userMappings, data.getClientLocationsByStatus.client_locations),
    }
  );
  const { mutate, isLoading: mutationLoading } = useUpdateUserMappingsForClientMutation({
    onError: notify.mutationError,
    onSuccess: () => onCompleted(),
  });

  function mergeDepartments(userMappings: UserMappings, departments: ClientDepartment[]) {
    if (!(userMappings && departments)) {
      return;
    }
    const mappedDepartments = userMappings.external_user_mappings.department || [];
    setDepartmentMappings(
      departments.map(department => {
        return {
          id: department.id,
          title: department.title,
          value: mappedDepartments.find(d => d.internal == department.id)?.external || '',
        };
      })
    );
  }

  function mergeLocations(userMappings: UserMappings, locations: ClientLocation[]) {
    if (!(userMappings && locations)) {
      return;
    }
    const mappedLocations = userMappings.external_user_mappings.location || [];
    setLocationMappings(
      locations.map(location => {
        return {
          id: location.id,
          title: location.title,
          value: mappedLocations.find(l => l.internal == location.id)?.external || '',
        };
      })
    );
  }

  function externalize(mappings: Mapping[]) {
    const externalized = mappings
      .filter(m => !!m.value)
      .map(m => ({ internal: m.id, external: m.value }));
    return externalized;
  }

  function handleSave() {
    const user_mappings = {
      ...userMappings,
      external_user_mappings: {
        ...(userMappings.external_user_mappings || {}),
        user_type: externalize(userTypeMappings),
        department: externalize(departmentMappings),
        location: externalize(locationMappings),
        user_status: externalize([]),
      },
    };
    if (!user_mappings.external_user_mappings.user_type.length) {
      user_mappings.user_type_attr = null;
    }
    if (!user_mappings.external_user_mappings.department.length) {
      user_mappings.department_attr = null;
    }
    if (!user_mappings.external_user_mappings.location.length) {
      user_mappings.location_attr = null;
    }
    user_mappings.user_status_attr = null;
    mutate({
      client_id,
      user_mappings,
    });
  }

  if (mutationLoading || !(userMappings && departmentMappings && locationMappings)) {
    return <Loaders.Standard />;
  }

  return (
    <Grid container direction="column">
      <Grid>
        <Box pb={2}>
          <TextField
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Email"
            value={userMappings.email_attr}
            onChange={event => setUserMappings({ ...userMappings, email_attr: event.target.value })}
          />
        </Box>
      </Grid>
      <Grid>
        <Box pb={2}>
          <TextField
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Full Name"
            value={userMappings.full_name_attr || ''}
            onChange={event =>
              setUserMappings({ ...userMappings, full_name_attr: event.target.value })
            }
          />
        </Box>
      </Grid>
      <Grid>
        <Box pb={2}>
          <TextField
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Title"
            value={userMappings.job_title_attr || ''}
            onChange={event =>
              setUserMappings({ ...userMappings, job_title_attr: event.target.value })
            }
          />
        </Box>
      </Grid>
      <Grid>
        <Box pb={2}>
          <TextField
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="User Type"
            value={userMappings.user_type_attr || ''}
            onChange={event =>
              setUserMappings({ ...userMappings, user_type_attr: event.target.value })
            }
          />
          {userTypeMappings.map(userType => (
            <Box key={userType.id} pl={3} pt={2}>
              <TextField
                variant="standard"
                key={userType.id}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={userType.title}
                value={userType.value}
                onChange={event =>
                  setUserTypeMappings(
                    userTypeMappings.map(m =>
                      m.id == userType.id ? { ...m, value: event.target.value } : m
                    )
                  )
                }
              />
            </Box>
          ))}
        </Box>
      </Grid>
      {!!departmentMappings.length && (
        <Grid>
          <Box pb={2}>
            <TextField
              variant="standard"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Department"
              value={userMappings.department_attr || ''}
              onChange={event =>
                setUserMappings({ ...userMappings, department_attr: event.target.value })
              }
            />
            {departmentMappings.map(department => (
              <Box key={department.id} pl={3} pt={2}>
                <TextField
                  variant="standard"
                  key={department.id}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={department.title}
                  value={department.value}
                  onChange={event =>
                    setDepartmentMappings(
                      departmentMappings.map(m =>
                        m.id == department.id ? { ...m, value: event.target.value } : m
                      )
                    )
                  }
                />
              </Box>
            ))}
          </Box>
        </Grid>
      )}
      {!!locationMappings.length && (
        <Grid>
          <Box pb={2}>
            <TextField
              variant="standard"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Location"
              value={userMappings.location_attr || ''}
              onChange={event =>
                setUserMappings({ ...userMappings, location_attr: event.target.value })
              }
            />
            {locationMappings.map(location => (
              <Box key={location.id} pl={3} pt={2}>
                <TextField
                  variant="standard"
                  key={location.id}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={location.title}
                  value={location.value}
                  onChange={event =>
                    setLocationMappings(
                      locationMappings.map(m =>
                        m.id == location.id ? { ...m, value: event.target.value } : m
                      )
                    )
                  }
                />
              </Box>
            ))}
          </Box>
        </Grid>
      )}
      <Grid>
        <Grid container justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AttrMappingForm;
