import React, { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { Button, lighten, Link, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import PublishIcon from '@mui/icons-material/Publish';
import { useMatch } from 'react-router-dom';

import { AspectRatios } from 'types/app';
import { ContentInput, S3ObjectInput, S3VideoObjectInput } from 'api';
import { Loaders, UploadDialog, Uploader, UploaderContentTypes, UploadInput } from 'components';
import { useNotify, useProtectedClient } from 'hooks';
import uploadFile from 'app-react-query/utilities/upload-file';
import * as paths from 'paths';
import theme from 'theme';

import { usePostContext } from '../../PostContext';

const styles = {
  uploadButton: {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
    border: 'none',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5, 2),

    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  },
  warningText: {
    padding: theme.spacing(0, 5, 0, 5),
  },
} as const;

const MediaUpload: FC = () => {
  const { t } = useTranslation();
  const client = useProtectedClient();
  const notify = useNotify();
  const isExternalShare = useMatch(paths.share);

  const { setFieldValue } = useFormikContext<ContentInput>();
  const { dispatch } = usePostContext();

  const [isLoading, setIsLoading] = useState(false);

  async function handleSave(
    s3Object: S3ObjectInput | S3VideoObjectInput,
    type: UploaderContentTypes
  ) {
    if (type === UploaderContentTypes.Video) {
      setFieldValue('content_object.media.video', { ...s3Object });
      setFieldValue('content_object.photos', []);

      if (!client.public_post_page) {
        setFieldValue('is_shareable', false);
        dispatch({ type: 'share', value: false });
      }
    }

    if (type === UploaderContentTypes.Image || type === UploaderContentTypes.GIF) {
      setIsLoading(true);
      try {
        await uploadFile(s3Object);
        setIsLoading(false);
        setFieldValue('content_object.photos', [s3Object]);
        setFieldValue('content_object.media', null);
        setFieldValue('is_shareable', true);
        dispatch({ type: 'clearPhotoIndexes' });
        dispatch({ type: 'updatePhotoIndexes', value: 0 });
      } catch (e) {
        notify.mutationError();
      }
    }
  }

  function renderGifMessage() {
    return (
      <Trans i18nKey="posting:gifWarning">
        GIFs must be shorter than 12 seconds, under 8 MB, and auto-loop to enable playback on social
        networks. Learn more about image and video sizes
        <Link
          color="primary"
          href="https://help.everyonesocial.app/hc/en-us/articles/4420158868119-Video-and-Image-Sizes-and-Types"
          target="_blank"
          underline="hover"
        >
          here
        </Link>
        .
      </Trans>
    );
  }

  function renderVideoMessage() {
    return (
      <Trans i18nKey="posting:linkedInVideoWarning">
        Videos must be shorter than 10 minutes, under 200 MB, and in .mp4 format to play natively on
        LinkedIn. Please note, LinkedIn’s video API does not currently support emojis. Learn more
        about LinkedIn video sharing
        <Link
          color="primary"
          href="https://help.everyonesocial.app/hc/en-us/articles/5593394264471-Share-Video-to-LinkedIn-Beta-"
          target="_blank"
          underline="hover"
        >
          here
        </Link>
        .
      </Trans>
    );
  }

  if (isLoading) {
    return <Loaders.Standard />;
  }

  return (
    <Uploader onSave={handleSave} data-testid="uploader">
      {uploaderProps => (
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid>
            <UploadInput
              accept={isExternalShare ? 'image/*' : 'video/*,image/*'}
              onUpload={uploaderProps.onUpload}
            >
              <Button
                component="span"
                variant="contained"
                color="primary"
                sx={{ root: styles.uploadButton }}
              >
                <PublishIcon />
                <Typography variant="body2">{t('posting:upload')}</Typography>
              </Button>
            </UploadInput>
          </Grid>
          <Grid>
            <Typography
              sx={styles.warningText}
              align="center"
              display="block"
              variant="overline"
              color="textSecondary"
            >
              {renderGifMessage()}
            </Typography>
          </Grid>
          {!isExternalShare && (
            <Grid>
              <Typography
                sx={styles.warningText}
                align="center"
                display="block"
                variant="overline"
                color="textSecondary"
              >
                {renderVideoMessage()}
              </Typography>
            </Grid>
          )}
          <UploadDialog
            aspectRatio={AspectRatios.none}
            i18nKeyTitle="posting:photo"
            i18nKeyReplace="components:replaceImage"
            {...uploaderProps}
          />
        </Grid>
      )}
    </Uploader>
  );
};

export default MediaUpload;
