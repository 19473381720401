import React, { FC, Fragment } from 'react';
import { useFormikContext } from 'formik';
import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Network as NetworkType } from 'api';
import { Capability } from 'components';
import { useSocialConnections } from 'hooks';

import { Share } from '../../types';
import PartnerShareDisconnected from './Disconnected';
import PartnerShareDirect from './Direct';
import NetworkConnected from '../NetworkConnected';

interface Props {
  partnerName: string;
}

const PartnerShareConnections: FC<Props> = ({ partnerName, children }) => {
  const { twitterAccounts, linkedinAccounts } = useSocialConnections();
  const { values } = useFormikContext<Share>();
  const theme = useTheme();

  function renderConnectTwitter() {
    if (twitterAccounts.length) {
      return null;
    }

    return (
      <Grid>
        <Box padding={3} bgcolor={theme.palette.common.white}>
          <PartnerShareDisconnected
            partnerName={partnerName}
            networkMeta={{ provider: NetworkType.Twitter, name: 'Twitter' }}
          />
        </Box>
      </Grid>
    );
  }

  function renderConnectLinkedIn() {
    if (linkedinAccounts.length) {
      return null;
    }

    return (
      <Grid>
        <Box padding={3} bgcolor={theme.palette.common.white}>
          <PartnerShareDisconnected
            partnerName={partnerName}
            networkMeta={{ provider: NetworkType.Linkedin, name: 'LinkedIn' }}
          />
        </Box>
      </Grid>
    );
  }

  function renderConnection(index: number) {
    const { state } = values.items[index];
    const { networkMeta } = state;

    const isFacebook = networkMeta.provider === NetworkType.Facebook;

    if (isFacebook) {
      return (
        <Capability name="Sharing.Function.ShareToFacebook">
          <Grid>
            <Box padding={3} bgcolor={theme.palette.common.white}>
              <PartnerShareDirect index={index} />
            </Box>
          </Grid>
        </Capability>
      );
    }

    return (
      <Capability key={index} name={`Sharing.Function.ShareTo${networkMeta.name}`}>
        <Grid>
          <Box padding={3} bgcolor={theme.palette.common.white}>
            <NetworkConnected index={index} />
          </Box>
        </Grid>
      </Capability>
    );
  }

  return (
    <Fragment>
      <Capability name="Sharing.Function.ShareToTwitter">{renderConnectTwitter()}</Capability>
      <Capability name="Sharing.Function.ShareToLinkedIn">{renderConnectLinkedIn()}</Capability>
      {values.items.map((item, index) => (
        <Fragment key={index}>{renderConnection(index)}</Fragment>
      ))}
      {children}
    </Fragment>
  );
};

export default PartnerShareConnections;
