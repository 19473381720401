import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClickAwayListener, Input, Unstable_Grid2 as Grid } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { uniq } from 'lodash';
import getScrollbarSize from 'dom-helpers/scrollbarSize';

import { useGetUserSearchesQuery } from 'api';
import { search as searchPath } from 'paths';
import { useNotify } from 'hooks';
import { toggleScroll } from 'utilities';
import theme from 'theme';

import { EndAdornment, List, Recent, StartAdornment } from '../components/Search';

const styles = {
  root: {
    paddingLeft: 0,
    borderRadius: 0,
    fontWeight: 'fontWeightBold',
    border: 'none',
    backgroundColor: 'common.white',
  },
  sidebar: {
    fontSize: 16,
    '& > div': {
      marginRight: 2,
    },
  },
  input: {
    paddingLeft: 0,
  },
  gridItem: {
    position: 'relative',
  },
  focusOut: {
    backgroundColor: lighten(theme.palette.primary.light, 0.5),
    borderRadius: 1,
  },
} as const;

interface Props {
  sidebar?: boolean;
}

const STORE_SEARCH_LENGTH = 4;
const MAX_RECENTS = 4;

const SCROLL_BLOCK_KEY = 'search';

const NavbarSearch: FC<Props> = ({ sidebar }) => {
  const notify = useNotify();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [focused, setFocused] = useState(false);
  const [recents, setRecents] = useState([]);

  const inputEl = useRef(null);
  const recentsListEl = useRef(null);
  const suggestedListEl = useRef(null);

  const showRecent = focused && !inputValue && !!recents.length;

  const query = new URLSearchParams(location.search);
  const term = query.get('q');

  const rootClasses = {
    ...styles.root,
    ...(sidebar && styles.sidebar),
    ...(focused && styles.focusOut),
  };

  useEffect(() => {
    if (term) {
      setInputValue(term);
    }

    return () => {
      setInputValue('');
    };
  }, [term]);

  useGetUserSearchesQuery(
    { limit: MAX_RECENTS },
    {
      onError: notify.queryError,
      onSuccess: ({ getUserSearches }) => {
        setRecents(getUserSearches);
      },
    }
  );

  useEffect(() => {
    showRecent ? setStyle() : resetStyle();
  });

  function getListEl() {
    return showRecent ? recentsListEl : suggestedListEl;
  }

  function resetStyle() {
    toggleScroll(SCROLL_BLOCK_KEY, false);
    document.body.style.paddingRight = null;
  }

  function setStyle() {
    toggleScroll(SCROLL_BLOCK_KEY, true);
    document.body.style.paddingRight = `${getScrollbarSize()}px`;
  }

  function updateUserSearches(text: string) {
    if (text.length < STORE_SEARCH_LENGTH) {
      return;
    }

    if (recents.length === MAX_RECENTS) {
      recents.pop();
    }

    recents.unshift(text);
    setRecents(uniq(recents));
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  function handleListKeyDown(event: KeyboardEvent) {
    const { key } = event;

    if (!showRecent) {
      return;
    }

    const listEl = getListEl().current;
    const isFirst = document.activeElement === listEl.querySelector('li:first-of-type');
    const isLast = document.activeElement === listEl.querySelector('li:last-child');

    if (key === 'ArrowUp') {
      if (isFirst) {
        inputEl.current.focus();
      } else {
        const { previousElementSibling } = document.activeElement;
        (previousElementSibling as HTMLLIElement).focus();
      }
    }

    if (key === 'ArrowDown') {
      if (isLast) {
        inputEl.current.focus();
      } else {
        const { nextElementSibling } = document.activeElement;
        (nextElementSibling as HTMLLIElement).focus();
      }
    }
  }

  function handleInputKeyDown(event: KeyboardEvent) {
    const { key } = event;

    if (key === 'Enter' && !!inputValue) {
      return handleSearch(inputValue);
    }

    if (!showRecent) {
      return;
    }

    const listEl = getListEl().current;

    if (key === 'ArrowDown') {
      listEl.querySelector('li:first-of-type').focus();
    } else if (key === 'ArrowUp') {
      listEl.querySelector('li:last-child').focus();
    }
  }

  function handleClear() {
    setInputValue('');
  }

  function handleSearch(text: string) {
    setFocused(false);
    updateUserSearches(text);

    navigate(`${searchPath}?q=${encodeURIComponent(text)}`);
  }

  function renderRecents() {
    return (
      <List i18nKey="common:recent" onKeyDown={handleListKeyDown}>
        {recents.map(text => (
          <Recent key={text} text={text} onClick={handleSearch} />
        ))}
      </List>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <Grid container direction="column">
        <Grid>
          <Input
            fullWidth
            value={inputValue}
            onFocus={() => setFocused(true)}
            onChange={handleChange}
            onKeyDown={handleInputKeyDown}
            sx={{ root: rootClasses, input: styles.input }}
            placeholder={t('common:search')}
            inputRef={inputEl}
            startAdornment={<StartAdornment focused={focused || !!inputValue} />}
            endAdornment={!!inputValue && <EndAdornment query={inputValue} onClear={handleClear} />}
          />
        </Grid>
        <Grid sx={styles.gridItem}>{showRecent && renderRecents()}</Grid>
      </Grid>
    </ClickAwayListener>
  );
};

export default NavbarSearch;
