import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { PostsSortFields, SortDirections, SortedPost } from 'api';
import { useDateRange, useReportingFilter } from 'hooks';
import { DatePickers, Queries } from 'components';
import { AdminAnalyticsPosts } from 'Links';

import { OVERVIEW_TABLE_PER_PAGE } from '../../constants';

import TableHead from './TableHead';
import TableFooter from './TableFooter';
import TableLoader from './TableLoader';
import StatRow from './StatRow';
import EmptyStatRow from './EmptyStatRow';

const PostOverviewLikeStats: FC = () => {
  const { t } = useTranslation();
  const { startDate, setStartDate, endDate, setEndDate, dateRange, setDateRange } = useDateRange();
  const { filter } = useReportingFilter();

  const variables = {
    start_date: startDate,
    end_date: endDate,
    sort_direction: SortDirections.Desc,
    per_page: OVERVIEW_TABLE_PER_PAGE,
    sort: PostsSortFields.Reactions,
    snapshot: true,
    filter,
    page: 0,
  };

  function renderStat(sortedPost: SortedPost) {
    return (
      <StatRow
        link="content"
        title={sortedPost.title}
        stat={sortedPost.reactions}
        id={sortedPost.content_id}
        key={sortedPost.content_id}
      />
    );
  }

  function renderEmpty() {
    return <EmptyStatRow colSpan={2} />;
  }

  return (
    <Fragment>
      <Typography paragraph variant="h5" color="textSecondary">
        {t('analytics:likes_post_stats')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            title={t('analytics:posts_dashboard')}
            type={t('common:like_plural')}
            component={AdminAnalyticsPosts}
          />
          <TableBody>
            <Queries.AnalyticsSortedTable type="sortedPosts" variables={variables}>
              {({ loading, stats }) => (
                <Fragment>
                  {loading && <TableLoader rows={OVERVIEW_TABLE_PER_PAGE} />}
                  {!loading && (stats.length ? stats.map(renderStat) : renderEmpty())}
                </Fragment>
              )}
            </Queries.AnalyticsSortedTable>
          </TableBody>
          <TableFooter>
            <DatePickers.Range
              dateRange={dateRange}
              startDate={startDate}
              endDate={endDate}
              onSetStart={setStartDate}
              onSetEnd={setEndDate}
              onSetRange={setDateRange}
              transparent
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default PostOverviewLikeStats;
