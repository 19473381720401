import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { connectionPopup } from 'helpers';
import { SocialAccount } from 'api';
import palette from 'theme/palette';

const styles = {
  iconButton: {
    padding: 0,
  },
  updateIcon: {
    color: palette.red,
  },
} as const;

interface Props {
  account: SocialAccount;
}

const Refresh: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  return (
    <Tooltip arrow title={t('common:refresh')} placement="top">
      <IconButton
        sx={styles.iconButton}
        onClick={() => connectionPopup(account.provider)}
        size="large"
      >
        <InfoIcon sx={styles.updateIcon} />
      </IconButton>
    </Tooltip>
  );
};

export default Refresh;
