import React, { FC } from 'react';
import { TableCell, TableFooter as MuiTableFooter, TableRow } from '@mui/material';

const TableFooter: FC = ({ children }) => (
  <MuiTableFooter>
    <TableRow>
      <TableCell variant="footer" colSpan={2} padding="none">
        {children}
      </TableCell>
    </TableRow>
  </MuiTableFooter>
);

export default TableFooter;
