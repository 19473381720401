import React, { FC, SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Box, Tab, Tabs, Unstable_Grid2 as Grid } from '@mui/material';
import { find } from 'lodash';

import { Network } from 'api';
import { useContent, useGlobalClient, useReleases, useShareCopy, useSharePreview } from 'hooks';
import { MentionsEditor, Note } from 'components';

import { ShareItem } from '../types';
import Count from './Count';
import theme, { pxToRem } from 'theme';

const styles = {
  tabs: {
    minHeight: 2,
    padding: theme.spacing(1, 0, 1, 2),
    background: theme.palette.background.default,
  },
  indicator: {
    display: 'none',
  },
  tab: {
    fontSize: `${pxToRem(14)} !important`,
    minWidth: 3,
    padding: `${theme.spacing(0, 2.5, 0, 0)} !important`,
    minHeight: 2,
  },
  error: {
    color: 'error.main',
  },
} as const;

interface Props {
  index: number;
}

const ShareCopy: FC<Props> = ({ index }) => {
  const content = useContent();
  const client = useGlobalClient();
  const sharePreview = useSharePreview();

  const releases = useReleases();
  const hasLinkedInMentions = releases.includes('linkedinCompanyMentions');

  const { t } = useTranslation();
  const { networkShareCopyIndex, setNetworkShareCopyIndex, networkCommentaries } = useShareCopy();
  const [field, , helpers] = useField<ShareItem>(`items.${index}`);

  const { name, value } = field;
  const { networkMeta } = value.state;

  const isLinkedIn =
    networkMeta.provider === Network.Linkedin || networkMeta.provider === Network.LinkedinPage;
  const showTabs = networkCommentaries.length > 1;
  const lockedCommentary = sharePreview?.locked_commentary ?? false;
  const disclosure = getContentDisclosure();
  const shouldShowMentionsEditor = isLinkedIn && hasLinkedInMentions;

  useEffect(() => {
    const loadValues = {
      ...value,
      user_commentary: networkCommentaries[networkShareCopyIndex]?.commentary ?? '',
      ...(shouldShowMentionsEditor
        ? { mentions: networkCommentaries[networkShareCopyIndex]?.mentions ?? [] }
        : {}),
    };

    helpers.setValue(loadValues);
  }, []);

  function getContentDisclosure() {
    const contentDisclosure = find(content?.disclosures, [
      'network',
      isLinkedIn ? Network.Linkedin : networkMeta.provider,
    ]);
    if (!contentDisclosure) {
      return null;
    }

    if (contentDisclosure && !client) {
      return contentDisclosure.disclosure;
    }

    const clientDisclosure = find(client?.disclosures, ['id', contentDisclosure?.disclosure_id]);
    if (!clientDisclosure) {
      return null;
    }

    return clientDisclosure.disclosure;
  }

  function onChangeTab(event: SyntheticEvent, nextValue: number) {
    setNetworkShareCopyIndex(nextValue);
    if (shouldShowMentionsEditor) {
      return helpers.setValue({
        ...value,
        user_commentary: networkCommentaries[nextValue].commentary,
        mentions: networkCommentaries[nextValue]?.mentions ?? [],
      });
    }
    helpers.setValue({ ...value, user_commentary: networkCommentaries[nextValue].commentary });
  }

  return (
    <Box marginTop={1}>
      {showTabs && (
        <Tabs
          value={networkShareCopyIndex}
          onChange={onChangeTab}
          sx={styles.tabs}
          TabIndicatorProps={{ sx: styles.indicator }}
        >
          {networkCommentaries.map((_, idx) => (
            <Tab key={idx} label={t('sharing:option_count', { count: idx + 1 })} sx={styles.tab} />
          ))}
        </Tabs>
      )}
      <MentionsEditor
        fieldName={`${name}.user_commentary`}
        mentionsFieldName={`${name}.mentions`}
        blockListFieldName={`${name}.state.blockList`}
        placeholder={t('posting:linkedinShare')}
        disabled={lockedCommentary}
        publicQuery={false}
        disableMentions={!shouldShowMentionsEditor}
      />
      <Box marginTop={0.5}>
        <Grid container spacing={1}>
          <Grid>
            <Count index={index} />
          </Grid>
        </Grid>
      </Box>
      {!!disclosure && <Note color="inherit">{t('sharing:disclosureTip', { disclosure })}</Note>}
      {lockedCommentary && <Note i18nKey="sharing:shareLockdownTip" />}
    </Box>
  );
};

export default ShareCopy;
