import React, { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Unstable_Grid2 as Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
} from '@mui/material';
import {
  ClientReleaseToggle,
  ClientsFeatureToggleUpdateInput,
  UpdateClientToReleaseTogglesMutationVariables,
  useUpdateClientToReleaseTogglesMutation,
  useGetReleaseToggleDetailsQuery,
} from 'api';
import { useNotify } from 'hooks';
import theme from 'theme';

import * as paths from '../../paths';

const styles = {
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 400,
    height: 800,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
} as const;

interface IProps {
  clients: ClientReleaseToggle[];
  release_toggle_id: string;
}

interface IFormState {
  checked: ClientReleaseToggle[];
  left: ClientReleaseToggle[];
  right: ClientReleaseToggle[];
  changed: ClientReleaseToggle[];
}

function not(a: ClientReleaseToggle[], b: ClientReleaseToggle[]) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a: ClientReleaseToggle[], b: ClientReleaseToggle[]) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a: ClientReleaseToggle[], b: ClientReleaseToggle[]) {
  return [...a, ...not(b, a)];
}
const ReleaseToggleForm: FC<IProps> = ({ release_toggle_id, clients }) => {
  const notify = useNotify();
  const navigate = useNavigate();
  const initialInactive: ClientReleaseToggle[] = [];
  const initialActive: ClientReleaseToggle[] = [];
  const [working, setWorking] = useState(false);

  clients.forEach(client =>
    client.active ? initialActive.push(client) : initialInactive.push(client)
  );
  const [formState, setFormState] = useState<IFormState>({
    checked: [],
    left: initialInactive,
    right: initialActive,
    changed: [],
  });
  const { checked, left, right, changed } = formState;

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: ClientReleaseToggle) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFormState({ ...formState, checked: newChecked });
  };

  const numberOfChecked = (items: ClientReleaseToggle[]) => intersection(checked, items).length;

  const handleToggleAll = (items: ClientReleaseToggle[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setFormState({ ...formState, checked: not(checked, items) });
    } else {
      setFormState({ ...formState, checked: union(checked, items) });
    }
  };

  const handleChanged = (checkedList: ClientReleaseToggle[]) => {
    const clientsToRemove: string[] = [];
    const clientsToAddToChange: ClientReleaseToggle[] = [];
    checkedList.forEach(client => {
      if (changed.find(changedClient => changedClient.client_id === client.client_id)) {
        clientsToRemove.push(client.client_id);
      } else {
        clientsToAddToChange.push(client);
      }
    });
    return changed
      .filter(client => !clientsToRemove.includes(client.client_id))
      .concat(clientsToAddToChange);
  };

  const queryClient = useQueryClient();

  const { mutate: updateClientToReleaseToggles } = useUpdateClientToReleaseTogglesMutation({
    onError: handleError,
    // @ts-ignore
    onSuccess: handleCompleted,
  });

  function handleCompleted(data: any) {
    const queryKey = useGetReleaseToggleDetailsQuery.getKey({
      release_toggle_id: data.updateClientsToReleaseToggles.release_id,
    });
    notify.info({ message: 'Clients Updated' });
    queryClient.setQueryData(queryKey, data);
    navigate(paths.releaseToggles);
  }

  function handleError() {
    setWorking(false);
    notify.mutationError();
  }

  function handleSubmit(nextValues: UpdateClientToReleaseTogglesMutationVariables) {
    setWorking(true);
    updateClientToReleaseToggles({
      release_toggle_ids: nextValues.release_toggle_ids,
      clients: nextValues.clients,
    });
  }

  function submitChanges() {
    const values: UpdateClientToReleaseTogglesMutationVariables = {
      release_toggle_ids: [release_toggle_id] as string[],
      clients: [] as ClientsFeatureToggleUpdateInput[],
    };

    const clientUpdates: ClientsFeatureToggleUpdateInput[] = [];
    changed.forEach(change => {
      clientUpdates.push({
        client_id: change.client_id,
        active: !change.active,
      });
    });

    values.clients = clientUpdates;

    handleSubmit(values);
  }

  const handleCheckedRight = () => {
    const newChanged = handleChanged(leftChecked);
    setFormState({
      ...formState,
      right: right.concat(leftChecked),
      left: not(left, leftChecked),
      checked: not(checked, leftChecked),
      changed: newChanged,
    });
  };

  const handleCheckedLeft = () => {
    const newChanged = handleChanged(rightChecked);
    setFormState({
      ...formState,
      left: left.concat(rightChecked),
      right: not(right, rightChecked),
      checked: not(checked, rightChecked),
      changed: newChanged,
    });
  };

  const customList = (title: React.ReactNode, items: ClientReleaseToggle[]) => (
    <Card>
      <CardHeader
        sx={styles.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List sx={styles.list} dense component="div" role="list">
        {items.map((value: ClientReleaseToggle) => {
          const labelId = `transfer-list-all-item-${value.client_id}-${title}-label`;

          return (
            <ListItem key={value.client_id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.client_subdomain || value.client_title}:${value.client_id}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <form>
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={styles.root}>
        <Grid>{customList('Choices', left)}</Grid>
        <Grid>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              sx={styles.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0 || working}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={styles.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0 || working}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid>{customList('Chosen', right)}</Grid>
        <Grid>
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={changed.length === 0 || working}
            onClick={submitChanges}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReleaseToggleForm;
