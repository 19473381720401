import React, { FC, Fragment } from 'react';
import { CardContent, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useContent } from 'hooks';

import { EngagePublic } from '../widgets';
import { isEngageable } from '../helpers';

const MetaPublic: FC = () => {
  const content = useContent();
  const { t } = useTranslation();

  const { share_count } = content;
  const showEngage = isEngageable(content);

  return (
    <Fragment>
      {!!share_count && (
        <CardContent>
          <Grid container justifyContent="flex-start" alignItems="center" paddingBottom={2}>
            <Typography>{t('content:xHasShared', { count: share_count })}</Typography>
          </Grid>
        </CardContent>
      )}
      {showEngage && (
        <CardContent>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid container alignItems="center" spacing={1}>
              {showEngage && (
                <Grid container justifyContent="flex-end">
                  <EngagePublic />
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Fragment>
  );
};

export default MetaPublic;
