import React, { FC } from 'react';
import { Box, Link, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IMGIX_HOST } from 'settings';
import { generateLink } from 'Links';
import { getAppImage, getImageSrc } from 'helpers';
import { APP_IMAGES } from 'app-constants';
import { ContentFragment } from 'api';
import { queryString } from 'utilities';

import { getImage } from 'features/content/helpers';

interface Props {
  size: number;
  content: ContentFragment;
  rounded?: boolean;
}

const styles = {
  noImage: {
    position: 'absolute',
    textAlign: 'center',
  },
  rounded: {
    borderRadius: 16,
  },
} as const;

const PostImage: FC<Props> = props => {
  const { content, size, rounded } = props;
  const { t } = useTranslation();
  const image = getImage(content);

  function getImgixUrl() {
    const key = image.file_key ? image.file_key : image.key;
    const baseUrl = `${IMGIX_HOST}/${key}`;

    const params = {
      auto: 'format',
      fit: 'crop',
      crop: 'faces',
      width: size,
      height: size,
    };

    if (rounded) {
      params['border'] = '1,00FFFFFF';
      params['border-radius'] = '16,16,16,16';
    }

    return `${baseUrl}?${queryString(params)}`;
  }

  function renderPlaceholderImage() {
    let children = null;

    if (content) {
      children = (
        <Link component={generateLink(`/content/${content.content_id}`)} underline="hover">
          <img
            style={{ height: size, width: size, ...(rounded && styles.rounded) }}
            {...getAppImage(APP_IMAGES.SocialLoading)}
          />
        </Link>
      );
    } else {
      children = (
        <img
          style={{ height: size, width: size, ...(rounded && styles.rounded) }}
          {...getAppImage(APP_IMAGES.SocialLoading)}
        />
      );
    }

    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: size, width: size, position: 'relative' }}
      >
        <Typography sx={styles.noImage}>{t('admin:noPreview')}</Typography>
        {children}
      </Grid>
    );
  }

  if (!image || !image.bucket) {
    return renderPlaceholderImage();
  }

  const imageUrl = getImgixUrl();

  return (
    <Link component={generateLink(`/content/${content.content_id}`)} underline="hover">
      <Box sx={{ height: size, width: size, position: 'relative' }}>
        <img {...getImageSrc(imageUrl)} />
      </Box>
    </Link>
  );
};

PostImage.defaultProps = {
  size: 200,
  rounded: false,
};

export default PostImage;
