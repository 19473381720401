import React, { ChangeEvent, FC, HTMLAttributes, useState } from 'react';
import { Autocomplete, Box, Checkbox, Chip, Unstable_Grid2 as Grid } from '@mui/material';
import { useDebounce } from 'use-debounce';

import {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import { AutocompleteGetTagProps } from '@mui/material/useAutocomplete';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';

import { UserAvatar } from 'components';
import { useNotify } from 'hooks';
import {
  MinimalUserProfileFragment,
  ProfileSuggestion,
  ProfileSuggestionFragment,
  PublicUserProfileFragment,
  UserSuggestQuery,
  useUserSuggestQuery,
} from 'api';

import { PickerInput, PopupIcon } from './components';
import useAutoCompleteStyles from './styles/useAutoCompleteStyles';

type CustomProps<T> = Omit<
  AutocompleteProps<T, boolean, boolean, boolean>,
  'renderInput' | 'options'
>;

export type UserType = PublicUserProfileFragment | MinimalUserProfileFragment;

interface IProps extends CustomProps<PublicUserProfileFragment> {
  selectedUsers: UserType[];
  onSelectedUsersChange: (selectedUsers: UserType[]) => void;
  saving?: boolean;
  excludeIds?: string[];
  fullWidth?: boolean;
}

const UserPicker: FC<IProps> = props => {
  const { selectedUsers, onSelectedUsersChange, saving, excludeIds, fullWidth, ...rest } = props;

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const notify = useNotify();

  const [prefix] = useDebounce(inputValue, 250);
  const { isLoading } = useUserSuggestQuery(
    { prefix },
    {
      enabled: !!prefix,
      onError: notify.queryError,
      onSuccess: handleCompleted,
    }
  );

  const autocompleteClasses = useAutoCompleteStyles;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function toggleOpen() {
    setOpen(!open);
  }

  function handleChange(event: ChangeEvent, newSelectedOptions: PublicUserProfileFragment[]) {
    setInputValue('');
    onSelectedUsersChange(newSelectedOptions);
  }

  function handleCompleted({ suggest }: UserSuggestQuery) {
    const { results } = suggest;
    const filtered = results.filter(
      (result: ProfileSuggestionFragment) => !excludeIds.includes(result.id)
    );

    setOptions(
      filtered.map((profile: ProfileSuggestionFragment) => ({
        ...profile,
        full_name: profile.title,
      }))
    );
  }

  function handleAsyncInputChange(nextInputValue: string) {
    setInputValue(nextInputValue);

    if (!nextInputValue) {
      setOptions([]);
    }
  }

  function renderTags(value: UserType[], getTagProps: AutocompleteGetTagProps) {
    return value.map((user, index) => (
      <Chip
        key={user.id}
        avatar={<UserAvatar link size={30} user={user} />}
        label={user.full_name || (user as unknown as ProfileSuggestionFragment).title}
        deleteIcon={<CloseIcon />}
        {...getTagProps({ index })}
      />
    ));
  }

  function renderOption(
    props: HTMLAttributes<HTMLLIElement>,
    option: ProfileSuggestion,
    { selected }: AutocompleteRenderOptionState
  ) {
    return (
      <Grid component="li" container alignItems="center" {...props}>
        <Grid>
          <Box mr={1}>
            <UserAvatar link size={30} user={option} />
          </Box>
        </Grid>
        <Grid xs>{option.title}</Grid>
        <Grid>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
        </Grid>
      </Grid>
    );
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <PickerInput
        working={saving || isLoading}
        placeholder="components:userSuggest"
        onChange={handleAsyncInputChange}
        params={params}
      />
    );
  }

  function isOptionSelected(option: ProfileSuggestion, group: UserType) {
    return option.id === group.id;
  }

  return (
    <Autocomplete
      fullWidth={fullWidth}
      multiple
      disableClearable
      disableCloseOnSelect
      open={open}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      loading={isLoading}
      sx={autocompleteClasses}
      options={options}
      onChange={handleChange}
      getOptionLabel={({ title }) => title}
      popupIcon={<PopupIcon open={open} />}
      renderTags={renderTags}
      renderOption={renderOption}
      renderInput={renderInput}
      value={selectedUsers}
      inputValue={inputValue}
      isOptionEqualToValue={isOptionSelected}
      {...rest}
    />
  );
};

UserPicker.defaultProps = {
  excludeIds: [],
};

export default UserPicker;
