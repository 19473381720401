import React, { FC } from 'react';
import { Tab } from '@mui/material';
import { TabOwnProps } from '@mui/material/Tab/Tab';

import theme from 'theme';

const styles = {
  root: {
    minWidth: 0,
    padding: theme.spacing(3, 2.5),
    '@media (min-width: 960px)': {
      fontSize: theme.typography.pxToRem(16),
    },
    '@media (min-width: 1240px)': {
      fontSize: theme.typography.pxToRem(16),
    },
  },
} as const;

const SmallTab: FC<TabOwnProps> = props => {
  return <Tab {...props} sx={styles.root} />;
};

export default SmallTab;
