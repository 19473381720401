import {
  GetTrackingDetailsQuery,
  GetTrackingDetailsQueryVariables,
  GetTrackingDetailsDocument,
  SegmentEventType,
} from 'api';
import { transport } from '../protected-transport';

const trackQuery = () => {
  return (eventName: SegmentEventType, userId: string, extraArgs: Record<string, unknown> = {}) => {
    const variables = {
      event_name: eventName,
      user_id: userId,
    };
    transport<GetTrackingDetailsQuery, GetTrackingDetailsQueryVariables>(
      GetTrackingDetailsDocument,
      variables
    )()
      .then(value => {
        const eventArgs = JSON.parse(value.getTrackingDetails.event_args);

        window?.analytics?.track(
          value.getTrackingDetails.event_name,
          {
            ...eventArgs,
            ...extraArgs,
          },
          {
            userId: userId,
          }
        );
      })
      .catch(() => {
        // Ignore empty block
      });
  };
};

export default trackQuery;
