import React, { FC, createContext } from 'react';

interface Context {
  needs: (neededCapability: string, value?: string | string[]) => boolean;
  extract: (targetedCapability: string) => string[];
  isOwner: boolean;
  isAdmin: boolean;
  isModerator: boolean;
  isContributor: boolean;
}

interface Props {
  capabilities: string[];
}

export const CapabilitiesContext = createContext<Context>({
  needs: () => false,
  extract: () => [],
  isOwner: false,
  isAdmin: false,
  isModerator: false,
  isContributor: false,
});

const WILD_CARD = '*';
const SCOPED_CHARACTER = '[';

export const CapabilitiesProvider: FC<Props> = ({ children, capabilities }) => {
  const helpers = {
    isOwner: needs('Access.Owner'),
    isAdmin: needs('Access.Admin'),
    isModerator: needs('Access.Moderator'),
    isContributor: needs('Access.Contributor'),
  };

  function formatValues(value?: string | string[]): string[] {
    if (!value) {
      return [];
    }

    if (typeof value === 'string') {
      return [value];
    }

    return value;
  }

  function needs(neededCapability: string, value?: string | string[]): boolean {
    const baseCapability = capabilities.find(c => c.includes(neededCapability));

    if (!baseCapability) {
      return false;
    }

    const formattedValuesArray = formatValues(value);
    const hasAdminLevelCapability = baseCapability.includes(WILD_CARD);
    const hasFormattedValues = !!formattedValuesArray.length;
    const hasScopedCapability =
      !hasFormattedValues && neededCapability === baseCapability.split(SCOPED_CHARACTER)[0];

    if (hasAdminLevelCapability || hasScopedCapability) {
      return true;
    }

    // Developer Error: Requested partial match of capability. Ex: Access instead of Access.Admin
    if (!hasFormattedValues) {
      return false;
    }

    const [, grantedResources] = /\[(.*)]/g.exec(baseCapability);
    const grantedResourcesArray = grantedResources.split(',');

    return Array.isArray(value)
      ? formattedValuesArray.every(current => grantedResourcesArray.includes(current))
      : grantedResourcesArray.some(current => formattedValuesArray.includes(current));
  }

  function extract(targetedCapability: string): string[] {
    const baseCapability = capabilities.find(c => c.includes(targetedCapability));

    if (!baseCapability) {
      return [];
    }

    const regExpExecArray = /\[(.*)]/g.exec(baseCapability);

    if (!regExpExecArray) {
      return [];
    }

    return regExpExecArray[1].split(',');
  }

  return (
    <CapabilitiesContext.Provider value={{ needs, extract, ...helpers }}>
      {children}
    </CapabilitiesContext.Provider>
  );
};
