import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Unstable_Grid2 as Grid,
  IconButton,
  Input,
  MenuItem,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Clipboard from 'clipboard';

import { useContent, useCurrentUser, useProtectedClient } from 'hooks';
import { getEmbedWidgetSrc, getSubdomain, getSubdomainUrl } from 'helpers';
import { Events } from 'analytics';
import theme from 'theme';

type embedProps = {
  src?: string;
  width: number;
  height: number;
};

const styles = {
  dialogTitle: {
    marginBottom: 0,
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  copyButton: {
    width: 165,
    padding: '10px 24px',
  },
} as const;

const CopyPostEmbedCode: FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const content = useContent();
  const client = useProtectedClient();
  const currentUser = useCurrentUser();

  const codeClipboard = useRef<Clipboard>(null);
  const copyButtonRef = useRef(null);
  const codeInputRef = useRef(null);

  const embedWidgetSrc = getEmbedWidgetSrc(client, content);
  const [embedOptions] = useState<embedProps>({
    src: embedWidgetSrc,
    width: 580,
    height: 600,
  });

  useEffect(() => {
    window.addEventListener('message', handleComplete, false);

    return () => {
      codeClipboard.current?.destroy();
      window.removeEventListener('message', handleComplete, false);
    };
  }, []);

  function getEmbedCode() {
    const { src, width, height } = embedOptions;
    return `<iframe src="${src}" width="${width}" height="${height}" style="border: none; overflow: hidden" scrolling="no" frameBorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`;
  }

  function handleEntered() {
    codeClipboard.current = new Clipboard(copyButtonRef.current, {
      target: () => codeInputRef.current,
    });

    codeClipboard.current.on('success', () => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }

  function handleTracking() {
    window?.analytics?.track(Events.CopyEmbedCode, {
      client_id: client.id,
      client_name: client.title,
      client_subdomain: client.subdomain,
      content_id: content.content_id,
      content_url: `${getSubdomainUrl(client.subdomain)}/content/${content.content_id}`,
      content_type: content.content_object.post_type,
      user_id: currentUser.id,
      user_email: currentUser.email_address,
      user_type: currentUser.user_type,
    });
  }

  function handleComplete(event: MessageEvent) {
    const origin = `${window.location.protocol}//${getSubdomain()}`;

    if (event.origin !== origin) {
      return;
    }
  }

  function toggleDialog() {
    setOpen(!open);
  }

  function renderDialogTitle() {
    return (
      <DialogTitle>
        <Grid xs={12} container alignItems="center" justifyContent="space-between">
          <Grid xs>
            <Typography variant="h6">{t('content:embedPost')}</Typography>
          </Grid>
          <Grid>
            <IconButton onClick={toggleDialog} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
    );
  }

  return (
    <Fragment>
      <MenuItem onClick={toggleDialog}>{t('content:copyPostEmbedCode')}</MenuItem>
      <Dialog
        TransitionProps={{ onEntered: handleEntered }}
        maxWidth="sm"
        open={open}
        onClose={toggleDialog}
      >
        {renderDialogTitle()}
        <DialogContent sx={styles.dialogContent}>
          <Grid container alignItems="center" spacing={1}>
            <Grid xs={12}>
              <Typography>{t('content:copyCodeHelp')}</Typography>
            </Grid>
            <Grid xs={12} container justifyContent="space-between" alignItems="center">
              <Grid xs={8}>
                <Input autoFocus ref={codeInputRef} fullWidth value={getEmbedCode()} />
              </Grid>
              <Grid xs={4}>
                <Button
                  onClick={handleTracking}
                  sx={styles.copyButton}
                  ref={copyButtonRef}
                  size="large"
                  variant="contained"
                  color="primary"
                  endIcon={copied && <CheckCircleIcon />}
                >
                  {copied ? t('content:copied') : t('content:copyCode')}
                </Button>
              </Grid>
            </Grid>

            <Grid xs={12}>
              <iframe
                src={embedOptions.src}
                width={embedOptions.width}
                height={embedOptions.height}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default CopyPostEmbedCode;
