import React, { FC, Fragment } from 'react';
import { Formik } from 'formik';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { ClientPublicFragment } from 'api/public';
import { useContent } from 'hooks';

import { sharingSchema } from 'features/sharing/validations';
import { Share } from 'features/sharing/types';
import useInitialShareValues from 'features/sharing/useInitialValues';
import { isTextOnly } from 'features/content/helpers';

import PartnerSharePreview from './Preview';
import PartnerShareConnections from './Connections';
import SharingThumbnails from '../Thumbnails';
import Footer from '../Footer';

interface Props {
  partnerName: string;
  client: ClientPublicFragment;
  onSubmit: (values: Share) => void;
}

const PartnerShareIndex: FC<Props> = ({ partnerName, onSubmit }) => {
  const { t } = useTranslation();
  const content = useContent();
  const initialValues = useInitialShareValues({ disablePages: true });
  const theme = useTheme();

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={sharingSchema}
    >
      <Fragment>
        <Box marginTop={2} paddingX={2} paddingBottom={10}>
          <Grid container direction="column" rowSpacing={3}>
            {!isTextOnly(content) && (
              <Grid>
                <PartnerSharePreview />
              </Grid>
            )}

            {content.content_object?.photos?.length > 1 && (
              <Grid>
                <Box bgcolor={theme.palette.common.white}>
                  <Box padding={0.5} marginLeft={0.5}>
                    <Typography variant="body2" color="textSecondary">
                      {t('sharing:selectedImageToShare')}
                    </Typography>
                  </Box>

                  <Grid container spacing={1}>
                    <SharingThumbnails images={content.content_object.photos} />
                  </Grid>
                </Box>
              </Grid>
            )}
            <PartnerShareConnections partnerName={partnerName} />
          </Grid>
        </Box>
        <Footer position="fixed" />
      </Fragment>
    </Formik>
  );
};

export default PartnerShareIndex;
