import React, { FC, useState } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import theme from 'theme';

type CustomElementProps = {
  leaf: {
    bold?: boolean;
    highlight?: boolean;
  };
  element: {
    type?: string;
    info: {
      name?: string;
    };
  };
} & Element;

const styles = {
  blueText: {
    fontWeight: 'bold',
    color: theme.custom.networks.Company,
  },
  normalText: {
    fontWeight: 'normal',
    color: 'common.black',
  },
  inlineClickStyleToggled: {
    fontWeight: 'bold',
    color: theme.custom.networks.Company,
    borderStyle: 'solid',
    borderColor: theme.custom.networks.Company,
    background: theme.custom.palette.grey[600],
    borderRadius: '20px',
    padding: '2px 0.4em 2px 0.23em',
  },
  paragraphStyles: {
    margin: 0,
  },
  highlight: {
    color: 'error.dark',
    backgroundColor: 'error.light',
    fontWeight: 'bold',
  },
} as const;

export const Leaf: FC<CustomElementProps> = props => {
  return (
    <Box
      component="span"
      {...props.attributes}
      sx={{
        ...(props.leaf.bold ? styles.blueText : styles.normalText),
        ...(props.leaf.highlight && styles.highlight),
      }}
    >
      {props.children}
    </Box>
  );
};

export const Element: FC<CustomElementProps> = props => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'mention':
      return <Mention {...props} />;
    case 'text':
      return <span {...attributes}>{children}</span>;
    default:
      return (
        <p {...attributes} style={styles.paragraphStyles}>
          {children}
        </p>
      );
  }
};

const Mention: FC<CustomElementProps> = ({ attributes, children, element }) => {
  const [highlighted, setHighlighted] = useState(false);
  const handleClick = () => setHighlighted(!highlighted);
  const handleClickAway = () => setHighlighted(false);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        component="span"
        {...attributes}
        contentEditable={false}
        style={{ cursor: 'pointer' }}
        sx={highlighted ? styles.inlineClickStyleToggled : styles.blueText}
        onClick={handleClick}
      >
        @{element.info.name}
        {children}
      </Box>
    </ClickAwayListener>
  );
};
