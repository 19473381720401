import React, { FC, createContext } from 'react';
import { ContentSharePreviewFragment } from 'api';

interface Props {
  value: ContentSharePreviewFragment;
}

const SharePreviewContext = createContext<ContentSharePreviewFragment>(null);

const SharePreviewProvider: FC<Props> = ({ value, children }) => {
  return <SharePreviewContext.Provider value={value}>{children}</SharePreviewContext.Provider>;
};

export { SharePreviewContext, SharePreviewProvider };
