const palette = {
  blue: '#389AFF',
  blueDark: '#328DEA',
  blueLight: '#5FAEFF',
  sky: '#C3E0FF',
  skyDark: '#2F88E4',
  skyLight: '#E3F1FF',
  green: '#6FBE44',
  black: '#1B1D1E',
  grey: {
    50: '#FCFCFC',
    100: '#F7F7F8',
    200: '#ECEDEE',
    300: '#DDDFE1',
    400: '#BFC1C4',
    500: '#90959B',
    600: '#6D7278',
    700: '#54575C',
    800: '#36383A',
  },
  white: '#FFFFFF',
  orange: '#F9A81A',
  red: '#EA1D64',
  redDark: '#C71351',
  redLight: '#FFD0CA',
  whiteAlternate: '#FBFBFB',
  whiteAlternate2: '#F0F0F0',
};

export default palette;
