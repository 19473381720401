import React, { FC, Fragment, MouseEvent, useState } from 'react';
import { Button, MenuItem, Select, SelectChangeEvent, TableCell, TableRow } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { UserProfileFragment, UserStatus, UserType } from 'api';
import { UserAvatar } from 'components';

import UserMenu from './UserMenu';
import moment from 'moment';

interface IProps {
  user: UserProfileFragment;
  onUpdateUserType(userType: UserType, user: UserProfileFragment): void;
  onUpdateUserStatus(userStatus: UserStatus, user: UserProfileFragment): void;
  onSetImpersonation(user: UserProfileFragment): void;
}

const UserRow: FC<IProps> = props => {
  const { user, onUpdateUserType, onUpdateUserStatus, onSetImpersonation } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  function handleUpdateUserType(event: SelectChangeEvent<UserType>) {
    onUpdateUserType(event.target.value as UserType, user);
  }

  function handleUpdateUserStatus(event: SelectChangeEvent<UserStatus>) {
    onUpdateUserStatus(event.target.value as UserStatus, user);
  }

  function handleSelectUser(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.target);
  }

  function handleDeselectUser() {
    setAnchorEl(null);
  }

  return (
    <Fragment>
      <TableRow key={user.id}>
        <TableCell style={{ width: 82 }} align="center">
          <UserAvatar user={user} />
        </TableCell>
        <TableCell>{user.id}</TableCell>
        <TableCell>{user.email_address}</TableCell>
        <TableCell>{user.full_name}</TableCell>
        <TableCell>{moment.unix(user.last_accessed_at).format('YYYY-MM-DD hh:mm:ss')}</TableCell>
        <TableCell>
          <Select
            variant="standard"
            fullWidth
            value={user.user_type}
            onChange={handleUpdateUserType}
            disabled={user.user_type === UserType.Cs}
          >
            <MenuItem value={UserType.User}>User</MenuItem>
            <MenuItem value={UserType.Contributor}>Contributor</MenuItem>
            <MenuItem value={UserType.Admin}>Client Admin</MenuItem>
            {user.user_type === UserType.Cs && (
              <MenuItem value={UserType.Cs}>Superadmin (CS)</MenuItem>
            )}
          </Select>
        </TableCell>
        <TableCell>
          <Select
            variant="standard"
            fullWidth
            value={user.user_status}
            onChange={handleUpdateUserStatus}
          >
            <MenuItem value={UserStatus.Active}>Active</MenuItem>
            <MenuItem value={UserStatus.Disabled}>Disabled</MenuItem>
            <MenuItem value={UserStatus.Deleted}>Delete</MenuItem>
          </Select>
        </TableCell>
        <TableCell>
          <Button onClick={handleSelectUser}>
            <MenuIcon />
          </Button>
        </TableCell>
      </TableRow>
      <UserMenu
        user={user}
        onClose={handleDeselectUser}
        anchorEl={anchorEl}
        onSetImpersonation={onSetImpersonation}
      />
    </Fragment>
  );
};

export default UserRow;
