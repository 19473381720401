import { UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  DeleteGroupSourceMutation,
  DeleteGroupSourceMutationVariables,
  GetGroupSourcesQuery,
  useGetGroupSourcesQuery,
} from 'api';
import { useNotify } from 'hooks';

type Mutation = DeleteGroupSourceMutation;
type MutationVariables = DeleteGroupSourceMutationVariables;
type QueryData = GetGroupSourcesQuery;

const useDeleteSource = (groupId: string) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const queryKey = useGetGroupSourcesQuery.getKey({ group_id: groupId });

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const index = draftState.getClientGroupSourcesByGroup.findIndex(source => {
            return source.id === id;
          });
          draftState.getClientGroupSourcesByGroup.splice(index, 1);
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },

    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }

      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useDeleteSource;
