import React, { FC, Fragment, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { Numeric } from 'components';

interface IProps extends WithStyles<typeof styles> {
  color: string;
  value: number;
  title: ReactNode;
}

const styles = () =>
  createStyles({
    block: {
      display: 'inline-flex',
      width: 6,
      height: 12,
      borderRadius: 4,
    },
  });

const AreaLegendItem: FC<IProps> = ({ classes, color, title, value }) => (
  <Fragment>
    <Typography variant="subtitle2">
      <div className={classes.block} style={{ backgroundColor: color }} /> {title}
    </Typography>
    <Typography variant="subtitle2">
      <Numeric value={value} />
    </Typography>
  </Fragment>
);

export default withStyles(styles)(AreaLegendItem);
