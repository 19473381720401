import React, { FC, useEffect } from 'react';
import { Loaders } from 'components';
import { useParams } from 'react-router-dom';
import * as paths from 'paths';
import { AUTH_DOMAIN } from 'settings';
import { queryString } from 'utilities';
import { LOCAL_STORAGE_KEY_ACCESS_KEY, SOCIAL_CONNECTION_SOURCE_TYPES } from 'app-constants';
import { useGlobalClient } from 'hooks';

type ConnectParams = { network: string; content_id: string };

const PublicConnect: FC = () => {
  const { network, content_id } = useParams<ConnectParams>();
  const access_key = localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_KEY);
  const client = useGlobalClient();

  useEffect(() => {
    const params = {
      access_key,
      success_callback_url: `${window.location.origin}${paths.connectionSuccess}`,
      failure_callback_url: `${window.location.origin}${paths.connectionFailure}`,
      client_id: client.id,
      source:
        content_id === SOCIAL_CONNECTION_SOURCE_TYPES.express_activation
          ? content_id
          : SOCIAL_CONNECTION_SOURCE_TYPES.web_source,
    };

    const url = `${AUTH_DOMAIN}/connect/${network}?${queryString(params)}`;
    location.replace(url);
  }, []);

  return <Loaders.Standard />;
};

export default PublicConnect;
