import { InfiniteData, UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  GetInboxItemsQuery,
  MarkInboxItemAsReadMutation,
  MarkInboxItemAsReadMutationVariables,
  useGetInboxItemsQuery,
} from 'api';
import { useNotify } from 'hooks';
import { NOTIFICATION_INBOX_LIMIT, NOTIFICATION_POPUP_LIMIT } from 'app-constants';

type Mutation = MarkInboxItemAsReadMutation;
type MutationVariables = MarkInboxItemAsReadMutationVariables;
type QueryData = InfiniteData<GetInboxItemsQuery>;

const useMarkInboxItemAsRead = () => {
  const queryClient = useQueryClient();
  const notify = useNotify();

  const inboxQueryKey = useGetInboxItemsQuery.getKey({ limit: NOTIFICATION_INBOX_LIMIT });
  const popupQueryKey = useGetInboxItemsQuery.getKey({ limit: NOTIFICATION_POPUP_LIMIT });

  function markItemAsRead(baseState: QueryData, mutationVariables: MutationVariables) {
    if (!baseState) {
      return null;
    }

    return produce(baseState, draftState => {
      draftState.pages.forEach(page => {
        page.getInboxItems.inbox.map(item => {
          if (item.id === mutationVariables.id) {
            item.item_read = true;
          }
          return item;
        });
      });
    });
  }

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async mutationVariables => {
      await queryClient.cancelQueries(popupQueryKey);
      await queryClient.cancelQueries(inboxQueryKey);

      const popupBaseState = queryClient.getQueryData<QueryData>(popupQueryKey);
      const popupNextState = markItemAsRead(popupBaseState, mutationVariables);

      const inboxBaseState = queryClient.getQueryData<QueryData>(inboxQueryKey);
      const inboxNextState = markItemAsRead(inboxBaseState, mutationVariables);

      if (popupBaseState) {
        queryClient.setQueryData<QueryData>(popupQueryKey, popupNextState);
      }

      if (inboxBaseState) {
        queryClient.setQueryData<QueryData>(inboxQueryKey, inboxNextState);
      }

      return { popupBaseState, inboxBaseState };
    },
    onError: (error, mutationArgs, { popupBaseState, inboxBaseState }) => {
      if (popupBaseState) {
        queryClient.setQueryData<QueryData>(popupQueryKey, popupBaseState);
      }

      if (inboxBaseState) {
        queryClient.setQueryData<QueryData>(inboxQueryKey, inboxBaseState);
      }

      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useMarkInboxItemAsRead;
