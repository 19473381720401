import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { SortedLeaderboard } from 'api';
import { Numeric, Queries, TableCellWithOverflow } from 'components';
import { generateLink } from 'Links';
import { usePrevious, useScrollToRef } from 'hooks';

import { IUnsortableHeadCell } from '../types';
import { DateCell, EmptyStatRow, TableLoader } from '../shared';
import { ROWS_PER_PAGE_OPTIONS } from '../constants';
import useStyles from '../analyticsStyles';

interface IProps {
  startDate: string;
  endDate: string;
  leaderboardId: string;
}

const LeaderboardStatTable: FC<IProps> = ({ startDate, endDate, leaderboardId }) => {
  const classes = useStyles;
  const hasMounted = usePrevious('hasMounted');
  const { t } = useTranslation();
  const { ref, scrollTo } = useScrollToRef();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const headCells: IUnsortableHeadCell[] = [
    {
      id: 'rank',
      align: 'inherit',
      label: t('common:rank'),
      width: 'auto',
    },
    {
      id: 'name',
      align: 'inherit',
      label: t('common:person'),
      width: 300,
    },
    {
      id: 'last_seen_at',
      align: 'inherit',
      label: t('analytics:last_active'),
      width: 'auto',
    },
    {
      id: 'department',
      align: 'inherit',
      label: t('common:department'),
      width: 'auto',
    },
    {
      id: 'location',
      align: 'inherit',
      label: t('common:location'),
      width: 'auto',
    },
    {
      id: 'score',
      align: 'inherit',
      label: t('common:point_plural'),
      width: 'auto',
    },
  ];

  useEffect(() => {
    hasMounted && scrollTo();
  }, [page, rowsPerPage]);

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function renderHeadCell(cellProps: IUnsortableHeadCell) {
    return (
      <TableCell
        align={cellProps.align}
        key={cellProps.id}
        style={{ width: cellProps.width }}
        size="small"
      >
        {cellProps.label}
      </TableCell>
    );
  }

  function renderStat(sortedLeaderboard: SortedLeaderboard) {
    const { rank, name, user_id, department, last_seen_at, location, score } = sortedLeaderboard;

    return (
      <TableRow sx={{ root: classes.tableBodyRow }} key={user_id}>
        <TableCell>
          <Numeric value={rank} />
        </TableCell>
        <TableCellWithOverflow>
          <Link
            noWrap
            target="_blank"
            title={name}
            component={generateLink(`/profile/${user_id}`)}
            variant="body2"
            underline="hover"
          >
            {name}
          </Link>
        </TableCellWithOverflow>
        <DateCell dateValue={last_seen_at} />
        <TableCell>{department}</TableCell>
        <TableCell>{location}</TableCell>
        <TableCell>
          <Numeric value={score} />
        </TableCell>
      </TableRow>
    );
  }

  function renderEmpty() {
    return <EmptyStatRow colSpan={headCells.length} />;
  }

  return (
    <Fragment>
      <Typography ref={ref} paragraph variant="h5" color="textSecondary">
        {t('common:leaderboard_plural')}
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>{headCells.map(renderHeadCell)}</TableRow>
          </TableHead>
          <TableBody>
            <Queries.LeaderboardOverviewStats
              loader={<TableLoader rows={8} />}
              startDate={startDate}
              endDate={endDate}
              leaderboardConfigId={leaderboardId}
              page={page}
              perPage={rowsPerPage}
            >
              {leaderboardStats =>
                leaderboardStats.length ? leaderboardStats.map(renderStat) : renderEmpty()
              }
            </Queries.LeaderboardOverviewStats>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                colSpan={headCells.length}
                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={t('analytics:rows_per_page')}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to }) =>
                  t('analytics:table_displayed_rows', { from, to })
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default LeaderboardStatTable;
