import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { CustomSVG } from 'components';
import { useCapabilities, useContent } from 'hooks';

const Reposts: FC = () => {
  const { t } = useTranslation();
  const { isAdmin, needs } = useCapabilities();
  const content = useContent();

  const { slack_channels, ms_teams_channels } = content;
  const moderatesAllGroups = needs('Access.Moderator', content.group_ids);
  const hasSlackChannels = !!slack_channels?.length;
  const hasTeamsChannels = !!ms_teams_channels?.length;

  if (!isAdmin && !moderatesAllGroups) {
    return null;
  }

  if (!hasSlackChannels && !hasTeamsChannels) {
    return null;
  }

  return (
    <Fragment>
      <Box padding={2}>
        <Grid container direction="column" spacing={1}>
          {hasSlackChannels && (
            <Grid xs>
              <Grid container alignItems="center" spacing={1}>
                <Grid>
                  <CustomSVG name="slack" />
                </Grid>
                <Grid>
                  <Typography>{t('content:shared_to_slack')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {hasTeamsChannels && (
            <Grid xs>
              <Grid container alignItems="center" spacing={1}>
                <Grid>
                  <CustomSVG name="msteams" />
                </Grid>
                <Grid>
                  <Typography>{t('content:shared_to_msteams')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
      <Divider />
    </Fragment>
  );
};

export default Reposts;
