import React, { FC, useEffect, useState } from 'react';
import { Button, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import { CustomSVG } from 'components';
import { ChatChannelPicker } from 'components/Pickers';
import { ContentInput, Network, SharingChannelInfo, useGetChatChannelsQuery } from 'api';
import { useIntegrations, useNotify } from 'hooks';
import { connectionPopup } from 'helpers';
import { generateRepostChannelsString } from 'features/posting/helpers';

import { usePostContext } from '../../PostContext';
import Widget from './WidgetContainer';
import { Switch } from '../';

const styles = {
  sendAgainButton: {
    fontSize: '12px',
    textDecoration: 'underline',
  },

  text: {
    marginTop: 1,
  },
};

const Slack: FC = () => {
  const notify = useNotify();
  const { state, dispatch } = usePostContext();
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<ContentInput>();
  const { slack_channels } = values;

  const integrations = useIntegrations();
  const hasSlack = integrations.some(integration => integration.provider === Network.Slack);

  const [prevSelectedChannels] = useState(slack_channels);

  useEffect(() => {
    const isPending =
      !isEmpty(slack_channels) && slack_channels.every(channel => channel.status === 'pending');

    dispatch({ type: 'slack', value: hasSlack && isPending });
  }, []);

  const { data, isLoading } = useGetChatChannelsQuery(
    { network: Network.Slack },
    { onError: notify.queryError }
  );

  const options = data?.getChatChannels?.slack_channels ?? [];
  let channelListString = '';
  slack_channels.forEach(channel => {
    if (channelListString === '') {
      channelListString += `#${channel.channel_name}`;
    } else {
      channelListString += `, ${channel.channel_name}`;
    }
  });
  channelListString = `${t('posting:SentToRepost')} ${channelListString}`;

  function handleConnect() {
    connectionPopup(Network.Slack);
  }

  function enableChannelsEditing() {
    dispatch({ type: 'previousSlackPost', value: false });
    dispatch({ type: 'slack', value: true });
  }

  function handleSwitch() {
    dispatch({ type: 'slack', value: !state.slack });
  }

  function handleChannelSelect(channels: SharingChannelInfo[]) {
    setFieldValue('slack_channels', channels);
  }

  function renderBody() {
    if (state.slack) {
      return (
        <Grid container marginTop={2}>
          <Grid xs={1} />
          <Grid xs={10}>
            <ChatChannelPicker
              isLoading={isLoading}
              options={options}
              selectedChannels={values.slack_channels}
              onChannelChange={handleChannelSelect}
            />
          </Grid>
        </Grid>
      );
    }

    return null;
  }

  function renderAlreadyShared() {
    if (isEmpty(prevSelectedChannels)) {
      return null;
    }

    const channels = prevSelectedChannels.map(channel => {
      const selected: SharingChannelInfo = options.find(
        option => option.channel_id === channel.channel_id
      );
      return selected?.channel_name;
    });

    return (
      <Grid container sx={styles.text}>
        <Grid xs={1} />
        <Grid xs={11}>
          <Typography variant="body1" color="primary" display="inline">
            {`Already shared to: #${channels.join(', #')}`}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function renderToggle() {
    if (state.previousSlackPost) {
      return (
        <Button color="primary" sx={styles.sendAgainButton} onClick={enableChannelsEditing}>
          {t('posting:sendAgainRepost')}
        </Button>
      );
    }
    if (hasSlack) {
      return (
        <Switch
          checked={state.slack}
          onChange={handleSwitch}
          color="primary"
          data-testid="post-radio-slack"
        />
      );
    }

    return (
      <Button variant="text" color="primary" onClick={handleConnect} sx={{ float: 'right' }}>
        <Typography color="primary">{t('user:connectSlackAccount')}</Typography>
      </Button>
    );
  }

  return (
    <Widget
      titleKey="posting:shareSlack"
      subtitleKey={
        state.previousSlackPost
          ? generateRepostChannelsString(values.slack_channels, t('posting:sentToRepost'))
          : 'posting:guidelineSlack'
      }
      icon={<CustomSVG name={state.slack ? 'slack' : 'slackGreyscale'} />}
      toggle={renderToggle()}
      toggleColumns={hasSlack ? 1 : 3}
    >
      {renderBody()}
      {!isEmpty(options) && renderAlreadyShared()}
    </Widget>
  );
};

export default Slack;
