import React, { FC, useEffect, useLayoutEffect } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import { CognitoUserProvider } from 'providers';
import { Loaders } from 'components';
import { useSyncBillingCustomerMutation } from 'api';
import { isRoot } from 'utilities';
import { useNotify } from 'hooks';
import cookieStorage, { LOGOUT_COOKIE } from 'cookie-storage';

import i18n from 'config/i18n';
import ThemeProvider from 'theme/ThemeProvider';

import Protected from './protected/ProtectedIndex';
import Public from './public/PublicIndex';
import RootProtectedRoutes from './root/protected/Routes';
import RootPublicRoutes from './root/public/Routes';
import { RootLayout } from './root/Layout';
import { GlobalLayout, SubdomainLayout } from './Layout';
import ReactQueryProvider from './ReactQueryProvider';
import TokenCheck from 'features/auth/containers/TokenCheck';
import LegacyProviderCheck from 'features/auth/LegacyProviderCheck';

const ALLOWED_REFERRERS = [
  'billing.stripe.com',
  'stripe.com',
  'www.stripe.com',
  'checkout.stripe.com',
];

/**
 * The core of the app is on the subdomain
 * The protected and public folders are for subdomains
 */
const Subdomain: FC = () => {
  const notify = useNotify();
  const { t } = useTranslation();
  const { error, mutate, isLoading } = useSyncBillingCustomerMutation({
    onError: () => {
      notify.error({ message: t('errors:billingSyncError') });
    },
  });

  useEffect(() => {
    const { referrer } = window.document;

    if (referrer) {
      const url = new URL(referrer);
      const fromBilling = ALLOWED_REFERRERS.includes(url.hostname);
      fromBilling && mutate({});
    }
  }, []);

  if (isLoading || error) {
    return <Loaders.Startup />;
  }

  return (
    <BrowserRouter>
      <GlobalLayout>
        <SubdomainLayout>
          <CognitoUserProvider>
            {cognitoState => (cognitoState ? <Protected /> : <Public />)}
          </CognitoUserProvider>
        </SubdomainLayout>
      </GlobalLayout>
    </BrowserRouter>
  );
};

/**
 * Root domain is for client lookup and workspace selection
 * It is for the user while the subdomain is for the workspace
 */
const RootDomain: FC = () => {
  useLayoutEffect(() => {
    if (cookieStorage.logout) {
      const nextUrl = cookieStorage.logout;
      cookieStorage.remove(LOGOUT_COOKIE);
      window.location.replace(nextUrl);
    }
  }, []);

  return (
    <BrowserRouter>
      <GlobalLayout>
        <RootLayout>
          <TokenCheck>
            <LegacyProviderCheck>
              <CognitoUserProvider>
                {cognitoState => (cognitoState ? <RootProtectedRoutes /> : <RootPublicRoutes />)}
              </CognitoUserProvider>
            </LegacyProviderCheck>
          </TokenCheck>
        </RootLayout>
      </GlobalLayout>
    </BrowserRouter>
  );
};

const App: FC = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider>
      <CssBaseline />
      <ReactQueryProvider>
        <I18nextProvider i18n={i18n}>{isRoot() ? <RootDomain /> : <Subdomain />}</I18nextProvider>
      </ReactQueryProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
