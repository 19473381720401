import React, { FC, Fragment, MouseEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Divider, Popover, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReportingFilter, UserFeedFollowGroupFragment } from 'api';
import { DepartmentPicker, GroupPicker, LocationPicker, SegmentPicker } from 'components/Pickers';
import { useCapabilities } from 'hooks';
import * as paths from 'paths';

import { FiltersButton } from 'features/admin/components/shared';

interface Props {
  filter: ReportingFilter;
  selectedGroups?: UserFeedFollowGroupFragment[];
  onSubmit: (filter: ReportingFilter, selectedGroups: UserFeedFollowGroupFragment[]) => void;
}

const Filters: FC<Props> = ({ filter, onSubmit, selectedGroups }) => {
  const { pathname } = useLocation();
  const { isAdmin, isModerator } = useCapabilities();
  const [tempFilter, setTempFilter] = useState<ReportingFilter>(filter);
  const [tempGroups, setTempGroups] = useState<UserFeedFollowGroupFragment[]>(selectedGroups);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const hideGroups = pathname === paths.adminAnalyticsGroups;
  const hideSegments = (isModerator && !isAdmin) || hideGroups;

  function onClickButton(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleReconciliation() {
    setTempFilter(filter);
    setTempGroups(selectedGroups);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleSubmit() {
    onSubmit(tempFilter, tempGroups);
    handleClose();
  }

  return (
    <Fragment>
      <FiltersButton onClick={onClickButton} />
      <Popover
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionProps={{
          onExited: handleReconciliation,
          onEnter: handleReconciliation,
        }}
      >
        <Box padding={4} sx={{ maxWidth: 650 }}>
          <Grid container spacing={2}>
            <Grid xs={12} container alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2">{t('admin:departments')}</Typography>
              </Grid>
              <Grid xs={9}>
                <DepartmentPicker
                  selectedDepartmentIds={tempFilter.department_ids}
                  onDepartmentChange={departmentIds =>
                    setTempFilter({ ...tempFilter, department_ids: departmentIds })
                  }
                />
              </Grid>
            </Grid>

            <Grid xs={12} container alignItems="center">
              <Grid xs={3}>
                <Typography variant="body2">{t('admin:locations')}</Typography>
              </Grid>

              <Grid xs={9}>
                <LocationPicker
                  selectedLocationIds={tempFilter.location_ids}
                  onLocationChange={locationIds =>
                    setTempFilter({ ...tempFilter, location_ids: locationIds })
                  }
                />
              </Grid>
            </Grid>

            {!hideSegments && (
              <Grid xs={12} container alignItems="center">
                <Grid xs={3}>
                  <Typography variant="body2">{t('common:segment_plural')}</Typography>
                </Grid>

                <Grid xs={9}>
                  <SegmentPicker
                    hideCompany
                    selectedSegmentsIds={tempFilter.segment_ids}
                    onSegmentChange={segmentIds =>
                      setTempFilter({ ...tempFilter, segment_ids: segmentIds })
                    }
                  />
                </Grid>
              </Grid>
            )}

            {!hideGroups && (
              <Grid xs={12} container alignItems="center">
                <Grid xs={3}>
                  <Typography variant="body2">{t('admin:groups')}</Typography>
                </Grid>

                <Grid xs={9}>
                  <GroupPicker
                    scope={isAdmin ? 'admin' : 'moderator'}
                    selectedGroups={tempGroups}
                    onSelectedGroupsChange={groupList => {
                      setTempGroups(groupList);
                      setTempFilter({
                        ...tempFilter,
                        group_ids: groupList.map(group => group.id),
                      });
                    }}
                  />
                </Grid>
              </Grid>
            )}

            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={12} container justifyContent="flex-end" spacing={2}>
              <Grid>
                <Button variant="outlined" onClick={handleClose} size="large">
                  {t('common:cancel')}
                </Button>
              </Grid>

              <Grid>
                <Button variant="contained" color="primary" onClick={handleSubmit} size="large">
                  {t('common:apply')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Fragment>
  );
};

Filters.defaultProps = {
  selectedGroups: [],
};

export default Filters;
