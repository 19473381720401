import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Unstable_Grid2 as Grid,
  Typography,
} from '@mui/material';
import {
  CsUpdateClientInput,
  ScimToken,
  useDeleteScimTokenMutation,
  useGetPublicUserProfileQuery,
} from 'api';
import { Alert, Loaders } from 'components';
import moment from 'moment';
import { useNotify } from 'hooks';

interface IProps {
  token: ScimToken;
  client: CsUpdateClientInput;
  onDeleteToken: (token: ScimToken) => void;
}

const ScimTokenRow: FC<IProps> = ({ token, client, onDeleteToken }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const getPublicUserProfileQuery = useGetPublicUserProfileQuery({ user_id: token.user_id });
  const notify = useNotify();
  const deleteScimTokenMutation = useDeleteScimTokenMutation({
    onSuccess() {
      notify.info({ message: 'Token Deleted' });
      onDeleteToken(token);
    },
  });
  const [detailsOpen, setDetailsOpen] = useState(false);

  const scimRootUrl = `https://${client.subdomain
    .split('.')
    .slice(0, 2)
    .join('.')}-sso.app/sso/scim/v2`;

  function handleDeleteToken() {
    deleteScimTokenMutation.mutate({ id: token.id });
    setConfirmOpen(false);
  }

  return (
    <Box pt={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid xs={4}>
          {getPublicUserProfileQuery.isLoading ? (
            <Loaders.Standard height={26} />
          ) : (
            <Box pl={2}>{getPublicUserProfileQuery.data?.getPublicUserProfile?.email_address}</Box>
          )}
        </Grid>
        <Grid xs={2}>
          <Box pl={2}>{moment.unix(token.expires_at).format('LL')}</Box>
        </Grid>
        <Grid xs={2}>
          <Box pl={2}>{token.rate_limit_max_requests}</Box>
        </Grid>
        <Grid xs={2}>
          <Box pl={2}>
            {token.rate_limit_window_end_at
              ? moment.unix(token.rate_limit_window_end_at).format('LL')
              : 'Never'}
          </Box>
        </Grid>
        <Grid xs={1}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => setDetailsOpen(true)}
            disabled={deleteScimTokenMutation.isLoading}
          >
            Details
          </Button>
        </Grid>
        <Grid xs={1}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => setConfirmOpen(true)}
            disabled={deleteScimTokenMutation.isLoading}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
      <Alert
        open={confirmOpen}
        onConfirm={handleDeleteToken}
        onCancel={() => setConfirmOpen(false)}
        message="Are you sure? This can't be undone!"
        confirm="Proceed"
      />
      <Dialog open={detailsOpen} onClose={() => setDetailsOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>Scim Token</DialogTitle>
        <DialogContent>
          <Box pb={2}>
            <Typography variant="body2">Token</Typography>
            <Typography>{token.id}</Typography>
          </Box>
          <Box pb={2}>
            <Typography variant="body2">Scim Root URL</Typography>
            <Typography>{scimRootUrl}</Typography>
          </Box>
          <Box pb={2}>
            <Typography variant="body2">Example / Test</Typography>
            <Typography>
              curl -H {`"Authorization: Bearer ${token.id}"`} {scimRootUrl}/Users
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ScimTokenRow;
