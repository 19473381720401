import { createContext, Dispatch, useContext } from 'react';

import { PublicPostState, PublicPostStateAction } from './types';

interface PublicPostContext {
  state: PublicPostState;
  dispatch: Dispatch<PublicPostStateAction>;
}

export const initialPublicPostState: PublicPostState = {
  content: undefined,
};

export const PublicPostContext = createContext<PublicPostContext>({
  state: initialPublicPostState,
  dispatch: () => null,
});

export function publicPostReducer(prevState: PublicPostState, action: PublicPostStateAction) {
  switch (action.type) {
    case 'setContent':
      return {
        ...prevState,
        content: action.value,
      };
  }
}

export const usePublicPostContext = () => useContext(PublicPostContext);
