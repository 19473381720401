import React, { createContext, FC, KeyboardEvent, useContext, useEffect, useState } from 'react';
import { Descendant, Editor, Range, Transforms } from 'slate';
import { init, Data as EmojiData, SearchIndex } from 'emoji-mart';
import data, { EmojiMartData } from '@emoji-mart/data';

import { getRangeAndMatch } from '../helpers';
import { EMOJI_MAX_COUNT, EMOJI_MAX_DISTANCE, EMOJI_PARTIAL_REGEX } from '../constants';
import { useSelectionContext } from './SelectionContext';

interface Props {
  editor: Editor;
}

interface PopoverState {
  target: Range;
  index: number;
  search: string;
}

interface EmojiInsertState {
  emoji: typeof EmojiData;
  range: Range;
}

interface ContextValue {
  target: Range;
  index: number;
  list: typeof EmojiData[];
  actions: {
    insertEmoji(emoji: typeof EmojiData): void;
  };
  callbacks: {
    onEditorKeyDown(event: KeyboardEvent): void;
    onEditorKeyUp(): void;
    onEditorChange(value: Descendant[]): void;
  };
}

const EmojiContext = createContext<ContextValue>({
  target: null,
  index: 0,
  list: [],
  actions: {
    insertEmoji: () => undefined,
  },
  callbacks: {
    onEditorKeyDown: () => undefined,
    onEditorKeyUp: () => undefined,
    onEditorChange: () => undefined,
  },
});

/**
 * Handles character matching and list generation for popover
 * based insertions of emojis. The same pattern can be used for
 * other resources like mentions, content, and groups.
 */
const EmojiContextProvider: FC<Props> = ({ editor, children }) => {
  const [popoverState, setPopoverState] = useState<PopoverState>({
    target: null,
    search: '',
    index: 0,
  });

  const [list, setList] = useState<EmojiMartData[]>([]);
  const [emojiInsertState, setEmojiInsertState] = useState<EmojiInsertState>({
    emoji: null,
    range: null,
  });

  const { target, search, index } = popoverState;
  const { actions: selectionActions } = useSelectionContext();

  useEffect(() => {
    void init({ data });

    if (target) {
      void emojiSearch(search);
    }
  }, [target]);

  function handleEditorKeyUp() {
    if (emojiInsertState.emoji) {
      handleReplaceTextWithEmoji();
    }
  }

  async function emojiSearch(value: string) {
    const emojis = await SearchIndex.search(value);
    const results = emojis.map((emoji: typeof EmojiData) => emoji);

    setList(results.slice(0, EMOJI_MAX_COUNT));
  }

  function handleInsertEmoji(emoji: typeof EmojiData) {
    selectionActions.ensureEditorFocus();
    Transforms.insertText(editor, emoji.skins[0].native, {
      at: target,
    });
  }

  function handleReplaceTextWithEmoji() {
    const { emoji, range } = emojiInsertState;

    Transforms.insertText(editor, emoji.skins[0].native, { at: range });

    setEmojiInsertState({ emoji: null, range: null });
    setPopoverState({ target: null, search: '', index: 0 });
  }

  function handleEditorChange() {
    const { selection } = editor;
    const shouldPerformMatchCheck = selection && Range.isCollapsed(selection);

    if (!shouldPerformMatchCheck) {
      return setPopoverState({ ...popoverState, target: null });
    }

    const { range: emojiPartialRange, match: emojiPartialMatch } = getRangeAndMatch(
      editor,
      EMOJI_PARTIAL_REGEX,
      EMOJI_MAX_DISTANCE
    );

    if (emojiPartialRange && emojiPartialMatch) {
      setPopoverState({ target: emojiPartialRange, search: emojiPartialMatch, index: 0 });
      return;
    }

    setPopoverState({ target: null, search: '', index: 0 });
  }

  function handleEditorKeyDown(event: KeyboardEvent) {
    if (!target) {
      return;
    }

    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        const prevIndex = index >= list.length - 1 ? 0 : index + 1;
        setPopoverState({ ...popoverState, index: prevIndex });
        break;
      }
      case 'ArrowUp': {
        event.preventDefault();
        const nextIndex = index <= 0 ? list.length - 1 : index - 1;
        setPopoverState({ ...popoverState, index: nextIndex });
        break;
      }
      case 'Tab':
      case 'Enter': {
        event.preventDefault();
        handleInsertEmoji(list[index]);
        setPopoverState({ ...popoverState, target: null });
        break;
      }
      case 'Escape': {
        event.preventDefault();
        setPopoverState({ ...popoverState, target: null });
        break;
      }
    }
  }

  const contextValue: ContextValue = {
    target,
    index,
    list,
    actions: {
      insertEmoji: handleInsertEmoji,
    },
    callbacks: {
      onEditorKeyUp: handleEditorKeyUp,
      onEditorKeyDown: handleEditorKeyDown,
      onEditorChange: handleEditorChange,
    },
  };

  return <EmojiContext.Provider value={contextValue}>{children}</EmojiContext.Provider>;
};

const useEmojiContext = () => {
  return useContext(EmojiContext);
};

export { EmojiContextProvider, useEmojiContext };
