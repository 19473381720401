import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ListItemText, MenuItem, Typography } from '@mui/material';

import { SuggestionResultsObject } from 'api';
import { SuggestionTypes } from 'types/app';
import { getSuggestionResultType } from 'helpers';
import theme from 'theme';

const styles = {
  listItem: {
    paddingTop: 1,
    paddingBottom: 1,
    borderTop: `1px solid ${theme.custom.palette.grey[300]}`,
  },
  weight: {
    fontWeight: theme.typography.fontWeightRegular,
  },
};

interface Props {
  suggestion: SuggestionResultsObject;
  onClick: (type: SuggestionTypes, id: string) => void;
}

const Suggestion: FC<Props> = ({ suggestion, onClick }) => {
  const { t } = useTranslation();
  const type = getSuggestionResultType(suggestion);
  let prefix = '';

  if (type === SuggestionTypes.Group) {
    prefix = `${t('common:group')}:`;
  }

  if (type === SuggestionTypes.Profile) {
    prefix = `${t('common:profile')}:`;
  }

  return (
    <MenuItem key={suggestion.id} sx={styles.listItem} onClick={() => onClick(type, suggestion.id)}>
      <ListItemText disableTypography>
        <Typography variant="subtitle2" noWrap>
          {prefix}{' '}
          <Box component="span" sx={styles.weight}>
            {suggestion.title}
          </Box>
        </Typography>
      </ListItemText>
    </MenuItem>
  );
};

export default Suggestion;
