import React, { FC, ReactNode } from 'react';

import { LoaderTypes } from 'types/app';
import {
  LeaderboardEventScores,
  QueryGetLeaderboardConfigScoresArgs,
  useGetLeaderboardConfigScoresQuery,
} from 'api';
import { QueryManager } from 'components';

interface IProps {
  loader?: LoaderTypes;
  variables: QueryGetLeaderboardConfigScoresArgs;
  children: (data: LeaderboardEventScores[]) => ReactNode;
}

const GetLeaderboardConfigScores: FC<IProps> = ({ children, loader, variables }) => {
  const queryResult = useGetLeaderboardConfigScoresQuery(variables);

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.getLeaderboardConfigScores) {
          return null;
        }

        return children(data.getLeaderboardConfigScores);
      }}
    </QueryManager>
  );
};

export default GetLeaderboardConfigScores;
