import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { RoiIndividualStat } from 'api';
import { formatStat } from 'helpers';
import { ConditionalNumberTooltip, TextTransform } from 'components';

import { ROIChartType } from '../../types';
import { Change } from '../../shared';

interface Props {
  chartType: ROIChartType;
  summary: RoiIndividualStat;
}

const Summary: FC<Props> = ({ chartType, summary }) => {
  const { t } = useTranslation();
  const formattedCurrency = `$${formatStat(summary.currency)}`;

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid>
        <Tooltip title={t(`help:admin_roi_${chartType.toLowerCase()}`)}>
          <Typography variant="subtitle2" color="primary">
            <TextTransform transform="capitalize">
              {t(`analytics:roi_chart_header_${chartType.toLowerCase()}`)}
            </TextTransform>
          </Typography>
        </Tooltip>
        <ConditionalNumberTooltip prefix="$" value={summary.currency}>
          <Typography variant="h2">{formattedCurrency}</Typography>
        </ConditionalNumberTooltip>
        <Change countChange={summary.change} />
      </Grid>
    </Grid>
  );
};

export default Summary;
