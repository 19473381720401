import React, { FC, useEffect } from 'react';
import { FormControl, InputLabel, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { compact } from 'lodash';
import moment from 'moment';

import { Content } from 'api';
import { CustomSVG, DatePickers, ValidatedCheckbox } from 'components';
import { useCapabilities } from 'hooks';
import { USER_FEED_ID } from 'app-constants';

import { usePostContext } from '../../PostContext';
import { PinnedGroupDropdown, Switch } from '../';
import Widget from './WidgetContainer';

const PinPost: FC = () => {
  const { t } = useTranslation();
  const { isAdmin } = useCapabilities();
  const { values, setFieldValue } = useFormikContext<Content>();
  const { state, dispatch } = usePostContext();

  const { is_pinned, group_ids, pinned_to_groups, primary_group_id } = values;

  const fullGroupList =
    primary_group_id && !group_ids.includes(primary_group_id)
      ? [primary_group_id, ...group_ids]
      : [...group_ids];

  useEffect(() => ensurePostPinnedForPinnedTime(), [pinned_to_groups, is_pinned]);

  function ensurePostPinnedForPinnedTime() {
    if (state.pin && !is_pinned && !fullGroupList.length) {
      handleChangeSwitch();
    }
  }

  function getSwitchDisabled() {
    return !fullGroupList.length || fullGroupList.every(id => id === USER_FEED_ID);
  }

  function handleChangeDate(time: number) {
    if (is_pinned || pinned_to_groups.length) {
      setFieldValue('pinned_end_at', time);
    }

    const nextPinnedToGroups = pinned_to_groups.map(pinnedGroup => ({
      group_id: pinnedGroup.group_id,
      pinned_end_at: time,
    }));

    setFieldValue('pinned_to_groups', nextPinnedToGroups);
  }

  function handleChangeSwitch() {
    if (state.pin) {
      setFieldValue('is_pinned', false);
      setFieldValue('pinned_end_at', null);
      setFieldValue('pinned_to_groups', []);
    }

    if (!state.pin) {
      const pinnedEndAt = moment().add(7, 'days').unix();
      const groupsWithoutTimeline = fullGroupList.filter(groupId => groupId !== USER_FEED_ID);

      const pinnedGroups = compact(groupsWithoutTimeline).map(groupId => ({
        group_id: groupId,
        pinned_end_at: pinnedEndAt,
      }));

      setFieldValue('pinned_end_at', pinnedEndAt);
      setFieldValue('pinned_to_groups', pinnedGroups);
    }

    dispatch({ type: 'pin' });
  }

  function renderBody() {
    return (
      <Grid container marginTop={2}>
        <Grid xs={1} />
        <Grid xs={10}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>{t('posting:pinToGroups')}</InputLabel>
                <PinnedGroupDropdown currentlySelected={fullGroupList} />
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <DatePickers.Select
                enableTime
                fullWidth
                label={t('posting:expire')}
                value={values.pinned_end_at}
                setValue={handleChangeDate}
                defaultValue={t('posting:never')}
              />
            </Grid>
          </Grid>
          {isAdmin && (
            <Grid container>
              <Grid>
                <ValidatedCheckbox
                  name="is_pinned"
                  label={t('posting:pinToTimeline')}
                  color="primary"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <Widget
      titleKey="posting:pin"
      subtitleKey={isAdmin ? 'posting:pinGuidelineAdmin' : 'posting:pinGuidelineModerator'}
      icon={<CustomSVG name="pin" color={state.pin ? 'inherit' : 'disabled'} />}
      toggle={
        <Switch
          data-testid="pinPost"
          checked={state.pin}
          onChange={handleChangeSwitch}
          color="primary"
          disabled={getSwitchDisabled()}
        />
      }
    >
      {state.pin && renderBody()}
    </Widget>
  );
};

export default PinPost;
