import React, { FC, useState } from 'react';
import { Box, Button, Unstable_Grid2 as Grid, TextField, Typography } from '@mui/material';

import {
  ProviderType,
  SsoConfiguration,
  useGetIdentityProviderForClientQuery,
  useUpdateProviderDetailsForClientMutation,
} from 'api';
import { Loaders } from 'components';
import { useNotify } from 'hooks';

interface IProps {
  client_id: string;
  onCompleted: (sso_configuration: SsoConfiguration) => void;
}

const IdentityProviderForm: FC<IProps> = ({ client_id, onCompleted }) => {
  const [isNotSaml, setIsNotSaml] = useState(false);
  const [url, setUrl] = useState('');
  const [file, setFile] = useState('');
  const notify = useNotify();

  const { isLoading: loading } = useGetIdentityProviderForClientQuery(
    {
      client_id,
    },
    {
      onError: notify.queryError,
      onSuccess: data => {
        const { getIdentityProviderForClient } = data;
        if (!getIdentityProviderForClient) {
          return;
        }
        if (getIdentityProviderForClient.provider_type !== ProviderType.Saml) {
          setIsNotSaml(true);
          return;
        }
        setFile(getIdentityProviderForClient.provider_details.metadata_file);
        setUrl(getIdentityProviderForClient.provider_details.metadata_u_r_l);
      },
    }
  );

  const { mutate: updateProviderDetailsForClient, isLoading: mutationLoading } =
    useUpdateProviderDetailsForClientMutation({
      onError: notify.mutationError,
      onSuccess: handleSaveSuccess,
    });

  function handleSaveSuccess(data: any) {
    onCompleted(data.updateProviderDetailsForClient.sso_configuration);
  }

  function handleSave() {
    updateProviderDetailsForClient({
      client_id,
      provider_type: ProviderType.Saml,
      provider_details: {
        metadata_file: file,
        metadata_u_r_l: url,
      },
    });
  }

  if (loading || mutationLoading) {
    return <Loaders.Standard />;
  }

  if (isNotSaml) {
    return (
      <Grid container direction="column">
        <Grid>
          <Typography>
            This client uses OIDC, which is currently not supported in this interface.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column">
      <Grid>
        <Box pb={2}>
          <TextField
            variant="standard"
            fullWidth
            label="URL"
            value={url}
            disabled={!!file}
            onChange={event => setUrl(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Grid>
      <Grid>
        <Box pb={2}>
          <TextField
            variant="standard"
            fullWidth
            label="Metadata"
            value={file}
            disabled={!!url}
            onChange={event => setFile(event.target.value)}
            InputLabelProps={{ shrink: true }}
            rows={1}
            maxRows={25}
            multiline={true}
          />
        </Box>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={() => handleSave()}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IdentityProviderForm;
