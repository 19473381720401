import React, { FC, useLayoutEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const styles = {
  root: {
    position: 'sticky',
    overflowY: 'auto',
    left: 0,
    right: 'auto',
    bottom: 0,
    minWidth: 220,
  },
} as const;

const StickySidebar: FC = ({ children }) => {
  const theme = useTheme();

  useLayoutEffect(() => {
    const headerEl = document.querySelector('header');
    const { offsetHeight } = headerEl;

    const calculatedOffset = offsetHeight + theme.custom.bodyPaddingOffset;
    const channelEl: HTMLDivElement = document.querySelector('#sticky-sidebar');
    channelEl.style.top = `${calculatedOffset}px`;
    channelEl.style.maxHeight = `calc(100vh - ${
      calculatedOffset + theme.custom.bodyPaddingOffset
    }px`;
  }, []);

  return (
    <Box id="sticky-sidebar" sx={styles.root}>
      {children}
    </Box>
  );
};

export default StickySidebar;
