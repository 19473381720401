import { useBootstrapQuery } from 'api';
import useCognitoUser from './useCognitoUser';

/**
 * The bootstrap query is unique in that it is the one place where the cognito user id should be used rather than the current user id.
 * We have this hook to make that as explicit as possible
 */
const useBootstrapQueryKey = () => {
  const cognitoUser = useCognitoUser();
  const queryKey = useBootstrapQuery.getKey({ user_id: cognitoUser.id });
  return queryKey;
};

export default useBootstrapQueryKey;
