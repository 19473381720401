import { createContext, Dispatch, useContext } from 'react';
import { ExternalShareOption, IPostUIState, PostOption, PostUIStateAction } from './types';
import { ACTIVE_THUMBNAIL_COUNT } from './constants';

interface IPostContext {
  state: IPostUIState;
  dispatch: Dispatch<PostUIStateAction>;
}

export const initialPostUIState: IPostUIState = {
  option: PostOption.Link,
  shareOption: ExternalShareOption.LinkShare,
  moderatorMode: true,
  repost: false,
  engagePost: false,
  brandedPost: false,
  validUrl: false,
  pin: false,
  schedule: false,
  commentary: false,
  contentTagging: false,
  disclosures: false,
  tracking: false,
  share: false,
  msteams: false,
  slack: false,
  previousMSTeamsPost: false,
  previousSlackPost: false,
  editing: false,
  requiresApproval: false,
  multipleGroupPosting: false,
  selectedPhotoIndexes: [],
  showShareWidget: true,
};

export const PostUIContext = createContext<IPostContext>({
  state: initialPostUIState,
  dispatch: () => null,
});

export function postContextReducer(prevState: IPostUIState, action: PostUIStateAction) {
  function updateSelectedPhotoIndexes(index: number) {
    const { selectedPhotoIndexes } = prevState;

    if (selectedPhotoIndexes.includes(index)) {
      return selectedPhotoIndexes.filter(idx => idx !== index);
    }

    if (selectedPhotoIndexes.length === ACTIVE_THUMBNAIL_COUNT) {
      return selectedPhotoIndexes.filter(idx => idx !== index);
    } else {
      selectedPhotoIndexes.push(index);
      return selectedPhotoIndexes;
    }
  }

  function swapSelectedPhotoIndexes([idx1, idx2]: [number, number]) {
    const { selectedPhotoIndexes } = prevState;
    const index1 = selectedPhotoIndexes.indexOf(idx1);
    const index2 = selectedPhotoIndexes.indexOf(idx2);

    [selectedPhotoIndexes[index1], selectedPhotoIndexes[index2]] = [
      selectedPhotoIndexes[index2],
      selectedPhotoIndexes[index1],
    ];
    return selectedPhotoIndexes;
  }

  switch (action.type) {
    case 'option':
      return { ...prevState, option: action.value };
    case 'shareOption':
      return { ...prevState, shareOption: action.value };
    case 'validUrl':
      return { ...prevState, validUrl: action.value };
    case 'engagePost':
      return { ...prevState, engagePost: action.value };
    case 'brandedPost':
      return { ...prevState, brandedPost: action.value };
    case 'share':
      return { ...prevState, share: action.value };
    case 'msteams':
      return { ...prevState, msteams: action.value };
    case 'previousSlackPost':
      return { ...prevState, previousSlackPost: action.value };
    case 'previousMSTeamsPost':
      return { ...prevState, previousMSTeamsPost: action.value };
    case 'slack':
      return { ...prevState, slack: action.value };
    case 'tracking':
      return { ...prevState, tracking: action.value };
    case 'editing':
      return { ...prevState, editing: action.value };
    case 'requiresApproval':
      return { ...prevState, requiresApproval: action.value };
    case 'updatePhotoIndexes':
      return { ...prevState, selectedPhotoIndexes: updateSelectedPhotoIndexes(action.value) };
    case 'swapPhotoIndexes':
      return { ...prevState, selectedPhotoIndexes: swapSelectedPhotoIndexes(action.value) };
    case 'clearPhotoIndexes':
      return { ...prevState, selectedPhotoIndexes: [] };
    case 'moderatorMode':
      return { ...prevState, moderatorMode: action.value };
    case 'repost':
      return { ...prevState, repost: action.value };
    case 'showShareWidget':
      return { ...prevState, showShareWidget: action.value };
    default:
      return { ...prevState, [action.type]: !prevState[action.type] };
  }
}

export const usePostContext = () => useContext(PostUIContext);
