import Auth from '@aws-amplify/auth';

const ERROR_CODE = 'NotAuthorizedException';

const jwtToken = async () => {
  try {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  } catch (err) {
    if (err?.code === ERROR_CODE) {
      location.reload();
    }
    return null;
  }
};

export default jwtToken;
