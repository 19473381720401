import React, { FC, useEffect, useState } from 'react';

import { AdminUpdateClientInput } from 'api';
import { getAppImage, getImageSrc } from '../helpers';
import { Loaders } from 'components';
import { IMGIX_HOST } from 'settings';
import { APP_IMAGES } from '../app-constants';

interface IProps {
  size?: number;
  client: AdminUpdateClientInput;
}

const ClientIcon: FC<IProps> = ({ client, size }) => {
  const localUri = (client?.icon?.localUri as unknown as Blob) ?? '';
  const [working, setWorking] = useState(false);
  const [dataUrl, setDataUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    let mounted = true;
    if (localUri) {
      setWorking(true);
      const reader = new FileReader();
      reader.onload = e => {
        if (mounted) {
          setDataUrl(e.target.result as string);
          setWorking(false);
        }
      };
      reader.onerror = () => {
        if (mounted) {
          setDataUrl(null);
          setWorking(false);
        }
      };
      reader.readAsDataURL(localUri);
    }
    return () => {
      mounted = false;
    };
  }, [client.icon]);

  function getSrcAttrs() {
    if (dataUrl) {
      return {
        src: dataUrl,
        style: { height: size, width: size },
      };
    }

    const { key } = client.icon;
    const imageUrl = `${IMGIX_HOST}/${key}?h=${size}&auto=format`;
    return getImageSrc(imageUrl);
  }

  if (!client.icon) {
    return <img {...getAppImage(APP_IMAGES.Icon, size)} />;
  }

  if (working) {
    return <Loaders.Standard />;
  }

  return <img {...getSrcAttrs()} alt="workspace-icon" />;
};

ClientIcon.defaultProps = {
  size: 48,
};

export default ClientIcon;
