import { Network } from 'api';
import useCapabilities from './useCapabilities';

export default () => {
  const { needs } = useCapabilities();

  const hasTwitter = needs('UserConnectNetworks.Function.ConnectTwitterAccount');
  const hasLinkedin = needs('UserConnectNetworks.Function.ConnectLinkedInAccount');
  const hasSlack = needs('UserConnectNetworks.Function.ConnectSlackAccount');
  const hasMSTeams = needs('UserConnectNetworks.Function.ConnectMsTeamsAccount');

  const hasTwitterSharing = needs('Sharing.Function.ShareToTwitter');
  const hasLinkedinSharing = needs('Sharing.Function.ShareToLinkedIn');
  const hasFacebookSharing = needs('Sharing.Function.ShareToFacebook');

  function hasNetwork(network: Network) {
    if (network === Network.Twitter) {
      return hasTwitter;
    }

    if (network === Network.Linkedin) {
      return hasLinkedin;
    }

    if (network === Network.Slack) {
      return hasSlack;
    }

    if (network === Network.Msteams) {
      return hasMSTeams;
    }

    return false;
  }

  function hasSharing(network: Network) {
    if (network === Network.Twitter) {
      return hasTwitterSharing;
    }

    if (network === Network.Linkedin) {
      return hasLinkedinSharing;
    }

    if (network === Network.Facebook) {
      return hasFacebookSharing;
    }

    return false;
  }

  return {
    hasTwitter,
    hasLinkedin,
    hasSlack,
    hasMSTeams,
    hasTwitterSharing,
    hasLinkedinSharing,
    hasFacebookSharing,
    hasNetwork,
    hasSharing,
  };
};
