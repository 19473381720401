import { useState } from 'react';

import { CapabilityGroup, useToggleCapabilityGroupMutation } from 'api';
import { UseCapabilityToggle } from 'types/app';

import useCapabilities from './useCapabilities';
import useProtectedClient from './useProtectedClient';

const useCapabilityToggle = (
  name: string,
  capabilityGroup: CapabilityGroup
): UseCapabilityToggle => {
  const { needs } = useCapabilities();
  const client = useProtectedClient();
  const { mutateAsync, isLoading } = useToggleCapabilityGroupMutation({});

  const isActive = needs(name);
  const [current, setCurrent] = useState(isActive);
  const isDirty = isActive !== current;

  function save() {
    if (!isDirty) {
      return Promise.resolve();
    }

    return mutateAsync({
      client_id: client.id,
      active: current,
      capability_group: capabilityGroup,
    });
  }

  return {
    save,
    isLoading,
    isDirty,
    isActive: current,
    onChange: setCurrent,
  };
};

export default useCapabilityToggle;
