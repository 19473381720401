import { createContext } from 'react';
import {
  GetContentQueryVariables,
  GetFeedQueryVariables,
  GetGroupFollowersQueryVariables,
  SearchContentQueryVariables,
} from 'api';

type AcceptedVariables =
  | GetContentQueryVariables
  | GetFeedQueryVariables
  | GetGroupFollowersQueryVariables
  | SearchContentQueryVariables;

const QueryVariablesContext = createContext<AcceptedVariables>({});
const QueryVariablesProvider = QueryVariablesContext.Provider;

export { QueryVariablesContext, QueryVariablesProvider };
