import React, {
  ChangeEvent,
  FC,
  HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { AutocompleteChangeReason } from '@mui/material/useAutocomplete';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { intersection, throttle } from 'lodash';
import parse from 'autosuggest-highlight/parse';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { AddressInput } from 'api';
import { GOOGLE_MAPS_API_KEY } from 'settings';
import theme from 'theme';

type PlaceResult = google.maps.places.PlaceResult;
type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type PlacesServiceStatus = google.maps.places.PlacesServiceStatus;

interface Props {
  name: string;
}

const MATCHERS = {
  number: ['street_number'],
  street: ['route'],
  city: ['locality', 'sublocality', 'sublocality_level_1', 'postal_town'],
  state: ['administrative_area_level_1'],
  zip: ['postal_code'],
  country: ['country'],
};

const EMPTY_STATE = {
  address: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  address2: '',
  google_place_id: '',
};

const styles = {
  icon: {
    color: 'text.secondary',
    marginRight: 2,
  },
} as const;

const autocompleteClasses = {
  paper: {
    margin: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  clearIndicator: {
    marginRight: theme.spacing(),
    '&:hover': {
      backgroundColor: 'grey.300',
    },
  },
  option: {
    fontWeight: theme.typography.fontWeightRegular,
    '&[aria-selected="true"]': {
      color: 'text.primary',
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      color: 'text.primary',
    },
    '&:active': {
      color: 'text.primary',
      backgroundColor: 'transparent',
    },
  },
} as const;

const AddressPicker: FC<Props> = ({ name }) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField<AddressInput>(name);
  const hasError = !!meta.error && !!meta.touched;

  const [value, setValue] = useState<AutocompletePrediction | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<AutocompletePrediction[]>([]);

  const autocompleteService = useRef(null);

  const fetch = useMemo(() => {
    return throttle((request, callback) => {
      autocompleteService.current.getPlacePredictions(request, callback);
    }, 200);
  }, []);

  useEffect(() => {
    void fetchPlace();
  }, [value, inputValue, fetch]);

  async function loadGoogleMaps() {
    const { Loader } = await import('@googlemaps/js-api-loader');
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      libraries: ['places'],
      version: 'weekly',
    });

    await loader.load();
    autocompleteService.current = new google.maps.places.AutocompleteService();
  }

  function handleAddressParsing(place: PlaceResult, status: PlacesServiceStatus) {
    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      return;
    }

    const { address_components } = place;
    const address: AddressInput = {
      ...EMPTY_STATE,
      address2: field.value.address2,
      google_place_id: place.place_id,
    };

    address_components.forEach(addressComponent => {
      for (const matchTest in MATCHERS) {
        const componentType = addressComponent.types;
        const matcherType = MATCHERS[matchTest];
        const isMatch = !!intersection(componentType, matcherType).length;

        if (isMatch) {
          if (matchTest === 'number') {
            address.address = addressComponent.long_name;
          } else if (matchTest === 'street') {
            address.address = `${address.address} ${addressComponent.long_name}`;
          } else if (matchTest === 'country') {
            address.country = addressComponent.short_name;
          } else {
            address[matchTest] = addressComponent.long_name;
          }
        }
      }
    });

    helpers.setValue(address);
  }

  function handleChange(
    event: ChangeEvent,
    newValue: AutocompletePrediction | null,
    reason: AutocompleteChangeReason
  ) {
    if (reason === 'selectOption') {
      setOptions([newValue, ...options]);
      fetchPlaceDetails(newValue);
    }

    if (reason === 'clear') {
      helpers.setValue(EMPTY_STATE);
    }

    setValue(newValue);
  }

  function fetchPlaceDetails(nextPlace: AutocompletePrediction) {
    const request = {
      placeId: nextPlace.place_id,
      fields: ['address_components', 'place_id'],
    };

    const service = new google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails(request, handleAddressParsing);
  }

  async function fetchPlace() {
    let active = true;

    if (!autocompleteService.current) {
      await loadGoogleMaps();
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, types: ['address'] }, (results?: AutocompletePrediction[]) => {
      if (!active) {
        return;
      }

      const nextResults = results.filter(result => {
        return result.types.includes('street_address') || result.types.includes('premise');
      });

      let newOptions = [] as AutocompletePrediction[];
      if (value) {
        newOptions = [value];
      }
      if (nextResults) {
        newOptions = [...newOptions, ...nextResults];
      }
      setOptions(newOptions);
    });

    return () => {
      active = false;
    };
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        variant="standard"
        {...params}
        fullWidth
        error={hasError}
        placeholder={t('components:businessAddress')}
        label={t('components:businessAddress')}
        helperText={hasError && t('common:required')}
      />
    );
  }

  function renderOption(props: HTMLAttributes<HTMLLIElement>, option: AutocompletePrediction) {
    const matches = option.structured_formatting.main_text_matched_substrings;
    const parts = parse(
      option.structured_formatting.main_text,
      matches.map(match => [match.offset, match.offset + match.length])
    );

    return (
      <Grid container alignItems="center">
        <Grid>
          <LocationOnIcon sx={styles.icon} />
        </Grid>
        <Grid xs>
          {parts.map((part, index) => (
            <Typography
              display="inline"
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </Typography>
          ))}
          <Typography color="textSecondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Autocomplete
      fullWidth
      autoComplete
      includeInputInList
      filterSelectedOptions
      options={options}
      sx={autocompleteClasses}
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      filterOptions={x => x}
      popupIcon={null}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  );
};

export default AddressPicker;
