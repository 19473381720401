import React, { ElementType, FC } from 'react';
import {
  Grid,
  Link,
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';

interface IProps {
  title: string;
  type: string;
  component: ElementType;
}

const TableHead: FC<IProps> = ({ title, type, component }) => (
  <MuiTableHead>
    <TableRow>
      <TableCell variant="head">
        <Link component={component} underline="none">
          <Grid container alignItems="center">
            <Grid>
              <Typography variant="body2">{title}</Typography>
            </Grid>
            <Grid>
              <ChevronRight color="action" fontSize="large" />
            </Grid>
          </Grid>
        </Link>
      </TableCell>
      <TableCell variant="head" align="right">
        <Typography>{type}</Typography>
      </TableCell>
    </TableRow>
  </MuiTableHead>
);

export default TableHead;
