import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { yupPasswordValidation } from 'helpers';

interface Props {
  name: string;
}

const PasswordRequirements: FC<Props> = ({ name }) => {
  const { t } = useTranslation();
  const [field, meta] = useField({ name, validate });

  const hasBasicError = (!meta.error && !field.value) || meta.error?.includes('required');
  const hasMinMaxError = meta.error?.includes('min') || meta.error?.includes('max');

  const hasCharacterError = hasBasicError || hasMinMaxError;
  const hasLowercaseError = hasBasicError || meta.error?.includes('minLowercase');
  const hasUppercaseError = hasBasicError || meta.error?.includes('minUppercase');
  const hasNumberError = hasBasicError || meta.error?.includes('minNumbers');
  const hasSpecialError = hasBasicError || meta.error?.includes('minSymbols');

  function validate(value: string) {
    let requirementsErrors;

    try {
      yupPasswordValidation.validateSync(value, { abortEarly: false });
    } catch (validationError) {
      requirementsErrors = validationError.errors;
    }

    return requirementsErrors;
  }

  return (
    <Fragment>
      <Typography gutterBottom variant="body2">
        {t('user:passwordContain')}
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            {hasCharacterError ? <CloseIcon color="error" /> : <CheckIcon color="secondary" />}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primaryTypographyProps={{ variant: 'body1' }}
            primary={t('user:passwordCharacters')}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {hasUppercaseError ? <CloseIcon color="error" /> : <CheckIcon color="secondary" />}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primaryTypographyProps={{ variant: 'body1' }}
            primary={t('user:passwordUppercase')}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {hasLowercaseError ? <CloseIcon color="error" /> : <CheckIcon color="secondary" />}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primaryTypographyProps={{ variant: 'body1' }}
            primary={t('user:passwordLowercase')}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {hasNumberError ? <CloseIcon color="error" /> : <CheckIcon color="secondary" />}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primaryTypographyProps={{ variant: 'body1' }}
            primary={t('user:passwordNumber')}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {hasSpecialError ? <CloseIcon color="error" /> : <CheckIcon color="secondary" />}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primaryTypographyProps={{ variant: 'body1' }}
            primary={t('user:passwordSpecial')}
          />
        </ListItem>
      </List>
    </Fragment>
  );
};

export default PasswordRequirements;
