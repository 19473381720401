import React, { FC, ReactElement } from 'react';

import {
  ClientPublicFragment,
  ClientStatus,
  GetActiveClientByAttrQueryVariables,
  useGetActiveClientByAttrQuery,
} from 'api/public';
import { ErrorPage, Loaders } from 'components';
import { useNotify } from 'hooks';
import { configureForClient } from 'config/amplify';
import { getSubdomainUrl } from 'helpers';

interface IProps {
  variables: GetActiveClientByAttrQueryVariables;
  children(client: ClientPublicFragment): ReactElement;
}

const GetActiveClientByAttr: FC<IProps> = ({ variables, children }) => {
  const notify = useNotify();

  const { data, isLoading } = useGetActiveClientByAttrQuery(variables, {
    onError: notify.queryError,
  });

  if (isLoading) {
    return <Loaders.Startup />;
  }

  configureForClient(data.getActiveClientByAttr);

  if (!data?.getActiveClientByAttr) {
    return <ErrorPage type="no-client" />;
  }

  const { getActiveClientByAttr } = data;
  const { client_status, subdomain } = getActiveClientByAttr;

  const isValidClient = client_status === ClientStatus.Active;

  if (!isValidClient) {
    return <ErrorPage type="no-client" />;
  }

  if (subdomain !== variables.value) {
    const subdomainUrl = getSubdomainUrl(subdomain);
    location.replace(`${subdomainUrl}${location.pathname}`);
    return null;
  }
  return children(getActiveClientByAttr);
};

export default GetActiveClientByAttr;
