import React, { FC, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import Auth from '@aws-amplify/auth';

import { useGetUserClientsQuery, UserClientStatus } from 'api';
import { Loaders } from 'components';
import { getNextLogoutUrl } from 'helpers';
import { useGlobalClient } from 'hooks';
import { ROOT_DOMAIN_LABEL_FOR_LOGOUT } from 'app-constants';
import { isRoot } from 'utilities';
import cookieStorage from 'cookie-storage';

const Logout: FC = () => {
  const [fetchSubdomains, setFetchSubdomains] = useState(false);
  const queryClient = useQueryClient();
  const client = useGlobalClient();

  const searchParams = new URLSearchParams(window.location.search);
  const subdomains = searchParams.get('subdomains') || '';
  const currentSubdomain = window.location.hostname.split('.')[0];

  const { data } = useGetUserClientsQuery(
    {},
    {
      enabled: fetchSubdomains,
    }
  );

  useEffect(() => shouldFetchSubdomains(), []);
  useEffect(() => prepareFetchedSubdomains(), [data?.getUserClients]);
  useEffect(() => handleActiveSubdomain(), []);

  function shouldFetchSubdomains() {
    if (!subdomains) {
      setFetchSubdomains(true);
    }
  }

  function prepareFetchedSubdomains() {
    if (!data?.getUserClients) {
      return;
    }

    const { getUserClients } = data;
    const activeClients = getUserClients.items.filter(
      item => item.user_client_status === UserClientStatus.Active
    );
    const reducedSubdomains = activeClients.reduce((list, item) => {
      const subdomain = item.client.subdomain.split('.')[0];
      return subdomain === currentSubdomain && !isRoot() ? list : list.concat(subdomain);
    }, []);

    reducedSubdomains.push(ROOT_DOMAIN_LABEL_FOR_LOGOUT);

    const nextSubdomain = reducedSubdomains.shift();
    const remainingSubdomains = reducedSubdomains.join(',');
    const nextUrl = getNextLogoutUrl(remainingSubdomains, nextSubdomain);

    // The user is leaving the site. Store the next URL for when they come back
    if (client?.sso_configuration) {
      cookieStorage.logout = nextUrl;
    }

    void logout(nextUrl);
  }

  function handleActiveSubdomain() {
    if (!subdomains) {
      return;
    }

    const nextSubdomain = subdomains.substring(0, subdomains.indexOf(',')) || subdomains;
    const remainingSubdomains = subdomains.substring(
      subdomains.indexOf(',') + 1,
      subdomains.length
    );
    const nextUrl = getNextLogoutUrl(remainingSubdomains, nextSubdomain);

    // The user is leaving the site. Store the next URL for when they come back
    if (client?.sso_configuration) {
      cookieStorage.logout = nextUrl;
    }

    void logout(nextUrl);
  }

  async function logout(nextUrl: string) {
    await queryClient.cancelQueries();

    queryClient.clear();
    cookieStorage.removeAll();

    await Auth.signOut();

    window.location.replace(nextUrl);
  }

  return <Loaders.Logout />;
};

export default Logout;
