import React, { FC } from 'react';
import { useFormikContext } from 'formik';

import { CustomSVG } from 'components';
import { ContentInput } from 'api';

import { Switch } from '../';
import Widget from './WidgetContainer';

const MarkImportant: FC = () => {
  const { values, setFieldValue } = useFormikContext<ContentInput>();
  const { is_important } = values;

  function onChangeSwitch() {
    setFieldValue('is_important', !is_important);
  }

  return (
    <Widget
      titleKey="posting:markImportantTitle"
      subtitleKey="posting:markImportantSubhead"
      icon={<CustomSVG name="flag" color={is_important ? 'inherit' : 'disabled'} />}
      toggle={
        <Switch
          checked={is_important}
          onChange={onChangeSwitch}
          color="primary"
          data-testid="post-radio-markImportant"
        />
      }
    />
  );
};

export default MarkImportant;
