import React, { FC, useEffect, useState } from 'react';

import { AdminUpdateClientInput, CreateClientInput, CsUpdateClientInput } from 'api';
import { Loaders } from 'components';
import { IMGIX_HOST } from 'settings';

import { getAppImage, getImageSrc } from '../helpers';
import { APP_IMAGES } from '../app-constants';

interface IProps {
  width?: number;
  height?: number;
  disableMargin?: boolean;
  client: CreateClientInput | CsUpdateClientInput | AdminUpdateClientInput;
}

const styles = {
  logo: {
    maxWidth: '100%',
    marginBottom: 3,
  },
} as const;

const ClientLogo: FC<IProps> = ({ width, disableMargin, height, client }) => {
  const className = !disableMargin
    ? { ...styles.logo, ...(width && { maxWidth: width }) }
    : { ...(width && { maxWidth: width }) };
  const localUri = (client?.image?.localUri as unknown as Blob) ?? '';

  const [working, setWorking] = useState(false);
  const [dataUrl, setDataUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    let mounted = true;
    if (localUri) {
      setWorking(true);
      const reader = new FileReader();
      reader.onload = e => {
        if (mounted) {
          setDataUrl(e.target.result as string);
          setWorking(false);
        }
      };
      reader.onerror = () => {
        if (mounted) {
          setDataUrl(null);
          setWorking(false);
        }
      };
      reader.readAsDataURL(localUri);
    }
    return () => {
      mounted = false;
    };
  }, [client.image]);

  function getSrcAttrs() {
    if (dataUrl) {
      return {
        src: dataUrl,
        style: { height },
      };
    }

    const { key } = client.image;
    const imageUrl = `${IMGIX_HOST}/${key}?h=${height}&auto=format`;
    return getImageSrc(imageUrl);
  }

  if (!client.image) {
    return <img style={className} {...getAppImage(APP_IMAGES.Logo, height)} />;
  }

  if (working) {
    return <Loaders.Standard />;
  }

  return <img style={className} {...getSrcAttrs()} alt="workspace-logo" />;
};

ClientLogo.defaultProps = {
  height: 120,
  disableMargin: false,
};

export default ClientLogo;
