import React, { FC, Fragment } from 'react';
import { AppBar, Box, Toolbar, Unstable_Grid2 as Grid } from '@mui/material';
import { times } from 'lodash';

import { LoaderComponents, Loaders } from 'components';

const Startup: FC = () => {
  const { Content, Image } = LoaderComponents;

  return (
    <Fragment>
      <AppBar>
        <Toolbar>
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid>
              <Content height={20} maxWidth={300} />
            </Grid>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid>
                <Image shape="circular" />
              </Grid>
              <Grid>
                <Image shape="circular" />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box paddingTop={4} paddingBottom={4} maxWidth={1260} margin="0 auto 100px">
        <Grid container spacing={3}>
          <Grid lg={5} xs={12}>
            <Loaders.Feed />
          </Grid>
          <Grid lg={7} xs={12}>
            <Grid container direction="column" wrap="nowrap" spacing={3}>
              {times(2, rowIndex => (
                <Grid key={rowIndex}>
                  <Loaders.Widget />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <AppBar position="fixed" style={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid>
              <Content height={20} maxWidth={300} />
            </Grid>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid>
                <Image shape="circular" />
              </Grid>
              <Grid>
                <Image shape="circular" />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

Startup.defaultProps = {
  appBar: true,
};

export default Startup;
