import * as yup from 'yup';

import {
  contentSchema,
  itemSchema,
  itemStateSchema,
  networkMetaSchema,
  sharingSchema,
  socialAccountSchema,
} from './validations';

export type Share = yup.InferType<typeof sharingSchema>;
export type ShareItem = yup.InferType<typeof itemSchema>;
export type ShareItemState = yup.InferType<typeof itemStateSchema>;
export type ShareItemNetworkMeta = yup.InferType<typeof networkMetaSchema>;
export type ShareItemSocialAccount = yup.InferType<typeof socialAccountSchema>;
export type ShareItemContent = yup.InferType<typeof contentSchema>;

export enum ShareDisplayOption {
  Page = 'page',
  Popup = 'popup',
  iFrame = 'iframe',
}

export enum ShareConnectionOption {
  Popup = 'popup',
  Page = 'page',
}
