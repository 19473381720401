import React, { FC } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Unstable_Grid2 as Grid,
  TextField,
} from '@mui/material';

import { useAdminSetPasswordMutation, UserProfileSearchFragment } from 'api';
import { Loaders, PasswordRequirements, ValidatedTextFieldNext } from 'components';
import { useNotify } from 'hooks';
import * as yup from 'yup';

interface Props {
  user: UserProfileSearchFragment;
  open: boolean;
  onClose: () => void;
}

const ResetPasswordDialog: FC<Props> = ({ user, open, onClose }) => {
  const notify = useNotify();

  const { mutate, isLoading } = useAdminSetPasswordMutation({
    onError: notify.mutationError,
    onSuccess: () => {
      notify.info({ message: 'Password changed successfully' });
      onClose();
    },
  });

  const initialValues = {
    user_id: user.id,
    password: '',
  };

  const validationSchema = yup.object().shape({
    user_id: yup.string().required(),
  });

  function handleSubmit(values: typeof initialValues) {
    mutate(values);
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Reset User Password</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <FormikForm>
            <TextField
              variant="standard"
              name="user_id"
              fullWidth
              value={user.email_address}
              disabled
            />
            <Box pt={2}>
              <ValidatedTextFieldNext
                fullWidth
                disableError
                name="password"
                type="password"
                placeholder="Password"
                autoComplete="new-password"
                InputLabelProps={{ shrink: true }}
              />
              <Box marginTop={2}>
                <PasswordRequirements name="password" />
              </Box>
            </Box>
            <Box pt={2}>
              <Grid container justifyContent="flex-end">
                <Grid>
                  <Button disabled={isLoading} type="submit" variant="contained" color="primary">
                    {isLoading ? <Loaders.Standard height={24} /> : 'Reset Password'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </FormikForm>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordDialog;
