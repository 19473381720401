import React, { forwardRef, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VideoJsPlayer } from 'video.js';
import { Box, Fade, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import OnDemandVideo from '@mui/icons-material/OndemandVideo';
import theme from 'theme';

import { S3VideoObject, S3VideoObjectInput } from 'api';
import { IMGIX_HOST, VIDEO_HOST } from 'settings';

interface Props {
  video: Partial<S3VideoObjectInput> & S3VideoObject;
  disableHeight?: boolean;
  processing?: boolean;
  autoPlay?: boolean;
}

enum VideoFormats {
  MP4 = 'video/mp4',
  OGG = 'video/ogg',
  WebM = 'video/webm',
  QT = 'video/quicktime',
}

const SUPPORTED_PREVIEW_FORMATS = [
  VideoFormats.MP4,
  VideoFormats.OGG,
  VideoFormats.WebM,
  VideoFormats.QT,
];

const styles = {
  '@global': {
    '.vjs-poster:active': {
      outline: 'none',
    },
    '.video-js .vjs-big-play-button > .vjs-icon-placeholder': {
      opacity: 0,
    },
    '.video-js .vjs-big-play-button': {
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${IMGIX_HOST}/webapp/video.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '80px',
      backgroundPosition: '50% calc(50%)',
      border: 0,
      boxShadow: 0,
      '&:before': { content: '""', display: 'none' },
      '&:hover': { backgroundColor: 'transparent', opacity: 0.9 },
    },
  },
  root: {
    position: 'relative',
  },
  frame: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `linear-gradient(to right, ${theme.palette.grey[200]} 0%, ${theme.palette.grey.A100} 70%, ${theme.palette.grey[300]} 100%)`,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 1,
    opacity: 1,
  },
  frameHidden: {
    opacity: 0,
    zIndex: -1,
  },
  icon: {
    fontSize: '5rem',
  },
  unsupported: {
    maxWidth: '75%',
    margin: `${theme.spacing(1)} auto 0`,
  },
} as const;

const Video = forwardRef<HTMLVideoElement, Props>(function Video(props, ref) {
  const { processing, video, disableHeight, autoPlay } = props;

  const [loading, setLoading] = useState(true);
  const [supported] = useState(isSupported());
  const { t } = useTranslation();
  const videoElementRef = ref as MutableRefObject<HTMLVideoElement>;
  const videoPlayerRef = useRef<VideoJsPlayer>(null);

  const rootClasses = !disableHeight && styles.root;
  const containerClasses = { ...styles.frame, ...(!loading && styles.frameHidden) };

  function isSupported() {
    if (!video.localUri) {
      return true;
    }

    const { type } = video.localUri as unknown as Blob;
    return SUPPORTED_PREVIEW_FORMATS.includes(type as VideoFormats);
  }

  function getSource() {
    // Local
    if (video.localUri) {
      const { type } = video.localUri as unknown as Blob;
      const fileType = type === VideoFormats.QT ? VideoFormats.MP4 : type;

      return {
        sources: [
          {
            src: URL.createObjectURL(video.localUri),
            type: fileType,
          },
        ],
      };
    }

    // Remote
    return {
      poster: `${IMGIX_HOST}/${video.poster}?w=640&h=360&auto=format`,
      sources: [
        {
          src: `${VIDEO_HOST}/${video.playlist}`,
          type: 'application/x-mpegURL',
        },
      ],
    };
  }

  useEffect(() => {
    async function loadVideo() {
      const videoJs = await import('video.js');

      const options = {
        controls: true,
        fluid: true,
        ...getSource(),
        autoplay: autoPlay ? 'play' : false,
      };

      videoPlayerRef.current = videoJs.default(videoElementRef.current, options, () => {
        setLoading(false);
      });
    }

    if (supported && !processing) {
      void loadVideo();
    }

    return () => {
      videoPlayerRef.current?.dispose();
      if (video.localUri) {
        URL.revokeObjectURL(video.localUri);
      }
    };
  }, [video.key, video.playlist]);

  return (
    <Box sx={rootClasses}>
      <Box sx={containerClasses}>
        <Grid container direction="column" wrap="nowrap" alignItems="center">
          <Grid>
            <OnDemandVideo color="disabled" sx={styles.icon} />
          </Grid>
          {!supported && (
            <Grid>
              <Typography variant="body2" align="center" sx={styles.unsupported}>
                {t('posting:unsupportedVideo')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <Fade in={!loading} timeout={500}>
        <div key={video.key}>
          <video className="video-js" ref={videoElementRef} />
        </div>
      </Fade>
    </Box>
  );
});

Video.defaultProps = {
  disableHeight: false,
  autoPlay: false,
  processing: false,
};

export default Video;
