import { FC } from 'react';

import { getNextLogoutUrl } from 'helpers';

const PublicLogout: FC = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const subdomains = searchParams.get('subdomains') || '';
  const nextSubdomain = subdomains.substring(0, subdomains.indexOf(',')) || subdomains;
  const remainingSubdomains = subdomains.substring(subdomains.indexOf(',') + 1, subdomains.length);
  const nextUrl = getNextLogoutUrl(remainingSubdomains, nextSubdomain);

  window.location.replace(nextUrl);

  return null;
};

export default PublicLogout;
