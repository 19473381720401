import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { ImageType } from './types';
import { Loaders } from 'components';
import { IMGIX_HOST } from 'settings';
import theme from 'theme';

interface Props {
  open: boolean;
  image: ImageType;
  filePathKey: string;
  onClose(): void;
}

const styles = {
  dialog: {
    backgroundColor: alpha(theme.palette.common.black, 0.6),
  },
  dialogTitle: {
    margin: 0,
    paddingTop: 1,
    borderBottom: 0,
  },
  dialogContent: {
    display: 'flex',
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    display: 'block',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  white: {
    color: 'common.white',
  },
} as const;

const ImageViewer: FC<Props> = ({ image, filePathKey, open, onClose }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const fullPhotoUrl = `${IMGIX_HOST}/${image[filePathKey]}?auto=format`;

  function toggleDialog() {
    onClose();
    setLoading(false);
  }

  return (
    <Dialog fullScreen open={open} onClose={toggleDialog} sx={{ paperFullScreen: styles.dialog }}>
      <DialogTitle sx={styles.dialogTitle}>
        <Typography variant="subtitle2" sx={styles.white}>
          {t('components:photoViewer')}
          {loading && (
            <Fragment>
              {' '}
              <Loaders.Standard height={10} />
            </Fragment>
          )}
        </Typography>
        <IconButton onClick={toggleDialog} size="large">
          <CloseIcon sx={styles.white} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <img
          alt="EveryoneSocial Image"
          style={styles.image}
          src={fullPhotoUrl}
          onLoad={() => setLoading(false)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImageViewer;
