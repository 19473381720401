import React, { FC, Fragment, ReactNode } from 'react';
import { QueryKey, useQueryClient } from 'react-query';

import {
  FeedKey,
  FollowMutationVariables,
  PublicUserProfileFragment,
  UnfollowMutationVariables,
  useFollowMutation,
  UserFeedFollowGroupFragment,
  useUnfollowMutation,
} from 'api';
import { useCapabilities, useCurrentUser, useFollowed } from 'hooks';
import { useFollow, useUnfollow } from 'updaters';

export interface IFollowToggleRenderProps {
  disableToggle?: boolean;
  isFollowing: boolean;
  loading: boolean;
  onClick: () => Promise<void>;
  isFollowRequired: boolean;
}

type FollowsMutationsArgs = FollowMutationVariables | UnfollowMutationVariables;
type FollowSource = UserFeedFollowGroupFragment | PublicUserProfileFragment;
type FollowType = FeedKey.Group | FeedKey.User;

interface Props {
  source: FollowSource;
  type: FollowType;
  children: (props: IFollowToggleRenderProps) => ReactNode;
  refetchQueryKeys?: QueryKey[];
}

const FollowToggle: FC<Props> = ({ children, source, type, refetchQueryKeys }) => {
  const { needs, isAdmin } = useCapabilities();
  const { followed, required } = useFollowed();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  function refetchQueries(queryKey: QueryKey) {
    void queryClient.refetchQueries(queryKey);
  }

  const followMutationOptions = useFollow({ type, source });
  const { mutateAsync: followMutation, isLoading: isFollowLoading } = useFollowMutation({
    onSettled: () => {
      refetchQueryKeys.forEach(refetchQueries);
    },
    ...followMutationOptions,
  });

  const unfollowMutationOptions = useUnfollow({ type });
  const { mutateAsync: unfollowMutation, isLoading: isUnfollowLoading } = useUnfollowMutation({
    onSettled: () => {
      refetchQueryKeys.forEach(refetchQueries);
    },
    ...unfollowMutationOptions,
  });

  const isFollowing = followed.some(follow => follow.id === source.id);
  const isFollowRequired = required.some(follow => {
    if (type === FeedKey.User) {
      return follow.user?.id === source.id;
    }

    if (type === FeedKey.Group) {
      return follow.group?.id === source.id;
    }

    return false;
  });

  function hasExceptions() {
    // Users can't follow toggle themselves
    if (source.id === currentUser.id) {
      return true;
    }

    // Group owners and moderators can't follow toggle
    if (!isAdmin && needs('Access.Moderator', source.id)) {
      return true;
    }

    return isFollowRequired;
  }

  async function handleToggle() {
    const mutation = isFollowing ? unfollowMutation : followMutation;
    const variables: FollowsMutationsArgs = { id: source.id, key: type };

    await mutation(variables);
  }

  const renderProps: IFollowToggleRenderProps = {
    isFollowing,
    disableToggle: hasExceptions(),
    loading: isFollowLoading || isUnfollowLoading,
    onClick: handleToggle,
    isFollowRequired,
  };

  return <Fragment>{children(renderProps)}</Fragment>;
};

FollowToggle.defaultProps = {
  refetchQueryKeys: [],
};

export default FollowToggle;
