import { PUBLIC_GRAPHQL_ENDPOINT } from 'settings';

export const transport = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    const body = JSON.stringify({
      query,
      variables,
    });

    const res = await fetch(PUBLIC_GRAPHQL_ENDPOINT, { method: 'POST', body });
    const json = await res.json();

    if (json.errors) {
      // TODO: Call Coralogix
      const { message } = json.errors[0] || 'Error..';
      throw new Error(message);
    }

    return json.data;
  };
};
