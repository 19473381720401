import React, { FC, Fragment, ReactNode } from 'react';
import { times } from 'lodash';

interface Props {
  rows?: number;
  padding?: string;
  children: (rowIndex: number) => ReactNode;
}

const List: FC<Props> = ({ padding, rows, children }) => (
  <div style={{ padding, overflow: 'hidden' }}>
    {times(rows, (rowIndex: number) => (
      <Fragment key={rowIndex}>{children(rowIndex)}</Fragment>
    ))}
  </div>
);

List.defaultProps = {
  rows: 1,
  padding: '0px',
};

export default List;
