import React, { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { DistributiveOmit } from '@mui/types';

import { ClientProtectedFragment, CapabilityFragment, ReleaseToggleFragment } from 'api';
import * as csPaths from 'clientSuccess/paths';

export const ClientList = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={csPaths.listClients} {...props} />
));

export const CreateClient = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={csPaths.createClient} {...props} />
);

export const updateClient = (id: ClientProtectedFragment['id']) => {
  const path = csPaths.updateClient.replace(':client_id', id);

  return forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => {
    return <RouterLink ref={ref} to={path} {...props} />;
  });
};

export const UserList = (clientId?: ClientProtectedFragment['id']) => {
  const path = csPaths.userList.replace(':client_id', clientId || '');
  return forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
    <RouterLink ref={ref} to={path} {...props} />
  ));
};

export const FeatureToggles = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to={csPaths.featureToggles} {...props} />
);

export const updateCapability = (id: CapabilityFragment['capability_id']) => {
  const path = csPaths.capabilityDetail.replace(':capability_id', id);

  return forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => {
    return <RouterLink ref={ref} to={path} {...props} />;
  });
};

export const updateReleaseToggle = (id: ReleaseToggleFragment['release_id']) => {
  const path = csPaths.releaseToggleDetail.replace(':release_toggle_id', id);

  return forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => {
    return <RouterLink ref={ref} to={path} {...props} />;
  });
};

export const Billing = forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((props, ref) => (
  <RouterLink ref={ref} to={csPaths.billing} {...props} />
));
