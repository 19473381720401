import React, { FC, ReactNode } from 'react';

import { ReachOverviewStat, useTotalReachOverviewStatsQuery } from 'api';
import { LoaderTypes } from 'types/app';
import { QueryManager } from 'components';
import { useProtectedClient } from 'hooks';

interface IProps {
  loader?: LoaderTypes;
  children: (data: ReachOverviewStat) => ReactNode;
  endDate: string;
  startDate: string;
}

const TotalReachOverviewStats: FC<IProps> = ({ children, loader, startDate, endDate }) => {
  const client = useProtectedClient();
  const queryResult = useTotalReachOverviewStatsQuery({
    client_id: client.id,
    start_date: startDate,
    end_date: endDate,
  });

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.reachOverviewStats) {
          return null;
        }
        return children(data.reachOverviewStats);
      }}
    </QueryManager>
  );
};

export default TotalReachOverviewStats;
