import React, { FC, ReactNode } from 'react';
import { Link, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

import { PublicUserProfileFragment } from 'api';
import { getFullName } from 'helpers';
import { generateLink } from 'Links';
import { ListWidget, UserAvatar } from 'components';

interface Props {
  title: string | ReactNode;
  results: PublicUserProfileFragment[];
  maxResults: number;
}

const ProfilesWidget: FC<Props> = props => {
  const { results, maxResults, title } = props;

  function renderProfile(profile: PublicUserProfileFragment) {
    if (!profile) {
      return null;
    }

    return (
      <ListItem key={profile.id} disableGutters>
        <ListItemAvatar>
          <UserAvatar link user={profile} size={40} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Link
              noWrap
              variant="subtitle2"
              display="block"
              title={getFullName(profile)}
              component={generateLink(`/profile/${profile.id}`)}
              underline="hover"
            >
              {getFullName(profile)}
            </Link>
          }
          secondary={
            <Typography title={profile.job_title} color="textSecondary" noWrap>
              {profile.job_title}
            </Typography>
          }
        />
      </ListItem>
    );
  }

  return (
    <ListWidget title={title}>
      {results.slice(0, maxResults).map(result => renderProfile(result))}
    </ListWidget>
  );
};

export default ProfilesWidget;
