import React, { FC, Fragment } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { LoaderComponents } from 'components';
import { times } from 'lodash';

import useStyles from '../../analyticsStyles';

interface IProps {
  rows: number;
}

const OverviewTableLoader: FC<IProps> = ({ rows }) => {
  const classes = useStyles;
  const { Block, Content } = LoaderComponents;

  return (
    <Fragment>
      {times(rows, (rowIndex: number) => (
        <TableRow sx={classes.tableBodyRow} key={rowIndex}>
          <TableCell>
            <Content index={rowIndex} height={15} maxWidth={250} />
          </TableCell>
          <TableCell align="right">
            <Block height={10} width={25} />
          </TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
};
export default OverviewTableLoader;
