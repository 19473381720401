import { FC, useEffect, useRef } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { isEmpty } from 'lodash';

interface IProps {
  onChange: (values: FormikValues) => void;
  validate?: boolean;
  delay?: number;
}

/**
 *  formik.isValid returns true if the form is dirty and has no errors
 *  We don't want to show errors as a user types for autosave functionality,
 *  so we're more explicit in the check
 */
const FormikEffect: FC<IProps> = ({ onChange, delay, validate }) => {
  const { values, validateForm } = useFormikContext();
  const [debouncedCallback] = useDebouncedCallback(onChange, delay);
  const hasMountRan = useRef(false);

  useEffect(() => {
    if (!hasMountRan.current) {
      hasMountRan.current = true;
      return;
    }

    if (!validate) {
      return debouncedCallback(values);
    }

    void validateForm(values).then(errors => {
      if (isEmpty(errors)) {
        debouncedCallback(values);
      }
    });
  }, [values]);

  return null;
};

FormikEffect.defaultProps = {
  validate: true,
  delay: 1000,
};

export default FormikEffect;
