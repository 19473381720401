import React, { FC } from 'react';
import { Link, LinkProps } from '@mui/material';
import { noop } from 'lodash';

import { useContent } from 'hooks';

interface IProps {
  onClick?: () => void;
}

const ExternalLink: FC<IProps & LinkProps> = ({ children, onClick }) => {
  const content = useContent();
  const { content_object } = content;

  if (!content_object.url) {
    return null;
  }

  const linkProps = {
    href: content_object.url,
    target: '_blank',
    rel: 'noopener noreferrer',
    onClick,
  };

  return (
    <Link underline="none" {...linkProps}>
      {children}
    </Link>
  );
};

ExternalLink.defaultProps = {
  onClick: noop,
};

export default ExternalLink;
