import React, { FC, Fragment } from 'react';
import { Divider, Unstable_Grid2 as Grid, Paper, Tabs, Typography } from '@mui/material';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import { useGetCapabilitiesQuery, useGetReleaseTogglesQuery } from 'api';
import { LargeTab, Layouts } from 'components';
import { useNotify } from 'hooks';
import theme from 'theme';

import { CapabilitiesTable } from '../components/Capabilities';
import { ReleaseToggleTable } from '../components/Releases';
import Loader from '../components/Client/Loader';

const styles = {
  groupLabel: {
    margin: 2,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(28),
  },
  divider: {
    marginTop: 2,
  },
} as const;

export const CapabilityToggles: FC = () => {
  const notify = useNotify();

  const { isFetching, data } = useGetCapabilitiesQuery(
    {},
    {
      onError: notify.queryError,
    }
  );

  const capabilities = data?.getCapabilities ?? [];
  const capabilityFlags = {};
  capabilities.forEach(flag => {
    capabilityFlags[flag.group] = [...(capabilityFlags[flag.group] || []), flag];
  });

  function renderCapabilityToggles() {
    if (isFetching) {
      return <Loader rows={4} />;
    }
    return (
      <Fragment>
        {capabilityFlags &&
          Object.keys(capabilityFlags).map(toggleGroup => (
            <Paper key={toggleGroup} sx={styles.divider}>
              <Typography sx={styles.groupLabel}>{toggleGroup}</Typography>
              <CapabilitiesTable
                isLoading={isFetching}
                capabilities={capabilityFlags[toggleGroup]}
              />
            </Paper>
          ))}
      </Fragment>
    );
  }

  return renderCapabilityToggles();
};

export const ReleaseToggles: FC = () => {
  const notify = useNotify();

  const { isFetching, data } = useGetReleaseTogglesQuery(
    {},
    {
      onError: notify.queryError,
    }
  );

  const releaseToggles = data?.getReleaseToggles;
  const releaseGroups = {};
  if (releaseToggles) {
    releaseToggles.forEach(flag => {
      releaseGroups[flag.group] = [...(releaseGroups[flag.group] || []), flag];
    });
  }

  if (isFetching) {
    return <Loader rows={4} />;
  }

  return (
    <Fragment>
      {releaseGroups &&
        Object.keys(releaseGroups).map(toggleGroup => (
          <Paper key={toggleGroup} sx={styles.divider}>
            <Typography sx={styles.groupLabel}>{toggleGroup}</Typography>
            <ReleaseToggleTable
              isLoading={isFetching}
              releaseToggles={releaseGroups[toggleGroup]}
            />
          </Paper>
        ))}
    </Fragment>
  );
};

const FeatureToggles: FC = () => {
  const releaseMatch = useMatch('/client-success/feature-toggles/release/*');
  const navigate = useNavigate();

  function renderMain() {
    return (
      <div>
        <Grid container alignItems="center">
          <Grid xs>
            <Tabs
              value={releaseMatch ? 'release' : 'capability'}
              onChange={(event, newValue) => navigate(newValue)}
            >
              <LargeTab key="capability" value="capability" label="Capabilities" />
              <LargeTab key="release" value="release" label="Release" />
            </Tabs>
          </Grid>
        </Grid>
        <Outlet />
        <Divider />
      </div>
    );
  }

  return <Layouts.App1Column desktopSize={12} main={renderMain()} />;
};

export default FeatureToggles;
