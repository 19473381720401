import { UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  OauthUserRevokeMutation,
  OauthUserRevokeMutationVariables,
  OauthUserIntegrationsQuery,
  useOauthUserIntegrationsQuery,
} from 'api';
import { useNotify } from 'hooks';

type Mutation = OauthUserRevokeMutation;
type MutationVariables = OauthUserRevokeMutationVariables;
type QueryData = OauthUserIntegrationsQuery;

const useDeleteOAuthIntegration = () => {
  const queryClient = useQueryClient();
  const queryKey = useOauthUserIntegrationsQuery.getKey({});
  const notify = useNotify();

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async ({ data }) => {
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const { oauthUserIntegrations } = draftState;
          draftState.oauthUserIntegrations = oauthUserIntegrations.filter(
            item => item.oauth_client_id !== data.oauth_client_id
          );
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useDeleteOAuthIntegration;
