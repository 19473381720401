import React, { createContext, FC, useEffect, useRef, useState } from 'react';

import { useContent, useReleases } from 'hooks';
import { randomNumberGenerator } from 'utilities';
import { ContentObject } from '../api';

interface Value {
  selectedPhotoIndex: number;
  setPhotoIndex: (idx: number) => void;
  didIndexChange: boolean;
}

export const SharePhotoContext = createContext<Value>({
  selectedPhotoIndex: 0,
  setPhotoIndex: () => undefined,
  didIndexChange: false,
});

export const SharePhotoProvider: FC = ({ children }) => {
  const content = useContent();
  const releases = useReleases();
  const [selectedPhotoIndex, setPhotoIndex] = useState(getInitialPhotoIndex());
  const prevSelectedPhotoIndex = useRef<number>();

  useEffect(() => {
    prevSelectedPhotoIndex.current = selectedPhotoIndex;
  }, [selectedPhotoIndex]);

  function getInitialPhotoIndex() {
    const content_object = content?.content_object || ({} as ContentObject);
    const hasVariations = releases.includes('shareVariations');

    return hasVariations ? randomNumberGenerator(content_object?.photos?.length || 0) : 0;
  }

  return (
    <SharePhotoContext.Provider
      value={{
        selectedPhotoIndex,
        setPhotoIndex,
        didIndexChange: selectedPhotoIndex !== prevSelectedPhotoIndex.current,
      }}
    >
      {children}
    </SharePhotoContext.Provider>
  );
};
