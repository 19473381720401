import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { ContentInput } from 'api';

const EditLinkData: FC = () => {
  const [open, setOpen] = useState(false);
  const titleEl = useRef<HTMLInputElement>(null);
  const descriptionEl = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<ContentInput>();
  const [titleField] = useField('content_object.url_metadata_override.title');
  const [descriptionField] = useField('content_object.url_metadata_override.description');

  function handleApply() {
    setFieldValue(titleField.name, titleEl.current.value);
    setFieldValue(descriptionField.name, descriptionEl.current.value);

    toggleOpen();
  }

  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <Grid>
      <Tooltip title={t('posting:editLinkTooltip')}>
        <IconButton onClick={toggleOpen} size="large">
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={toggleOpen}>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Typography variant="h6" color="primary">
            {t('posting:editLink')}
          </Typography>
          <Typography color="textSecondary">{t('posting:customizeLink')}</Typography>
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows="2"
            margin="normal"
            inputRef={titleEl}
            size="medium"
            defaultValue={titleField.value}
            placeholder={t('posting:editLinkTitle')}
            InputProps={{
              sx: {
                fontSize: theme => theme.typography.pxToRem(20),
                fontWeight: theme => theme.typography.fontWeightBold,
              },
            }}
          />
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows="6"
            margin="normal"
            inputRef={descriptionEl}
            defaultValue={descriptionField.value}
            placeholder={t('posting:editLInkDescription')}
          />
        </DialogContent>
        <DialogActions sx={{ marginTop: 0, marginBottom: 0 }}>
          <Button size="large" onClick={toggleOpen}>
            {t('common:cancel')}
          </Button>
          <Button size="large" onClick={handleApply} color="primary">
            {t('common:apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default EditLinkData;
