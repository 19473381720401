import React, { FC } from 'react';
import { FormControlLabel, FormControlLabelProps, Switch } from '@mui/material';

interface Props {
  checked: boolean;
  onChange(nextValue: boolean): void;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  label?: FormControlLabelProps['label'];
  disabled?: boolean;
}

const styles = {
  active: {
    color: 'text.primary',
  },
  inactive: {
    color: 'text.secondary',
  },
} as const;

const CapabilitySwitch: FC<Props> = ({ checked, label, labelPlacement, disabled, onChange }) => {
  return (
    <FormControlLabel
      label={label}
      disabled={disabled}
      labelPlacement={labelPlacement}
      sx={{ label: checked ? styles.active : styles.inactive }}
      control={
        <Switch
          checked={checked}
          onChange={(event, nextValue) => {
            onChange(nextValue);
          }}
        />
      }
    />
  );
};

CapabilitySwitch.defaultProps = {
  disabled: false,
  label: null,
  labelPlacement: 'start',
};

export default CapabilitySwitch;
