import { array, bool, object, string, lazy } from 'yup';

const SUBDOMAIN_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/;
const DOMAIN_REGEX =
  // eslint-disable-next-line
  /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
const DOMAIN_LINK_REGEX =
  // eslint-disable-next-line
  /^(http(s)?:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

const ClientSchema = () => {
  return object().shape({
    title: string().required('Title is Required'),
    subdomain: string()
      .matches(SUBDOMAIN_REGEX, 'Subdomain is Invalid')
      .required('Subdomain is Required'),
    email_sending_domain: string().matches(DOMAIN_REGEX, 'Invalid Domain'),
    email_from_address: string().nullable(),
    email_domains: array().of(string()),
    content_domains: array().of(string()),
    email_address: string().email('Invalid Email'),
    allow_user_invites: bool().required(),
    allow_user_signup: bool().required(),
    allow_group_creation: bool().required(),
    departments_in_signup_required: bool().required(),
    locations_in_signup_required: bool().required(),
    support_multiple_languages: bool().required(),
    allow_external_share: bool().required(),
    sort_mode: string().nullable().oneOf([null, 'trending', 'chronological']),
    image: object()
      .shape({
        key: string().required(),
        region: string().required(),
        bucket: string().required(),
      })
      .nullable(),
    menu_item: lazy(value => {
      // @ts-ignore
      if (value && value.menu_item_enabled) {
        return object()
          .shape({
            title: string().required('Menu item title is required'),
            link: string()
              .required('Menu item link is required')
              .matches(DOMAIN_LINK_REGEX, 'Invalid domain'),
          })
          .required('Menu item is required');
      } else {
        return object().nullable(true);
      }
    }),
    menu_item_enabled: bool().required(),
  });
};

export default ClientSchema;
