import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  Network,
  ShareContentMutation,
  ShareContentMutationVariables,
  SharedStatus,
  ShareType,
  useShareContentMutation,
} from 'api';
import {
  useContent,
  useNotify,
  usePrevious,
  useProtectedClient,
  useSharePhoto,
  useSharePreview,
  useSocialConnections,
} from 'hooks';
import * as paths from 'paths';

import { Share, ShareItem } from '../types';
import { sharingSchema } from '../validations';
import useInitialValues from '../useInitialValues';
import useShareContent from '../updaters/useShareContent';

interface Props {
  onClose?(): void;
}

interface TimeVariables {
  share_at: number;
  share_type: ShareType;
}

const Form: FC<Props> = ({ children }) => {
  const notify = useNotify();
  const sharePreview = useSharePreview();
  const { selectedPhotoIndex, didIndexChange } = useSharePhoto();
  const content = useContent();
  const client = useProtectedClient();
  const { connections } = useSocialConnections();
  const navigate = useNavigate();

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const prevConnections = usePrevious(connections);
  const initialValues = useInitialValues();
  const [timeVariables, setTimeVariables] = useState<TimeVariables>();
  const formikRef = useRef(null);

  useEffect(() => {
    if (!prevConnections) {
      return;
    }

    if (enableReinitialize) {
      setEnableReinitialize(false);
    }

    if (connections.length !== prevConnections.length) {
      setEnableReinitialize(true);
    }

    if (connections.every(connection => !connection.expires_soon)) {
      setEnableReinitialize(true);
    }
  }, [connections.length, enableReinitialize, connections]);

  const mutationOptions = useShareContent();
  const { mutate, mutateAsync } = useShareContentMutation({
    ...mutationOptions,
    onError: notify.mutationError,
    onSuccess: handleSuccess,
  });

  function handleSuccess(data: ShareContentMutation) {
    const { results } = data.shareContent;
    const hasFailure = results.some(item => item.status === SharedStatus.Failed);

    if (hasFailure) {
      notify.error({ i18n: { i18nKey: 'sharing:shareFailure' } });
      return;
    }

    let i18nKey = 'sharing:shareSuccess';
    const date = moment.unix(timeVariables.share_at).format('dddd, MMMM Do, YYYY [at] h:mma');

    switch (timeVariables.share_type) {
      case ShareType.Scheduled:
        i18nKey = 'sharing:scheduledSuccess';
        break;
      case ShareType.Now:
        i18nKey = 'sharing:shareSuccess';
        break;
      case ShareType.Frontend:
        i18nKey = 'sharing:shareSuccess';
        break;
      case ShareType.Auto:
        i18nKey = 'sharing:autoSuccess';
        break;
    }

    notify.info({
      i18n: {
        i18nKey,
        values: { date },
      },
    });

    window.history.length > 1 ? navigate(-1) : navigate(paths.index);
  }

  async function shareToTwitterAndLinkedin(formValues: Share) {
    const clonedItems = cloneDeep(formValues.items);
    const twitterAndLinkedInItems = clonedItems
      .filter(item => item.state.active)
      .filter(
        item =>
          item.state.networkMeta.provider === Network.Twitter ||
          item.state.networkMeta.provider === Network.Linkedin ||
          item.state.networkMeta.provider === Network.LinkedinPage
      );

    twitterAndLinkedInItems.forEach(item => delete item.state);

    if (!twitterAndLinkedInItems.length) {
      return;
    }

    const variables = {
      ...formValues,
      items: twitterAndLinkedInItems,
    };

    await mutateAsync(variables as ShareContentMutationVariables);
  }

  function shareToFacebook(formValues: Share) {
    const clonedItems = cloneDeep(formValues.items);
    const facebookShareItem = clonedItems.find(item => {
      return item.state.networkMeta.provider === Network.Facebook;
    });

    if (!facebookShareItem?.state?.active) {
      return;
    }

    if (!sharePreview.shortened_urls) {
      return;
    }

    const fbUIVariables = {
      method: 'share',
      href:
        sharePreview.shortened_urls?.facebook?.short_link ??
        `https://${client.subdomain}/content/${formValues.content_id}`,
      quote: facebookShareItem.user_commentary,
    };

    delete facebookShareItem.state;

    window?.FB?.ui(fbUIVariables, () => {
      const mutationVariables = {
        timezone: formValues.timezone,
        content_id: formValues.content_id,
        items: [facebookShareItem],
      };

      mutate(mutationVariables as ShareContentMutationVariables);
    });
  }

  function forcePhotoUpdate(items: ShareItem[]) {
    const photos = content?.content_object?.photos ?? [];
    const selectedPhotos = photos[selectedPhotoIndex] ? [photos[selectedPhotoIndex]] : [];
    items.forEach((item: ShareItem) => {
      item.photos = selectedPhotos;
    });
  }

  async function handleSubmit(values: Share, { setSubmitting }: FormikProps<Share>) {
    const [shareItem] = values.items;

    setTimeVariables({
      share_type: shareItem.share_type,
      share_at: shareItem.share_at,
    });

    if (didIndexChange) {
      forcePhotoUpdate(values.items);
    }

    try {
      await shareToTwitterAndLinkedin(values);
      shareToFacebook(values);
    } catch (error) {
      notify.error({ i18n: { i18nKey: 'sharing:shareFailure' } });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Formik
      innerRef={formikRef}
      validateOnMount
      enableReinitialize={enableReinitialize}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={sharingSchema}
    >
      <Fragment>{children}</Fragment>
    </Formik>
  );
};

export default Form;
