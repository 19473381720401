import React, { FC, Fragment } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { S3ObjectInput } from 'api';
import { getImageSrc } from 'helpers';
import { queryString } from 'utilities';
import { IMGIX_HOST } from 'settings';
import { THUMBNAIL_SIZE } from 'app-constants';

import { usePostContext } from 'features/posting/PostContext';

interface Props {
  photos: S3ObjectInput[];
}

const SelectableThumbnails: FC<Props> = ({ photos }) => {
  const theme = useTheme();
  const { dispatch, state } = usePostContext();

  function handleChangePhoto(index: number) {
    dispatch({ type: 'clearPhotoIndexes' });
    dispatch({ type: 'updatePhotoIndexes', value: index });
  }

  function renderThumbnail(image: S3ObjectInput, index: number) {
    const isSelected = state.selectedPhotoIndexes[0] === index;
    const rawColor = theme.palette.primary.main.split('#')[1];

    const params = {
      h: THUMBNAIL_SIZE,
      w: THUMBNAIL_SIZE,
      fit: 'crop',
      auto: 'format',
      border: `3,${isSelected ? rawColor : 'FFFFFFFF'}`,
      'border-radius': 4,
    };

    const imageUrl = `${IMGIX_HOST}/${image.key}?${queryString(params)}`;
    const imageSrc = getImageSrc(imageUrl);

    return (
      <Grid key={index}>
        <img style={{ cursor: 'pointer' }} onClick={() => handleChangePhoto(index)} {...imageSrc} />
      </Grid>
    );
  }

  if (!photos) {
    return null;
  }

  return <Fragment>{photos.map(renderThumbnail)}</Fragment>;
};

export default SelectableThumbnails;
