import { UniqueClientKey, useGetActiveClientByAttrQuery } from 'api/public';
import { Loaders } from 'components';
import { getSubdomainUrl } from 'helpers';
import React, { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import * as paths from 'paths';

interface IProps {
  children: ReactElement;
}

const LegacyProviderCheck: FC<IProps> = ({ children }) => {
  const params = new URLSearchParams(location.search);
  const provider = params.get('provider');
  return provider ? <LegacySsoRedirect provider={provider} /> : children;
};

interface ILegacySsoRedirectProps {
  provider: string;
}

const LegacySsoRedirect: FC<ILegacySsoRedirectProps> = ({ provider }) => {
  const { data, isError } = useGetActiveClientByAttrQuery({
    key: UniqueClientKey.Provider,
    value: provider,
  });
  if (data?.getActiveClientByAttr?.subdomain) {
    location.assign(getSubdomainUrl(data.getActiveClientByAttr.subdomain));
  } else if (isError) {
    return <Navigate to={paths} replace />;
  }
  return <Loaders.Standard />;
};

export default LegacyProviderCheck;
