import React, { FC, Fragment } from 'react';

import { Network } from 'api';
import { useCapabilities, useSocialConnections } from 'hooks';

interface Props {
  network: Network;
}

const ConnectAccount: FC<Props> = ({ network, children }) => {
  const { twitterAccounts, linkedinAccounts } = useSocialConnections();
  const { needs } = useCapabilities();

  const canConnectMultiple = needs('Sharing.Function.ConnectMultipleAccounts');

  const showTwitterConnect = !twitterAccounts.length || canConnectMultiple;
  const showLinkedInConnect = !linkedinAccounts.length || canConnectMultiple;

  if (network === Network.Twitter && showTwitterConnect) {
    return <Fragment>{children}</Fragment>;
  }

  if (network === Network.Linkedin && showLinkedInConnect) {
    return <Fragment>{children}</Fragment>;
  }

  return null;
};

export default ConnectAccount;
