import { darken } from '@mui/material/styles';
import theme from 'theme';

const styles = {
  tabbedChartWrapper: {
    padding: 2,
  },
  indicator: {
    top: 0,
  },
  tabRoot: {
    padding: 2,
    paddingBottom: 0,

    '& .Mui-selected': {
      color: 'inherit',
    },
  },
  tabWrapper: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  hidden: theme.mixins.hidden,
  hiddenIndicator: {
    height: 0,
  },
  tableBodyRow: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '&:nth-of-type(even)': {
      backgroundColor: 'common.white',
    },
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    color: 'grey.100',
    animation: 'chartLoading 3s ease-in-out infinite',
  },
  placeholder: {
    color: 'grey.300',
  },
  placeholderText: {
    position: 'absolute',
  },
  solid: {
    width: 24,
    height: 4,
    borderRadius: 8,
  },
  dashed: {
    width: 24,
    height: 4,
  },
  '@global @keyframes chartLoading': {
    '0%': { transform: 'scale(1)' },
    '20%': {
      transform: 'scale(1.1, 1.2)',
      color: darken(theme.palette.grey[100], 0.1),
    },
    '40%': { transform: 'scale(1)' },
  },
} as const;

export default styles;
