import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { useGetUserReachGraphDataQuery } from 'api';
import { useDateRange, useSocialConnections } from 'hooks';
import { Buttons, DatePickers, PaddedPaper } from 'components';

import {
  AreaChart,
  ChartLoader,
  IAreaChartConfig,
  TabLabel,
} from 'features/admin/components/Analytics';

interface Props {
  chartHeight: number;
  loaderHeight: number;
}

const TotalReach: FC<Props> = ({ chartHeight, loaderHeight }) => {
  const { startDate, setStartDate, endDate, setEndDate, dateRange, setDateRange } = useDateRange();
  const { linkedinAccounts } = useSocialConnections();
  const { t } = useTranslation();

  const {
    data: reachData,
    isLoading,
    isFetched,
  } = useGetUserReachGraphDataQuery({
    start_date: startDate,
    end_date: endDate,
  });

  const linkedInAccountToRefresh = linkedinAccounts.find(a => a.expires_soon);

  function renderLegend() {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid>
          <Typography display="inline">LinkedIn</Typography>
        </Grid>
        <Grid>
          <Buttons.Refresh account={linkedInAccountToRefresh} />
        </Grid>
      </Grid>
    );
  }

  function renderChart() {
    if (isLoading && !isFetched) {
      return <ChartLoader type="chartArea" height={loaderHeight} />;
    }

    const { summary, data } = reachData.getUserReachGraphData;

    const config: IAreaChartConfig[] = [
      {
        name: t('components:twitterFollowers'),
        dataKey: 'twitter_reach',
        value: summary.twitter_reach,
      },
      {
        name: t('components:linkedinConnections'),
        dataKey: 'linkedin_reach',
        value: summary.linkedin_reach,
        legendComponent: linkedInAccountToRefresh ? renderLegend : undefined,
      },
    ];

    return (
      <Fragment>
        <TabLabel
          stat={summary.total_reach}
          i18nKey="components:yourNetwork"
          tooltipI18nKey="help:admin_external_followers"
        />
        <AreaChart height={chartHeight} data={data} config={config} />
      </Fragment>
    );
  }

  return (
    <PaddedPaper>
      <Box paddingLeft={2} paddingTop={2} paddingRight={2} marginBottom={3}>
        {renderChart()}
      </Box>
      <Divider />
      <DatePickers.Range
        dateRange={dateRange}
        startDate={startDate}
        endDate={endDate}
        onSetStart={setStartDate}
        onSetEnd={setEndDate}
        onSetRange={setDateRange}
        transparent
      />
    </PaddedPaper>
  );
};

export default TotalReach;
