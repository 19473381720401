import React, { FC, ReactNode, useState } from 'react';

import { generateS3Image, generateS3Video } from 'helpers';
import { useCurrentUser } from 'hooks';
import { S3ObjectInput } from 'api';

type OnClose = () => void;
type OnUpload = (file: File, src: string | ArrayBuffer) => void;
type OnSave = (object: S3ObjectInput, type: UploaderContentTypes) => void;

interface IProps {
  children: (props: IUploader) => ReactNode;
  onSave: OnSave;
  disableCropper?: boolean;
  ['data-testid']?: string;
}

export enum UploaderContentTypes {
  Video = 'video',
  GIF = 'gif',
  Image = 'image',
}

export interface IUploader {
  open: boolean;
  onClose: OnClose;
  onUpload: OnUpload;
  onSave: OnSave;
  data: {
    src: string | ArrayBuffer;
    file: File;
  };
}

const Uploader: FC<IProps> = ({ children, disableCropper, onSave, 'data-testid': testId }) => {
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const [file, setFile] = useState(null);

  const currentUser = useCurrentUser();

  const onClose: OnClose = () => {
    setOpen(false);
  };

  const onUpload: OnUpload = (nextFile, nextSrc) => {
    if (disableCropper) {
      return onSave(getImage(nextFile), getType(nextFile));
    }

    if (!isGif(nextFile) && !isVideo(nextFile)) {
      setOpen(true);
      setSrc(nextSrc);
      setFile(nextFile);
      return;
    }

    if (isGif(nextFile)) {
      return onSave(getImage(nextFile), getType(nextFile));
    }

    if (isVideo(nextFile)) {
      return onSave(getVideo(nextFile), getType(nextFile));
    }
  };

  function isGif(fileToCheck: File) {
    return fileToCheck && (fileToCheck.type === 'image/gif' || fileToCheck.type === 'image/webp');
  }

  function isVideo(fileToCheck: File) {
    return fileToCheck && fileToCheck.type.startsWith('video');
  }

  function getImage(fileToCheck: File) {
    return generateS3Image(fileToCheck, fileToCheck, currentUser);
  }

  function getVideo(fileToCheck: File) {
    return generateS3Video(fileToCheck);
  }

  function getType(fileToCheck: File) {
    if (isVideo(fileToCheck)) {
      return UploaderContentTypes.Video;
    }

    if (isGif(fileToCheck)) {
      return UploaderContentTypes.GIF;
    }

    return UploaderContentTypes.Image;
  }

  const props: IUploader = {
    open,
    onClose,
    onUpload,
    onSave,
    data: { file, src },
  };

  return <div data-testid={testId}>{children(props)}</div>;
};

Uploader.defaultProps = {
  disableCropper: false,
};

export default Uploader;
