import React, { FC, Fragment } from 'react';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ValidatedCheckbox } from 'components';

import {
  BodyTextField,
  BrandedCheckbox,
  ReadOnlyWarning,
  ShareableCheckbox,
  UrlField,
  UrlPreview,
} from '../';
import { usePostContext } from '../../PostContext';
import { PreviewUrlSourceType } from 'api';

interface Props {
  moderator: boolean;
  contributor: boolean;
  disabled?: boolean;
}

const LinkForm: FC<Props> = ({ disabled, moderator, contributor }) => {
  const { state } = usePostContext();
  const { t } = useTranslation();

  return (
    <Fragment>
      <BodyTextField />
      <UrlField disabled={disabled} />

      {state.validUrl && (
        <UrlPreview
          moderator={moderator}
          disabled={disabled}
          app_source={PreviewUrlSourceType.Posting}
        />
      )}

      <Box marginTop={1}>
        <ShareableCheckbox disabled={disabled} />
        {contributor && <BrandedCheckbox />}
        {state.engagePost && (
          <Tooltip title={t('posting:engageableChecked')}>
            <span>
              <ValidatedCheckbox
                disabled={!contributor || disabled}
                name="is_engageable"
                label={t('posting:engageable')}
                color="primary"
              />
            </span>
          </Tooltip>
        )}
        <ReadOnlyWarning />
      </Box>
    </Fragment>
  );
};

export default LinkForm;
