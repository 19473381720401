import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { SocialIcon } from 'components';
import Interstitial from './Interstitial';

import { ShareItemNetworkMeta } from '../../types';

interface Props {
  partnerName: string;
  networkMeta: ShareItemNetworkMeta;
}

const PartnerShareDisconnected: FC<Props> = ({ partnerName, networkMeta }) => {
  const { t } = useTranslation();

  const [interstitialOpen, setInterstitialOpen] = useState(false);

  function toggleInterstitial() {
    setInterstitialOpen(!interstitialOpen);
  }

  return (
    <Fragment>
      <Grid container alignItems="center" spacing={2}>
        <Grid>
          <SocialIcon provider={networkMeta.provider} />
        </Grid>
        <Grid xs>
          <Typography variant="subtitle2">{networkMeta.name}</Typography>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            onClick={toggleInterstitial}
            sx={{
              borderRadius: 16,
              backgroundColor: '#D7EBFF',
              color: 'primary.main',
              '&:hover': {
                backgroundColor: '#BADAFB',
                color: 'primary.main',
              },
            }}
          >
            {t('common:connect')}
          </Button>
        </Grid>
      </Grid>
      <Interstitial
        networkName={networkMeta.name}
        partnerName={partnerName}
        network={networkMeta.provider}
        open={interstitialOpen}
        onClose={toggleInterstitial}
      />
    </Fragment>
  );
};

export default PartnerShareDisconnected;
