import React, { ChangeEvent, FC, HTMLAttributes, useState } from 'react';
import { useFormikContext } from 'formik';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { Checkbox, Chip, Typography } from '@mui/material';

import { useSearchCompaniesQuery } from 'api';
import { ClientInput } from '../../types';
import { PickerInput, PopupIcon } from 'components/Pickers';
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material';

import useAutoCompleteStyles from 'components/Pickers/styles/useAutoCompleteStyles';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useDebounce } from 'use-debounce';

interface ClientCompanyAlias {
  company_id: string;
  name: string;
}

const ClientCompanyAlias: FC = () => {
  const { setFieldValue, values } = useFormikContext<ClientInput>();
  const { client_aliases } = values;
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 250);
  const autocompleteClasses = useAutoCompleteStyles;
  const { data, isLoading: isLoadingQuery } = useSearchCompaniesQuery(
    {
      query: debouncedInputValue,
    },
    {
      enabled: debouncedInputValue.length > 0,
      //@ts-ignore
      initialData: () => {
        return {
          searchCompanies: client_aliases,
        };
      },
    }
  );

  function toggleOpen() {
    setOpen(!open);
  }

  function getOptions() {
    if (!data) {
      return [];
    }

    return data.searchCompanies.filter((option: ClientCompanyAlias) => {
      return !client_aliases.some(alias => alias.company_id === option.company_id);
    });
  }

  const options = getOptions();
  const selectedOptions = getSelectedOptions();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function getSelectedOptions() {
    return client_aliases;
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>, nextValue: ClientCompanyAlias[]) {
    setInputValue('');
    setFieldValue('client_aliases', [...nextValue]);
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <PickerInput
        working={false}
        placeholder="clientSuccess:clientCompanyAliasPlaceholder"
        onChange={setInputValue}
        params={params}
      />
    );
  }

  function renderTags(value: ClientCompanyAlias[], getTagProps: AutocompleteGetTagProps) {
    return value.map((company: ClientCompanyAlias, index: number) => (
      <Chip
        key={company.company_id}
        label={company.name}
        deleteIcon={<CloseIcon />}
        {...getTagProps({ index })}
      />
    ));
  }

  function renderOption(
    props: HTMLAttributes<HTMLLIElement>,
    option: ClientCompanyAlias,
    { selected }: AutocompleteRenderOptionState
  ) {
    return (
      <Grid component="li" container alignItems="center" {...props}>
        <Grid xs>{option.name}</Grid>
        <Grid>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid xs={12} container>
      <Grid xs={9}>
        <Typography variant="overline">Client Company Alias List</Typography>
      </Grid>
      <Grid xs={12}>
        <Autocomplete
          multiple
          disableClearable
          disableCloseOnSelect
          open={open}
          onOpen={toggleOpen}
          onClose={toggleOpen}
          sx={autocompleteClasses}
          options={options}
          loading={isLoadingQuery}
          onChange={handleChange}
          getOptionLabel={({ name }) => name}
          popupIcon={<PopupIcon open={open} />}
          renderTags={renderTags}
          renderOption={renderOption}
          renderInput={renderInput}
          value={selectedOptions}
          inputValue={inputValue}
          filterSelectedOptions={true}
        />
      </Grid>
    </Grid>
  );
};

export default ClientCompanyAlias;
