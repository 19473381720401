import React, { FC, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, List, ListItemButton, ListItemText, Typography } from '@mui/material';

import { isPathMatch } from 'utilities';
import { ILink } from '../../types';

interface Props {
  links: ILink[];
  search: ReactNode;
  onNavigate: () => void;
}

const styles = {
  list: {
    width: 300,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 2,
  },
  listItem: {
    paddingTop: 1.5,
    paddingBottom: 1.5,
    marginTop: 1,
    marginBottom: 1,
    borderRadius: 0.5,
  },
} as const;

const Drawer: FC<Props> = ({ links, search, onNavigate }) => {
  const { t } = useTranslation();

  function renderDrawerListItem(link: ILink) {
    const { i18nKey, component, matchPaths } = link;
    const isActive = isPathMatch(matchPaths);

    return (
      <ListItemButton
        key={i18nKey}
        selected={isActive}
        component={component}
        onClick={onNavigate}
        sx={styles.listItem}
      >
        <ListItemText disableTypography>
          <Typography variant="subtitle2">{t(i18nKey)}</Typography>
        </ListItemText>
      </ListItemButton>
    );
  }

  return (
    <Fragment>
      <Box paddingX={3.5} paddingY={2}>
        {search}
      </Box>
      <Divider />
      <List sx={styles.list}>{links.map(renderDrawerListItem)}</List>
    </Fragment>
  );
};

export default Drawer;
