import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { PostsSortFields, ReportingFilter, SortDirections, SortedPost } from 'api';
import { Numeric, Queries, TableCellWithOverflow } from 'components';
import { generateLink } from 'Links';
import { usePrevious, useScrollToRef } from 'hooks';

import { ISortableHeadCell } from '../types';
import {
  BrandCell,
  DateCell,
  EmptyStatRow,
  SortableHeadCell,
  TableLoader,
  TablePagination,
} from '../shared';
import { ROWS_PER_PAGE_OPTIONS } from '../constants';
import useStyles from '../analyticsStyles';

interface IProps {
  startDate: string;
  endDate: string;
  filter: ReportingFilter;
}

const PostDashboardStatTable: FC<IProps> = ({ startDate, endDate, filter }) => {
  const classes = useStyles;
  const hasMounted = usePrevious('hasMounted');
  const { t } = useTranslation();
  const { ref, scrollTo } = useScrollToRef();

  const [sortValue, setSortValue] = useState(PostsSortFields.CreatedAt);
  const [sortDirection, setSortDirection] = useState(SortDirections.Desc);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const variables = {
    start_date: startDate,
    end_date: endDate,
    sort_direction: sortDirection,
    per_page: rowsPerPage,
    sort: sortValue,
    snapshot: false,
    filter,
    page,
  };

  const headCells: ISortableHeadCell[] = [
    {
      id: PostsSortFields.Title,
      align: 'inherit',
      label: t('common:post'),
      initialSort: SortDirections.Asc,
      width: 400,
    },
    {
      id: PostsSortFields.CreatedAt,
      align: 'inherit',
      label: t('analytics:created_at'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: PostsSortFields.SubmittedBy,
      align: 'inherit',
      label: t('common:creator'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: PostsSortFields.ApprovedBy,
      align: 'inherit',
      label: t('analytics:approved_by'),
      initialSort: SortDirections.Asc,
      width: 'auto',
    },
    {
      id: PostsSortFields.Reactions,
      align: 'right',
      label: t('common:like_plural'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: PostsSortFields.Shares,
      align: 'right',
      label: t('common:share_plural'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: PostsSortFields.Branded,
      align: 'center',
      label: t('common:branded'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
  ];

  useEffect(() => {
    hasMounted && scrollTo();
  }, [page, rowsPerPage]);

  function handleSort(id: PostsSortFields, direction: SortDirections) {
    setPage(0);
    setSortValue(id);
    setSortDirection(direction);
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function renderHeadCell(cellProps: ISortableHeadCell) {
    return (
      <SortableHeadCell
        key={cellProps.id}
        {...cellProps}
        onSort={handleSort}
        sortValue={sortValue}
        sortDirection={sortDirection}
      />
    );
  }

  function renderStat(sortedPost: SortedPost) {
    return (
      <TableRow sx={{ root: classes.tableBodyRow }} key={sortedPost.content_id}>
        <TableCellWithOverflow>
          {sortedPost.title && (
            <Link
              noWrap
              target="_blank"
              title={sortedPost.title}
              component={generateLink(`/content/${sortedPost.content_id}`)}
              variant="body2"
              underline="hover"
            >
              {sortedPost.title}
            </Link>
          )}
        </TableCellWithOverflow>
        <DateCell dateValue={sortedPost.created_at} />
        <TableCell>
          <Typography>{sortedPost.submitted_by}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{sortedPost.approved_by}</Typography>
        </TableCell>
        <TableCell align="right">
          <Numeric value={sortedPost.reactions} />
        </TableCell>
        <TableCell align="right">
          <Numeric value={sortedPost.shares} />
        </TableCell>
        <BrandCell align="center" brandValue={sortedPost.branded} />
      </TableRow>
    );
  }

  function renderEmpty() {
    return <EmptyStatRow colSpan={headCells.length} />;
  }

  return (
    <Fragment>
      <Typography ref={ref} paragraph variant="h5" color="textSecondary">
        {t('common:post_plural')}
      </Typography>
      <Queries.AnalyticsSortedTable type="sortedPosts" variables={variables}>
        {({ loading, stats }) => (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>{headCells.map(renderHeadCell)}</TableRow>
              </TableHead>
              <TableBody>
                {loading && <TableLoader rows={rowsPerPage} colSpan={headCells.length} />}
                {!loading && (stats.length ? stats.map(renderStat) : renderEmpty())}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    colSpan={headCells.length}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    disabled={!stats.length || stats.length < rowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Queries.AnalyticsSortedTable>
    </Fragment>
  );
};

export default PostDashboardStatTable;
