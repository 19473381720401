import React, { FC } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

import { ContentLayoutProps } from 'types/app';
import { useContent } from 'hooks';

import { hasCreator } from '../helpers';
import { ContentActions, User } from '../partials';
import {
  Description,
  ExternalLink,
  Images,
  Important,
  PostMenu,
  Provider,
  Title,
} from '../widgets';

const styles = {
  card: {
    marginBottom: 3,
  },
  cardHeader: {
    paddingTop: 0.5,
  },
} as const;

const Url: FC<ContentLayoutProps> = ({ selectedGroup }) => {
  const content = useContent();

  const { content_object } = content;
  const { url_metadata } = content_object;
  const isDeletedUser = content?.user?.full_name === 'Deleted User';

  if (!url_metadata) {
    return null;
  }

  const { title, description } = url_metadata;
  const hasPostCreator = hasCreator(content);

  function renderCardHeader() {
    const provider = (
      <ExternalLink>
        <Provider content={content} />
      </ExternalLink>
    );

    return (
      <CardHeader
        sx={styles.cardHeader}
        title={provider}
        action={!hasPostCreator && <PostMenu />}
      />
    );
  }

  return (
    <Card sx={styles.card}>
      <Important />
      <User selectedGroup={selectedGroup} isDeletedUser={isDeletedUser} />
      {renderCardHeader()}
      <ExternalLink>
        <Images disableViewer />
      </ExternalLink>
      <CardContent>
        <ExternalLink>
          <Title title={title} />
          <Description description={description} />
        </ExternalLink>
      </CardContent>
      <ContentActions selectedGroup={selectedGroup} />
    </Card>
  );
};

export default Url;
