import React, { ChangeEvent, FC, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';
import { useFormikContext } from 'formik';
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Checkbox,
  Chip,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';

import {
  ContentInput,
  CreateClientContentTagsMutation,
  useCreateClientContentTagsMutation,
  useGetClientContentTagsQuery,
} from 'api';
import { useCapabilities, useNotify } from 'hooks';

import { PickerInput, PopupIcon } from './components';
import useAutoCompleteStyles from './styles/useAutoCompleteStyles';

interface ContentTag {
  title: string;
  tag_id: string;
  warning?: string;
}

const ContentTaggingPicker: FC = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<ContentInput>();
  const { content_tags } = values;
  const { isAdmin } = useCapabilities();

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const notify = useNotify();

  const autocompleteClasses = useAutoCompleteStyles;
  const { data, isLoading: isLoadingQuery, refetch } = useGetClientContentTagsQuery();
  const { mutate: createClientContentTag, isLoading: isLoadingMutation } =
    useCreateClientContentTagsMutation({
      onSuccess: handleCreateTagComplete,
    });

  async function handleCreateTagComplete(data: CreateClientContentTagsMutation) {
    if (data.createClientContentTags[0].warning?.includes('special characters')) {
      return notify.error({
        message: t('content:specialCharacters', { tag: data.createClientContentTags[0].title }),
      });
    }
    if (data.createClientContentTags[0].warning?.includes('duplicate tag')) {
      return notify.error({
        message: t('content:duplicateTag', { tag: data.createClientContentTags[0].title }),
      });
    }
    setFieldValue('content_tags', [
      {
        title: data.createClientContentTags[0].title,
        tag_id: data.createClientContentTags[0].tag_id,
      },
      ...content_tags,
    ]);
    await refetch();
  }

  function getOptions() {
    if (!data) {
      return [];
    }

    return data.getClientContentTags;
  }

  const options = getOptions();
  const selectedOptions = getSelectedOptions();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function getSelectedOptions() {
    return content_tags?.reduce((list, tag) => {
      const selected = find(options, ['tag_id', tag.tag_id]);
      if (!selected) {
        return list.concat([]);
      }
      return list.concat([selected]);
    }, []);
  }

  function toggleOpen() {
    setOpen(!open);
  }

  function handleChange(event: ChangeEvent, nextValue: ContentTag[]) {
    setInputValue('');
    setFieldValue('content_tags', [...nextValue]);
  }

  function renderTags(value: ContentTag[], getTagProps: AutocompleteGetTagProps) {
    return value.map((tag: ContentTag, index: number) => (
      <Chip
        key={tag.tag_id}
        label={tag.title}
        deleteIcon={<CloseIcon />}
        {...getTagProps({ index })}
      />
    ));
  }

  function createTag() {
    createClientContentTag({
      tags: [{ title: inputValue }],
    });
  }

  function renderOption(
    props: HTMLAttributes<HTMLLIElement>,
    option: ContentTag,
    { selected }: AutocompleteRenderOptionState
  ) {
    return (
      <Grid component="li" {...props} display="flex" justifyContent="space-between">
        <Grid xs>
          <span>{option.title}</span>
        </Grid>

        <Grid>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
        </Grid>
      </Grid>
    );
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <PickerInput
        working={false}
        placeholder="components:contentTaggingSuggest"
        onChange={setInputValue}
        params={params}
      />
    );
  }

  function renderNoOptions() {
    if (isAdmin) {
      return (
        <Grid container alignItems="center">
          <Grid xs={4} />
          <Grid xs={4}>
            <div onMouseDown={createTag} style={{ cursor: 'pointer' }}>
              {inputValue} <b style={{ color: '#000' }}>( Create New )</b>
            </div>
          </Grid>
          <Grid xs={4} />
        </Grid>
      );
    }

    return <Typography>{t('posting:noOptions')}</Typography>;
  }

  return (
    <Autocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      open={open}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      sx={autocompleteClasses}
      options={options}
      loading={isLoadingQuery || isLoadingMutation}
      onChange={handleChange}
      getOptionLabel={({ title }) => title}
      popupIcon={<PopupIcon open={open} />}
      renderTags={renderTags}
      renderOption={renderOption}
      renderInput={renderInput}
      value={selectedOptions}
      inputValue={inputValue}
      noOptionsText={renderNoOptions()}
    />
  );
};

export default ContentTaggingPicker;
