import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { GroupsSortFields, SortDirections, SortedGroup } from 'api';
import { useDateRange, useReportingFilter } from 'hooks';
import { DatePickers, Queries } from 'components';
import { AdminAnalyticsGroups } from 'Links';

import { OVERVIEW_TABLE_PER_PAGE } from '../../constants';

import TableHead from './TableHead';
import TableFooter from './TableFooter';
import TableLoader from './TableLoader';
import StatRow from './StatRow';
import EmptyStatRow from './EmptyStatRow';

const GroupOverviewCreatedStats: FC = () => {
  const { t } = useTranslation();
  const { startDate, setStartDate, endDate, setEndDate, dateRange, setDateRange } = useDateRange();
  const { filter } = useReportingFilter();

  const variables = {
    start_date: startDate,
    end_date: endDate,
    sort_direction: SortDirections.Desc,
    per_page: OVERVIEW_TABLE_PER_PAGE,
    sort: GroupsSortFields.CreatedAt,
    snapshot: true,
    filter,
    page: 0,
  };

  function renderStat(sortedGroup: SortedGroup) {
    return (
      <StatRow
        link="group"
        title={sortedGroup.title}
        stat={sortedGroup.created_at}
        key={sortedGroup.group_id}
        id={sortedGroup.group_id}
      />
    );
  }

  function renderEmpty() {
    return <EmptyStatRow colSpan={2} />;
  }

  return (
    <Fragment>
      <Typography paragraph variant="h5" color="textSecondary">
        {t('analytics:created_group_stats')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            title={t('analytics:groups_dashboard')}
            type={t('analytics:created_date')}
            component={AdminAnalyticsGroups}
          />
          <TableBody>
            <Queries.AnalyticsSortedTable type="sortedGroups" variables={variables}>
              {({ loading, stats }) => (
                <Fragment>
                  {loading && <TableLoader rows={OVERVIEW_TABLE_PER_PAGE} />}
                  {!loading && (stats.length ? stats.map(renderStat) : renderEmpty())}
                </Fragment>
              )}
            </Queries.AnalyticsSortedTable>
          </TableBody>
          <TableFooter>
            <DatePickers.Range
              dateRange={dateRange}
              startDate={startDate}
              endDate={endDate}
              onSetStart={setStartDate}
              onSetEnd={setEndDate}
              onSetRange={setDateRange}
              transparent
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default GroupOverviewCreatedStats;
