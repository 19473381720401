import { UseQueryOptions } from 'react-query';

import {
  SearchClientInvitationsQuery,
  SearchClientInvitationsQueryVariables,
  useSearchClientInvitationsQuery,
} from 'api';

type Query = SearchClientInvitationsQuery;
type Variables = SearchClientInvitationsQueryVariables;
type Options = UseQueryOptions<Query>;

const useSearchInvitationsReport = (variables: Variables, options?: Options) => {
  const result = useSearchClientInvitationsQuery(variables, options);
  return result;
};

export default useSearchInvitationsReport;
