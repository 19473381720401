import { createTheme, ThemeOptions } from '@mui/material/styles';

import ProximaNovaRegular from 'fonts/Proxima-Nova-Regular.woff2';
import ProximaNovaSemibold from 'fonts/Proxima-Nova-Semibold.woff2';
import ProximaNovaBold from 'fonts/Proxima-Nova-Bold.woff2';
import ProximaNovaExtrabold from 'fonts/Proxima-Nova-Extrabold.woff2';

import { mixins } from './mixins';
import palette from './palette';
import shadows from './shadows';

const layoutSingleColumnMaxWidth = 1300;
const layoutTwoColumnMainMaxWidth = 1000;
const layoutTwoColumnMainMaxWidthWide = 1240;
const bodyPaddingOffset = 24;

const baseSize = 14;
const htmlFontSize = 16;
const coef = baseSize / 14;
export const pxToRem = (px: number) => `${(px / htmlFontSize) * coef}rem`;

const themeOptions: ThemeOptions = {
  custom: {
    layoutSingleColumnMaxWidth,
    layoutTwoColumnMainMaxWidth,
    layoutTwoColumnMainMaxWidthWide,
    bodyPadding: `${bodyPaddingOffset}px 12px`,
    bodyPaddingOffset: bodyPaddingOffset,
    palette,
    networks: {
      Company: palette.blue,
      x: palette.black,
      linkedin: '#037EBB',
      facebook: '#1678F4',
      youtube: '#FF0000',
      instagram: {
        background:
          'linear-gradient(110deg, #4e60d3 8%, #913baf 21%, #d52d88 42%, #d72e85 56%, #e6366d 80%, #f26d4f 97%)',
        color: palette.white,
        borderRadius: 8,
      },
      msTeams: '#37379E',
      slack: '#4A154B',
      reddit: '#FF4500',
      tiktok: '#010101',
      weibo: '#D62B2A',
      wechat: '#04D569',
    },
  },
  mixins,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
  components: {
    MuiGrid2: {
      defaultProps: {
        disableEqualOverflow: true,
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          boxShadow: 'none',

          '&.MuiButton-root': {
            display: 'inline-flex',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&[class*="MuiOutlinedInput-root"]': {
            '& $input': {
              padding: '7px 4px',
            },
          },
          '&[class*="MuiInput-root"]': {
            paddingBottom: 0,
            '& $input': {
              padding: 16,
            },
            '& $input:first-of-type': {
              padding: '16px 0 16px 16px',
            },
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 0,
          '&:hover': {
            color: palette.black,
            backgroundColor: 'transparent',
          },
          '&$selected': {
            color: palette.blue,
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              color: palette.blue,
            },
          },
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        position: 'sticky',
        color: 'default',
      },
      styleOverrides: {
        root: {
          boxShadow: shadows.depth2,
        },
        colorDefault: {
          backgroundColor: palette.white,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'text',
        color: 'secondary',
        size: 'medium',
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState: { variant, color, size } }) => ({
          ...(variant === 'contained' && {
            borderRadius: 20,
            textTransform: 'none',
            boxShadow: 'none',
            border: 'none',
            color: palette.white,
            minWidth: 80,

            '&:hover, &:active': {
              boxShadow: 'none',
              border: 'none',
              transition: 'none',
            },

            '&:Mui-disabled': {
              opacity: 0.4,
            },

            ...(color === 'primary' && {
              backgroundColor: palette.blue,
              color: palette.white,

              '&.Mui-disabled': {
                backgroundColor: palette.skyLight,
                color: palette.white,
              },

              ...(size === 'large' && {
                minWidth: '120px',
              }),
            }),

            ...(color === 'secondary' && {
              backgroundColor: palette.grey[100],
              color: palette.black,
              border: 'none',

              '&:hover': {
                backgroundColor: palette.grey[200],
                border: 'none',
              },
            }),
          }),
          ...(variant === 'outlined' && {
            backgroundColor: palette.white,
            color: palette.grey[500],
            borderRadius: 20,
            textTransform: 'none',
            boxShadow: 'none',

            ...(color === 'secondary' && {
              border: `1px solid ${palette.grey[400]}`,
              '&:hover': {
                border: `1px solid ${palette.grey[400]}`,
                backgroundColor: palette.grey[100],
              },
            }),
          }),
          ...(variant === 'text' && {
            borderRadius: 20,
            color: palette.grey[600],
            textTransform: 'none',
            border: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
              color: palette.black,
            },

            ...(color === 'primary' && {
              color: palette.blue,

              '&:hover': {
                backgroundColor: palette.sky,
              },
            }),
          }),
          ...(size === 'large' && {
            fontSize: pxToRem(15),
            lineHeight: pxToRem(26),
            padding: '8px 24px',
          }),
          ...(size === 'medium' && {
            fontSize: pxToRem(14),
            lineHeight: pxToRem(24),
            padding: '4px 16px',
          }),
          ...(size === 'small' && {
            fontSize: pxToRem(12),
            lineHeight: pxToRem(24),
            padding: '4px 16px',
          }),
        }),
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 8,
          '&:last-child': {
            paddingBottom: 8,
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 12,
          paddingBottom: 4,
        },
        action: {
          marginTop: 0,
          '@media (min-width: 600px)': {
            marginRight: -8,
          },
        },
        avatar: {
          marginRight: 12,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.10)',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'textPrimary',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontWeight: 600,
          paddingLeft: 4,
          backgroundColor: palette.grey[100],
        },
        deleteIcon: {
          fontSize: pxToRem(16),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: 16,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 16px 8px',
          margin: '0 0 16px',
          borderBottom: `1px solid ${palette.grey[300]}`,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: 12,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$focused': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          opacity: 1,
          borderRadius: 0.5,

          '&.Mui-hovered': {
            border: `2px solid ${palette.blue}`,
            borderRadius: 0.5,
          },
          '&.Mui-focused': {
            '&:after': {
              borderRadius: 0.5,
              border: `2px solid ${palette.blue}`,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              content: '""',
              position: 'absolute',
              transform: 'scaleX(1)',
              pointerEvents: 'none',
            },
          },
        },
        input: {
          '&:focus': {
            borderRadius: 0.5,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          minHeight: 32,
        },
        multiline: {
          padding: 16,
          '&$marginDense': {
            padding: 16,
            paddingTop: 16,
          },
        },
        input: {
          height: 'inherit',
          borderRadius: 0.5,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
        },
        shrink: {
          transform: 'scale(0.65)',
        },
        animated: {
          transition: 'transform 100ms cubic-bezier(0.0, 0, 0.1, 1) 0ms',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& div:focus': {
            outline: 'none',
          },
        },
        subheader: {
          '@media (max-width: 1279.95px)': {
            paddingTop: 12,
          },
          '@media (min-width: 1280px)': {
            paddingTop: 24,
          },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontSize: pxToRem(12),
          lineHeight: '24px',
          color: palette.grey[400],
        },
        gutters: {
          paddingRight: 0,
          '@media (max-width: 1279.95px)': {
            paddingLeft: 14,
          },
          '@media (min-width: 1280px)': {
            paddingLeft: 18,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 6,
          paddingBottom: 6,
          '&$disabled': {
            backgroundColor: 'transparent',
          },
          '&$selected, &$selected:hover, &$selected:focus': {
            backgroundColor: palette.grey[500],
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 52,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: 16,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        disableAutoFocusItem: true,
      },
      styleOverrides: {},
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
          fontSize: pxToRem(13),
          transition: 'none',
          color: palette.grey[600],

          '&:hover': {
            backgroundColor: '#F0F0F0',
            color: palette.black,
          },

          '&$selected': {
            opacity: 1,
            backgroundColor: 'transparent',
            color: palette.blue,
            fontWeight: 600,

            '&:focus': {
              backgroundColor: 'transparent',
            },

            '&:hover': {
              color: palette.blue,
              backgroundColor: 'transparent',
              fontWeight: 600,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover $notchedOutline': {
            borderColor: '#E0E0E0',
          },
          '&$focused $notchedOutline': {
            borderColor: palette.blue,
          },
        },
        input: {
          padding: 16,
        },
        notchedOutline: {
          borderColor: '#E0E0E0',
        },
      },
    },
    MuiPaper: {
      defaultProps: {},
      styleOverrides: {
        elevation1: {
          boxShadow: shadows.depth2,
        },
        rounded: {
          borderRadius: 16,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          backgroundColor: palette.grey[100],
          border: 'none',
          paddingLeft: 16,
          paddingRight: 8,
          paddingTop: 8,
          paddingBottom: 8,
        },
        icon: {
          marginLeft: 8,
          marginRight: 8,
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },

          '&.MuiOutlinedInput-input': {
            padding: 4,
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginTopRight: {
          '@media (min-width: 960px)': {
            top: 58,
          },
        },
        anchorOriginTopCenter: {
          '@media (min-width: 960px)': {
            top: 58,
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          color: palette.black,
          '@media (min-width: 600px)': {
            minWidth: 350,
            maxWidth: 350,
          },
        },
        message: {
          width: '100%',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          switchBase: {
            '&:hover': {
              color: palette.blueLight,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: pxToRem(13),
          lineHeight: 'initial',

          '&:first-of-type': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          },

          '&:last-child': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          },
        },
        body: {
          '&:not(:first-of-type)': {
            fontWeight: 400,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 48,
        },
        head: {
          height: 48,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'primary',
      },
      styleOverrides: {
        indicator: {
          height: 5,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: 'none',
          minWidth: 160,
          '@media (min-width: 960px)': {
            fontSize: pxToRem(16),
          },
          '@media (min-width: 1240px)': {
            fontSize: pxToRem(20),
          },
          '&:selected': {
            color: palette.black,
          },
          '& span': {
            width: '100%',
          },
          '&.Mui-selected': {
            color: 'black',
          },
        },
        textColorInherit: {
          color: palette.grey[600],
          opacity: 1,

          '&:hover': {
            color: 'inherit',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: 60,
          maxHeight: 60,
        },
        dense: {
          minHeight: 40,
          maxHeight: 40,
        },
        gutters: {
          paddingLeft: 16,
          paddingRight: 8,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
      },
      styleOverrides: {
        arrow: {
          color: palette.white,
        },
        tooltip: {
          padding: 8,
          lineHeight: 1.5,
          fontSize: pxToRem(12),
          backgroundColor: palette.white,
          color: 'rgba(0, 0, 0, 0.87)',
          borderRadius: 2,
          boxShadow: '0 2px 6px 1px rgba(0,0,0,0), 0 10px 14px 0 rgba(0,0,0,0.17)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: 12,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Proxima Nova'), local('Proxima Nova Regular'), url(${ProximaNovaRegular}) format('woff2');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Proxima Nova'), local('Proxima Nova Semibold'), url(${ProximaNovaSemibold}) format('woff2');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Proxima Nova'), local('Proxima Nova Bold'), url(${ProximaNovaBold}) format('woff2');
        }
        @font-face {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('Proxima Nova'), local('Proxima Nova Extrabold'), url(${ProximaNovaExtrabold}) format('woff2');
        }
        body: {
          overscroll-behavior: none;
        }
        strong, b {
          font-weight: 600;
        }
        video:focus, button:focus {
          outline: none;
        }
        .recharts-text {
          color: #777777;
          font-family: 'Proxima Nova';
          font-size: 12px;
        }
        .recharts-default-tooltip {
          font-family: 'Proxima Nova';
          font-size: 13px;
        }
        @keyframes animation-shimmering{
          0% {
            -webkit-background-position:-300px 0;
            background-position:-300px 0;
          }
          100%{
            -webkit-background-position:800px 0;
            background-position:800px 0;
          }
        }
        twitter-widget {
          margin-top: 0 !important;
        }
        .embedly-card-hug {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
        .fb_iframe_widget {
          margin: 0 auto;
        }
      `,
    },
  },
  palette: {
    background: {
      default: palette.grey[100],
    },
    primary: {
      main: palette.blue,
      dark: palette.blueDark,
      light: palette.blueLight,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: palette.sky,
      dark: palette.skyDark,
      light: palette.skyLight,
      contrastText: '#389AFF',
    },
    warning: {
      main: '#FF9800',
      dark: palette.orange,
      light: '#FFB74D',
      contrastText: '#FFFFFF',
    },
    error: {
      main: palette.red,
      dark: palette.redDark,
      light: palette.redLight,
      contrastText: '#FFFFFF',
    },
    action: {
      disabledOpacity: 0.42,
    },
    text: {
      primary: palette.black,
      secondary: palette.grey[600],
    },
  },
  typography: {
    fontFamily: 'Proxima Nova, Arial',
    fontSize: baseSize,
    fontWeightBold: 600,
    fontWeightRegular: 400,

    h1: {
      fontWeight: 600,
      fontSize: pxToRem(52),
      lineHeight: 1.31,
      letterSpacing: '-0.43px',
    },
    h2: {
      fontWeight: 600,
      fontSize: pxToRem(32),
      lineHeight: 1.2,
      letterSpacing: '-0.33px',
    },
    h3: {
      fontWeight: 400,
      fontSize: pxToRem(32),
      lineHeight: 1.235,
      letterSpacing: '-0.33px',
    },
    h4: {
      fontWeight: 600,
      fontSize: pxToRem(24),
      lineHeight: 1.33,
      letterSpacing: '-0.20px',
    },
    h5: {
      fontWeight: 400,
      fontSize: pxToRem(24),
      lineHeight: 1.33,
      letterSpacing: '-0.20px',
    },
    h6: {
      fontWeight: 600,
      fontSize: pxToRem(20),
      lineHeight: 1.3,
      letterSpacing: '-0.17px',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: pxToRem(16),
      lineHeight: 1.442,
      letterSpacing: '-0.13px',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: pxToRem(16),
      lineHeight: 1.442,
      letterSpacing: '-0.13px',
    },
    body1: {
      fontWeight: 400,
      fontSize: pxToRem(14),
      lineHeight: 1.4,
      letterSpacing: '-0.12px',
    },
    body2: {
      fontWeight: 600,
      fontSize: pxToRem(14),
      lineHeight: 1.4,
      letterSpacing: '-0.12px',
    },
    caption: {
      fontWeight: 600,
      fontSize: pxToRem(12),
      lineHeight: 1.5,
      letterSpacing: '-0.10px',
    },
    overline: {
      textTransform: 'none',
      fontWeight: 400,
      fontSize: pxToRem(12),
      lineHeight: 1.5,
      letterSpacing: '-0.10px',
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
