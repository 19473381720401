import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DailyLeaderboardEventStat } from 'api';
import { PaddedPaper, Queries } from 'components';

import { ILineChartConfig, LineChart, LineChartLoader } from '../shared';
import { LINE_CHART_HEIGHT } from '../constants';

interface IProps {
  startDate: string;
  endDate: string;
  leaderboardId: string;
}

const DailyLeaderboardStatsChart: FC<IProps> = ({ startDate, endDate, leaderboardId }) => {
  const { t } = useTranslation();

  function renderChart(queryResults: DailyLeaderboardEventStat) {
    const { data } = queryResults;

    const config: ILineChartConfig[] = [
      {
        label: t('common:internal'),
        dataKey: 'internal_points',
      },
      {
        label: t('common:external'),
        dataKey: 'external_points',
      },
    ];

    return <LineChart height={LINE_CHART_HEIGHT} data={data} config={config} />;
  }

  return (
    <PaddedPaper>
      <Typography variant="body2">{t('analytics:daily_points')}</Typography>
      <Queries.DailyLeaderboardStats
        loader={<LineChartLoader />}
        leaderboardConfigId={leaderboardId}
        startDate={startDate}
        endDate={endDate}
      >
        {stats => renderChart(stats)}
      </Queries.DailyLeaderboardStats>
    </PaddedPaper>
  );
};

export default DailyLeaderboardStatsChart;
