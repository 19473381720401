import React, { FC, useEffect, useRef, useState } from 'react';

import EmbedPlaceholder from './EmbedPlaceholder';
import EmbedWrapper from './EmbedWrapper';

interface Props {
  id: string;
}

const EmbedTwitter: FC<Props> = ({ id }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = useRef();

  useEffect(() => {
    void loadPost();
  }, []);

  async function loadPost() {
    try {
      await window.twttr.widgets.createTweet(id, ref.current, {
        align: 'center',
      });
      setIsLoaded(true);
    } catch (error) {
      throw new Error(error);
    }
  }

  return (
    <div>
      {!isLoaded && <EmbedPlaceholder />}
      <EmbedWrapper visible={isLoaded}>
        <div ref={ref} />
      </EmbedWrapper>
    </div>
  );
};

export default EmbedTwitter;
