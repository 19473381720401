import React, { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {
  isPushBlocked,
  isPushPermissionGranted,
  isPushSupported,
  requestPushPermission,
} from '@braze/web-sdk';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MenuIcon from '@mui/icons-material/Menu';

import { ClientLogo, Desktop, Phone, Tablet } from 'components';
import { isPathMatch } from 'utilities';
import { isFreeWorkspace, isTrialingTeamsWorkspace } from 'helpers';
import {
  useCapabilities,
  useCurrentUser,
  useProtectedClient,
  useReleases,
  useUserLeaderboards,
} from 'hooks';
import * as Links from 'Links';
import * as paths from 'paths';
import cookieStorage from 'cookie-storage';
import { FeaturePlanType, NotificationDeliveryType } from 'api';

import { ComposeMenu } from 'features/compose';
import { PushNotification, TrialBanner, TrialEndedBanner } from 'features/notifications';
import { isSafari } from 'features/content/helpers';
import Drawer from 'features/header/components/Bar/Drawer';
import theme from 'theme';

import Search from './Search';
import Notifications from './Notifications';
import User from './User';
import { ILink } from '../types';

const styles = {
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    width: '1px',
    height: theme.mixins.toolbar.minHeight,
    backgroundColor: 'grey.100',
  },
  button: {
    padding: theme.spacing(1, 2),
    '&:hover': {
      textShadow: '0.5px 0 0 currentColor',
      color: 'text.primary',
      backgroundColor: 'grey.100',
    },
  },
  buttonActive: {
    textShadow: '0.5px 0 0 currentColor',
    color: 'text.primary',
    backgroundColor: 'grey.100',
  },
} as const;

const COOKIE_VALUE = 'dismissed';

const NavBar: FC = () => {
  const client = useProtectedClient();
  const releases = useReleases();
  const { isAdmin, isModerator, isOwner } = useCapabilities();

  const { t } = useTranslation();
  const { notification_delivery_types } = useCurrentUser();
  const userLeaderboards = useUserLeaderboards();

  const isLeaderboardEnabled = !!userLeaderboards.length;
  const isTrialingTeams = isTrialingTeamsWorkspace(client);
  const isFree = isFreeWorkspace(client);

  const userSupportedPush = notification_delivery_types.includes(NotificationDeliveryType.Push);
  const isPushRequestDismissed = cookieStorage.pushNotification === COOKIE_VALUE || isPushBlocked();
  const isTrialDismissed = cookieStorage.trialDismissed === COOKIE_VALUE;
  const isEnterprise = client.feature_plan_type === FeaturePlanType.E;

  const [pushRequestDismissed, setPushRequestDismissed] = useState(isPushRequestDismissed);
  const [trialDismissed, setTrialDismissed] = useState(isTrialDismissed);
  const [notificationsLoaded, setNotificationsLoaded] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleSafariPush = isSafari() && !isPushPermissionGranted() && !pushRequestDismissed;
  const handleDefaultBrowserPush = !pushRequestDismissed && !userSupportedPush;

  useEffect(() => {
    if (isPushSupported() && !isPushPermissionGranted()) {
      requestPushPermission();
    }
  }, []);

  const permissions = {
    hasAdmin: isAdmin,
    hasGroupModeration: isModerator,
    hasLeaderboard: isLeaderboardEnabled,
    hasNetwork: releases.includes('networkBuilder'),
    hasInsights: releases.includes('OrganicInsights') && isEnterprise,
  };

  const links: ILink[] = [
    {
      i18nKey: 'common:home',
      component: Links.Index,
      matchPaths: paths.HOME_TAB_ROUTES,
    },
  ];

  if (permissions.hasNetwork) {
    links.push({
      i18nKey: 'common:network',
      component: Links.Network,
      matchPaths: paths.NETWORK_TAB_ROUTES,
    });
  }

  links.push({
    i18nKey: 'common:activity',
    component: Links.Activity,
    matchPaths: paths.ACTIVITY_TAB_ROUTES,
  });

  if (permissions.hasInsights) {
    links.push({
      i18nKey: 'common:insights',
      component: Links.Insights,
      matchPaths: paths.INSIGHTS_ROUTES,
    });
  }

  if (permissions.hasLeaderboard) {
    links.push({
      i18nKey: 'common:leaderboard',
      component: Links.Leaderboard,
      matchPaths: paths.LEADERBOARD_TAB_ROUTES,
    });
  }

  links.push(
    {
      i18nKey: 'common:team',
      component: Links.Team,
      matchPaths: paths.TEAM_TAB_ROUTES,
    },
    {
      i18nKey: 'common:group_plural',
      component: Links.Groups,
      matchPaths: paths.GROUPS_TAB_ROUTES,
    }
  );

  if (permissions.hasAdmin) {
    links.push({
      i18nKey: 'common:admin',
      component: Links.AdminAnalytics,
      matchPaths: paths.ADMIN_TAB_ROUTES,
    });
  } else if (permissions.hasGroupModeration) {
    links.push({
      i18nKey: 'common:moderator',
      component: Links.AdminAnalytics,
      matchPaths: paths.ADMIN_TAB_ROUTES,
    });
  }

  function renderButton(link: ILink) {
    const { i18nKey, component, matchPaths } = link;
    const isActive = isPathMatch(matchPaths);

    const buttonStyles = { ...styles.button, ...(isActive && styles.buttonActive) };

    return (
      <Grid key={i18nKey}>
        <Button
          variant="text"
          color="secondary"
          size="large"
          id={`menu-item-${i18nKey.replace(':', '-')}`}
          sx={buttonStyles}
          component={component}
        >
          {t(i18nKey)}
        </Button>
      </Grid>
    );
  }

  function renderLogo() {
    return (
      <Typography sx={styles.logo} component={Links.Index} color="inherit">
        <ClientLogo client={client} disableMargin height={24} />
      </Typography>
    );
  }

  function renderMenuButton() {
    return (
      <IconButton id="mobileMenuButton" onClick={() => setDrawerOpen(true)} size="large">
        <MenuIcon />
      </IconButton>
    );
  }

  function renderNotifications() {
    return (
      <Fragment>
        <Notifications onLoaded={() => setNotificationsLoaded(true)} />
        {!notificationsLoaded && (
          <IconButton size="large">
            <NotificationsNoneIcon />
          </IconButton>
        )}
      </Fragment>
    );
  }

  function renderDrawer() {
    return (
      <SwipeableDrawer
        anchor="left"
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        open={drawerOpen}
      >
        <Drawer
          links={links}
          search={<Search sidebar />}
          onNavigate={() => {
            setDrawerOpen(false);
          }}
        />
      </SwipeableDrawer>
    );
  }

  function renderDesktop() {
    return (
      <Desktop>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={1}
          sx={{ width: '100vw' }}
        >
          <Grid marginRight={2}>{renderLogo()}</Grid>
          <Grid>
            <Divider sx={styles.divider} />
          </Grid>
          <Grid xs>
            <Search />
          </Grid>
          {links.map(renderButton)}
          <Grid>
            <ComposeMenu />
          </Grid>
          <Grid>{renderNotifications()}</Grid>
          <Grid>
            <User />
          </Grid>
        </Grid>
      </Desktop>
    );
  }

  function renderTablet() {
    return (
      <Tablet>
        {renderDrawer()}
        {renderMenuButton()}
        {renderLogo()}
        <Grid container alignItems="center" spacing={1} justifyContent="flex-end">
          <Grid xs>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid>
                <Divider sx={styles.divider} />
              </Grid>
              <Grid xs>
                <Search />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <ComposeMenu />
          </Grid>
          <Grid>
            <Grid container alignItems="center">
              <Grid>
                <Divider sx={styles.divider} />
              </Grid>
              <Grid>{renderNotifications()}</Grid>
            </Grid>
          </Grid>
          <Grid>
            <User />
          </Grid>
        </Grid>
      </Tablet>
    );
  }

  function renderMobile() {
    return (
      <Phone>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          {renderDrawer()}
          <Grid>{renderMenuButton()}</Grid>

          <Grid xs>{renderLogo()}</Grid>

          <Grid>
            <ComposeMenu />
          </Grid>
          <Grid>
            <User />
          </Grid>
        </Grid>
      </Phone>
    );
  }

  return (
    <AppBar>
      {isOwner && isTrialingTeams && <TrialBanner />}
      {isOwner && isFree && !trialDismissed && (
        <TrialEndedBanner cookieValue={COOKIE_VALUE} onDismissed={() => setTrialDismissed(true)} />
      )}
      {(handleSafariPush || handleDefaultBrowserPush) && (
        <PushNotification
          cookieValue={COOKIE_VALUE}
          onDismissed={() => setPushRequestDismissed(true)}
        />
      )}
      <Toolbar>
        {renderDesktop()}
        {renderTablet()}
        {renderMobile()}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
