import React, { FC } from 'react';
import { useFormikContext } from 'formik';

import { ContentInput } from 'api';
import { Dimensions } from 'components';
import { DimensionsProvider } from 'providers';
import { useReleases } from 'hooks';

import { getPhoto } from 'features/content/helpers';
import { Images, Photo } from 'features/content/widgets';
import { usePostContext } from 'features/posting/PostContext';

const PhotoPreview: FC = () => {
  const { values } = useFormikContext<ContentInput>();
  const { state } = usePostContext();
  const releases = useReleases();
  const hasVariations = releases.includes('shareVariations');

  const photo = getPhoto(values, state.selectedPhotoIndexes[0] ?? 0);

  if (!photo) {
    return null;
  }

  if (hasVariations) {
    return <Images preview />;
  }

  // TODO: This is only necessary due to the need for a photo index when using selectable thumbnails
  // TODO: It should be removed after shareVariations goes live and selectable thumbnails are removed
  return (
    <Dimensions>
      {dimensions => (
        <DimensionsProvider value={dimensions}>
          <Photo photo={photo} />
        </DimensionsProvider>
      )}
    </Dimensions>
  );
};

export default PhotoPreview;
