import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { useContent } from 'hooks';
import { useCanShare } from '../hooks';
import { CustomSVG } from 'components';
import theme from 'theme';

const styles = {
  text: {
    paddingTop: '6px',
    paddingBottom: '6px',
    color: 'common.white',
  },
  important: {
    backgroundColor: theme.custom.palette.orange,
  },
  importantToShare: {
    backgroundColor: 'primary.main',
  },
  importantIcon: {
    marginRight: theme.spacing(1),
  },
  shareIcon: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
} as const;

const Important: FC = () => {
  const { t } = useTranslation();
  const content = useContent();
  const { is_important, important_to_share } = content;
  const showShare = useCanShare(content);

  if (is_important) {
    const textstyles = { ...styles.text, ...styles.important };

    return (
      <Grid container alignItems="center" justifyContent="center" sx={textstyles}>
        <Grid>
          <CustomSVG sx={styles.importantIcon} name="important" size={16} />
        </Grid>
        <Grid>
          <Typography variant="subtitle2">{t('common:important')}</Typography>
        </Grid>
      </Grid>
    );
  }

  if (important_to_share && showShare) {
    const textstyles = { ...styles.text, ...styles.importantToShare };

    return (
      <Grid container alignItems="center" justifyContent="center" sx={textstyles}>
        <Grid>
          <CustomSVG name="share" size={16} sx={styles.shareIcon} />
        </Grid>
        <Grid>
          <Typography variant="subtitle2">{t('content:recommendedToShare')}</Typography>
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default Important;
