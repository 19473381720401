import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { AutocompleteRenderInputParams, CircularProgress, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
  working: boolean;
  placeholder: string;
  onChange: (input: string) => void;
  params: AutocompleteRenderInputParams;
}

const PickerInput: FC<IProps> = ({ working, placeholder, onChange, params }) => {
  const [val, setVal] = useState('');
  const { t } = useTranslation();

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setVal(value);
    onChange(value);
  }

  return (
    <TextField
      {...params}
      value={val}
      variant="outlined"
      placeholder={t(placeholder)}
      onChange={handleChange}
      onBlur={() => onChange('')}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <Fragment>
            {working && <CircularProgress color="inherit" size={24} />}
            {!working && params.InputProps.endAdornment}
          </Fragment>
        ),
      }}
    />
  );
};

export default PickerInput;
