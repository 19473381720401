import React, { FC } from 'react';
import { Skeleton } from '@mui/material';

interface Props {
  height?: number | string;
  width?: number | string;
}

const Block: FC<Props> = ({ height, width }) => {
  return <Skeleton width={width} height={height} />;
};

Block.defaultProps = {
  height: 12,
  width: 100,
};

export default Block;
