import React, { CSSProperties, FC, memo } from 'react';

import { DataTableActions, DataTableColumn, Datum } from './types';
import useStyles, { StyleProps } from './styles';
import BodyCell from './BodyCell';
import { Box } from '@mui/material';

interface Props extends StyleProps {
  datum: Datum;
  columns: DataTableColumn<any>[];
  actions: DataTableActions<any>;
  index?: number;
  sx?: CSSProperties;
}

const BodyRowMemoized: FC<Props> = memo(function BodyRow(props) {
  const { columns, datum, actions, index, sx } = props;
  const styles = useStyles;

  return (
    <Box sx={{ ...styles.tableBodyRow, ...sx }}>
      {columns.map((column, idx) => (
        <BodyCell key={idx} column={column} datum={datum} index={index + 1} />
      ))}
      <Box sx={styles.actionCell}>{actions(datum)}</Box>
    </Box>
  );
});

export default BodyRowMemoized;
