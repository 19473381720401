import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { PublicUserProfileFragment, ReactionType, useAddReactionAnalyticsMutation } from 'api';
import { UserAvatar } from 'components';
import { useContent } from 'hooks';
import { generateLink } from 'Links';
import { Events } from 'analytics';

import ShareCount from './ShareCount';
import ShareCountIcon from './ShareCountIcon';

const ShareCountModal: FC = () => {
  const content = useContent();
  const [isOpen, setIsOpen] = useState(false);

  const { content_id, shared_by } = content;

  const { mutate: addReactionAnalytics } = useAddReactionAnalyticsMutation();

  useEffect(() => {
    if (isOpen) {
      window?.analytics?.track(Events.ClickShareCount);
    }
  }, [isOpen]);

  function handleReactionAnalytics() {
    !isOpen && handleOpenToggle();

    addReactionAnalytics({
      content_id,
      reaction_type: ReactionType.Share,
    });
  }

  function handleOpenToggle() {
    setIsOpen(!isOpen);
  }

  function renderUser(user: PublicUserProfileFragment) {
    return (
      <Grid container key={user.id} alignItems="center" spacing={2}>
        <Grid>
          <UserAvatar link size={40} user={user} />
        </Grid>
        <Grid>
          <Link
            title={user.full_name}
            variant="body2"
            component={generateLink(`/profile/${user.id}`)}
            noWrap
            underline="hover"
          >
            {user.full_name}
          </Link>
        </Grid>
      </Grid>
    );
  }

  if (!isOpen) {
    return (
      <Link onClick={handleReactionAnalytics} underline="hover" sx={{ cursor: 'pointer' }}>
        <ShareCountIcon />
      </Link>
    );
  }

  return (
    <Fragment>
      <Link onClick={handleReactionAnalytics} underline="hover" sx={{ cursor: 'pointer' }}>
        <ShareCountIcon />
      </Link>
      <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={handleOpenToggle}>
        <DialogTitle sx={{ marginBottom: 0 }}>
          <ShareCount variant="subtitle2" />
          <IconButton onClick={handleOpenToggle} size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            {shared_by.map(renderUser)}
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ShareCountModal;
