import React, { ClipboardEvent, FC, KeyboardEvent, useEffect } from 'react';
import { IconButton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import CancelIcon from '@mui/icons-material/Cancel';

import { ContentInput } from 'api';
import { validateUrl } from 'utilities';

import { usePostContext } from 'features/posting/PostContext';
import { enforceHTTPS } from 'features/posting/helpers';
        
import theme from 'theme';

const styles = {
  input: {
    color: 'primary.main',
    fontSize: theme.typography.pxToRem(17),
    '&::placeholder': {
      opacity: 1,
    },
  },
  inputPreview: {
    borderBottom: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
} as const;

interface Props {
  disabled?: boolean;
}

const LinkForm: FC<Props> = ({ disabled }) => {
  const { state, dispatch } = usePostContext();
  const [urlField] = useField('content_object.url');

  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<ContentInput>();

  const inputRootClass = state.validUrl ? styles.inputPreview : {};

  useEffect(() => {
    handleLinkCheck();
  }, []);

  function handleLinkCheck(pastedUrl?: string) {
    if (validateUrl(pastedUrl || urlField.value)) {
      dispatch({ type: 'validUrl', value: true });
      setFieldValue('content_object.url', pastedUrl || enforceHTTPS(urlField.value));
    }
  }

  function handleKeyPress(event: KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleLinkCheck();
    }
  }

  function handlePaste(event: ClipboardEvent<HTMLDivElement>) {
    event.preventDefault();

    const value = enforceHTTPS(event.clipboardData.getData('text').trim());
    handleLinkCheck(value);
  }

  function handleRemove() {
    setFieldValue('content_object.url', '');
    setFieldValue('content_object.photos', []);
    setFieldValue('is_branded', false);
    setFieldValue('is_engageable', false);
    setFieldValue('content_object.url_metadata_override.title', '');
    setFieldValue('content_object.url_metadata_override.description', '');

    setFieldValue('url_param_values', []);
    dispatch({ type: 'validUrl', value: false });
    dispatch({ type: 'engagePost', value: false });
    dispatch({ type: 'brandedPost', value: false });
    dispatch({ type: 'clearPhotoIndexes' });
  }

  return (
    <TextField
      fullWidth
      variant="outlined"
      disabled={state.validUrl}
      value={urlField.value}
      InputProps={{
        sx: { root: inputRootClass, input: styles.input },
        endAdornment: !!urlField.value && (
          <IconButton onClick={handleRemove} size="large" disabled={disabled}>
            <CancelIcon />
          </IconButton>
        ),
        disabled,
      }}
      inputProps={{ name: urlField.name }}
      placeholder={t('posting:enterUrl')}
      onChange={urlField.onChange}
      onPaste={handlePaste}
      onKeyPress={handleKeyPress}
    />
  );
};

export default LinkForm;
