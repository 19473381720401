import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Paper, Tab, Tabs, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { ExternalOverviewStat } from 'api';
import { DatePickers, Queries } from 'components';
import { useCapabilities, useDateRange } from 'hooks';

import { ChartLoader, ILineChartConfig, LineChart, TabLabel } from '../shared';
import { EXTERNAL_CHART_HEIGHT, TABBED_CHART_EXCESS_HEIGHT } from '../constants';
import useStyles from '../analyticsStyles';

enum StatType {
  Shares = 'shares',
  Impressions = 'impressions',
  Clicks = 'clicks',
  Engagements = 'engagements',
}

const ExternalOverviewStats: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles;
  const { needs } = useCapabilities();
  const { startDate, setStartDate, endDate, setEndDate, dateRange, setDateRange } = useDateRange();

  const [tab, setTab] = useState<StatType>(StatType.Shares);
  const hasImpressions = needs('Admin.Impressions.View.Access');

  function handleTabChange(event: ChangeEvent<HTMLDivElement>, value: StatType) {
    setTab(value);
  }

  function renderSummaryTabs(queryResults: ExternalOverviewStat) {
    const { summary } = queryResults;
    const { shares, impressions, clicks, engagements } = summary;

    return (
      <Grid container>
        <Tabs value={tab} onChange={handleTabChange} TabIndicatorProps={{ sx: classes.indicator }}>
          <Tab
            sx={{ root: classes.tabRoot, alignItems: 'flex-start', justifyContent: 'flex-start' }}
            label={
              <TabLabel
                stat={shares}
                i18nKey="common:share_plural"
                tooltipI18nKey="help:admin_external_shares"
              />
            }
            value="shares"
          />
          {hasImpressions && (
            <Tab
              sx={{
                root: classes.tabRoot,
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
              label={
                <TabLabel
                  stat={impressions}
                  i18nKey="common:impression_plural"
                  tooltipI18nKey="help:admin_external_impressions"
                />
              }
              value="impressions"
            />
          )}
          <Tab
            sx={{ root: classes.tabRoot, alignItems: 'flex-start', justifyContent: 'flex-start' }}
            label={
              <TabLabel
                stat={clicks}
                i18nKey="common:click_plural"
                tooltipI18nKey="help:admin_external_clicks"
              />
            }
            value="clicks"
          />
          <Tab
            sx={{ root: classes.tabRoot, alignItems: 'flex-start', justifyContent: 'flex-start' }}
            label={
              <TabLabel
                stat={engagements}
                i18nKey="common:engagement_plural"
                tooltipI18nKey="help:admin_external_engagements"
              />
            }
            value="engagements"
          />
        </Tabs>
      </Grid>
    );
  }

  function renderChart(queryResults: ExternalOverviewStat, statType: StatType) {
    const { data } = queryResults;
    const config: ILineChartConfig[] = [
      { label: t('common:branded'), dataKey: '' },
      { label: t('common:nonBranded'), dataKey: '' },
    ];

    switch (statType) {
      case StatType.Shares:
        config[0].dataKey = 'branded_shares';
        config[1].dataKey = 'non_branded_shares';
        break;
      case StatType.Clicks:
        config[0].dataKey = 'branded_clicks';
        config[1].dataKey = 'non_branded_clicks';
        break;
      case StatType.Engagements:
        config[0].dataKey = 'branded_engagements';
        config[1].dataKey = 'non_branded_engagements';
        break;
      case StatType.Impressions:
        config[0].label = t('common:impression_plural');
        config[0].dataKey = 'impressions';
        config.pop();
        break;
    }

    return <LineChart height={EXTERNAL_CHART_HEIGHT} data={data} config={config} />;
  }

  return (
    <Fragment>
      <Typography paragraph variant="h5" color="textSecondary">
        {t('analytics:external_overview_stats')}
      </Typography>
      <Paper>
        <Queries.ExternalOverviewStats
          loader={
            <ChartLoader
              type="chartLine"
              height={EXTERNAL_CHART_HEIGHT + TABBED_CHART_EXCESS_HEIGHT}
            />
          }
          startDate={startDate}
          endDate={endDate}
        >
          {externalStats => (
            <Fragment>
              {renderSummaryTabs(externalStats)}
              <Box sx={classes.tabbedChartWrapper}>
                {tab === StatType.Shares && renderChart(externalStats, tab)}
                {hasImpressions && tab === StatType.Impressions && renderChart(externalStats, tab)}
                {tab === StatType.Clicks && renderChart(externalStats, tab)}
                {tab === StatType.Engagements && renderChart(externalStats, tab)}
              </Box>
            </Fragment>
          )}
        </Queries.ExternalOverviewStats>
        <Divider />
        <DatePickers.Range
          dateRange={dateRange}
          startDate={startDate}
          endDate={endDate}
          onSetStart={setStartDate}
          onSetEnd={setEndDate}
          onSetRange={setDateRange}
          transparent
        />
      </Paper>
    </Fragment>
  );
};

export default ExternalOverviewStats;
