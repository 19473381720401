import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { ClientProtectedFragment, UserClientStatus } from 'api';
import { PaddedPaper } from 'components';

interface Props {
  user_client_status: UserClientStatus;
  client: ClientProtectedFragment;
}

const InactiveWorkspace: FC<Props> = ({ user_client_status, client }) => {
  const { t } = useTranslation();

  return (
    <Grid>
      <PaddedPaper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container xs spacing={2} alignItems="center">
            <Grid>
              <Typography variant="h6">{client.title}</Typography>
              <Typography color="textSecondary">{client.subdomain}</Typography>
            </Grid>
          </Grid>
          <Grid>
            <Button disabled size="large" variant="contained">
              {t(`auth:status_${user_client_status}`)}
            </Button>
          </Grid>
        </Grid>
      </PaddedPaper>
      <Box pl={2} pr={2}>
        <Typography color="textSecondary">{t(`auth:description_${user_client_status}`)}</Typography>
      </Box>
    </Grid>
  );
};

export default InactiveWorkspace;
