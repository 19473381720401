import React, { CSSProperties, FC } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIconProps } from '@mui/material';

interface IIconProps extends SvgIconProps {
  onClick?: () => void;
}

interface Props {
  iconProps?: IIconProps;
  sx?: CSSProperties;
}

const DeleteIcon: FC<Props> = ({ iconProps, sx }) => {
  return (
    <AddCircleIcon
      {...iconProps}
      onClick={iconProps.onClick}
      sx={{
        transform: 'rotate(45deg)',
        ...sx,
      }}
    />
  );
};

DeleteIcon.defaultProps = {
  iconProps: {},
};

export default DeleteIcon;
