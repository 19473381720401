import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Tooltip, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import Favorite from '@mui/icons-material/Favorite';

import { ContentFragment, FeedKey } from 'api';
import { ContentProvider } from 'providers';
import { CustomSVG, EmptyState, Numeric, PostImage } from 'components';
import { useCurrentUser, useGetFeed } from 'hooks';
import { DEFAULT_SORT } from 'app-constants';
import { ShareButton } from 'features/content/widgets';
import { IMAGE_HEIGHT } from 'features/activity/constants';

import { Date, Post } from './Partials';
import ListLoader from './ListLoader';

const PostsPast: FC = () => {
  const currentUser = useCurrentUser();
  const { t } = useTranslation();

  const { results, isEmpty, isInitialLoad } = useGetFeed({
    sort: DEFAULT_SORT,
    key: FeedKey.Privateuser,
    id: currentUser.id,
    limit: 25,
  });

  if (isInitialLoad) {
    return <ListLoader />;
  }

  if (isEmpty) {
    return <EmptyState name="myPosts" />;
  }

  function renderActionRow(content: ContentFragment) {
    return (
      <Grid xs={12} container alignItems="center" padding={2}>
        <Grid xs>
          <Date time={content.publish_at} />
        </Grid>
        <Grid>
          <ContentProvider value={content}>
            <ShareButton size="small" />
          </ContentProvider>
        </Grid>
      </Grid>
    );
  }

  function renderPerformance(content: ContentFragment) {
    return (
      <Grid container spacing={2}>
        <Grid>
          <Tooltip arrow title={t('common:like_plural')} placement="top">
            <Grid container alignItems="center">
              <Favorite color="action" />
              <Box marginLeft={0.5}>
                <Typography display="inline">
                  <Numeric value={content.reaction_count} />
                </Typography>
              </Box>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid>
          <Tooltip arrow title={t('common:share_plural')} placement="top">
            <Grid container alignItems="center">
              <CustomSVG color="action" name="share" />
              <Box marginLeft={0.5}>
                <Typography display="inline">
                  <Numeric value={content.share_count} />
                </Typography>
              </Box>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  function renderRow(content: ContentFragment) {
    return (
      <Grid container key={content.content_id}>
        {renderActionRow(content)}
        <Grid xs={12} container paddingX={2}>
          <Grid paddingRight={2} paddingBottom={2}>
            <PostImage size={IMAGE_HEIGHT} content={content} />
          </Grid>
          <Grid xs container>
            <Grid>
              <Post content={content} />
            </Grid>

            <Grid xs={12} marginY={2}>
              <Divider />
            </Grid>

            <Grid xs={12}>{renderPerformance(content)}</Grid>
          </Grid>
        </Grid>

        <Grid xs={12} marginTop={2}>
          <Divider />
        </Grid>
      </Grid>
    );
  }

  return <Fragment>{results.map(renderRow)}</Fragment>;
};

export default PostsPast;
