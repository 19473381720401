import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

import * as paths from 'paths';

const styles = {
  title: {
    position: 'relative',
    justifyContent: 'center',
    margin: 0,
    padding: 2,
  },

  closeButton: {
    position: 'absolute',
    left: 2,
  },
} as const;

const Header: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onClickClose() {
    window.history.length > 1 ? navigate(-1) : navigate(paths.index);
    return;
  }

  return (
    <AppBar>
      <Toolbar sx={styles.title}>
        <IconButton sx={styles.closeButton} onClick={onClickClose} size="large">
          <CloseIcon />
        </IconButton>
        <Typography align="center" variant="h4">
          {t('sharing:shareToYourNetworks')}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
