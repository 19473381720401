import React, { FC, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Unstable_Grid2 as Grid } from '@mui/material';

import BareAppBar from './BareAppBar';

interface Props {
  main: ReactNode;
  image?: ReactNode;
}

const OnboardingLayout: FC<Props> = ({ image, main }) => {
  const { t } = useTranslation();

  function renderTermsLink() {
    return (
      <Link
        href="https://everyonesocial.com/user-terms-of-service"
        target="_blank"
        underline="hover"
      >
        {t('auth:userTerms')}
      </Link>
    );
  }

  function renderPrivacyPolicyLink() {
    return (
      <Link href="https://everyonesocial.com/privacy-policy" target="_blank" underline="hover">
        {t('auth:privacyPolicy')}
      </Link>
    );
  }

  function renderContactLink() {
    return (
      <Link href="https://everyonesocial.com/contact" target="_blank" underline="hover">
        {t('auth:contactUs')}
      </Link>
    );
  }

  return (
    <Fragment>
      <BareAppBar />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
        minHeight="100vh"
        sx={{ backgroundColor: 'common.white' }}
      >
        <Grid xs={12} container justifyContent="center" alignItems="center">
          {image && <Grid sx={{ display: { sx: 'none', md: 'none', lg: 'flex' } }}>{image}</Grid>}
          <Grid>{main}</Grid>
        </Grid>
        <Grid container>
          <Grid>
            {renderTermsLink()} & {renderPrivacyPolicyLink()}
          </Grid>
          <Grid>{renderContactLink()}</Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OnboardingLayout;
