import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Box, Button, Typography } from '@mui/material';

import { useSendSignOnEmailMutation } from 'api/public';
import { CheckEmail, Layouts, Loaders, PaddedPaper, ValidatedTextFieldNext } from 'components';
import { useNotify } from 'hooks';
import { getAppImage } from 'helpers';
import cookieStorage from 'cookie-storage';

import { LandingSchema, landingSchema } from '../validations';

import { Events } from 'analytics';

const Landing: FC = () => {
  const notify = useNotify();
  const { t } = useTranslation();
  const [showPasswordless, setShowPasswordless] = useState(false);

  const initialValues: LandingSchema = { username: cookieStorage.userEmail || '' };

  const { mutate: sendSignOnMutation, isLoading } = useSendSignOnEmailMutation({
    onError: notify.mutationError,
    onSuccess: () => setShowPasswordless(true),
  });

  function handleSubmit(values: LandingSchema) {
    const email = values.username.toLowerCase();
    window?.analytics?.track(Events.FormSubmitted, {
      form_name: 'Passwordless Email',
      field_input: email,
      submitted: true,
    });
    sendSignOnMutation({ email });
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    cookieStorage.userEmail = event.target.value;
  }

  function renderImage() {
    return <img width={500} height={500} {...getAppImage('illustrations/boat.png', 500)} />;
  }

  function renderMain() {
    return (
      <PaddedPaper elevation={0} padding="64px 48px">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={landingSchema}
        >
          <Layouts.OnboardingBox component={Form}>
            <Typography align="center" paragraph variant="h2">
              {t('auth:clientSelectHeader')}
            </Typography>
            <Typography align="center" paragraph color="textSecondary">
              {t('auth:clientSelectSubHead')}
            </Typography>
            <Box paddingTop={3}>
              <ValidatedTextFieldNext
                enableTrim
                autoFocus
                fullWidth
                name="username"
                type="email"
                label={t('auth:clientSelectLabel')}
                placeholder={t('auth:clientSelectPlaceholder')}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box paddingTop={3}>
              <Button
                fullWidth
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? <Loaders.Standard height={26} /> : t('auth:getStartedButton')}
              </Button>
            </Box>
          </Layouts.OnboardingBox>
        </Formik>
      </PaddedPaper>
    );
  }

  return showPasswordless ? (
    <Layouts.Onboarding main={<CheckEmail />} />
  ) : (
    <Layouts.Onboarding image={renderImage()} main={renderMain()} />
  );
};

export default Landing;
