import React, { FC } from 'react';
import { Box, Link, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
  message: 'redirect' | 'name' | 'iframe';
}

const MESSAGES = {
  redirect: {
    headline: 'Unknown Redirect URL',
    description: (
      <ul>
        <li>
          <Typography>All redirect URLs must be registered with us prior to usage</Typography>
        </li>
        <li>
          <Typography>When using the page option, a `redirect_url` must be provided</Typography>
        </li>
      </ul>
    ),
  },
  name: {
    headline: 'Unknown Partner Name',
    description: (
      <ul>
        <li>
          <Typography>All partners must be registered with us prior to usage</Typography>
        </li>
        <li>
          <Typography>All partner names are lowercase</Typography>
        </li>
        <li>
          <Typography>Check your spelling :)</Typography>
        </li>
      </ul>
    ),
  },
  iframe: {
    headline: 'iFrame Configuration Error',
    description: (
      <ul>
        <li>
          <Typography>iFrame display requires popup as the connection option</Typography>
        </li>
      </ul>
    ),
  },
};

const PartnerShareError: FC<Props> = ({ message }) => {
  const theme = useTheme();

  return (
    <Box padding={4}>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1}>
        <Grid>
          <ErrorIcon color="error" style={{ fontSize: theme.typography.pxToRem(48) }} />
        </Grid>
        <Grid>
          <Typography variant="h4">{MESSAGES[message].headline}</Typography>
        </Grid>
        <Grid>
          <Typography align="center" paragraph variant="body2">
            Troubleshooting:
          </Typography>
          {MESSAGES[message].description}
        </Grid>
        <Grid>
          <Typography>
            Please{' '}
            <Link color="primary" href="mailto:support@everyonesocial.com" underline="hover">
              contact support
            </Link>{' '}
            or{' '}
            <Link
              color="primary"
              target="_blank"
              href="https://developers.everyonesocial.com/"
              underline="hover"
            >
              {' '}
              visit our docs
            </Link>{' '}
            for help.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartnerShareError;
