import React, { isValidElement, PropsWithChildren, useEffect } from 'react';
import { UseQueryResult } from 'react-query';

import { LoaderTypes } from 'types/app';
import { Loaders, NullComponent } from './';
import notify from 'notify';

type Props<Query> = UseQueryResult<Query> & {
  children: (props: PropsWithChildren<Props<Query>>) => any;
  loader?: LoaderTypes;
};

function QueryManager<Query>(props: PropsWithChildren<Props<Query>>) {
  const { isLoading, loader, isError, error, children } = props;
  const LoaderComponent = getLoader();

  useEffect(() => {
    if (isError) {
      notify.queryError(error as Error);
    }
  }, [isError, error]);

  function getLoader() {
    switch (loader) {
      case 'standard':
        return Loaders.Standard;
      case 'widget':
        return Loaders.Widget;
      case 'none':
        return NullComponent;
      default:
        return Loaders.Standard;
    }
  }

  if (isLoading) {
    return isValidElement(loader) ? loader : <LoaderComponent {...props} />;
  }

  return children(props);
}

QueryManager.defaultProps = {
  loader: 'standard',
};

export default QueryManager;
