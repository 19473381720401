import React, { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCapabilityDetailsQuery } from 'api';
import { Paper, Typography, Box } from '@mui/material';

import { useNotify } from 'hooks';
import { Layouts } from 'components';
import { CapabilityForm } from '../components/Capabilities';
import Standard from 'components/Loaders/Standard';

const FeatureToggleDetails: FC = () => {
  const { capability_id } = useParams<{ capability_id: string }>();
  const notify = useNotify();

  const { isLoading, data } = useGetCapabilityDetailsQuery(
    {
      capability_id: capability_id,
    },
    {
      onError: notify.queryError,
    }
  );

  const capability = data?.getCapabilityDetails;

  function renderMain() {
    return (
      <Paper>
        {isLoading && <Standard />}
        {capability && (
          <Fragment>
            <Box m={4}>
              <Typography variant="h3">{capability.description}</Typography>
            </Box>
            <CapabilityForm clients={capability.clients} capability_id={capability.capability_id} />
          </Fragment>
        )}
      </Paper>
    );
  }

  return <Layouts.App1Column desktopSize={12} main={renderMain()} />;
};

export default FeatureToggleDetails;
