import React, { FC } from 'react';
import { Box, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { GroupFragment, useGetFilterChipsQuery } from 'api';
import { useNotify } from 'hooks';
import { LoaderComponents } from 'components';

interface Props {
  selectedGroups?: GroupFragment[];
  flattenedFilter: string[];
  onRemove: (id: string) => void;
}

const styles = {
  container: {
    paddingTop: 2,
    '& > div': {
      marginRight: 1,
      marginBottom: 1,
    },
  },
} as const;

const FilterChips: FC<Props> = ({ selectedGroups, flattenedFilter, onRemove }) => {
  const notify = useNotify();

  const { data, isLoading } = useGetFilterChipsQuery(
    {},
    {
      onError: notify.queryError,
    }
  );

  if (isLoading) {
    return (
      <Box paddingTop={2} sx={styles.container}>
        <LoaderComponents.Block height={32} width={150} />
      </Box>
    );
  }

  const { getUserSegments, getClientDepartmentsByStatus, getClientLocationsByStatus } = data;
  const { client_departments } = getClientDepartmentsByStatus;
  const { client_locations } = getClientLocationsByStatus;
  const masterList = getUserSegments.concat(selectedGroups, client_departments, client_locations);

  return (
    <Box paddingTop={2} sx={styles.container}>
      {flattenedFilter.map(id => {
        const resource = masterList.find(item => item.id === id);

        return (
          <Chip
            key={id}
            variant="outlined"
            label={resource?.title}
            onDelete={() => onRemove(id)}
            deleteIcon={<CloseIcon fontSize="small" />}
          />
        );
      })}
    </Box>
  );
};

FilterChips.defaultProps = {
  selectedGroups: [],
};

export default FilterChips;
