import React, { FC } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface Props {
  query: string;
  onClear: () => void;
}

const EndAdornment: FC<Props> = ({ query, onClear }) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={onClear} size="large">
        <AddCircleIcon sx={query ? { transform: 'rotate(45deg)' } : { color: 'transparent' }} />
      </IconButton>
    </InputAdornment>
  );
};

export default EndAdornment;
