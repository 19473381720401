import React, { FC, useEffect, useState } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { Box, MenuItem, Paper, Popper } from '@mui/material';

import { useEmojiContext } from '../Contexts';
import { EMOJI_MAX_COUNT, EMOJI_OVERFLOW_BUFFER, EMOJI_ROW_HEIGHT } from '../constants';
import theme from 'theme';

const styles = {
  root: {
    maxHeight: EMOJI_ROW_HEIGHT * EMOJI_MAX_COUNT + EMOJI_OVERFLOW_BUFFER,
    overflow: 'auto',
  },
  colons: {
    marginLeft: theme.spacing(),
  },
  menuItem: {
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'primary.main',
    },
  },
} as const;

const EmojiSearch: FC = () => {
  const editor = useSlate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { target, index, list, actions } = useEmojiContext();
  const [prevRect, setPrevRect] = useState<DOMRect>(null);

  useEffect(() => {
    if (!target) {
      return setAnchorEl(null);
    }

    const rect = getBoundingClientRect();
    setPrevRect(rect);

    setAnchorEl({
      clientWidth: rect.width,
      clientHeight: rect.height,
      getBoundingClientRect,
    });
  }, [target]);

  function getBoundingClientRect(): DOMRect {
    try {
      const domRange = ReactEditor.toDOMRange(editor, target);
      return domRange.getBoundingClientRect();
    } catch (error) {
      return prevRect;
    }
  }

  return (
    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start">
      <Paper sx={styles.root}>
        {list.map((emoji, eIndex) => (
          <MenuItem
            sx={styles.menuItem}
            selected={eIndex === index}
            onClick={() => actions.insertEmoji(emoji)}
            key={emoji.id}
          >
            {emoji.skins[0].native}
            <Box component="span" sx={styles.colons}>
              {emoji.skins[0].shortcodes}
            </Box>
          </MenuItem>
        ))}
      </Paper>
    </Popper>
  );
};

export default EmojiSearch;
