import React, { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';

interface IProps {
  height: number;
  colSpan: number;
}

const styles = {
  cell: {
    border: 'none',
    padding: 0,
  },
} as const;

const DummyTableRow: FC<IProps> = ({ height, colSpan }) => (
  <TableRow sx={{ height }}>
    <TableCell colSpan={colSpan} sx={styles.cell} />
  </TableRow>
);

export default DummyTableRow;
