import React, { createContext, FC, ReactElement } from 'react';

import { UniqueClientKey, QueryGetActiveClientByAttrArgs, ClientPublicFragment } from 'api/public';
import { Queries } from 'components';
import { getSubdomain } from 'helpers';

interface Props {
  children: ReactElement;
}

export const GlobalClientContext = createContext<ClientPublicFragment>(null);

export const GlobalClientProvider: FC<Props> = ({ children }) => {
  const variables: QueryGetActiveClientByAttrArgs = {
    key: UniqueClientKey.Subdomain,
    value: getSubdomain(),
  };

  return (
    <Queries.GetActiveClientByAttr variables={variables}>
      {client => (
        <GlobalClientContext.Provider value={client}>{children}</GlobalClientContext.Provider>
      )}
    </Queries.GetActiveClientByAttr>
  );
};
