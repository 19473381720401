// Special Routes
export const index = '/';
export const logout = '/logout';
export const sso = '/sso';
export const help = 'https://help.everyonesocial.app';
export const oauthAuth = '/v1/oauth/auth';

// Public Routes

// - Connections
export const publicConnect = '/connect/:network/:content_id';

// - Auth
export const confirmSignup = '/confirm-signup';
export const forgotPassword = '/forgot-password';
export const forgotPasswordSubmit = '/forgot-password-submit';
export const invitation = '/invitation';
export const unsubscribe = '/unsubscribe';
export const login = '/login';
export const passwordLogin = '/password-login';
export const passwordlessLogin = '/passwordless-login';
export const signUp = '/sign-up';
export const socialMediaPolicy = '/social-media-policy';
export const authError = '/auth-error';
export const locationBlock = '/location-block';
export const expressActivation = '/express';

// Protected Routes

// - Client Success
export const clientSuccess = '/client-success';

// - Groups
export const group = '/group/:group_id';
export const groupCreate = '/group/create';
export const groupEdit = '/group/:group_id/edit';
export const groupMembers = '/group/:group_id/members';
export const groupScheduled = '/group/:group_id/scheduled';
export const groupApproval = '/group/:group_id/approval';
export const groupRemoved = '/group/:group_id/removed';
export const groups = '/groups';
export const groupsDepartment = '/groups/department/:department_id';
export const groupsLocation = '/groups/location/:location_id';
export const groupsIOwn = '/groups/owner';
export const groupsModerating = '/groups/moderating';
export const groupsFollowing = '/groups/following';
export const groupsAll = '/groups/all';

// - Teams
export const team = '/team';
export const teamEveryone = '/team/everyone';
export const teamDepartment = '/team/department/:department_id';
export const teamLocation = '/team/location/:location_id';

// - Profile
export const profile = '/profile/:profile_id';
export const profileGroups = '/profile/:profile_id/groups';

// - Features
export const activity = '/activity';
export const network = '/network';
export const leaderboard = '/leaderboard';
export const leaderboardView = '/leaderboard/:leaderboard_id';
export const account = '/account';
export const notifications = '/notifications';
export const search = '/search';

// - Admin
export const adminInvite = '/admin/invite';
export const adminGroups = '/admin/groups';
export const adminInvitations = '/admin/invitations';
export const adminMessages = '/admin/messages';
export const adminLeaderboards = '/admin/leaderboards';
export const adminLeaderboardsCreate = '/admin/leaderboards/create';
export const adminLeaderboardsEdit = '/admin/leaderboards/:config_id';
export const adminContentEmails = '/admin/emails';
export const adminContentEmailCreate = '/admin/emails/create';
export const adminContentEmailEdit = '/admin/emails/:email_id';

export const adminAnalytics = '/admin/analytics';
export const adminAnalyticsOverview = '/admin/analytics/overview';
export const adminAnalyticsGroups = '/admin/analytics/groups';
export const adminAnalyticsUsers = '/admin/analytics/users';
export const adminAnalyticsPosts = '/admin/analytics/posts';
export const adminAnalyticsShares = '/admin/analytics/shares';
export const adminAnalyticsLeaderboards = '/admin/analytics/leaderboards';

export const adminPostManagement = '/admin/post-management';
export const adminUserManagement = '/admin/user-management';

export const adminSettings = '/admin/settings';
export const adminSettingsAccount = '/admin/settings/account';
export const adminSettingsLocations = '/admin/settings/locations';
export const adminSettingsDisclosures = '/admin/settings/disclosures';
export const adminSettingsPolicies = '/admin/settings/policies';
export const adminSettingsReferrals = '/admin/settings/referrals';
export const adminSettingsIntegrations = '/admin/settings/integrations';
export const adminSettingsCompanyPages = '/admin/settings/company-pages';

export const adminSettingsBlocklist = '/admin/settings/blocklist';
export const adminSettingsUserSegments = '/admin/settings/user-segments';
export const adminSettingsShareTracking = '/admin/settings/share-tracking';
export const adminSettingsSharing = '/admin/settings/sharing';
export const adminSettingsStickers = '/admin/settings/stickers';
export const adminSettingsAssignments = '/admin/settings/assignments';
export const adminSettingsAssignmentsCreate = '/admin/settings/assignments/create';
export const adminSettingsAssignmentsEdit = '/admin/settings/assignments/:assignment_id';

export const adminUpgrade = '/admin/upgrade';
export const adminBilling = '/admin/billing';

// - Connections
export const connectLoader = '/connect';
export const connectNetwork = '/connect/:network';
export const connectionSuccess = '/connections/success';
export const connectionFailure = '/connections/failure';

// - Onboarding
export const onboarding = '/onboarding';
export const onboardingCreate = '/onboarding/create';
export const onboardingIdentity = '/onboarding/identity';
export const onboardingSocials = '/onboarding/socials';

// - Setup
export const setupProfile = '/setup/profile';
export const setupWorkspace = '/setup/workspace';
export const setupWorkspaceGroups = '/setup/workspace-groups';
export const setupWorkspaceInvites = '/setup/workspace-invites';

// - Post
export const postCreate = '/post/create';
export const postEdit = '/post/edit/:content_id';

// - Share
export const share = '/share';

// - Root
export const workspaces = '/workspaces';
export const workspaceLauncher = '/workspace-launcher';
export const clientCreate = '/client/create';
export const integrationsProvider = '/integrations/:provider';
export const sharingSandbox = '/sharing-sandbox';

// Hybrid Routes (available to both public and protected)

// - Content
export const content = '/content/:content_id';
export const contentLike = '/content/:content_id/like';
export const contentShare = '/content/:content_id/share';

// Partners
export const partnerShare = '/partner-share/:content_id';
export const partnerActivity = '/partner-activity';

// Organic insights
export const insights = '/insights';
export const insightsCompanies = '/insights/companies';
export const insightsPeople = '/insights/people';

/**
 * This section is for matching routes to a selected navbar tab
 * The list is stored here instead of NavBar.tsx to avoid
 * missing forgetfulness when adding a new route
 */
export const HOME_TAB_ROUTES = [index, postCreate, postEdit, share];
export const NETWORK_TAB_ROUTES = [network];
export const ACTIVITY_TAB_ROUTES = [activity];
export const LEADERBOARD_TAB_ROUTES = [leaderboard, leaderboardView];
export const TEAM_TAB_ROUTES = [
  team,
  teamEveryone,
  teamDepartment,
  teamLocation,
  profile,
  profileGroups,
];
export const GROUPS_TAB_ROUTES = [
  group,
  groupCreate,
  groupEdit,
  groupMembers,
  groupScheduled,
  groupApproval,
  groupRemoved,
  groups,
  groupsDepartment,
  groupsLocation,
  groupsIOwn,
  groupsModerating,
  groupsFollowing,
  groupsAll,
];
export const ADMIN_TAB_ROUTES = [
  adminInvite,
  adminGroups,
  adminInvitations,
  adminMessages,
  adminLeaderboards,
  adminLeaderboardsCreate,
  adminLeaderboardsEdit,
  adminContentEmails,
  adminContentEmailCreate,
  adminContentEmailEdit,
  adminAnalytics,
  adminAnalyticsOverview,
  adminAnalyticsGroups,
  adminAnalyticsUsers,
  adminAnalyticsPosts,
  adminAnalyticsShares,
  adminAnalyticsLeaderboards,
  adminPostManagement,
  adminUserManagement,
  adminSettings,
  adminSettingsAccount,
  adminSettingsBlocklist,
  adminSettingsLocations,
  adminSettingsCompanyPages,
  adminSettingsDisclosures,
  adminSettingsIntegrations,
  adminSettingsPolicies,
  adminSettingsReferrals,
  adminSettingsUserSegments,
  adminSettingsShareTracking,
  adminSettingsSharing,
  adminSettingsStickers,
  adminSettingsAssignments,
  adminSettingsAssignmentsCreate,
  adminSettingsAssignmentsEdit,
  adminUpgrade,
  adminBilling,
];
export const INSIGHTS_ROUTES = [insights, insightsCompanies, insightsPeople];

/**
 * Master list of all protected routes. We use this for redirecting users to
 * the chosen route after logging in. If you're adding a protected path, you
 * should add it here as well
 */
export const PROTECTED_ROOT_ROUTES = [
  workspaceLauncher,
  workspaces,
  clientCreate,
  integrationsProvider,
];

export const PROTECTED_ROUTES = [
  account,
  notifications,
  search,
  connectLoader,
  connectNetwork,
  connectionSuccess,
  connectionFailure,
  onboarding,
  onboardingCreate,
  onboardingIdentity,
  onboardingSocials,
  setupProfile,
  setupWorkspace,
  setupWorkspaceGroups,
  setupWorkspaceInvites,
  postCreate,
  postEdit,
  share,
  content,
  contentLike,
  contentShare,
  ...HOME_TAB_ROUTES,
  ...NETWORK_TAB_ROUTES,
  ...ACTIVITY_TAB_ROUTES,
  ...LEADERBOARD_TAB_ROUTES,
  ...TEAM_TAB_ROUTES,
  ...GROUPS_TAB_ROUTES,
  ...ADMIN_TAB_ROUTES,
  ...INSIGHTS_ROUTES,
];
