import React, { FC, Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Divider, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import moment from 'moment';

import { CustomSVG } from 'components';
import { useCapabilities, useContent } from 'hooks';

import { isPinned } from '../../helpers';

const Pinned: FC = () => {
  const { t } = useTranslation();
  const { isAdmin } = useCapabilities();
  const navigate = useNavigate();
  const theme = useTheme();
  const content = useContent();

  if (!isAdmin || !isPinned(content)) {
    return null;
  }

  const { pinned_end_at } = content;
  const expiresTime = moment.unix(pinned_end_at).format('h:mm A');
  const expiresDate = moment.unix(pinned_end_at).format('MMMM D');

  return (
    <Fragment>
      <Grid
        container
        alignItems="center"
        onClick={() => navigate(`/post/edit/${content.content_id}`)}
        sx={{
          padding: theme.spacing(1, 2),
          cursor: 'pointer',
        }}
      >
        <CustomSVG
          name="pin"
          sx={{
            marginRight: 1,
            color: 'primary.main',
          }}
        />
        <Typography>{t('content:expiresOn', { expiresTime, expiresDate })}</Typography>
      </Grid>
      <Divider />
    </Fragment>
  );
};

export default Pinned;
