import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';

import {
  Button,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  Unstable_Grid2 as Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { AspectRatios } from 'types/app';
import { generateS3Image } from 'helpers';
import { useCurrentUser } from 'hooks';
import { IUploader, UploaderContentTypes, UploadInput } from '../';

interface Props extends IUploader {
  i18nKeyTitle: string;
  i18nKeyReplace: string;
  aspectRatio: AspectRatios;
  fixedAspectRatio?: boolean;
}

const styles = {
  dialogTitle: {
    margin: 0,
    paddingBottom: 0,
    borderBottom: 0,
  },
  dialogContent: {
    paddingTop: 1,
  },
  cropper: {
    height: 400,
  },
  container: {
    marginTop: 2,
  },
  saveButton: {
    borderRadius: 4,
    minWidth: 10,
  },
} as const;

const Dialog: FC<Props> = props => {
  const {
    aspectRatio,
    fixedAspectRatio,
    data,
    onSave,
    i18nKeyTitle,
    i18nKeyReplace,
    onClose,
    open,
    onUpload,
  } = props;
  const currentUser = useCurrentUser();
  const cropper = useRef<HTMLImageElement>();
  const { t } = useTranslation();

  function getImage(blob: Blob) {
    const { file } = data;

    return generateS3Image(blob, file, currentUser);
  }

  function handleSave() {
    // @ts-ignore
    const canvas = cropper.current.cropper.getCroppedCanvas();
    const { height, width } = canvas;

    canvas.toBlob((blob: Blob) => {
      const image = {
        height,
        width,
        ...getImage(blob),
      };

      onSave(image, UploaderContentTypes.Image);
      onClose();
    });
  }

  function renderTitle() {
    return (
      <DialogTitle sx={styles.dialogTitle}>
        <Typography variant="h6">{t(i18nKeyTitle)}</Typography>
        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    );
  }

  function renderCropper() {
    const { src } = data;

    if (!src) {
      return null;
    }

    const aspectRatioOptions = fixedAspectRatio
      ? { aspectRatio, zoomable: false }
      : { initialAspectRatio: aspectRatio, autoCropArea: 1 };

    return (
      <Cropper
        ref={cropper}
        src={src as string}
        style={styles.cropper}
        autoCropArea={1}
        {...aspectRatioOptions}
      />
    );
  }

  return (
    <MuiDialog fullWidth open={open}>
      {renderTitle()}
      <DialogContent sx={styles.dialogContent}>
        {renderCropper()}
        <Grid container justifyContent="space-between" alignItems="center" sx={styles.container}>
          <Grid>
            <UploadInput onUpload={onUpload} accept="image/*">
              <Button component="span">{t(i18nKeyReplace)}</Button>
            </UploadInput>
          </Grid>
          <Grid>
            <Button sx={styles.saveButton} variant="contained" color="primary" onClick={handleSave}>
              {t('posting:saveImage')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </MuiDialog>
  );
};

Dialog.defaultProps = {
  fixedAspectRatio: false,
};

export default Dialog;
