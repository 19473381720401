import React, { CSSProperties, FC, Fragment } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { ContentInput } from 'api';
import { useContent, useReleases, useSharePhoto } from 'hooks';
import { useFormikContext } from 'formik';
import { Dimensions } from 'components';
import { DimensionsProvider } from 'providers';
import * as paths from 'paths';

import { usePostContext } from 'features/posting';

import { ImageType } from './types';
import Photo from '../Photo';
import Image from '../Image';
import Renderer from './Renderer';

const styles = {
  P80: {
    paddingTop: '80%',
  },
  P100: {
    paddingTop: '100%',
  },
  P140: {
    paddingTop: '140%',
  },
} as const;

interface RendererOptions {
  index: number;
  rootClass: CSSProperties;
  autoShrink: boolean;
}

interface Props {
  disableViewer?: boolean;
  onClick?: () => void;
  preview?: boolean;
}

const ImageLayouts: FC<Props> = ({ disableViewer, onClick, preview }) => {
  const releases = useReleases();
  const formik = useFormikContext<ContentInput>();
  const content = useContent();
  const { state } = usePostContext();
  const { pathname } = useLocation();
  const { selectedPhotoIndex } = useSharePhoto();

  const hasVariations = releases.includes('shareVariations');
  const isExternalShare = pathname === paths.share;
  const imageList = getImages();

  function getImages() {
    const list: Array<ImageType> = [];

    const { content_object } = formik?.values ?? content;
    const { url_metadata, photos } = content_object;
    const { selectedPhotoIndexes } = state;

    if (!preview) {
      // Feed URL and Manual post
      if (photos?.length) {
        list.push(...photos);
        return list;
      }

      // RSS feed
      if (!content.user) {
        url_metadata?.images?.length && list.push(url_metadata?.images[0]);
        return list;
      }
    }

    // External Share
    if (isExternalShare) {
      list.push(formik.values.content_object.photos[selectedPhotoIndex]);
      return list;
    }

    // Url Preview
    if (selectedPhotoIndexes.length) {
      const filteredPhotos = photos.filter((photo, index) => selectedPhotoIndexes.includes(index));
      list.push(...filteredPhotos);
      return list;
    }

    return list;
  }

  function renderImage({ index, rootClass, autoShrink }: RendererOptions) {
    const image = imageList[index];
    const commonProps = {
      onClick,
      disableViewer,
      autoShrink,
      classes: {
        root: rootClass,
        image: {},
      },
      image: imageList[index],
    };

    if ('key' in image) {
      return <Renderer filePathKey="key" {...commonProps} />;
    }

    if ('file_key' in image) {
      return <Renderer filePathKey="file_key" {...commonProps} />;
    }

    throw new Error('The Images component encountered an unknown object type');
  }

  function renderOne() {
    const [image] = imageList;

    if (!image) {
      return null;
    }

    if ('key' in image) {
      return (
        <Dimensions>
          {dimensions => (
            <DimensionsProvider value={dimensions}>
              <Photo photo={image} onClickReaction={onClick} />
            </DimensionsProvider>
          )}
        </Dimensions>
      );
    }

    if ('file_key' in image) {
      return <Image content={content} onClickReaction={onClick} />;
    }

    return null;
  }

  function renderTwo() {
    return (
      <Grid container>
        <Grid xs={6}>{renderImage({ index: 0, rootClass: styles.P140, autoShrink: false })}</Grid>
        <Grid xs={6}>{renderImage({ index: 1, rootClass: styles.P140, autoShrink: false })}</Grid>
      </Grid>
    );
  }

  function renderThree() {
    return (
      <Fragment>
        <Grid container>
          <Grid xs>{renderImage({ index: 0, rootClass: styles.P80, autoShrink: true })}</Grid>
        </Grid>
        <Grid container>
          <Grid xs={6}>{renderImage({ index: 1, rootClass: styles.P80, autoShrink: false })}</Grid>
          <Grid xs={6}>{renderImage({ index: 2, rootClass: styles.P80, autoShrink: false })}</Grid>
        </Grid>
      </Fragment>
    );
  }

  function renderFour() {
    return (
      <Fragment>
        <Grid container>
          <Grid xs>{renderImage({ index: 0, rootClass: styles.P80, autoShrink: true })}</Grid>
        </Grid>
        <Grid container>
          <Grid xs={4}>{renderImage({ index: 1, rootClass: styles.P100, autoShrink: false })}</Grid>
          <Grid xs={4}>{renderImage({ index: 2, rootClass: styles.P100, autoShrink: false })}</Grid>
          <Grid xs={4}>{renderImage({ index: 3, rootClass: styles.P100, autoShrink: false })}</Grid>
        </Grid>
      </Fragment>
    );
  }

  function renderFive() {
    return (
      <Fragment>
        <Grid container>
          <Grid xs={6}>{renderImage({ index: 0, rootClass: styles.P100, autoShrink: false })}</Grid>
          <Grid xs={6}>{renderImage({ index: 1, rootClass: styles.P100, autoShrink: false })}</Grid>
        </Grid>
        <Grid container>
          <Grid xs={4}>{renderImage({ index: 2, rootClass: styles.P100, autoShrink: false })}</Grid>
          <Grid xs={4}>{renderImage({ index: 3, rootClass: styles.P100, autoShrink: false })}</Grid>
          <Grid xs={4}>{renderImage({ index: 4, rootClass: styles.P100, autoShrink: false })}</Grid>
        </Grid>
      </Fragment>
    );
  }

  if (!hasVariations) {
    return renderOne();
  }

  switch (imageList.length) {
    case 1:
      return renderOne();
    case 2:
      return renderTwo();
    case 3:
      return renderThree();
    case 4:
      return renderFour();
    case 5:
      return renderFive();
    default:
      return null;
  }
};

ImageLayouts.defaultProps = {
  disableViewer: false,
  onClick: () => undefined,
  preview: false,
};

export default ImageLayouts;
