import useInfiniteScroll from 'react-infinite-scroll-hook';
import { UseInfiniteQueryOptions } from 'react-query';

import {
  SearchGroupsQueryVariables,
  SearchGroupsQuery,
  UserFeedFollowGroupFragment,
  useSearchGroupsQuery,
} from 'api';
import { InfiniteQuery } from 'types/app';
import { useInfiniteQuery } from 'app-react-query/hooks';
import { useNotify } from 'hooks';
import { PAGINATION_ROOT_MARGIN } from 'app-constants';

type Query = SearchGroupsQuery;
type Variables = SearchGroupsQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = InfiniteQuery<UserFeedFollowGroupFragment>;

const useGroupSearch = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();
  const queryResult = useInfiniteQuery<Query, Variables>(
    useSearchGroupsQuery,
    ({ pageParam = 0 }: { pageParam: number }) => ({ ...variables, results_from: pageParam }),
    {
      initialData: {
        pages: [{ search: { count: 0, results: [], results_from: 0 } }],
        pageParams: [],
      },
      onError: notify.queryError,
      getNextPageParam: lastPage => {
        const { search } = lastPage;
        const endOfList = search.results.length >= search.count;
        const noResults = !search.results.length;

        if (noResults || endOfList) {
          return false;
        }

        return search.results_from + variables.size;
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = queryResult;
  const results = data.pages
    .map(page => page.search.results)
    .flat() as UserFeedFollowGroupFragment[];
  const { count } = data.pages[0].search;

  const [sentinelRef] = useInfiniteScroll({
    hasNextPage,
    loading: isFetchingNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: PAGINATION_ROOT_MARGIN,
  });

  return {
    sentinelRef,
    results,
    count,
    isEmpty: !results.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !results.length && isFetching,
  };
};

export default useGroupSearch;
