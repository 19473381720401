import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box, Button, Unstable_Grid2 as Grid } from '@mui/material';
import { lighten } from '@mui/material/styles';

import theme from 'theme';
import { DEFAULT_CURRENCY_VALUES } from '../ROIOverviewStats';

interface Props {
  collapsed: boolean;
  onToggleCollapse: (collapsed: boolean) => void;
}

const styles = {
  applyButton: {
    backgroundColor: theme.palette.secondary.light,
    color: 'primary.main',
    fontWeight: 800,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.light, 0.5),
    },
  },
} as const;

const ROIOverviewActions: FC<Props> = ({ collapsed, onToggleCollapse }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  function handleReset() {
    formik.resetForm({ values: DEFAULT_CURRENCY_VALUES });
  }

  if (!collapsed) {
    return (
      <Box marginRight={1}>
        <Button onClick={() => onToggleCollapse(!collapsed)}>
          {t(`analytics:roi_edit_values`)}
        </Button>
      </Box>
    );
  }

  return (
    <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
      <Grid>
        <Button type="reset" onClick={handleReset}>
          {t(`analytics:roi_restore_defaults`)}
        </Button>
      </Grid>
      <Grid>
        <Button variant="contained" type="submit" sx={styles.applyButton}>
          {t(`common:apply`)}
        </Button>
      </Grid>
      <Grid>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={() => onToggleCollapse(!collapsed)}
        >
          {t(`common:done`)}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ROIOverviewActions;
