import React, { FC, ReactNode } from 'react';

import { ClientProtectedFragment, GetClientQueryVariables, useGetClientQuery } from 'api';
import { QueryManager } from 'components';

interface IProps {
  variables: GetClientQueryVariables;
  children: (data: ClientProtectedFragment) => ReactNode;
}

const GetClient: FC<IProps> = ({ children, variables, ...queryProps }) => {
  const queryResult = useGetClientQuery(variables);

  return (
    <QueryManager {...queryResult} {...queryProps}>
      {({ data }) => {
        if (!data?.getClient) {
          return null;
        }
        return children(data.getClient);
      }}
    </QueryManager>
  );
};

export default GetClient;
