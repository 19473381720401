import React, { FC, Fragment, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Fab, IconButton, Menu as MuiMenu, MenuItem, Typography } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { useNavigate } from 'react-router-dom';

import * as Links from 'Links';
import { CustomSVG, Desktop, Mobile } from 'components';
import { useProtectedClient } from 'hooks';
import * as paths from '../../paths';
import theme from 'theme';

const styles = {
  composeButtonDesktop: {
    borderRadius: 22.5,
    padding: theme.spacing(1, 2),
    color: 'common.white',
  },
  composeButtonMobile: {
    backgroundColor: 'primary.main',
    color: 'common.white',
    padding: '6px',
    '&:hover': {
      backgroundColor: 'primary.dark',
      color: 'common.white',
    },
  },
  icon: {
    fontSize: 20,
  },
  menuText: {
    paddingLeft: 1,
  },
} as const;

const Menu: FC = () => {
  const { t } = useTranslation();
  const { allow_external_share } = useProtectedClient();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  function handleOpen(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  if (!allow_external_share) {
    return (
      <Fragment>
        <Mobile>
          <Fab color="primary" size="small" sx={styles.composeButtonMobile} onClick={handleOpen}>
            <CreateIcon fontSize="small" />
          </Fab>
        </Mobile>
        <Desktop>
          <Button
            variant="contained"
            color="primary"
            sx={styles.composeButtonDesktop}
            onClick={() => navigate(paths.postCreate)}
            startIcon={<CreateIcon fontSize="small" />}
          >
            {t('common:compose')}
          </Button>
        </Desktop>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <MuiMenu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem sx={{ paddingY: 1.5 }} onClick={handleClose} component={Links.PostCreate}>
          <CreateIcon sx={styles.icon} />
          <Typography sx={styles.menuText} variant="body2">
            {t('posting:internalPost')}
          </Typography>
        </MenuItem>
        <MenuItem sx={{ paddingY: 1.5 }} onClick={handleClose} component={Links.Share}>
          <CustomSVG name="share" size={20} />
          <Typography sx={styles.menuText} variant="body2">
            {t('posting:externalShare')}
          </Typography>
        </MenuItem>
      </MuiMenu>
      <Mobile>
        <IconButton sx={styles.composeButtonMobile} onClick={handleOpen} size="large">
          <CreateIcon fontSize="small" />
        </IconButton>
      </Mobile>
      <Desktop>
        <Button
          variant="contained"
          color="primary"
          sx={styles.composeButtonDesktop}
          onClick={handleOpen}
          startIcon={<CreateIcon fontSize="small" />}
        >
          {t('common:compose')}
        </Button>
      </Desktop>
    </Fragment>
  );
};

export default Menu;
