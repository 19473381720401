import React from 'react';
import { FormikValues } from 'formik';
import { intersectionWith, isEmpty } from 'lodash';

import {
  Content,
  ContentApproval,
  ContentCommentary,
  ContentDisclosures,
  ContentFragment,
  ContentShareFragment,
  Group,
  GroupIdApproval,
  S3Object,
  S3VideoObject,
  SharesItemFragment,
  UrlMetadataImage,
  UserFeedFollowGroupFragment,
  UserProfileFragment,
} from 'api';
import { RenderHTML } from 'components';
import { convertHTMLToText, isHTML, queryString, resizeImage } from 'utilities';
import { foundReadOnlyGroup } from 'features/posting/helpers';
import { IMGIX_HOST } from 'settings';
import { getFullName } from 'helpers';
import { GridLayoutSizes } from 'types/app';
import i18n from 'config/i18n';

import { Embed, EmbedPublic, Manual, ManualPublic, Url, UrlPublic } from './layouts';
import { EmbedFacebook, EmbedInstagram, EmbedTikTok, EmbedTwitter, EmbedYouTube } from './widgets';
import { FACEBOOK_OEMBED_REGEX } from './constants';

type ContentType = Content | ContentFragment | FormikValues | SharesItemFragment['content'];
type DisplayImage = S3Object | UrlMetadataImage | string;
type ImageOptions = { height?: number; width?: number; maxWidth?: number };

const MAX_TITLE_CHARS = 65;
const MAX_DESCRIPTION_CHARS = 155;
const APP_IMAGES_DIRECTORY = 'webapp';

const HOST_MATCHERS = {
  facebook: 'www.facebook.com',
  instagram: 'www.instagram.com',
  linkedin: 'www.linkedin.com',
  tiktok: 'www.tiktok.com',
};

const shouldUseFacebookEmbed = (content: ContentType) => {
  try {
    const { content_object } = content;

    if (!content_object.url) {
      return false;
    }

    const objectURL = new URL(content_object.url);
    const regex = new RegExp(FACEBOOK_OEMBED_REGEX, 'g');
    return regex.test(objectURL.href);
  } catch (e) {
    return false;
  }
};

const isHostMatch = (content: ContentType, match: string) => {
  try {
    const { content_object } = content;

    if (!content_object.url) {
      return false;
    }

    const objectURL = new URL(content_object.url);
    return objectURL.hostname === match;
  } catch (e) {
    return false;
  }
};

export const getLayout = (content: ContentType) => {
  if (isEmbed(content)) {
    return Embed;
  }

  if (isLinkedIn(content)) {
    return Url;
  }

  if (hasVideo(content)) {
    return Manual;
  }

  if (isUrl(content)) {
    return Url;
  }

  return Manual;
};

export const getPublicLayout = (content: ContentType) => {
  if (isEmbed(content)) {
    return EmbedPublic;
  }

  if (isLinkedIn(content)) {
    return UrlPublic;
  }

  if (hasVideo(content)) {
    return ManualPublic;
  }

  if (isUrl(content)) {
    return UrlPublic;
  }

  return ManualPublic;
};

export const getEmbed = (content: ContentType) => {
  if (isFacebook(content)) {
    const url = getUrl(content);
    return <EmbedFacebook url={url} />;
  }

  if (isInstagram(content)) {
    const url = getUrl(content);
    return <EmbedInstagram url={url} />;
  }

  if (isYouTube(content)) {
    const id = getYouTubeId(content);
    return <EmbedYouTube id={id} />;
  }

  if (isTwitter(content)) {
    const id = getTweetId(content);
    return <EmbedTwitter id={id} />;
  }

  if (isTikTok(content)) {
    const url = getUrl(content);
    return <EmbedTikTok url={url} />;
  }

  return null;
};

export const isSafari = () => {
  return (
    navigator.userAgent.toLowerCase().indexOf('safari/') !== -1 &&
    navigator.userAgent.toLowerCase().indexOf('chrome/') === -1
  );
};

export const isEmbed = (content: ContentType) => {
  return (
    (isFacebook(content) && shouldUseFacebookEmbed(content)) ||
    isTwitter(content) ||
    isYouTube(content) ||
    isInstagram(content) ||
    isTikTok(content)
  );
};

export const getNetwork = (content: ContentType) => {
  const { content_object } = content;
  if (content_object.url_metadata) {
    const { provider_name } = content_object.url_metadata;
    // TODO: https://everyonesocial.atlassian.net/browse/DEV-4428
    // look for backend changes to implement clearly defined network name here
    // rather than relying on hardcoded string matching.
    if (provider_name === 'www.instagram.com') return 'instagram';
    if (provider_name === '微信公众平台') return 'wechat';
    return provider_name;
  }

  return '';
};

export const isLinkedIn = (content: ContentType) => {
  return isHostMatch(content, HOST_MATCHERS.linkedin);
};

export const isTikTok = (content: ContentType) => {
  return isHostMatch(content, HOST_MATCHERS.tiktok);
};

export const isFacebook = (content: ContentType) => {
  return isHostMatch(content, HOST_MATCHERS.facebook) && getNetwork(content) === 'Facebook';
};

export const isInstagram = (content: ContentType) => {
  return isHostMatch(content, HOST_MATCHERS.instagram);
};

export const isTwitter = (content: ContentType) => {
  const { content_object } = content;
  return !!content_object.tweet_metadata;
};

export const isUrl = (content: ContentType) => {
  const { content_object } = content;
  return !!content_object.url;
};

export const isYouTube = (content: ContentType) => {
  const { content_object } = content;
  return !!content_object.youtube_metadata;
};

export const getYouTubeId = (content: ContentType) => {
  const { content_object } = content;
  const { youtube_metadata } = content_object;

  return youtube_metadata.video_id;
};

export const getTweetId = (content: ContentType) => {
  const { content_object } = content;
  const { tweet_metadata } = content_object;

  return tweet_metadata.id_str;
};

export const getUrl = (content: ContentType) => {
  const { content_object } = content;
  return content_object.url;
};

export const getImage = (content: ContentType) => {
  if (!content) {
    return null;
  }

  const { content_object } = content;
  const { url_metadata, photos } = content_object;

  if (!isEmpty(photos)) {
    return photos[0];
  }

  if (isEmpty(url_metadata)) {
    return null;
  }

  const { images } = url_metadata;
  return images ? images[0] : null;
};

export const getPhoto = (content: ContentType, index = 0) => {
  const { content_object } = content;
  const { photos } = content_object;

  if (!photos) {
    return null;
  }

  return photos ? photos[index] : null;
};

export const getVideo = (content: ContentType) => {
  if (isEmpty(content)) {
    return null;
  }

  const { content_object } = content;
  const { media } = content_object;

  if (!media || !media.video || !media.video) {
    return null;
  }

  return media.video;
};

export const getProviderDisplay = (content: ContentType) => {
  const { content_object } = content;
  const { url_metadata } = content_object;

  return url_metadata ? url_metadata.provider_display : '';
};

export const getProviderName = (content: ContentType) => {
  const { content_object } = content;
  const { url_metadata } = content_object;

  return url_metadata ? url_metadata.provider_name : '';
};

export const isPostCreator = (content: ContentType, user: UserProfileFragment) => {
  if (!hasCreator(content)) {
    return false;
  }

  return content.user.id === user.id;
};

export const hasCreator = (content: ContentType) => {
  return content.user;
};

export const hasUrl = (content: ContentType) => {
  const { content_object } = content;
  return !!content_object?.url?.length;
};

export const hasPhoto = (content: ContentType) => {
  return !!getPhoto(content);
};

export const hasVideo = (content: ContentType) => {
  return !!getVideo(content);
};

export const hasVideoPoster = (content: ContentType) => {
  return !!getVideoPoster(content);
};

export const isTextOnly = (content: ContentType) => {
  if (!content) {
    return false;
  }
  return !hasUrl(content) && !hasPhoto(content) && !hasVideo(content);
};

export const isPhotoOnly = (content: ContentType) => {
  return !hasUrl(content) && hasPhoto(content);
};

export const isVideoOnly = (content: ContentType) => {
  return !hasUrl(content) && hasVideo(content);
};

export const isShareable = (content: ContentType) => {
  return content.is_shareable;
};

export const isEngageable = (content: ContentType) => {
  return content.is_engageable;
};

export const getGroupsByApproval = (content: ContentType, contentApproval: ContentApproval) => {
  const { groups, group_id_approvals } = content;

  return intersectionWith(
    groups,
    group_id_approvals,
    (group: Group, groupIdApproval: GroupIdApproval) => {
      if (group.id !== groupIdApproval.group_id) {
        return false;
      }

      const { approval } = groupIdApproval;
      return approval === contentApproval;
    }
  );
};

export const getApprovedGroups = (content: ContentType) => {
  if (!content) {
    return [];
  }
  const approvedGroups = getGroupsByApproval(content, ContentApproval.Approved);
  const autoApprovedGroups = getGroupsByApproval(content, ContentApproval.Autoapproved);

  return approvedGroups.concat(autoApprovedGroups);
};

export const getSubmittedGroups = (content: ContentType) => {
  if (content.schedule?.publish) {
    return []; // content has a publish date, so it is scheduled rather than pending approval
  }
  return getGroupsByApproval(content, ContentApproval.Submitted);
};

export const getPostableGroupsToApprove = (
  content: ContentType,
  postableGroups: UserFeedFollowGroupFragment[]
): UserFeedFollowGroupFragment[] => {
  const submittedGroups = getSubmittedGroups(content);

  return intersectionWith(
    submittedGroups,
    postableGroups,
    (group: UserFeedFollowGroupFragment, postableGroup: UserFeedFollowGroupFragment) => {
      return group.id === postableGroup.id;
    }
  );
};

export const getPendingGroups = (content: ContentType) => {
  return getGroupsByApproval(content, ContentApproval.Pending);
};

export const getPrimaryGroup = (groups: ContentType['groups'][], primaryGroupId: string) => {
  return groups.find(group => group.id === primaryGroupId) || groups[0];
};

export const hasCommentary = (content: ContentType) => {
  if (!content.commentary) {
    return false;
  }

  return content.commentary.some((commentary: ContentCommentary) => !!commentary.commentary);
};

export const hasDisclosures = (content: ContentType) => {
  if (!content.disclosures) {
    return false;
  }

  return content.disclosures.some((disclosure: ContentDisclosures) => !!disclosure.disclosure_id);
};

export const isPinned = (content: ContentType) => {
  return content.is_pinned;
};

export const getVideoPoster = (content: ContentType) => {
  const video = getVideo(content);

  if (!video) {
    return null;
  }

  return video.poster;
};

export const getDisplayImage = (content: ContentType, index = 0): DisplayImage => {
  if (hasVideo(content)) {
    return getVideoPoster(content);
  }

  if (hasPhoto(content)) {
    return getPhoto(content, index);
  }

  if (hasUrl(content)) {
    return getUrlImage(content, index);
  }

  return null;
};

export const getUrlImage = (content: ContentType, index = 0) => {
  const { content_object } = content;
  const { url_metadata } = content_object;

  if (!url_metadata) {
    return null;
  }

  const { images } = url_metadata;
  return images ? images[index] : null;
};

export const getImageUrl = (content: ContentType, index = 0, options: ImageOptions = {}) => {
  const { height = 0, width = 0, maxWidth = 700 } = options;
  const displayImage = getDisplayImage(content, index);

  if (!displayImage) {
    return null;
  }

  if (isVideoPoster(displayImage)) {
    const params = queryString({
      auto: 'format,compress',
      fit: 'crop',
      crop: 'faces',
    });

    return `${IMGIX_HOST}/${displayImage}?${params}`;
  }

  const imageKey = isS3Object(displayImage) ? 'key' : 'file_key';

  if (displayImage[imageKey] && displayImage[imageKey].startsWith('file:')) {
    return displayImage[imageKey];
  }

  const { height: resizedHeight, width: resizedWidth } = resizeImage(
    displayImage.width,
    displayImage.height,
    maxWidth
  );

  const params = queryString({
    auto: 'format,compress',
    fit: 'crop',
    crop: 'faces',
    w: width || resizedWidth,
    h: height || resizedHeight,
  });

  return `${IMGIX_HOST}/${displayImage[imageKey]}?${params}`;
};

export const getGridImageUrl = (
  displayImage: DisplayImage,
  options: ImageOptions = {},
  size: GridLayoutSizes
) => {
  const { height = 0, width = 0, maxWidth = 700 } = options;

  if (!displayImage) {
    return null;
  }

  const { height: posterHeight, width: posterWidth } = resizeImage(width, height, maxWidth);

  const borderRadius = size === GridLayoutSizes.Large ? '0,16,16,0' : '16,16,0,0';
  const commonParams = {
    auto: 'format,compress',
    fit: 'crop',
    crop: 'faces',
    border: '1,00FFFFFF',
    'border-radius': borderRadius,
  };

  if (isNaN(posterHeight) || isNaN(posterWidth)) {
    return '';
  }

  if (isVideoPoster(displayImage)) {
    const params = queryString({
      width: posterWidth,
      height: posterHeight,
      'blend-mode': 'normal',
      blend64: btoa(`${IMGIX_HOST}/${APP_IMAGES_DIRECTORY}/video.png?w=80`),
      ...commonParams,
    });

    return `${IMGIX_HOST}/${displayImage}?${params}`;
  }

  const imageKey = isS3Object(displayImage) ? 'key' : 'file_key';

  const { height: resizedHeight, width: resizedWidth } = resizeImage(
    width || displayImage.width,
    height || displayImage.height,
    maxWidth
  );

  if (isNaN(resizedHeight) || isNaN(resizedWidth)) {
    return '';
  }

  const params = queryString({
    w: width || resizedWidth,
    h: height || resizedHeight,
    ...commonParams,
  });

  return `${IMGIX_HOST}/${displayImage[imageKey]}?${params}`;
};

export const getTitle = (content: ContentType, allowHTML = true) => {
  const { content_object } = content;

  if (isTextOnly(content)) {
    if (allowHTML && isHTML(content_object.body)) {
      return <RenderHTML html={content_object.body} />;
    }

    return convertHTMLToText(content_object.body);
  }

  if (content_object.title) {
    return content_object.title;
  }

  if (hasUrl(content)) {
    return content_object.url_metadata?.title ?? i18n.t('content:articleTitle');
  }

  if (hasVideo(content)) {
    return i18n.t('content:videoTitle');
  }

  if (hasPhoto(content)) {
    return i18n.t('content:photoTitle');
  }

  return 'EveryoneSocial';
};

export const getDescription = (content: ContentType) => {
  const { content_object } = content;

  if (content_object.description) {
    return content_object.description;
  }

  return content_object?.url_metadata?.description ?? i18n.t('content:genericDescription');
};

export const getOpenGraphTags = (content: ContentType) => {
  const image = getImage(content);
  const { height, width } = resizeImage(image?.width || 0, image?.height || 0, 700);
  const imageHeight = `${height}`;
  const imageWidth = `${width}`;

  let title = getTitle(content, false).substring(0, MAX_TITLE_CHARS);
  let description = getDescription(content).substring(0, MAX_DESCRIPTION_CHARS);
  let imageUrl = getImageUrl(content);

  function trimText(text: string) {
    const trimmedParts = text.split(' ');
    trimmedParts.pop();
    text = `${trimmedParts.join(' ')}...`;
    return text;
  }

  if (title.length >= MAX_TITLE_CHARS) {
    title = trimText(title);
  }

  if (description.length >= MAX_DESCRIPTION_CHARS) {
    description = trimText(description);
  }

  if (hasVideo(content)) {
    imageUrl = `${imageUrl}?w=600&q=25&blend-mode=normal&blend64=${btoa(
      `${IMGIX_HOST}/${APP_IMAGES_DIRECTORY}/video.png?w=80`
    )}`;
    description = '';
  }

  return {
    title,
    description,
    imageUrl,
    imageHeight,
    imageWidth,
  };
};

export const filterShareableContent = (
  content: ContentShareFragment,
  groupRules: Record<string, { read_only: boolean }>,
  allowSharing: boolean
) => {
  let isContentShareable = true;
  if (!content?.groups) {
    return false;
  }
  if ('is_shareable' in content) {
    isContentShareable = isShareable(content);
  }

  const groupIds = content.groups.map(group => group.id);

  const readOnlyGroup = foundReadOnlyGroup(groupIds, groupRules);
  return !readOnlyGroup && allowSharing && isContentShareable;
};

export const getContentApprovalFromContent = (content: Content) => {
  const { group_id_approvals } = content;
  if (!group_id_approvals.length) {
    return ContentApproval.Deleted; // if there are no groups, we count this as deleted
  }
  const groupApproval = group_id_approvals.find((a: GroupIdApproval) =>
    [ContentApproval.Approved, ContentApproval.Autoapproved].includes(a.approval)
  );
  if (groupApproval) {
    return ContentApproval.Approved;
  }
  const approval = group_id_approvals[0].approval;
  if (approval === ContentApproval.Submitted && !content.schedule?.publish) {
    return ContentApproval.Pending;
  }
  return approval;
};

export const getAuthor = (content: ContentType) => {
  if (isUrl(content)) {
    return getProviderDisplay(content);
  }

  if (hasCreator(content)) {
    return getFullName(content.user);
  }

  return '';
};

function isVideoPoster(image: DisplayImage): image is string {
  return typeof image === 'string';
}

function isS3Object(image: DisplayImage): image is S3Object {
  return (image as S3VideoObject | S3Object).key !== undefined;
}
