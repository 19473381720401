import { UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  GetGroupSourcesQuery,
  UpdateGroupSourceMutation,
  UpdateGroupSourceMutationVariables,
  useGetGroupSourcesQuery,
} from 'api';
import { useNotify } from 'hooks';

type Mutation = UpdateGroupSourceMutation;
type MutationVariables = UpdateGroupSourceMutationVariables;
type QueryData = GetGroupSourcesQuery;

const useUpdateSource = (groupId: string) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const queryKey = useGetGroupSourcesQuery.getKey({ group_id: groupId });

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async ({ data }) => {
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const { getClientGroupSourcesByGroup } = draftState;

          const index = getClientGroupSourcesByGroup.findIndex(source => source.id === data.id);
          const currentSource = getClientGroupSourcesByGroup[index];

          draftState.getClientGroupSourcesByGroup[index] = {
            ...currentSource,
            ...data,
          };
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },

    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }

      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useUpdateSource;
