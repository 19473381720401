import React, { FC } from 'react';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const styles = {
  icon: {
    fontSize: 28,
    transition: 'color 100ms ease-in-out',
    marginLeft: 1,
    backgroundColor: 'common.white',
  },
  inactive: {
    color: 'grey.300',
  },
  focused: {
    color: 'common.black',
  },
} as const;

interface Props {
  focused: boolean;
}

const StartAdornment: FC<Props> = ({ focused }) => {
  const searchIconClasses = { ...styles.icon, ...(focused ? styles.focused : styles.inactive) };

  return (
    <InputAdornment position="start">
      <SearchIcon sx={searchIconClasses} />
    </InputAdornment>
  );
};

export default StartAdornment;
