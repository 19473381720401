import React, { FC } from 'react';
import parse from 'html-react-parser';
import theme from 'theme';
import { Box } from '@mui/material';

interface Props {
  html: string;
}

const styles = {
  root: {
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: 'pre-line',
    ...theme.mixins.breakWord,
    '& a': {
      textDecoration: 'none',
      color: 'primary.main',
    },
    '& p': {
      marginTop: 0,
    },
    '& blockquote': {
      borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.grey[50]}`,
      paddingLeft: theme.spacing(),
    },
    '& h1, & h2': {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
} as const;

const RenderHTML: FC<Props> = ({ html }) => {
  const parsed = parse(html);

  return <Box sx={styles.root}>{parsed}</Box>;
};

export default RenderHTML;
