import React, { FC, ForwardRefExoticComponent, ReactElement, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import { IFilter } from 'types/app';
import { UserFeedFollowGroupFragment } from 'api';
import { useCurrentUser } from 'hooks';
import theme from 'theme';

import PrivateGroupIcon from './PrivateGroupIcon';

interface IProps {
  link: ForwardRefExoticComponent<any>;
  isSelected: boolean;
  textNode: string;
  color?: 'inherit';
  id?: string;
  icon?: ReactElement;
  selectedColor?: string;
  inset?: boolean;
  subMenu?: IFilter[];
  group?: UserFeedFollowGroupFragment;
  secondaryAction?: ReactNode;
  onClick?: () => void;
}

const styles = {
  item: {
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0, 2, 2, 0),
    borderLeft: `4px solid ${theme.palette.primary.main}`,

    '&:hover': {
      color: 'inherit',
      backgroundColor: 'transparent',

      '& $itemText': {
        color: 'common.black',
      },
    },

    '&$itemSelected, &$itemSelected:hover, &$itemSelected:focus': {
      borderRadius: '0 18px 18px 0',

      '& $itemText': {},
    },

    '&.Mui-selected': {
      backgroundColor: theme.palette.grey[200],
      color: 'common.black',
    },
  },
  itemSelected: {},
  itemTextWrapper: {
    margin: 0,
    ...theme.mixins.overflowText(),
  },
  itemText: {
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
  normal: {
    padding: '10px 8px 10px 14px',
  },
  inherit: {
    '&$itemSelected, &$itemSelected:hover, &$itemSelected:focus': {
      backgroundColor: 'grey[600]',
    },
  },
  selected: {
    backgroundColor: theme.palette.grey[100],
    color: 'common.black',
  },
  inset: {
    paddingLeft: 4,
    '&$itemSelected, &$itemSelected:hover, &$itemSelected:focus': {
      backgroundColor: 'transparent',

      '& $itemText': {
        color: 'common.black',
      },
    },
  },
  superMenu: {
    borderRadius: '0 4px 0 0',
    color: 'text.secondary',

    [theme.breakpoints.up('lg')]: {
      borderLeft: `4px solid transparent`,
    },

    '&$itemSelected, &$itemSelected:hover, &$itemSelected:focus': {
      backgroundColor: 'transparent',
    },
  },
  privateIcon: {
    marginTop: -2,
    marginRight: theme.spacing(0.5),
  },
  secondaryAction: {
    paddingRight: 5,
  },
  listItemSecondaryAction: {
    right: 4,
    visibility: 'hidden',
  },
} as const;

const SidebarItem: FC<IProps> = props => {
  const theme = useTheme();
  const params = useParams<{ group_id: string }>();
  const currentUser = useCurrentUser();

  const {
    id,
    color,
    link,
    isSelected,
    selectedColor,
    textNode,
    icon,
    inset,
    subMenu,
    group,
    onClick,
    secondaryAction,
  } = props;

  const borderSelectedColor = selectedColor || theme.palette.primary.main;
  const borderLeftColor = !subMenu && isSelected ? borderSelectedColor : 'transparent';
  const itemClasses = {
    ...styles.normal,
    ...(!!secondaryAction && styles.secondaryAction),
    ...(color === 'inherit' && styles.inherit),
    ...(subMenu ? styles.superMenu : styles.item),
    ...(inset && styles.inset),
    ...(isSelected && styles.selected),
    borderLeftColor,
  };

  const menuItemProps = {
    component: link,
    selected: isSelected,
    onClick: onClick || handleScrollToTop,
  };

  const typeProps = {
    sx: styles.itemText,
    noWrap: true,
    title: textNode,
  };

  function handleScrollToTop() {
    const { group_id } = params;
    const isSameGroup = group_id === id;
    const isTimeline = currentUser.id === id;

    if (isSameGroup || isTimeline) {
      window.scrollTo(0, 0);
    }
  }

  function renderArrow() {
    if (!subMenu) {
      return null;
    }

    return (
      <ListItemIcon>
        {isSelected ? <ExpandLess color="action" /> : <ExpandMore color="action" />}
      </ListItemIcon>
    );
  }

  return (
    <ListItem component="button" {...menuItemProps} sx={itemClasses}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      {group?.is_private && (
        <ListItemIcon sx={{ root: styles.privateIcon }}>
          <PrivateGroupIcon group={group} type="list" />
        </ListItemIcon>
      )}
      <ListItemText
        sx={styles.itemTextWrapper}
        primaryTypographyProps={typeProps}
        primary={textNode}
      />
      {renderArrow()}
      {secondaryAction && (
        <ListItemSecondaryAction
          sx={{ ...styles.listItemSecondaryAction, ...(isSelected && { visibility: 'inherit' }) }}
        >
          {secondaryAction}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default SidebarItem;
