import { InfiniteData, UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';
import { isEmpty, remove } from 'lodash';

import { useNotify } from 'hooks';
import {
  DeleteScheduledSharesMutation,
  DeleteScheduledSharesMutationVariables,
  GetSharesItemsQuery,
  GetSharesItemsQueryVariables,
  SharesItem,
  useGetSharesItemsQuery,
} from 'api';
import { WritableDraft } from 'immer/dist/types/types-external';

type Mutation = DeleteScheduledSharesMutation;
type MutationVariables = DeleteScheduledSharesMutationVariables;
type QueryData = InfiniteData<GetSharesItemsQuery>;
type QueryVariables = GetSharesItemsQueryVariables;

const useDeleteSharesItems = (variables: QueryVariables) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const queryKey = useGetSharesItemsQuery.getKey(variables);

  const itemsCallback = (page: WritableDraft<GetSharesItemsQuery>, ids: string | string[]) => {
    remove(page.getSharesItems.items, (shareItem: SharesItem) => {
      return ids.includes(shareItem.id);
    });
  };

  const itemsBySessionCallback = (
    page: WritableDraft<GetSharesItemsQuery>,
    ids: string | string[]
  ) => {
    const { items_by_session } = page.getSharesItems;

    items_by_session.forEach((shareItem: SharesItem[], shareIdx: number) => {
      const idx = shareItem.findIndex(result => ids.includes(result.id));
      if (idx !== -1) {
        page.getSharesItems.items_by_session[shareIdx].splice(idx, 1);
      }
      if (isEmpty(items_by_session[shareIdx])) {
        page.getSharesItems.items_by_session.splice(shareIdx, 1);
      }
    });
  };

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async ({ ids }) => {
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.pages.forEach(page =>
            variables.group_by_session
              ? itemsBySessionCallback(page, ids)
              : itemsCallback(page, ids)
          );
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },

    onError: (error, _, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }

      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useDeleteSharesItems;
