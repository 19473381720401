import { FC, useEffect, useState } from 'react';

import { useListPublicSocialConnectionsQuery } from 'api/public';
import { LOCAL_STORAGE_KEY_ACCESS_KEY, CONNECTION_SUCCESS_MESSAGE } from 'app-constants';

const PublicConnections: FC = () => {
  const [refetch, setRefetch] = useState(false);

  useListPublicSocialConnectionsQuery(
    { access_key: getCurrentAccessKey() },
    {
      enabled: refetch,
      onSettled: () => setRefetch(false),
    }
  );

  useEffect(() => {
    window.addEventListener('message', event => void updateConnections(event), false);
    return () => {
      window.removeEventListener('message', event => void updateConnections(event), false);
    };
  }, []);

  function getCurrentAccessKey() {
    return localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_KEY);
  }

  function updateConnections(event: MessageEvent) {
    const origin = window.location.origin;

    if (event.origin !== origin || !event.data) {
      return;
    }

    if (event.data?.message !== CONNECTION_SUCCESS_MESSAGE) {
      return;
    }

    setRefetch(true);
  }

  return null;
};

export default PublicConnections;
