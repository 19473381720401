import React, { FC, Fragment } from 'react';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { ContentInput } from 'api';

import {
  BodyTextField,
  BrandedCheckbox,
  PhotoStrip,
  ReadOnlyWarning,
  ShareableCheckbox,
  TitleTextField,
} from '../';

import UploadSection from './UploadSection';
import { DisabledView } from 'components';

interface Props {
  moderator: boolean;
  contributor: boolean;
  disabled?: boolean;
}

const ImageVideoForm: FC<Props> = ({ contributor, disabled }) => {
  const { values } = useFormikContext<ContentInput>();
  const { photos } = values.content_object;

  return (
    <Fragment>
      <TitleTextField />

      <DisabledView disabled={disabled}>
        <UploadSection />
        {!!photos.length && (
          <Box marginBottom={2}>
            <PhotoStrip />
          </Box>
        )}
      </DisabledView>

      <BodyTextField />

      <DisabledView disabled={disabled}>
        <ShareableCheckbox />
        {contributor && <BrandedCheckbox />}
        <ReadOnlyWarning />
      </DisabledView>
    </Fragment>
  );
};

export default ImageVideoForm;
