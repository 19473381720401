import React, { FC, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { GridSize } from '@mui/material/Grid';

import { PaddedPaper } from 'components';

interface IProps {
  icon: ReactNode;
  titleKey: string;
  subtitleKey: string;
  toggle: ReactNode;
  toggleColumns?: GridSize;
  disabled?: boolean;
}

const styles = {
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
} as const;

const WidgetContainer: FC<IProps> = ({
  icon,
  titleKey,
  subtitleKey,
  toggle,
  toggleColumns,
  children,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <PaddedPaper>
      <Grid container sx={disabled ? styles.disabled : {}}>
        <Grid xs={1}>
          <Box mt={0.5}>{icon}</Box>
        </Grid>
        <Grid container xs>
          <Grid xs={12}>
            <Typography variant="subtitle1">{t(titleKey)}</Typography>
          </Grid>
          <Grid xs={12}>
            <Typography color="textSecondary">{t(subtitleKey)}</Typography>
          </Grid>
        </Grid>
        <Grid xs={toggleColumns}>{toggle}</Grid>
        {children && (
          <Grid xs={12}>
            <Fragment>{children}</Fragment>
          </Grid>
        )}
      </Grid>
    </PaddedPaper>
  );
};

WidgetContainer.defaultProps = {
  toggleColumns: 1,
};

export default WidgetContainer;
