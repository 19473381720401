import React, { FC, Fragment } from 'react';
import { useFormikContext } from 'formik';

import { Network as NetworkType } from 'api';
import { Capability, PaddedPaper } from 'components';
import { useSocialConnections } from 'hooks';
import { connectionPopup } from 'helpers';

import { Share } from '../types';
import NetworkDisconnected from './NetworkDisconnected';
import NetworkDirect from './NetworkDirect';
import NetworkConnected from './NetworkConnected';

const ShareConnections: FC = ({ children }) => {
  const { twitterAccounts, linkedinAccounts } = useSocialConnections();
  const { values } = useFormikContext<Share>();

  function renderConnectTwitter() {
    if (twitterAccounts.length) {
      return null;
    }

    return (
      <PaddedPaper data-testid={NetworkType.Twitter} sx={{ marginBottom: 2 }}>
        <NetworkDisconnected
          networkMeta={{ provider: NetworkType.Twitter, name: 'Twitter' }}
          onConnect={() => connectionPopup(NetworkType.Twitter)}
        />
      </PaddedPaper>
    );
  }

  function renderConnectLinkedIn() {
    if (linkedinAccounts.length) {
      return null;
    }

    return (
      <PaddedPaper data-testid={NetworkType.Linkedin} sx={{ marginBottom: 2 }}>
        <NetworkDisconnected
          networkMeta={{ provider: NetworkType.Linkedin, name: 'LinkedIn' }}
          onConnect={() => connectionPopup(NetworkType.Linkedin)}
        />
      </PaddedPaper>
    );
  }

  function renderConnection(index: number) {
    const { state } = values.items[index];
    const { networkMeta } = state;

    const isFacebook = networkMeta.provider === NetworkType.Facebook;
    const isLinkedInPage = networkMeta.provider === NetworkType.LinkedinPage;

    if (isFacebook) {
      return (
        <Capability name="Sharing.Function.ShareToFacebook">
          <NetworkDirect index={index} />
        </Capability>
      );
    }

    if (isLinkedInPage) {
      return (
        <Capability name="Sharing.Function.ShareToLinkedIn">
          <NetworkConnected index={index} />
        </Capability>
      );
    }

    return (
      <Capability name={`Sharing.Function.ShareTo${state.networkMeta.name}`}>
        <NetworkConnected index={index} />
      </Capability>
    );
  }

  return (
    <Fragment>
      <Capability name="Sharing.Function.ShareToTwitter">{renderConnectTwitter()}</Capability>
      <Capability name="Sharing.Function.ShareToLinkedIn">{renderConnectLinkedIn()}</Capability>
      {values.items.map((item, index) => (
        <PaddedPaper
          key={index}
          data-testid={`${item.state.networkMeta.provider}-${index}`}
          sx={{ marginBottom: 2 }}
        >
          {renderConnection(index)}
        </PaddedPaper>
      ))}
      {children}
    </Fragment>
  );
};

export default ShareConnections;
