import React, { ChangeEvent, FC, HTMLAttributes, useState } from 'react';
import { find, map } from 'lodash';
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Checkbox,
  Chip,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';

import { ClientDepartmentFragment, useGetClientDepartmentsByStatusQuery } from 'api';
import { useNotify } from 'hooks';
import { PickerInput, PopupIcon } from './components';
import useAutoCompleteStyles from './styles/useAutoCompleteStyles';

type CustomProps<T> = Omit<
  AutocompleteProps<T, boolean, boolean, boolean>,
  'renderInput' | 'options'
>;
type DepartmentIds = Array<ClientDepartmentFragment['id']>;
type Options = Array<Partial<ClientDepartmentFragment>>;

interface IProps extends CustomProps<ClientDepartmentFragment> {
  selectedDepartmentIds: DepartmentIds;
  onDepartmentChange: (departmentIds: DepartmentIds, departments: Options) => void;
  saving?: boolean;
}

const DepartmentPicker: FC<IProps> = props => {
  const { selectedDepartmentIds, onDepartmentChange, saving, ...rest } = props;

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const notify = useNotify();

  const { data, isLoading } = useGetClientDepartmentsByStatusQuery(
    {},
    {
      onError: notify.queryError,
    }
  );

  const autocompleteClasses = useAutoCompleteStyles;

  const options = data?.getClientDepartmentsByStatus?.client_departments ?? [];
  const selectedOptions = getSelectedOptions();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function getSelectedOptions() {
    return selectedDepartmentIds.reduce((list, id) => {
      const selected = find(options, ['id', id]);
      if (!selected) {
        return list.concat([]);
      }
      return list.concat([selected]);
    }, []);
  }

  function toggleOpen() {
    setOpen(!open);
  }

  function handleChange(event: ChangeEvent, nextValue: Options) {
    const ids = map(nextValue, 'id');
    setInputValue('');
    onDepartmentChange(ids, nextValue);
  }

  function renderTags(value: Options, getTagProps: AutocompleteGetTagProps) {
    return value.map((department, index) => (
      <Chip
        key={department.id}
        label={department.title}
        deleteIcon={<CloseIcon />}
        {...getTagProps({ index })}
      />
    ));
  }

  function renderOption(
    props: HTMLAttributes<HTMLLIElement>,
    option: ClientDepartmentFragment,
    { selected }: AutocompleteRenderOptionState
  ) {
    return (
      <Grid component="li" container alignItems="center" {...props}>
        <Grid xs>{option.title}</Grid>
        <Grid>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
        </Grid>
      </Grid>
    );
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <PickerInput
        working={saving || isLoading}
        placeholder="components:departmentSuggest"
        onChange={setInputValue}
        params={params}
      />
    );
  }

  return (
    <Autocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      open={open}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      loading={isLoading}
      sx={autocompleteClasses}
      options={options}
      onChange={handleChange}
      getOptionLabel={({ title }) => title}
      popupIcon={<PopupIcon open={open} />}
      renderTags={renderTags}
      renderOption={renderOption}
      renderInput={renderInput}
      value={selectedOptions}
      inputValue={inputValue}
      {...rest}
    />
  );
};

export default DepartmentPicker;
