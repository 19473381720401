export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
export const PIE_CHART_HEIGHT = 303;
export const PIE_CHART_LOADER_HEIGHT = 365;
export const LINE_CHART_HEIGHT = 365;
export const LINE_CHART_LOADER_HEIGHT = 365;
export const EXTERNAL_CHART_HEIGHT = 320;
export const INTERNAL_CHART_HEIGHT = 400;
export const ROI_CHART_HEIGHT = 200;

// Magic numbers from measuring renders. Purely
// for loader heights and ensuring no UI shifting
export const TABBED_CHART_EXCESS_HEIGHT = 129;
export const ROI_CHART_EXCESS_HEIGHT = 85;

export const OVERVIEW_TABLE_PER_PAGE = 5;
