import React, { FC, Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  GlobalStyles,
  Unstable_Grid2 as Grid,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import {
  AuthenticationType,
  ClientProtectedFragment,
  Network,
  useCreateLoginTokenForCurrentUserInClientMutation,
} from 'api';
import {
  APP_IMAGES,
  CONNECTION_FAILURE_MESSAGE,
  CONNECTION_SUCCESS_MESSAGE,
  OAUTH_SUCCESS_MESSAGE,
  README_SUCCESS_MESSAGE,
} from 'app-constants';
import { Loaders, OAuthScopes } from 'components';
import { WorkspacePicker } from 'components/Pickers';
import { getAppImage, getSubdomainUrl } from 'helpers';
import { popup } from 'utilities';
import { useNotify } from 'hooks';
import * as paths from 'paths';
import * as Links from 'Links';
import theme from 'theme';

const MAX_WIDTH = 640;

const PROVIDERS = {
  [Network.Salesloft]: {
    name: 'Salesloft',
    path: `connect/${Network.Salesloft}`,
    icon: '/images/salesloft-icon.webp',
    scopes: ['write'],
  },
  khoros: {
    name: 'Khoros',
    path: 'v1/oauth/auth',
    icon: '/images/khoros-icon.png',
    scopes: ['read', 'write'],
  },
  upcontent: {
    name: 'UpContent',
    path: 'v1/oauth/auth',
    icon: '/images/upcontent-icon.png',
    scopes: ['read', 'write'],
  },
  readme: {
    name: 'Readme',
    path: 'connect/readme',
    icon: '/images/readme-icon.png',
    scopes: ['read', 'write'],
  },
};

const styles = {
  button: {
    width: 100,
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
} as const;

const globalStyles = {
  body: {
    backgroundColor: theme.palette.common.white,
  },
};

const OAuthIntegrationClient: FC = () => {
  const notify = useNotify();
  const { t } = useTranslation();
  const { provider } = useParams<{ provider: string }>();
  const [client, setClient] = useState<ClientProtectedFragment>(null);
  const [noWorkspaces, setNoWorkspaces] = useState(false);
  const { mutateAsync, isLoading } = useCreateLoginTokenForCurrentUserInClientMutation();

  useEffect(() => {
    window.addEventListener('message', event => handleConnection(event), false);
    return () => {
      window.removeEventListener('message', event => handleConnection(event), false);
    };
  });

  if (!PROVIDERS[provider]) {
    window.location.href = paths.workspaces;
    return null;
  }

  const { name, path, icon, scopes } = PROVIDERS[provider];
  const subdomainUrl = `${getSubdomainUrl(client?.subdomain ?? '')}`;

  const connectionName = name;
  const connectionIcon = icon;
  const connectionLoaderUrl = `${subdomainUrl}${paths.connectLoader}`;
  const connectionUrl = `${subdomainUrl}/${path}`;

  function getRedirectUrl() {
    const urlParams = new URLSearchParams(window.location.search);

    switch (provider) {
      case Network.Salesloft: {
        return 'https://app.salesloft.com/app/settings/integrations';
      }
      case 'readme': {
        const redirect = urlParams.get('redirect') || '/';
        const [, slug] = redirect.split('/');
        return `https://developers.everyonesocial.com/${slug}`;
      }

      default: {
        throw new Error('Unknown integration provider');
      }
    }
  }

  function isOauthProvider() {
    const searchParams = new URLSearchParams(location.search);
    const oauth_response_type = searchParams.get('response_type');
    const oauth_client_id = searchParams.get('client_id');
    const oauth_redirect_uri = searchParams.get('redirect_uri');
    const oauth_state = searchParams.get('state');

    return oauth_response_type && oauth_client_id && oauth_redirect_uri && oauth_state;
  }

  function handleConnection(event: MessageEvent) {
    if (event.origin !== subdomainUrl || !event.data) {
      return;
    }

    if (event.data?.message === CONNECTION_SUCCESS_MESSAGE) {
      window.location.href = getRedirectUrl();
    }

    if (event.data?.message === CONNECTION_FAILURE_MESSAGE) {
      notify.error({ message: t('api:connectFailure', { name: connectionName }) });
    }

    if (event.data?.message === README_SUCCESS_MESSAGE) {
      const readmeUrl = getRedirectUrl();
      const { token } = event.data;

      window.location.href = `${readmeUrl}?auth_token=${token}`;
    }

    if (event.data?.message === OAUTH_SUCCESS_MESSAGE) {
      const { redirect_url } = event.data;
      window.location.href = redirect_url;
    }
  }

  function handleWorkspaceChange(client: ClientProtectedFragment) {
    if (!client) {
      setNoWorkspaces(true);
    }
    setClient(client);
  }

  async function handleProcess() {
    const isSSOClient = client.authentication_type === AuthenticationType.Sso;
    const queryParams = new URLSearchParams();

    if (isOauthProvider()) {
      const searchParams = new URLSearchParams(location.search);
      const oauth_response_type = searchParams.get('response_type');
      const oauth_client_id = searchParams.get('client_id');
      const oauth_redirect_uri = searchParams.get('redirect_uri');
      const oauth_state = searchParams.get('state');

      queryParams.set('response_type', oauth_response_type);
      queryParams.set('client_id', oauth_client_id);
      queryParams.set('redirect_uri', oauth_redirect_uri);
      queryParams.set('state', oauth_state);
    }

    if (isSSOClient) {
      popup(`${connectionUrl}?${queryParams.toString()}`, 'Connection Popup', 650, 650);
      return;
    }

    try {
      const popupWindow = popup(connectionLoaderUrl, 'Connection Popup', 650, 650);
      const data = await mutateAsync({ client_id: client.id });
      const { createLoginTokenForCurrentUserInClient } = data;

      queryParams.set('token', createLoginTokenForCurrentUserInClient);
      popupWindow.location.href = `${connectionUrl}?${queryParams.toString()}`;
    } catch (error) {
      notify.mutationError();
    }
  }

  function renderTermsAndPolicies() {
    return (
      <Trans i18nKey="api:terms">
        <Link
          color="primary"
          href="https://everyonesocial.com/user-terms-of-service/"
          target="_blank"
          underline="hover"
        >
          User Terms
        </Link>
        |
        <Link
          color="primary"
          href="https://everyonesocial.com/cookie-policy/"
          target="_blank"
          underline="hover"
        >
          Cookie Policy
        </Link>
        |
        <Link
          color="primary"
          href="https://everyonesocial.com/privacy-policy/"
          target="_blank"
          underline="hover"
        >
          Privacy Policy
        </Link>
      </Trans>
    );
  }

  return (
    <Fragment>
      <GlobalStyles styles={globalStyles} />
      <AppBar position="fixed">
        <Toolbar>
          <Grid container alignItems="center">
            <Grid xs>
              <div style={{ width: 242, height: 30 }}>
                <img {...getAppImage(APP_IMAGES.Logo, 30)} />
              </div>
            </Grid>
            <Grid>
              <Box width={250}>
                <WorkspacePicker onChange={handleWorkspaceChange} />
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box display="flex" justifyContent="center">
        <Box
          width={{ lg: MAX_WIDTH }}
          maxWidth={{ xs: MAX_WIDTH, sm: MAX_WIDTH, md: MAX_WIDTH }}
          paddingTop={11}
        >
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid>
              <img alt={`${connectionName} Icon`} height={64} width={64} src={connectionIcon} />
            </Grid>
            <Grid>
              <SwapHorizIcon fontSize="large" />
            </Grid>
            <Grid>
              <img
                alt="EveryoneSocial Icon"
                height={64}
                width={64}
                src="/images/everyonesocial-icon.png"
              />
            </Grid>
          </Grid>
          <Box marginTop={6}>
            <Typography variant="h4" align="center">
              {t('api:connect', { name: connectionName })}
            </Typography>
          </Box>
          {scopes.includes('read') && (
            <Fragment>
              <Box marginTop={6} marginBottom={2}>
                <Typography variant="h4">
                  {t('api:viewHeader', { name: connectionName })}
                </Typography>
              </Box>
              <OAuthScopes.Profile />
              <OAuthScopes.Analytics />
            </Fragment>
          )}
          {scopes.includes('write') && (
            <Fragment>
              <Box marginTop={6} marginBottom={2}>
                <Typography variant="h4">{t('api:doHeader', { name: connectionName })}</Typography>
              </Box>
              <OAuthScopes.Post />
              <OAuthScopes.Share />
              <OAuthScopes.Workspace />
            </Fragment>
          )}
          <Box marginTop={6} marginBottom={6}>
            <Grid container justifyContent="center">
              <Grid>
                <Button
                  onClick={handleProcess}
                  disabled={!client?.subdomain || isLoading}
                  sx={styles.button}
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  {isLoading ? <Loaders.Standard height={26} width={60} /> : t('common:proceed')}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={10} marginBottom={6}>
            <Typography align="center">{renderTermsAndPolicies()}</Typography>
          </Box>
        </Box>
      </Box>
      <Dialog fullWidth maxWidth="xs" open={noWorkspaces}>
        <DialogContent sx={styles.dialogContent}>
          <Box textAlign="center">
            <img width={300} height={300} {...getAppImage('illustrations/boat.png', 300)} />
          </Box>
          <Typography gutterBottom align="center" variant="h6">
            {t('auth:workspaceNoneHeader')}
          </Typography>
          <Typography align="center" color="textSecondary">
            {t('auth:integrationsNoWorkspaces', { name: connectionName })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" component={Links.Workspaces}>
            {t('components:takeMeThere')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default OAuthIntegrationClient;
