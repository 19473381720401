import React, { FC, ReactNode } from 'react';

import { SortedLeaderboard, useLeaderboardOverviewStatsQuery } from 'api';
import { LoaderTypes } from 'types/app';
import { QueryManager } from 'components';
import { useProtectedClient } from 'hooks';

interface IProps {
  loader?: LoaderTypes;
  children: (data: SortedLeaderboard[]) => ReactNode;
  endDate: string;
  startDate: string;
  leaderboardConfigId: string;
  page?: number;
  perPage?: number;
}

const LeaderboardOverviewStats: FC<IProps> = ({
  children,
  loader,
  startDate,
  endDate,
  leaderboardConfigId,
  page,
  perPage,
}) => {
  const client = useProtectedClient();
  const queryResult = useLeaderboardOverviewStatsQuery({
    client_id: client.id,
    start_date: startDate,
    end_date: endDate,
    leaderboard_config_id: leaderboardConfigId,
    page,
    per_page: perPage,
  });

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.sortedLeaderboards) {
          return null;
        }
        return children(data.sortedLeaderboards);
      }}
    </QueryManager>
  );
};

export default LeaderboardOverviewStats;
