import React, { FC } from 'react';
import { AvatarGroup } from '@mui/material';

import { MinimalUserProfileFragment } from 'api';
import theme from 'theme';

import UserAvatar from './UserAvatar';

interface Props {
  users: MinimalUserProfileFragment[];
  size?: number;
  max?: number;
}

const styles = {
  avatar: {
    marginLeft: theme.spacing(-1),
    border: `2px ${theme.palette.common.white} solid`,
  },
  root: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
} as const;

const UserAvatarGroup: FC<Props> = ({ size, users, max }) => {
  if (!users?.length) {
    return null;
  }

  return (
    <AvatarGroup
      max={max}
      sx={{ ...styles.root, avatar: { ...styles.avatar, width: size, height: size } }}
      slotProps={{
        additionalAvatar: {
          sx: { width: size, height: size, fontSize: 'medium' },
        },
      }}
    >
      {users.map(user => (
        <UserAvatar size={size} key={user.id} user={user} />
      ))}
    </AvatarGroup>
  );
};

UserAvatarGroup.defaultProps = {
  size: 40,
  max: 15,
};

export default UserAvatarGroup;
