import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';
import moment from 'moment';

interface Props {
  time: number;
}

const Date: FC<Props> = ({ time }) => {
  const { t } = useTranslation();
  const mom = moment.unix(time);

  return (
    <Grid container spacing={1} justifyContent="space-between">
      <Grid>
        <Typography display="inline" variant="body2">
          {mom.format('ddd, MMM D, YYYY')} {t('common:at')} {mom.format('h:mmA')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Date;
