import React, { FC } from 'react';
import { TableCell, Typography } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';
import moment from 'moment';

interface IPropsEnhanced extends TableCellProps {
  dateValue: string;
}

const DateCell: FC<IPropsEnhanced> = ({ dateValue, ...rest }) => (
  <TableCell {...rest}>
    <Typography>{dateValue !== '' && moment(dateValue).format('MM/DD/YYYY')}</Typography>
  </TableCell>
);

export default DateCell;
