import React, { FC, useEffect } from 'react';
import { lighten, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import { Button, Switch, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { Network, SocialAccount } from 'api';
import { SocialIcon } from 'components';
import { ShareCopyProvider } from 'providers';
import { connectionPopup, getSocialAccountName } from 'helpers';

import { Share, ShareItem } from '../types';
import ShareCopy from './ShareCopy';

interface Props {
  index: number;
}

const NetworkConnected: FC<Props> = ({ index }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { values } = useFormikContext<Share>();
  const [field, , helpers] = useField<ShareItem>(`items.${index}`);

  const { name, value, onChange } = field;
  const { active, networkMeta, socialAccount } = value.state;
  const { expires_soon } = socialAccount;

  const isLinkedinPage = networkMeta.provider === Network.LinkedinPage;
  const network = isLinkedinPage ? Network.Linkedin : networkMeta.provider;
  const linkedin = values?.items.find(
    item => item?.state?.networkMeta.provider === Network.Linkedin
  );
  const linkedinExpiry = linkedin?.state?.socialAccount?.expires_soon || false;
  const willExpire = expires_soon || (isLinkedinPage && linkedinExpiry);

  useEffect(() => {
    if (willExpire) {
      helpers.setValue({ ...value, state: { ...value.state, active: false } });
    }
  }, [willExpire]);

  function renderAction() {
    if (willExpire) {
      return (
        <Button
          variant="contained"
          onClick={() => connectionPopup(network)}
          sx={{
            color: 'error.main',
            background: lighten(theme.palette.error.main, 0.8),
            '&:hover': {
              background: lighten(theme.palette.error.main, 0.77),
            },
          }}
        >
          {t('sharing:reconnectToken')}
        </Button>
      );
    }

    return <Switch name={`${name}.state.active`} checked={active} onChange={onChange} />;
  }

  return (
    <ShareCopyProvider network={network}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid container xs justifyContent="flex-start" spacing={2}>
          <Grid>
            <SocialIcon state={active ? 'active' : 'disabled'} provider={network} />
          </Grid>
          <Grid>
            <Typography variant="subtitle2" sx={{ marginTop: theme.spacing(0.5) }}>
              {getSocialAccountName(socialAccount as SocialAccount)}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs="auto">{renderAction()}</Grid>
      </Grid>
      {active && <ShareCopy index={index} />}
    </ShareCopyProvider>
  );
};

export default NetworkConnected;
