import React, { CSSProperties, FC, memo, useEffect, useState } from 'react';
import { Avatar as MuiAvatar } from '@mui/material';

import { getFullName, getImageSrc, getRandomProfileImage } from 'helpers';
import { MinimalUserProfile, ProfileSuggestion, UserProfileInput } from 'api';
import { generateLink } from 'Links';
import { IMGIX_HOST } from 'settings';

interface IProps {
  user: MinimalUserProfile & Partial<ProfileSuggestion>;
  link?: boolean;
  size?: number;
  sx?: CSSProperties;
}

const UserAvatar: FC<IProps> = memo(({ user, size, link, sx }) => {
  const [defaultImageSrc, setDefaultImageSrc] = useState(getRandomProfileImage());
  const style = { height: size, width: size };

  const fullName = getFullName(user);
  const imageProps = user.image ? getImageProps() : defaultImageSrc;

  useEffect(() => {
    setDefaultImageSrc(defaultImageSrc);
  }, [user]);

  function getProfileLink() {
    return link && user.id ? { component: generateLink(`/profile/${user.id}`) } : {};
  }

  function getImageProps() {
    if ((user as UserProfileInput)?.image.localUri) {
      const { localUri } = (user as UserProfileInput).image;
      return { src: window.URL.createObjectURL(localUri as any) };
    }

    const imageUrl = `${IMGIX_HOST}/${user.image.key}?w=${size}&h=${size}auto=format`;
    return getImageSrc(imageUrl);
  }

  return (
    <MuiAvatar {...getProfileLink()} {...imageProps} sx={{ ...sx, ...style }} title={fullName} />
  );
});

UserAvatar.defaultProps = {
  link: false,
  size: 32,
};

export default UserAvatar;
