import React, { createContext, FC } from 'react';
import { findIndex, orderBy } from 'lodash';

import {
  GroupContentApprovalType,
  PostedToGroup,
  PublicUserProfileFragment,
  useGetPostedToGroupsQuery,
  UserFeedFollowFragment,
  UserFeedFollowGroupFragment,
} from 'api';
import { useCapabilities, useCurrentUser, useFollowedAllResultsQuery, useNotify } from 'hooks';
import { FOLLOW_QUERIES_LIMIT, USER_FEED_ID, USER_FEED_NAME } from 'app-constants';
import palette from '../theme/palette';

type Groups = UserFeedFollowGroupFragment[];
type Users = PublicUserProfileFragment[];
type Follows = Groups & Users;

interface IPostable {
  hasTimeline: boolean;
  list: Groups;
  timeline: UserFeedFollowGroupFragment;
  recent: Groups;
}

export interface IFollowed {
  postable: IPostable;
  groups: Groups;
  groupRules: Record<string, { read_only: boolean }>;
  users: Users;
  followed: Follows;
  required: UserFeedFollowFragment[];
  moderated: Groups;
  owned: Groups;
  followedOnly: Groups;
  requiresApprovalGroups?: Groups;
}

export interface IFollowedContext {
  postable?: IPostable;
  groups?: Groups;
  groupRules?: Record<string, { read_only: boolean }>;
  users?: Users;
  followed?: Follows;
  required?: UserFeedFollowFragment[];
  moderated?: Groups;
  owned?: Groups;
  followedOnly?: Groups;
  requiresApprovalGroups?: Groups;
}

const FollowedContext = createContext<IFollowedContext>({
  postable: {
    hasTimeline: false,
    list: [],
    recent: [],
    timeline: null,
  },
  groups: [],
  users: [],
  followed: [],
  moderated: [],
  owned: [],
  required: [],
  requiresApprovalGroups: [],
});

const FollowedProvider: FC = ({ children }) => {
  const currentUser = useCurrentUser();
  const notify = useNotify();
  const { extract } = useCapabilities();

  const postedToGroupsQueryResult = useGetPostedToGroupsQuery(null, {
    onError: notify.queryError,
  });

  const feedFollows = useFollowedAllResultsQuery({
    data: { user_id: currentUser.id, limit: FOLLOW_QUERIES_LIMIT },
  });

  function filterRecent(groups: Groups, postedToGroups: PostedToGroup[]) {
    return postedToGroups.reduce((list, postedToGroup) => {
      const suggestedGroupIndex = findIndex(groups, ['id', postedToGroup.group_id]);
      const suggestedGroup = groups.splice(suggestedGroupIndex, 1);
      return list.concat(suggestedGroup);
    }, []);
  }

  function getAllFollowed(): Follows {
    const follows: Follows = [];

    feedFollows.forEach(follow => {
      follow.group && follows.push(follow.group);
      follow.user && follows.push(follow.user);
    });

    return follows;
  }

  function getPostable(): IPostable {
    const postableGroups = getPostableGroups();
    const postedToGroups = postedToGroupsQueryResult?.data?.contextGetPostedToGroups ?? [];

    const recentGroups = filterRecent(postableGroups, postedToGroups);

    const sortedGroups = orderBy(postableGroups, [group => group.title.toLowerCase()], ['asc']);
    const combinedGroups = [...recentGroups, ...sortedGroups];

    return {
      hasTimeline: false,
      timeline: {
        id: USER_FEED_ID,
        title: USER_FEED_NAME,
        color_map: {
          hex: palette.blue,
        },
        icon_map: {
          file_key: null,
          bucket: null,
        },
        content_approval_type: GroupContentApprovalType.Review,
      },
      recent: recentGroups,
      list: combinedGroups,
    };
  }

  function getFollowedUsers(): Users {
    const followedUsers: Users = [];

    feedFollows.forEach(follow => {
      follow.user && followedUsers.push(follow.user);
    });

    return followedUsers;
  }

  function getFollowedGroups(): Groups {
    const followedGroups: Groups = [];

    feedFollows.forEach(follow => {
      follow.group && followedGroups.push(follow.group);
    });

    return orderBy(followedGroups, [group => group.title.toLowerCase()], ['asc']);
  }

  function getFollowedGroupsRules() {
    const groupsReadOnlyMap = {};
    feedFollows.forEach(follow => {
      if (follow.group) {
        const group = follow.group;
        groupsReadOnlyMap[group.id] = { read_only: group.read_only };
      }
    });
    return groupsReadOnlyMap;
  }

  function getPostableGroups(): Groups {
    const followedGroups: Groups = [];

    feedFollows.forEach(follow => {
      follow.group && follow.postable && followedGroups.push(follow.group);
    });

    return orderBy(followedGroups, [group => group.title.toLowerCase()], ['asc']);
  }

  function getModeratedGroups(): Groups {
    const followedGroups = getFollowedGroups();
    const extractedGroupIds = extract('Groups.Moderator');
    return followedGroups.filter(group => extractedGroupIds.includes(group.id));
  }

  function getOwnedGroups() {
    const followedGroups = getFollowedGroups();
    const extractedGroupIds = extract('Groups.Owner');
    return followedGroups.filter(group => extractedGroupIds.includes(group.id));
  }

  function getFollowedOnlyGroups() {
    const moderatedGroupIds = extract('Groups.Moderator');
    const ownedGroupIds = extract('Groups.Owner');
    const participatedGroupIds = moderatedGroupIds.concat(ownedGroupIds);
    const followedGroups = getFollowedGroups();

    return followedGroups.filter(group => !participatedGroupIds.includes(group.id));
  }

  function getAllRequired(): UserFeedFollowFragment[] {
    return feedFollows.filter(feed => feed.required);
  }

  function getRequiresApprovalGroups(): Groups {
    const groups = getFollowedGroups();

    return groups.filter(group => group.content_approval_type === GroupContentApprovalType.Review);
  }

  const value: IFollowed = {
    followed: getAllFollowed(),
    postable: getPostable(),
    users: getFollowedUsers(),
    groups: getFollowedGroups(),
    groupRules: getFollowedGroupsRules(),
    required: getAllRequired(),
    moderated: getModeratedGroups(),
    owned: getOwnedGroups(),
    followedOnly: getFollowedOnlyGroups(),
    requiresApprovalGroups: getRequiresApprovalGroups(),
  };

  return <FollowedContext.Provider value={value}>{children}</FollowedContext.Provider>;
};

export { FollowedContext, FollowedProvider };
