import axios from 'axios';

import {
  CreateMultiPartUploadDocument,
  CompleteMultiPartUploadDocument,
  MultiPartListInput,
} from 'api';
import { PROTECTED_GRAPHQL_ENDPOINT } from 'settings';
import i18n from 'config/i18n';

import { FileField } from '../types';
import jwtToken from './get-jwt-token';

interface UploaderOptions {
  url: string;
  body: string;
  loaded: number;
}

const MAX_PARTS = 10000;
const PART_SIZE = 5 * 1024 * 1024;
const CUSTOM_USER_AGENT = 'EveryoneSocial File Upload Link';

const uploadFile = async (fileField: FileField, onProgress?: (val: number) => void) => {
  const { localUri: file } = fileField;
  const { size } = file as unknown as Blob;
  const numParts = Math.ceil(size / PART_SIZE);

  const graphQLAPIHeaders = {
    'x-amz-user-agent': CUSTOM_USER_AGENT,
    Authorization: await jwtToken(),
  };

  const s3UploadHeaders = {
    'Content-Type': 'application/octet-stream',
  };

  // Sets the limit at S3 max of 50 GB
  if (size / PART_SIZE > MAX_PARTS) {
    throw new Error(i18n.t('components:maxPartsError', { numParts: size / PART_SIZE, MAX_PARTS }));
  }

  async function createMultiPartUpload() {
    const createMultiPartMutation = JSON.stringify({
      query: CreateMultiPartUploadDocument,
      variables: {
        bucket_name: fileField.bucket,
        object_name: fileField.key,
        total_parts: numParts,
      },
    });

    const createMultiPartResult = await fetch(PROTECTED_GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: graphQLAPIHeaders,
      body: createMultiPartMutation,
    });

    const createMultiPartResponse = await createMultiPartResult.json();

    if (createMultiPartResponse.errors) {
      const { message } = createMultiPartResponse.errors[0];
      throw new Error(message);
    }

    const { createMultiPartUpload } = createMultiPartResponse.data;
    return createMultiPartUpload;
  }

  async function completeMultiPartUpload(multiPartListInput: MultiPartListInput[]) {
    const completeMultiPartMutation = JSON.stringify({
      query: CompleteMultiPartUploadDocument,
      variables: {
        bucket_name: fileField.bucket,
        object_name: fileField.key,
        upload_id: upload_id,
        multipart_upload: multiPartListInput,
      },
    });

    const completeMultiPartResult = await fetch(PROTECTED_GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: graphQLAPIHeaders,
      body: completeMultiPartMutation,
    });

    const completeMultiPartResponse = await completeMultiPartResult.json();

    if (completeMultiPartResponse.errors) {
      const { message } = completeMultiPartResponse.errors[0];
      throw new Error(message);
    }

    const { completeMultiPartUpload } = completeMultiPartResponse.data;
    return completeMultiPartUpload;
  }

  function generateParts(uploadUrls: string[]) {
    const parts = [];

    for (let bodyStart = 0; bodyStart < size; ) {
      const bodyEnd = Math.min(bodyStart + PART_SIZE, size);
      const body = file.slice(bodyStart, bodyEnd);

      parts.push({ url: uploadUrls[parts.length], body, loaded: 0 });
      bodyStart += PART_SIZE;
    }

    return parts;
  }

  function uploader(uploaderOptions: UploaderOptions, index: number) {
    const { url, body } = uploaderOptions;

    return axios.put(url, body, {
      headers: s3UploadHeaders,
      onUploadProgress: progressEvent => {
        parts[index].loaded = progressEvent.loaded;

        const totalLoaded = parts.reduce((acc, part) => acc + part.loaded, 0);
        const percentCompleted = Math.round((totalLoaded / size) * 100);

        onProgress?.(percentCompleted);
      },
    });
  }

  const { upload_id, upload_urls } = await createMultiPartUpload();
  const parts = generateParts(upload_urls);

  try {
    const uploadResults = await Promise.all(parts.map(uploader));
    const multiPartListInput = uploadResults.map((uploadResult, index) => {
      const { etag } = uploadResult.headers;
      return { etag, part_number: index + 1 };
    });

    await completeMultiPartUpload(multiPartListInput);
  } catch (error) {
    throw new Error(error);
  }
};

export default uploadFile;
