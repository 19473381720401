import React, { CSSProperties, FC, Fragment, useState } from 'react';

import { queryString } from 'utilities';
import { getImageSrc } from 'helpers';
import { IMGIX_HOST } from 'settings';
import { Dimensions } from 'components';

import { ImageType } from './types';
import Viewer from './Viewer';
import { Box } from '@mui/material';

interface Props {
  image: ImageType;
  filePathKey: string;
  disableViewer: boolean;
  autoShrink: boolean;
  onClick: () => void;
  classes: {
    root: CSSProperties;
    image: CSSProperties;
  };
}

const ImageRenderer: FC<Props> = ({
  image,
  filePathKey,
  disableViewer,
  autoShrink,
  onClick,
  classes,
}) => {
  const [viewerOpen, setViewerOpen] = useState(false);

  function handleOnClick() {
    setViewerOpen(true);
    onClick();
  }

  return (
    <Fragment>
      <Viewer
        filePathKey={filePathKey}
        image={image}
        open={viewerOpen}
        onClose={() => setViewerOpen(false)}
      />
      <Dimensions>
        {dimensions => {
          const isImageLessWide = image.width < dimensions.width;
          const isImageLessTall = image.height < dimensions.height;
          const disableScaling = isImageLessTall && isImageLessWide;

          const untouched = queryString({
            auto: 'format',
          });

          const scaled = queryString({
            auto: 'format',
            height: dimensions.height,
            width: dimensions.width,
            fit: 'crop',
            /**
             * imgix crop: entropy causes issues with animated images, and mime-type
             * detection only exists on file upload but not when a post
             * renders so GIF detection is not consistent.
             * https://docs.imgix.com/apis/rendering/size/crop#entropy
             */
            crop: 'faces',
          });

          const imageUrl = `${IMGIX_HOST}/${image[filePathKey]}?${
            disableScaling ? untouched : scaled
          }`;

          const imageStyle: CSSProperties = {
            objectFit: disableScaling ? 'none' : 'cover',
            cursor: disableViewer ? 'inherit' : 'zoom-in',
            position: 'absolute',
            height: '100%',
            width: '100%',
            inset: '0 0 0 0',
            ...classes.image,
          };

          const imageProps = {
            src: imageUrl,
            style: imageStyle,
            alt: 'EveryoneSocial Image',
            onClick: disableViewer ? undefined : handleOnClick,
            ...getImageSrc(imageUrl),
          };

          return (
            <Box
              sx={{
                overflow: 'hidden',
                position: 'relative',
                border: theme => `1px solid ${theme.palette.common.white}`,
                ...(disableScaling && autoShrink && { paddingTop: image.height * 2 }),
                ...classes.root,
              }}
            >
              <img {...imageProps} />
            </Box>
          );
        }}
      </Dimensions>
    </Fragment>
  );
};

export default ImageRenderer;
