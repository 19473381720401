import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { darken } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { Events } from 'analytics';
import { CustomSVG } from 'components';
import { useContent } from 'hooks';
import { useCanShare } from '../hooks';
import { ReactionType, useAddReactionAnalyticsMutation } from 'api';
import theme from 'theme';

const styles = {
  button: {
    color: 'common.white',
    backgroundColor: 'primary.main',
    '&:hover': {
      color: 'common.white',
      backgroundColor: darken(theme.palette.primary.main, 0.1),
    },
  },
  hasShared: {
    backgroundColor: 'grey.600',
  },
  small: {
    padding: theme.spacing(0.5),
  },
  medium: {
    padding: theme.spacing(),
  },
  regular: {
    padding: theme.spacing(),
  },
  inverted: {
    color: 'primary.main',
    '&:hover': {
      color: darken(theme.palette.primary.main, 0.1),
    },
  },
} as const;

interface Props {
  size: 'small' | 'medium' | 'regular';
  inverted?: boolean;
  position?: IconButtonProps['edge'];
  sx?: string;
}

const ShareButton: FC<Props> = ({ size, inverted, position, sx }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const content = useContent();
  const showShare = useCanShare(content);

  const { content_id } = content;

  const { mutate: addReactionAnalytics } = useAddReactionAnalyticsMutation();

  if (!showShare) {
    return null;
  }

  let iconSize = 0;
  switch (size) {
    case 'small':
      iconSize = 16;
      break;
    case 'medium':
      iconSize = 24;
      break;
    case 'regular':
      iconSize = 32;
      break;
  }
  const buttonstyles = {
    ...styles[size],
    sx,
    ...(inverted ? styles.inverted : styles.button),
    ...(content.has_requesting_user_shared && styles.hasShared),
  };

  function onClickButton() {
    handleOpen();

    addReactionAnalytics({
      content_id,
      reaction_type: ReactionType.Share,
    });
  }

  function handleOpen() {
    navigate(`/content/${content_id}`);

    const searchParams = new URLSearchParams(location.search);
    window?.analytics?.track(Events.ShareInitiated, {
      content_id,
      referral_source: searchParams.get('es_source'),
    });
  }

  return (
    <Tooltip title={t('components:shareNow')}>
      <IconButton sx={buttonstyles} onClick={onClickButton} edge={position} size="large">
        <CustomSVG name="share" size={iconSize} />
      </IconButton>
    </Tooltip>
  );
};

export default ShareButton;
