import React, { CSSProperties, FC } from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import RedditIcon from '@mui/icons-material/Reddit';

import { CustomSVG } from './';
import { Network } from 'api';

type IconState = 'active' | 'disabled' | 'inverted';

interface Props {
  provider:
    | Network
    | 'facebook'
    | 'instagram'
    | 'linkedin'
    | 'linkedin_page'
    | 'reddit'
    | 'twitter'
    | 'youtube'
    | 'tiktok'
    | 'wechat'
    | 'weibo';
  state?: IconState;
  size?: number;
  className?: string;
  sx?: CSSProperties;
}

const getWeiboIcon = (state: IconState) => {
  const activeColorMap = ['#FFF', '#D62B2A', '#E89214', '#E89214', '#040000'];
  const disabledColorMap = [
    'rgba(0, 0, 0, 0.26)',
    'rgba(0, 0, 0, 0.26)',
    'rgba(0, 0, 0, 0.26)',
    'rgba(0, 0, 0, 0.26)',
    'rgba(0, 0, 0, 0.26)',
  ];
  const invertedColorMap = ['#FFF', '#040000', '#E89214', '#E89214', '#040000'];

  let colorMap = activeColorMap;

  if (state === 'disabled') {
    colorMap = disabledColorMap;
  }

  if (state === 'inverted') {
    colorMap = invertedColorMap;
  }

  return (
    <g fill="currentColor">
      <path
        fill={colorMap[0]}
        d="M2.86,14c0,2.43,3.26,4.43,7.24,4.43s7.23-2,7.23-4.43S14.07,9.55,10.1,9.55s-7.24,2-7.24,4.45"
      />
      <path
        fill={colorMap[1]}
        d="M10.26,18c-3.52.35-6.61-1.21-6.85-3.51s2.45-4.41,6-4.74S16,11,16.26,13.3,13.81,17.71,10.26,18Zm7.09-7.55c-.31-.09-.52-.14-.33-.51A2.2,2.2,0,0,0,17,7.9c-.71-1-2.62-.93-4.78,0,0,0-.69.31-.52-.25a2.42,2.42,0,0,0-.24-2.47c-1.17-1.17-4.28,0-7,2.66-2,2-3.19,4.08-3.19,5.92,0,3.44,4.56,5.57,9,5.57,5.82,0,9.7-3.34,9.7-6A3.26,3.26,0,0,0,17.35,10.48Z"
      />
      <path
        fill={colorMap[2]}
        d="M21.23,4.12h0a5.79,5.79,0,0,0-5.4-1.7.76.76,0,0,0-.62.93.78.78,0,0,0,1,.61A4.17,4.17,0,0,1,20,5.17,4.05,4.05,0,0,1,20.88,9a.8.8,0,0,0,.16.8.86.86,0,0,0,.79.25.83.83,0,0,0,.62-.54A5.58,5.58,0,0,0,21.23,4.12Z"
      />
      <path
        fill={colorMap[3]}
        d="M19.07,6.06a2.84,2.84,0,0,0-2.65-.84.71.71,0,0,0-.51.48.67.67,0,0,0,.17.67.71.71,0,0,0,.68.2A1.36,1.36,0,0,1,18,7a1.32,1.32,0,0,1,.28,1.3.7.7,0,0,0,1.33.42,2.55,2.55,0,0,0-.57-2.66Z"
      />
      <path
        fill={colorMap[4]}
        d="M10.45,13.91a.5.5,0,0,1-.62.23.4.4,0,0,1-.2-.22.33.33,0,0,1,0-.29.52.52,0,0,1,.59-.23.32.32,0,0,1,.19.51ZM9.34,15.33a1.39,1.39,0,0,1-1.65.51.9.9,0,0,1-.35-1.37A1.34,1.34,0,0,1,8.93,14,.89.89,0,0,1,9.34,15.33Zm1.28-3.77a3.84,3.84,0,0,0-4.33,1.86A2.58,2.58,0,0,0,8,17.1a3.91,3.91,0,0,0,4.59-1.91A2.58,2.58,0,0,0,10.62,11.56Z"
      />
    </g>
  );
};

const SocialIcon: FC<Props> = ({ state, provider, size, sx }) => {
  const theme = useTheme();

  const materialIcons = ['instagram', 'linkedin', 'linkedin_page', 'reddit', 'twitter', 'youtube'];
  const customIcons = ['facebook', 'tiktok', 'wechat', 'weibo', 'slack', 'msteams'];
  const isMaterialIcon = materialIcons.includes(provider);
  const isCustomIcon = customIcons.includes(provider);

  function getMaterialIcon() {
    switch (provider) {
      case 'instagram':
        return InstagramIcon;
      case 'linkedin':
      case 'linkedin_page':
        return LinkedInIcon;
      case 'reddit':
        return RedditIcon;
      case 'twitter':
        return XIcon;
      case 'youtube':
        return YouTubeIcon;
      default:
        throw new Error('SocialIcon: MaterialIcon provider not found');
    }
  }

  function getCustomIcon() {
    switch (provider) {
      case 'facebook':
      case 'tiktok':
      case 'wechat':
      case 'msteams':
      case 'slack':
        return (
          <CustomSVG
            name={provider}
            size={size}
            sx={{
              verticalAlign: 'middle',
              ...(state === 'active' && { color: theme.custom.networks[provider] }),
              ...(state === 'disabled' && { color: theme.palette.action.disabled }),
              ...(state === 'inverted' && { color: theme.palette.common.white }),
              ...sx,
            }}
          />
        );
      case 'weibo':
        return (
          <SvgIcon
            fontSize="inherit"
            sx={{
              verticalAlign: 'middle',
              fontSize: theme.typography.pxToRem(size),
              ...(state === 'active' && { color: theme.custom.networks[provider] }),
              ...(state === 'disabled' && { color: theme.palette.action.disabled }),
              ...(state === 'inverted' && { color: theme.palette.common.white }),
              ...sx,
            }}
          >
            {getWeiboIcon(state)}
          </SvgIcon>
        );
      default:
        throw new Error('SocialIcon: CustomIcon provider not found');
    }
  }

  if (isMaterialIcon) {
    const Icon = getMaterialIcon();

    return (
      <Icon
        fontSize="inherit"
        sx={{
          verticalAlign: 'middle',
          fontSize: theme.typography.pxToRem(size),
          ...(state === 'active' && { color: theme.custom.networks[provider] }),
          ...(state === 'disabled' && { color: theme.palette.action.disabled }),
          ...(state === 'inverted' && { color: theme.palette.common.white }),
          ...sx,
        }}
      />
    );
  }

  if (isCustomIcon) {
    return getCustomIcon();
  }

  return null;
};

SocialIcon.defaultProps = {
  state: 'active',
  size: 28,
  className: '',
};

export default SocialIcon;
