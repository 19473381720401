import { InfiniteData, UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  GetFeedQuery,
  GetFeedQueryVariables,
  UpdateContentApprovalsMutation,
  UpdateContentApprovalsMutationVariables,
  useGetFeedQuery,
} from 'api';
import { useNotify, useQueryVariables } from 'hooks';

type Mutation = UpdateContentApprovalsMutation;
type MutationVariables = UpdateContentApprovalsMutationVariables;
type QueryData = InfiniteData<GetFeedQuery>;

const useRemoveContent = (groupId: string) => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const queryVariables = useQueryVariables<GetFeedQueryVariables>();
  const queryKey = useGetFeedQuery.getKey({ ...queryVariables, id: groupId });

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async ({ content_id }) => {
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.pages.map(page => {
            page.getFeed.results = page.getFeed.results.filter(
              result => result.content_id !== content_id
            );
          });
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },

    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }

      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useRemoveContent;
