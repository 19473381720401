import { UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import { useNotify } from 'hooks';
import {
  DeleteGroupWebhookMutation,
  DeleteGroupWebhookMutationVariables,
  GetCombinedGroupWebhookListQuery,
  useGetCombinedGroupWebhookListQuery,
} from 'api';

type DeleteMutation = DeleteGroupWebhookMutation;
type DeleteMutationVariables = DeleteGroupWebhookMutationVariables;
type QueryData = GetCombinedGroupWebhookListQuery;

const useDeleteGroupWebhook = () => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<DeleteMutation, unknown, DeleteMutationVariables> = {
    onMutate: async ({ group_id, webhook_id }) => {
      const queryKey = useGetCombinedGroupWebhookListQuery.getKey({ id: group_id });
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const { webhooks } = draftState.getGroupWebhookList;
          const index = webhooks.findIndex(webhook => webhook.webhook_id === webhook_id);

          if (index !== -1) {
            const groupIndex = webhooks[index].groups.findIndex(id => id === group_id);

            if (groupIndex !== -1) {
              draftState.getGroupWebhookList.webhooks[index]?.groups?.splice(groupIndex, 1);
            }
          }
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },

    onError: (error, { group_id }, baseState: QueryData) => {
      const queryKey = useGetCombinedGroupWebhookListQuery.getKey({ id: group_id });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }

      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useDeleteGroupWebhook;
