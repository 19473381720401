import React, { FC, ReactNode } from 'react';

import { InternalOverviewStat, useInternalOverviewStatsQuery } from 'api';
import { LoaderTypes } from 'types/app';
import { QueryManager } from 'components';
import { useProtectedClient } from 'hooks';

interface IProps {
  loader: LoaderTypes;
  children: (data: InternalOverviewStat) => ReactNode;
  endDate: string;
  startDate: string;
}

const InternalOverviewStats: FC<IProps> = ({ children, loader, startDate, endDate }) => {
  const client = useProtectedClient();
  const queryResult = useInternalOverviewStatsQuery({
    client_id: client.id,
    start_date: startDate,
    end_date: endDate,
  });

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.internalOverviewStats) {
          return null;
        }
        return children(data.internalOverviewStats);
      }}
    </QueryManager>
  );
};

export default InternalOverviewStats;
