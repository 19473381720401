import { GetMentionsQuery, useGetMentionsQuery } from 'api';
import { useGetMentionsPublicQuery } from 'api/public';
import { LOCAL_STORAGE_KEY_ACCESS_KEY } from 'app-constants';
import { useCallback } from 'react';
import { useNotify } from 'hooks';

interface MentionsArgs {
  mentionState: {
    results: GetMentionsQuery['getMention']['items'];
    target: any;
    search: string;
    index: number;
  };
  setMentionState: (state: object) => void;
  token_id: string;
  publicQuery: boolean;
}

export const useMentionService = (config: MentionsArgs) => {
  const { publicQuery, token_id, mentionState, setMentionState } = config;
  const notify = useNotify();
  const getService = useCallback(
    (publicQuery: boolean) => {
      if (publicQuery) {
        return useGetMentionsPublicQuery;
      }

      return useGetMentionsQuery;
    },
    [publicQuery]
  );

  const getVariables = (publicQuery: boolean) => {
    return publicQuery
      ? {
          access_key: localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_KEY),
          token_id,
          query: mentionState.search,
        }
      : { token_id, query: mentionState.search };
  };

  const { isLoading } = getService(publicQuery)(getVariables(publicQuery), {
    onError: notify.queryError,
    onSuccess: data => setMentionState({ ...mentionState, results: data?.getMention?.items || [] }),
    enabled: mentionState.search?.length > 2 && mentionState.search?.length < 50 && !!token_id,
  });

  return {
    isLoading,
  };
};
