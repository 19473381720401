import React, { ChangeEvent, FC, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Field, FieldProps } from 'formik';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { get } from 'lodash';

interface IProps {
  name: string;
  disabled?: boolean;
  fullWidth?: boolean;
  labelClasses?: Record<string, unknown>;
  label?: ReactNode;
  i18nKey?: string;
  checkedFn?: (value: unknown) => boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: boolean) => void;
  color?: 'default' | 'primary' | 'secondary';
}

interface IFormValue {
  [name: string]: string;
}

const ValidatedCheckbox: FC<IProps> = ({
  name,
  fullWidth,
  labelClasses,
  i18nKey,
  label,
  checkedFn,
  onChange,
  color,
  disabled,
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<IFormValue>) => {
        const { errors, touched } = form;
        const hasError = !!(get(errors, name) && get(touched, name));
        const nameError = get(touched, name) && get(errors, name);
        const checked = checkedFn(field.value);

        return (
          <FormControl
            variant="standard"
            disabled={disabled}
            required
            error={hasError}
            fullWidth={fullWidth}
          >
            <FormControlLabel
              label={label || <Trans i18nKey={i18nKey} />}
              classes={labelClasses}
              control={
                <Checkbox
                  name={name}
                  checked={checked}
                  onChange={(event, value) => {
                    onChange ? onChange(event, value) : field.onChange(event);
                  }}
                  color={color}
                />
              }
            />
            {hasError && <FormHelperText>{nameError}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};

ValidatedCheckbox.defaultProps = {
  disabled: false,
  fullWidth: false,
  labelClasses: {},
  checkedFn: (value: boolean) => !!value,
};

export default ValidatedCheckbox;
