import React, { FC } from 'react';
import { Box, Unstable_Grid2 as Grid, TextField, Typography } from '@mui/material';
import { ClientInput } from 'clientSuccess/types';

import { USER_POOL_ID } from 'settings';

interface IProps {
  client: ClientInput;
}

const ServiceProviderMetadata: FC<IProps> = ({ client }) => {
  const validUntil = new Date();
  validUntil.setFullYear(validUntil.getFullYear() + 1);
  const entityId = `urn:amazon:cognito:sp:${USER_POOL_ID}`;
  const acsUrl = 'https://auth.everyonesocial-prod.com/saml2/idpresponse';
  const ssoUrl = `https://${client.subdomain.split('.').slice(0, 2).join('.')}-sso.app/saml/sso`;
  const xmlMetadata = `<?xml version="1.0"?>
  <md:EntityDescriptor xmlns:md="urn:oasis:names:tc:SAML:2.0:metadata"
                       validUntil="${validUntil.toISOString()}"
                       cacheDuration="PT604800S"
                       entityID="${entityId}">
      <md:SPSSODescriptor AuthnRequestsSigned="false" WantAssertionsSigned="true" protocolSupportEnumeration="urn:oasis:names:tc:SAML:2.0:protocol">
          <md:NameIDFormat>urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified</md:NameIDFormat>
          <md:AssertionConsumerService Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST"
                                       Location="${acsUrl}"
                                       index="1" />

      </md:SPSSODescriptor>
      <md:Organization>
         <md:OrganizationName xml:lang="en-US">EveryoneSocial</md:OrganizationName>
         <md:OrganizationDisplayName xml:lang="en-US">EveryoneSocial</md:OrganizationDisplayName>
         <md:OrganizationURL xml:lang="en-US">https://everyonesocial.com</md:OrganizationURL>
      </md:Organization>
  </md:EntityDescriptor>`;

  return (
    <Grid container direction="column">
      <Grid>
        <Box pb={2}>
          <Typography variant="body2">Identifier / Entity ID:</Typography>
          <Typography>{entityId}</Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="body2">
            Single sign on URL / SAML Consumer URL / Sign on URL:
          </Typography>
          <Typography>{ssoUrl}</Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="body2">Reply URL / Assertion Consumer Service URL:</Typography>
          <Typography>{acsUrl}</Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="body2">
            App Start URL (most IDPs do not support this and use the ACS URL):
          </Typography>
          <Typography>https://{client.subdomain}</Typography>
        </Box>
        <Box pb={2}>
          <TextField
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            multiline
            label="XML Metadata"
            value={xmlMetadata}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ServiceProviderMetadata;
