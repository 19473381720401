import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

interface IProps {
  i18nKey?: string;
  color?: 'primary' | 'secondary' | 'inherit';
  children?: ReactNode;
}

const Note: FC<IProps> = ({ i18nKey, color, children }) => {
  const { t } = useTranslation();

  return (
    <Box marginTop={1}>
      <Typography display="inline" color={color} variant="caption">
        <em>{t('common:note')}:</em>
      </Typography>{' '}
      <Typography display="inline" variant="overline" color="textSecondary">
        <em>{children ? children : t(i18nKey)}</em>
      </Typography>
    </Box>
  );
};

Note.defaultProps = {
  color: 'primary',
};

export default Note;
