import React, { FC } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { Button } from '@mui/material';

interface Props extends ButtonProps {
  rounded?: boolean;
}

const styles = {
  button: {
    width: 100,
    backgroundColor: 'grey.100',
    '&:hover': {
      backgroundColor: 'grey.200',
    },
  },
  rounded: {
    borderRadius: 4,
  },
} as const;

const Secondary: FC<Props> = ({ children, rounded, ...rest }) => {
  return (
    <Button sx={{ ...styles.button, ...(rounded ? styles.rounded : {}) }} {...rest}>
      {children}
    </Button>
  );
};

export default Secondary;
