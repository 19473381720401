import useInfiniteScroll from 'react-infinite-scroll-hook';
import { UseInfiniteQueryOptions } from 'react-query';

import {
  SharesItemFragment,
  GetSharesItemsQuery,
  GetSharesItemsQueryVariables,
  useGetSharesItemsQuery,
} from 'api';
import { InfiniteQuery } from 'types/app';
import { useInfiniteQuery } from 'app-react-query/hooks';
import { useNotify } from 'hooks';
import { PAGINATION_ROOT_MARGIN } from 'app-constants';

type Query = GetSharesItemsQuery;
type Variables = GetSharesItemsQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = InfiniteQuery<SharesItemFragment>;

const useSharesItems = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();

  const sanitizeShareResults = (results: SharesItemFragment[]): SharesItemFragment[] => {
    for (const result of results) {
      if (result.network.includes('|')) {
        result.network = result.network.split('|')[0];
      }
    }
    return results;
  };

  const queryResult = useInfiniteQuery<Query, Variables>(
    useGetSharesItemsQuery,
    ({ pageParam }: { pageParam: Variables['data'] }) => {
      return { ...variables, data: { ...variables.data, ...pageParam } };
    },
    {
      initialData: {
        pages: [{ getSharesItems: { count: 0, items: [], paginate: false } }],
        pageParams: [],
      },
      onError: notify.queryError,
      getNextPageParam: lastPage => {
        const { getSharesItems } = lastPage;
        const { created_at, id, items } = getSharesItems;

        const endOfList = !created_at;
        const noResults = !items.length;

        if (noResults || endOfList) {
          return false;
        }

        return { created_at, id };
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = queryResult;
  const results = sanitizeShareResults(data.pages.map(page => page.getSharesItems.items).flat());
  const { count } = data.pages[0].getSharesItems;

  const [sentinelRef] = useInfiniteScroll({
    hasNextPage,
    loading: isFetchingNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: PAGINATION_ROOT_MARGIN,
  });

  return {
    sentinelRef,
    results,
    count,
    isEmpty: !results.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !results.length && isFetching,
  };
};

export default useSharesItems;
