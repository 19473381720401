import React, { ChangeEvent, DragEvent, FC, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

type UploadEvent = ChangeEvent<HTMLInputElement> & DragEvent;

interface IProps {
  onUpload: (file: File, result?: string | ArrayBuffer) => void;
  accept?: string;
}

const Input: FC<IProps> = ({ onUpload, children, accept }) => {
  const input = useRef<HTMLInputElement>(null);
  const id = uuidv4();

  function getFile(event: UploadEvent) {
    let files;

    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }

    return files[0];
  }

  function triggerAdditionalUploads(event: UploadEvent) {
    event.preventDefault();
    input.current?.click();
  }

  function handleUpload(event: UploadEvent) {
    event.preventDefault();

    const file = getFile(event);
    const reader = new FileReader();

    if (!file) {
      return;
    }

    const isCSV = file.name.split('.')[1] === 'csv';
    reader.onload = () => onUpload(file, reader.result);

    if (file.type.startsWith('video')) {
      onUpload(file);
    }

    if (file.type.startsWith('image') || isCSV) {
      reader.readAsDataURL(file);
    }

    if (input.current) {
      input.current.value = null;
    }
  }

  return (
    <label htmlFor={id} style={{ cursor: 'pointer' }}>
      <input type="file" accept={accept} id={id} ref={input} onChange={handleUpload} hidden />
      <div onClick={triggerAdditionalUploads}>{children}</div>
    </label>
  );
};

Input.defaultProps = {
  accept: 'image/*',
};

export default Input;
