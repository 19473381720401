import React, { FC, Fragment } from 'react';
import { CardContent, CardHeader, Typography } from '@mui/material';

import { UserAvatar } from 'components';
import { getFullName } from 'helpers';
import { useContent } from 'hooks';

import { hasCreator } from '../helpers';
import { Body } from '../widgets';

interface Props {
  isDeletedUser: boolean;
}

const UserPublic: FC<Props> = ({ isDeletedUser }) => {
  const content = useContent();
  const { user, content_object } = content;
  const { body } = content_object;
  const hasPostCreator = hasCreator(content);

  function renderName() {
    if (isDeletedUser) {
      return <Typography variant="subtitle2">{getFullName(user)}</Typography>;
    }

    return (
      <Typography variant="subtitle2" noWrap>
        {getFullName(user)}
      </Typography>
    );
  }

  if (!hasPostCreator) {
    if (body) {
      return (
        <CardContent>
          <Body />
        </CardContent>
      );
    }

    return null;
  }

  return (
    <Fragment>
      <CardHeader
        avatar={<UserAvatar size={40} user={!isDeletedUser ? user : { id: '' }} />}
        title={renderName()}
      />
      {body && (
        <CardContent>
          <Body />
        </CardContent>
      )}
    </Fragment>
  );
};

export default UserPublic;
