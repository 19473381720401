import React, { FC, useEffect } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import Label from '@mui/icons-material/Label';

import { usePostContext } from '../../PostContext';
import { Switch } from '../';
import Widget from './WidgetContainer';
import { ContentTaggingPicker } from 'components/Pickers';
import { useFormikContext } from 'formik';
import { ContentInput } from 'api';

const ContentTagging: FC = () => {
  const { state, dispatch } = usePostContext();
  const { values } = useFormikContext<ContentInput>();
  const { content_tags } = values;

  useEffect(() => {
    if (content_tags?.length) {
      dispatch({ type: 'contentTagging' });
    }
  }, []);

  function onChangeSwitch() {
    dispatch({ type: 'contentTagging' });
  }

  function renderBody() {
    return (
      <Grid container marginTop={2}>
        <Grid xs={1} />
        <Grid xs={11} container>
          <Grid xs={12}>
            <ContentTaggingPicker />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Widget
      titleKey="posting:contentTagging"
      subtitleKey="posting:guidelineContentTagging"
      icon={<Label color={state.contentTagging ? 'inherit' : 'disabled'} />}
      toggle={
        <Switch
          checked={state.contentTagging}
          onChange={onChangeSwitch}
          color="primary"
          data-testid="post-radio-contentTagging"
        />
      }
    >
      {state.contentTagging && renderBody()}
    </Widget>
  );
};

export default ContentTagging;
