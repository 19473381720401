import React, { FC, Fragment } from 'react';
import { Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment';

import { ClientProtectedFragment } from 'api';
import { ClientLogo } from 'components';

import { updateClient } from '../../Links';
import Loader from './Loader';

interface IProps {
  results: ClientProtectedFragment[];
  isExhausted: boolean;
  isInitialLoad: boolean;
  sentinelRef: (node: Element) => void;
}

const ClientTable: FC<IProps> = ({ results, isExhausted, isInitialLoad, sentinelRef }) => {
  function renderHeadCell(text: string) {
    return (
      <TableCell>
        <Typography title={text} noWrap variant="body2">
          {text}
        </Typography>
      </TableCell>
    );
  }

  function renderClientRow(client: ClientProtectedFragment) {
    return (
      <TableRow key={client.id}>
        <TableCell>
          <Link component={updateClient(client.id)} underline="hover">
            <ClientLogo client={client} height={24} disableMargin />
          </Link>
        </TableCell>
        <TableCell>
          <Link component={updateClient(client.id)} underline="hover">
            {client.title}
          </Link>
        </TableCell>
        <TableCell>
          <Link target="_blank" href={`https://${client.subdomain}`} underline="hover">
            {client.subdomain}
          </Link>
        </TableCell>
        <TableCell>{moment.unix(client.created_at).format('l')}</TableCell>
      </TableRow>
    );
  }

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {renderHeadCell('Logo')}
            {renderHeadCell('Name')}
            {renderHeadCell('Domain')}
            {renderHeadCell('Created On')}
          </TableRow>
        </TableHead>
        <TableBody>{results.map(renderClientRow)}</TableBody>
      </Table>
      {isInitialLoad && <Loader rows={8} />}
      {!isExhausted && <Loader sentinelRef={sentinelRef} rows={1} />}
    </Fragment>
  );
};

export default ClientTable;
