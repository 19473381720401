import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import IframeResizer from 'iframe-resizer-react';

import { oEmbedResponseRich } from 'types/oembed';
import { FACEBOOK_APP_ID, FACEBOOK_CLIENT_ID } from 'settings';
import { queryString } from 'utilities';

import { INSTAGRAM_OEMBED_ENDPOINT } from '../constants';
import EmbedPlaceholder from './EmbedPlaceholder';
import EmbedWrapper from './EmbedWrapper';

// @ts-ignore
import contentWindow from 'iframe-resizer/js/iframeResizer.contentWindow.min.js?raw';
import { Box } from '@mui/material';

const styles = {
  embed: {
    display: 'block',
    width: '100%',
    border: 0,
  },
} as const;

interface Props {
  url: string;
}

const EmbedInstagram: FC<Props> = ({ url }) => {
  const ref = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [html, setHtml] = useState('');

  useEffect(() => {
    void loadEmbed();
  }, []);

  async function loadEmbed() {
    try {
      const params = queryString({
        url,
        access_token: `${FACEBOOK_APP_ID}|${FACEBOOK_CLIENT_ID}`,
      });

      const fetchResponse = await fetch(`${INSTAGRAM_OEMBED_ENDPOINT}?${params}`);
      const embedResponse: oEmbedResponseRich = await fetchResponse.json();

      const script = `<script>${contentWindow}</script>`;
      const { html } = embedResponse;

      setHtml(`${script}${html}`);
      setIsLoaded(true);
    } catch (error) {
      throw new Error(error);
    }
  }

  return (
    <Fragment>
      {!isLoaded && <EmbedPlaceholder />}
      <EmbedWrapper visible={isLoaded}>
        <Box sx={{ display: 'block', width: '100%', border: 0 }}>
          <IframeResizer
            sizeWidth
            style={styles.embed}
            checkOrigin={false}
            forwardRef={ref}
            srcDoc={html}
            bodyMargin={0}
            bodyPadding={0}
            minHeight={400}
          />
        </Box>
      </EmbedWrapper>
    </Fragment>
  );
};

export default EmbedInstagram;
