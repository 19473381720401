import React, { FC } from 'react';
import { RenderPlaceholderProps } from 'slate-react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import theme from 'theme';

import SlateEditor from 'components/SlateEditor';

const styles = {
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
} as const;

interface Props {
  required?: boolean;
  disabled?: boolean;
}

const BodyTextField: FC<Props> = ({ disabled, required }) => {
  const { t } = useTranslation();

  function handleRenderPlaceholder({ attributes, children }: RenderPlaceholderProps) {
    const increasedFontSize = {
      ...attributes,
      style: {
        ...attributes.style,
        fontSize: theme.typography.pxToRem(17),
      },
    };
    if (!required) {
      return <span {...increasedFontSize}>{children}</span>;
    }

    const requiredAttributes = {
      ...increasedFontSize,
      style: {
        ...increasedFontSize.style,
        opacity: 1,
        color: theme.palette.primary.main,
      },
    };

    return <span {...requiredAttributes}>{children}</span>;
  }

  return (
    <Box marginBottom={2} sx={disabled && styles.disabled}>
      <SlateEditor
        fieldName="content_object.body"
        placeholder={t('posting:addTextHere')}
        blockListFieldName="content_object.body_blocklist"
        renderPlaceholder={handleRenderPlaceholder}
        disabled={disabled}
      />
    </Box>
  );
};

BodyTextField.defaultProps = {
  required: false,
};

export default BodyTextField;
