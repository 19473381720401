import React, { FC, memo, useEffect, useState } from 'react';
import { Avatar as MuiAvatar } from '@mui/material';

import { getImageSrc, getRandomProfileImage } from 'helpers';

interface IProps {
  name: string;
  image: string;
  size: number;
}

const SocialAvatar: FC<IProps> = memo(({ name, image, size }) => {
  const [defaultImageSrc, setDefaultImageSrc] = useState(getRandomProfileImage());
  const imageSrc = getImageSrc(image);
  const style = { height: size, width: size };

  useEffect(() => {
    setDefaultImageSrc(defaultImageSrc);
  }, [image]);

  return (
    <MuiAvatar alt={name} sx={{ ...style }} {...imageSrc}>
      <img alt={name} style={style} {...defaultImageSrc} />
    </MuiAvatar>
  );
});

export default SocialAvatar;
