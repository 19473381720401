import { UseInfiniteQueryOptions } from 'react-query';

import { InfiniteQuery } from 'types/app';
import { useNotify } from 'hooks';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {
  AssignmentFragment,
  GetAssignmentsQuery,
  GetAssignmentsQueryVariables,
  useGetAssignmentsQuery,
} from 'api';
import { useInfiniteQuery } from 'app-react-query/hooks';

import { PAGINATION_ROOT_MARGIN } from 'app-constants';

type Query = GetAssignmentsQuery;
type Variables = GetAssignmentsQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = InfiniteQuery<AssignmentFragment>;

const useGetAssignments = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();

  const queryResult = useInfiniteQuery<Query, Variables>(
    useGetAssignmentsQuery,
    ({ pageParam }: { pageParam: Variables }) => ({ ...variables, ...pageParam }),
    {
      initialData: {
        pages: [{ getAssignments: { total_count: 0, results: [] } }],
        pageParams: [],
      },
      onError: notify.queryError,
      getNextPageParam: lastPage => {
        const { getAssignments } = lastPage;
        const { results, next_id } = getAssignments;
        const endOfList = !next_id;
        const noResults = !results.length;

        if (noResults || endOfList) {
          return false;
        }

        return { next_id };
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = queryResult;
  const results = data?.pages?.map(page => page.getAssignments.results).flat() ?? [];
  const totalCount = data?.pages?.[0]?.getAssignments.total_count ?? 0;

  const [sentinelRef] = useInfiniteScroll({
    hasNextPage,
    loading: isFetchingNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: PAGINATION_ROOT_MARGIN,
  });

  return {
    sentinelRef,
    results,
    count: totalCount,
    isEmpty: !results.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !results.length && isFetching,
  };
};

export default useGetAssignments;
