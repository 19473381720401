import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Tabs as MuiTabs, Unstable_Grid2 as Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { LargeTab } from 'components';
import { useProtectedClient } from 'hooks';
import { CELL_HEIGHT } from 'features/activity/constants';

import SharesScheduled from './SharesScheduled';
import SharesPast from './SharesPast';
import PostsPast from './PostsPast';
import theme from 'theme';

const styles = {
  container: {
    minHeight: CELL_HEIGHT,
  },

  tabsContainer: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },

  tabsGridRoot: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
} as const;

interface Props {
  showInternal: boolean;
}

const Tabs: FC<Props> = ({ showInternal }) => {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location.state ? 1 : 0);
  const { t } = useTranslation();
  const { allow_sharing } = useProtectedClient();

  const scheduledShares = t('sharing:scheduledShares');
  const pastShares = t('sharing:pastShares');
  const myPosts = t('sharing:myPosts');

  function handleTabChange(event: ChangeEvent<HTMLDivElement>, value: number) {
    setTabValue(value);
  }

  if (!allow_sharing) {
    return (
      <Fragment>
        <MuiTabs value={tabValue} onChange={handleTabChange} sx={styles.tabsContainer}>
          <LargeTab label={myPosts} />
        </MuiTabs>
        <Grid
          container
          alignItems="center"
          spacing={0}
          justifyContent="center"
          sx={styles.container}
        >
          <Grid xs>{tabValue === 0 && <PostsPast />}</Grid>
        </Grid>
      </Fragment>
    );
  }
  return (
    <Paper>
      <Grid container alignItems="center" sx={styles.tabsGridRoot}>
        <Grid>
          <MuiTabs value={tabValue} onChange={handleTabChange}>
            <LargeTab label={scheduledShares} />
            <LargeTab label={pastShares} />
            {showInternal && <LargeTab label={myPosts} />}
          </MuiTabs>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center" sx={styles.container}>
        <Grid xs>
          {tabValue === 0 && <SharesScheduled />}
          {tabValue === 1 && <SharesPast />}
          {tabValue === 2 && <PostsPast />}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Tabs;
