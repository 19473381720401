import { useRef } from 'react';

export default () => {
  const ref = useRef<HTMLElement>();

  function scrollTo() {
    const refOffset = ref?.current?.offsetTop ?? 0;
    const headerEl = document.querySelector('header');
    const { offsetHeight } = headerEl;

    window.scrollTo(0, refOffset - offsetHeight);
  }

  return {
    ref,
    scrollTo,
  };
};
