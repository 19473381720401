import React, { FC, Fragment, useEffect, useState } from 'react';
import { Box, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ContentInput, TeamInfo } from 'api';
import { Loaders } from 'components';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

interface Props {
  selectedTeam: TeamInfo;
  setSelectedTeam: (team: TeamInfo) => void;
  options: TeamInfo[];
  isLoading: boolean;
  disabled: boolean;
}

const ChatTeamPicker: FC<Props> = ({
  disabled,
  options,
  isLoading,
  selectedTeam,
  setSelectedTeam,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<ContentInput>();
  const { ms_teams_channels } = values;

  const [open, setOpen] = useState(false);
  const [teamDisabled, setTeamDisabled] = useState(!isEmpty(ms_teams_channels) || false);

  useEffect(() => {
    if (options.length === 1) {
      setTeamDisabled(true);
      setSelectedTeam({ team_id: options[0].team_id, team_name: options[0].team_name });
    }
  }, [options]);

  function onChange(event: SelectChangeEvent<string>) {
    const { value } = event.target;
    const team = options.find(team => team.team_name === value);

    setSelectedTeam({ team_id: team.team_id, team_name: value });
  }

  function renderOptions(option: TeamInfo) {
    return (
      <MenuItem key={option.team_id} value={option.team_name}>
        {option.team_name}
      </MenuItem>
    );
  }

  return (
    <Fragment>
      <InputLabel shrink>{t('posting:team')}</InputLabel>
      {
        <Select
          variant="standard"
          fullWidth
          displayEmpty
          disabled={isLoading || disabled || teamDisabled}
          value={selectedTeam.team_name}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={onChange}
          IconComponent={() => {
            if (isLoading) {
              return (
                <Box mr={2}>
                  <Loaders.Standard height={8} />
                </Box>
              );
            }
            return options.length < 2 && <Fragment />;
          }}
          renderValue={(selected: string) => {
            if (!selected) {
              return <Typography variant="body2">{t('posting:selectTeam')}</Typography>;
            }
            return selected;
          }}
        >
          {!isLoading && options.map(renderOptions)}
        </Select>
      }
    </Fragment>
  );
};

export default ChatTeamPicker;
