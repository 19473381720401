import { Editor, Text, Transforms } from 'slate';
import isUrl from 'is-url';

import { wrapLink } from './helpers';
import { EMOJI_REGEX } from './constants';
import { Data as EmojiData, SearchIndex } from 'emoji-mart';

export function withLinks(editor: Editor) {
  const { insertData, isInline } = editor;

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
}

async function emojiSearch(value: string) {
  const emojis = await SearchIndex.search(value);
  return emojis.map((emoji: typeof EmojiData) => emoji);
}

export function withEmojis(editor: Editor) {
  const { normalizeNode } = editor;

  editor.normalizeNode = async entry => {
    const [node, path] = entry;

    if (!Text.isText(node)) {
      return normalizeNode([node, path]);
    }

    const emojiMatch = EMOJI_REGEX.exec(node.text);

    if (!emojiMatch) {
      return normalizeNode([node, path]);
    }

    const [searchMatch, colonMatch] = emojiMatch;
    const { index: startIndex } = emojiMatch;
    const endIndex = startIndex + searchMatch.length;

    const [matchedEmoji] = await emojiSearch(colonMatch);

    if (!matchedEmoji) {
      return normalizeNode([node, path]);
    }

    Transforms.insertText(editor, matchedEmoji.skins[0].native, {
      at: {
        anchor: { path, offset: startIndex },
        focus: { path, offset: endIndex },
      },
    });

    normalizeNode([node, path]);
  };

  return editor;
}
