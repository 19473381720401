import React, { FC } from 'react';
import { Box, TableCell as MuiTableCell } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';

const styles = {
  box: {
    display: 'table',
    tableLayout: 'fixed',
  },
  wrapper: {
    display: 'table-cell',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
} as const;

/**
 * Drop in replacement for material-ui TableCell which
 * handles cells that require overflow like long titles
 * Note: You still need to add noWrap to Typography component
 * @param props
 * @constructor
 */
const TableCellWithOverflow: FC<TableCellProps> = props => {
  return (
    <MuiTableCell {...props}>
      <Box sx={styles.box} width="100%">
        <Box sx={styles.wrapper}>{props.children}</Box>
      </Box>
    </MuiTableCell>
  );
};

export default TableCellWithOverflow;
