import { useQueryClient, UseMutationOptions, InfiniteData } from 'react-query';
import produce from 'immer';

import {
  useGetFollowedQuery,
  UnfollowMutation,
  UnfollowMutationVariables,
  GetFollowedQuery,
  FeedKey,
} from 'api';
import { useCurrentUser, useNotify } from 'hooks';
import { FOLLOW_QUERIES_LIMIT } from 'app-constants';

type Mutation = UnfollowMutation;
type MutationVariables = UnfollowMutationVariables;
type QueryData = InfiniteData<GetFollowedQuery>;

type FollowType = FeedKey.Group | FeedKey.User;

interface IOptions {
  type: FollowType;
}

const useUnfollow = ({ type }: IOptions) => {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const notify = useNotify();

  const queryKey = useGetFollowedQuery.getKey({
    data: { user_id: currentUser.id, limit: FOLLOW_QUERIES_LIMIT },
  });

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async mutationArgs => {
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.pages.map(page => {
            const { feed_follows } = page.getPaginatedUserFeedFollows;
            const index = feed_follows.findIndex(feedFollow => {
              const followId = feedFollow[type]?.id;
              const unfollowedId = mutationArgs.id;

              return followId === unfollowedId;
            });

            if (index !== -1) {
              feed_follows.splice(index, 1);
              page.getPaginatedUserFeedFollows.feed_follows = feed_follows;
            }
          });
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useUnfollow;
