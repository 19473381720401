import React, { FC } from 'react';
import { useField } from 'formik';
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';

import { SocialAccount } from 'api';
import { getSocialAccountName } from 'helpers';
import { SocialIcon } from 'components';

const styles = {
  secondaryAction: {
    right: -8,
  },
} as const;

interface Props {
  index: number;
}

const SmartSchedulerConnection: FC<Props> = ({ index }) => {
  const [field] = useField<SocialAccount>(`${index}`);
  const { value, onChange } = field;

  return (
    <ListItem disableGutters key={value.id}>
      <ListItemIcon>
        <SocialIcon provider={value.provider} />
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Typography noWrap variant="body2">
            {getSocialAccountName(value)}
          </Typography>
        }
      />
      <ListItemSecondaryAction sx={styles.secondaryAction}>
        <Switch
          name={`${index}.smart_schedule`}
          checked={value.smart_schedule}
          onChange={onChange}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default SmartSchedulerConnection;
