import React, { FC } from 'react';

import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { TextField } from '@mui/material';
import { TextCounter } from 'components';
import theme from 'theme';
import { useBlockList, useReleases } from 'hooks';
import { findWordMatch } from 'features/sharing/helpers';

interface Props {
  required?: boolean;
}

const styles = {
  required: {
    input: {
      color: theme.palette.primary.main,
      '&::placeholder': {
        opacity: 1,
      },
    },
  },
  textStyle: {
    marginBottom: 2,
  },
  errorText: {
    marginLeft: 0,
  },
};

const TitleTextField: FC<Props> = ({ required }) => {
  const { t } = useTranslation();
  const blockListFieldName = 'content_object.title_blocklist';
  const fieldName = 'content_object.title';
  const [field] = useField(fieldName);
  const { setFieldValue } = useFormikContext();

  const releases = useReleases();
  const { terms } = useBlockList();

  const hasBlocklist = releases.includes('blocklist');

  const [, meta, helpers] = useField(fieldName);

  const hasError = !!meta.touched && !!meta.error;
  const errorText = hasError ? { helperText: meta.error } : {};

  function handleBlur() {
    setFieldValue(fieldName, field.value);
    if (hasBlocklist) {
      const matchedWords = findWordMatch(terms, field.value);
      setFieldValue(blockListFieldName, matchedWords);
    }
  }

  return (
    <TextField
      fullWidth
      value={field.value}
      variant="outlined"
      placeholder={t('common:title')}
      inputProps={{ name: field.name }}
      onChange={value => {
        field.onChange(value);
      }}
      onFocus={() => {
        helpers.setTouched(true);
      }}
      onBlur={handleBlur}
      error={!!meta.error}
      helperText={meta.error}
      sx={{
        ...styles.textStyle,
        ...(required && !field.value && styles.required),
      }}
      FormHelperTextProps={{ sx: styles.errorText }}
      InputProps={{
        endAdornment: <TextCounter length={field.value.length} limit={250} />,
      }}
      {...errorText}
    />
  );
};

TitleTextField.defaultProps = {
  required: false,
};

export default TitleTextField;
