import React, { FC, Fragment, useEffect } from 'react';
import { Routes, Route, Outlet, Navigate, useMatch, useNavigate } from 'react-router-dom';
import { CoralogixRum } from '@coralogix/browser';

import { NotificationDeliveryType } from 'api';
import { ErrorPage, LocationBlock } from 'components';
import { MonitorPostProvider } from 'providers';
import { useCognitoUser, useCurrentUser, useProtectedClient, useSocialConnections } from 'hooks';
import { NavBar, AccountMarker } from 'features/header';
import { AuthError } from 'features/auth';
import { SmartSchedulerDialog } from 'features/notifications';
import cookieStorage, { NEXT_PATH_COOKIE } from 'cookie-storage';
import moment from 'moment';
import * as paths from 'paths';

import ClientSuccess from 'clientSuccess';
import * as csPaths from '../../clientSuccess/paths';
import * as Containers from '../../clientSuccess/containers';

import { onboardingRoutes } from './Onboarding';
import { mainRoutes } from './Main';
import Logout from './Logout';
import ConnectLoader from '../ConnectLoader';
import ConnectNetwork from './ConnectNetwork';
import * as LayoutComponents from './Components';
import * as PublicLayoutComponents from '../public/Components';

const RoutesComponent: FC = () => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const auth = useCognitoUser();
  const client = useProtectedClient();

  const { twitterAccounts, linkedinAccounts } = useSocialConnections();
  const isImpersonatingUser = auth.id !== currentUser.id;

  const onboardingSteps = {
    null: paths.onboardingCreate,
    0: paths.onboardingCreate, // server default - no steps completed
    1: paths.onboardingIdentity,
    2: paths.onboardingSocials,
  };

  const { last_onboarding_step, onboarding_completed, location } = currentUser;
  const nextOnboardingStep = onboardingSteps[last_onboarding_step];
  const isConnecting = window.location.pathname.startsWith('/connect');
  const isOauth = useMatch(paths.oauthAuth);
  const isViewingPrivacyPolicy = useMatch(paths.socialMediaPolicy);
  const isContent = useMatch(paths.content);

  useEffect(() => {
    const nextPath = cookieStorage.nextPath;
    cookieStorage.remove(NEXT_PATH_COOKIE);

    if (isViewingPrivacyPolicy) {
      navigate(paths.socialMediaPolicy);
      return;
    }

    if (location?.block_message) {
      navigate(paths.locationBlock);
      return;
    }

    if (!onboarding_completed && !isConnecting && !isOauth) {
      navigate(nextOnboardingStep);
      return;
    }

    if (nextPath) {
      navigate(nextPath);
    }
  }, []);

  useEffect(() => {
    setupSegment();
    setupCoralogix();
  }, [currentUser, twitterAccounts.length, linkedinAccounts.length]);

  function setupCoralogix() {
    CoralogixRum.setUserContext({
      user_id: currentUser.id,
      user_name: currentUser.full_name,
      user_email: currentUser.email_address,
      user_metadata: {
        client_id: client.id,
        client_title: client.title,
      },
    });
  }

  function setupSegment() {
    window.analytics?.identify(currentUser.id, {
      name: currentUser.full_name,
      email: currentUser.email_address,
      client_id: currentUser.client_id,
      user_created_at: moment.unix(currentUser.created_at).toISOString(),
      department_id: currentUser.department_id,
      email_notifications_enabled: currentUser.notification_delivery_types.includes(
        NotificationDeliveryType.Email
      ),
      has_image: !!currentUser.image,
      language: currentUser.lang,
      last_accessed_at: moment.unix(currentUser.last_accessed_at).toISOString(),
      last_onboarding_step: currentUser.last_onboarding_step,
      linkedin_connected: !!linkedinAccounts.length,
      location_id: currentUser.location_id,
      onboarding_completed: currentUser.onboarding_completed,
      push_notifications_enabled: currentUser.notification_delivery_types.includes(
        NotificationDeliveryType.Push
      ),
      reach: currentUser.reach,
      title: currentUser.job_title,
      twitter_connected: !!twitterAccounts.length,
      user_status: currentUser.user_status,
      user_type: currentUser.user_type,
    });

    window.analytics?.group(currentUser.client_id, {
      client_created_at: moment.unix(client.created_at).toISOString(),
      client_status: client.client_status,
      client_title: client.title,
      feature_plan_type: client.feature_plan_type,
      is_self_serve: client.is_self_serve,
      subdomain: client.subdomain,
    });
  }

  return (
    <Routes>
      <Route path={paths.authError} element={<AuthError />} />
      <Route path={paths.login} element={<Navigate to={paths.index} replace />} />
      <Route path={paths.sso} element={<Navigate to={paths.index} replace />} />
      <Route path={paths.passwordLogin} element={<Navigate to={paths.index} replace />} />
      <Route path={paths.passwordlessLogin} element={<Navigate to={paths.index} replace />} />
      <Route path={paths.invitation} element={<Navigate to={paths.index} replace />} />
      <Route path={paths.signUp} element={<Navigate to={paths.index} replace />} />
      <Route path={paths.confirmSignup} element={<Navigate to={paths.index} replace />} />
      <Route path={paths.logout} element={<Logout />} />
      <Route path={paths.connectLoader} element={<ConnectLoader />} />
      <Route path={paths.connectNetwork + '/*'} element={<ConnectNetwork />} />
      <Route path={paths.oauthAuth} element={<LayoutComponents.OauthIntegrationLayout />} />
      <Route
        path={paths.socialMediaPolicy + '/*'}
        element={<PublicLayoutComponents.SocialMediaPolicyLayout />}
      />
      <Route
        path={paths.setupWorkspaceGroups}
        element={<LayoutComponents.SetupWorkspaceGroupsLayout />}
      />
      <Route
        path={paths.setupWorkspaceInvites}
        element={<LayoutComponents.SetupWorkspaceInvitesLayout />}
      />
      <Route path={paths.locationBlock} element={<LocationBlock />} />
      <Route path={paths.partnerShare} element={<LayoutComponents.PartnerShareProtectedLayout />} />
      <Route
        path={paths.partnerActivity}
        element={<LayoutComponents.PartnerActivityProtectedLayout />}
      />
      <Route path={paths.clientSuccess} element={<ClientSuccess />}>
        <Route index element={<Navigate to={csPaths.subpathListClients} replace />} />
        <Route path={csPaths.subpathListClients} element={<Containers.ClientList />} />
        <Route path={csPaths.subpathCreateClient} element={<Containers.CreateClient />} />
        <Route path={csPaths.subpathUpdateClient} element={<Containers.UpdateClient />} />
        <Route path={csPaths.subpathUserList} element={<Containers.UserList />} />
        <Route path={csPaths.subpathFeatureToggles} element={<Containers.FeatureToggles />}>
          <Route index element={<Navigate to={csPaths.subpathCapabilityToggles} replace />} />
          <Route path={csPaths.subpathCapabilityToggles} element={<Outlet />}>
            <Route index element={<Containers.CapabilityToggles />} />
            <Route
              path={csPaths.subpathCapabilityDetail}
              element={<Containers.CapabilityDetail />}
            />
          </Route>
          <Route path={csPaths.subpathReleaseToggles} element={<Outlet />}>
            <Route index element={<Containers.ReleaseToggles />} />
            <Route
              path={csPaths.subpathReleaseDetail}
              element={<Containers.ReleaseToggleDetails />}
            />
          </Route>
        </Route>
        <Route
          path={csPaths.subpathBilling}
          element={<Containers.ClientBillingReportAggregate />}
        />
      </Route>
      <Route path={paths.onboarding} element={<Outlet />}>
        {onboardingRoutes.map(({ path, component: Component }) => {
          const subpath = path.replace(paths.onboarding + '/', '');
          return <Route key={path} path={subpath} element={<Component />} />;
        })}
      </Route>
      <Route
        path={paths.index}
        element={
          <Fragment>
            {!isContent && <NavBar />}
            <MonitorPostProvider>
              {!isImpersonatingUser && <SmartSchedulerDialog />}
              <Outlet />
            </MonitorPostProvider>
            <AccountMarker />
          </Fragment>
        }
      >
        {mainRoutes
          .flatMap(({ layoutPaths, component: Component }) =>
            layoutPaths.map(path => {
              const subpath = path.replace(paths.index, '');
              return <Route key={path} path={subpath} element={<Component />} />;
            })
          )
          .concat([<Route key="*" path="*" element={<ErrorPage />} />])}
      </Route>
      <Route path={paths.expressActivation} element={<Navigate to={paths.index} replace />} />
    </Routes>
  );
};

export default RoutesComponent;
