import { Node, Text } from 'slate';
import { jsx } from 'slate-hyperscript';
import escapeHtml from 'escape-html';

import { SlateValue } from './types';

const ELEMENT_TAGS = {
  P: () => ({ type: 'paragraph' }),
  UL: () => ({ type: 'bulleted-list' }),
  OL: () => ({ type: 'numbered-list' }),
  LI: () => ({ type: 'list-item' }),
  H1: () => ({ type: 'title' }),
  H2: () => ({ type: 'subtitle' }),
  BLOCKQUOTE: () => ({ type: 'block-quote' }),
  A: (el: Element) => ({ type: 'link', url: el.getAttribute('href') }),
};

const TEXT_TAGS = {
  B: () => ({ bold: true }),
  STRONG: () => ({ bold: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  U: () => ({ underline: true }),
};

export const serialize = (node: SlateValue) => {
  if (Text.isText(node)) {
    let text = escapeHtml(node.text);

    if (node.bold) {
      text = `<strong>${text}</strong>`;
    }

    if (node.italic) {
      text = `<em>${text}</em>`;
    }

    if (node.underline) {
      text = `<u>${text}</u>`;
    }

    return text;
  }

  const children: string = node.children.map((n: SlateValue) => serialize(n)).join('');

  switch (node.type) {
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'numbered-list':
      return `<ol>${children}</ol>`;
    case 'list-item':
      return `<li>${children}</li>`;
    case 'title':
      return `<h1>${children}</h1>`;
    case 'subtitle':
      return `<h2>${children}</h2>`;
    case 'block-quote':
      return `<blockquote>${children}</blockquote>`;
    case 'link':
      return `<a target="_blank" href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
};

export const serializeToPlainText = (nodes: SlateValue[]) => {
  return nodes.map(n => Node.string(n)).join('\n');
};

export const deserialize = (el: Element) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === 'BR') {
    return '\n';
  }

  const { childNodes, nodeName } = el;
  const children: (string | SlateValue)[] = Array.from(childNodes).map(deserialize).flat();

  if (nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if (!children.length) {
    children.push({
      text: '',
    });
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map(child => jsx('text', attrs, child));
  }

  return children;
};
