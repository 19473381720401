import React, { FC } from 'react';
import { Box, SvgIconProps } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

import { UserFeedFollowGroupFragment } from 'api';

interface Props {
  group: UserFeedFollowGroupFragment;
  type: 'index' | 'title' | 'list';
  size?: SvgIconProps['fontSize'];
}

const styles = {
  margin: {
    marginRight: 1,
  },
  align: {
    verticalAlign: 'middle',
  },
  colorBlock: {
    height: 16,
    width: 16,
    borderRadius: '50%',
  },
} as const;

const PrivateGroupIcon: FC<Props> = ({ group, size, type }) => {
  const backgroundColor = group.color_map?.hex;

  switch (type) {
    case 'index': {
      if (group.is_private) {
        return <LockIcon sx={styles.align} fontSize={size} style={{ color: backgroundColor }} />;
      }
      return <Box sx={styles.colorBlock} style={{ backgroundColor }} />;
    }
    case 'title': {
      if (group.is_private) {
        return <LockIcon sx={styles.align} style={{ color: backgroundColor }} />;
      }
      return <Box sx={styles.colorBlock} style={{ backgroundColor }} />;
    }
    case 'list': {
      return group.is_private ? <LockIcon sx={styles.align} fontSize={size} /> : null;
    }
  }

  return null;
};

PrivateGroupIcon.defaultProps = {
  size: 'inherit',
};

export default PrivateGroupIcon;
