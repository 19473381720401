import React, { FC, useMemo, useState } from 'react';
import { createEditor, Editor } from 'slate';
import { RenderPlaceholderProps, withReact } from 'slate-react';
import { Box, ClickAwayListener, FormControl, FormHelperText } from '@mui/material';
import { useField } from 'formik';

import { MentionsInstance } from 'components';
import { Network } from 'api';
import theme from 'theme';

interface Props {
  fieldName: string;
  mentionsFieldName: string;
  blockListFieldName: string;
  placeholder: string;
  renderPlaceholder?: (props: RenderPlaceholderProps) => JSX.Element;
  disabled?: boolean;
  editor?: Editor;
  publicQuery: boolean;
  showTextCounter?: boolean;
  disableMentions?: boolean;
  network?: Network;
}

const styles = {
  editor: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '4px',

    [`& span[data-slate-placeholder]`]: {
      color: theme.palette.grey[400],
    },
  },
  error: {
    border: `2px solid ${theme.palette.error.main}`,
    '&:after': {
      borderRadius: 4,
    },
  },
  focused: {
    '&:after': {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      content: '""',
      position: 'absolute',
      transform: 'scaleX(1)',
      pointerEvents: 'none',
    },
  },
} as const;

const MentionsEditor: FC<Props> = props => {
  const {
    fieldName,
    placeholder,
    renderPlaceholder,
    disabled,
    mentionsFieldName,
    blockListFieldName,
    publicQuery,
    showTextCounter,
    disableMentions,
    network,
  } = props;
  const [, meta] = useField(fieldName);
  const [focused, setFocused] = useState(false);
  const hasError = !!meta.error && meta.touched;
  const integratedEditor = useMemo(() => withMentions(withReact(createEditor())), []);
  const editorClasses = {
    ...styles.editor,
    ...(hasError && styles.error),
    ...(!hasError && focused && styles.focused),
  };
  function handleClickAway() {
    setFocused(false);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl
        variant="standard"
        data-test-id={fieldName}
        error={hasError}
        fullWidth
        focused={focused}
      >
        <Box sx={editorClasses}>
          <MentionsInstance
            editor={integratedEditor}
            fieldName={fieldName}
            mentionsFieldName={mentionsFieldName}
            blockListFieldName={blockListFieldName}
            placeholder={placeholder}
            renderPlaceholder={renderPlaceholder}
            onFocus={() => setFocused(true)}
            disabled={disabled}
            publicQuery={publicQuery}
            showTextCounter={showTextCounter}
            disableMentions={disableMentions}
            network={network}
          />
        </Box>
        {hasError && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    </ClickAwayListener>
  );
};

export default MentionsEditor;

export function withMentions(editor: Editor) {
  const { isInline, isVoid } = editor;

  editor.isInline = element => {
    //@ts-ignore
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = element => {
    //@ts-ignore
    return element.type === 'mention' ? true : isVoid(element);
  };

  return editor;
}
