import React, { FC, Fragment, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import isHTML from 'is-html';

import { Account, ClientSuccess, generateLink, Logout } from 'Links';
import { Loaders, UserAvatar } from 'components';
import { useCognitoUser, useCurrentUser, useNotify, useProtectedClient } from 'hooks';
import { isClientSuccessUser } from 'helpers';
import { getRootDomain } from 'utilities';
import * as paths from 'paths';
import { useCreateLoginTokenForCurrentUserInClientMutation } from 'api';

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'grey[100]',
    paddingRight: 0,
  },
} as const;

const User: FC = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const client = useProtectedClient();

  const currentUser = useCurrentUser();
  const isClientSuccess = isClientSuccessUser(currentUser);
  const auth = useCognitoUser();
  const isImpersonatingUser = auth.id !== currentUser.id;

  const [anchorEl, setAnchorEl] = useState(null);
  const [launchWindow, setLaunchWindow] = useState<Window>(null);

  const { mutate: createLoginTokenMutate, isLoading: createLoginTokenIsLoading } =
    useCreateLoginTokenForCurrentUserInClientMutation({
      onError: notify.mutationError,
      onSuccess: ({ createLoginTokenForCurrentUserInClient }) => {
        launchWindow.location.href = `https://${getRootDomain()}/?token=${createLoginTokenForCurrentUserInClient}`;
        setLaunchWindow(null);
      },
    });

  useEffect(() => {
    if (launchWindow) {
      createLoginTokenMutate({ client_id: currentUser.client_id });
    }
  }, [launchWindow]);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleHelp() {
    window.open(paths.help, '_blank');
    handleClose();
  }

  function handleMenuItemClick() {
    const link = client.menu_item.link;

    if (!isHTML(link)) {
      window.open(link, '_blank');
      handleClose();
      return;
    }

    const document = new DOMParser().parseFromString(link, 'text/html');
    const text = document.documentElement.textContent;
    window.open(text, '_blank');
    handleClose();
  }

  return (
    <Fragment>
      <Button disableRipple component="button" onClick={handleClick} sx={styles.wrapper}>
        <UserAvatar size={40} user={currentUser} />
        <KeyboardArrowDownRoundedIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        sx={{ boxShadow: '0 2px 23px 0 rgba(162,162,162,0.12)' }}
      >
        <MenuItem
          component={generateLink(`/profile/${currentUser.id}`)}
          onClick={() => handleClose()}
        >
          {t('user:myProfile')}
        </MenuItem>
        <MenuItem component={Account} onClick={handleClose}>
          {t('user:accountSettings')}
        </MenuItem>
        {client?.menu_item_enabled && client?.menu_item?.title && client?.menu_item?.link && (
          <MenuItem onClick={handleMenuItemClick}>{client?.menu_item.title}</MenuItem>
        )}
        <MenuItem onClick={handleHelp}>{t('common:help')}</MenuItem>
        {isClientSuccess && (
          <MenuItem component={ClientSuccess} onClick={handleClose}>
            {t('components:clientSuccess')}
          </MenuItem>
        )}
        {!isImpersonatingUser && (
          <MenuItem onClick={() => setLaunchWindow(window.open())}>
            {createLoginTokenIsLoading ? (
              <Loaders.Standard height={26} width={60} />
            ) : (
              t('common:workspace_plural')
            )}
          </MenuItem>
        )}
        <MenuItem component={Logout}>{t('common:logout')}</MenuItem>
      </Menu>
    </Fragment>
  );
};

export default User;
