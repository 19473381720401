import React, { FC, ReactNode } from 'react';

import {
  DailyGroupStat,
  DailyPostStat,
  DailyShareStat,
  DailyUserStat,
  ReportingFilter,
  useDailyGroupStatsQuery,
  useDailyPostStatsQuery,
  useDailyShareStatsQuery,
  useDailyUserStatsQuery,
} from 'api';
import { LoaderTypes } from 'types/app';
import { useProtectedClient } from 'hooks';
import { QueryManager } from 'components';

type ReturnTypes = DailyGroupStat & DailyPostStat & DailyShareStat & DailyUserStat;

interface IProps {
  loader: LoaderTypes;
  type: 'dailyGroupStats' | 'dailyPostStats' | 'dailyShareStats' | 'dailyUserStats';
  endDate: string;
  startDate: string;
  filter?: ReportingFilter;
  children: (props: ReturnTypes) => ReactNode;
}

const AnalyticsDailyStats: FC<IProps> = props => {
  const { children, loader, filter, startDate, endDate, type } = props;

  const client = useProtectedClient();
  const query = getQuery();
  const variables = getVariables();

  const queryResult = query(variables);

  function getVariables() {
    return {
      client_id: client.id,
      start_date: startDate,
      end_date: endDate,
      filter,
    };
  }

  function getQuery() {
    switch (type) {
      case 'dailyGroupStats':
        return useDailyGroupStatsQuery;
      case 'dailyPostStats':
        return useDailyPostStatsQuery;
      case 'dailyShareStats':
        return useDailyShareStatsQuery;
      case 'dailyUserStats':
        return useDailyUserStatsQuery;
    }
  }

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.[type]) {
          return null;
        }
        return children(data?.[type] ?? { summary: {}, data: [] });
      }}
    </QueryManager>
  );
};

export default AnalyticsDailyStats;
