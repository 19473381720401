import React, { FC } from 'react';

import { ContentApproval, GroupIdApprovalInput } from 'api';
import { useContent } from 'hooks';

import GroupApprovals from './GroupApprovals';

const DeletePost: FC = () => {
  const { group_id_approvals } = useContent();

  const groupIdApprovals: GroupIdApprovalInput[] = group_id_approvals.map(({ group_id }) => ({
    group_id,
    approval: ContentApproval.Deleted,
  }));

  return (
    <GroupApprovals
      i18nKeys={{
        alertMessage: 'content:deletePostMessage',
        alertConfirm: 'common:delete',
        menuItem: 'content:postDelete',
        notify: 'content:postDeleted',
      }}
      groupIdApprovals={groupIdApprovals}
    />
  );
};

export default DeletePost;
