import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { IconButton, Paper } from '@mui/material';
import { noop } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

import { ContentInput } from 'api';
import { useProtectedClient } from 'hooks';
import { hasPhoto, hasVideo } from 'features/content/helpers';

import { usePostContext } from '../../PostContext';
import VideoPreview from './VideoPreview';
import MediaUpload from './MediaUpload';
import PhotoPreview from '../Partials/PhotoPreview';
import theme from 'theme';

const styles = {
  paperDefault: {
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  paper: {
    minHeight: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  removeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  },
} as const;

const UploadSection: FC = () => {
  const client = useProtectedClient();
  const { values, setFieldValue } = useFormikContext<ContentInput>();
  const { dispatch } = usePostContext();

  const hasContent = hasVideo(values) || hasPhoto(values);

  function onClickRemove() {
    setFieldValue('content_object.media', null);
    setFieldValue('content_object.photos', []);

    dispatch({ type: 'clearPhotoIndexes' });

    if (!client.public_post_page) {
      setFieldValue('is_shareable', false);
      dispatch({ type: 'share', value: false });
    }
  }

  function renderContent() {
    if (hasVideo(values)) {
      return <VideoPreview />;
    }

    if (hasPhoto(values)) {
      return <PhotoPreview />;
    }

    return <MediaUpload />;
  }

  return (
    <Paper variant="outlined" sx={{ ...styles.paperDefault, ...(!hasContent && styles.paper) }}>
      {renderContent()}
      {hasContent && (
        <IconButton onClick={onClickRemove} size="large" sx={styles.removeButton}>
          <CloseIcon />
        </IconButton>
      )}
    </Paper>
  );
};

UploadSection.defaultProps = {
  onImageUpload: noop,
};

export default UploadSection;
