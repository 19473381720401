import { number, object } from 'yup';
import i18n from 'config/i18n';

const numberROIRequired = i18n.t('analytics:roi_currency_input_required');
const numberROIFormatting = i18n.t('analytics:roi_currency_input_formatting');
const CURRENCY_REGEX = /^([1-9]\d*|0)(\.\d{1,2})?$/;

const ROICurrencyInputSchema = () => {
  return object({
    reach_twitter: number().noWhitespace().typeError(numberROIRequired),
    reach_linkedin: number().noWhitespace().typeError(numberROIRequired),
    clicks_twitter: number().noWhitespace().typeError(numberROIRequired),
    clicks_linkedin: number().noWhitespace().typeError(numberROIRequired),
    clicks_facebook: number().noWhitespace().typeError(numberROIRequired),
    engagements_twitter: number().noWhitespace().typeError(numberROIRequired),
    engagements_linkedin: number().noWhitespace().typeError(numberROIRequired),
    engagements_facebook: number().noWhitespace().typeError(numberROIRequired),
    impressions_linkedin: number().noWhitespace().typeError(numberROIRequired),
    impressions_twitter: number().noWhitespace().typeError(numberROIRequired),
  }).test('currency-formatting', numberROIFormatting, (values, testContext) => {
    const invalidFields = Object.keys(values).filter(field => {
      if (!CURRENCY_REGEX.test(values[field] + '')) {
        return field;
      }
      return false;
    });
    if (invalidFields?.length) {
      return testContext.createError({
        path: invalidFields[0],
        message: numberROIFormatting,
      });
    }

    return true;
  });
};

export { ROICurrencyInputSchema };
