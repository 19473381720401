import React, { FC } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';

import { LoaderComponents, PaddedPaper } from 'components';

const Widget: FC = () => {
  const { Block, Content, Image, List, Row } = LoaderComponents;

  return (
    <PaddedPaper>
      <Grid container direction="column" wrap="nowrap">
        <Grid container alignItems="center">
          <Grid>
            <Image shape="circular" />
          </Grid>
          <Grid xs marginLeft={2}>
            <Block width="50%" />
          </Grid>
        </Grid>

        <Grid>
          <List rows={4}>
            {rowIndex => (
              <Row key={rowIndex} spacing={2} padding="4px 0">
                <Grid>
                  <Image shape="rounded" />
                </Grid>
                <Grid>
                  <Content index={rowIndex} height={16} maxWidth={200} />
                </Grid>
              </Row>
            )}
          </List>
        </Grid>
      </Grid>
    </PaddedPaper>
  );
};

export default Widget;
