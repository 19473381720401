import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import {
  IconButton,
  Link,
  Slide,
  Toolbar,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import theme from 'theme';

import cookieStorage from 'cookie-storage';
import * as Links from 'Links';

const styles = {
  background: {
    width: '100%',
    backgroundColor: alpha(theme.palette.error.main, 0.1),
  },

  text: {
    [theme.breakpoints.down('xl')]: {
      paddingRight: 5,
    },
  },

  closeIcon: {
    position: 'absolute',
    right: 15,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

interface Props {
  onDismissed: () => void;
  cookieValue: string;
}

const TrialEndedBanner: FC<Props> = ({ cookieValue, onDismissed }) => {
  function handleCancel() {
    cookieStorage.trialDismissed = cookieValue;
    onDismissed();
  }

  return (
    <Slide in mountOnEnter unmountOnExit>
      <Toolbar sx={styles.background} variant="dense">
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Typography sx={styles.text} noWrap color="error" variant="subtitle2">
            <Trans i18nKey="components:trialBannerEnded">
              Your trial of the Teams plan has ended. &nbsp;
              <Link color="error" underline="always" component={Links.AdminUpgrade}>
                Upgrade
              </Link>{' '}
              to access your historical data.
            </Trans>
          </Typography>
          <IconButton onClick={handleCancel} sx={styles.closeIcon} size="large">
            <CloseIcon color="error" />
          </IconButton>
        </Grid>
      </Toolbar>
    </Slide>
  );
};

export default TrialEndedBanner;
