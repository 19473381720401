import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { ErrorPage } from 'components';
import { isPathMatch } from 'utilities';
import * as paths from 'paths';
import * as csPaths from 'clientSuccess/paths';
import AuthError from 'features/auth/containers/AuthError';
import cookieStorage from 'cookie-storage';

import * as LayoutComponents from './Components';
import PublicLogout from './PublicLogout';
import PublicConnect from './PublicConnect';
import ConnectLoader from '../ConnectLoader';

const RoutesComponent: FC = () => {
  const isProtectedRoute =
    isPathMatch(paths.PROTECTED_ROUTES) || isPathMatch(csPaths.PROTECTED_ROUTES);
  const RedirectComponent = isProtectedRoute ? LayoutComponents.AuthLayout : ErrorPage;

  if (isProtectedRoute) {
    cookieStorage.nextPath = `${window.location.pathname}${window.location.search}`;
  }

  return (
    <Routes>
      <Route path={paths.authError} element={<AuthError />} />
      <Route path={paths.logout} element={<PublicLogout />} />
      <Route path={paths.index} element={<LayoutComponents.AuthLayout />} />
      <Route path={paths.invitation} element={<LayoutComponents.AuthLayout />} />
      <Route path={paths.login} element={<LayoutComponents.AuthLayout />} />
      <Route path={paths.passwordLogin} element={<LayoutComponents.AuthLayout />} />
      <Route path={paths.passwordlessLogin} element={<LayoutComponents.AuthLayout />} />
      <Route path={paths.signUp} element={<LayoutComponents.AuthLayout />} />
      <Route path={paths.forgotPassword} element={<LayoutComponents.AuthLayout />} />
      <Route path={paths.forgotPasswordSubmit} element={<LayoutComponents.AuthLayout />} />
      <Route path={paths.confirmSignup} element={<LayoutComponents.AuthLayout />} />
      <Route path={paths.sso} element={<LayoutComponents.SSOLayout />} />
      <Route path={paths.unsubscribe} element={<LayoutComponents.UnsubscribeLayout />} />
      <Route
        path={paths.socialMediaPolicy}
        element={<LayoutComponents.SocialMediaPolicyLayout />}
      />
      <Route path={paths.content} element={<LayoutComponents.PublicPermalinkLayout />} />
      <Route path={paths.contentLike} element={<LayoutComponents.PublicPermalinkLayout />} />
      <Route path={paths.contentShare} element={<LayoutComponents.PublicPermalinkLayout />} />
      <Route path={paths.publicConnect} element={<PublicConnect />} />
      <Route path={paths.connectionSuccess} element={<LayoutComponents.ConnectionsSuccess />} />
      <Route path={paths.connectionFailure} element={<LayoutComponents.ConnectionsFailure />} />
      <Route path={paths.connectLoader} element={<ConnectLoader />} />
      <Route path={paths.partnerShare} element={<LayoutComponents.PartnerSharePublicLayout />} />
      <Route
        path={paths.partnerActivity}
        element={<LayoutComponents.PartnerActivityPublicLayout />}
      />
      <Route
        path={paths.expressActivation}
        element={<LayoutComponents.ExpressActivationPublicLayout />}
      />
      <Route path="*" element={<RedirectComponent />} />
    </Routes>
  );
};

export default RoutesComponent;
