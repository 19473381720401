import React, { FC, Fragment } from 'react';
import { Divider, Unstable_Grid2 as Grid } from '@mui/material';

import { SharesItemFragment } from 'api';
import { ContentProvider } from 'providers';
import { EmptyState, Loaders, PostImage } from 'components';
import { useNotify, useSharesItems } from 'hooks';
import { ShareButton } from 'features/content/widgets';

import { IMAGE_HEIGHT } from '../constants';
import { Account, Date, Post, ShareCopy, SharePerformance } from './Partials';
import ListLoader from './ListLoader';

const SharesPast: FC = () => {
  const notify = useNotify();

  const { results, isExhausted, isEmpty, isInitialLoad, sentinelRef } = useSharesItems(
    {
      scheduled: false,
      data: {
        limit: 10,
      },
    },
    {
      onError: notify.queryError,
    }
  );

  if (isInitialLoad) {
    return <ListLoader />;
  }

  if (isEmpty) {
    return <EmptyState name="past" />;
  }

  function renderActionRow(shareItem: SharesItemFragment) {
    return (
      <Grid
        xs={12}
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ minHeight: 48 }}
        paddingX={2}
      >
        <Grid>
          <Date time={shareItem.share_at} />
        </Grid>
        <Grid>
          <ContentProvider value={shareItem.content}>
            <ShareButton size="small" />
          </ContentProvider>
        </Grid>
      </Grid>
    );
  }

  function renderRow(shareItem: SharesItemFragment) {
    return (
      <Grid container key={shareItem.id}>
        {renderActionRow(shareItem)}
        <Grid xs={12} container paddingX={2}>
          <Grid paddingRight={2} paddingBottom={2}>
            <PostImage size={IMAGE_HEIGHT} content={shareItem.content} />
          </Grid>
          <Grid xs container>
            <Grid>
              <Post content={shareItem.content} />
            </Grid>
            <Grid xs={12} marginTop={2}>
              <Account shareItem={shareItem} />
            </Grid>
            <Grid xs={12} marginTop={2}>
              <ShareCopy item={shareItem} />
            </Grid>

            <Grid xs={12} marginTop={2}>
              <Divider />
            </Grid>

            <Grid xs={12} container marginTop={2} marginBottom={2}>
              <SharePerformance shareItem={shareItem} />
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Divider />
        </Grid>
      </Grid>
    );
  }

  return (
    <Fragment>
      {results.map(renderRow)}
      {!isExhausted && (
        <div ref={sentinelRef}>
          <Loaders.Standard />
        </div>
      )}
    </Fragment>
  );
};

export default SharesPast;
