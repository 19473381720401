import React, { FC } from 'react';

import { Skeleton, SkeletonProps } from '@mui/material';

interface Props {
  height?: number | string;
  width?: number | string;
  shape?: SkeletonProps['variant'];
}

const Image: FC<Props> = ({ shape, height, width }) => {
  return <Skeleton variant={shape} width={width} height={height} />;
};

Image.defaultProps = {
  height: 20,
  width: 20,
  shape: 'rectangular',
};

export default Image;
