import { InfiniteData, UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  GetContentQuery,
  GetContentQueryVariables,
  GetFeedQuery,
  GetFeedQueryVariables,
  ShareContentMutation,
  ShareContentMutationVariables,
  ShareType,
  useGetContentQuery,
  useGetFeedQuery,
} from 'api';
import { useCurrentUser, useNotify, useQueryVariables } from 'hooks';

type Mutation = ShareContentMutation;
type MutationVariables = ShareContentMutationVariables;
type QueryData = GetContentQuery;
type FeedQueryData = InfiniteData<GetFeedQuery>;

const useShareContent = () => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();

  const queryVariables = useQueryVariables<GetContentQueryVariables>();
  const queryKey = useGetContentQuery.getKey(queryVariables);

  const feedQueryVariables = useQueryVariables<GetFeedQueryVariables>();
  const feedQueryKey = useGetFeedQuery.getKey(feedQueryVariables);

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: ({ items, content_id }) => {
      const isShareNow = items[0].share_type === ShareType.Now;

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const { getContent } = draftState;

          getContent.has_requesting_user_shared = isShareNow;
          getContent.share_count++;
          const alreadyShared = !!getContent.shared_by.filter(item => item.id === currentUser.id);

          if (!alreadyShared) {
            getContent.shared_by = [
              ...getContent.shared_by,
              { id: currentUser.id, full_name: currentUser.full_name, image: currentUser.image },
            ];
          }
        });

      const baseFeedState = queryClient.getQueryData<FeedQueryData>(feedQueryKey);
      const nextFeedState =
        baseFeedState &&
        produce(baseFeedState, draftState => {
          draftState.pages.forEach(page => {
            const { getFeed } = page;
            const { results } = getFeed;

            const index = results.findIndex(result => result.content_id === content_id);
            const indexedContent = getFeed.results[index];

            if (indexedContent) {
              getFeed.results[index].has_requesting_user_shared = isShareNow;
              getFeed.results[index].share_count++;
              const alreadyShared = !!getFeed.results[index].shared_by.filter(
                item => item.id === currentUser.id
              );

              if (!alreadyShared) {
                getFeed.results[index].shared_by = [
                  ...getFeed.results[index].shared_by,
                  {
                    id: currentUser.id,
                    full_name: currentUser.full_name,
                    image: currentUser.image,
                  },
                ];
              }
            }
          });
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      if (baseFeedState) {
        queryClient.setQueryData<FeedQueryData>(feedQueryKey, nextFeedState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }

      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useShareContent;
