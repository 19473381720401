import React, { ComponentType, FC, ReactNode, useEffect, useRef, useState } from 'react';

export interface IDimensions {
  width: number;
  height: number;
}

interface IProps {
  children: (props: IDimensions) => ComponentType | ReactNode | JSX.Element;
}

const Dimensions: FC<IProps> = ({ children }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  });

  return <div ref={ref}>{children({ width, height })}</div>;
};

export default Dimensions;
