import React, { Fragment, useRef } from 'react';
import { Box, Card, CardContent, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { Dimensions, Video } from 'components';
import { DimensionsProvider } from 'providers';
import { useContent, useReleases, useSharePhoto } from 'hooks';
import { UserFeedFollowGroupFragment } from 'api';

import {
  getEmbed,
  getPhoto,
  getProviderDisplay,
  getVideo,
  hasVideo,
  isEmbed,
  isTextOnly,
  isUrl,
} from 'features/content/helpers';
import { ExternalLink, Image, Photo } from 'features/content/widgets';
import { ContentActions, User } from 'features/content/partials';
import SharingThumbnails from './Thumbnails';
import theme from 'theme';

const Preview = () => {
  const content = useContent();
  const releases = useReleases();
  const { selectedPhotoIndex } = useSharePhoto();
  const videoRef = useRef<HTMLVideoElement>(null);

  const hasVariations = releases.includes('shareVariations');
  const { content_object, groups } = content;
  const { title } = content_object;
  const selectedGroup = groups?.[0] || ({} as UserFeedFollowGroupFragment);
  const isDeletedUser = content?.user?.full_name === 'Deleted User';

  function renderCardHeader() {
    const providerDisplay = getProviderDisplay(content);

    return (
      <Fragment>
        <User selectedGroup={selectedGroup} isDeletedUser={isDeletedUser} />
        {!!providerDisplay && (
          <CardContent>
            <ExternalLink>
              <Typography color="textSecondary">{providerDisplay}</Typography>
            </ExternalLink>
          </CardContent>
        )}
      </Fragment>
    );
  }

  function renderEmbed() {
    return <Fragment>{getEmbed(content)}</Fragment>;
  }

  function renderVideo() {
    const video = getVideo(content);

    return <Video ref={videoRef} video={video} />;
  }

  function renderPhoto(disableViewer: boolean) {
    const photo = getPhoto(content, selectedPhotoIndex);

    return (
      <Dimensions>
        {dimensions => (
          <DimensionsProvider value={dimensions}>
            {photo ? (
              <Photo disableViewer={disableViewer} photo={photo} />
            ) : (
              <Image content={content} />
            )}
          </DimensionsProvider>
        )}
      </Dimensions>
    );
  }

  function renderUrl() {
    const { url_metadata } = content_object;

    return (
      <Card>
        {renderCardHeader()}
        {hasVideo(content) ? renderVideo() : <ExternalLink>{renderPhoto(true)}</ExternalLink>}
        <CardContent>
          <Box paddingY={1}>
            {hasVariations && (
              <Grid container spacing={1}>
                <SharingThumbnails images={content_object.photos} />
              </Grid>
            )}
            <Box marginTop={1}>
              <ExternalLink>
                <Typography variant="h4" sx={theme.mixins.breakWord}>
                  {url_metadata.title}
                </Typography>
                <Typography color="textSecondary">{url_metadata.description}</Typography>
              </ExternalLink>
            </Box>
          </Box>
        </CardContent>
        <ContentActions selectedGroup={selectedGroup} />
      </Card>
    );
  }

  function renderText() {
    return (
      <Card>
        {renderCardHeader()}
        {title && (
          <CardContent>
            <Box marginTop={1}>
              <Typography variant="h4" sx={theme.mixins.breakWord}>
                {title}
              </Typography>
            </Box>
          </CardContent>
        )}
        <ContentActions selectedGroup={selectedGroup} />
      </Card>
    );
  }

  function renderImage() {
    return (
      <Card>
        {renderCardHeader()}
        {renderPhoto(false)}
        {hasVariations && (
          <Box paddingX={2} paddingTop={1}>
            <Grid container spacing={1}>
              <SharingThumbnails images={content_object.photos} />
            </Grid>
          </Box>
        )}
        {title && (
          <CardContent>
            <Box marginTop={1}>
              <Typography variant="h4" sx={theme.mixins.breakWord}>
                {title}
              </Typography>
            </Box>
          </CardContent>
        )}
        <ContentActions selectedGroup={selectedGroup} />
      </Card>
    );
  }

  if (isEmbed(content)) {
    return renderEmbed();
  } else if (isTextOnly(content)) {
    return renderText();
  } else if (isUrl(content)) {
    return renderUrl();
  }

  return renderImage();
};

export default Preview;
