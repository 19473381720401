import React, { FC } from 'react';
import { Skeleton } from '@mui/material';

interface Props {
  height?: number;
  maxWidth?: number;
  index?: number;
}

const Content: FC<Props> = ({ index, height, maxWidth }) => {
  const firstWidth = maxWidth * 0.7;
  const secondWidth = maxWidth * 0.9;
  const thirdWidth = maxWidth;

  function getContentWidth() {
    const firstInSeries = index % 3 === 0;
    const secondInSeries = index % 3 === 1;
    const thirdInSeries = index % 3 === 2;

    if (firstInSeries) {
      return firstWidth;
    }

    if (secondInSeries) {
      return secondWidth;
    }

    if (thirdInSeries) {
      return thirdWidth;
    }

    return secondWidth;
  }

  return <Skeleton width={getContentWidth()} height={height} animation="wave" />;
};

Content.defaultProps = {
  height: 12,
  maxWidth: 300,
  index: null,
};

export default Content;
