import React, { FC, lazy, Suspense } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { ErrorPage, Loaders } from 'components';

const ErrorBoundary: FC = ({ children }) => (
  <ReactErrorBoundary fallback={<ErrorPage type="general" />}>{children}</ReactErrorBoundary>
);

const accountImport = import(/* webpackPrefetch: true */ 'features/account/containers/Edit');
const activityImport = import(/* webpackPrefetch: true */ 'features/activity/containers/Activity');
const adminImport = import(/* webpackPrefetch: true */ 'features/admin/Controller');
const connectionsSuccess = import(/* webpackPrefetch: true */ 'features/connections/Success');
const connectionsFailure = import(/* webpackPrefetch: true */ 'features/connections/Failure');
const contentImport = import(
  /* webpackPrefetch: true */ 'features/content/containers/ContentLoader'
);
const contentShareImport = import(
  /* webpackPrefetch: true */ 'features/sharing/containers/ContentShare'
);
const editGroupImport = import(
  /* webpackPrefetch: true */ 'features/groups/containers/EditGroupLoader'
);
const groupsIndexImport = import(
  /* webpackPrefetch: true */ 'features/groups/containers/GroupsIndex'
);
const viewGroupImport = import(/* webpackPrefetch: true */ 'features/groups/containers/View');
const leaderboardImport = import(
  /* webpackPrefetch: true */ 'features/leaderboard/containers/LeaderboardIndex'
);
const createGroupImport = import(/* webpackPrefetch: true */ 'features/groups/containers/Create');
const notificationsImport = import(
  /* webpackPrefetch: true */ 'features/notifications/containers/Inbox'
);
const teamImport = import(/* webpackPrefetch: true */ 'features/team/containers/TeamIndex');
const profileImport = import(/* webpackPrefetch: true */ 'features/team/containers/Profile');
const searchImport = import(/* webpackPrefetch: true */ 'features/search/containers/Search');
const timelineImport = import(/* webpackPrefetch: true */ 'features/timeline/TimelineIndex');
const organicInsightsImport = import(/* webpackPrefetch: true */ 'features/insights/Controller');
const onboardingCreateImport = import(
  /* webpackPrefetch: true */ 'features/onboarding/containers/Create'
);
const onboardingIdentityImport = import(
  /* webpackPrefetch: true */ 'features/onboarding/containers/Identity'
);
const onboardingSocialsImport = import(
  /* webpackPrefetch: true */ 'features/onboarding/containers/Socials'
);
const postImport = import(/* webpackPrefetch: true */ 'features/posting/Controller');
const externalShareImport = import(
  /* webpackPrefetch: true */ 'features/sharing/containers/ExternalShare'
);
const setupProfileImport = import(/* webpackPrefetch: true */ 'features/setup/containers/Profile');
const setupWorkspaceImport = import(
  /* webpackPrefetch: true */ 'features/setup/containers/Workspace'
);
const setupWorkspaceGroupsImport = import(
  /* webpackPrefetch: true */ 'features/setup/containers/WorkspaceGroups'
);
const setupWorkspaceInvitesImport = import(
  /* webpackPrefetch: true */ 'features/setup/containers/WorkspaceInvites'
);
const networkImport = import(/* webpackPrefetch: true */ 'features/network/NetworkIndex');
const oAuthIntegrationServerImport = import('features/auth/containers/OAuthIntegrationServer');
const partnerShareProtectedImport = import(
  'features/sharing/containers/PartnerShare/ProtectedLoader'
);
const partnerActivityProtectedImport = import(
  'features/activity/containers/ActivityPartnerProtected'
);

const LazyAccountLayout = lazy(() => accountImport);
const LazyActivityLayout = lazy(() => activityImport);
const LazyAdminLayout = lazy(() => adminImport);
const LazyConnectionsSuccessLayout = lazy(() => connectionsSuccess);
const LazyConnectionsFailureLayout = lazy(() => connectionsFailure);
const LazyContentLayout = lazy(() => contentImport);
const LazyContentShareLayout = lazy(() => contentShareImport);
const LazyEditGroupLayout = lazy(() => editGroupImport);
const LazyGroupsIndexLayout = lazy(() => groupsIndexImport);
const LazyViewGroupLayout = lazy(() => viewGroupImport);
const LazyLeaderboardLayout = lazy(() => leaderboardImport);
const LazyCreateGroupLayout = lazy(() => createGroupImport);
const LazyNotificationsLayout = lazy(() => notificationsImport);
const LazyTeamLayout = lazy(() => teamImport);
const LazyProfileLayout = lazy(() => profileImport);
const LazySearchLayout = lazy(() => searchImport);
const LazyTimelineLayout = lazy(() => timelineImport);
const LazyOrganicInsightsImport = lazy(() => organicInsightsImport);
const LazyOnboardingCreateLayout = lazy(() => onboardingCreateImport);
const LazyOnboardingIdentityLayout = lazy(() => onboardingIdentityImport);
const LazyOnboardingSocialsLayout = lazy(() => onboardingSocialsImport);
const LazyPostLayout = lazy(() => postImport);
const LazyExternalShareLayout = lazy(() => externalShareImport);
const LazySetupProfileLayout = lazy(() => setupProfileImport);
const LazySetupWorkspaceLayout = lazy(() => setupWorkspaceImport);
const LazySetupWorkspaceGroupsLayout = lazy(() => setupWorkspaceGroupsImport);
const LazySetupWorkspaceInvitesLayout = lazy(() => setupWorkspaceInvitesImport);
const LazyNetworkLayout = lazy(() => networkImport);
const LazyOAuthIntegrationServerImport = lazy(() => oAuthIntegrationServerImport);
const LazyPartnerShareProtectedLayout = lazy(() => partnerShareProtectedImport);
const LazyPartnerActivityProtectedLayout = lazy(() => partnerActivityProtectedImport);

export const AccountLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyAccountLayout />
    </Suspense>
  </ErrorBoundary>
);
export const ActivityLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyActivityLayout />
    </Suspense>
  </ErrorBoundary>
);
export const AdminLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyAdminLayout />
    </Suspense>
  </ErrorBoundary>
);
export const ConnectionsSuccess = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyConnectionsSuccessLayout />
    </Suspense>
  </ErrorBoundary>
);
export const ConnectionsFailure = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyConnectionsFailureLayout />
    </Suspense>
  </ErrorBoundary>
);
export const ContentLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyContentLayout />
    </Suspense>
  </ErrorBoundary>
);
export const ContentShareLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyContentShareLayout />
    </Suspense>
  </ErrorBoundary>
);
export const TimelineLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyTimelineLayout />
    </Suspense>
  </ErrorBoundary>
);
export const OrganicInsightsLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyOrganicInsightsImport />
    </Suspense>
  </ErrorBoundary>
);
export const OnboardingCreateLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyOnboardingCreateLayout />
    </Suspense>
  </ErrorBoundary>
);
export const OnboardingIdentityLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyOnboardingIdentityLayout />
    </Suspense>
  </ErrorBoundary>
);
export const OnboardingSocialsLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyOnboardingSocialsLayout />
    </Suspense>
  </ErrorBoundary>
);
export const GroupsIndexLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyGroupsIndexLayout />
    </Suspense>
  </ErrorBoundary>
);
export const GroupViewLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyViewGroupLayout />
    </Suspense>
  </ErrorBoundary>
);
export const LeaderboardLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyLeaderboardLayout />
    </Suspense>
  </ErrorBoundary>
);
export const CreateGroupLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <ErrorBoundary>
        <LazyCreateGroupLayout />
      </ErrorBoundary>
    </Suspense>
  </ErrorBoundary>
);
export const EditGroupLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyEditGroupLayout />
    </Suspense>
  </ErrorBoundary>
);
export const NotificationsLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyNotificationsLayout />
    </Suspense>
  </ErrorBoundary>
);
export const TeamLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyTeamLayout />
    </Suspense>
  </ErrorBoundary>
);
export const ProfileLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyProfileLayout />
    </Suspense>
  </ErrorBoundary>
);
export const SearchLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazySearchLayout />
    </Suspense>
  </ErrorBoundary>
);
export const PostLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyPostLayout />
    </Suspense>
  </ErrorBoundary>
);
export const ExternalShareLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyExternalShareLayout />
    </Suspense>
  </ErrorBoundary>
);
export const SetupProfileLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazySetupProfileLayout />
    </Suspense>
  </ErrorBoundary>
);
export const SetupWorkspaceLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazySetupWorkspaceLayout />
    </Suspense>
  </ErrorBoundary>
);
export const SetupWorkspaceGroupsLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazySetupWorkspaceGroupsLayout />
    </Suspense>
  </ErrorBoundary>
);
export const SetupWorkspaceInvitesLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazySetupWorkspaceInvitesLayout />
    </Suspense>
  </ErrorBoundary>
);
export const NetworkLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyNetworkLayout />
    </Suspense>
  </ErrorBoundary>
);
export const OauthIntegrationLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyOAuthIntegrationServerImport />
    </Suspense>
  </ErrorBoundary>
);
export const PartnerShareProtectedLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyPartnerShareProtectedLayout />
    </Suspense>
  </ErrorBoundary>
);
export const PartnerActivityProtectedLayout = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loaders.Startup />}>
      <LazyPartnerActivityProtectedLayout />
    </Suspense>
  </ErrorBoundary>
);
