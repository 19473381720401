import React, { FC, ReactNode } from 'react';

import { LoaderTypes } from 'types/app';
import { ClientLocationList, useGetClientLocationsByStatusQuery } from 'api';
import { QueryManager } from 'components';

interface IProps {
  loader?: LoaderTypes;
  children: (data: ClientLocationList) => ReactNode;
}

const GetClientLocations: FC<IProps> = ({ children, loader }) => {
  const queryResult = useGetClientLocationsByStatusQuery();

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.getClientLocationsByStatus) {
          return children({ client_locations: [], count: 0, paginate: false });
        }

        return children(data.getClientLocationsByStatus);
      }}
    </QueryManager>
  );
};

export default GetClientLocations;
