import React, { FC, Fragment, useState } from 'react';

import { YOUTUBE_EMBED_ENDPOINT } from '../constants';

import EmbedPlaceholder from './EmbedPlaceholder';
import EmbedWrapper from './EmbedWrapper';

const styles = {
  frame: {
    display: 'block',
    width: '100%',
    height: 300,
    margin: '0 auto',
    border: 0,
  },
} as const;

interface Props {
  id: string;
}

const EmbedYouTube: FC<Props> = ({ id }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  function handleLoad() {
    setIsLoaded(true);
  }

  return (
    <Fragment>
      {!isLoaded && <EmbedPlaceholder />}
      <EmbedWrapper visible={isLoaded}>
        <iframe onLoad={handleLoad} style={styles.frame} src={`${YOUTUBE_EMBED_ENDPOINT}/${id}`} />
      </EmbedWrapper>
    </Fragment>
  );
};

export default EmbedYouTube;
