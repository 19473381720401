import React, { FC, ReactNode } from 'react';
import { Tab as MuiTab, TabProps } from '@mui/material';
import theme from 'theme';

const styles = {
  tabRoot: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  tabWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justify: 'space-between',
    padding: theme.spacing(1, 0),
  },
} as const;

type Props = TabProps & { component?: ReactNode };

const Tab: FC<Props> = props => {
  return <MuiTab wrapped sx={{ wrapped: styles.tabWrapper, root: styles.tabRoot }} {...props} />;
};

export default Tab;
