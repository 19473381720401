import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, ListSubheader } from '@mui/material';

import { ClientLocation } from 'api';
import { SidebarItem } from 'components';
import { generateLink } from '../Links';

interface Props {
  context: 'groups' | 'team';
  locations: ClientLocation[];
}

const Locations: FC<Props> = props => {
  const { context, locations } = props;
  const { t } = useTranslation();
  const params = useParams<{ location_id: string }>();

  function isLocationSelected(filter: ClientLocation) {
    const { location_id } = params;
    return filter.id === location_id;
  }

  function renderFilter(location: ClientLocation) {
    return (
      <SidebarItem
        color="inherit"
        key={location.id}
        link={generateLink(`/${context}/location/${location.id}`)}
        isSelected={isLocationSelected(location)}
        textNode={location.title}
      />
    );
  }

  if (!locations.length) {
    return null;
  }

  return (
    <List
      component="nav"
      subheader={<ListSubheader disableSticky>{t('common:location_plural')}</ListSubheader>}
    >
      {locations.map(renderFilter)}
    </List>
  );
};

export default Locations;
