import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { pick } from 'lodash';
import produce from 'immer';

import { ClientProtectedFragment, CsUpdateClientInput, useCsUpdateClientMutation } from 'api';
import { GetClient } from '../queries';

import { Layouts } from 'components';
import { useNotify } from 'hooks';

import { ClientForm } from '../components';

const UpdateClient: FC = () => {
  const { client_id } = useParams<{ client_id: string }>();
  const notify = useNotify();

  const { mutate: csUpdateClient, isLoading } = useCsUpdateClientMutation({
    onError: handleError,
    onSuccess: handleCompleted,
  });

  function getUpdateValues(client: ClientProtectedFragment) {
    const clientUpdateInput = pick<CsUpdateClientInput>(
      client,
      'id',
      'title',
      'subdomain',
      'email_sending_domain',
      'email_from_address',
      'email_domains',
      'content_domains',
      'email_address',
      'client_status',
      'authentication_type',
      // TODO: Add Authentication Support
      // 'sso_configuration',
      'sso_provider',
      'allow_user_invites',
      'allow_user_signup',
      'allow_group_creation',
      'departments_in_signup_required',
      'locations_in_signup_required',
      'support_multiple_languages',
      'allow_external_share',
      'sort_mode',
      'image',
      'allow_sharing',
      'compliance_notification_emails',
      'compliance_notification_enabled',
      'moderator_can_mark_as_important',
      'features',
      'public_post_page',
      'public_post_details',
      'contract_committed_user_count',
      'contract_type',
      'contract_end_at',
      'contract_start_at',
      'menu_item',
      'menu_item_enabled',
      'yearly_enrichment_quota',
      'client_aliases'
    );
    const defaultValues: Partial<CsUpdateClientInput> = {
      email_sending_domain: '',
      email_address: '',
      email_domains: [],
      content_domains: [],
    };
    Object.keys(defaultValues).forEach(k => {
      if (clientUpdateInput[k] == null) {
        clientUpdateInput[k] = defaultValues[k];
      }
    });
    return clientUpdateInput as CsUpdateClientInput;
  }

  function handleCompleted() {
    notify.info({ message: 'Client Updated' });
  }

  function handleError() {
    notify.mutationError();
  }

  function handleSubmit(nextValues: CsUpdateClientInput, prevValues: ClientProtectedFragment) {
    const updateData = produce<CsUpdateClientInput>(nextValues, draftValues => {
      // Avoid Duplicate Error
      if (prevValues.subdomain === draftValues.subdomain) {
        delete draftValues.subdomain;
      }
    });

    csUpdateClient({ data: updateData });
  }

  function renderMain() {
    return (
      <GetClient variables={{ id: client_id }}>
        {client => {
          if (client.allow_sharing == null) {
            client.allow_sharing = true;
          }
          if (client.menu_item_enabled == null) {
            client.menu_item_enabled = true;
          }
          return (
            <ClientForm
              action="edit"
              initialValues={getUpdateValues(client)}
              working={isLoading}
              onSubmit={values => handleSubmit(values as CsUpdateClientInput, client)}
            />
          );
        }}
      </GetClient>
    );
  }

  return <Layouts.App1Column desktopSize={12} main={renderMain()} />;
};

export default UpdateClient;
