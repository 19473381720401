import React, { FC } from 'react';
import { Typography } from '@mui/material';

import { UrlMetadata } from 'api';

interface IProps {
  description: UrlMetadata['description'];
}

const Description: FC<IProps> = ({ description }) => (
  <Typography color="textSecondary">{description}</Typography>
);

export default Description;
