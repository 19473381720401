import React, { FC } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';

import { useContent, useReleases } from 'hooks';
import { useCanShare } from 'features/content/hooks';
import { hasUrl } from 'features/content/helpers';

import Connections from './Connections';
import Preview from './Preview';
import SendToEmail from './SendToEmail';
import ShareDirectly from './ShareDirectly';

const FormBody: FC = () => {
  const content = useContent();
  const releases = useReleases();
  const showShare = useCanShare(content);

  const { is_shareable } = content;
  const hideLinkSharing = releases.includes('hideLinkSharing');
  const showLinkSharing: boolean = hasUrl(content) && !hideLinkSharing;

  function renderShareSection() {
    if (!showShare) {
      return null;
    }

    return (
      <Grid lg={5} xs={12}>
        <Connections>
          <Grid container spacing={2}>
            <Grid xs>
              <SendToEmail />
            </Grid>
            {showLinkSharing && (
              <Grid xs>
                <ShareDirectly />
              </Grid>
            )}
          </Grid>
        </Connections>
      </Grid>
    );
  }

  return (
    <Grid justifyContent="center" container paddingTop={4} maxWidth={1260} margin="0 auto 100px">
      <Grid lg={5} xs={12} marginRight={2}>
        <Preview />
      </Grid>
      {is_shareable && renderShareSection()}
    </Grid>
  );
};

export default FormBody;
