import React, { FC } from 'react';
import {
  Cell,
  Legend,
  LegendProps,
  Pie,
  PieChart as ReChartsPieChart,
  ResponsiveContainer,
  Tooltip as ReChartsTooltip,
} from 'recharts';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { getColor } from '../helpers';
import { Tooltip } from '../shared';

const styles = {
  dot: {
    width: 18,
    height: 18,
    borderRadius: '50%',
  },
  description: {
    padding: 0,
  },
} as const;

export interface IPieChartConfig {
  name: string;
  value: number;
}

interface IProps {
  height: number;
  data: IPieChartConfig[];
}

const PieChart: FC<IProps> = ({ height, data }) => {
  const margin = { top: 24, right: 0, bottom: 24, left: 0 };
  const wrapperStyle = { bottom: 0 };

  function renderLegendItem({ value }: { value: number }, index: number) {
    const legendBgColor = getColor(index);

    return (
      <Grid key={index}>
        <Grid container alignItems="center" spacing={1}>
          <Grid>
            <Box sx={styles.dot} style={{ background: legendBgColor }} />
          </Grid>
          <Grid>
            <Typography display="inline">{value}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderLegend({ payload }: LegendProps) {
    return (
      <Grid container spacing={3}>
        {payload.map(renderLegendItem)}
      </Grid>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ReChartsPieChart margin={margin}>
        <Legend wrapperStyle={wrapperStyle} content={renderLegend} />
        <ReChartsTooltip content={<Tooltip />} />
        <Pie data={data} dataKey="value" label outerRadius={94} innerRadius={70}>
          {data.map((entry, index) => (
            <Cell key={index} fill={getColor(index)} />
          ))}
        </Pie>
      </ReChartsPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
