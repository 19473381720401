export const SPECIAL_CHARACTERS = [
  '\\',
  '|',
  '{',
  '}',
  '@',
  '[',
  ']',
  '(',
  ')',
  '<',
  '>',
  '*',
  '_',
  '~',
];
