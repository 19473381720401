import React, { CSSProperties, FC, ReactNode } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export interface ICustomSVG {
  adsClick: ReactNode;
  bolt: ReactNode;
  chartArea: ReactNode;
  chartLine: ReactNode;
  chartPie: ReactNode;
  download: ReactNode;
  everyoneSocial: ReactNode;
  everyoneSocialSolid: ReactNode;
  facebook: ReactNode;
  flag: ReactNode;
  important: ReactNode;
  msteamsGreyscale: ReactNode;
  msteamsWhite: ReactNode;
  msteams: ReactNode;
  network: ReactNode;
  pin: ReactNode;
  share: ReactNode;
  slack: ReactNode;
  slackGreyscale: ReactNode;
  slackMono: ReactNode;
  sticker: ReactNode;
  track: ReactNode;
  tiktok: ReactNode;
  visibility: ReactNode;
  wechat: ReactNode;
}

interface Props extends SvgIconProps {
  name: keyof ICustomSVG;
  size?: number;
  sx?: CSSProperties;
}

const ICONS: ICustomSVG = {
  adsClick: (
    <g fill="currentColor">
      <path d="M11.71,17.99C8.53,17.84,6,15.22,6,12c0-3.31,2.69-6,6-6c3.22,0,5.84,2.53,5.99,5.71l-2.1-0.63C15.48,9.31,13.89,8,12,8 c-2.21,0-4,1.79-4,4c0,1.89,1.31,3.48,3.08,3.89L11.71,17.99z M22,12c0,0.3-0.01,0.6-0.04,0.9l-1.97-0.59C20,12.21,20,12.1,20,12 c0-4.42-3.58-8-8-8s-8,3.58-8,8s3.58,8,8,8c0.1,0,0.21,0,0.31-0.01l0.59,1.97C12.6,21.99,12.3,22,12,22C6.48,22,2,17.52,2,12 C2,6.48,6.48,2,12,2S22,6.48,22,12z M18.23,16.26L22,15l-10-3l3,10l1.26-3.77l4.27,4.27l1.98-1.98L18.23,16.26z" />
    </g>
  ),
  bolt: (
    <g fill="currentColor">
      <path d="M11 21h-1l1-7H7.5c-.58 0-.57-.32-.38-.66.19-.34.05-.08.07-.12C8.48 10.94 10.42 7.54 13 3h1l-1 7h3.5c.49 0 .56.33.47.51l-.07.15C12.96 17.55 11 21 11 21z" />
    </g>
  ),
  chartArea: (
    <g fill="currentColor">
      <path d="M23.48,21H.52a.52.52,0,1,0,0,1h23a.52.52,0,1,0,0-1Z" />
      <path d="M23.8,7.33a.49.49,0,0,0-.44-.1L18.1,8.39l-6.94-3a.52.52,0,0,0-.39,0L6.13,7.13l-5.25-5a.52.52,0,0,0-.73,0A.47.47,0,0,0,0,2.51V17.9a.51.51,0,0,0,.52.51h23A.51.51,0,0,0,24,17.9V7.73A.48.48,0,0,0,23.8,7.33Zm-.84,6-4.69-2.18a.61.61,0,0,0-.4,0l-6.94,2.36-4.69-1.1L1,8.09V3.72l4.6,4.37a.52.52,0,0,0,.54.12l4.76-1.77,6.9,3a.44.44,0,0,0,.32,0L23,8.37Z" />
    </g>
  ),
  chartLine: (
    <g fill="currentColor">
      <path d="M23.54,21.45H.92v-.61L5,18.4a1.85,1.85,0,0,0,3.34-1.08.51.51,0,0,0,0-.13l4-1.59a1.86,1.86,0,0,0,1.52.8,1.83,1.83,0,0,0,1.41-.68l4.14,1.44c0,.05,0,.1,0,.16a1.86,1.86,0,1,0,.32-1l-4-1.41a1.76,1.76,0,0,0,0-.33,1.85,1.85,0,1,0-3.69,0,1.1,1.1,0,0,0,0,.18L8,16.31a1.84,1.84,0,0,0-3.39,1,1.36,1.36,0,0,0,0,.2L.92,19.76V12.57L5.37,8.64A1.79,1.79,0,0,0,6.46,9,1.84,1.84,0,0,0,8.18,7.82l3.88.7a1.84,1.84,0,0,0,3.63-.43,1.36,1.36,0,0,0,0-.2l4.43-3a1.81,1.81,0,0,0,1.13.4,1.85,1.85,0,1,0-1.85-1.85,2,2,0,0,0,.15.73L15.34,7a1.83,1.83,0,0,0-3.26.57L8.28,6.9a1.84,1.84,0,0,0-3.66.26,2,2,0,0,0,.16.77L.92,11.34V3.73a.46.46,0,0,0-.46-.46A.47.47,0,0,0,0,3.73V21.91a.46.46,0,0,0,.46.46H23.54a.46.46,0,0,0,.46-.46A.47.47,0,0,0,23.54,21.45ZM21.23,16.4a.92.92,0,1,1-.92.92A.92.92,0,0,1,21.23,16.4Zm-7.38-2.77a.92.92,0,1,1-.93.92A.92.92,0,0,1,13.85,13.63ZM6.46,16.4a.92.92,0,1,1-.92.92A.92.92,0,0,1,6.46,16.4ZM21.23,2.55a.93.93,0,1,1-.92.92A.92.92,0,0,1,21.23,2.55ZM13.85,7.16a.93.93,0,1,1-.93.93A.93.93,0,0,1,13.85,7.16ZM6.46,6.24a.93.93,0,1,1-.92.92A.92.92,0,0,1,6.46,6.24Z" />
    </g>
  ),
  chartPie: (
    <g fill="currentColor">
      <path d="M13.91,0a.52.52,0,0,0-.52.52v9.57a.52.52,0,0,0,.52.52h9.57a.52.52,0,0,0,.52-.52A10.11,10.11,0,0,0,13.91,0Z" />
      <path d="M19.65,13.39h-9v-9a.52.52,0,0,0-.52-.52A10.09,10.09,0,1,0,20.17,13.91.52.52,0,0,0,19.65,13.39Z" />
    </g>
  ),
  download: (
    <g fill="currentColor">
      <path d="M19 3H4.99C3.88 3 3 3.9 3 5v14c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12h-3.13c-.47 0-.85.34-.98.8-.35 1.27-1.52 2.2-2.89 2.2s-2.54-.93-2.89-2.2c-.13-.46-.51-.8-.98-.8H4.99V6c0-.55.45-1 1-1H18c.55 0 1 .45 1 1v9zm-3-5h-2V7h-4v3H8l3.65 3.65c.2.2.51.2.71 0L16 10z" />
    </g>
  ),
  everyoneSocial: (
    <g transform="translate(1.000000, 3.000000)">
      <path
        fill="#F9A81A"
        d="M11.8,1.4v6.3c0,0.2,0.2,0.2,0.3,0.1c0.4-0.5,1.5-2.3,3.8-2.3c2.2,0,4.1-1.7,4.3-3.9C20.4-0.9,18.4-3,15.9-3C13.6-3,11.8-1,11.8,1.4"
      />
      <path
        fill="#E82164"
        d="M9.9,8.5v6.9c0,0.2-0.2,0.3-0.4,0.1c-0.6-0.6-2-1.6-5-1.7c-2.8,0-5.3-2.1-5.5-5c-0.3-3.3,2.3-6.1,5.5-6.1C7.5,2.9,9.9,5.5,9.9,8.5"
      />
      <path
        fill="#389AFF"
        d="M11.8,13.6v7.1c0,0.2,0.2,0.3,0.4,0.1c0.6-0.7,2-1.7,5.1-1.7c2.9,0,5.4-2.2,5.7-5.2c0.3-3.4-2.3-6.3-5.6-6.2C14.2,7.8,11.8,10.5,11.8,13.6"
      />
    </g>
  ),
  everyoneSocialSolid: (
    <g fill="currentColor" transform="translate(1.000000, 3.000000)">
      <path d="M11.8,1.4v6.3c0,0.2,0.2,0.2,0.3,0.1c0.4-0.5,1.5-2.3,3.8-2.3c2.2,0,4.1-1.7,4.3-3.9C20.4-0.9,18.4-3,15.9-3C13.6-3,11.8-1,11.8,1.4" />
      <path d="M9.9,8.5v6.9c0,0.2-0.2,0.3-0.4,0.1c-0.6-0.6-2-1.6-5-1.7c-2.8,0-5.3-2.1-5.5-5c-0.3-3.3,2.3-6.1,5.5-6.1C7.5,2.9,9.9,5.5,9.9,8.5" />
      <path d="M11.8,13.6v7.1c0,0.2,0.2,0.3,0.4,0.1c0.6-0.7,2-1.7,5.1-1.7c2.9,0,5.4-2.2,5.7-5.2c0.3-3.4-2.3-6.3-5.6-6.2C14.2,7.8,11.8,10.5,11.8,13.6" />
    </g>
  ),
  facebook: (
    <g fill="currentColor">
      <path d="M22,12c0-5.52-4.48-10-10-10S2,6.48,2,12c0,4.84,3.44,8.87,8,9.8V15H8v-3h2V9.5C10,7.57,11.57,6,13.5,6H16v3h-2 c-0.55,0-1,0.45-1,1v2h3v3h-3v6.95C18.05,21.45,22,17.19,22,12z" />
    </g>
  ),
  flag: (
    <g fill="currentColor">
      <path d="M8.76,21.05a.43.43,0,0,1-.54-.29L3,4a.43.43,0,1,1,.83-.25L9.05,20.51A.43.43,0,0,1,8.76,21.05Zm9.13-16.9c-.69.2-2.29.65-5.35-1.09C10.3,1.89,7.41.9,4.65,3.28A1.4,1.4,0,0,0,3,2.56a1.37,1.37,0,0,0-.91,1.71L7.33,21a1.36,1.36,0,1,0,2.6-.79L8,14.05c2.05-1.8,4.32-2,6.94-.44a8,8,0,0,0,4,1.22,4.16,4.16,0,0,0,2.3-.59,1.87,1.87,0,0,0,.58-2.12c-.6-1.94-2.08-6.74-2.19-7.13S19.11,3.82,17.89,4.15Z" />
    </g>
  ),
  important: (
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M21,12.39a1,1,0,0,1-.23,1.1s-1.9,1.29-5.31-.68c-2.83-1.63-5.42-1.52-7.69.29L5,4.24c2.42-2.32,4.86-1.55,7.13-.36,3.39,1.92,5.09,1.44,5.9,1.2l.14,0c.52-.14.56,0,.62.2C18.86,5.64,20.35,10.45,21,12.39ZM8.76,21.05a.43.43,0,0,1-.54-.29L3,4a.43.43,0,1,1,.83-.25L9.05,20.51A.43.43,0,0,1,8.76,21.05Zm9.13-16.9c-.69.2-2.29.65-5.35-1.09C10.3,1.89,7.41.9,4.65,3.28A1.4,1.4,0,0,0,3,2.56a1.37,1.37,0,0,0-.91,1.71L7.33,21a1.36,1.36,0,1,0,2.6-.79L8,14.05c2.05-1.8,4.32-2,6.94-.44a8,8,0,0,0,4,1.22,4.16,4.16,0,0,0,2.3-.59,1.87,1.87,0,0,0,.58-2.12c-.6-1.94-2.08-6.74-2.19-7.13S19.11,3.82,17.89,4.15Z"
      />
    </g>
  ),
  msteamsGreyscale: (
    <g>
      <path
        fill="rgba(0, 0, 0, 0.26)"
        d="M12.23,7.73v.93a3,3,0,0,1-2.09-1.78h1.24A.86.86,0,0,1,12.23,7.73Z"
      />
      <circle fill="rgba(0, 0, 0, 0.26)" cx="19.44" cy="6.65" r="2.09" />
      <path
        fill="rgba(0, 0, 0, 0.26)"
        d="M17,9.67h-3.8V17.2a.85.85,0,0,1-.53.78.88.88,0,0,1-.32.07H8l0,0A5.21,5.21,0,0,0,12.7,21.3a5.24,5.24,0,0,0,5.11-5.36V10.57A.88.88,0,0,0,17,9.67Z"
      />
      <path
        fill="rgba(0, 0, 0, 0.26)"
        d="M12.31,6.42a.85.85,0,0,1,.85.85V8.72a3,3,0,1,0-3.25-3,2.74,2.74,0,0,0,.09.7Z"
      />
      <path
        fill="rgba(0, 0, 0, 0.26)"
        d="M12.23,16.26V7.74a.86.86,0,0,0-.85-.86H2.85A.86.86,0,0,0,2,7.74v8.52a.86.86,0,0,0,.85.86h8.53A.86.86,0,0,0,12.23,16.26ZM9.36,10.13H7.66v4.64H6.57V10.13H4.87v-.9H9.36Z"
      />
      <path
        fill="rgba(0, 0, 0, 0.26)"
        d="M21.12,9.67H18.59a1.74,1.74,0,0,1,.22.9v5.34a6.09,6.09,0,0,1-.49,2.56,3,3,0,0,0,.42,0h0A3.24,3.24,0,0,0,22,15.26v-4.7A.89.89,0,0,0,21.12,9.67Z"
      />
    </g>
  ),
  msteamsWhite: (
    <g>
      <circle fill="#ffffff" cx="19.44" cy="6.65" r="2.09" />
      <path
        fill="#ffffff"
        d="M17,9.67h-3.8V17.2a.85.85,0,0,1-.53.78.88.88,0,0,1-.32.07H8l0,0A5.21,5.21,0,0,0,12.7,21.3a5.24,5.24,0,0,0,5.11-5.36V10.57A.88.88,0,0,0,17,9.67Z"
      />
      <path
        fill="#ffffff"
        d="M12.31,6.42a.85.85,0,0,1,.85.85V8.72a3,3,0,1,0-3.25-3,2.74,2.74,0,0,0,.09.7Z"
      />
      <path
        fill="#ffffff"
        d="M12.23,16.26V7.74a.86.86,0,0,0-.85-.86H2.85A.86.86,0,0,0,2,7.74v8.52a.86.86,0,0,0,.85.86h8.53A.86.86,0,0,0,12.23,16.26ZM9.36,10.13H7.66v4.64H6.57V10.13H4.87v-.9H9.36Z"
      />
      <path
        fill="#ffffff"
        d="M21.12,9.67H18.59a1.74,1.74,0,0,1,.22.9v5.34a6.09,6.09,0,0,1-.49,2.56,3,3,0,0,0,.42,0h0A3.24,3.24,0,0,0,22,15.26v-4.7A.89.89,0,0,0,21.12,9.67Z"
      />
    </g>
  ),
  msteams: (
    <g>
      <path
        fill="#555eaa"
        d="M16,9.67h5.17a.89.89,0,0,1,.88.89h0v4.7a3.24,3.24,0,0,1-3.25,3.25h0a3.24,3.24,0,0,1-3.25-3.25h0V10.14A.47.47,0,0,1,16,9.67Z"
      />
      <circle fill="#555eaa" cx="19.44" cy="6.65" r="2.09" />
      <circle fill="#7a80be" cx="12.93" cy="5.72" r="3.02" />
      <path
        fill="#7a80be"
        d="M17,9.67H8.43a.89.89,0,0,0-.85.9v5.37A5.24,5.24,0,0,0,12.7,21.3a5.24,5.24,0,0,0,5.11-5.36V10.57A.88.88,0,0,0,17,9.67Z"
      />
      <path
        fill="#010101"
        fillOpacity="0.1"
        style={{ isolation: 'isolate' }}
        d="M13.16,9.67V17.2a.85.85,0,0,1-.53.78.88.88,0,0,1-.32.07H8a4.34,4.34,0,0,1-.16-.47,5.74,5.74,0,0,1-.25-1.65V10.57a.89.89,0,0,1,.85-.9Z"
      />
      <path
        fill="#010101"
        fillOpacity="0.2"
        style={{ isolation: 'isolate' }}
        d="M12.7,9.67v8a.71.71,0,0,1-.07.32.85.85,0,0,1-.78.53H8.21c-.08-.15-.15-.31-.22-.46a4.34,4.34,0,0,1-.16-.47,5.74,5.74,0,0,1-.25-1.65V10.57a.89.89,0,0,1,.85-.9Z"
      />
      <path
        fill="#010101"
        fillOpacity="0.2"
        style={{ isolation: 'isolate' }}
        d="M12.7,9.67v7.06a.86.86,0,0,1-.85.85h-4a5.74,5.74,0,0,1-.25-1.65V10.57a.89.89,0,0,1,.85-.9Z"
      />
      <path
        fill="#010101"
        fillOpacity="0.2"
        style={{ isolation: 'isolate' }}
        d="M12.23,9.67v7.06a.85.85,0,0,1-.85.85H7.83a5.74,5.74,0,0,1-.25-1.65V10.57a.89.89,0,0,1,.85-.9Z"
      />
      <path
        fill="#010101"
        fillOpacity="0.1"
        style={{ isolation: 'isolate' }}
        d="M13.16,7.27V8.73H12.7a2.42,2.42,0,0,1-.47-.07,3,3,0,0,1-2.09-1.78A3.53,3.53,0,0,1,10,6.42h2.32A.85.85,0,0,1,13.16,7.27Z"
      />
      <path
        fill="#010101"
        fillOpacity="0.2"
        style={{ isolation: 'isolate' }}
        d="M12.7,7.73v1a2.42,2.42,0,0,1-.47-.07,3,3,0,0,1-2.09-1.78h1.71A.86.86,0,0,1,12.7,7.73Z"
      />
      <path
        fill="#010101"
        fillOpacity="0.2"
        style={{ isolation: 'isolate' }}
        d="M12.7,7.73v1a2.42,2.42,0,0,1-.47-.07,3,3,0,0,1-2.09-1.78h1.71A.86.86,0,0,1,12.7,7.73Z"
      />
      <path
        fill="#010101"
        fillOpacity="0.2"
        style={{ isolation: 'isolate' }}
        d="M12.23,7.73v.93a3,3,0,0,1-2.09-1.78h1.24A.86.86,0,0,1,12.23,7.73Z"
      />
      <path
        fill="#37379E"
        d="M2.85,6.88h8.53a.86.86,0,0,1,.85.86v8.52a.86.86,0,0,1-.85.86H2.85A.86.86,0,0,1,2,16.26V7.74A.86.86,0,0,1,2.85,6.88Z"
      />
      <path fill="#ffffff" d="M9.36,10.13H7.66v4.64H6.57V10.13H4.87v-.9H9.36Z" />
    </g>
  ),
  network: (
    <g fill="currentColor">
      <path d="M18,19.5a2,2,0,1,1,2-2A2,2,0,0,1,18,19.5Zm0-6a4,4,0,1,0,4,4A4,4,0,0,0,18,13.5Zm-6-1.9a1.9,1.9,0,0,0,0,3.8,1.9,1.9,0,0,0,0-3.8ZM6,19.5a2,2,0,1,1,2-2A2,2,0,0,1,6,19.5Zm0-6a4,4,0,1,0,4,4A4,4,0,0,0,6,13.5Zm6-9a2,2,0,1,1-2,2A2,2,0,0,1,12,4.5Zm0,6a4,4,0,1,0-4-4A4,4,0,0,0,12,10.5Z" />
    </g>
  ),
  pin: (
    <g fill="currentColor">
      <path d="M16 12V4h1V2H7v2h1v8l-2 2v2h5.2v6h1.6v-6H18v-2z" />
    </g>
  ),
  share: (
    <g fill="currentColor">
      <path
        d="M18.5739769,11.5294818 L16.2985845,11.5294818 L16.2985845,3.58824697 C16.2985845,2.71471114 15.6546055,2 14.86752,2 L9.14326226,2 C8.35617682,2 7.71219782,2.71471114 7.71219782,3.58824697 L7.71219782,11.5294818 L5.43680536,11.5294818 C4.16315801,11.5294818 3.51917901,13.2447886 4.4207496,14.2453842 L10.9893354,21.5354378 C11.5474505,22.1548541 12.4490211,22.1548541 13.0071363,21.5354378 L19.575722,14.2453842 C20.4772926,13.2447886 19.8476243,11.5294818 18.5739769,11.5294818 Z"
        transform="rotate(-90 12 12)"
        fillRule="evenodd"
      />
    </g>
  ),
  slack: (
    <g transform="matrix(0.19544, 0, 0, 0.19544, -14.384365, -14.384365)">
      <g>
        <path
          fill="#E01E5A"
          d="M99.4,151.2c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h12.9V151.2z"
        />
        <path
          fill="#E01E5A"
          d="M105.9,151.2c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v32.3c0,7.1-5.8,12.9-12.9,12.9 s-12.9-5.8-12.9-12.9V151.2z"
        />
      </g>
      <g>
        <path
          fill="#36C5F0"
          d="M118.8,99.4c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v12.9H118.8z"
        />
        <path
          fill="#36C5F0"
          d="M118.8,105.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9H86.5c-7.1,0-12.9-5.8-12.9-12.9 s5.8-12.9,12.9-12.9H118.8z"
        />
      </g>
      <g>
        <path
          fill="#2EB67D"
          d="M170.6,118.8c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9h-12.9V118.8z"
        />
        <path
          fill="#2EB67D"
          d="M164.1,118.8c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9V86.5c0-7.1,5.8-12.9,12.9-12.9 c7.1,0,12.9,5.8,12.9,12.9V118.8z"
        />
      </g>
      <g>
        <path
          fill="#ECB22E"
          d="M151.2,170.6c7.1,0,12.9,5.8,12.9,12.9c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9v-12.9H151.2z"
        />
        <path
          fill="#ECB22E"
          d="M151.2,164.1c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h32.3c7.1,0,12.9,5.8,12.9,12.9 c0,7.1-5.8,12.9-12.9,12.9H151.2z"
        />
      </g>
    </g>
  ),
  slackGreyscale: (
    <g transform="matrix(0.19544, 0, 0, 0.19544, -14.384365, -14.384365)">
      <g>
        <path
          fill="rgba(0, 0, 0, 0.26)"
          d="M99.4,151.2c0,7.1-5.8,12.9-12.9,12.9s-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h12.9V151.2z"
        />
        <path
          fill="rgba(0, 0, 0, 0.26)"
          d="M105.9,151.2c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v32.3c0,7.1-5.8,12.9-12.9,12.9 s-12.9-5.8-12.9-12.9C105.9,183.5,105.9,151.2,105.9,151.2z"
        />
      </g>
      <g>
        <path
          fill="rgba(0, 0, 0, 0.26)"
          d="M118.8,99.4c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v12.9H118.8z"
        />
        <path
          fill="rgba(0, 0, 0, 0.26)"
          d="M118.8,105.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9H86.5c-7.1,0-12.9-5.8-12.9-12.9 s5.8-12.9,12.9-12.9C86.5,105.9,118.8,105.9,118.8,105.9z"
        />
      </g>
      <g>
        <path
          fill="rgba(0, 0, 0, 0.26)"
          d="M170.6,118.8c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9h-12.9V118.8z"
        />
        <path
          fill="rgba(0, 0, 0, 0.26)"
          d="M164.1,118.8c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9V86.5c0-7.1,5.8-12.9,12.9-12.9 c7.1,0,12.9,5.8,12.9,12.9V118.8z"
        />
      </g>
      <g>
        <path
          fill="rgba(0, 0, 0, 0.26)"
          d="M151.2,170.6c7.1,0,12.9,5.8,12.9,12.9c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9v-12.9H151.2z"
        />
        <path
          fill="rgba(0, 0, 0, 0.26)"
          d="M151.2,164.1c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h32.3c7.1,0,12.9,5.8,12.9,12.9 c0,7.1-5.8,12.9-12.9,12.9H151.2z"
        />
      </g>
    </g>
  ),
  slackMono: (
    <g transform="matrix(0.19544, 0, 0, 0.19544, -14.384365, -14.384365)">
      <g>
        <path d="M99.4,151.2c0,7.1-5.8,12.9-12.9,12.9s-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h12.9V151.2z" />
        <path d="M105.9,151.2c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v32.3c0,7.1-5.8,12.9-12.9,12.9 s-12.9-5.8-12.9-12.9C105.9,183.5,105.9,151.2,105.9,151.2z" />
      </g>
      <g>
        <path d="M118.8,99.4c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v12.9H118.8z" />
        <path d="M118.8,105.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9H86.5c-7.1,0-12.9-5.8-12.9-12.9 s5.8-12.9,12.9-12.9C86.5,105.9,118.8,105.9,118.8,105.9z" />
      </g>
      <g>
        <path d="M170.6,118.8c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9h-12.9V118.8z" />
        <path d="M164.1,118.8c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9V86.5c0-7.1,5.8-12.9,12.9-12.9 c7.1,0,12.9,5.8,12.9,12.9V118.8z" />
      </g>
      <g>
        <path d="M151.2,170.6c7.1,0,12.9,5.8,12.9,12.9c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9v-12.9H151.2z" />
        <path d="M151.2,164.1c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h32.3c7.1,0,12.9,5.8,12.9,12.9 c0,7.1-5.8,12.9-12.9,12.9H151.2z" />
      </g>
    </g>
  ),
  sticker: (
    <g fill="currentColor">
      <path d="M21.204 31.867c-.717.544-1.731.544-2.447 0L6.52 22.577c-.716-.544-1.71-.544-2.427 0-1.014.778-1.014 2.274 0 3.051L17.543 35.85c1.432 1.089 3.442 1.089 4.894 0l13.449-10.222c1.014-.777 1.014-2.273 0-3.05l-.02-.02c-.716-.544-1.711-.544-2.427 0l-12.235 9.309zm1.253-5.87l13.449-10.222c1.014-.777 1.014-2.293 0-3.07L22.457 2.483c-1.432-1.088-3.442-1.088-4.894 0L4.114 12.724c-1.014.778-1.014 2.294 0 3.07l13.449 10.223c1.432 1.088 3.462 1.088 4.894-.02z" />
    </g>
  ),
  track: (
    <g fill="currentColor">
      <path d="M23,8a2,2,0,0,1-2,2,1.7,1.7,0,0,1-.51-.07l-3.56,3.55A1.77,1.77,0,0,1,17,14a2,2,0,0,1-4,0,1.77,1.77,0,0,1,.07-.52l-2.55-2.55a2,2,0,0,1-1,0L4.93,15.49A1.7,1.7,0,0,1,5,16a2,2,0,1,1-2-2,1.7,1.7,0,0,1,.51.07L8.07,9.52A1.77,1.77,0,0,1,8,9a2,2,0,0,1,4,0,1.77,1.77,0,0,1-.07.52l2.55,2.55a2,2,0,0,1,1,0l3.55-3.56A1.7,1.7,0,0,1,19,8a2,2,0,0,1,4,0Z" />
    </g>
  ),
  tiktok: (
    <g fill="currentColor">
      <path d="M7.87,18.27a2.87,2.87,0,0,1,2.25-4.6,2.72,2.72,0,0,1,.86.14V10.34a6.37,6.37,0,0,0-1-.05V13a2.71,2.71,0,0,0-.86-.13,2.87,2.87,0,0,0-1.27,5.42Zm9.67-12a4.83,4.83,0,0,1-1.21-2.77V3H15.4A4.72,4.72,0,0,0,17.54,6.22ZM16.33,9.63A7.89,7.89,0,0,0,21,11.14V7.76a4.6,4.6,0,0,1-1-.11v2.67a8,8,0,0,1-4.67-1.51v6.91A6.22,6.22,0,0,1,9.15,22a6.16,6.16,0,0,1-3.44-1.05,6.13,6.13,0,0,0,4.42,1.88,6.22,6.22,0,0,0,6.2-6.26V9.63Z" />
      <path d="M15.35,8.81A8,8,0,0,0,20,10.32V7.65a4.72,4.72,0,0,1-2.48-1.43A4.72,4.72,0,0,1,15.4,3H13V16.55a2.85,2.85,0,0,1-2.84,2.85,2.8,2.8,0,0,1-2.25-1.13,2.87,2.87,0,0,1,1.27-5.42A3,3,0,0,1,10,13V10.29A6.28,6.28,0,0,0,5.71,20.93,6.16,6.16,0,0,0,9.15,22a6.23,6.23,0,0,0,6.2-6.27Z" />
      <path d="M10.05,10.29V9.52a6.19,6.19,0,0,0-7,6.2,6.29,6.29,0,0,0,2.75,5.21,6.28,6.28,0,0,1,4.3-10.64ZM15.44,3c0-.13,0-.26,0-.39V2.19H12V15.72a2.85,2.85,0,0,1-2.83,2.86,2.75,2.75,0,0,1-1.28-.31,2.82,2.82,0,0,0,2.26,1.13A2.85,2.85,0,0,0,13,16.55V3Zm4.62,4.63V6.93a4.62,4.62,0,0,1-2.48-.71A4.7,4.7,0,0,0,20.06,7.65Z" />
    </g>
  ),
  visibility: (
    <g fill="currentColor">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    </g>
  ),
  wechat: (
    <g fill="currentColor">
      <path d="M10.89,7.53a1,1,0,1,0,1,1A1,1,0,0,0,10.89,7.53Zm-4.82,0a1,1,0,1,0,1,1A1,1,0,0,0,6.07,7.53Zm11.16,5a.76.76,0,0,0-.75.76.77.77,0,1,0,.75-.76Zm-4,0a.76.76,0,0,0-.75.76.77.77,0,1,0,.75-.76ZM8.53,4.38c3.6,0,6.59,2.21,7.13,5.09h-.39c-3.64,0-6.59,2.46-6.6,5.53a5.06,5.06,0,0,0,.24,1.45,1.21,1.21,0,0,1-.38,0,8.58,8.58,0,0,1-2.36-.29l-.29,0a.6.6,0,0,0-.29.09L4,17.13a.33.33,0,0,1-.15.05.24.24,0,0,1-.24-.24,1.15,1.15,0,0,1,.05-.2c0-.06.11-.46.21-.89l0-.21,0-.11a.81.81,0,0,0,0-.19A.37.37,0,0,0,3.8,15a5.68,5.68,0,0,1-2.5-4.56C1.3,7.08,4.53,4.38,8.53,4.38ZM15.27,10c3.33,0,6,2.26,6,5.05a4.69,4.69,0,0,1-2.07,3.78.39.39,0,0,0-.17.3.84.84,0,0,0,0,.19c.08.37.25,1,.26,1a.66.66,0,0,1,0,.15.2.2,0,0,1-.19.2.33.33,0,0,1-.15-.05l-1.3-.78a.44.44,0,0,0-.32-.08l-.16,0a7.52,7.52,0,0,1-2,.29c-3.32,0-6-2.26-6-5S12,10,15.27,10Z" />
    </g>
  ),
};

const CustomSVG: FC<Props> = ({ name, size, sx, ...props }) => {
  const theme = useTheme();

  return (
    <SvgIcon
      fontSize="inherit"
      sx={{
        fontSize: theme.typography.pxToRem(size),
        ...props.style,
        ...sx,
      }}
      {...props}
    >
      {ICONS[name]}
    </SvgIcon>
  );
};

CustomSVG.defaultProps = {
  size: 24,
  className: '',
  viewBox: '0 0 24 24',
};

export default CustomSVG;
