import React, { FC, Fragment } from 'react';

import {
  BodyTextField,
  BrandedCheckbox,
  ReadOnlyWarning,
  ShareableCheckbox,
  TitleTextField,
} from '../';

interface Props {
  moderator: boolean;
  contributor: boolean;
}

const TextForm: FC<Props> = ({ contributor }) => {
  return (
    <Fragment>
      <TitleTextField required />
      <BodyTextField required />
      <ShareableCheckbox />
      {contributor && <BrandedCheckbox />}
      <ReadOnlyWarning />
    </Fragment>
  );
};

export default TextForm;
