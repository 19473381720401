import useInfiniteScroll from 'react-infinite-scroll-hook';
import { UseInfiniteQueryOptions } from 'react-query';

import {
  ContentEmailHistoryFragment,
  GetContentEmailHistoryQuery,
  GetContentEmailHistoryQueryVariables,
  useGetContentEmailHistoryQuery,
} from 'api';
import { InfiniteQuery } from 'types/app';
import { useInfiniteQuery } from 'app-react-query/hooks';
import { useNotify } from 'hooks';
import { PAGINATION_ROOT_MARGIN } from 'app-constants';

type Query = GetContentEmailHistoryQuery;
type Variables = GetContentEmailHistoryQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = InfiniteQuery<ContentEmailHistoryFragment>;

const useContentEmailHistory = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();
  const queryResult = useInfiniteQuery<Query, Variables>(
    useGetContentEmailHistoryQuery,
    ({ pageParam }: { pageParam: Variables }) => ({ data: { ...variables.data, ...pageParam } }),
    {
      initialData: {
        pages: [{ getContentEmailHistory: { history: [], paginate: true } }],
        pageParams: [],
      },
      onError: notify.queryError,
      getNextPageParam: lastPage => {
        const { getContentEmailHistory } = lastPage;
        const { history, sent_at, spec_id } = getContentEmailHistory;

        const endOfList = !spec_id && !sent_at;
        const noResults = !history.length;

        if (noResults || endOfList) {
          return false;
        }

        return { spec_id, sent_at };
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = queryResult;
  const results = data?.pages?.map(page => page.getContentEmailHistory.history).flat() ?? [];

  const [sentinelRef] = useInfiniteScroll({
    hasNextPage,
    loading: isFetchingNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: PAGINATION_ROOT_MARGIN,
  });

  return {
    sentinelRef,
    count: null,
    results,
    isEmpty: !results.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !results.length && isFetching,
  };
};

export default useContentEmailHistory;
