import React, { FC } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import theme from 'theme';

interface Props {
  open: boolean;
  color?: string;
}

const PickerInput: FC<Props> = ({ open, color }) => {
  const { t } = useTranslation();

  if (open) {
    return (
      <Button color="primary" variant="contained" sx={{ transform: 'rotate(180deg)' }}>
        {t('common:done')}
      </Button>
    );
  }
  return (
    <AddCircleIcon
      sx={{ fontSize: theme.typography.pxToRem(32) }}
      color="primary"
      style={{ color }}
    />
  );
};

export default PickerInput;
