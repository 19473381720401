export enum PostOption {
  Link = 'Link',
  ImageVideo = 'ImageVideo',
  Text = 'Text',
}

export enum ExternalShareOption {
  LinkShare = 'LinkShare',
  ImageShare = 'ImageShare',
  TextShare = 'TextShare',
}

export interface IPostUIState {
  option: PostOption;
  shareOption: ExternalShareOption;
  validUrl: boolean;
  engagePost: boolean;
  brandedPost: boolean;
  moderatorMode: boolean;
  repost: boolean;
  pin: boolean;
  schedule: boolean;
  commentary: boolean;
  contentTagging: boolean;
  disclosures: boolean;
  tracking: boolean;
  share: boolean;
  msteams: boolean;
  slack: boolean;
  editing: boolean;
  previousSlackPost: boolean;
  previousMSTeamsPost: boolean;
  requiresApproval: boolean;
  multipleGroupPosting: boolean;
  selectedPhotoIndexes: number[];
  showShareWidget: boolean;
}

export type PostUIStateAction =
  | { type: 'option'; value: PostOption }
  | { type: 'shareOption'; value: ExternalShareOption }
  | { type: 'validUrl'; value: boolean }
  | { type: 'engagePost'; value: boolean }
  | { type: 'brandedPost'; value: boolean }
  | { type: 'share'; value: boolean }
  | { type: 'msteams'; value: boolean }
  | { type: 'slack'; value: boolean }
  | { type: 'previousSlackPost'; value: boolean }
  | { type: 'previousMSTeamsPost'; value: boolean }
  | { type: 'moderatorMode'; value: boolean }
  | { type: 'repost'; value: boolean }
  | { type: 'pin' }
  | { type: 'schedule' }
  | { type: 'commentary' }
  | { type: 'contentTagging' }
  | { type: 'disclosures' }
  | { type: 'tracking'; value: boolean }
  | { type: 'editing'; value: boolean }
  | { type: 'requiresApproval'; value: boolean }
  | { type: 'multipleGroupPosting' }
  | { type: 'updatePhotoIndexes'; value: number }
  | { type: 'swapPhotoIndexes'; value: [number, number] }
  | { type: 'clearPhotoIndexes' }
  | { type: 'showShareWidget'; value: boolean };
