import React, { FC, Fragment } from 'react';
import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocation } from 'react-router-dom';

import * as paths from 'paths';
import { S3ObjectInput } from 'api';
import { getImageSrc } from 'helpers';
import { queryString } from 'utilities';
import { IMGIX_HOST } from 'settings';

import { useSharePhoto } from 'hooks';
import { CONTENT_THUMBNAIL_SIZE, THUMBNAIL_SIZE } from 'app-constants';
import theme from 'theme';

const styles = {
  container: {
    margin: theme.spacing(),
  },
  imageWrapper: {
    position: 'relative',
  },
  icon: {
    backgroundColor: 'common.white',
    color: 'primary.main',
    borderRadius: '50%',
    ...theme.mixins.absoluteCenter,
  },
  pointer: {
    cursor: 'pointer',
  },
} as const;

interface Props {
  images: S3ObjectInput[];
}

const SharingThumbnails: FC<Props> = ({ images }) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const { selectedPhotoIndex, setPhotoIndex } = useSharePhoto();

  const isExternalShare = pathname === paths.share;

  function handleChangePhoto(index: number) {
    setPhotoIndex(index);
  }

  function renderThumbnail(image: S3ObjectInput, index: number) {
    const isSelected = selectedPhotoIndex === index;
    const rawColor = theme.palette.primary.main.split('#')[1];

    const params = {
      h: isExternalShare ? THUMBNAIL_SIZE : CONTENT_THUMBNAIL_SIZE,
      w: isExternalShare ? THUMBNAIL_SIZE : CONTENT_THUMBNAIL_SIZE,
      fit: 'crop',
      auto: 'format',
      border: `3,${isSelected ? rawColor : 'FFFFFFFF'}`,
      'border-radius': 4,
    };

    const imageUrl = `${IMGIX_HOST}/${image.key}?${queryString(params)}`;
    const imageSrc = getImageSrc(imageUrl);

    return (
      <Grid key={index}>
        <Box sx={styles.imageWrapper}>
          {isSelected && <CheckCircleIcon sx={styles.icon} />}
          <img style={styles.pointer} onClick={() => handleChangePhoto(index)} {...imageSrc} />
        </Box>
      </Grid>
    );
  }

  if (!images || images?.length <= 1) {
    return null;
  }

  return <Fragment>{images.map(renderThumbnail)}</Fragment>;
};

export default SharingThumbnails;
