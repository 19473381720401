import React, { ChangeEvent, FC, useState } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  ClientLogo,
  Layouts,
  Loaders,
  PasswordRequirements,
  ValidatedTextFieldNext,
  VisibilityAdornment,
} from 'components';
import { Login } from 'Links';
import { useGlobalClient } from 'hooks';

interface Props {
  updateUsername: (username: string) => void;
  updatePassword: (password: string) => void;
  working: boolean;
  hiddenEmail?: boolean;
}

const SignUpForm: FC<Props> = props => {
  const { hiddenEmail, working, updateUsername, updatePassword } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { t } = useTranslation();
  const client = useGlobalClient();

  function handleUsernameChange(event: ChangeEvent<HTMLInputElement>) {
    updateUsername(event.currentTarget.value.toLowerCase());
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    updatePassword(event.currentTarget.value);
  }

  return (
    <Layouts.OnboardingBox component={Form}>
      <Box textAlign="center">
        <ClientLogo client={client} />
      </Box>
      <Typography align="center" paragraph variant="h2">
        {t('auth:signUpInternal')}
      </Typography>
      {!hiddenEmail && (
        <Box paddingTop={3}>
          <ValidatedTextFieldNext
            enableTrim
            fullWidth
            name="username"
            type="email"
            onChange={handleUsernameChange}
            label={t('auth:clientSelectLabel')}
            placeholder={t('auth:clientSelectPlaceholder')}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      )}
      <Box paddingTop={3}>
        <ValidatedTextFieldNext
          disableError
          fullWidth
          name="password"
          type={passwordVisible ? 'text' : 'password'}
          label={t('auth:signUpPassword')}
          placeholder={t('auth:signUpPassword')}
          onChange={handlePasswordChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <VisibilityAdornment
                visible={passwordVisible}
                onToggle={() => setPasswordVisible(!passwordVisible)}
              />
            ),
            sx: { backgroundColor: 'grey.100' },
          }}
        />
        <Box marginTop={2}>
          <PasswordRequirements name="password" />
        </Box>
      </Box>
      <Box paddingTop={3}>
        <Button
          fullWidth
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          disabled={working}
        >
          {working ? <Loaders.Standard height={26} /> : t('auth:signUpButton')}
        </Button>
      </Box>
      <Box paddingTop={5} textAlign="center">
        <Link color="textSecondary" component={Login} underline="hover">
          {t('auth:signUpLogin')}
        </Link>
      </Box>
    </Layouts.OnboardingBox>
  );
};

export default SignUpForm;
