import { jsx } from 'slate-hyperscript';
import inRange from 'lodash/inRange';
import { MentionObject } from 'api';

/**
 * Handles initial values as wells text only values,
 * and values with complimentary mention meta-data objects
 * @param text
 * @param mentions
 */
export const deserialize: any = (text: string, mentions: MentionObject[]) => {
  if (text?.length && !mentions?.length) {
    return [jsx('element', { type: 'paragraph' }, text)];
  }

  if (!text?.length && !mentions?.length) {
    return [jsx('element', { type: 'paragraph' }, [{ text: '' }])];
  }

  const lines = text.split('\n');
  const children = [];
  let lineRanges = [0, lines[0]?.length];

  for (let i = 0; i < lines.length; i++) {
    const currentLine = lines[i];

    const mentionsInRange = mentions.filter((value: MentionObject) => {
      if (inRange(value.start, lineRanges[0], lineRanges[1])) {
        return value;
      }
      return null;
    });

    if (mentionsInRange.length) {
      children.push(
        jsx(
          'element',
          { type: 'paragraph' },
          populateWithMention(mentionsInRange, currentLine, lineRanges)
        )
      );
    } else {
      children.push(
        jsx('element', { type: 'paragraph' }, currentLine?.length ? currentLine : [{ text: '' }])
      );
    }

    lineRanges = [
      lineRanges[1],
      lineRanges[1] + lines[i + 1]?.length || lines[lines.length - 1].length,
    ];
  }

  return children;
};

/**
 * Core Mention deserialization logic
 * @param mentionStack
 * @param line
 * @param lineRanges
 */
const populateWithMention = (mentionStack: MentionObject[], line: string, lineRanges: number[]) => {
  const children = [];
  const length = line.length;

  for (let i = 0; i < length; i++) {
    const mentionStart = mentionStack[0].start - lineRanges[0];
    const mentionEnd = mentionStack[0].start + mentionStack[0].length - lineRanges[0];

    // get any text before @mention
    if (!inRange(i, mentionStart, mentionEnd) && i < mentionStart && i == 0) {
      children.push(jsx('text', { type: 'text', text: line.slice(0, mentionStart) }, null));
      i = mentionStart;
    }

    // get @mention
    if (inRange(i, mentionStart, mentionEnd)) {
      children.push(jsx('text', { type: 'text', text: '' }, null));
      children.push(
        jsx(
          'element',
          {
            type: 'mention',
            info: { name: line.slice(mentionStart, mentionEnd), ...mentionStack[0] },
          },
          [{ type: 'text', text: line.slice(mentionStart, mentionEnd) }]
        )
      );
      i = mentionEnd;
      mentionStack.shift();
    }

    // get text in between @mentions
    if (!inRange(i, mentionStart, mentionEnd) && i > mentionStart && mentionStack.length) {
      const newStart = mentionStack[0].start - lineRanges[0];
      children.push(jsx('text', { type: 'text', text: line.slice(i, newStart) }, null));
      i = newStart;
    }

    // get text after all @mentions
    if (!mentionStack.length && i < length) {
      children.push(jsx('text', { type: 'text', text: line.slice(i, line.length) }, null));
      i = length;
    }
  }

  return children;
};
