import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, GlobalStyles, Unstable_Grid2 as Grid } from '@mui/material';

import { Layouts, PaddedPaper } from 'components';
import { getRootDomain } from 'utilities';
import { getAppImage } from 'helpers';
import { AUTH_ERROR_KEY, AUTH_ERROR_MSG } from '../constants';
import theme from 'theme';

interface IProps {
  code?: string;
}

const globalStyles = {
  'html, body, #root': {
    backgroundColor: theme.custom.palette.grey[100],
  },
} as const;

const AuthError: FC<IProps> = ({ code }) => {
  const { t } = useTranslation();
  const errorDescription = getErrorDescription();
  const workspaceHref = `https://${getRootDomain()}`;
  const hasWorkspaceAccess = location.href != getRootDomain();

  function getErrorDescription() {
    const authError = sessionStorage.getItem(AUTH_ERROR_KEY) || code || AUTH_ERROR_MSG;
    const translation = t(authError);
    if (translation != authError || translation.startsWith('auth:')) {
      return translation;
    }
    const authError2 = `auth:${authError}`;
    const translation2 = t(authError2);
    if (translation2 != authError2) {
      return translation2;
    }
    return translation;
  }

  function renderMain() {
    return (
      <Fragment>
        <GlobalStyles styles={globalStyles} />
        <Grid container justifyContent="center">
          <Grid>
            <PaddedPaper>
              <Box textAlign="center">
                <img {...getAppImage('illustrations/pipe.png', 350)} />
              </Box>
              <Typography gutterBottom align="center" variant="h6">
                {errorDescription}
              </Typography>
              <Box paddingBottom={4} />
              {hasWorkspaceAccess && (
                <Box paddingBottom={4}>
                  <Button
                    fullWidth
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => location.assign(workspaceHref)}
                  >
                    {t('auth:backToWorkspaces')}
                  </Button>
                </Box>
              )}
            </PaddedPaper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  return <Layouts.Onboarding main={renderMain()} />;
};

export default AuthError;
