import React, { FC, Fragment, ReactNode } from 'react';

import {
  GroupOverviewStatsQueryVariables,
  GroupsSortFields,
  PostOverviewStatsQueryVariables,
  PostsSortFields,
  ReportingFilter,
  ShareOverviewStatsQueryVariables,
  SharesSortFields,
  SortDirections,
  SortedGroup,
  SortedPost,
  SortedShare,
  SortedUser,
  useGroupOverviewStatsQuery,
  usePostOverviewStatsQuery,
  UserOverviewStatsQueryVariables,
  UsersSortFields,
  useShareOverviewStatsQuery,
  useUserOverviewStatsQuery,
} from 'api';
import { useProtectedClient } from 'hooks';

type ReturnTypes = SortedShare | SortedUser | SortedPost | SortedGroup;
type SortTypes = SharesSortFields | PostsSortFields | UsersSortFields | GroupsSortFields;
type QueryVariables = ShareOverviewStatsQueryVariables &
  PostOverviewStatsQueryVariables &
  GroupOverviewStatsQueryVariables &
  UserOverviewStatsQueryVariables;

interface ISortedVariables {
  start_date: string;
  end_date: string;
  page: number;
  per_page: number;
  sort: SortTypes;
  sort_direction: SortDirections;
  snapshot: boolean;
  filter: ReportingFilter;
}

interface IChildrenProps {
  loading: boolean;
  stats: ReturnTypes[];
}

interface IProps {
  type: 'sortedPosts' | 'sortedShares' | 'sortedUsers' | 'sortedGroups';
  variables: ISortedVariables;
  children: (props: IChildrenProps) => ReactNode;
}

const AnalyticsSortedTable: FC<IProps> = props => {
  const { children, type, variables } = props;

  const client = useProtectedClient();
  const query = getQuery();

  const { data, isLoading } = query({
    client_id: client.id,
    ...(variables as QueryVariables),
  });

  function getQuery() {
    switch (type) {
      case 'sortedPosts':
        return usePostOverviewStatsQuery;
      case 'sortedShares':
        return useShareOverviewStatsQuery;
      case 'sortedUsers':
        return useUserOverviewStatsQuery;
      case 'sortedGroups':
        return useGroupOverviewStatsQuery;
    }
  }

  return (
    <Fragment>
      {children({
        loading: isLoading,
        stats: data?.[type] ?? [],
      })}
    </Fragment>
  );
};

export default AnalyticsSortedTable;
