import React, { FC, useEffect, useState } from 'react';
import { Button, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import { CustomSVG } from 'components';
import { ChatChannelPicker, ChatTeamPicker } from 'components/Pickers';
import {
  ContentInput,
  Network,
  SharingChannelInfo,
  TeamInfo,
  useGetChatChannelsQuery,
  useGetChatTeamsQuery,
} from 'api';
import { useIntegrations, useNotify } from 'hooks';
import { connectionPopup } from 'helpers';
import { generateRepostChannelsString } from 'features/posting/helpers';

import { usePostContext } from '../../PostContext';
import Widget from './WidgetContainer';
import { Switch } from '../';

const styles = {
  sendAgainButton: {
    fontSize: '12px',
    textDecoration: 'underline',
  },
} as const;

const MicrosoftTeams: FC = () => {
  const notify = useNotify();

  const { t } = useTranslation();

  const { state, dispatch } = usePostContext();
  const { values, setFieldValue } = useFormikContext<ContentInput>();
  const { ms_teams_channels } = values;

  const integrations = useIntegrations();
  const hasMSTeams = integrations.some(integration => integration.provider === Network.Msteams);

  const [selectedTeam, setSelectedTeam] = useState<TeamInfo>({ team_id: '', team_name: '' });
  const [prevSelectedTeam] = useState<TeamInfo>(getPrevSelectedTeam());
  const [prevSelectedChannels] = useState(ms_teams_channels);

  useEffect(() => {
    const isPending =
      !isEmpty(values.ms_teams_channels) &&
      ms_teams_channels.every(channel => channel.status === 'pending');
    dispatch({ type: 'msteams', value: hasMSTeams && isPending });
  }, []);

  const teamQueryOptions = useGetChatTeamsQuery(
    { network: Network.Msteams },
    {
      onSuccess: data => {
        const selectedTeam = data.getChatTeams.ms_teams.find(
          team => team.team_id === prevSelectedTeam.team_id
        );
        setSelectedTeam({
          team_id: selectedTeam?.team_id || '',
          team_name: selectedTeam?.team_name || '',
        });
      },
      onError: notify.queryError,
    }
  );

  const chatQueryOptions = useGetChatChannelsQuery(
    { network: Network.Msteams, team_id: selectedTeam.team_id },
    { onError: notify.queryError }
  );

  const teamOptions = teamQueryOptions.data?.getChatTeams?.ms_teams ?? [];
  const channelOptions = chatQueryOptions.data?.getChatChannels?.msteams_channels ?? [];

  function getPrevSelectedTeam() {
    const teamId = !isEmpty(ms_teams_channels) ? ms_teams_channels[0].team_id : '';

    return { team_id: teamId, team_name: '' };
  }

  function handleConnect() {
    connectionPopup(Network.Msteams);
  }

  function handleSwitch() {
    dispatch({ type: 'msteams', value: !state.msteams });
  }

  function handleChannelSelect(channels: SharingChannelInfo[]) {
    setFieldValue('ms_teams_channels', channels);
  }

  function enableChannelsEditing() {
    dispatch({ type: 'previousMSTeamsPost', value: false });
    dispatch({ type: 'msteams', value: true });
  }

  function renderAlreadyShared() {
    if (isEmpty(prevSelectedChannels)) {
      return null;
    }

    const channels = prevSelectedChannels.map(channel => {
      const selected = channelOptions.find(option => option.channel_id === channel.channel_id);
      return selected.channel_name;
    });

    return (
      <Grid container>
        <Grid xs={1} />
        <Grid xs={11}>
          <Typography variant="body1" color="primary" display="inline">
            {`Already shared to: ${selectedTeam.team_name}: #${channels.join(', #')}`}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function renderBody() {
    if (state.msteams) {
      return (
        <Grid container marginTop={2}>
          <Grid xs={1} />
          <Grid xs={4} marginRight={2}>
            <ChatTeamPicker
              disabled={!!selectedTeam.team_id}
              isLoading={teamQueryOptions.isLoading}
              options={teamOptions}
              selectedTeam={selectedTeam}
              setSelectedTeam={setSelectedTeam}
            />
          </Grid>
          {!!selectedTeam.team_id && (
            <Grid xs={6}>
              <ChatChannelPicker
                isLoading={chatQueryOptions.isLoading}
                options={channelOptions}
                selectedChannels={values.ms_teams_channels}
                onChannelChange={handleChannelSelect}
              />
            </Grid>
          )}
        </Grid>
      );
    }
    return null;
  }

  function renderToggle() {
    if (state.previousMSTeamsPost) {
      return (
        <Button
          variant="text"
          color="primary"
          sx={styles.sendAgainButton}
          onClick={enableChannelsEditing}
        >
          {t('posting:sendAgainRepost')}
        </Button>
      );
    }

    if (hasMSTeams) {
      return (
        <Switch
          checked={state.msteams}
          onChange={handleSwitch}
          color="primary"
          data-testid="post-radio-msTeams"
        />
      );
    }

    return (
      <Button variant="text" color="primary" onClick={handleConnect} sx={{ float: 'right' }}>
        <Typography color="primary">{t('user:connectTeamsAccount')}</Typography>
      </Button>
    );
  }

  return (
    <Widget
      titleKey="posting:shareMicrosoftTeams"
      subtitleKey={
        state.previousMSTeamsPost
          ? generateRepostChannelsString(values.ms_teams_channels, t('posting:sentToRepost'))
          : 'posting:guidelineMicrosoftTeams'
      }
      icon={<CustomSVG name={state.msteams ? 'msteams' : 'msteamsGreyscale'} />}
      toggle={renderToggle()}
      toggleColumns={hasMSTeams ? 1 : 3}
    >
      {renderBody()}
      {selectedTeam.team_id && !isEmpty(channelOptions) && renderAlreadyShared()}
    </Widget>
  );
};

export default MicrosoftTeams;
