// Polyfills

/*
  Babel polyfill is forcibly loaded by coralogix/browser
  If we remove coralogix/browser, we need to load @babel/polyfill again
 */
import 'url-polyfill';
import 'isomorphic-fetch';
import 'delayed-scroll-restoration-polyfill';

// Library CSS
import 'cropperjs/dist/cropper.min.css';
import 'flatpickr/dist/flatpickr.css';

require('!style-loader!css-loader!video.js/dist/video-js.css');

import React from 'react';
import ReactDOM from 'react-dom';
import { CoralogixRum } from '@coralogix/browser';
import { CORALOGIX_PUBLIC_KEY, CORALOGIX_APP } from './settings';

import 'config/braze';
import 'config/yup';
import 'config/browser';

import App from 'startup/App';

CoralogixRum.init({
  public_key: CORALOGIX_PUBLIC_KEY,
  application: CORALOGIX_APP,
  version: '1.0',
  coralogixDomain: 'US2',
});

ReactDOM.render(<App />, document.getElementById('root'));
