import { Network, useCreateLoginTokenForCurrentUserInClientMutation } from 'api';
import { useCurrentUser, useGlobalClient, useNotify } from 'hooks';
import { popup, queryString } from 'utilities';
import { AUTH_DOMAIN } from 'settings';
import { LOCAL_STORAGE_KEY_ACCESS_KEY } from 'app-constants';
import { ShareConnectionOption } from 'features/sharing/types';

import * as paths from 'paths';

/**
 * Social media account connection hook
 * Currently used for partner sharing
 * @param network
 * @param connectionOption
 */
const useConnectAccount = (network: Network, connectionOption: ShareConnectionOption) => {
  const client = useGlobalClient();
  const notify = useNotify();
  const currentUser = useCurrentUser();

  if (!client) {
    throw new Error('useConnectAccount requires a client provider');
  }

  const accessKey = localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_KEY);
  const commonParams = getCallbackUrls();

  const { mutate: createLoginToken } = useCreateLoginTokenForCurrentUserInClientMutation({
    onError: notify.mutationError,
    onSuccess: ({ createLoginTokenForCurrentUserInClient }) => {
      const params = {
        user_id: currentUser.id,
        token: createLoginTokenForCurrentUserInClient,
        source: 'protected partner sharing',
        ...commonParams,
      };

      initConnect(params);
    },
  });

  function getCallbackUrls() {
    if (connectionOption === ShareConnectionOption.Page) {
      return {
        success_callback_url: `${window.location.origin}${
          window.location.pathname
        }${encodeURIComponent(window.location.search)}`,
        failure_callback_url: `${window.location.origin}${
          window.location.pathname
        }${encodeURIComponent(window.location.search)}`,
      };
    }

    return {
      success_callback_url: `${window.location.origin}${paths.connectionSuccess}`,
      failure_callback_url: `${window.location.origin}${paths.connectionFailure}`,
    };
  }

  function handlePublicConnect() {
    if (!accessKey) {
      throw new Error('Access key is required for public connect');
    }

    const params = {
      access_key: accessKey,
      client_id: client.id,
      source: 'public partner sharing',
      ...commonParams,
    };

    initConnect(params);
  }

  function handleProtectedConnect() {
    createLoginToken({ client_id: client.id });
  }

  function initConnect(params: Record<string, unknown>) {
    const connectionUrl = `${AUTH_DOMAIN}/connect/${network}?${queryString(params)}`;

    if (connectionOption === ShareConnectionOption.Page) {
      window.location.href = connectionUrl;
    }

    if (connectionOption === ShareConnectionOption.Popup) {
      popup(connectionUrl, 'Connection Popup', 650, 650);
    }
  }

  function connect() {
    !currentUser ? handlePublicConnect() : handleProtectedConnect();
  }

  return connect;
};

export default useConnectAccount;
