import React, {
  ChangeEvent,
  FC,
  FormEvent,
  Fragment,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  ListSubheader,
  Portal,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { alpha } from '@mui/material/styles';
import { FieldArrayRenderProps, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { compact, includes, orderBy } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';

import theme from 'theme';

import {
  CreateGroupInput,
  CreateGroupMutation,
  Group,
  GroupContentApprovalType,
  useCreateGroupMutation,
  UserFeedFollowGroupFragment,
} from 'api';
import { useCapabilities, useFollowed, useNotify, useProtectedClient } from 'hooks';
import { GroupAvatar, PrivateGroupIcon } from 'components';
import { getCreateGroupInitialValues } from 'features/groups/helpers';
import { useCreateGroup } from 'features/groups/updaters';

import { usePostContext } from '../../PostContext';
import { GroupCreateAlert } from './index';
import { CreateGroupDialogSchema } from '../../validations';

const styles = {
  tag: {
    padding: theme.spacing(0, 0.5, 0, 1),
  },
  doneButton: {
    padding: 0,
    minWidth: 'auto',
    '&:hover': {
      background: 'transparent',
      color: 'primary.main',
    },
  },
  listSubheader: {
    padding: theme.spacing(0.5, 1, 0, 1),
    textTransform: 'none',
  },
  divider: {
    margin: theme.spacing(0.5, 0),
  },

  createGroupButton: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    border: 'none',
    borderRadius: 0.5,
    padding: theme.spacing(1.5, 2),
    marginRight: 1.5,
  },
  listOption: {
    paddingRight: 3,
    cursor: 'pointer',
    margin: theme.spacing(0, 0.5),
  },
} as const;

const AutocompleteStyles = {
  '.MuiAutocomplete-paper': {
    margin: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  '.MuiAutocomplete-inputRoot': {
    backgroundColor: theme.palette.background.paper,
    flexWrap: 'nowrap',
  },
  '.MuiAutocomplete-input': {},
  '.MuiAutocomplete-option': {
    padding: theme.spacing(0.5, 0, 0.5, 1),

    '&:hover, &[aria-selected="true"]': {
      background: 'transparent',
    },

    '& > button:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.4),
    },
  },
  '.MuiAutocomplete-listbox': {
    margin: 0,
    padding: 0,
    '& li[data-focus="true"]': {
      background: 'transparent',
    },
  },
} as const;

interface IProps {
  selectedIds: string[];
  helpers: FieldArrayRenderProps;
  primaryGroupId: string;
}

const filter = createFilterOptions();

const GroupDropdown: FC<IProps> = ({ helpers, selectedIds, primaryGroupId }) => {
  const { isAdmin, isModerator } = useCapabilities();
  const { allow_group_creation } = useProtectedClient();
  const { t } = useTranslation();
  const { state } = usePostContext();
  const { postable, moderated, owned } = useFollowed();
  const notify = useNotify();

  const createGroupFormikRef = useRef<FormikProps<CreateGroupInput>>(
    {} as FormikProps<CreateGroupInput>
  );

  const { recent, list } = postable;

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [groupData, setGroupData] = useState({
    multiGroupList: [],
    sortedGroups: [],
    tempSelectedGroups: [],
  });
  const [renderGroupCreateModal, setRenderGroupCreatModal] = useState(false);

  const createGroupData = {
    id: 'create-group',
    title: t('groups:createGroup'),
    color_map: {
      label: '',
      hex: '',
    },
    allow_user_posts: false,
    is_private: false,
  };

  useEffect(() => {
    const updatedList = list.filter(
      group =>
        group.id !== primaryGroupId ||
        group?.content_approval_type !== GroupContentApprovalType.FourEyes
    );
    const sortedGroups = getSortedGroups(updatedList);
    setGroupData({
      multiGroupList: updatedList,
      sortedGroups: sortedGroups,
      tempSelectedGroups: getSelectedGroups(sortedGroups),
    });
  }, [list, primaryGroupId]);

  const createGroupMutationOptions = useCreateGroup();
  const { mutate: createGroupMutation } = useCreateGroupMutation({
    ...createGroupMutationOptions,
    onError: handleCreateGroupError,
    onSuccess: handleCompleted,
  });

  function handleCreateGroupError(error: Error) {
    setRenderGroupCreatModal(false);

    if (error.message.includes('duplicate')) {
      notify.error({ i18n: t('groups:groupExistErrorMessage') });
    }

    notify.mutationError();
  }

  function handleCompleted({ createGroup }: CreateGroupMutation) {
    setRenderGroupCreatModal(false);

    const message = t('groups:createGroupSuccess', { name: createGroup.title });

    notify.info({ message });

    onClickGroupItem(createGroup);
  }

  function isGroupSelected(group: UserFeedFollowGroupFragment) {
    return selectedIds.includes(group.id);
  }

  function getSelectedGroups(sortedGroups: UserFeedFollowGroupFragment[]) {
    return selectedIds.map(id => sortedGroups.find(item => item.id === id));
  }

  function getGroupsForModerator() {
    return state.moderatorMode
      ? [...moderated, ...owned].filter(
          group =>
            group.id !== primaryGroupId ||
            group?.content_approval_type !== GroupContentApprovalType.FourEyes
        )
      : groupData.multiGroupList;
  }

  function getSortedGroups(nextSortedGroups: UserFeedFollowGroupFragment[]) {
    nextSortedGroups = nextSortedGroups.filter(
      group => group.content_approval_type !== GroupContentApprovalType.FourEyes
    );

    if (isModerator && !isAdmin) {
      nextSortedGroups = getGroupsForModerator();
    }

    return orderBy(nextSortedGroups, group => selectedIds.includes(group.id), ['desc']);
  }

  function getPlaceholder() {
    if (isOpen) {
      return t('posting:searchGroups');
    }

    if (!groupData.tempSelectedGroups.length) {
      return t('posting:selectMultiGroup');
    }

    return '';
  }

  function onToggleCreateGroupDialog() {
    setRenderGroupCreatModal(!renderGroupCreateModal);
  }

  function onClickToggle() {
    setIsOpen(!isOpen);
    setInputValue('');
    const sortedGroups = getSortedGroups(groupData.multiGroupList);
    setGroupData({
      ...groupData,
      sortedGroups: sortedGroups,
      tempSelectedGroups: getSelectedGroups(sortedGroups),
    });
  }

  function onChangeInput(event: ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  function onClickGroupItem(group: UserFeedFollowGroupFragment) {
    const currentIndex = selectedIds.findIndex(id => id === group.id);
    currentIndex >= 0 ? helpers.remove(currentIndex) : helpers.push(group.id);
  }

  function onSubmitCreateGroup() {
    setRenderGroupCreatModal(false);
    const { title } = createGroupFormikRef.current.values;

    if (!title) {
      return;
    }

    // TODO: This is a hack that needs to be fixed
    const initialValues = getCreateGroupInitialValues();
    initialValues.title = title;

    createGroupMutation({ data: initialValues });
  }

  function renderAvatar(group: UserFeedFollowGroupFragment) {
    return <GroupAvatar group={group as Group} />;
  }

  function renderCreateGroupOption() {
    const onClick = () => {
      createGroupFormikRef.current.values.title = inputValue;
      const groupTitles = groupData.multiGroupList.map(item => item.title);
      if (inputValue !== '' && !includes(groupTitles, inputValue)) {
        return onSubmitCreateGroup();
      }
      return setRenderGroupCreatModal(true);
    };

    return (
      <Button
        variant="contained"
        size="large"
        color="primary"
        fullWidth
        onClick={onClick}
        sx={{ root: styles.createGroupButton }}
      >
        <Typography variant="body2">
          {inputValue ? `${t('groups:createGroup')}: "${inputValue}"` : t('groups:createGroup')}
        </Typography>
      </Button>
    );
  }

  function renderOptions(props: HTMLAttributes<HTMLLIElement>, group: UserFeedFollowGroupFragment) {
    const isSelected = isGroupSelected(group);
    const isPrimaryGroup = primaryGroupId === group.id;
    if (group.id === 'create-group') {
      if (!isAdmin && !allow_group_creation) {
        return null;
      }
      return renderCreateGroupOption();
    }
    // Do not render the primary group in the second group dropdwon.
    if (isPrimaryGroup) {
      return null;
    }
    return (
      <Grid
        data-testid="dropdownOption"
        key={group.id}
        container
        alignItems="center"
        onClick={() => onClickGroupItem(group)}
        sx={styles.listOption}
      >
        <Grid xs={2}>{renderAvatar(group)}</Grid>
        <Grid xs={9} container>
          {group.is_private && (
            <Box mr={0.5}>
              <PrivateGroupIcon group={group} type="list" />
            </Box>
          )}
          <Grid xs>
            <Typography title={group.title} noWrap variant={isSelected ? 'body2' : 'body1'}>
              {group.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={1}>
          <Checkbox checked={isSelected} color="primary" />
        </Grid>
      </Grid>
    );
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    let { startAdornment, endAdornment } = params.InputProps;
    if (isOpen) {
      startAdornment = <SearchIcon />;
      endAdornment = (
        <Button sx={styles.doneButton} onClick={onClickToggle} fullWidth={false}>
          {t('common:done')}
        </Button>
      );
    }

    return (
      <TextField
        {...params}
        value={inputValue}
        variant="outlined"
        placeholder={getPlaceholder()}
        onChange={onChangeInput}
        fullWidth
        InputProps={{
          ...params.InputProps,
          sx: {
            input: {
              color: groupData.tempSelectedGroups.length ? 'common.black' : 'primary.main',
              '&::placeholder': {
                opacity: 1, // Fixes issue with placeholder looking faded
              },
              fontWeight: groupData.tempSelectedGroups.length
                ? theme.typography.fontWeightBold
                : theme.typography.fontWeightRegular,
            },
          },
          startAdornment,
          endAdornment,
        }}
      />
    );
  }

  function renderTags(value: UserFeedFollowGroupFragment[]) {
    const compactedValue = compact(value);

    if (!compactedValue.length) {
      return null;
    }

    const count = compactedValue.length - 1;
    const group = compactedValue[0];

    return (
      <Fragment>
        <Typography
          noWrap
          key={group.id}
          variant="body2"
          title={group.title}
          onClick={onClickToggle}
          sx={styles.tag}
        >
          {group.title}
        </Typography>
        {!!count && (
          <Typography variant="body2" onClick={onClickToggle}>
            {`+${count}`}
          </Typography>
        )}
      </Fragment>
    );
  }

  function groupBy(group: UserFeedFollowGroupFragment) {
    const isSelected = groupData.tempSelectedGroups.includes(group);
    const isRecentGroup = recent.includes(group);

    if (isSelected) {
      return t('posting:postingTo');
    }

    if (isRecentGroup) {
      return t('posting:recentGroups');
    }

    return t('posting:otherGroups');
  }

  function renderGroup(params: AutocompleteRenderGroupParams) {
    return (
      <Fragment key={params.key}>
        <ListSubheader key={params.key} disableSticky sx={styles.listSubheader}>
          {params.group}
        </ListSubheader>
        {params.children}
        <Divider sx={styles.divider} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Autocomplete
        sx={AutocompleteStyles}
        open={isOpen}
        onOpen={onClickToggle}
        onClose={onClickToggle}
        openOnFocus
        multiple
        fullWidth
        disableClearable
        disableCloseOnSelect
        forcePopupIcon={!isOpen}
        options={groupData.sortedGroups}
        getOptionLabel={(group: UserFeedFollowGroupFragment) => {
          if (typeof group === 'string') {
            return group;
          } else {
            return group?.title || '';
          }
        }}
        renderOption={renderOptions}
        renderInput={renderInput}
        renderTags={renderTags}
        value={groupData.tempSelectedGroups}
        inputValue={inputValue}
        groupBy={groupBy}
        renderGroup={renderGroup}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return [...filtered, createGroupData] as UserFeedFollowGroupFragment[];
        }}
      />

      <Portal>
        <Formik
          validateOnMount
          initialValues={getCreateGroupInitialValues()}
          onSubmit={onSubmitCreateGroup}
          validationSchema={CreateGroupDialogSchema(groupData.multiGroupList)}
          innerRef={ref => (createGroupFormikRef.current = ref)}
        >
          {() => (
            <form
              onSubmit={(event: FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                return false;
              }}
            >
              <GroupCreateAlert
                isOpen={renderGroupCreateModal}
                onCancel={onToggleCreateGroupDialog}
              />
            </form>
          )}
        </Formik>
      </Portal>
    </Fragment>
  );
};

export default GroupDropdown;
