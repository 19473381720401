export const NODE_ENV = process.env.NODE_ENV;
export const BASE_URL = process.env.BASE_URL;
export const DOMAIN = process.env.DOMAIN;
export const STRICT = process.env.STRICT;

// Analytics Services
export const SEGMENT_ID = process.env.SEGMENT_ID;
export const CORALOGIX_PUBLIC_KEY = process.env.CORALOGIX_PUBLIC_KEY;
export const CORALOGIX_APP = process.env.CORALOGIX_APP;

// Application Services
export const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID;
export const FACEBOOK_CLIENT_ID = process.env.FACEBOOK_CLIENT_ID;
export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;

// AWS
export const AWS_REGION = process.env.AWS_REGION;

// AppSync
export const PROTECTED_GRAPHQL_ENDPOINT = process.env.PROTECTED_GRAPHQL_ENDPOINT;
export const PROTECTED_GRAPHQL_ENDPOINT_REALTIME = process.env.PROTECTED_GRAPHQL_ENDPOINT_REALTIME;
export const PROTECTED_API_ID = process.env.PROTECTED_API_ID;
export const PUBLIC_GRAPHQL_ENDPOINT = process.env.PUBLIC_GRAPHQL_ENDPOINT;
export const PUBLIC_API_ID = process.env.PUBLIC_API_ID;

// Cognito
export const USER_POOL_ID = process.env.USER_POOL_ID;
export const USER_POOL_WEB_CLIENT_ID = process.env.USER_POOL_WEB_CLIENT_ID;
export const IDENTITY_POOL_ID = process.env.IDENTITY_POOL_ID;
export const OAUTH_DOMAIN = process.env.OAUTH_DOMAIN;

// Connections Service
export const AUTH_DOMAIN = process.env.AUTH_DOMAIN;

// Media
export const IMGIX_HOST = process.env.IMGIX_HOST;
export const IMAGES_BUCKET = process.env.IMAGES_BUCKET;
export const VIDEO_HOST = process.env.VIDEO_HOST;
export const VIDEOS_BUCKET = process.env.VIDEOS_BUCKET;

// CSV
export const CSV_BUCKET = process.env.CSV_BUCKET;

// Braze
export const BRAZE_API_KEY = process.env.BRAZE_API_KEY;
export const BRAZE_SDK_ENDPOINT = process.env.BRAZE_SDK_ENDPOINT;

// Safari Web Push
export const SAFARI_WEBSITE_PUSH_ID = process.env.SAFARI_WEBSITE_PUSH_ID;

// USERWAY
export const USERWAY_ACCOUNT_ID = process.env.USERWAY_ACCOUNT_ID;

// SSO
export const SSO_DOMAIN = process.env.SSO_DOMAIN;
