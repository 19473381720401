import React, { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  AppBarProps,
  Button,
  Unstable_Grid2 as Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { useNavigate } from 'react-router-dom';

import { ShareType } from 'api';
import { useContent } from 'hooks';
import { Buttons, UserAvatarGroup } from 'components';
import { getFullName } from 'helpers';

import Schedule from './Schedule';
import { Share } from '../types';

const Footer: FC<AppBarProps> = ({ position }) => {
  const content = useContent();
  const navigate = useNavigate();

  const [schedulerOpen, setSchedulerOpen] = useState(false);
  const [sentToScheduler, setSentToScheduler] = useState(false);
  const { t } = useTranslation();
  const { values, isValid, isSubmitting, submitForm, setSubmitting, setFieldValue } =
    useFormikContext<Share>();

  useEffect(() => {
    if (sentToScheduler) {
      setSubmitting(true);
    }
  });

  const sharedByCount = content?.shared_by?.length || null;

  async function handleShareNow() {
    setSubmitting(true);
    await submitForm();
  }

  function handleScheduleShare() {
    setSchedulerOpen(!schedulerOpen);
  }

  async function handleSendToScheduler() {
    if (sentToScheduler) {
      return;
    }
    values.items.forEach((item, index) => {
      setFieldValue(`items[${index}].share_type`, ShareType.Auto);
    });

    setSentToScheduler(true);
    await submitForm();
  }

  function renderSharedByText() {
    const firstSharedBy = getFullName(content.shared_by[0]);

    if (sharedByCount === 1) {
      return t('sharing:oneSharedThis', { user1: firstSharedBy });
    }

    const secondSharedBy = getFullName(content.shared_by[1]);

    if (sharedByCount === 2) {
      return t('sharing:twoSharedThis', { user1: firstSharedBy, user2: secondSharedBy });
    }

    return t('sharing:moreSharedThis', {
      user1: firstSharedBy,
      user2: secondSharedBy,
      count: sharedByCount - 2,
    });
  }

  function onClose() {
    navigate(-1);
  }

  return (
    <AppBar component="footer" position={position} sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar>
        <Grid xs={12} container alignItems="center" justifyContent="space-between">
          <Grid container alignItems="center">
            {sharedByCount && (
              <Fragment>
                <Grid marginRight={2}>
                  <UserAvatarGroup users={content.shared_by} />
                </Grid>
                <Grid xs>
                  <Typography color="textSecondary">{renderSharedByText()}</Typography>
                </Grid>
              </Fragment>
            )}
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid>
              <Button onClick={onClose} size="large">
                {t('common:cancel')}
              </Button>
            </Grid>
            <Grid>
              <Buttons.SubAction
                disabled={!isValid || isSubmitting}
                onClickButton={handleShareNow}
                name={t('components:shareNow')}
              >
                <MenuList>
                  <MenuItem onClick={handleScheduleShare} disabled={schedulerOpen}>
                    <ListItemIcon>
                      <ScheduleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                      primary={t('sharing:scheduleShare')}
                    />
                  </MenuItem>
                  <MenuItem onClick={handleSendToScheduler} disabled={sentToScheduler}>
                    <ListItemIcon>
                      <AddToPhotosIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                      primary={t('sharing:sendToScheduler')}
                    />
                  </MenuItem>
                </MenuList>
              </Buttons.SubAction>
              <Schedule open={schedulerOpen} onClose={() => setSchedulerOpen(false)} />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
