import React, { FC, ReactNode } from 'react';

import { LoaderTypes } from 'types/app';
import { ClientDisclosureList, useGetAllClientDisclosuresQuery } from 'api';
import { QueryManager } from 'components';

interface IProps {
  loader?: LoaderTypes;
  children: (data: ClientDisclosureList) => ReactNode;
}

const GetAllClientDisclosures: FC<IProps> = ({ children, loader }) => {
  const queryResult = useGetAllClientDisclosuresQuery({ data: {} });

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.getAllClientDisclosures) {
          return null;
        }

        return children(data.getAllClientDisclosures);
      }}
    </QueryManager>
  );
};

export default GetAllClientDisclosures;
