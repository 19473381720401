import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { useContent, useGlobalClient } from 'hooks';
import { SocialButton } from 'components/Buttons';
import { Events } from 'analytics';

import { ExternalLink } from './index';
import { getNetwork } from '../helpers';

const Engage: FC = () => {
  const content = useContent();
  const client = useGlobalClient();
  const { t } = useTranslation();

  const network = getNetwork(content);
  const networkLowercase = network.toLowerCase();

  if (!content.is_engageable) {
    return null;
  }

  function handleTracking() {
    const searchParams = new URLSearchParams(location.search);

    const properties = {
      client_id: client.id,
      content_id: content.content_id,
      linkedin: networkLowercase === 'linkedin',
      twitter: networkLowercase === 'twitter',
      facebook: networkLowercase === 'facebook',
      instagram: networkLowercase === 'instagram',
      youtube: networkLowercase === 'youtube',
      referral_source: searchParams.get('es_source'),
    };

    window?.analytics?.track(Events.PublicPostEngageClicked, properties);
  }

  return (
    <Box sx={{ flexGrow: 1, marginBottom: 2 }}>
      <ExternalLink>
        <SocialButton
          onClick={handleTracking}
          network={networkLowercase}
          text={t(`common:engage`)}
          fullWidth
        />
      </ExternalLink>
    </Box>
  );
};

export default Engage;
