import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';

import { Loaders } from 'components';

interface IProps {
  working: boolean;
}

const Submit: FC<IProps> = ({ working }) => {
  const { isValid } = useFormikContext();

  return (
    <Button
      disabled={!isValid || working}
      color="primary"
      size="large"
      type="submit"
      variant="contained"
    >
      {working ? <Loaders.Standard height={26} /> : 'Save Client'}
    </Button>
  );
};

export default Submit;
