import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  PaperProps,
  Typography,
} from '@mui/material';

import { UserFeedFollowGroupFragment } from 'api';
import { GroupAvatar } from '../';
import { generateLink } from 'Links';
import PaddedPaper from '../PaddedPaper';
import theme from 'theme';

interface Props {
  title: string | ReactNode;
  results: UserFeedFollowGroupFragment[];
  maxResults: number;
  elevation: PaperProps['elevation'];
}

const GroupsWidget: FC<Props> = props => {
  const { title, results, maxResults, elevation } = props;
  const { t } = useTranslation();

  function renderGroup(group: UserFeedFollowGroupFragment) {
    if (!group) {
      return null;
    }

    return (
      <ListItem key={group.id} disableGutters>
        <ListItemAvatar>
          <GroupAvatar link group={group} size={40} square />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Link
              noWrap
              variant="subtitle2"
              display="block"
              title={group.title}
              component={generateLink(`/group/${group.id}`)}
              underline="hover"
            >
              {group.title}
            </Link>
          }
          secondary={
            <Typography color="textSecondary" noWrap>
              {t('components:xMember', { count: group.follower_count })}
            </Typography>
          }
        />
      </ListItem>
    );
  }

  return (
    <PaddedPaper elevation={elevation} sx={{ padding: '16px' }}>
      <Typography
        paragraph
        color="textSecondary"
        variant="subtitle2"
        fontWeight={theme.typography.fontWeightBold}
      >
        {title}
      </Typography>
      <List>{results.slice(0, maxResults).map(result => renderGroup(result))}</List>
    </PaddedPaper>
  );
};

export default GroupsWidget;
