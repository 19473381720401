import {
  useInfiniteQuery as useReactQueryInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
} from 'react-query';

import { transport } from '../protected-transport';

interface IUseQueryFn<
  TData extends Record<string, unknown>,
  TVariables extends Record<string, unknown>
> {
  (variables: TVariables, options?: UseQueryOptions<TData>): unknown;
  document: string;
  getKey: (variables: TVariables) => unknown[];
}

function useInfiniteQuery<
  TData extends Record<string, unknown>,
  TVariables extends Record<string, unknown>
>(
  useQuery: IUseQueryFn<TData, TVariables>,
  getVariables: ({ pageParam }: { pageParam?: unknown }) => TVariables,
  options?: UseInfiniteQueryOptions<TData, Error>
): UseInfiniteQueryResult<TData, Error> {
  return useReactQueryInfiniteQuery<TData, Error>(
    useQuery.getKey(getVariables({})),
    async ({ pageParam }) =>
      transport<TData, TVariables>(useQuery.document, getVariables({ pageParam }))(),
    options
  );
}

export default useInfiniteQuery;
