import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { ReportingFilter, SharesSortFields, SortDirections, SortedShare } from 'api';
import { Numeric, Queries, TableCellWithOverflow } from 'components';
import { useCapabilities, usePrevious, useScrollToRef } from 'hooks';
import { generateLink } from 'Links';

import { MixedHeadCell } from '../types';
import {
  BrandCell,
  DateCell,
  EmptyStatRow,
  SortableHeadCell,
  TableLoader,
  TablePagination,
} from '../shared';
import { ROWS_PER_PAGE_OPTIONS } from '../constants';
import useStyles from '../analyticsStyles';

interface IProps {
  startDate: string;
  endDate: string;
  filter: ReportingFilter;
}

const ShareDashboardStatTable: FC<IProps> = ({ startDate, endDate, filter }) => {
  const classes = useStyles;
  const hasMounted = usePrevious('hasMounted');
  const { t } = useTranslation();
  const { ref, scrollTo } = useScrollToRef();
  const { needs } = useCapabilities();

  const [sortValue, setSortValue] = useState(SharesSortFields.FirstSharedAt);
  const [sortDirection, setSortDirection] = useState(SortDirections.Desc);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const hasImpressions = needs('Admin.Impressions.View.Access');
  const variables = {
    start_date: startDate,
    end_date: endDate,
    sort_direction: sortDirection,
    per_page: rowsPerPage,
    sort: sortValue,
    snapshot: false,
    filter,
    page,
  };

  const headCellsWithImpressions: MixedHeadCell[] = [
    {
      id: SharesSortFields.Title,
      align: 'inherit',
      label: t('common:post'),
      initialSort: SortDirections.Asc,
      width: 300,
    },
    {
      id: SharesSortFields.FirstSharedAt,
      align: 'inherit',
      label: t('common:date'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: 'users',
      align: 'inherit',
      label: t('analytics:shared_by'),
      width: 150,
    },
    {
      id: SharesSortFields.ApprovedBy,
      align: 'inherit',
      label: t('analytics:approved_by'),
      initialSort: SortDirections.Asc,
      width: 150,
    },
    {
      id: SharesSortFields.Shares,
      align: 'right',
      label: t('common:share_plural'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: 'impressions',
      align: 'right',
      label: t('common:impression_plural'),
      width: 'auto',
    },
    {
      id: SharesSortFields.Clicks,
      align: 'right',
      label: t('common:click_plural'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: SharesSortFields.Engagements,
      align: 'right',
      label: t('common:engagement_plural'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: SharesSortFields.Branded,
      align: 'center',
      label: t('common:branded'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
  ];
  const headCells: MixedHeadCell[] = headCellsWithImpressions.filter(cell => {
    if (!hasImpressions) {
      return cell.id !== 'impressions';
    }

    return true;
  });

  useEffect(() => {
    hasMounted && scrollTo();
  }, [page, rowsPerPage]);

  function handleSort(id: SharesSortFields, direction: SortDirections) {
    setPage(0);
    setSortValue(id);
    setSortDirection(direction);
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function renderHeadCell(cellProps: MixedHeadCell) {
    if ('initialSort' in cellProps) {
      return (
        <SortableHeadCell
          key={cellProps.id}
          {...cellProps}
          onSort={handleSort}
          sortValue={sortValue}
          sortDirection={sortDirection}
        />
      );
    }

    return (
      <TableCell
        align={cellProps.align}
        key={cellProps.id}
        style={{ width: cellProps.width }}
        size="small"
      >
        {cellProps.label}
      </TableCell>
    );
  }

  function renderStat(sortedShare: SortedShare, index: number) {
    const sortedUsers = [...sortedShare.users].sort();
    return (
      <TableRow sx={{ root: classes.tableBodyRow }} key={index}>
        <TableCellWithOverflow>
          <Link
            noWrap
            target="_blank"
            title={sortedShare.title}
            component={generateLink(`/content/${sortedShare.content_id}`)}
            variant="body2"
            underline="hover"
          >
            {sortedShare.title}
          </Link>
        </TableCellWithOverflow>
        <DateCell dateValue={sortedShare.first_shared_at} />
        <TableCellWithOverflow>
          <Typography title={sortedUsers.join(', ')} noWrap>
            {sortedUsers[0]}
          </Typography>
        </TableCellWithOverflow>
        <TableCell>
          <Typography>{sortedShare.approved_by}</Typography>
        </TableCell>
        <TableCell align="right">
          <Numeric value={sortedShare.shares} />
        </TableCell>
        {hasImpressions && (
          <TableCell align="right">
            <Numeric value={sortedShare.impressions} />
          </TableCell>
        )}
        <TableCell align="right">
          <Numeric value={sortedShare.clicks} />
        </TableCell>
        <TableCell align="right">
          <Numeric value={sortedShare.engagements} />
        </TableCell>
        <BrandCell align="center" brandValue={sortedShare.branded} />
      </TableRow>
    );
  }

  function renderEmpty() {
    return <EmptyStatRow colSpan={headCells.length} />;
  }

  return (
    <Fragment>
      <Typography ref={ref} paragraph variant="h5" color="textSecondary">
        {t('common:share_plural')}
      </Typography>
      <Queries.AnalyticsSortedTable type="sortedShares" variables={variables}>
        {({ loading, stats }) => (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>{headCells.map(renderHeadCell)}</TableRow>
              </TableHead>
              <TableBody>
                {loading && <TableLoader rows={rowsPerPage} colSpan={headCells.length} />}
                {!loading && (stats.length ? stats.map(renderStat) : renderEmpty())}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    colSpan={headCells.length}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    disabled={!stats.length || stats.length < rowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Queries.AnalyticsSortedTable>
    </Fragment>
  );
};

export default ShareDashboardStatTable;
