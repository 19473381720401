import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, GlobalStyles, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

import { getAppImage } from 'helpers';
import { APP_IMAGES } from 'app-constants';
import { Layouts } from './index';
import theme from 'theme';

interface Props {
  type?: keyof Errors;
}

interface Error {
  title: string;
  subtitle: string;
  image: string;
}

interface Errors {
  'not-found': Error;
  'no-client': Error;
  'no-user': Error;
  general: Error;
}

const styles = {
  root: {
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    minHeight: 600,
    [theme.breakpoints.down('lg')]: {
      backgroundImage: 'none !important',
    },
  },
  button: {
    borderRadius: theme.spacing(0.5),
  },
} as const;

const globalStyles = {
  body: {
    backgroundColor: theme.palette.common.white,
  },
};

const errors: Errors = {
  'not-found': {
    title: 'errors:notFound',
    subtitle: 'errors:notFoundSubheader',
    image: APP_IMAGES.Error3,
  },
  'no-client': {
    title: 'errors:noClient',
    subtitle: 'errors:noClientSubheader',
    image: APP_IMAGES.Error1,
  },
  'no-user': {
    title: 'errors:noUser',
    subtitle: 'errors:noUserSubheader',
    image: APP_IMAGES.Error2,
  },
  general: {
    title: 'errors:unknown',
    subtitle: 'errors:unknownSubheader',
    image: APP_IMAGES.Error3,
  },
};

const ErrorPage: FC<Props> = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const backgroundStyle = {
    backgroundImage: `url(${getAppImage(errors[type].image).src})`,
  };

  function onClickBack() {
    navigate(-1);
  }

  function renderMain() {
    return (
      <Fragment>
        <GlobalStyles styles={globalStyles} />
        <Box sx={styles.root} style={backgroundStyle}>
          <Grid container xs={12} md={5} spacing={3} wrap="nowrap" direction="column">
            <Grid>
              <Typography variant="h3">{t(errors[type].title)}</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">{t(errors[type].subtitle)}</Typography>
            </Grid>
            <Grid>
              <Button startIcon={<ArrowBackIosIcon fontSize="small" />} onClick={onClickBack}>
                {t('errors:back')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    );
  }

  return <Layouts.App1Column main={renderMain()} />;
};

ErrorPage.defaultProps = {
  type: 'general',
};

export default ErrorPage;
