import React, { FC, Fragment } from 'react';
import { useFormikContext } from 'formik';
import { Button, Unstable_Grid2 as Grid, Typography } from '@mui/material';

import { S3ObjectInput } from 'api';
import { AspectRatios } from 'types/app';
import { ClientLogo, IUploader, UploadDialog, Uploader, UploadInput } from 'components';

import { ClientInput } from '../../types';

const Logos: FC = () => {
  const { touched, errors, values, setFieldValue } = useFormikContext<ClientInput>();
  const { image } = values;
  const error = touched.image && errors.image;

  function handleSave(imageUpload: S3ObjectInput) {
    setFieldValue('image', imageUpload);
  }

  return (
    <Fragment>
      <Grid container>
        <Grid xs={3}>
          <Typography variant="subtitle2">Logos</Typography>
        </Grid>
        <Grid xs>
          {!!error && (
            <Typography variant="body2" color="error">
              Image is Required
            </Typography>
          )}
          <Uploader onSave={handleSave}>
            {(uploaderProps: IUploader) => (
              <Fragment>
                <Grid container spacing={4} wrap="nowrap" direction="column">
                  <Grid>
                    <Typography variant="body2">Brand logo (min width = 600px):</Typography>
                  </Grid>
                  {!!image && !!image.key && (
                    <Grid>
                      <UploadInput onUpload={uploaderProps.onUpload}>
                        <ClientLogo height={50} disableMargin client={values} />
                      </UploadInput>
                    </Grid>
                  )}
                  <Grid>
                    <UploadInput onUpload={uploaderProps.onUpload}>
                      <Button component="span" variant="outlined">
                        Upload Image
                      </Button>
                    </UploadInput>
                  </Grid>
                </Grid>
                <UploadDialog
                  i18nKeyTitle="clientSuccess:brandLogo"
                  i18nKeyReplace="components:replaceImage"
                  aspectRatio={AspectRatios.none}
                  {...uploaderProps}
                />
              </Fragment>
            )}
          </Uploader>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Logos;
