import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import { Button, ButtonProps } from '@mui/material';
import CheckMark from '@mui/icons-material/Check';

import { CustomSVG, Loaders } from 'components';
import { Network } from 'api/public';
import { getClientCommentCharLimit } from 'features/posting/helpers';
import { usePublicPostContext } from '../PublicPostContext';
import { useGlobalClient } from 'hooks';
import { Variations } from '../types';
import theme from 'theme';

interface Props extends ButtonProps {
  network: Network;
  hasShared?: boolean;
  isLight?: boolean;
  isLoading?: boolean;
  fieldName: string;
}

const Share: FC<Props> = ({ fieldName, network, hasShared, isLoading, ...rest }) => {
  const { t } = useTranslation();
  const client = useGlobalClient();

  const color = theme.custom.networks[network];

  const { handleSubmit, values } = useFormikContext<Variations>();
  const { messages } = values;

  const { state } = usePublicPostContext();
  const { content } = state;

  const [, meta] = useField(fieldName);
  const limit = getClientCommentCharLimit(network, content, client);
  const messageOverLimit = (messages || [])[0]?.commentary?.length > limit;
  const disabled = hasShared || messageOverLimit;
  const hasError = !!meta.error;

  function renderContent() {
    if (isLoading) {
      return <Loaders.Standard height={26} width={64} />;
    }

    if (hasShared) {
      return t('common:shared');
    }

    return t('content:share');
  }

  function renderIcon() {
    if (isLoading) {
      return null;
    }

    if (hasShared) {
      return <CheckMark fontSize="large" />;
    }

    return <CustomSVG name="share" size={20} />;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleSubmit()}
      size="small"
      endIcon={renderIcon()}
      disabled={disabled || hasError}
      {...rest}
      sx={{ backgroundColor: color }}
    >
      {renderContent()}
    </Button>
  );
};

export default Share;
