export const NO_SECONDS = 0;
export const DAILY_SECONDS = 86400;
export const WEEKLY_SECONDS = DAILY_SECONDS * 7;
export const MONTHLY_SECONDS = WEEKLY_SECONDS * 4;
export const CONTENT_EMAIL_FALLBACK_EMAIL = 'alerts@mg.everyonesocial.com';

export const STICKER_SIZE = 136;
export const PAGINATION_LIMIT = 24;
export const MESSAGE_LIMIT = 12;
export const CONTENT_EMAIL_LIMIT = 12;

export const IMAGE_SIZE = 100;

export const CONTENT_EMAIL_RECURRING_VARIABLES = {
  data: { recurringOnly: true, limit: CONTENT_EMAIL_LIMIT },
};

export const CONTENT_EMAIL_NON_RECURRING_VARIABLES = {
  data: { recurringOnly: false, limit: CONTENT_EMAIL_LIMIT },
};

export const CONTENT_EMAIL_SENT_VARIABLES = {
  data: { limit: CONTENT_EMAIL_LIMIT },
};
