import React, { FC, Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider } from '@mui/material';

import { useContent, useGlobalClient, useSharePhoto } from 'hooks';
import { Events } from 'analytics';

import SharingThumbnails from 'features/sharing/components/Thumbnails';

import { hasCreator } from '../helpers';
import { MetaPublic, UserPublic } from '../partials';
import { Description, ExternalLink, Image, Photo, Provider, Title } from '../widgets';

const UrlPublic: FC = () => {
  const client = useGlobalClient();
  const content = useContent();
  const theme = useTheme();
  const { selectedPhotoIndex } = useSharePhoto();

  const { content_object } = content;
  const { url_metadata, photos } = content_object;

  if (!url_metadata) {
    return null;
  }

  const { title, description } = url_metadata;
  const photo = photos?.[selectedPhotoIndex];
  const hasPostCreator = hasCreator(content);
  const isDeletedUser = content?.user?.full_name === 'Deleted User';

  function handleTracking() {
    window?.analytics?.track(Events.PublicPostClicked);
  }

  function renderCardHeader() {
    const provider = (
      <ExternalLink>
        <Provider content={content} />
      </ExternalLink>
    );

    return <CardHeader title={provider} sx={{ paddingTop: theme.spacing(0.5) }} />;
  }

  return (
    <Card
      sx={{
        marginBottom: 3,
        padding: theme.spacing(),
        [theme.breakpoints.down('xl')]: {
          marginBottom: 0,
          marginTop: 0,
        },
      }}
    >
      {hasPostCreator && (
        <Fragment>
          {client.public_post_details && <UserPublic isDeletedUser={isDeletedUser} />}
          <Divider />
        </Fragment>
      )}
      {renderCardHeader()}
      <ExternalLink onClick={handleTracking}>
        {photo ? <Photo disableViewer photo={photo} /> : <Image content={content} />}
      </ExternalLink>

      <SharingThumbnails images={photos} />

      <CardContent>
        <ExternalLink onClick={handleTracking}>
          <Title title={title} />
          <Description description={description} />
        </ExternalLink>
      </CardContent>
      <MetaPublic />
    </Card>
  );
};

export default UrlPublic;
