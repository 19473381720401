import React, { FC } from 'react';
import { Box, Card } from '@mui/material';

import { ContentLayoutProps } from 'types/app';
import { useContent } from 'hooks';
import { ReactionType, useAddReactionAnalyticsMutation } from 'api';

import { getVideo, hasPhoto, hasVideo } from '../helpers';
import { ContentActions, User } from '../partials';
import { Images, Important, Video } from '../widgets';

const styles = {
  root: {
    marginBottom: 3,
    border: 'none',
  },
} as const;

const Manual: FC<ContentLayoutProps> = ({ selectedGroup }) => {
  const content = useContent();

  const { content_id } = content;
  const { mutate: addReactionAnalytics } = useAddReactionAnalyticsMutation();
  const isDeletedUser = content?.user?.full_name === 'Deleted User';

  function onClickContent() {
    addReactionAnalytics({
      content_id,
      reaction_type: ReactionType.Click,
    });
  }

  function getContent() {
    if (hasVideo(content)) {
      const video = getVideo(content);
      return <Video video={video} onClickReaction={onClickContent} />;
    }

    if (hasPhoto(content)) {
      return <Images onClick={onClickContent} />;
    }

    return null;
  }

  return (
    <Card sx={styles.root}>
      <Important />
      <User selectedGroup={selectedGroup} isDeletedUser={isDeletedUser} />
      <Box marginBottom={4}>{getContent()}</Box>
      <ContentActions selectedGroup={selectedGroup} />
    </Card>
  );
};

export default Manual;
