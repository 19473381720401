import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMatch } from 'react-router-dom';
import { find } from 'lodash';

import { GetFeedQueryVariables } from 'api';
import { CustomSVG } from 'components';
import { useContent, useQueryVariables } from 'hooks';

import * as paths from 'paths';

const Pin: FC = () => {
  const content = useContent();
  const theme = useTheme();

  const variables = useQueryVariables<GetFeedQueryVariables>();

  const isGroupFeed = !!useMatch(paths.group);

  function isPinnedToGroup() {
    const group = find(content.pinned_to_groups, ['group_id', variables.id]);
    return group && group.pinned_end_at * 1000 > Date.now();
  }

  function isPinned() {
    return content.is_pinned && content.pinned_end_at * 1000 > Date.now();
  }
  if (!isPinned() && !isPinnedToGroup()) {
    return null;
  }

  if (isGroupFeed && !isPinnedToGroup()) {
    return null;
  }

  return (
    <CustomSVG
      name="pin"
      size={16}
      sx={{ marginRight: theme.spacing(0.5), color: 'primary.main' }}
    />
  );
};

export default Pin;
