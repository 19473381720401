import React, { FC } from 'react';
import { IconButton, Slide, Toolbar, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { requestPushPermission } from '@braze/web-sdk';
import { useTranslation } from 'react-i18next';
import LaptopIcon from '@mui/icons-material/Laptop';
import CloseIcon from '@mui/icons-material/Close';

import { NotificationDeliveryType, useUpdateUserProfileMutation } from 'api';
import { useCurrentUser, useNotify } from 'hooks';
import { isSafari } from 'features/content/helpers';
import cookieStorage from 'cookie-storage';
import theme from 'theme';

const styles = {
  background: {
    width: '100%',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  text: {
    cursor: 'pointer',
    [theme.breakpoints.down('xl')]: {
      paddingRight: 5,
    },
  },
  icon: {
    verticalAlign: 'middle',
  },
  closeIcon: {
    position: 'absolute',
    padding: 0,
    right: 15,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

interface Props {
  onDismissed: () => void;
  cookieValue: string;
}

const PushNotification: FC<Props> = ({ cookieValue, onDismissed }) => {
  const { t } = useTranslation();

  const currentUser = useCurrentUser();
  const notify = useNotify();

  const { mutate: updateUserProfile } = useUpdateUserProfileMutation({
    onError: notify.mutationError,
  });

  function handleRequestPermission() {
    handleUpdateProfile();
    // Delay push permission request on chrome to avoid page refresh.
    // This ensures the update profile call above finishes in time.
    if (isSafari()) {
      requestPushPermission(() => {
        location.reload();
      });
    } else {
      setTimeout(() => {
        requestPushPermission();
      }, 1000);
    }
  }

  function handleCancel() {
    cookieStorage.pushNotification = cookieValue;
    onDismissed();
  }

  function handleUpdateProfile() {
    const data = {
      id: currentUser.id,
      notification_delivery_types: [
        ...currentUser.notification_delivery_types,
        NotificationDeliveryType.Push,
      ],
    };

    updateUserProfile({ data });
  }

  return (
    <Slide in mountOnEnter unmountOnExit>
      <Toolbar variant="dense" sx={styles.background}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs>
            <Grid container wrap="nowrap" spacing={1} alignItems="center" justifyContent="center">
              <Grid>
                <LaptopIcon color="primary" sx={styles.icon} />
              </Grid>
              <Grid>
                <Typography
                  noWrap
                  onClick={handleRequestPermission}
                  sx={styles.text}
                  variant="subtitle2"
                  color="primary"
                >
                  {t('components:pushNotification')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <IconButton onClick={handleCancel} sx={styles.closeIcon} size="large">
            <CloseIcon color="primary" />
          </IconButton>
        </Grid>
      </Toolbar>
    </Slide>
  );
};

export default PushNotification;
