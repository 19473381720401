import { USERWAY_ACCOUNT_ID } from 'settings';
import { useEffect } from 'react';

export default () => {
  const initializeUserway = () => {
    if (!USERWAY_ACCOUNT_ID) {
      return;
    }

    const script = document.createElement('script');
    script.id = USERWAY_ACCOUNT_ID;
    script.setAttribute('data-position', '5');
    script.setAttribute('data-account', USERWAY_ACCOUNT_ID);
    script.setAttribute('src', 'https://cdn.userway.org/widget.js');
    (document.body || document.head).appendChild(script);
  };

  useEffect(() => {
    const userway = document.getElementById(USERWAY_ACCOUNT_ID);

    return !userway && initializeUserway();
  }, []);
};
