import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { getAppImage } from '../helpers';
import { PaddedPaper } from './index';

interface Props {
  name: keyof IEmptyState;
  fullWidth?: boolean;
  box?: boolean;
  actionComponent?: ReactNode;
}

interface StateOptions {
  image: string;
  text: string;
}

export interface IEmptyState {
  timeline: StateOptions;
  groupJoin: StateOptions;
  groupMember: StateOptions;
  groupApproval: StateOptions;
  groupScheduled: StateOptions;
  groupRemoved: StateOptions;
  search: StateOptions;
  notificationPop: StateOptions;
  notifications: StateOptions;
  groupsRecommended: StateOptions;
  groupsFollowed: StateOptions;
  groupsOwned: StateOptions;
  groupsModerated: StateOptions;
  groupsDepartments: StateOptions;
  groupsLocations: StateOptions;
  insightsCompanies: StateOptions;
  insightsPeople: StateOptions;
  insightsEmployee: StateOptions;
  leaderboardIndex: StateOptions;
  leaderboard: StateOptions;
  peopleRecommended: StateOptions;
  peopleFollowing: StateOptions;
  peopleDepartments: StateOptions;
  peopleLocations: StateOptions;
  profileActivity: StateOptions;
  privateGroup: StateOptions;
  myProfileActivity: StateOptions;
  profileGroups: StateOptions;
  myProfileGroups: StateOptions;
  profileFollowing: StateOptions;
  myProfileFollowing: StateOptions;
  profileFollowers: StateOptions;
  myProfileFollowers: StateOptions;
  shareThese: StateOptions;
  scheduled: StateOptions;
  past: StateOptions;
  myPosts: StateOptions;
  suspendedUsers: StateOptions;
  searchUsers: StateOptions;
  messages: StateOptions;
  dashboardContent: StateOptions;
  invitations: StateOptions;
  postManagement: StateOptions;
  assignments: StateOptions;
  userManagement: StateOptions;
  contentEmailsEnabled: StateOptions;
  contentEmailsDisabled: StateOptions;
  contentEmailsSent: StateOptions;
  contentDeleted: StateOptions;
  networkHistory: StateOptions;
  externalShares: StateOptions;
}

const states: IEmptyState = {
  timeline: {
    image: 'cards.png',
    text: 'timelineText',
  },
  groupJoin: {
    image: 'cards.png',
    text: 'groupJoinText',
  },
  groupMember: {
    image: 'paper-airplane.png',
    text: 'groupMemberText',
  },
  groupApproval: {
    image: 'checkboard.png',
    text: 'groupApprovalText',
  },
  groupScheduled: {
    image: 'checkboard.png',
    text: 'groupScheduledText',
  },
  groupRemoved: {
    image: 'trash.png',
    text: 'groupRemovedText',
  },
  insightsCompanies: {
    image: 'box.png',
    text: 'insightsCompanies',
  },
  insightsPeople: {
    image: 'sofa.png',
    text: 'insightsPeople',
  },
  insightsEmployee: {
    image: 'people.png',
    text: 'insightsEmployee',
  },
  search: {
    image: 'spyglass.png',
    text: 'searchText',
  },
  notificationPop: {
    image: 'notifications.png',
    text: 'notificationPopText',
  },
  notifications: {
    image: 'notifications-2.png',
    text: 'notificationsText',
  },
  groupsRecommended: {
    image: 'flower.png',
    text: 'groupsRecommendedText',
  },
  groupsFollowed: {
    image: 'spyglass.png',
    text: 'groupsFollowedText',
  },
  groupsOwned: {
    image: 'box-cards.png',
    text: 'groupsOwnedText',
  },
  groupsModerated: {
    image: 'box-cards.png',
    text: 'groupsModeratedText',
  },
  groupsDepartments: {
    image: 'box.png',
    text: 'groupsDepartmentsText',
  },
  groupsLocations: {
    image: 'box.png',
    text: 'groupsLocationsText',
  },
  peopleRecommended: {
    image: 'rockn-roll.png',
    text: 'peopleRecommendedText',
  },
  peopleFollowing: {
    image: 'people.png',
    text: 'peopleFollowingText',
  },
  peopleDepartments: {
    image: 'pipe.png',
    text: 'peopleDepartmentsText',
  },
  peopleLocations: {
    image: 'pipe.png',
    text: 'peopleLocationsText',
  },
  profileActivity: {
    image: 'mailbox.png',
    text: 'profileActivityText',
  },
  myProfileActivity: {
    image: 'mailbox.png',
    text: 'myProfileActivityText',
  },
  profileGroups: {
    image: 'box.png',
    text: 'profileGroupsText',
  },
  myProfileGroups: {
    image: 'box.png',
    text: 'myProfileGroupsText',
  },
  profileFollowing: {
    image: 'people-2.png',
    text: 'profileFollowingText',
  },
  myProfileFollowing: {
    image: 'people-2.png',
    text: 'myProfileFollowingText',
  },
  profileFollowers: {
    image: 'people-1.png',
    text: 'profileFollowersText',
  },
  privateGroup: {
    image: 'people-1.png',
    text: 'privateGroupText',
  },
  myProfileFollowers: {
    image: 'people-1.png',
    text: 'myProfileFollowersText',
  },
  shareThese: {
    image: 'posts.png',
    text: 'shareTheseText',
  },
  scheduled: {
    image: 'schedule.png',
    text: 'scheduledText',
  },
  past: {
    image: 'posts.png',
    text: 'pastText',
  },
  myPosts: {
    image: 'cards.png',
    text: 'myPostsText',
  },
  suspendedUsers: {
    image: 'sofa.png',
    text: 'suspendedUsersText',
  },
  searchUsers: {
    image: 'spyglass.png',
    text: 'searchUsersText',
  },
  messages: {
    image: 'notifications.png',
    text: 'messagesText',
  },
  dashboardContent: {
    image: 'folder.png',
    text: 'adminDashboardContent',
  },
  leaderboardIndex: {
    image: 'spyglass.png',
    text: 'leaderboard',
  },
  leaderboard: {
    image: 'spyglass.png',
    text: 'single_leaderboard',
  },
  invitations: {
    image: 'sofa.png',
    text: 'invitationsText',
  },
  postManagement: {
    image: 'checkboard.png',
    text: 'postManagementText',
  },
  externalShares: {
    image: 'checkboard.png',
    text: 'externalSharesText',
  },
  assignments: {
    image: 'notifications-2.png',
    text: 'assignmentsText',
  },
  userManagement: {
    image: 'notifications-2.png',
    text: 'userManagementText',
  },
  contentEmailsEnabled: {
    image: 'mailbox.png',
    text: 'contentEmailsEnabled',
  },
  contentEmailsDisabled: {
    image: 'pipe.png',
    text: 'contentEmailsDisabled',
  },
  contentEmailsSent: {
    image: 'invite.png',
    text: 'contentEmailsSent',
  },
  contentDeleted: {
    image: 'spyglass.png',
    text: 'contentDeleted',
  },
  networkHistory: {
    image: 'spyglass.png',
    text: 'networkHistory',
  },
};

const styles = {
  img: {
    objectFit: 'contain',
  },
} as const;

const EmptyState: FC<Props> = ({ name, fullWidth, actionComponent }) => {
  const { image, text }: StateOptions = states[name];
  const { t } = useTranslation();

  return (
    <PaddedPaper sx={fullWidth ? { maxWidth: '100%' } : {}}>
      <Grid container alignItems="center" direction="column" justifyContent="center">
        <Grid>
          <img style={styles.img} src={getAppImage(image, 200).src} alt="emptyState" />
        </Grid>

        <Grid>
          <Typography paragraph variant="subtitle2" align="center">
            {t(`emptyStates:${text}`)}
          </Typography>
        </Grid>
        <Grid>{actionComponent}</Grid>
      </Grid>
    </PaddedPaper>
  );
};

EmptyState.defaultProps = {
  fullWidth: false,
  box: false,
};

export default EmptyState;
