import { countBy, filter, find, omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  Client,
  ClientPublicFragment,
  ContentFragment,
  ContentInput,
  Network,
  S3Object,
  S3ObjectInput,
  S3VideoObjectInput,
  SharingChannelInfo,
  UrlMetadataImage,
} from 'api';
import isHTML from 'is-html';

import { AWS_REGION, IMAGES_BUCKET } from 'settings';
import { hasDisclosures, hasUrl, hasVideo } from 'features/content/helpers';
import { SPECIAL_CHARACTERS } from 'features/sharing/constants';
import { GetPublicContentQuery } from 'api/public';
import {
  DEFAULT_LIMIT,
  EMPTY_SLATE_EDITOR,
  FACEBOOK_LIMIT,
  INSTAGRAM_LIMIT,
  LINKEDIN_LIMIT,
  TWITTER_LIMIT,
  TWITTER_LIMIT_WITH_LINK,
} from 'app-constants';
import uploadFile from 'app-react-query/utilities/upload-file';

export const formatDisclosures = (content: ContentInput) => {
  if (!hasDisclosures(content)) {
    return [];
  }

  const disclosures = filter(content.disclosures, disclosure => !!disclosure.disclosure_id);
  return disclosures.map(item => omit(item, 'disclosure'));
};

export const convertUrlImageToPhoto = (image: UrlMetadataImage): S3Object => {
  return {
    bucket: image.bucket,
    key: image.file_key,
    width: image.width,
    height: image.height,
    region: AWS_REGION,
  };
};

export const foundReadOnlyGroup = (
  groupIds: string[],
  groupsRules: Record<string, { read_only: boolean }>
) => {
  let readOnlyGroupFound = false;

  if (groupIds.length === 0) {
    return readOnlyGroupFound;
  }

  groupIds.forEach(id => {
    if (groupsRules[id]?.read_only) {
      readOnlyGroupFound = true;
    }
  });

  return readOnlyGroupFound;
};

export const getSafeBody = (content: ContentInput) => {
  const { body } = content.content_object;
  return body === EMPTY_SLATE_EDITOR ? null : body;
};

export const generateRepostChannelsString = (
  channels: SharingChannelInfo[],
  repostText: string
) => {
  let channelListString = '';
  channels.forEach(channel => {
    if (channelListString === '') {
      channelListString += `#${channel.channel_name}`;
    } else {
      channelListString += `, ${channel.channel_name}`;
    }
  });
  channelListString = `${repostText} ${channelListString}`;

  return channelListString;
};

export const getClientCommentCharLimit = (
  network: Network,
  content: ContentFragment | GetPublicContentQuery['getPublicContent'],
  client: Client | ClientPublicFragment
) => {
  let limit = getCommentCharLimit(network, content);
  limit -= getContentDisclosureLength(network, content, client);
  return limit;
};

export const getCommentCharLimit = (
  network: Network,
  content: ContentFragment | GetPublicContentQuery['getPublicContent']
) => {
  let limit = 0;

  switch (network) {
    case Network.Twitter:
      limit = hasUrl(content) || hasVideo(content) ? TWITTER_LIMIT_WITH_LINK : TWITTER_LIMIT;
      break;
    case Network.Linkedin:
    case Network.LinkedinPage:
      limit = LINKEDIN_LIMIT;
      break;
    case Network.Facebook:
      limit = FACEBOOK_LIMIT;
      break;
    case Network.Instagram:
      limit = INSTAGRAM_LIMIT;
      break;
    default:
      limit = DEFAULT_LIMIT;
  }
  return limit;
};

const getContentDisclosureLength = (
  network: Network,
  content: ContentFragment | GetPublicContentQuery['getPublicContent'],
  client: Client | ClientPublicFragment
) => {
  const isLinkedIn = network === Network.Linkedin || network === Network.LinkedinPage;
  const contentDisclosure = find((content as any)?.disclosures, [
    'network',
    isLinkedIn ? Network.Linkedin : network,
  ]);
  if (!contentDisclosure) {
    return 0;
  }

  if (contentDisclosure && !client) {
    return contentDisclosure?.disclosure?.length + 1;
  }

  const clientDisclosure = find(client?.disclosures, ['id', contentDisclosure?.disclosure_id]);
  if (!clientDisclosure) {
    return 0;
  }

  return clientDisclosure?.disclosure?.length + 1 ?? contentDisclosure?.disclosure?.length + 1;
};

export const getTextLength = (network: Network, text: string) => {
  if (network !== Network.Linkedin) {
    return text.length;
  }

  function count(str: string, char: string) {
    return countBy(str)[char] || 0;
  }

  let specialCharacterCount = 0;
  SPECIAL_CHARACTERS.forEach(ch => {
    specialCharacterCount += count(text, ch);
  });

  return text.length + specialCharacterCount;
};

export const getTextContext = (text: string) => {
  if (isHTML(text)) {
    const document = new DOMParser().parseFromString(text, 'text/html');
    return document.documentElement.textContent;
  }

  return text;
};

export const uploadVideoPoster = async (blob: Blob, video: S3VideoObjectInput, userId: string) => {
  const key = `user_images/${userId}/${uuidv4()}`;
  const videoPosterInput: S3ObjectInput = {
    bucket: IMAGES_BUCKET,
    key: key,
    region: video.region,
    localUri: blob as unknown as string,
    mimeType: 'image/png',
  };

  await uploadFile(videoPosterInput);
  return key;
};

export const getVideoCanvas = (videoElement: HTMLVideoElement) => {
  const canvas = document.createElement('canvas');

  canvas.width = videoElement.videoWidth;
  canvas.height = videoElement.videoHeight;

  const context = canvas.getContext('2d');
  context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

  return canvas;
};

export const enforceHTTPS = (url: string): string => {
  if (url.startsWith('https://')) {
    return url;
  }

  if (url.startsWith('http://')) {
    return 'https://' + url.slice(7);
  }

  return 'https://' + url;
};
