import { InfiniteData, UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';
import { forEach } from 'lodash';

import {
  GetSharesItemsQuery,
  GetSharesItemsQueryVariables,
  ScheduledShareContentInput,
  UpdateScheduledSharesMutation,
  UpdateScheduledSharesMutationVariables,
  useGetSharesItemsQuery,
} from 'api';
import { useNotify } from 'hooks';
import { WritableDraft } from 'immer/dist/types/types-external';

type Mutation = UpdateScheduledSharesMutation;
type MutationVariables = UpdateScheduledSharesMutationVariables;
type QueryData = InfiniteData<GetSharesItemsQuery>;
type QueryVariables = GetSharesItemsQueryVariables;

const useUpdateSharesItems = (variables: QueryVariables) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const queryKey = useGetSharesItemsQuery.getKey(variables);

  const itemsCallback = (
    page: WritableDraft<GetSharesItemsQuery>,
    data: ScheduledShareContentInput
  ) => {
    forEach(page.getSharesItems.items, value => {
      if (value.id === data.items[0].id) {
        value.share_at = data.items[0].share_at;
        value.user_commentary = data.items[0].user_commentary;
      }
    });
  };

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async ({ data }) => {
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.pages.forEach(page => itemsCallback(page, data));
        });

      if (baseState) {
        queryClient.setQueryData(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useUpdateSharesItems;
