import React, { FC, Dispatch, createContext, useReducer } from 'react';

import { ContentFragment } from 'api';

interface FeedDuplicatesContext {
  ids: string[];
  getUniqueResults: (results: ContentFragment[]) => ContentFragment[];
}

interface ReducerAction {
  ids: string[];
}

type DispatchOptions = Dispatch<ReducerAction>;

const FeedDuplicatesContext = createContext<FeedDuplicatesContext>({
  ids: [],
  getUniqueResults: () => [],
});
const FeedDuplicatesDispatchContext = createContext<DispatchOptions>(null);

const FeedDuplicatesProvider: FC = ({ children }) => {
  const [ids, dispatch] = useReducer(addContentIds, []);

  function addContentIds(_: string[], action: ReducerAction) {
    return action.ids;
  }

  function getUniqueResults(results: ContentFragment[]) {
    const uniqueResults: ContentFragment[] = [];

    results.forEach(result => {
      if (!ids.includes(result.content_id)) {
        uniqueResults.push(result);
      }
    });

    return uniqueResults;
  }

  return (
    <FeedDuplicatesContext.Provider value={{ ids, getUniqueResults }}>
      <FeedDuplicatesDispatchContext.Provider value={dispatch}>
        {children}
      </FeedDuplicatesDispatchContext.Provider>
    </FeedDuplicatesContext.Provider>
  );
};

export { FeedDuplicatesContext, FeedDuplicatesDispatchContext, FeedDuplicatesProvider };
