import React, { createContext, FC, useContext, useState } from 'react';
import { PublicUserProfileFragment, UserFeedFollowGroupFragment } from '../api';

interface Value {
  selectedGroups: UserFeedFollowGroupFragment[];
  setSelectedGroups: (groups: UserFeedFollowGroupFragment[]) => void;
  selectedUsers: PublicUserProfileFragment[];
  setSelectedUsers: (users: PublicUserProfileFragment[]) => void;
}

export const SearchFilterContext = createContext<Value>({
  selectedGroups: [],
  setSelectedGroups: () => undefined,
  selectedUsers: [],
  setSelectedUsers: () => undefined,
});

export const SearchFilterProvider: FC = ({ children }) => {
  const [selectedGroups, setSelectedGroups] = useState<UserFeedFollowGroupFragment[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<PublicUserProfileFragment[]>([]);

  return (
    <SearchFilterContext.Provider
      value={{ selectedGroups, setSelectedGroups, selectedUsers, setSelectedUsers }}
    >
      {children}
    </SearchFilterContext.Provider>
  );
};

export const useSearchFilter = () => useContext(SearchFilterContext);
