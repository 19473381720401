import { InfiniteData, UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';
import { isEmpty } from 'lodash';

import {
  BulkUpdateContentMutation,
  BulkUpdateContentMutationVariables,
  ContentApprovalTypes,
  SearchContentQuery,
  SearchContentQueryVariables,
  useSearchContentQuery,
} from 'api';
import { useCurrentUser, useNotify } from 'hooks';

type Mutation = BulkUpdateContentMutation;
type MutationVariables = BulkUpdateContentMutationVariables;
type QueryData = InfiniteData<SearchContentQuery>;
type QueryVariables = SearchContentQueryVariables;

const useBulkContentUpdateMutation = (variables: QueryVariables) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();

  const queryKey = useSearchContentQuery.getKey(variables);

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async mutationArgs => {
      await queryClient.cancelQueries(queryKey);

      const { bulk_update } = mutationArgs;
      const { content_group_list, approval } = bulk_update;
      const contentIds = content_group_list.map(item => item.content_id);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.pages.forEach(page => {
            const { searchContent } = page;
            const { total_count, results } = searchContent;

            if (!isEmpty(contentIds)) {
              if (approval === ContentApprovalTypes.Submitted) {
                const idx = results.findIndex(result => contentIds.includes(result.content_id));

                if (idx !== -1) {
                  const auditLength = searchContent.results[idx].audit.length - 1;
                  const lastAudit = searchContent.results[idx].audit[auditLength];
                  const updatedApprovalCount = Number(lastAudit.message || 0) + 1;

                  searchContent.results[idx].audit = [
                    ...searchContent.results[idx].audit,
                    {
                      ACTION: 'four_eyes_submitted',
                      by_user_id: currentUser?.id,
                      message: updatedApprovalCount.toString(),
                    },
                  ];
                }
              }

              if (approval === ContentApprovalTypes.Approved) {
                searchContent.total_count = total_count - content_group_list.length;
                searchContent.results = results.filter(
                  result => !contentIds.includes(result.content_id)
                );
              }

              if (approval === ContentApprovalTypes.Removed) {
                searchContent.total_count = total_count - 1;
                searchContent.results = results.filter(
                  result => !contentIds.includes(result.content_id)
                );
              }
            }
          });
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useBulkContentUpdateMutation;
