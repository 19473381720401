import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';

import Flatpickr from './Flatpickr';
import theme from 'theme';

interface IProps {
  value: number;
  onChange: (time: number) => void;
}

const HEIGHT = 48.63;

const styles = {
  root: {
    '@global': {
      '.flatpickr-input': {
        display: 'none',
      },
      '.flatpickr-calendar.inline': {
        top: 0,
        boxShadow: 'none',
        width: 'auto',
      },
      '.flatpickr-time': {
        maxHeight: HEIGHT,
        border: `1px solid ${theme.palette.grey[400]}`,
      },
      '.flatpickr-time .numInputWrapper': {
        height: HEIGHT,
      },
      '.flatpickr-time .flatpickr-am-pm': {
        position: 'relative',
        top: 7,
        height: HEIGHT,
        color: 'text.primary',
      },
      '.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time': {
        borderRadius: 4,
      },
      '.flatpickr-time input': {
        color: 'text.primary',
      },
      '.flatpickr-time input.flatpickr-hour': {
        fontWeight: theme.typography.fontWeightRegular,
      },
      '.flatpickr-time .flatpickr-time-separator': {
        color: 'text.primary',
      },
      '.numInputWrapper:hover': {
        background: 'transparent',
      },
      '.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus':
        {
          background: 'transparent',
        },
    },
  },
} as const;

const Time: FC<IProps> = ({ value, onChange }) => {
  const [time, setTime] = useState(moment.unix(value).toDate());

  useEffect(() => {
    setTime(moment.unix(value).toDate());
  }, [value]);

  const options = {
    inline: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    minuteIncrement: 1,
  };

  function handleDateChange(selectedDates: Date[]) {
    const newTime = moment(selectedDates[0]).unix();
    onChange(newTime);
  }

  return (
    <Box sx={styles.root}>
      <Flatpickr value={time} onChange={handleDateChange} options={options} />
    </Box>
  );
};

export default Time;
