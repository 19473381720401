import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from '@aws-amplify/auth';

import * as paths from 'paths';
import { useGlobalClient } from 'hooks';
import { Loaders } from 'components';

import { PasswordlessToken } from '../types';
import { AUTH_ERROR_KEY, LINK_EXPIRED_MSG } from '../constants';

interface Props {
  children: ReactElement;
}

const TokenCheck: FC<Props> = ({ children }) => {
  const client = useGlobalClient();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);
  const [token, setToken] = useState(urlParams.get('token'));

  useEffect(() => {
    if (token) {
      void processToken();
    }
  }, [token]);

  async function processToken() {
    try {
      await Auth.signOut();
      const publicContent = JSON.parse(atob(token)) as PasswordlessToken;
      const cognitoUser = await Auth.signIn(publicContent.email, null);
      const clientMetadata = client ? { client_id: client.id } : { is_multi_client: 'true' };
      await Auth.sendCustomChallengeAnswer(cognitoUser, publicContent.answer, clientMetadata);
      await Auth.currentSession();
    } catch (error) {
      sessionStorage.setItem(AUTH_ERROR_KEY, LINK_EXPIRED_MSG);
      navigate(paths.authError, { replace: true });
    } finally {
      setToken(null);
    }
  }

  if (token) {
    window.history.replaceState(null, '', window.location.pathname);
    return <Loaders.Startup />;
  }

  return children;
};

export default TokenCheck;
