import { AuthenticationType, ClientPublicFragment, UserProfile, UserType } from 'api';
import cookieStorage from 'cookie-storage';

const WORKVIVO_PROVIDER = 'WorkvivoProxy';

export const shouldRedirectToWorkvivoJwtSSO = (cognitoUser: any, jwt: string) => {
  if (jwt) {
    try {
      const email = cognitoUser.authenticatedUser.attributes.email;
      const payload: any = JSON.parse(atob(jwt.split('.')[1]));
      if (payload.email !== email) {
        return true;
      }
    } catch (error) {
      console.log('Could not parse token', jwt);
    }
  }
  return false;
};

export const shouldBlockStandardCharterUser = (
  publicClient: ClientPublicFragment,
  userProfile: UserProfile
) => {
  if (
    !isRestrictedStandardCharterUser(
      publicClient.sso_configuration?.provider,
      publicClient.authentication_type,
      userProfile?.user_type
    )
  ) {
    return false;
  }

  const nextPath = cookieStorage.nextPath || '';
  const pathName = location.pathname || '';

  const isPathPermitted =
    nextPath.startsWith('/partner-') ||
    pathName.startsWith('/partner-') ||
    nextPath.startsWith('/connections') ||
    pathName.startsWith('/connections') ||
    nextPath.startsWith('/onboarding') ||
    pathName.startsWith('/onboarding');

  return !isPathPermitted;
};

const isRestrictedStandardCharterUser = (
  ssoProvider: string,
  authenticationType: AuthenticationType,
  userType: UserType
) => {
  return (
    ssoProvider === WORKVIVO_PROVIDER &&
    authenticationType == AuthenticationType.Sso &&
    [UserType.User].includes(userType)
  );
};
