import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow, Typography } from '@mui/material';

import { getAppImage } from 'helpers';

const styles = {
  img: {
    width: 175,
    height: 175,
    marginTop: 3,
  },
} as const;

interface IProps {
  colSpan: number;
}

const EmptyStatRow: FC<IProps> = ({ colSpan }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell padding="none" colSpan={colSpan} align="center">
        <img style={styles.img} {...getAppImage('folder.png')} />
        <Typography paragraph variant="subtitle2" align="center">
          {t('emptyStates:analyticsOverview')}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default EmptyStatRow;
