import React, { FC, ReactNode } from 'react';
import { Text } from 'slate';
import { Box } from '@mui/material';

interface LeafProps {
  children: ReactNode;
  leaf: Text;
  text: Text;
  attributes: Record<string, unknown>;
}
const styles = {
  highlight: {
    color: 'error.dark',
    backgroundColor: 'error.light',
    fontWeight: 'bold',
  },
};
const Leaf: FC<LeafProps> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.highlight) {
    children = (
      <Box
        component="span"
        {...attributes}
        sx={{
          ...(leaf.highlight && styles.highlight),
        }}
      >
        {children}
      </Box>
    );
  }
  return <span {...attributes}>{children}</span>;
};

export default Leaf;
