import { useQueryClient, InfiniteData, UseMutationOptions } from 'react-query';
import produce from 'immer';

import {
  useGetUserSearchesV2Query,
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables,
  GetUserSearchesV2Query,
  GetUserSearchesV2QueryVariables,
} from 'api';
import { useNotify } from 'hooks';

type Mutation = UpdateUserProfileMutation;
type MutationVariables = UpdateUserProfileMutationVariables;
type QueryData = InfiniteData<GetUserSearchesV2Query>;
type QueryVariables = GetUserSearchesV2QueryVariables;

const useUpdateUserProfile = (variables: QueryVariables) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const queryKey = useGetUserSearchesV2Query.getKey(variables);

  function updateUserStatus(baseState: QueryData, mutationArgs: MutationVariables) {
    const { id } = mutationArgs.data;

    return produce(baseState, draftState => {
      draftState.pages.forEach(page => {
        const index = page.getUserSearchesV2.results.findIndex(result => result.id === id);
        if (index !== -1) {
          page.getUserSearchesV2.results.splice(index, 1);
        }
      });
    });
  }

  function updateUserType(baseState: QueryData, mutationArgs: MutationVariables) {
    const { id, user_type } = mutationArgs.data;

    return produce(baseState, draftState => {
      draftState.pages.forEach(page => {
        const index = page.getUserSearchesV2.results.findIndex(result => result.id === id);
        if (index !== -1) {
          page.getUserSearchesV2.results[index].user_type = user_type;
        }
      });
    });
  }

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async mutationArgs => {
      await queryClient.cancelQueries(queryKey);
      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const { user_type, user_status } = mutationArgs.data;

      let nextState = baseState;

      if (user_type) {
        nextState = updateUserType(baseState, mutationArgs);
      }

      if (user_status) {
        nextState = updateUserStatus(baseState, mutationArgs);
      }

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useUpdateUserProfile;
