import React, { FC, Fragment } from 'react';
import { Box, Divider, GlobalStyles, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { Form as FormikForm, Formik } from 'formik';

import { ClientInput } from '../types';
import ClientSchema from '../validations/ClientSchema';

import Information from './Client/Information';
import Status from './Client/Status';
import Logos from './Client/Logos';
import EmailOptions from './Client/EmailOptions';
import MultiValue from './Client/MultiValue';
import Authentication from './Client/Authentication';
import Privileges from './Client/Privileges';
import Onboarding from './Client/Onboarding';
import OrganicInsights from './Client/OrganicInsights';
import PublicSharing from './Client/PublicSharing';
import Submit from './Client/Submit';
import Feeds from './Client/Feeds';
import Errors from './Client/Errors';
import Compliance from './Client/Compliance';
import Features from './Client/Features';
import Contract from './Client/Contract';
import MenuItem from './Client/MenuItem';

import theme from 'theme';

interface Props {
  initialValues: ClientInput;
  working: boolean;
  action: 'create' | 'edit';
  onSubmit(createClientInput: ClientInput): void;
}

const styles = {
  dividerFirst: {
    height: 1,
    backgroundColor: 'primary.main',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
} as const;

const globalStyles = {
  body: {
    backgroundColor: theme.palette.common.white,
  },
};

const ClientForm: FC<Props> = ({ initialValues, onSubmit, working, action }) => {
  return (
    <Fragment>
      <GlobalStyles styles={globalStyles} />
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={ClientSchema()}>
        <FormikForm>
          <Box marginBottom={4}>
            <Typography color="inherit" variant="h4">
              {action === 'edit' ? 'Edit Client' : 'Create Client'}
            </Typography>
            <Divider sx={styles.dividerFirst} />
            <Information />
            <Divider sx={styles.divider} />
            <Status />
            <Divider sx={styles.divider} />
            <Logos />
            <Divider sx={styles.divider} />
            <EmailOptions />
            <Divider sx={styles.divider} />
            <MultiValue
              title="Email Domains"
              placeholder="Add Email Domain"
              dataKey="email_domains"
            />
            <Divider sx={styles.divider} />
            <MultiValue
              title="Content Domains"
              placeholder="Add Content Domain"
              dataKey="content_domains"
            />
            <Divider sx={styles.divider} />
            <Authentication />
            <Divider sx={styles.divider} />
            <Privileges />
            <Divider sx={styles.divider} />
            <Onboarding />
            <Divider sx={styles.divider} />
            <PublicSharing />
            <Divider sx={styles.divider} />
            <Features />
            <Divider sx={styles.divider} />
            <Feeds />
            <Divider sx={styles.divider} />
            <Compliance />
            <Divider sx={styles.divider} />
            <MenuItem />
            <Divider sx={styles.divider} />
            <Contract />
            <Divider sx={styles.divider} />
            <OrganicInsights />
            <Divider sx={styles.divider} />
            <Errors />
            <Grid container justifyContent="flex-end">
              <Grid>
                <Submit working={working} />
              </Grid>
            </Grid>
          </Box>
        </FormikForm>
      </Formik>
    </Fragment>
  );
};

export default ClientForm;
