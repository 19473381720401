import React, { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  BillableUserEventType,
  BootstrapQuery,
  LeaderboardConfig,
  UserProfileFragment,
  useSendBillingBillableUserEventQueueMutation,
  useUpdateUserProfileMutation,
} from 'api';
import {
  CapabilitiesProvider,
  ProtectedClientContext,
  ReleasesContext,
  FollowedProvider,
  SocialConnectionsProvider,
  UserContext,
  UserLeaderboardsContext,
} from 'providers';

interface Props {
  bootstrapQuery: BootstrapQuery;
  children: ReactNode;
}

const Providers: FC<Props> = ({ bootstrapQuery, children }) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const { mutate: updateUserProfile } = useUpdateUserProfileMutation();
  const { mutate: sendBillingEvent } = useSendBillingBillableUserEventQueueMutation();

  const {
    client,
    user,
    capabilities,
    release_toggles,
    user_leaderboards,
    user_social_accounts,
    user_pages,
  } = bootstrapQuery.bootstrap;
  const bootstrapUser = user as UserProfileFragment;
  const bootstrapUserLeaderboards = user_leaderboards as LeaderboardConfig[];

  useEffect(() => {
    handleBillingEvent(location.pathname);
  }, [location]);

  function handleBillingEvent(pathname: string) {
    sendBillingEvent({
      client_id: client.id,
      user_id: bootstrapUser.id,
      billable_user_event: {
        event_type: BillableUserEventType.AppRouteSwitched,
        measure_reference: pathname,
        measure_source: 'web',
      },
    });
  }

  function reconcileLanguage() {
    const { languages } = i18n;
    const isLanguageMatch = bootstrapUser.lang === languages[0];

    if (isLanguageMatch) {
      return;
    }

    updateUserProfile({ data: { id: bootstrapUser.id, lang: languages[0] } });
  }

  useEffect(() => {
    reconcileLanguage();
  }, []);

  return (
    <ProtectedClientContext.Provider value={client}>
      <UserContext.Provider value={bootstrapUser}>
        <ReleasesContext.Provider value={release_toggles}>
          <CapabilitiesProvider capabilities={capabilities}>
            <UserLeaderboardsContext.Provider value={bootstrapUserLeaderboards}>
              <SocialConnectionsProvider connections={user_social_accounts} pages={user_pages}>
                <FollowedProvider>{children}</FollowedProvider>
              </SocialConnectionsProvider>
            </UserLeaderboardsContext.Provider>
          </CapabilitiesProvider>
        </ReleasesContext.Provider>
      </UserContext.Provider>
    </ProtectedClientContext.Provider>
  );
};

export default Providers;
