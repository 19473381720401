import React, { FC } from 'react';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box';

const WRAPPER_SIZE = 400;

const OnboardingBox: FC<BoxProps> = ({ children, ...rest }) => (
  <Box
    width={{ lg: WRAPPER_SIZE }}
    maxWidth={{ xs: WRAPPER_SIZE, sm: WRAPPER_SIZE, md: WRAPPER_SIZE }}
    {...rest}
  >
    {children}
  </Box>
);

export default OnboardingBox;
