import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { some } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { Network } from 'api';
import { useCapabilities, useContent, useNetworkCapabilities } from 'hooks';
import { Capability, SocialIcon } from 'components';

import { hasCommentary } from '../../helpers';

const Commentary: FC = () => {
  const { t } = useTranslation();
  const { isAdmin } = useCapabilities();
  const theme = useTheme();
  const content = useContent();
  const navigate = useNavigate();
  let { hasTwitter } = useNetworkCapabilities();

  if (!isAdmin || !hasCommentary(content)) {
    return null;
  }

  hasTwitter = hasTwitter && some(content.commentary, ['network', Network.Twitter]);
  const hasLinkedIn = some(content.commentary, ['network', Network.Linkedin]);
  const hasFacebook = some(content.commentary, ['network', Network.Facebook]);

  return (
    <Grid
      container
      alignItems="center"
      onClick={() => navigate(`/post/edit/${content.content_id}`)}
      sx={{ cursor: 'pointer', padding: theme => theme.spacing(1, 2) }}
    >
      {hasTwitter && (
        <SocialIcon provider="twitter" size={24} sx={{ marginRight: theme.spacing(0.5) }} />
      )}
      {hasLinkedIn && (
        <SocialIcon provider="linkedin" size={24} sx={{ marginRight: theme.spacing(0.5) }} />
      )}
      <Capability name="Sharing.Function.ShareToFacebook">
        {hasFacebook && (
          <SocialIcon provider="facebook" size={24} sx={{ marginRight: theme.spacing(0.5) }} />
        )}
      </Capability>
      <Typography sx={{ marginLeft: theme.spacing(0.5) }}>{t('posting:commentary')}</Typography>
    </Grid>
  );
};

export default Commentary;
