import React, { FC } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { Button } from '@mui/material';
import { alpha, darken } from '@mui/material/styles';

import theme from 'theme';

interface Props extends ButtonProps {
  rounded?: boolean;
  dark?: boolean;
}

const styles = {
  button: {
    width: 100,
    color: 'primary.main',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    '&:hover': {
      color: alpha(theme.palette.primary.main, 0.5),
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
  },
  rounded: {
    borderRadius: 4,
  },
  dark: {
    backgroundColor: 'primary.main',
    color: 'common.white',
    '&:hover': {
      color: 'common.white',
      backgroundColor: darken(theme.palette.primary.main, 0.1),
    },
  },
} as const;

const Primary: FC<Props> = ({ children, rounded, dark, ...rest }) => {
  return (
    <Button
      sx={{ ...styles.button, ...(dark ? styles.dark : {}), ...(rounded ? styles.rounded : {}) }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default Primary;
