import React, { FC, Fragment } from 'react';
import {
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  ClientBillingReportAggregateFragment,
  useGenerateClientBillingUserReportDownloadMutation,
} from 'api';

import { updateClient } from '../../Links';
import Loader from '../Client/Loader';
import { useNotify } from 'hooks';

const styles = {
  low: {
    background: 'warning.light',
  },
  med: {},
  high: {
    background: 'error.light',
  },
} as const;

interface IProps {
  results: ClientBillingReportAggregateFragment[];
  isExhausted: boolean;
  isInitialLoad: boolean;
  sentinelRef: (node: Element) => void;
}

const ReportTable: FC<IProps> = ({ results, isExhausted, isInitialLoad, sentinelRef }) => {
  const notify = useNotify();
  const { mutate: downloadReport } = useGenerateClientBillingUserReportDownloadMutation({
    onError: notify.mutationError,
  });

  function handleDownload(clientId: string, calendarMonth: string) {
    notify.info({ message: 'Preparing report...' });
    downloadReport({
      client_id: clientId,
      calendar_month: calendarMonth,
    });
  }

  function renderHeadCell(text: string, left?: boolean) {
    return (
      <TableCell align={left ? 'left' : 'right'}>
        <Typography title={text} variant="body2">
          {text}
        </Typography>
      </TableCell>
    );
  }

  function renderValueCell(value: string | number) {
    return (
      <TableCell align="right">
        <Box pr={1}>{value}</Box>
      </TableCell>
    );
  }

  function renderRow(report: ClientBillingReportAggregateFragment) {
    let className = styles.med;
    if (report.usage_rate > 1) {
      className = styles.high;
    } else if (report.usage_rate && report.usage_rate < 0.2) {
      className = styles.low;
    }
    return (
      <TableRow key={report.client_id} sx={className}>
        <TableCell>
          <Link component={updateClient(report.client_id)} underline="hover">
            {report.client_id}
            <br />
            {report.client_subdomain}
          </Link>
        </TableCell>
        {renderValueCell(report.contract_committed_user_count)}
        {renderValueCell(report.num_potential_users)}
        {renderValueCell(report.num_registered_users)}
        {renderValueCell(report.num_email_users)}
        {renderValueCell(report.num_anonymous_users)}
        {renderValueCell(report.total_users)}
        {renderValueCell(report.registered_rate)}
        {renderValueCell(report.user_overage)}
        {renderValueCell(report.two_months_ago_total_users)}
        {renderValueCell(report.last_month_total_users)}
        {renderValueCell(
          report.usage_rate == null ? '' : `${(report.usage_rate * 100).toFixed(2)}%`
        )}
        <TableCell align="right">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDownload(report.client_id, report.calendar_month)}
          >
            Users
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {renderHeadCell('Client Id / Subdomain', true)}
            {renderHeadCell('Committed')}
            {renderHeadCell('Total Registered Users')}
            {renderHeadCell('Active Onboarded Users')}
            {renderHeadCell('Active Email Users')}
            {renderHeadCell('Active Anonymous Users')}
            {renderHeadCell('Total Active Users')}
            {renderHeadCell('Total Active Users / Registered users')}
            {renderHeadCell('Overage')}
            {renderHeadCell('2 Months Ago')}
            {renderHeadCell('1 Month Ago')}
            {renderHeadCell('Percentage Usage')}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{results.map(renderRow)}</TableBody>
      </Table>
      {isInitialLoad && <Loader rows={8} />}
      {!isExhausted && <Loader sentinelRef={sentinelRef} rows={1} />}
    </Fragment>
  );
};

export default ReportTable;
