import React, { FC, Fragment, ReactElement, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  ButtonProps,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

interface Props extends ButtonProps {
  name: string;
  onClickButton?: () => void | null;
}

const styles = {
  arrowButton: {
    padding: 0,
  },
  groupedHorizontal: {
    '&:not(:first-of-type)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 24,
      borderBottomRightRadius: 24,
    },
    '&:not(:last-child)': {
      borderTopLeftRadius: 24,
      borderBottomLeftRadius: 24,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  disabled: {},
  groupedContainedHorizontal: {
    '&:not(:last-child)': {
      '&$disabled': {
        borderColor: 'transparent',
        borderRightColor: 'common.white',
      },
    },
  },
} as const;

const SubAction: FC<Props> = ({
  children,
  color,
  disabled,
  name,
  onClickButton,
  size,
  variant,
}) => {
  const anchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  function onClickArrow() {
    setMenuOpen(!menuOpen);
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setMenuOpen(false);
  };

  return (
    <Fragment>
      <ButtonGroup
        disabled={disabled}
        sx={{
          groupedHorizontal: styles.groupedHorizontal,
          groupedContainedHorizontal: styles.groupedContainedHorizontal,
          disabled: styles.disabled,
        }}
        variant={variant}
        color={color}
        size={size}
      >
        <Button onClick={onClickButton ?? onClickArrow}>{name}</Button>
        <Button sx={styles.arrowButton} ref={anchorRef} size="small" onClick={onClickArrow}>
          <ArrowDropDownRoundedIcon />
        </Button>
      </ButtonGroup>

      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="top-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {children as ReactElement}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

SubAction.defaultProps = {
  variant: 'contained',
  color: 'primary',
  size: 'large',
  disabled: false,
};

export default SubAction;
