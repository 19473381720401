import { useSocialConnections } from 'hooks';
import { LOCAL_STORAGE_KEY_ACCESS_KEY } from 'app-constants';
import { useListPublicSocialConnectionsQuery } from 'api/public';
import notify from 'notify';

interface SocialConnectionsArgs {
  publicQuery: boolean;
}

export const useGetLinkedInTokenId = (config: SocialConnectionsArgs) => {
  const { publicQuery } = config;
  const access_key = localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_KEY);

  let token_id;

  const publicData = useListPublicSocialConnectionsQuery(
    { access_key },
    {
      onError: notify.queryError,
      enabled: publicQuery && access_key !== null,
    }
  );

  const privateData = useSocialConnections();

  if (publicData.data) {
    const items = publicData?.data?.listPublicSocialConnections?.items || [];
    const userNetworkInfo = items.find(item => item.provider === 'linkedin');
    token_id = userNetworkInfo?.id;
    return token_id;
  }

  token_id = privateData?.linkedinAccounts?.[0]?.id;

  return token_id;
};
