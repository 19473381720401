import React, { FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';
import { EncryptStorage } from 'encrypt-storage';
import Auth from '@aws-amplify/auth';

import cookieStorage from 'cookie-storage';

import { BootstrapQuery, UserProfile, useBootstrapQuery } from 'api';
import { ErrorPage, Loaders } from 'components';
import { useCognitoUser, useGlobalClient } from 'hooks';
import { SESSION_KEY_ES_UUID } from 'app-constants';
import { AuthError, USER_DISABLED } from 'features/auth';
import * as paths from 'paths';

import Providers from './Providers';
import Connections from './Connections';
import ProtectedRoutes from './Routes';
import { getJwt } from 'helpers';
import {
  shouldBlockStandardCharterUser,
  shouldRedirectToWorkvivoJwtSSO,
} from 'features/custom/StandardCharter';
import { OAUTH_DOMAIN } from 'settings';

const MIN_REFRESH_TIME = 60000;

const ProtectedIndex: FC = () => {
  const cognitoUser = useCognitoUser();
  const variables = { user_id: cognitoUser.id };
  const { data, error, isLoading } = useBootstrapQuery<BootstrapQuery, Error>(variables);
  const userProfile = data?.bootstrap?.user as UserProfile;
  const lastRefresh = useRef(0);
  const publicClient = useGlobalClient();
  const navigate = useNavigate();
  const jwt = getJwt();

  useEffect(() => {
    Hub.listen('auth', handleAuth);
    checkForClientMismatch();
  }, []);

  if (shouldRedirectToWorkvivoJwtSSO(cognitoUser, jwt)) {
    // First we store current path to be restored later
    const url = new URL(location.href);
    const params = url.searchParams;
    cookieStorage.nextPath = url.pathname + '?' + params.toString();

    // Next, we need to make sure we are logged out locally
    for (const key in localStorage) {
      if (key.indexOf('CognitoIdentityServiceProvider') >= 0) {
        localStorage.removeItem(key);
      }
    }

    // Finally, we need to make sure we are also logged out on the cognito domain
    const clientId = publicClient.sso_configuration.app_client_id;
    const redirectUri = encodeURIComponent(`https://${url.hostname}`);
    const logoutUrl = `https://${OAUTH_DOMAIN}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;
    location.replace(logoutUrl);

    return <Loaders.Startup />;
  } else if (jwt) {
    const url = new URL(location.href);
    url.searchParams.delete('jwt');
    url.searchParams.delete('Keyid');
    navigate(url.pathname + '?' + url.searchParams.toString());
  }

  if (shouldBlockStandardCharterUser(publicClient, userProfile)) {
    return <AuthError code="auth:forbidden" />;
  }

  // This is a hack to work around the fact that cognito currently ignores the clientMetadata in refreshToken requests.
  // When we detect that the client_id in the token differs from the client_id from the domain, we update the user attributes
  // with the correct client id, and then generate and store a new token.
  async function forceRefreshToken() {
    const now = new Date().getTime();
    if (now - lastRefresh.current < MIN_REFRESH_TIME) {
      return;
    }
    lastRefresh.current = now;
    try {
      const token = currentIdTokenFromLocalStorage();
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(cognitoUser, { 'custom:client_id': publicClient.id });
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession((currentSession as any).refreshToken, (err: any, session: any) => {
        console.log('forcedTokenRefresh', err, session);
        if (
          session.idToken.payload['custom:client_id'] != publicClient.id ||
          token['custom:client_id'] != publicClient.id
        ) {
          location.reload(); // We reload because a request may have gone through with the wrong token
        }
      });
    } catch (e) {
      console.log('forcedTokenRefresh:fail', e);
      navigate(paths.logout);
    }
  }

  function handleAuth(event: any) {
    const eventType = event?.payload?.event;
    if (eventType === 'tokenRefresh') {
      checkForClientMismatch();
    }
  }

  /**
   * Handles case where cognito refresh token contains a different client ID
   * If a different client ID is given by Cognito, we redirect to root domain
   * https://forums.aws.amazon.com/thread.jspa?messageID=910021&#910021
   */
  function checkForClientMismatch() {
    const { authenticatedUser } = cognitoUser;
    const clientId = (authenticatedUser as any)?.attributes['custom:client_id'];
    // We read the token from local storage here because sometimes the attributes from currentAuthenticatedUser
    // are not synchronized with localStorage
    const token = currentIdTokenFromLocalStorage();
    if (clientId != publicClient.id || token['custom:client_id'] != publicClient.id) {
      void forceRefreshToken();
    }
  }

  function currentIdTokenFromLocalStorage() {
    const { authenticatedUser } = cognitoUser;
    const { clientId } = (authenticatedUser as any).pool;
    const username = authenticatedUser.getUsername();
    const idToken = Object.keys(localStorage).find(k =>
      k.endsWith(`.${clientId}.${username}.idToken`)
    );
    if (idToken) {
      try {
        const secretKey = localStorage[SESSION_KEY_ES_UUID];
        const encryptStorage = new EncryptStorage(secretKey);
        const encodedToken = encryptStorage.getItem(idToken);
        const encodedPayload = encodedToken.split('.')[1];
        const token = JSON.parse(window.atob(encodedPayload));
        return token;
      } catch (e) {
        // We fallback to using whatever cognito has stored, and hope it works
        console.log('InvalidToken', idToken, e);
      }
    }
    return (authenticatedUser as any)?.attributes;
  }

  if (isLoading) {
    return <Loaders.Startup />;
  }

  if (error?.message === USER_DISABLED) {
    return <AuthError code="user_profile_suspended" />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Providers bootstrapQuery={data}>
      <Connections />
      <ProtectedRoutes />
    </Providers>
  );
};

export default ProtectedIndex;
