import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { Loaders } from 'components';

const Logout: FC = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      spacing={2}
    >
      <Grid>
        <Typography variant="h6">{t('components:loggingOut')}</Typography>
      </Grid>
      <Grid>
        <Loaders.Standard height={40} />
      </Grid>
    </Grid>
  );
};

export default Logout;
