import React, { FC } from 'react';
import { Typography } from '@mui/material';

import { SharesItemFragment } from 'api';
import { isUrl } from 'features/content/helpers';
import theme from 'theme';

import UrlMetadata from './UrlMetadata';
import Body from './Body';

interface Props {
  item: SharesItemFragment;
}

const ShareCopy: FC<Props> = ({ item }) => {
  const { content } = item;

  function getFallback() {
    return isUrl(content) ? (
      <UrlMetadata disableTitle content={content} />
    ) : (
      <Body content={content} />
    );
  }

  return (
    <em>
      {!item.user_commentary ? (
        getFallback()
      ) : (
        <Typography sx={theme.mixins.clamp(2)}>{item.user_commentary}</Typography>
      )}
    </em>
  );
};

export default ShareCopy;
