import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar as MuiAppBar,
  Button,
  Toolbar,
  Typography,
  Unstable_Grid2 as Grid,
  useMediaQuery,
} from '@mui/material';

import { APP_IMAGES } from 'app-constants';
import { getAppImage } from 'helpers';
import { Logout } from 'Links';
import theme from 'theme';

interface Props {
  logout?: boolean;
}

const BareAppBar: FC<Props> = ({ logout }) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const height = isMobile ? 25 : 30;

  return (
    <MuiAppBar position="fixed">
      <Toolbar>
        <Grid
          xs={12}
          container
          alignItems="center"
          justifyContent={logout ? 'flex-start' : isMobile ? 'center' : 'space-between'}
        >
          <Grid sx={{ height }} xs="auto">
            <img {...getAppImage(APP_IMAGES.Logo, height)} />
          </Grid>

          {!isMobile && (
            <Grid sx={{ display: { sx: 'none', md: 'none', lg: 'flex' } }}>
              <Typography variant="subtitle1" noWrap>
                {t('components:description')}
              </Typography>
            </Grid>
          )}

          {logout && (
            <Grid xs container justifyContent="flex-end">
              <Button component={Logout}>{t('common:logout')}</Button>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
};

BareAppBar.defaultProps = {
  logout: false,
};

export default BareAppBar;
