import React, { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Unstable_Grid2 as Grid } from '@mui/material';
import Edit from '@mui/icons-material/Edit';

import PaddedPaper from './PaddedPaper';

interface IPropsEnhanced {
  header: (editing: boolean) => ReactNode;
  body: (editing: boolean) => ReactNode;
  toggle: (editing: boolean) => ReactNode;
  actions?: (editing: boolean) => ReactNode;
  controls?: (editing: boolean, setEditing: (editing: boolean) => void) => ReactNode;
}

const styles = {
  button: {
    minWidth: 150,
    marginBottom: 2,
  },
} as const;

const Panel: FC<IPropsEnhanced> = ({ actions, header, body, toggle, controls }) => {
  const [editing, setEditing] = useState(false);
  const { t } = useTranslation();

  function renderEditView() {
    return (
      <PaddedPaper>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid xs={10}>{header(editing)}</Grid>
          {toggle(editing)}
        </Grid>
        {body(editing)}
        {controls ? controls(editing, setEditing) : renderDefaultControls()}
      </PaddedPaper>
    );
  }

  function renderDefaultControls() {
    return (
      <Grid container justifyContent="flex-end">
        <Grid>
          <Button
            sx={styles.button}
            variant="contained"
            color="primary"
            onClick={() => setEditing(false)}
          >
            {t('common:done')}
          </Button>
        </Grid>
      </Grid>
    );
  }

  function renderReadOnlyView() {
    return (
      <PaddedPaper>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid xs>{header(editing)}</Grid>
          <Grid>
            {!!actions && actions(editing)}
            <Button onClick={() => setEditing(true)} endIcon={<Edit fontSize="small" />}>
              {t('common:edit')}
            </Button>
          </Grid>
          {toggle(editing)}
        </Grid>
        {body(editing)}
      </PaddedPaper>
    );
  }

  return editing ? renderEditView() : renderReadOnlyView();
};

export default Panel;
