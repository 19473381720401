import { cloneDeep } from 'lodash';

import { BlockedTerm, Network, Network as NetworkProtected } from 'api';
import { Network as NetworkPublic } from 'api/public';
import { LOCAL_STORAGE_KEY_PARTNER_SHARE_NETWORKS } from 'app-constants';

import { Share, ShareConnectionOption, ShareDisplayOption } from './types';
import { escapeRegex } from 'utilities';

export function addNetwork(network: NetworkPublic | NetworkProtected) {
  const current = localStorage.getItem(LOCAL_STORAGE_KEY_PARTNER_SHARE_NETWORKS);

  if (!current) {
    localStorage.setItem(LOCAL_STORAGE_KEY_PARTNER_SHARE_NETWORKS, network);
    return;
  }

  if (current.includes(network)) {
    return;
  }

  const next = `${current},${network}`;
  localStorage.setItem(LOCAL_STORAGE_KEY_PARTNER_SHARE_NETWORKS, next);
}

export function removeNetwork(network: NetworkPublic | NetworkProtected) {
  const current = localStorage.getItem(LOCAL_STORAGE_KEY_PARTNER_SHARE_NETWORKS);

  if (!current) {
    return;
  }

  if (!current.includes(network)) {
    return;
  }

  const next = current.replace(network, '').replace(/,\s*$/, '');
  localStorage.setItem(LOCAL_STORAGE_KEY_PARTNER_SHARE_NETWORKS, next);
}

export function prepShareItems(formValues: Share) {
  let hasFacebook = false;

  const clonedItems = cloneDeep(formValues.items);
  const filteredItems = clonedItems
    .filter(item => item.state.active)
    .filter(item => {
      if (item.state.networkMeta.provider === Network.Twitter) {
        addNetwork(Network.Twitter);
        return true;
      }

      if (item.state.networkMeta.provider === Network.Linkedin) {
        addNetwork(Network.Linkedin);
        return true;
      }

      if (item.state.networkMeta.provider === Network.Facebook) {
        addNetwork(Network.Facebook);
        hasFacebook = true;
        return true;
      }

      return false;
    });

  filteredItems.forEach(item => delete item.state);

  return { items: filteredItems, hasFacebook };
}

export function getShareDisplayOption() {
  const searchParams = new URLSearchParams(window.location.search);
  return (searchParams.get('display') as ShareDisplayOption) || ShareDisplayOption.Page;
}

export function getShareConnectionOption() {
  const searchParams = new URLSearchParams(window.location.search);
  return (searchParams.get('connection') as ShareConnectionOption) || ShareConnectionOption.Page;
}

export const findWordMatch = (list: string[], text = '') => {
  const regex = new RegExp(`\\b(?:${list.map(term => escapeRegex(term)).join('|')})\\b`, 'gi');
  const matches: BlockedTerm[] = Array.from(text?.matchAll(regex), match => {
    const term = match[0];
    const start = match.index;
    const end = start + term.length;

    if (term !== '') {
      return {
        start,
        end,
        term,
      };
    }

    return undefined;
  }).filter(Boolean) as BlockedTerm[]; // Filter out any undefined values

  return matches;
};
