import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  Paper,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { ContentInput, PreviewUrlQuery, PreviewUrlSourceType, usePreviewUrlQuery } from 'api';
import { Loaders } from 'components';
import { useNotify } from 'hooks';

import { getEmbed, isEmbed } from 'features/content/helpers';
import { convertUrlImageToPhoto } from 'features/posting/helpers';
import { usePostContext } from 'features/posting/PostContext';
import { PhotoStrip } from '../Partials';

import PhotoPreview from './PhotoPreview';
import EditLinkData from '../Link/EditLinkData';
import { USER_FEED_ID } from 'app-constants';

const styles = {
  paper: {
    display: 'flex',
    minHeight: 225,
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  card: {
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  cardBody: {
    paddingLeft: 2,
    paddingRight: 2,
  },
  chooseImage: {
    paddingTop: 1,
  },
  chooseImageTitle: {
    paddingBottom: 0.5,
  },
  loader: {
    height: 300,
  },
  selected: {
    border: `1px solid primary.main`,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
} as const;

interface Props {
  moderator: boolean;
  disabled?: boolean;
  app_source?: PreviewUrlSourceType;
}

const UrlPreview: FC<Props> = ({ disabled, moderator, app_source }) => {
  const notify = useNotify();
  const { t } = useTranslation();

  const { setFieldValue, values } = useFormikContext<ContentInput>();
  const { dispatch, state } = usePostContext();
  const [urlField] = useField('content_object.url');
  const { url_metadata_override, photos } = values.content_object;
  const isBrandedFromContent = values.is_branded;

  const { data, isLoading, error } = usePreviewUrlQuery(
    { url: urlField.value, group_id: USER_FEED_ID, app_source: app_source },
    {
      enabled: !!urlField.value,
      onError: () => notify.error({ message: t('posting:invalidUrl') }),
      onSuccess: handleCompleted,
    }
  );

  function handleCompleted(queryData: PreviewUrlQuery) {
    const { is_branded, default_engageable, default_shareable, is_network, content_object } =
      queryData.previewURL;
    const { images, title, description } = content_object.url_metadata;

    const combinedIsBranded = isBrandedFromContent || is_branded;

    dispatch({ type: 'engagePost', value: is_network });

    // Take user submitted value when editing
    if (state.editing) {
      setFieldValue('is_shareable', values?.is_shareable);
      setFieldValue('is_engageable', values?.is_engageable);
    } else {
      setFieldValue('is_shareable', is_network ? default_shareable : true);
      setFieldValue('is_engageable', is_network ? default_engageable : false);
    }

    setFieldValue('is_branded', combinedIsBranded);

    setFieldValue(
      'content_object.url_metadata_override.title',
      url_metadata_override.title || title || ''
    );
    setFieldValue(
      'content_object.url_metadata_override.description',
      url_metadata_override.description || description || ''
    );

    if (!photos.length) {
      const convertedImages = images?.map(img => convertUrlImageToPhoto(img)) ?? [];
      setFieldValue('content_object.photos', convertedImages);
      dispatch({ type: 'clearPhotoIndexes' });
      dispatch({ type: 'updatePhotoIndexes', value: 0 });
    }

    if (combinedIsBranded) {
      dispatch({ type: 'brandedPost', value: true });
    }
  }

  function renderLoader() {
    return (
      <Box sx={styles.loader}>
        <Loaders.Standard />
      </Box>
    );
  }

  function renderError() {
    return (
      <Paper sx={styles.paper}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid>
            <Typography variant="body2">{t('posting:previewUnavailable')}</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  function renderEmbed() {
    return (
      <Paper sx={styles.paper}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid>{getEmbed(data.previewURL)}</Grid>
        </Grid>
      </Paper>
    );
  }

  function renderPreview() {
    if (isEmbed(data.previewURL)) {
      return renderEmbed();
    }

    return (
      <Card sx={disabled ? { ...styles.card, ...styles.disabled } : styles.card}>
        <PhotoPreview />

        <CardContent sx={styles.chooseImage}>
          <Typography color="textSecondary" sx={styles.chooseImageTitle}>
            {t('posting:chooseImage')}
          </Typography>

          <PhotoStrip />
        </CardContent>

        <CardContent sx={{ root: styles.cardBody }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid xs>
              <Typography title={url_metadata_override.title} noWrap variant="h6">
                {url_metadata_override.title}
              </Typography>
            </Grid>
            {moderator && <EditLinkData />}
          </Grid>
          <Typography color="textSecondary">{url_metadata_override.description}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <FormControl variant="standard" fullWidth>
      {error && renderError()}
      {isLoading && renderLoader()}
      {data?.previewURL && renderPreview()}
    </FormControl>
  );
};

export default UrlPreview;
