import React, { ChangeEvent, FC, HTMLAttributes, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Checkbox,
  Chip,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { GroupSuggestQuery, useGroupSuggestQuery, UserFeedFollowGroupFragment } from 'api';
import { useDebounce } from 'use-debounce';
import { isEmpty } from 'lodash';
import { useFollowed, useNotify } from '../../hooks';
import { PickerInput } from './components';
import { GroupAvatar } from '../index';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

interface Props {
  selectedGroups: Array<Partial<UserFeedFollowGroupFragment>>;
  onSelectedGroupsChange: (selectedGroups: UserFeedFollowGroupFragment[]) => void;
  scope: 'admin' | 'moderator' | 'followed';
  saving?: boolean;
  excludeIds?: string[];
  fullWidth?: boolean;
}

const GroupPicker: FC<Props> = props => {
  const { selectedGroups, onSelectedGroupsChange, scope, saving, excludeIds, fullWidth, ...rest } =
    props;

  const { groups, moderated } = useFollowed();
  const notify = useNotify();

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<UserFeedFollowGroupFragment[]>([]);
  const [prefix] = useDebounce(inputValue, 250);
  const { isLoading } = useGroupSuggestQuery(
    { prefix },
    {
      enabled: !!prefix && scope == 'admin',
      onError: notify.queryError,
      onSuccess: handleCompleted,
    }
  );

  useEffect(() => {
    if (isEmpty(options) && inputValue === '') {
      setOptions(getInitialOptions());
    }
  }, [options, inputValue]);

  const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
  const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

  function getInitialOptions() {
    switch (scope) {
      case 'admin':
        return groups;
      case 'moderator':
        return moderated;
      case 'followed':
        return groups;
      default:
        throw new Error('GroupPicker scope not found');
    }
  }

  function handleChange(event: ChangeEvent, newSelectedOptions: UserFeedFollowGroupFragment[]) {
    setInputValue('');
    onSelectedGroupsChange(newSelectedOptions);
  }

  function handleCompleted({ suggest }: GroupSuggestQuery) {
    const results = suggest.results as UserFeedFollowGroupFragment[];
    const filtered = results.filter(result => !excludeIds.includes(result.id));
    setOptions(filtered);
  }

  function handleAsyncInputChange(nextInputValue: string) {
    setInputValue(nextInputValue);
    if (!nextInputValue) {
      setOptions([]);
    }
  }

  function handleSyncInputChange(nextInputValue: string) {
    setInputValue(nextInputValue);
    const unfilteredOptions = scope == 'moderator' ? moderated : groups;
    const nextOptions = unfilteredOptions.filter(group =>
      (group.title || '').toLowerCase().includes(nextInputValue.toLowerCase())
    );
    setOptions(nextOptions);
  }

  function renderTags(value: UserFeedFollowGroupFragment[], getTagProps: AutocompleteGetTagProps) {
    return value.map((group, index) => (
      <Chip
        key={group.id}
        avatar={<GroupAvatar link size={30} group={group} />}
        label={group.title}
        deleteIcon={<CloseRoundedIcon />}
        {...getTagProps({ index })}
      />
    ));
  }

  function renderOption(
    props: HTMLAttributes<HTMLLIElement>,
    option: UserFeedFollowGroupFragment,
    { selected }: AutocompleteRenderOptionState
  ) {
    return (
      <Grid component="li" container alignItems="center" spacing={1} {...props}>
        <Grid>
          <GroupAvatar size={30} group={option} />
        </Grid>
        <Grid xs>{option.title}</Grid>
        <Grid>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
        </Grid>
      </Grid>
    );
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    const changeFunction = scope === 'admin' ? handleAsyncInputChange : handleSyncInputChange;

    return (
      <PickerInput
        working={saving || isLoading}
        placeholder="components:groupSuggest"
        onChange={changeFunction}
        params={params}
      />
    );
  }

  return (
    <Autocomplete
      fullWidth={fullWidth}
      multiple
      disableClearable
      disableCloseOnSelect
      openOnFocus
      loading={isLoading}
      options={options}
      onChange={handleChange}
      getOptionLabel={(option: UserFeedFollowGroupFragment) => option.title || ''}
      renderTags={renderTags}
      renderOption={renderOption}
      renderInput={renderInput}
      value={selectedGroups}
      inputValue={inputValue}
      {...rest}
    />
  );
};

GroupPicker.defaultProps = {
  excludeIds: [],
};

export default GroupPicker;
