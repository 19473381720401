import React, { FC, MouseEvent } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface Props {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

const FiltersButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      size="large"
      onClick={onClick}
      endIcon={<AddCircleIcon color="primary" />}
    >
      {t('analytics:filters')}
    </Button>
  );
};

export default FiltersButton;
