import { UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import { useNotify } from 'hooks';
import {
  CreateGroupWebhookWorkvivoMutation,
  CreateGroupWebhookWorkvivoMutationVariables,
  GetCombinedGroupWebhookListQuery,
  useGetCombinedGroupWebhookListQuery,
} from 'api';
import { compact } from 'lodash';

type Mutation = CreateGroupWebhookWorkvivoMutation;
type MutationVariables = CreateGroupWebhookWorkvivoMutationVariables;
type QueryData = GetCombinedGroupWebhookListQuery;

const useCreateGroupWebhook = () => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async ({ group_id, webhook_id, workvivo_workspace_id }) => {
      const queryKey = useGetCombinedGroupWebhookListQuery.getKey({ id: group_id });
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const { webhooks } = draftState.getGroupWebhookList;
          const index = webhooks.findIndex(webhook => webhook.webhook_id === webhook_id);

          if (index !== -1) {
            draftState.getGroupWebhookList.webhooks[index].groups = compact([
              ...webhooks[index].groups,
              group_id,
            ]);
            draftState.getGroupWebhookList.webhooks[index].workvivo_workspace_id =
              workvivo_workspace_id;
          }
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },

    onError: (error, { group_id }, baseState: QueryData) => {
      const queryKey = useGetCombinedGroupWebhookListQuery.getKey({ id: group_id });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }

      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useCreateGroupWebhook;
