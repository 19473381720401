import React, { FC, memo } from 'react';

import { DataTableColumn, Datum } from './types';
import useStyles, { StyleProps } from './styles';
import { Box } from '@mui/material';

interface Props extends StyleProps {
  datum: Datum;
  column: DataTableColumn<any>;
  index?: number;
}

const BodyCellMemoized: FC<Props> = memo(function BodyCell(props) {
  const classes = useStyles;
  const { datum, column, index } = props;
  const { render, padding } = column.bodyCell;

  const cellStyles = {
    ...(padding === 'none' ? classes.paddingNone : {}),
    ...(padding === 'checkbox' ? classes.paddingCheckbox : {}),
    ...(padding === 'dense' ? classes.paddingDense : {}),
    ...(padding === 'normal' || !padding ? classes.paddingNormal : {}),
  };

  return (
    <Box sx={cellStyles} style={{ width: column.width }}>
      {render(datum, index)}
    </Box>
  );
});

export default BodyCellMemoized;
