import React, { FC } from 'react';
import moment from 'moment';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { RoiDailyStat } from 'api';
import { formatStat } from 'helpers';

import ROITooltip from './ROITooltip';
import { getColor } from '../../helpers';
import theme from 'theme';

export interface ILineChartConfig {
  dataKey: string;
  label: string;
}

interface Props {
  height: number;
  data: Array<RoiDailyStat>;
  config: ILineChartConfig[];
}

const ROILineChart: FC<Props> = ({ height, data, config }) => {
  const gridColor = theme.palette.grey[500];

  return (
    <ResponsiveContainer height={height}>
      <LineChart data={data}>
        <XAxis
          tickLine={false}
          axisLine={false}
          dataKey="calendar_day"
          tickFormatter={tickValue => moment(tickValue).format('DD MMM')}
          tick={{ fill: gridColor }}
          stroke={gridColor}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          orientation="right"
          tickFormatter={tickValue => `$${formatStat(tickValue, '')}`}
          domain={['dataMin', 'auto']}
          tick={{ fill: gridColor }}
          stroke={gridColor}
        />
        <CartesianGrid stroke={gridColor} vertical={false} horizontal={false} />
        <Tooltip content={<ROITooltip />} />
        {config.map(({ dataKey }, index) => (
          <Line
            key={dataKey}
            type="monotone"
            dataKey={dataKey}
            stroke={getColor(index)}
            strokeWidth={2}
            strokeDasharray={index === 0 ? null : '8 8'}
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ROILineChart;
