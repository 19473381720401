import { useState } from 'react';
import moment from 'moment';

import { DateRange, UseDateRange } from 'types/app';

interface UseDateRangeOptions {
  defaultRange?: DateRange;
  includeToday?: boolean;
}

export default (options: UseDateRangeOptions = {}): UseDateRange => {
  const { includeToday = false, defaultRange = DateRange.Last30Days } = options;

  const endMoment = moment()
    .add(includeToday ? 0 : -1, 'day')
    .endOf('day');
  const startingEndDate = endMoment.endOf('day').format('YYYY-MM-DD');
  const startingStartDate = getStartMoment().format('YYYY-MM-DD');

  const [startDate, setStartDate] = useState(startingStartDate);
  const [endDate, setEndDate] = useState(startingEndDate);
  const [dateRange, setDateRange] = useState<DateRange>(defaultRange);

  function getStartMoment() {
    switch (defaultRange) {
      case DateRange.Last7Days:
        return moment(endMoment).subtract(7, 'days').startOf('day');
      case DateRange.Last30Days:
        return moment(endMoment).subtract(30, 'days').startOf('day');
      case DateRange.Last60Days:
        return moment(endMoment).subtract(60, 'days').startOf('day');
      case DateRange.Last90Days:
        return moment(endMoment).subtract(90, 'days').startOf('day');
      default:
        throw new Error('Date range not supported');
    }
  }

  function calculateDateRange(startDate: string, endDate: string): DateRange {
    const startMoment = moment(startDate);
    const sevenBackMoment = moment(endMoment)
      .subtract(7, 'days')
      .startOf('day')
      .format('YYYY-MM-DD');
    const thirtyBackMoment = moment(endMoment)
      .subtract(30, 'days')
      .startOf('day')
      .format('YYYY-MM-DD');
    const sixtyBackMoment = moment(endMoment)
      .subtract(60, 'days')
      .startOf('day')
      .format('YYYY-MM-DD');
    const ninetyBackMoment = moment(endMoment)
      .subtract(90, 'days')
      .startOf('day')
      .format('YYYY-MM-DD');

    if (!moment(endDate).isSame(startingEndDate)) {
      return DateRange.LastXDays;
    }

    if (startMoment.isSame(sevenBackMoment)) {
      return DateRange.Last7Days;
    }

    if (startMoment.isSame(thirtyBackMoment)) {
      return DateRange.Last30Days;
    }

    if (startMoment.isSame(sixtyBackMoment)) {
      return DateRange.Last60Days;
    }

    if (startMoment.isSame(ninetyBackMoment)) {
      return DateRange.Last90Days;
    }

    return DateRange.LastXDays;
  }

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dateRange,
    setDateRange,
    calculateDateRange,
  };
};
