import React, { FC, Fragment, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import {
  ClientProtectedFragment,
  SearchSortFieldsV2,
  UserProfileFragment,
  UserStatus,
  UserType,
  useUpdateUserProfileMutation,
} from 'api';
import { useNotify } from 'hooks';
import { Alert, EmptyState } from 'components';
import { useGetUserSearchesV2 } from 'features/admin/hooks';
import { useUpdateUserProfile } from 'clientSuccess/updaters';

import CreateDialog from './CreateDialog';
import UserRow from './UserRow';
import Loader from './Loader';
import { useDebounce } from 'use-debounce/lib';

interface IProps {
  client?: ClientProtectedFragment;
  userStatus: UserStatus;
  query: string;
  onSetImpersonation(user: UserProfileFragment): void;
  onNumUsersFound(numUsers: number): void;
}

interface IDialogOptions {
  open: boolean;
  user?: UserProfileFragment;
}

interface ICSDialogOptions extends IDialogOptions {
  userType?: UserType;
}

interface IDeleteDialogOptions extends IDialogOptions {
  userStatus?: UserStatus;
}

const LIMIT = 24;

const UserTable: FC<IProps> = ({
  client,
  userStatus,
  onSetImpersonation,
  query,
  onNumUsersFound,
}) => {
  const notify = useNotify();
  const [inviteOpen, setInviteOpen] = useState(false);
  const [csDialogOptions, setCSDialogOptions] = useState<ICSDialogOptions>({ open: false });
  const [deleteDialogOptions, setDeleteDialogOptions] = useState<IDeleteDialogOptions>({
    open: false,
  });

  const [queryVariables] = useDebounce(
    {
      user_status: userStatus,
      client_id: client?.id || 'ALL',
      sort: SearchSortFieldsV2.FullName,
      q: query,
      size: LIMIT,
      next_id: '',
    },
    250
  );

  const mutationOptions = useUpdateUserProfile(queryVariables);
  const { results, isInitialLoad, isExhausted, sentinelRef, count, isEmpty } =
    useGetUserSearchesV2(queryVariables);
  const { mutate: updateUserProfile } = useUpdateUserProfileMutation(mutationOptions);
  useEffect(() => onNumUsersFound(count), [count]);

  function handleCheckUserType(nextUserType: UserType, targetUser: UserProfileFragment) {
    if (nextUserType === UserType.Cs) {
      return setCSDialogOptions({ open: true, user: targetUser, userType: nextUserType });
    }
    updateUserType(nextUserType, targetUser);
  }

  function updateUserType(nextUserType: UserType, targetUser: UserProfileFragment) {
    updateUserProfile(
      {
        client_id: targetUser.client_id,
        data: {
          id: targetUser.id,
          user_type: nextUserType,
        },
      },
      {
        onSuccess: () => {
          notify.info({ message: 'User Type updated successfully' });
        },
      }
    );
  }

  function handleCheckUserStatus(nextUserStatus: UserStatus, targetUser: UserProfileFragment) {
    if (nextUserStatus === 'deleted') {
      return setDeleteDialogOptions({ open: true, user: targetUser, userStatus: nextUserStatus });
    }
    updateUserStatus(nextUserStatus, targetUser);
  }

  function updateUserStatus(nextUserStatus: UserStatus, targetUser: UserProfileFragment) {
    updateUserProfile(
      {
        client_id: targetUser.client_id,
        data: {
          id: targetUser.id,
          user_status: nextUserStatus,
        },
      },
      {
        onSuccess: () => {
          notify.info({ message: 'User Status updated successfully' });
        },
      }
    );
  }

  function renderHeadCell(text: string) {
    return (
      <TableCell>
        <Typography title={text} noWrap variant="body2">
          {text}
        </Typography>
      </TableCell>
    );
  }

  return (
    <Fragment>
      {isEmpty ? (
        <EmptyState name="searchUsers" />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              {renderHeadCell('')}
              {renderHeadCell('Id')}
              {renderHeadCell('Email')}
              {renderHeadCell('Name')}
              {renderHeadCell('Last Accessed')}
              {renderHeadCell('Role')}
              {renderHeadCell('Status')}
              {renderHeadCell('')}
            </TableRow>
          </TableHead>
          <TableBody>
            {isInitialLoad && <Loader sentinelRef={null} rows={8} />}
            {results.map(result => (
              <UserRow
                key={result.id}
                user={result as UserProfileFragment}
                onUpdateUserStatus={handleCheckUserStatus}
                onUpdateUserType={handleCheckUserType}
                onSetImpersonation={onSetImpersonation}
              />
            ))}
            {!isExhausted && <Loader sentinelRef={sentinelRef} rows={1} />}
          </TableBody>
        </Table>
      )}
      <Alert
        open={csDialogOptions.open}
        onConfirm={() => {
          updateUserType(csDialogOptions.userType, csDialogOptions.user);
          setCSDialogOptions({ open: false });
        }}
        onCancel={() => setCSDialogOptions({ open: false })}
        message={`SuperAdmin (CS) access is dangerous. Are you sure ${csDialogOptions?.user?.full_name} should have it?`}
        confirm="Proceed"
      />
      <Alert
        open={deleteDialogOptions.open}
        onConfirm={() => {
          updateUserStatus(deleteDialogOptions.userStatus, deleteDialogOptions.user);
          setDeleteDialogOptions({ open: false });
        }}
        onCancel={() => setDeleteDialogOptions({ open: false })}
        message={`Are you sure you want to delete ${deleteDialogOptions?.user?.full_name}?`}
        confirm="Delete"
      />
      {!!client && <CreateDialog open={inviteOpen} setOpen={setInviteOpen} clientId={client.id} />}
    </Fragment>
  );
};

export default UserTable;
