import { UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  ClientGroupSourceFragment,
  CreateGroupSourceMutation,
  CreateGroupSourceMutationVariables,
  GetGroupSourcesQuery,
  useGetGroupSourcesQuery,
} from 'api';
import { useNotify } from 'hooks';
import { OAuthTokenError } from '../containers/OAuthTokenError';

type Mutation = CreateGroupSourceMutation;
type MutationVariables = CreateGroupSourceMutationVariables;
type QueryData = GetGroupSourcesQuery;

export const ID_STUB = 'NEW_ID';

const useCreateSource = () => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async mutationArgs => {
      const queryKey = useGetGroupSourcesQuery.getKey({ group_id: mutationArgs.group_id });
      await queryClient.cancelQueries(queryKey);

      const newModerator: ClientGroupSourceFragment = {
        id: ID_STUB,
        ...mutationArgs,
      };

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.getClientGroupSourcesByGroup.push(newModerator);
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },

    onError: (error: string, { group_id }, baseState: QueryData) => {
      const queryKey = useGetGroupSourcesQuery.getKey({ group_id });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      if (error.toString().includes('client_oauth_tokens_missing')) {
        OAuthTokenError(notify);
      } else {
        notify.error({ message: error });
      }
    },
    onSettled: ({ clientGroupSourceCreate }, error, { group_id }) => {
      const queryKey = useGetGroupSourcesQuery.getKey({ group_id });

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const index = draftState.getClientGroupSourcesByGroup.findIndex(source => {
            return source.id === ID_STUB;
          });
          draftState.getClientGroupSourcesByGroup[index] = clientGroupSourceCreate;
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }
    },
  };

  return mutationOptions;
};

export default useCreateSource;
