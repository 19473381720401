import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Switch, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { SocialIcon } from 'components';
import { ShareCopyProvider } from 'providers';
import { useContent } from 'hooks';
import { isPhotoOnly } from 'features/content/helpers';

import { ShareItem } from '../types';
import ShareCopy from './ShareCopy';

interface Props {
  index: number;
}

const styles = {
  title: {
    marginTop: 0.5,
    marginRight: 1,
  },
} as const;

const NetworkDirect: FC<Props> = ({ index }) => {
  const content = useContent();
  const [field] = useField<ShareItem>(`items.${index}`);
  const { t } = useTranslation();

  const { name, value, onChange } = field;
  const { active, networkMeta } = value.state;
  const photoOnly = isPhotoOnly(content);

  return (
    <ShareCopyProvider network={networkMeta.provider}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid>
              <SocialIcon
                size={24}
                state={active ? 'active' : 'disabled'}
                provider={networkMeta.provider}
              />
            </Grid>
            <Grid>
              <Typography display="inline" variant="subtitle2" sx={styles.title}>
                {networkMeta.name}
              </Typography>
              {photoOnly && (
                <Typography display="inline" color="textSecondary">
                  <em>&mdash; {t('sharing:facebookPhotoOnly')}</em>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Switch
            disabled={photoOnly}
            name={`${name}.state.active`}
            checked={active}
            onChange={onChange}
          />
        </Grid>
      </Grid>
      {active && <ShareCopy index={index} />}
    </ShareCopyProvider>
  );
};

export default NetworkDirect;
