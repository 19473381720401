import { InfiniteData, UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  ContentFragment,
  CreateReactionMutation,
  CreateReactionMutationVariables,
  DeleteReactionMutation,
  DeleteReactionMutationVariables,
  GetContentQuery,
  GetContentQueryVariables,
  GetFeedQuery,
  GetFeedQueryVariables,
  useGetContentQuery,
  useGetFeedQuery,
} from 'api';
import { useCurrentUser, useNotify, useQueryVariables } from 'hooks';

type Mutation = CreateReactionMutation & DeleteReactionMutation;
type MutationVariables = CreateReactionMutationVariables | DeleteReactionMutationVariables;
type FeedQueryData = InfiniteData<GetFeedQuery>;
type ContentQueryData = GetContentQuery;

const useReactions = (operation: 'like' | 'unlike') => {
  const notify = useNotify();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const feedQueryVariables = useQueryVariables<GetFeedQueryVariables>();
  const feedQueryKey = useGetFeedQuery.getKey(feedQueryVariables);

  const contentQueryVariables = useQueryVariables<GetContentQueryVariables>();
  const contentQueryKey = useGetContentQuery.getKey(contentQueryVariables);

  function createReaction(result: ContentFragment) {
    result.reaction_count++;
    result.reacted_by.unshift({
      id: currentUser.id,
      full_name: currentUser.full_name,
      image: currentUser.image,
    });
    return result;
  }

  function removeReaction(result: ContentFragment) {
    result.reaction_count--;
    result.reacted_by = result.reacted_by.filter(item => item.id !== currentUser.id);
    return result;
  }

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: ({ content_id }) => {
      const baseFeedState = queryClient.getQueryData<FeedQueryData>(feedQueryKey);
      const baseContentState = queryClient.getQueryData<ContentQueryData>(contentQueryKey);

      const nextFeedState = produce(baseFeedState, draftState => {
        if (!draftState) {
          return;
        }

        draftState.pages.map(page => {
          const index = page.getFeed.results.findIndex(result => result.content_id === content_id);

          if (index !== -1) {
            const result = page.getFeed.results[index];

            switch (operation) {
              case 'like':
                createReaction(result);
                break;
              case 'unlike':
                removeReaction(result);
                break;
            }
          }
        });
      });

      const nextContentState = produce(baseContentState, draftState => {
        if (!draftState) {
          return;
        }

        switch (operation) {
          case 'like':
            createReaction(draftState.getContent);
            break;
          case 'unlike':
            removeReaction(draftState.getContent);
            break;
        }
      });

      if (baseFeedState) {
        queryClient.setQueryData<FeedQueryData>(feedQueryKey, nextFeedState);
      }

      if (baseContentState) {
        queryClient.setQueryData<ContentQueryData>(contentQueryKey, nextContentState);
      }

      return { baseFeedState, baseContentState };
    },

    onError: (error, mutationArgs, { baseFeedState, baseContentState }) => {
      if (baseFeedState) {
        queryClient.setQueryData<FeedQueryData>(feedQueryKey, baseFeedState);
      }

      if (baseContentState) {
        queryClient.setQueryData<FeedQueryData>(contentQueryKey, baseContentState);
      }

      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useReactions;
