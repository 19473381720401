import React, { FC, createContext, useReducer, Dispatch } from 'react';

type Action = { type: 'watch'; id: string } | { type: 'unwatch'; id: string };

interface MonitorPostContext {
  state: Set<string>;
  dispatch: Dispatch<Action>;
}

const MonitorPostContext = createContext<MonitorPostContext>({
  state: new Set<string>(),
  dispatch: () => null,
});

const MonitorPostProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, new Set<string>());

  function reducer(currentState: Set<string>, action: Action) {
    switch (action.type) {
      case 'watch':
        return currentState.add(action.id);
      case 'unwatch':
        currentState.delete(action.id);
        return currentState;
      default:
        throw new Error();
    }
  }

  return (
    <MonitorPostContext.Provider value={{ state, dispatch }}>
      {children}
    </MonitorPostContext.Provider>
  );
};

export { MonitorPostContext, MonitorPostProvider };
