import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Toolbar, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { useGetUserClientsQuery, UserClientStatus } from 'api';
import { useCognitoUser, useNotify } from 'hooks';
import { Loaders } from 'components';

import { ExistingWorkspaceLauncher } from '../components';
import { getAppImage } from 'helpers';
import { APP_IMAGES } from 'app-constants';

const MAX_WIDTH = 640;

/**
 * This is a streamlined version of the Workspaces component which
 * is used to embed workspace selection into integrations. The primary
 * difference is that workspace creation and joining is unavailable.
 */
const WorkspaceLauncher: FC = () => {
  const notify = useNotify();
  const { t } = useTranslation();
  const { authenticatedUser } = useCognitoUser();

  const { data: userClientsData, isLoading: userClientsLoading } = useGetUserClientsQuery(
    {},
    {
      onError: notify.queryError,
    }
  );

  if (userClientsLoading) {
    return <Loaders.Startup />;
  }

  const activeClients = getWithStatus([UserClientStatus.Active]).map(w => w.client);
  const autoLaunch = activeClients.length === 1;

  function getWithStatus(allowedStatuses: UserClientStatus[]) {
    if (!userClientsData.getUserClients) {
      return [];
    }

    const { items } = userClientsData.getUserClients;
    return items.filter(userClient => allowedStatuses.includes(userClient.user_client_status));
  }

  function renderActiveWorkspaces() {
    return (
      <Box pb={7}>
        <Box pb={2}>
          <Typography variant="h6">
            {t('auth:workspaceLauncherHeader', { email: authenticatedUser.getUsername() })}
          </Typography>
        </Box>
        <Grid container direction="column" spacing={2}>
          {activeClients.map(client => (
            <ExistingWorkspaceLauncher key={client.id} client={client} autoLaunch={autoLaunch} />
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <Grid container alignItems="center">
            <Grid xs>
              <div style={{ width: 242, height: 30 }}>
                <img {...getAppImage(APP_IMAGES.Logo, 30)} />
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box display="flex" justifyContent="center">
        <Box
          width={{ lg: MAX_WIDTH }}
          maxWidth={{ xs: MAX_WIDTH, sm: MAX_WIDTH, md: MAX_WIDTH }}
          pt={11}
        >
          <Grid container justifyContent="center">
            <Grid xs={12}>{renderActiveWorkspaces()}</Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment>
  );
};

export default WorkspaceLauncher;
