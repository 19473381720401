import { InfiniteData, UseMutationOptions, useQueryClient } from 'react-query';

import {
  BootstrapQuery,
  CreateGroupMutation,
  CreateGroupMutationVariables,
  GetFollowedQuery,
  useGetFollowedQuery,
} from 'api';
import { useBootstrapQueryKey, useCurrentUser, useNotify } from 'hooks';
import { FOLLOW_QUERIES_LIMIT } from 'app-constants';

type Mutation = CreateGroupMutation;
type MutationVariables = CreateGroupMutationVariables;

type FollowedQueryData = InfiniteData<GetFollowedQuery>;
type BootstrapQueryData = BootstrapQuery;

const useCreateGroup = () => {
  const notify = useNotify();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const followedQueryKey = useGetFollowedQuery.getKey({
    data: { user_id: currentUser.id, limit: FOLLOW_QUERIES_LIMIT },
  });
  const bootstrapQueryKey = useBootstrapQueryKey();

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onError: (error, mutationArgs, { baseFollowedState, baseBootstrapState }) => {
      if (baseFollowedState) {
        queryClient.setQueryData<FollowedQueryData>(followedQueryKey, baseFollowedState);
      }

      if (baseBootstrapState) {
        queryClient.setQueryData<BootstrapQueryData>(bootstrapQueryKey, baseBootstrapState);
      }

      notify.mutationError();
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(bootstrapQueryKey);
      await queryClient.refetchQueries(followedQueryKey);
    },
  };

  return mutationOptions;
};

export default useCreateGroup;
