import React, { FC } from 'react';
import { Link, TableCell, TableRow, Typography } from '@mui/material';
import { Numeric, TableCellWithOverflow } from 'components';
import moment from 'moment';

import { generateLink } from 'Links';
import theme from 'theme';

const styles = {
  tableBodyRow: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '&:nth-of-type(even)': {
      backgroundColor: 'common.white',
    },
  },
} as const;

interface IProps {
  id?: string | number;
  stat: string | number;
  title: string;
  link: 'group' | 'content' | 'profile';
}

const StatRow: FC<IProps> = ({ title, stat, link, id }) => {
  return (
    <TableRow sx={{ root: styles.tableBodyRow }}>
      <TableCellWithOverflow size="small">
        {id ? (
          <Link
            title={title}
            target="_blank"
            component={generateLink(`/${link}/${id}`)}
            underline="hover"
          >
            {title}
          </Link>
        ) : (
          <Typography title={title} noWrap>
            {title}
          </Typography>
        )}
      </TableCellWithOverflow>
      <TableCell size="small" align="right">
        {typeof stat === 'number' ? <Numeric value={stat} /> : moment(stat).format('MM/DD/YYYY')}
      </TableCell>
    </TableRow>
  );
};

export default StatRow;
