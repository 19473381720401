import React, { FC } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import { Content, useGetContentUrlParamOptionsQuery } from 'api';
import { CustomSVG } from 'components';
import { useNotify } from 'hooks';

import { Switch, UrlTrackingOptions } from '../';
import { usePostContext } from '../../PostContext';
import Widget from './WidgetContainer';

const UrlTracking: FC = () => {
  const { setFieldValue, values } = useFormikContext<Content>();
  const { state, dispatch } = usePostContext();
  const notify = useNotify();
  const { data } = useGetContentUrlParamOptionsQuery(
    { url: values.content_object.url },
    {
      enabled: state.validUrl,
      onError: notify.queryError,
      onSuccess: ({ getContentUrlParamOptions }) => {
        if (getContentUrlParamOptions?.length) {
          dispatch({ type: 'tracking', value: state.tracking });
        }
      },
    }
  );

  function onChangeSwitch() {
    if (state.tracking) {
      setFieldValue('url_param_values', []);
    }

    dispatch({ type: 'tracking', value: !state.tracking });
  }

  function renderSwitch() {
    return (
      <Switch
        checked={state.tracking}
        onChange={onChangeSwitch}
        color="primary"
        data-testid="post-radio-urlTracking"
      />
    );
  }

  const hideWidget =
    !state.validUrl || !data?.getContentUrlParamOptions || !data.getContentUrlParamOptions.length;

  if (hideWidget) {
    return null;
  }

  return (
    <Grid xs={12}>
      <Grid container>
        <Grid xs>
          <Widget
            titleKey="posting:urlTracking"
            subtitleKey="posting:guidelineUrlTracking"
            icon={<CustomSVG name="track" color={state.tracking ? 'inherit' : 'disabled'} />}
            toggle={renderSwitch()}
          >
            {state.tracking && (
              <UrlTrackingOptions urlParamOptions={data.getContentUrlParamOptions} />
            )}
          </Widget>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UrlTracking;
