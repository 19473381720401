import React, { FC } from 'react';
import {
  Box,
  IconButton,
  Tooltip as MaterialUiTooltip,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import moment from 'moment';
import {
  CartesianGrid,
  Legend,
  LegendProps,
  Line,
  LineChart as ReChartsLineChart,
  ResponsiveContainer,
  Tooltip as ReChartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { useTranslation } from 'react-i18next';

import {
  ExternalStat,
  IndividualGroupStat,
  IndividualLeaderboardEvent,
  IndividualPostStat,
  IndividualShareStat,
  IndividualUserStat,
  InternalStat,
  ReachDailyStat,
} from 'api';

import { getColor } from '../helpers';
import { Tooltip } from '../shared';
import useAnalyticsStyles from '../analyticsStyles';

const styles = {
  description: {
    padding: 0,
  },
} as const;

export interface ILineChartConfig {
  dataKey: string;
  label: string;
}

interface IProps {
  height: number;
  data: Array<
    | InternalStat
    | ExternalStat
    | IndividualGroupStat
    | IndividualLeaderboardEvent
    | IndividualPostStat
    | IndividualShareStat
    | IndividualUserStat
    | ReachDailyStat
  >;
  config: ILineChartConfig[];
}

const LineChart: FC<IProps> = ({ height, data, config }) => {
  const analyticsClasses = useAnalyticsStyles;
  const { t } = useTranslation();
  const gridColor = 'grey.300';
  const margin = { top: 24, right: 0, bottom: 24, left: 0 };
  const wrapperStyle = { bottom: 0 };

  function renderLegendItem({ value }: { value: number }, index: number) {
    const legendBgColor = getColor(index);
    const dotClass = index === 0 ? analyticsClasses.solid : analyticsClasses.dashed;
    const background =
      index === 0
        ? { background: legendBgColor }
        : {
            background: `repeating-linear-gradient(to right, ${legendBgColor} 0 6px, transparent 6px 9px)`,
          };

    return (
      <Grid key={index}>
        <Grid container alignItems="center" spacing={1}>
          <Grid>
            <Box sx={dotClass} style={background} />
          </Grid>
          <Grid>
            <Typography display="inline">{value}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderLegend({ payload }: LegendProps) {
    return (
      <Grid container spacing={3}>
        {payload.map(renderLegendItem)}
        {(config[0].dataKey === 'daily_active_users' || config[0].dataKey === 'active_users') && (
          <Grid>
            <MaterialUiTooltip title={t('analytics:users_line_graph_description')}>
              <IconButton sx={styles.description} size="large">
                <HelpOutlineRoundedIcon fontSize="small" color="disabled" />
              </IconButton>
            </MaterialUiTooltip>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <ResponsiveContainer height={height}>
      <ReChartsLineChart data={data} margin={margin}>
        <XAxis
          tickLine={false}
          dataKey="calendar_day"
          tickMargin={10}
          axisLine={{ stroke: gridColor }}
          tickFormatter={tickValue => moment(tickValue).format('DD MMM')}
        />
        <YAxis
          tickLine={false}
          orientation="right"
          tickMargin={10}
          axisLine={{ stroke: gridColor }}
        />
        <CartesianGrid stroke={gridColor} vertical={false} horizontal={false} />
        <ReChartsTooltip content={<Tooltip />} />
        <Legend wrapperStyle={wrapperStyle} content={renderLegend} />
        {config.map(({ label, dataKey }, index) => (
          <Line
            key={dataKey}
            type="monotone"
            name={label}
            dataKey={dataKey}
            stroke={getColor(index)}
            strokeWidth={2}
            strokeDasharray={index === 0 ? null : '8 8'}
            dot={false}
          />
        ))}
      </ReChartsLineChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
