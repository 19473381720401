import React, { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { noop } from 'lodash';

import { S3Object, S3ObjectInput } from 'api';
import { Loaders } from 'components';
import { getImageSrc } from 'helpers';
import { resizeImage } from 'utilities';
import { IMGIX_HOST } from 'settings';
import { useDimensions } from 'hooks';
import theme from 'theme';

interface Props {
  disableViewer?: boolean;
  photo: S3Object & S3ObjectInput;
  onClickReaction?: () => void;
}

const styles = {
  media: {
    maxHeight: 800,
    objectPosition: 'top',
  },
  dialog: {
    backgroundColor: alpha(theme.palette.common.black, 0.6),
  },
  dialogTitle: {
    margin: 0,
    paddingTop: theme.spacing(),
    borderBottom: 0,
  },
  dialogContent: {
    display: 'flex',
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    display: 'block',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  white: {
    color: theme.palette.common.white,
  },
} as const;

const Photo: FC<Props> = ({ disableViewer, photo, onClickReaction }) => {
  const { t } = useTranslation();
  const dimensions = useDimensions();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localUrl, setLocalUrl] = useState(getLocalUrl());

  useEffect(() => {
    setLocalUrl(getLocalUrl());
  }, [photo.localUri]);

  function toggleDialog() {
    setOpen(!open);
    setLoading(!loading);

    !open && onClickReaction();
  }

  function isSavedPhoto() {
    return !photo.localUri;
  }

  function getLocalUrl() {
    if (!photo.localUri) {
      return '';
    }

    return window.URL.createObjectURL(photo.localUri);
  }

  function renderDialog() {
    const fullPhotoUrl = `${IMGIX_HOST}/${photo.key}?auto=format`;

    return (
      <Dialog fullScreen open={open} onClose={toggleDialog} sx={{ paperFullScreen: styles.dialog }}>
        <DialogTitle sx={styles.dialogTitle}>
          <Typography variant="subtitle2" sx={styles.white}>
            {t('components:photoViewer')}
            {loading && (
              <Fragment>
                {' '}
                <Loaders.Standard height={10} />
              </Fragment>
            )}
          </Typography>
          <IconButton onClick={toggleDialog} size="large">
            <CloseIcon sx={styles.white} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <img
            alt="photo"
            src={fullPhotoUrl}
            onLoad={() => setLoading(false)}
            style={styles.image}
          />
        </DialogContent>
      </Dialog>
    );
  }

  function renderLocalPhoto() {
    return (
      <CardMedia
        sx={styles.media}
        component="img"
        style={{ width: dimensions.width }}
        src={localUrl}
      />
    );
  }

  function renderSavedPhoto() {
    const { width } = dimensions;

    const style = {
      cursor: disableViewer ? 'inherit' : 'zoom-in',
      ...resizeImage(photo.width, photo.height, width),
    };
    const photoUrl = `${IMGIX_HOST}/${photo.key}?w=${width || 'auto'}&auto=format`;
    const cardMediaProps = {
      ...getImageSrc(photoUrl),
      onClick: disableViewer ? null : toggleDialog,
    };

    return (
      <Fragment>
        {renderDialog()}
        <CardMedia style={style} component="img" sx={styles.media} {...cardMediaProps} />
      </Fragment>
    );
  }

  if (!isSavedPhoto()) {
    return renderLocalPhoto();
  }

  return renderSavedPhoto();
};

Photo.defaultProps = {
  disableViewer: false,
  onClickReaction: noop,
};

export default Photo;
