import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Input,
  Link,
  MenuItem,
  PaperProps,
  Select,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import { find } from 'lodash';

import theme from 'theme';
import {
  LeaderboardScoreFragment,
  QueryGetLeaderboardForUserArgs,
  useGetUserLeaderboardQuery,
} from 'api';
import { LEADERBOARD_WIDGET_LIMIT } from 'app-constants';
import { useNotify, useUserLeaderboards } from 'hooks';
import { LoaderComponents, PaddedPaper, UserAvatar } from 'components';
import { formatStat, getFullName } from 'helpers';
import { generateLink, Leaderboard } from 'Links';

interface Props {
  variables: QueryGetLeaderboardForUserArgs;
  elevation: PaperProps['elevation'];
}

const styles = {
  input: {
    backgroundColor: 'transparent',
  },
  select: {
    ...theme.typography.subtitle2,
    padding: 0,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  nativeInput: {
    '&:focus': {
      outline: 'none',
    },
  },
  item: {
    backgroundColor: 'grey.50',
  },
  button: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: 2,
  },
} as const;

const LeaderboardWidget: FC<Props> = ({ variables, elevation }) => {
  const theme = useTheme();
  const notify = useNotify();
  const configs = useUserLeaderboards();
  const [selectedConfig, setSelectedConfig] = useState(configs[0]);
  const { t } = useTranslation();

  const queryVariables = {
    leaderboard_config_id: selectedConfig.id,
    limit: LEADERBOARD_WIDGET_LIMIT,
    ...variables,
  };

  const { data, isLoading } = useGetUserLeaderboardQuery(queryVariables, {
    onError: notify.queryError,
  });

  function handleChange(configId: string) {
    const nextConfig = find(configs, ['id', configId]);
    setSelectedConfig(nextConfig);
  }

  function renderLoader() {
    return (
      <Box marginLeft={4}>
        <LoaderComponents.List rows={LEADERBOARD_WIDGET_LIMIT}>
          {rowIndex => (
            <Fragment key={rowIndex}>
              <LoaderComponents.Row spacing={1} padding="8px 0">
                <Grid>
                  <LoaderComponents.Image width={40} height={40} shape="circular" />
                </Grid>
                <Grid xs>
                  <LoaderComponents.Content height={30} index={rowIndex} maxWidth={200} />
                </Grid>
              </LoaderComponents.Row>
            </Fragment>
          )}
        </LoaderComponents.List>
      </Box>
    );
  }

  function renderSingleConfig() {
    return (
      <Typography noWrap variant="subtitle2">
        {selectedConfig?.title}
      </Typography>
    );
  }

  function renderConfigs() {
    return (
      <Select
        variant="standard"
        fullWidth
        input={<Input sx={styles.input} />}
        value={selectedConfig.id}
        onChange={event => handleChange(event.target.value)}
      >
        {configs.map(config => (
          <MenuItem key={config.id} value={config.id}>
            {config.title}
          </MenuItem>
        ))}
      </Select>
    );
  }

  function renderUser(item: LeaderboardScoreFragment) {
    return (
      <Grid
        xs={12}
        container
        alignItems="center"
        justifyContent="center"
        marginBottom={2}
        sx={{ backgroundColor: 'grey.50' }}
        key={item.user_id}
      >
        <Grid marginRight={1}>
          <UserAvatar size={40} user={item.user} />
        </Grid>
        <Grid xs>
          <Link
            display="block"
            title={getFullName(item.user)}
            variant="subtitle2"
            component={generateLink(`/profile/${item.user.id}`)}
            noWrap
            underline="hover"
          >
            {getFullName(item.user)}
          </Link>
          <Typography display="block" noWrap color="textSecondary">
            {item.user.job_title}
          </Typography>
        </Grid>
        <Grid display="inline-flex" paddingRight={1}>
          <StarIcon htmlColor={theme.palette.warning.dark} />
          <Typography variant="subtitle1">{formatStat(Number(item.score))}</Typography>
        </Grid>
      </Grid>
    );
  }

  function renderBody() {
    if (isLoading) {
      return renderLoader();
    }

    const { leaderboard_scores, user_score } = data.getLeaderboardForUser;
    const { items } = leaderboard_scores;

    return (
      <Grid xs={12} container spacing={2}>
        <Grid xs={12}>{user_score && renderUser(user_score)}</Grid>

        {!items.length && (
          <Grid>
            <Typography>
              <em>{t('components:noLeaderboardResults')}</em>
            </Typography>
          </Grid>
        )}
        {!!items.length && (
          <Grid xs={12}>
            <Box marginBottom={2}>
              <Typography paragraph color="textSecondary">
                {t('components:xTop', { count: items.length })}
              </Typography>
            </Box>

            {items.map(renderUser)}
          </Grid>
        )}
      </Grid>
    );
  }

  if (!selectedConfig) {
    return null;
  }

  return (
    <PaddedPaper elevation={elevation}>
      <Grid container spacing={2}>
        <Grid xs={12}>{configs.length === 1 ? renderSingleConfig() : renderConfigs()}</Grid>

        {renderBody()}

        <Grid xs={12}>
          <Button component={Leaderboard} fullWidth sx={styles.button}>
            {t('components:seeLeaderboards')}
          </Button>
        </Grid>
      </Grid>
    </PaddedPaper>
  );
};

export default LeaderboardWidget;
