export const TOKEN_PARAM = 't';
export const TOKEN_ATTRIBUTE = 'custom:invitation_token';
export const CLIENT_ATTRIBUTE = 'custom:client_id';
export const USER_DISABLED = 'User is disabled';
export const USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException';
export const PASSWORD_RESET_REQUIRED_EXCEPTION = 'PasswordResetRequiredException';
export const COGNITO_CHALLENGE_NAME_NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
export const AUTH_ERROR_KEY = 'authError';
export const AUTH_ERROR_MSG = 'auth:error';
export const LINK_EXPIRED_MSG = 'auth:passwordlessLinkExpired';
