import React, { FC } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';

import palette from './palette';
import theme from './index';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      layoutSingleColumnMaxWidth: number;
      layoutTwoColumnMainMaxWidth: number;
      layoutTwoColumnMainMaxWidthWide: number;
      bodyPadding: string;
      bodyPaddingOffset: number;
      palette: typeof palette;
      networks: {
        Company: string;
        x: string;
        linkedin: string;
        facebook: string;
        youtube: string;
        instagram: {
          background: string;
          color: string;
          borderRadius: number;
        };
        msTeams: string;
        slack: string;
        reddit: string;
        tiktok: string;
        weibo: string;
        wechat: string;
      };
    };
    mixins: {
      hidden: CSSProperties;
      visible: CSSProperties;
      visuallyHidden: CSSProperties;
      breakWord: CSSProperties;
      xAxisCenter: CSSProperties;
      yAxisCenter: CSSProperties;
      absoluteCenter: CSSProperties;
      sticky: CSSProperties;
      toolbar: CSSProperties;
      overflowText: (maxWidth?: number) => CSSProperties;
      gutters: (styles?: CSSProperties) => CSSProperties;
      clamp: (lines: number) => CSSProperties;
      clampInline: (lines: number) => CSSProperties;
      checkeredBackground: CSSProperties;
    };
  }
  interface ThemeOptions {
    custom?: {
      layoutSingleColumnMaxWidth: number;
      layoutTwoColumnMainMaxWidth: number;
      layoutTwoColumnMainMaxWidthWide: number;
      bodyPadding: string;
      bodyPaddingOffset: number;
      palette: typeof palette;
      networks: {
        Company: string;
        x: string;
        linkedin: string;
        facebook: string;
        youtube: string;
        instagram: {
          background: string;
          color: string;
          borderRadius: number;
        };
        msTeams: string;
        slack: string;
        reddit: string;
        tiktok: string;
        weibo: string;
        wechat: string;
      };
    };
  }
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {
    custom: {
      layoutSingleColumnMaxWidth: number;
      layoutTwoColumnMainMaxWidth: number;
      layoutTwoColumnMainMaxWidthWide: number;
      bodyPadding: string;
      bodyPaddingOffset: number;
      palette: typeof palette;
      networks: {
        Company: string;
        x: string;
        linkedin: string;
        facebook: string;
        youtube: string;
        instagram: {
          background: string;
          color: string;
          borderRadius: number;
        };
        msTeams: string;
        slack: string;
        reddit: string;
        tiktok: string;
        weibo: string;
        wechat: string;
      };
    };
    mixins: {
      hidden: CSSProperties;
      visible: CSSProperties;
      visuallyHidden: CSSProperties;
      breakWord: CSSProperties;
      xAxisCenter: CSSProperties;
      yAxisCenter: CSSProperties;
      absoluteCenter: CSSProperties;
      sticky: CSSProperties;
      toolbar: CSSProperties;
      overflowText: (maxWidth?: number) => CSSProperties;
      gutters: (styles?: CSSProperties) => CSSProperties;
      clamp: (lines: number) => CSSProperties;
      clampInline: (lines: number) => CSSProperties;
      checkeredBackground: CSSProperties;
    };
  }
}

const ThemeProvider: FC = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
