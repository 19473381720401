import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DailyUserStat, ReportingFilter } from 'api';
import { PaddedPaper, Queries } from 'components';

import { ILineChartConfig, LineChart, LineChartLoader } from '../shared';
import { LINE_CHART_HEIGHT } from '../constants';

interface IProps {
  startDate: string;
  endDate: string;
  filter: ReportingFilter;
}

const DailyUserStatsChart: FC<IProps> = ({ startDate, endDate, filter }) => {
  const { t } = useTranslation();

  function renderChart(queryResults: DailyUserStat) {
    const { data } = queryResults;

    const config: ILineChartConfig[] = [
      {
        label: t('common:active'),
        dataKey: 'active_users',
      },
      {
        label: t('common:inactive'),
        dataKey: 'inactive_users',
      },
    ];

    return <LineChart height={LINE_CHART_HEIGHT} data={data} config={config} />;
  }

  return (
    <PaddedPaper>
      <Typography variant="body2">{t('analytics:activity_by_day')}</Typography>
      <Queries.AnalyticsDailyStats
        type="dailyUserStats"
        loader={<LineChartLoader />}
        filter={filter}
        startDate={startDate}
        endDate={endDate}
      >
        {stats => renderChart(stats)}
      </Queries.AnalyticsDailyStats>
    </PaddedPaper>
  );
};

export default DailyUserStatsChart;
