import { Network, ShareContentDestination, ShareContentInput, ShareType, SocialAccount } from 'api';
import {
  useContent,
  useNetworkCapabilities,
  useProtectedClient,
  useSharePhoto,
  useSocialConnections,
} from 'hooks';

import { Share, ShareItem, ShareItemContent } from './types';
import { getClientCommentCharLimit } from 'features/posting/helpers';

interface Options {
  disablePages?: boolean;
}

const useInitialValues = ({ disablePages = false }: Options = {}): Share => {
  const content = useContent();
  const { selectedPhotoIndex } = useSharePhoto();
  const { twitterAccounts, linkedinAccounts, linkedInPages } = useSocialConnections();
  const { hasTwitterSharing, hasLinkedinSharing, hasFacebookSharing } = useNetworkCapabilities();
  const client = useProtectedClient();

  const photos = content?.content_object?.photos ?? [];
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || null;
  const selectedPhotos = photos[selectedPhotoIndex] ? [photos[selectedPhotoIndex]] : [];
  const initialValues: ShareItem[] = [];

  const common = {
    photos: selectedPhotos,
    share_at: null as ShareContentInput['share_at'],
  };

  const contentCommentary = (content?.commentary || []).reduce((all, commentary) => {
    all[commentary.network] = { commentary: commentary.commentary, mentions: commentary.mentions };
    return all;
  }, {});

  hasTwitterSharing &&
    twitterAccounts.map(account => {
      initialValues.push({
        network_id: account.id,
        user_commentary: contentCommentary[Network.Twitter]?.commentary || '',
        share_type: ShareType.Now,
        state: {
          active: true,
          limit: getClientCommentCharLimit(Network.Twitter, content, client),
          socialAccount: account,
          networkMeta: { provider: Network.Twitter, name: 'Twitter' },
          content: content as unknown as ShareItemContent,
          blockList: [],
        },
        ...common,
      });
    });

  hasLinkedinSharing &&
    linkedinAccounts.map(account => {
      initialValues.push({
        network_id: account.id,
        user_commentary: contentCommentary[Network.Linkedin]?.commentary || '',
        share_type: ShareType.Now,
        mentions: contentCommentary[Network.Linkedin]?.mentions || [],
        state: {
          active: true,
          limit: getClientCommentCharLimit(Network.Linkedin, content, client),
          socialAccount: account,
          networkMeta: { provider: Network.Linkedin, name: 'LinkedIn' },
          content: content as unknown as ShareItemContent,
          blockList: [],
        },
        ...common,
      });
    });

  hasLinkedinSharing &&
    !disablePages &&
    linkedInPages.map(page => {
      initialValues.push({
        page_id: page.identifier,
        network_id: page.id,
        user_commentary: contentCommentary[Network.Linkedin]?.commentary || '',
        share_type: ShareType.Now,
        mentions: contentCommentary[Network.Linkedin]?.mentions || [],
        state: {
          active: false,
          limit: getClientCommentCharLimit(page.provider, content, client),
          socialAccount: page as SocialAccount,
          networkMeta: { provider: Network.LinkedinPage, name: 'LinkedIn Page' },
          content: content as unknown as ShareItemContent,
          blockList: [],
        },
        ...common,
      });
    });

  if (hasFacebookSharing) {
    initialValues.push({
      user_commentary: contentCommentary[Network.Facebook]?.commentary || '',
      share_type: ShareType.Frontend,
      destination: ShareContentDestination.Facebook,
      state: {
        active: false,
        limit: getClientCommentCharLimit(Network.Facebook, content, client),
        socialAccount: null,
        networkMeta: { provider: Network.Facebook, name: 'Facebook' },
        content: content as unknown as ShareItemContent,
        blockList: [],
      },
    });
  }

  return {
    timezone,
    content_id: content?.content_id || '',
    items: initialValues,
  };
};

export default useInitialValues;
