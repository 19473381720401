import gql from 'graphql-tag';

export default gql`
  fragment Inbox on Inbox {
    id
    created_at
    updated_at
    item_read
    message
    to_user_id
    url
  }
`;
