import React, { ChangeEvent, FC } from 'react';
import {
  InputLabel,
  Unstable_Grid2 as Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import moment from 'moment';

import { ContractType } from 'api';
import { DatePickers } from 'components';
import { ClientInput } from '../../types';
import theme from 'theme';

const Contract: FC = () => {
  const { setFieldValue, values } = useFormikContext<ClientInput>();
  const minDate = moment().add(-10, 'years').format('YYYY-MM-DD');

  return (
    <Grid xs={12} container>
      <Grid xs={3}>
        <Typography variant="subtitle2">Contract</Typography>
      </Grid>
      <Grid xs={9} container alignItems="center" wrap="wrap" spacing={2}>
        <Grid xs={6}>
          <InputLabel htmlFor="contractType" shrink>
            Contract Type
          </InputLabel>
          <Select
            variant="standard"
            fullWidth
            inputProps={{
              id: 'contractType',
            }}
            value={values.contract_type || ContractType.Traditional}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFieldValue('contract_type', event.target.value);
            }}
          >
            <MenuItem value={ContractType.Traditional}>Traditional</MenuItem>
            <MenuItem value={ContractType.FairBilling}>Fair Billing</MenuItem>
            <MenuItem value={ContractType.Demo}>Demo</MenuItem>
            <MenuItem value={ContractType.Sandbox}>Sandbox</MenuItem>
            <MenuItem value={ContractType.Unlimited}>Unlimited Contracts</MenuItem>
          </Select>
        </Grid>
        <Grid xs={6}>
          <TextField
            variant="standard"
            sx={{
              Input: {
                backgroundColor: theme.palette.grey[100],
                padding: theme.spacing(2),
              },
            }}
            label="Committed User Count"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={values.contract_committed_user_count || 0}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setFieldValue('contract_committed_user_count', event.target.value)
            }
          />
        </Grid>
        <Grid xs={6}>
          <DatePickers.Select
            fullWidth
            label="Contract Start Date"
            value={values.contract_start_at}
            setValue={time => setFieldValue('contract_start_at', time)}
            minDate={minDate}
          />
        </Grid>
        <Grid xs={6}>
          <DatePickers.Select
            fullWidth
            label="Contract End Date"
            value={values.contract_end_at}
            setValue={time => setFieldValue('contract_end_at', time)}
            minDate={minDate}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contract;
