import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';

import { ContentAuditFragment } from 'api';
import { TableCellWithOverflow, TextTransform, UserAvatar } from 'components';
import { useContent } from 'hooks';
import { getFullName } from 'helpers';
import { generateLink } from 'Links';

const AuditLog: FC = () => {
  const content = useContent();

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  // Only show if an edit occurred
  if (!content.audit || content.audit.length < 2) {
    return null;
  }

  const chronological = [...content.audit].reverse();

  function handleToggle() {
    setOpen(!open);
  }

  function renderRow(audit: ContentAuditFragment, index: number) {
    if (!audit.by_user_profile) {
      return null;
    }

    const { by_user_profile, time, ACTION } = audit;
    const name = getFullName(audit.by_user_profile);
    const link = generateLink(`/profile/${audit.by_user_profile.id}`);

    return (
      <TableRow key={index}>
        <TableCell>
          <TextTransform transform="uppercase">{ACTION}</TextTransform>
        </TableCell>
        <TableCell>
          <UserAvatar user={by_user_profile} />
        </TableCell>
        <TableCellWithOverflow sx={{ maxWidth: 200 }}>
          <Link
            noWrap
            target="_blank"
            title={name}
            component={link}
            variant="body2"
            underline="hover"
          >
            {name}
          </Link>
        </TableCellWithOverflow>
        <TableCell>{moment.unix(time).format('MMM D, YYYY [at] h:mm a')}</TableCell>
      </TableRow>
    );
  }

  return (
    <Fragment>
      <Dialog fullWidth maxWidth="sm" onClose={handleToggle} open={open}>
        <DialogTitle sx={{ marginBottom: 0 }}>{t('components:auditLog')}</DialogTitle>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('common:action')}</TableCell>
              <TableCell colSpan={2}>{t('common:who')}</TableCell>
              <TableCell>{t('common:when')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{chronological.map(renderRow)}</TableBody>
        </Table>
      </Dialog>
      <MenuItem onClick={handleToggle}>{t('components:auditLog')}</MenuItem>
    </Fragment>
  );
};

export default AuditLog;
