import useInfiniteScroll from 'react-infinite-scroll-hook';
import { UseInfiniteQueryOptions } from 'react-query';

import {
  UserEngagementHistoryQuery,
  UserEngagementHistoryQueryVariables,
  EngagementHistory,
  useUserEngagementHistoryQuery,
} from 'api';
import { InfiniteQuery } from 'types/app';
import { useInfiniteQuery } from 'app-react-query/hooks';
import { useNotify } from 'hooks';
import { PAGINATION_ROOT_MARGIN } from 'app-constants';

type Query = UserEngagementHistoryQuery;
type Variables = UserEngagementHistoryQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = InfiniteQuery<EngagementHistory>;

const usePeopleSearch = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();

  const queryResult = useInfiniteQuery<Query, Variables>(
    useUserEngagementHistoryQuery,
    ({ pageParam = 0 }: { pageParam: number }) => ({ ...variables, offset: pageParam }),
    {
      initialData: {
        pages: [{ userEngagementHistory: [] }],
        pageParams: [],
      },
      onError: notify.queryError,
      getNextPageParam: (lastPage, allPages) => {
        const { userEngagementHistory } = lastPage;
        const noResults = !userEngagementHistory.length;

        if (noResults) {
          return false;
        }

        return allPages.map(page => page.userEngagementHistory).flat().length;
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = queryResult;
  const results = data.pages.map(page => page.userEngagementHistory).flat();

  const [sentinelRef] = useInfiniteScroll({
    hasNextPage,
    loading: isFetchingNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: PAGINATION_ROOT_MARGIN,
  });

  return {
    sentinelRef,
    results,
    count: 0,
    isEmpty: !results.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !results.length && isFetching,
  };
};

export default usePeopleSearch;
