import React, { CSSProperties, FC } from 'react';
import { Avatar, Box } from '@mui/material';
import Group from '@mui/icons-material/Group';

import { GroupSuggestionFragment, UserFeedFollowGroupFragment } from 'api';
import { getImageSrc } from 'helpers';
import { generateLink } from 'Links';

import { IMGIX_HOST } from 'settings';
import theme from 'theme';

interface Props {
  group: UserFeedFollowGroupFragment | GroupSuggestionFragment;
  link?: boolean;
  size?: number;
  square?: boolean;
  sx?: CSSProperties;
}

const GroupAvatar: FC<Props> = props => {
  const { group, size, square, sx, link } = props;
  const style: CSSProperties = {
    width: size,
    height: size,
    borderRadius: theme.spacing(square ? 0.5 : 4),
    ...sx,
  };

  function getGroupLink() {
    return link ? { component: generateLink(`/group/${group.id}`) } : {};
  }

  function getIconImageProps() {
    const iconUrl = `${IMGIX_HOST}/${group.icon_map.file_key}?w=${size}&h=${size}auto=format`;
    return getImageSrc(iconUrl);
  }

  function getImageProps() {
    const imageUrl = `${IMGIX_HOST}/${group.image.key}?w=${size}&h=${size}auto=format`;
    return getImageSrc(imageUrl);
  }

  if (group.image) {
    return <Avatar alt={group.title} style={style} {...getGroupLink()} {...getImageProps()} />;
  }

  if (group.icon_map) {
    const backgroundColor = group.color_map.hex;

    return (
      <Box style={{ backgroundColor, ...style }}>
        <Avatar alt={group.title} style={style} {...getGroupLink()} {...getIconImageProps()} />
      </Box>
    );
  }

  return (
    <Avatar alt={group.title} style={style}>
      <Group />
    </Avatar>
  );
};

GroupAvatar.defaultProps = {
  link: false,
  size: 32,
  square: true,
};

export default GroupAvatar;
