import * as yup from 'yup';

import i18n from 'config/i18n';

import {
  BlockedTerm,
  Content,
  MentionObject,
  Network,
  S3ObjectInput,
  ShareContentDestination,
  ShareType,
  SocialAccount,
} from 'api';
import { PostType } from 'api/public';

import { isTextOnly } from 'features/content/helpers';

export const contentSchema = yup
  .object<Content>({
    content_id: yup.string(),
    client_id: yup.string(),
    content_object: yup.object(),
    created_at: yup.number(),
    group_id_approvals: yup.array(),
    group_ids: yup.array().of(yup.string()),
    publish_at: yup.number(),
  })
  .nullable(true);

export const socialAccountSchema = yup.object<SocialAccount>().nullable(true);

export const networkMetaSchema = yup.object({
  name: yup.string().oneOf(['Facebook', 'LinkedIn', 'Twitter', 'LinkedIn Page']),
  provider: yup.string().oneOf(Object.values(Network)),
});

export const blockListSchema = yup
  .array()
  .of(
    yup.object<BlockedTerm>({
      term: yup.string(),
      start: yup.number(),
      end: yup.number(),
    })
  )
  .nullable(false);

export const itemStateSchema = yup.object({
  active: yup.boolean().oneOf([true, false]).required(),
  limit: yup.number().required(),
  socialAccount: socialAccountSchema,
  networkMeta: networkMetaSchema,
  content: contentSchema,
  blockList: blockListSchema,
});

export const itemSchema = yup.object({
  share_type: yup.string().oneOf(Object.values(ShareType)),
  destination: yup.string().oneOf(Object.values(ShareContentDestination)),
  share_at: yup.number().nullable(true),
  page_id: yup.string().nullable(true),
  network_id: yup.string().nullable(true),
  mentions: yup
    .array()
    .of(
      yup.object<MentionObject>({
        identifier: yup.string().required(),
        length: yup.number().required(),
        provider: yup.string().oneOf(Object.values(Network)).required(),
        start: yup.number().required(),
      })
    )
    .nullable(true),
  user_commentary: yup.string().when('state.active', {
    is: true,
    then: (schema: yup.StringSchema) => {
      return schema
        .max(yup.ref('state.limit'), value => {
          return i18n.t('sharing:commentaryTooLong', { max: value.max });
        })
        .test('user_commentary', i18n.t('sharing:commentaryRequired'), (value, testContext) => {
          const { content } = testContext.parent.state;
          const isEmpty = typeof value === 'undefined' || value === '';

          const isExternalShare = content.content_object.post_type === PostType.Quickshare;
          if (isExternalShare) {
            return true;
          }

          if (isTextOnly(content)) {
            return !isEmpty;
          }

          return true;
        })
        .test('state.blockList', i18n.t('sharing:blocklistWarning'), (value, testContext) => {
          const {
            state: { blockList },
          } = testContext.parent;
          if (!blockList || !blockList.length || !value) {
            return true;
          }
          const hasBlockedTerms = blockList.some(({ term }: BlockedTerm) => value.includes(term));
          return !hasBlockedTerms;
        });
    },
    otherwise: yup.string(),
  }),
  photos: yup
    .array()
    .of(
      yup.object<S3ObjectInput>({
        bucket: yup.string().required(),
        key: yup.string().required(),
        region: yup.string().required(),
      })
    )
    .nullable(true),
  state: itemStateSchema,
});

export const sharingSchema = yup
  .object({
    content_id: yup.string(),
    timezone: yup.string().nullable(true),
    items: yup.array().of(itemSchema),
  })
  .test('has-active-network', i18n.t('sharing:allNetworksDisabled'), (values, testContext) => {
    const isValid = values?.items?.some(item => item.state.active);
    return isValid
      ? true
      : testContext.createError({
          path: 'invalid',
          message: i18n.t('sharing:allNetworksDisabled'),
        });
  });
