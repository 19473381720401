import React, { ChangeEvent, FC, Fragment, HTMLAttributes, useState } from 'react';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Box,
  Button,
  Checkbox,
  Divider,
  InputLabel,
  ListSubheader,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { compact, orderBy } from 'lodash';

import { ChannelInfo, SharingChannelInfo } from 'api';
import { Loaders } from 'components';
import theme from 'theme';

import useAutoCompleteStyles from './styles/useAutoCompleteStyles';

interface IProps {
  selectedChannels: SharingChannelInfo[];
  onChannelChange: (channels: SharingChannelInfo[]) => void;
  options: ChannelInfo[];
  isLoading?: boolean;
}

const styles = {
  doneButton: {
    padding: 0,
    minWidth: 'auto',
    '&:hover': {
      background: 'transparent',
      color: 'primary.main',
    },
  },
  input: {
    fontWeight: 'bold',
  },
  emptyBorder: {
    border: 0,
  },
  popupIndicatorEmpty: {},
  divider: {
    margin: theme.spacing(0.5, 0),
  },
  listSubheader: {
    padding: theme.spacing(0.5, 1, 0, 1),
    textTransform: 'none',
    color: theme.palette.grey[600],
  },
  tag: {
    color: 'common.black',
    padding: theme.spacing(0, 0.5, 0, 1),
  },
  tagCount: {
    color: 'common.black',
  },
} as const;

const ChatChannelPicker: FC<IProps> = ({
  selectedChannels,
  onChannelChange,
  options,
  isLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [sortedChannels, setSortedChannels] = useState(getSortedChannels());

  const { t } = useTranslation();
  const autocompleteClasses = useAutoCompleteStyles;

  const activeAutocompleteClasses = {
    ...autocompleteClasses,
    popupIndicator: styles.popupIndicatorEmpty,
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function getSortedChannels() {
    return orderBy(
      options,
      channel => !!selectedChannels.find(selected => selected.channel_id === channel.channel_id),
      ['desc']
    );
  }

  function getPlaceholder() {
    if (open) {
      return t('components:chatChannelSearch');
    }

    if (!selectedChannels.length) {
      return t('components:chatChannelSuggest');
    }

    return '';
  }

  function toggleOpen() {
    setOpen(!open);
    setInputValue('');
    const channels = getSortedChannels();
    setSortedChannels(channels);
    onChannelChange(selectedChannels);
  }

  function onChangeInput(event: ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  function handleChange(event: ChangeEvent, nextValue: SharingChannelInfo[]) {
    setInputValue('');
    onChannelChange(nextValue);
  }

  function groupBy(channel: SharingChannelInfo) {
    const isSelected = !!selectedChannels.find(
      selected => selected.channel_id === channel.channel_id
    );

    if (isSelected) {
      return t('posting:sendTo');
    }

    return t('posting:recentChannels');
  }

  function renderTags(value: SharingChannelInfo[]) {
    const compactedValue = compact(value);

    if (!compactedValue.length) {
      return null;
    }

    const count = compactedValue.length - 1;
    const channel = compactedValue[0];

    return (
      <Fragment>
        <Typography
          noWrap
          key={channel.channel_id}
          variant="body2"
          onClick={toggleOpen}
          sx={styles.tag}
        >
          {channel.channel_name}
        </Typography>
        {!!count && (
          <Typography variant="body2" onClick={toggleOpen} sx={styles.tagCount}>
            {`+${count}`}
          </Typography>
        )}
      </Fragment>
    );
  }

  function renderOption(
    props: HTMLAttributes<HTMLLIElement>,
    option: SharingChannelInfo,
    { selected }: AutocompleteRenderOptionState
  ) {
    return (
      <Grid component="li" container {...props}>
        <Grid xs>{option.channel_name}</Grid>
        <Grid>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
        </Grid>
      </Grid>
    );
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    let { startAdornment, endAdornment } = params.InputProps;
    if (open) {
      startAdornment = <SearchIcon />;
      endAdornment = (
        <Button sx={styles.doneButton} color="primary" onClick={toggleOpen}>
          {t('common:done')}
        </Button>
      );
    }

    return (
      <Fragment>
        <InputLabel shrink>{t('posting:channel')}</InputLabel>
        <TextField
          {...params}
          variant="outlined"
          placeholder={getPlaceholder()}
          onChange={onChangeInput}
          fullWidth
          InputProps={{
            ...params.InputProps,
            sx: {
              notchedOutline: styles.emptyBorder,
              input: !inputValue ? styles.input : '',
            },
            startAdornment,
            endAdornment,
          }}
        />
      </Fragment>
    );
  }

  function renderGroup(params: AutocompleteRenderGroupParams) {
    return (
      <Fragment key={params.key}>
        <ListSubheader key={params.key} disableSticky sx={styles.listSubheader}>
          {params.group}
        </ListSubheader>
        {params.children}
        <Divider sx={styles.divider} />
      </Fragment>
    );
  }

  function renderPopupIcon() {
    if (isLoading) {
      return (
        <Box mr={2} mt={1}>
          <Loaders.Standard height={16} />
        </Box>
      );
    }

    return null;
  }

  return (
    <Autocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      open={open}
      disabled={isLoading}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      sx={activeAutocompleteClasses}
      options={sortedChannels}
      loading={isLoading}
      onChange={handleChange}
      getOptionLabel={({ channel_name }) => channel_name}
      isOptionEqualToValue={(option, value) => option.channel_id === value.channel_id}
      renderTags={renderTags}
      renderOption={renderOption}
      renderInput={renderInput}
      value={selectedChannels}
      inputValue={inputValue}
      groupBy={groupBy}
      renderGroup={renderGroup}
      popupIcon={renderPopupIcon()}
    />
  );
};

ChatChannelPicker.defaultProps = {
  isLoading: true,
};

export default ChatChannelPicker;
