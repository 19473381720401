import { Blocks } from './types';

export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

export const LIST_TYPES: Partial<Blocks>[] = ['bulleted-list', 'numbered-list'];

export const DEFAULT_SELECTION = {
  anchor: { path: [0, 0], offset: 0 },
  focus: { path: [0, 0], offset: 0 },
};

export const EMOJI_PARTIAL_REGEX = /^:([\w-]+)$/;
export const EMOJI_REGEX = /:([\w-]+):$/;
export const EMOJI_WHITESPACE_REGEX = /^(\s|$)/;
export const EMOJI_MAX_DISTANCE = 4;
export const EMOJI_ROW_HEIGHT = 34;
export const EMOJI_MAX_COUNT = 7;
export const EMOJI_OVERFLOW_BUFFER = 8;
