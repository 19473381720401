import React, { FC, Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import { Link, Typography, TypographyTypeMap } from '@mui/material';

import { ContentShareFragment } from 'api';
import { generateLink } from 'Links';

interface Props {
  content: ContentShareFragment;
  disableTitle?: boolean;
  disableDescription?: boolean;
  variant?: TypographyTypeMap['props']['variant'];
  color?: TypographyTypeMap['props']['color'];
}

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 255;

const UrlMetadata: FC<Props> = props => {
  const theme = useTheme();
  const { content, disableTitle, disableDescription, variant, color } = props;
  const { content_object } = content;
  let description = content_object.url_metadata?.description || '';
  let title = content_object.url_metadata?.title || '';

  if (title?.length > MAX_TITLE_LENGTH) {
    title = `${title.substring(0, MAX_TITLE_LENGTH)}...`;
  }

  if (description?.length > MAX_DESCRIPTION_LENGTH) {
    description = `${description.substring(0, MAX_DESCRIPTION_LENGTH)}...`;
  }

  return (
    <Fragment>
      {!disableTitle && (
        <Link
          sx={theme.mixins.clamp(2)}
          color="textPrimary"
          variant={variant}
          component={generateLink(`/content/${content.content_id}`)}
          underline="hover"
        >
          {title}
        </Link>
      )}
      {!disableDescription && (
        <Typography sx={theme.mixins.clamp(2)} color={color}>
          {description}
        </Typography>
      )}
    </Fragment>
  );
};

UrlMetadata.defaultProps = {
  disableTitle: false,
  disableDescription: false,
  variant: 'subtitle2',
  color: 'textPrimary',
};

export default UrlMetadata;
