import React, { FC } from 'react';
import { TableCell, Typography } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';
import Check from '@mui/icons-material/Check';

interface IProps extends TableCellProps {
  brandValue: boolean;
}

const BrandCell: FC<IProps> = ({ brandValue, ...rest }) => (
  <TableCell {...rest}>
    <Typography>{!!brandValue && <Check color="primary" />}</Typography>
  </TableCell>
);

export default BrandCell;
