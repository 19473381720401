import React, { ChangeEvent, FC } from 'react';
import { useField } from 'formik';
import {
  FormControlLabel,
  FormControlLabelProps,
  lighten,
  Switch,
  SwitchProps,
  Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';
import theme from '../theme';

interface Props {
  name: string;
  color: SwitchProps['color'];
  label?: FormControlLabelProps['label'];
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  sx?: SxProps<Theme>;
  disabled?: boolean;
  checkedFn?: (value: unknown) => boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: boolean) => void;
  hexColor?: string;
}

const styles = {
  active: {
    color: 'text.primary',
  },
  inactive: {
    color: 'text.secondary',
  },
  switch: (hexColor: string) => ({
    '&.MuiSwitch-root .MuiSwitch-switchBase': {
      color: hexColor,
    },
    '&.MuiSwitch-root .MuiSwitch-track': {
      backgroundColor: lighten(hexColor, 0.5),
    },
  }),
} as const;

const ValidatedSwitch: FC<Props> = props => {
  const { name, color, checkedFn, disabled, label, labelPlacement, sx, onChange, hexColor } = props;

  const [field] = useField({ name });
  const checked = checkedFn(field.value);

  return (
    <FormControlLabel
      label={label}
      disabled={disabled}
      labelPlacement={labelPlacement}
      sx={{ label: checked ? styles.active : styles.inactive, ...sx }}
      control={
        <Switch
          color={color}
          name={name}
          checked={checked}
          onChange={(event, nextValue) => {
            onChange ? onChange(event, nextValue) : field.onChange(event);
          }}
          sx={styles.switch(hexColor)}
        />
      }
    />
  );
};

ValidatedSwitch.defaultProps = {
  disabled: false,
  label: null,
  labelPlacement: 'start',
  checkedFn: (value: boolean) => !!value,
  hexColor: theme.palette.primary.main,
};

export default ValidatedSwitch;
