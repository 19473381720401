import { InfiniteData, UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  GetGroupFollowersQuery,
  GetGroupFollowersQueryVariables,
  UnfollowMutation,
  UnfollowMutationVariables,
  useGetGroupFollowersQuery,
} from 'api';
import { useNotify, useQueryVariables } from 'hooks';

type Mutation = UnfollowMutation;
type MutationVariables = UnfollowMutationVariables;
type QueryData = InfiniteData<GetGroupFollowersQuery>;
type QueryVariables = GetGroupFollowersQueryVariables;

const useUnfollow = () => {
  const queryClient = useQueryClient();
  const notify = useNotify();

  const variables = useQueryVariables<QueryVariables>();
  const queryKey = useGetGroupFollowersQuery.getKey(variables);

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async mutationArgs => {
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.pages.map(page => {
            const { feed_follows } = page.feedFollowing;

            page.feedFollowing.feed_follows = feed_follows.filter(feedFollow => {
              return feedFollow.user_id !== mutationArgs.target_user_id;
            });
          });
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useUnfollow;
