import { useState } from 'react';
import { CheckedIds, CheckedMode, UseCheckedState, SetCheckedState } from 'types/app';

export default (): UseCheckedState => {
  const [checkedIds, setCheckedIds] = useState<CheckedIds>([]);
  const [checkedMode, setCheckedMode] = useState<CheckedMode>(CheckedMode.OFF);
  const hasChecked = checkedMode !== CheckedMode.OFF || !!checkedIds.length;

  function resetCheckedState() {
    setCheckedIds([]);
    setCheckedMode(CheckedMode.OFF);
  }

  function setCheckedState(option: SetCheckedState) {
    if (Array.isArray(option)) {
      setCheckedIds(option);
      return;
    }

    if (option === CheckedMode.OFF) {
      setCheckedIds([]);
    }

    setCheckedMode(option);
  }

  return {
    checkedIds,
    checkedMode,
    hasChecked,
    resetCheckedState,
    setCheckedState,
  };
};
