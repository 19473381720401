import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { ES_SHARE_URL_QUERY_PARAM } from 'app-constants';
import { isPathMatch } from 'utilities';
import cookieStorage from 'cookie-storage';

import * as paths from 'paths';
import AuthError from 'features/auth/containers/AuthError';
import Landing from 'features/root/containers/Landing';
import PublicLogout from 'startup/public/PublicLogout';
import { RootSharingSandbox } from 'features/root';

const RoutesComponent: FC = () => {
  const isProtectedRootRoute = isPathMatch(paths.PROTECTED_ROOT_ROUTES);
  const shareUrl = new URLSearchParams(location.search).get(ES_SHARE_URL_QUERY_PARAM);

  if (isProtectedRootRoute) {
    cookieStorage.nextPath = `${window.location.pathname}${window.location.search}`;
  }

  if (shareUrl) {
    cookieStorage.shareUrl = shareUrl;
  }

  return (
    <Routes>
      <Route path={paths.authError} element={<AuthError />} />
      <Route path={paths.logout} element={<PublicLogout />} />
      <Route path={paths.sharingSandbox} element={<RootSharingSandbox />} />
      <Route path="*" element={<Landing />} />
    </Routes>
  );
};

export default RoutesComponent;
