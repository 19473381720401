import React, { FC } from 'react';
import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { darken } from '@mui/material/styles';

import { Network } from 'api';
import { SocialIcon } from 'components/index';
import theme from 'theme';

interface IProps extends ButtonProps {
  target?: string;
  text: string;
  network: Network;
}

const styles = {
  button: {
    padding: theme.spacing(0.5, 7),
    color: 'common.white',
    border: 'none',
    borderRadius: 4,
    width: 94,
  },
  twitter: {
    backgroundColor: theme.custom.networks.x,
    '&:hover': {
      backgroundColor: darken(theme.custom.networks.x, 0.1),
    },
  },
  linkedin: {
    backgroundColor: theme.custom.networks.linkedin,
    '&:hover': {
      backgroundColor: darken(theme.custom.networks.linkedin, 0.1),
    },
  },
  facebook: {
    backgroundColor: theme.custom.networks.facebook,
    '&:hover': {
      backgroundColor: darken(theme.custom.networks.facebook, 0.1),
    },
  },
  youtube: {
    backgroundColor: theme.custom.networks.youtube,
    '&:hover': {
      backgroundColor: darken(theme.custom.networks.youtube, 0.1),
    },
  },
  instagram: {
    backgroundImage:
      'radial-gradient(circle at 95% 200%, hsla(19, 92%, 59%, 1) 12%, hsla(19, 92%, 59%, 1) 32%, hsla(348, 75%, 57%, 1) 52%, hsla(315, 55%, 46%, 1) 65%, hsla(246, 52%, 57%, 1) 93%)',
  },
  reddit: {
    backgroundColor: theme.custom.networks.reddit,
    '&:hover': {
      backgroundColor: darken(theme.custom.networks.reddit, 0.1),
    },
  },
  tiktok: {
    backgroundColor: theme.custom.networks.tiktok,
    '&:hover': {
      backgroundColor: darken(theme.custom.networks.tiktok, 0.1),
    },
  },
  weibo: {
    backgroundColor: theme.custom.networks.weibo,
    '&:hover': {
      backgroundColor: darken(theme.custom.networks.weibo, 0.1),
    },
  },
  wechat: {
    backgroundColor: theme.custom.networks.wechat,
    '&:hover': {
      backgroundColor: darken(theme.custom.networks.wechat, 0.1),
    },
  },
} as const;

const SocialButton: FC<IProps> = ({ network, text, ...rest }) => {
  const networkLowercase = network.toLowerCase() as Network;

  return (
    <Button
      variant="contained"
      sx={{ ...styles.button, ...styles[networkLowercase] }}
      endIcon={<SocialIcon state="inverted" provider={networkLowercase} size={20} />}
      {...rest}
    >
      {text}
    </Button>
  );
};

export default SocialButton;
