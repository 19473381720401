import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PublishIcon from '@mui/icons-material/Publish';

import { ContentInput, S3ObjectInput } from 'api';
import { AspectRatios } from 'types/app';
import { IMGIX_HOST } from 'settings';
import { getVideo, getVideoPoster, hasVideoPoster } from 'features/content/helpers';
import { Loaders, UploadDialog, Uploader, UploadInput, Video } from 'components';
import { useCurrentUser, useNotify } from 'hooks';
import { THUMBNAIL_SIZE } from 'app-constants';
import uploadFile from 'app-react-query/utilities/upload-file';

import { getVideoCanvas, uploadVideoPoster } from '../../helpers';
import { useUploadCount } from './UploadCountProvider';

const styles = {
  icon: {
    backgroundColor: 'common.white',
    color: 'primary.main',
    borderRadius: '50%',
    position: 'absolute',
    left: 8,
    top: 8,
  },
  button: {
    width: THUMBNAIL_SIZE,
    height: THUMBNAIL_SIZE,
    '&:hover': {
      background: 'grey.200',
    },
  },
  buttonLabel: {
    flexDirection: 'column',
  },
} as const;

const VideoPreview: FC = () => {
  const notify = useNotify();
  const currentUser = useCurrentUser();
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<ContentInput>();

  const [uploadProgress, setUploadProgress] = useState(0);
  const [posterObjectUrl, setPosterObjectUrl] = useState(getInitialVideoPoster());
  const [thumbnailWidth, setThumbnailWidth] = useState(THUMBNAIL_SIZE);
  const videoRef = useRef<HTMLVideoElement>(null);
  const uploadCount = useUploadCount();

  const video = getVideo(values);
  const localUri = video?.localUri;
  const buttonClasses = {
    root: styles.button,
    label: styles.buttonLabel,
  };

  useEffect(() => {
    const needsUpload = !!localUri;

    async function sendVideo() {
      try {
        uploadCount.inc();
        await uploadFile(video, value => setUploadProgress(value));
        setFieldValue('content_object.media.video.localUri', undefined);
      } catch (error) {
        notify.mutationError();
      } finally {
        uploadCount.dec();
      }
    }

    if (needsUpload) {
      void sendVideo();
    }

    videoRef.current?.addEventListener('loadeddata', getDelayedCaptureFrame);

    return () => {
      URL.revokeObjectURL(posterObjectUrl);
      videoRef.current?.addEventListener('loadeddata', getDelayedCaptureFrame);
    };
  }, [localUri]);

  function getInitialVideoPoster() {
    const poster = getVideoPoster(values);
    return !poster ? '' : `${IMGIX_HOST}/${poster}?w=640&h=360&auto=format`;
  }

  function getDelayedCaptureFrame() {
    /*
     * This setTimeout is a required workaround until
     * requestVideoFrameCallback is standardized
     * See https://web.dev/requestvideoframecallback-rvfc/
     */
    if (!hasVideoPoster(values)) {
      setTimeout(() => handleCaptureFrame(true), 500);
    }

    const thumbnailWidth = videoRef.current
      ? Math.round((videoRef.current.videoWidth * THUMBNAIL_SIZE) / videoRef.current.videoHeight)
      : THUMBNAIL_SIZE;

    setThumbnailWidth(thumbnailWidth);
  }

  async function handlePosterUpload(blob: Blob, skipVisualPosterUpdate = false) {
    try {
      uploadCount.inc();
      const fileKey = await uploadVideoPoster(blob, video, currentUser.id);
      setFieldValue('content_object.media.video.poster', fileKey);

      if (!skipVisualPosterUpdate) {
        videoRef.current.poster = `${IMGIX_HOST}/${fileKey}?w=640&h=360&auto=format`;
      }
    } catch (error) {
      notify.error({ message: t('error:posterUploadError') });
    } finally {
      uploadCount.dec();
    }
  }

  function handleCaptureFrame(skipVisualPosterUpdate = false) {
    URL.revokeObjectURL(posterObjectUrl);

    const canvas = getVideoCanvas(videoRef.current);
    canvas.toBlob(blob => {
      setPosterObjectUrl(URL.createObjectURL(blob));
      void handlePosterUpload(blob, skipVisualPosterUpdate);
    });
  }

  function handleSavePhoto(s3Object: S3ObjectInput) {
    URL.revokeObjectURL(posterObjectUrl);
    setPosterObjectUrl(URL.createObjectURL(s3Object.localUri as unknown as Blob));
    void handlePosterUpload(s3Object.localUri as unknown as Blob);
  }

  function renderPoster() {
    if (!posterObjectUrl || !videoRef.current?.videoWidth) {
      return (
        <Box width={thumbnailWidth} height={THUMBNAIL_SIZE} position="relative">
          <Loaders.Button size={24} />
        </Box>
      );
    }

    return (
      <Box width={thumbnailWidth} height={THUMBNAIL_SIZE} position="relative">
        <CheckCircleIcon sx={styles.icon} />
        <img
          alt="Video Poster"
          src={posterObjectUrl}
          style={{ width: thumbnailWidth, height: THUMBNAIL_SIZE }}
        />
      </Box>
    );
  }

  function renderUploader() {
    return (
      <Uploader onSave={handleSavePhoto}>
        {uploaderProps => (
          <Fragment>
            <UploadInput onUpload={uploaderProps.onUpload}>
              <Button component="span" sx={buttonClasses}>
                <PublishIcon />
                <Typography variant="caption">{t('posting:upload')}</Typography>
              </Button>
            </UploadInput>
            <UploadDialog
              aspectRatio={AspectRatios.none}
              i18nKeyTitle="posting:photo"
              i18nKeyReplace="components:replaceImage"
              {...uploaderProps}
            />
          </Fragment>
        )}
      </Uploader>
    );
  }

  return (
    <Card>
      <LinearProgress variant="determinate" value={uploadProgress} />
      <Video ref={videoRef} video={video} />
      <CardContent>
        <Box paddingTop={1}>
          <Box paddingBottom={0.5}>
            <Typography color="textSecondary">{t('posting:chooseThumbnail')}</Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid>{renderPoster()}</Grid>
            <Grid>
              <Button onClick={() => handleCaptureFrame()} component="span" sx={buttonClasses}>
                <Fragment>
                  <AspectRatioIcon />
                  <Typography variant="caption">{t('common:capture')}</Typography>
                </Fragment>
              </Button>
            </Grid>
            <Grid>{renderUploader()}</Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default VideoPreview;
