import React, { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, ClickAwayListener } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { toggleScroll } from 'utilities';

const SCROLL_BLOCK_KEY = 'mobileSubmenu';

interface Props {
  selected: ReactNode;
  children: ReactNode;
  expanded: boolean;
  onToggle: (isExpanded: boolean) => void;
}

const styles = {
  root: {
    position: 'fixed',
    top: 60, // Toolbar Height
    left: 0,
    right: 0,
    zIndex: 1,
  },
  expanded: {
    maxHeight: `calc(100vh - ${4})`,
    overflowY: 'auto',
  },
} as const;

const MobileSubmenu: FC<Props> = props => {
  const { selected, children, expanded, onToggle } = props;
  const location = useLocation();

  useEffect(() => {
    toggleScroll(SCROLL_BLOCK_KEY, expanded);
  }, [expanded]);

  useEffect(() => {
    toggleScroll(SCROLL_BLOCK_KEY, false);
    onToggle(false);
  }, [location]);

  return (
    <ClickAwayListener onClickAway={() => onToggle(false)}>
      <Accordion
        square
        expanded={expanded}
        sx={{ root: styles.root, expanded: styles.expanded }}
        onChange={(event, isExpanded: boolean) => onToggle(isExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{selected}</AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  );
};

export default MobileSubmenu;
