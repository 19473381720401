import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import { CustomSVG } from 'components';

import { LINE_CHART_LOADER_HEIGHT } from '../constants';
import useStyles from '../analyticsStyles';
import { Box } from '@mui/material';

const LineChartLoader: FC = () => {
  const classes = useStyles;
  const theme = useTheme();
  const fontSize = theme.typography.pxToRem(LINE_CHART_LOADER_HEIGHT / 3);

  return (
    <Box style={{ height: LINE_CHART_LOADER_HEIGHT }} sx={classes.loadingWrapper}>
      <CustomSVG style={{ fontSize }} sx={classes.loading} name="chartLine" />
    </Box>
  );
};

export default LineChartLoader;
