import React, { FC } from 'react';

import { getAppImage } from 'helpers';
import { APP_IMAGES } from 'app-constants';
import { Box } from '@mui/material';

const styles = {
  root: {
    height: 400,
    width: '100%',
  },
} as const;

const EmbedPlaceholder: FC = () => {
  return <Box sx={styles.root} {...getAppImage(APP_IMAGES.SocialLoading)} />;
};

export default EmbedPlaceholder;
