import React, { FC, createContext, useEffect, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import AWSAuth, { CognitoUser } from '@aws-amplify/auth';
import { Hub, HubCapsule, HubPayload } from '@aws-amplify/core';
import { changeUser, openSession } from '@braze/web-sdk';

import { AWSAmplifyAuthEvents } from 'types/app';
import { Loaders } from 'components';
import {
  PASSWORD_RESET_REQUIRED_EXCEPTION,
  USER_NOT_CONFIRMED_EXCEPTION,
} from 'features/auth/constants';
import * as paths from 'paths';

interface ICognitoUserContext {
  id: string;
  authenticatedUser: CognitoUser;
}

interface IProps {
  children: (props: ICognitoUserContext) => ReactNode;
}

const CognitoUserContext = createContext<ICognitoUserContext>({
  id: '',
  authenticatedUser: null,
});

const CognitoUserProvider: FC<IProps> = ({ children }) => {
  const navigate = useNavigate();
  const [cognitoState, setCognitoState] = useState<ICognitoUserContext>();

  useEffect(() => {
    Hub.listen('auth', listener);
    void setUserStatus();
  }, []);

  function listener(data: HubCapsule) {
    switch (data.payload.event) {
      case AWSAmplifyAuthEvents.signIn:
        void handleSignIn();
        break;
      case AWSAmplifyAuthEvents.signUp_failure:
        handleSignInFailure(data.payload);
        break;
      case AWSAmplifyAuthEvents.signUp:
        handleSignUp(data.payload);
        break;
      case AWSAmplifyAuthEvents.forgotPassword:
        navigate(paths.forgotPasswordSubmit);
        break;
      case AWSAmplifyAuthEvents.forgotPasswordSubmit:
        navigate(paths.login);
        break;
      case AWSAmplifyAuthEvents.signIn_failure:
        handleSignInFailure(data.payload);
        break;
    }
  }

  async function handleSignIn() {
    await setUserStatus();
  }

  function handleSignInFailure(payload: HubPayload) {
    if (payload.data.code === USER_NOT_CONFIRMED_EXCEPTION) {
      navigate(paths.confirmSignup);
    }

    if (payload.data.code === PASSWORD_RESET_REQUIRED_EXCEPTION) {
      navigate(paths.forgotPassword);
    }
  }

  function handleSignUp(payload: HubPayload) {
    const { userSub } = payload.data;
    window?.analytics?.alias(userSub);

    if (!payload.data.userConfirmed) {
      navigate(paths.confirmSignup);
    }
  }

  async function setUserStatus() {
    try {
      const authenticatedUser = await AWSAuth.currentAuthenticatedUser();
      const idToken = authenticatedUser.getSignInUserSession().getIdToken().payload;
      const id = idToken['custom:user_profile_id'] || idToken.sub;

      setCognitoState({
        id,
        authenticatedUser,
      });
    } catch (error) {
      const params = new URLSearchParams(location.search);
      if (params.get('code') && params.get('state')) {
        // If we have a code and state yet getting the authenticated user failed, then there is probably an issue with the
        // firewall blocking the background token request

        sessionStorage.setItem('authError', 'check_firewall');
        navigate(paths.authError);
      }
      setCognitoState(null);
    }
  }

  if (cognitoState && cognitoState.id) {
    changeUser(cognitoState.id);
    openSession();
  }

  if (cognitoState === undefined) {
    return <Loaders.Startup />;
  }

  return (
    <CognitoUserContext.Provider value={cognitoState}>
      {children(cognitoState)}
    </CognitoUserContext.Provider>
  );
};

export { CognitoUserContext, CognitoUserProvider };
