import React, { createContext, FC, useState } from 'react';
import { filter } from 'lodash';

import { ContentCommentary, Network } from 'api';
import { useContent, useReleases } from 'hooks';
import { randomNumberGenerator } from 'utilities';

interface Variations {
  twitterCommentary: ContentCommentary[];
  linkedinCommentary: ContentCommentary[];
  facebookCommentary: ContentCommentary[];
  instagramCommentary: ContentCommentary[];
  twitterCommentaryIndex: number;
  linkedinCommentaryIndex: number;
  facebookCommentaryIndex: number;
  instagramCommentaryIndex: number;
  selectedTwitterCommentary: string;
  selectedLinkedinCommentary: string;
  selectedFacebookCommentary: string;
  selectedInstagramCommentary: string;
  selectedPhotoIndex: number;
  setPhotoIndex: (idx: number) => void;
  setShareCopyIndex: (index: number, network: Network) => void;
}

export const ContentVariationsContext = createContext<Variations>({
  twitterCommentary: [],
  linkedinCommentary: [],
  facebookCommentary: [],
  instagramCommentary: [],
  twitterCommentaryIndex: 0,
  linkedinCommentaryIndex: 0,
  facebookCommentaryIndex: 0,
  instagramCommentaryIndex: 0,
  selectedTwitterCommentary: '',
  selectedLinkedinCommentary: '',
  selectedFacebookCommentary: '',
  selectedInstagramCommentary: '',
  selectedPhotoIndex: 0,
  setPhotoIndex: () => undefined,
  setShareCopyIndex: () => undefined,
});

export const ContentVariationsProvider: FC = ({ children }) => {
  const content = useContent();
  const releases = useReleases();
  const { content_object, commentary } = content;

  const hasVariations = releases.includes('shareVariations');
  const twitterCommentary = filter(commentary, ['network', Network.Twitter]);
  const linkedinCommentary = filter(commentary, ['network', Network.Linkedin]);
  const facebookCommentary = filter(commentary, ['network', Network.Facebook]);
  const instagramCommentary = filter(commentary, ['network', Network.Instagram]);

  const [photoIndex, setPhotoIndex] = useState(getInitialPhotoIndex());
  const [twitterCommentaryIndex, setTwitterCommentaryIndex] = useState(
    getInitialCommentaryIndex(twitterCommentary)
  );
  const [linkedinCommentaryIndex, setLinkedinCommentaryIndex] = useState(
    getInitialCommentaryIndex(linkedinCommentary)
  );
  const [facebookCommentaryIndex, setFacebookCommentaryIndex] = useState(
    getInitialCommentaryIndex(facebookCommentary)
  );
  const [instagramCommentaryIndex, setInstagramCommentaryIndex] = useState(
    getInitialCommentaryIndex(instagramCommentary)
  );

  function getInitialPhotoIndex() {
    if (!hasVariations) {
      return 0;
    }

    return randomNumberGenerator(content_object.photos?.length || 0);
  }

  function getInitialCommentaryIndex(networkCommentary: ContentCommentary[]) {
    if (!hasVariations) {
      return 0;
    }

    return randomNumberGenerator(networkCommentary?.length || 0);
  }

  function setShareCopyIndex(index: number, network: Network) {
    switch (network) {
      case Network.Twitter:
        setTwitterCommentaryIndex(index);
        break;
      case Network.Linkedin:
        setLinkedinCommentaryIndex(index);
        break;
      case Network.Facebook:
        setFacebookCommentaryIndex(index);
        break;
      case Network.Instagram:
        setInstagramCommentaryIndex(index);
        break;
      default:
        throw new Error('ContentVariations setShareCopyIndex: unknown network');
    }
  }

  const values = {
    twitterCommentary,
    linkedinCommentary,
    facebookCommentary,
    instagramCommentary,
    twitterCommentaryIndex,
    linkedinCommentaryIndex,
    facebookCommentaryIndex,
    instagramCommentaryIndex,
    selectedTwitterCommentary: twitterCommentary[twitterCommentaryIndex]?.commentary ?? '',
    selectedLinkedinCommentary: linkedinCommentary[linkedinCommentaryIndex]?.commentary ?? '',
    selectedFacebookCommentary: facebookCommentary[facebookCommentaryIndex]?.commentary ?? '',
    selectedInstagramCommentary: instagramCommentary[instagramCommentaryIndex]?.commentary ?? '',
    selectedPhotoIndex: photoIndex,
    setPhotoIndex,
    setShareCopyIndex,
  };

  return (
    <ContentVariationsContext.Provider value={values}>{children}</ContentVariationsContext.Provider>
  );
};
