import React, { ChangeEvent, FC, useState } from 'react';
import {
  Button,
  Divider,
  Unstable_Grid2 as Grid,
  Paper,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

import { ClientStatus } from 'api';
import { LargeTab, Layouts } from 'components';

import { CreateClient } from '../Links';
import { ClientTable } from '../components/Client';
import { PAGINATION_LIMIT } from 'features/admin/constants';
import useSearchClients from 'clientSuccess/hooks/useSearchClients';

const ClientList: FC = () => {
  const [variables, setVariables] = useState({
    search_filter: {
      query: '',
      client_status__eq: ClientStatus.Active,
    },
    limit: PAGINATION_LIMIT,
    count: true,
  });
  const { t } = useTranslation();
  const { isExhausted, isInitialLoad, results, count, sentinelRef } = useSearchClients(variables);

  function renderHeader() {
    return (
      <Grid container alignItems="center">
        <Grid xs>
          <Tabs
            value={variables.search_filter.client_status__eq}
            onChange={(event, newValue) => {
              setVariables({
                ...variables,
                search_filter: {
                  ...variables.search_filter,
                  client_status__eq: newValue,
                },
              });
            }}
          >
            <LargeTab value={ClientStatus.Active} label="Active" />
            <LargeTab value={ClientStatus.Disabled} label="Suspended" />
          </Tabs>
        </Grid>
        <Grid>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
            <Grid>
              {!isInitialLoad && (
                <Typography variant="body2">{t('components:xResult', { count })}</Typography>
              )}
            </Grid>
            <Grid>
              <TextField
                variant="outlined"
                sx={{ Input: { padding: 1 } }}
                placeholder="Search"
                value={variables.search_filter.query}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setVariables({
                    ...variables,
                    search_filter: {
                      ...variables.search_filter,
                      query: event.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid>
              <Button
                component={CreateClient}
                variant="contained"
                color="primary"
                endIcon={<AddIcon />}
              >
                New Client
              </Button>
            </Grid>
            <Grid />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderMain() {
    return (
      <Paper>
        {renderHeader()}
        <Divider />
        <ClientTable
          results={results}
          isExhausted={isExhausted}
          isInitialLoad={isInitialLoad}
          sentinelRef={sentinelRef}
        />
      </Paper>
    );
  }

  return <Layouts.App1Column desktopSize={12} main={renderMain()} />;
};

export default ClientList;
