import React, { FC, KeyboardEvent, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Box, List, Typography } from '@mui/material';
import theme from 'theme';

interface Props {
  children: ReactNode;
  i18nKey: string;
  onKeyDown: (event: KeyboardEvent) => void;
}

const styles = {
  wrapper: {
    position: 'absolute',
    left: -9,
    right: -9,
    backgroundColor: 'common.white',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderTop: 0,
    boxShadow: theme.shadows[1],
    zIndex: theme.zIndex.modal,
  },
  typography: {
    padding: theme.spacing(0.5, 2),
    fontWeight: theme.typography.fontWeightRegular,
  },
} as const;

const SearchList: FC<Props> = ({ children, i18nKey, onKeyDown }) => (
  <Box sx={styles.wrapper}>
    <Typography variant="body2" sx={styles.typography}>
      <Trans i18nKey={i18nKey} />
    </Typography>
    <List onKeyDown={onKeyDown}>{children}</List>
  </Box>
);

export default SearchList;
