import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { UrlMetadata } from 'api';

interface Props {
  title: UrlMetadata['title'];
}

const Title: FC<Props> = ({ title }) => {
  return (
    <Typography color="textPrimary" variant="h4" sx={{ marginTop: 1, marginBottom: 0.5 }}>
      {title}
    </Typography>
  );
};

export default Title;
