import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TablePagination as MuiTablePagination } from '@mui/material';
import { TablePaginationProps } from '@mui/material/TablePagination';

import { ROWS_PER_PAGE_OPTIONS } from '../constants';

type Props = Omit<TablePaginationProps, 'count'> & { disabled: boolean };

const TablePagination: FC<Props> = ({ disabled, ...tablePaginationProps }) => {
  const { t } = useTranslation();

  return (
    <MuiTablePagination
      count={-1}
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      labelRowsPerPage={t('analytics:rows_per_page')}
      labelDisplayedRows={({ from, to }) => t('analytics:table_displayed_rows', { from, to })}
      nextIconButtonProps={{ disabled }}
      {...tablePaginationProps}
    />
  );
};

export default TablePagination;
