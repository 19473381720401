import React, { FC, ReactNode } from 'react';
import { Box, Paper, Unstable_Grid2 as Grid } from '@mui/material';

import { Layouts } from 'components';

const MAX_WIDTH = 760;
const styles = {
  root: {
    paddingBottom: 20,
  },
  tabs: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    border: 0,
  },
  container: {
    margin: '0 auto',
    justifyContent: 'center',
  },
} as const;

interface Props {
  header: ReactNode;
  tabs: ReactNode;
  body: ReactNode;
}

const Layout: FC<Props> = ({ header, tabs, body, children }) => {
  function renderMain() {
    return (
      <Grid container rowSpacing={2} maxWidth={{ lg: MAX_WIDTH }} sx={styles.container}>
        <Grid xs={12}>{header}</Grid>
        <Grid xs={12}>
          <Paper variant="outlined" sx={styles.tabs}>
            {tabs}
          </Paper>
          {body}
        </Grid>

        {children}
      </Grid>
    );
  }

  return (
    <Box sx={styles.root}>
      <Layouts.App1Column desktopSize={8} main={renderMain()} />
    </Box>
  );
};

export default Layout;
