import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Element as SlateElement } from 'slate';
import { useSlate } from 'slate-react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import TitleIcon from '@mui/icons-material/Title';

import { isBlockActive, toggleBlock } from '../helpers';
import { Blocks } from '../types';
import { useSelectionContext } from '../Contexts';

const BlockGroup: FC = () => {
  const editor = useSlate();
  const { actions: selectionActions } = useSelectionContext();
  const { t } = useTranslation();

  function isActiveBlock(format: SlateElement['type']) {
    return isBlockActive(editor, format);
  }

  function handleBlockChange(event: MouseEvent<HTMLElement>, formats: Blocks[]) {
    selectionActions.ensureEditorFocus();
    formats.forEach(format => toggleBlock(editor, format));
  }

  return (
    <ToggleButtonGroup onChange={handleBlockChange}>
      <ToggleButton
        title={t('components:editorBulletedList')}
        selected={isActiveBlock('bulleted-list')}
        value="bulleted-list"
      >
        <FormatListBulletedRoundedIcon />
      </ToggleButton>
      <ToggleButton
        title={t('components:editorNumberedList')}
        selected={isActiveBlock('numbered-list')}
        value="numbered-list"
      >
        <FormatListNumberedRoundedIcon />
      </ToggleButton>
      <ToggleButton
        title={t('components:editorTitle')}
        selected={isActiveBlock('title')}
        value="title"
      >
        <TitleIcon />
      </ToggleButton>
      <ToggleButton
        title={t('components:editorSubtitle')}
        selected={isActiveBlock('subtitle')}
        value="subtitle"
      >
        <TitleIcon fontSize="inherit" />
      </ToggleButton>
      <ToggleButton
        title={t('components:editorBlockquote')}
        selected={isActiveBlock('block-quote')}
        value="block-quote"
      >
        <FormatQuoteIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default BlockGroup;
