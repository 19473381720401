import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

interface Props {
  visible: boolean;
}

const EmbedWrapper: FC<Props> = ({ children, visible }) => {
  const theme = useTheme();

  return <Box sx={visible ? theme.mixins.visible : theme.mixins.visuallyHidden}>{children}</Box>;
};

export default EmbedWrapper;
