import React, { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { LinkedInPage, Network, SocialAccount, useUpdateUserProfileMutation } from 'api';
import { useCurrentUser, useNotify, usePrevious } from '../hooks';

export const SOCIAL_MEDIA_CONNECTIONS = [Network.Linkedin, Network.Twitter];
export const THIRD_PARTY_INTEGRATIONS = [Network.Msteams, Network.Slack];

export interface SocialConnections {
  socialAccounts: SocialAccount[];
  setSocialAccounts: (socialAccounts: SocialAccount[]) => void;
  connections: SocialAccount[];
  integrations: SocialAccount[];
  linkedInPages: LinkedInPage[];
}

export const SocialConnectionsContext = createContext<SocialConnections>({
  socialAccounts: [],
  setSocialAccounts: null,
  connections: [],
  integrations: [],
  linkedInPages: [],
});

export interface SocialConnectionsProviderProps {
  connections: SocialAccount[];
  pages: LinkedInPage[];
  children: ReactNode;
}

export const SocialConnectionsProvider: FC<SocialConnectionsProviderProps> = ({
  connections,
  pages,
  children,
}) => {
  const notify = useNotify();
  const currentUser = useCurrentUser();
  const { id, smart_schedule_viewed, smart_schedule_acknowledgement } = currentUser;
  const prevConnections = usePrevious(connections);

  const [socialAccounts, setSocialAccounts] = useState<SocialAccount[]>([]);
  const state = {
    socialAccounts,
    setSocialAccounts,
    connections: getSocialConnections(),
    integrations: getIntegrations(),
    linkedInPages: pages,
  };

  const { mutate: updateUserProfile } = useUpdateUserProfileMutation({
    onError: notify.mutationError,
  });

  useEffect(() => {
    setSocialAccounts(connections);
    if (smart_schedule_acknowledgement && smart_schedule_viewed) {
      if (prevConnections !== undefined && prevConnections?.length !== connections.length) {
        updateUserProfile({
          data: {
            id,
            smart_schedule_viewed: false,
          },
        });
      }
    }
  }, [connections.length]);

  function getIntegrations() {
    return socialAccounts.filter(connection => {
      return THIRD_PARTY_INTEGRATIONS.includes(connection.provider);
    });
  }

  function getSocialConnections() {
    return socialAccounts.filter(connection => {
      return SOCIAL_MEDIA_CONNECTIONS.includes(connection.provider);
    });
  }

  return (
    <SocialConnectionsContext.Provider value={state}>{children}</SocialConnectionsContext.Provider>
  );
};
