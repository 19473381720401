import React, { FC, useState } from 'react';
import { some } from 'lodash';

import {
  ReactionType,
  useAddReactionAnalyticsMutation,
  useCreateReactionMutation,
  useDeleteReactionMutation,
} from 'api';
import { Heart } from 'components';
import { useContent, useCurrentUser } from 'hooks';
import { useReactions } from 'features/content/updaters';

const Reactions: FC = () => {
  const content = useContent();
  const currentUser = useCurrentUser();

  const [liked, setLiked] = useState(() => {
    const { reacted_by } = content;
    return some(reacted_by, { id: currentUser.id });
  });
  const [count, setCount] = useState(content.reaction_count || 0);

  const likeMutationHandler = useLikeMutationHandler([liked, setLiked]);
  const unlikeMutationHandler = useUnlikeMutationHandler([liked, setLiked]);
  const { mutate: addReactionAnalytics } = useAddReactionAnalyticsMutation();

  function handleClick() {
    const { content_id } = content;
    const mutation = liked
      ? unlikeMutationHandler.deleteReaction
      : likeMutationHandler.createReaction;

    setCount(liked ? count - 1 : count + 1);
    mutation({ content_id, reaction_type: ReactionType.Like });

    return addReactionAnalytics({ content_id, reaction_type: ReactionType.Like });
  }

  return (
    <Heart
      liked={liked}
      onClick={handleClick}
      handlerState={liked ? unlikeMutationHandler : likeMutationHandler}
      count={count}
    />
  );
};

const useLikeMutationHandler = (likedState: Array<any>) => {
  const [liked, setLiked] = likedState;
  const createMutationOptions = useReactions('like');
  const {
    mutate: createReaction,
    isLoading,
    isSuccess,
    isIdle,
  } = useCreateReactionMutation({
    ...createMutationOptions,
    onSuccess: () => setLiked(!liked),
  });
  return { createReaction, isLoading, isSuccess, isIdle };
};

const useUnlikeMutationHandler = (likedState: Array<any>) => {
  const [liked, setLiked] = likedState;
  const deleteMutationOptions = useReactions('unlike');
  const {
    mutate: deleteReaction,
    isLoading,
    isSuccess,
    isIdle,
  } = useDeleteReactionMutation({
    ...deleteMutationOptions,
    onSuccess: () => setLiked(!liked),
  });
  return { deleteReaction, isLoading, isSuccess, isIdle };
};

export default Reactions;
