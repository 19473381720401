import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { Box, Button, Unstable_Grid2 as Grid, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import {
  CsUpdateClientInput,
  GetUserSearchesV2QueryVariables,
  SearchSortFieldsV2,
  useCreateScimTokenMutation,
  UserProfileSearchFragment,
  UserStatus,
  UserType,
  useSearchScimTokensQuery,
} from 'api';
import { useGetUserSearchesV2 } from 'features/admin/hooks';
import { useDebounce } from 'use-debounce/lib';
import { Select } from 'components/DatePickers';
import { EmptyState, Loaders } from 'components';
import ScimTokenRow from './ScimTokenRow';
import moment from 'moment';
import { useNotify } from 'hooks';

interface IProps {
  client: CsUpdateClientInput;
}

const ScimTokenForm: FC<IProps> = ({ client }) => {
  const searchScimTokensQuery = useSearchScimTokensQuery({
    search_filter: { client_id__eq: client.id },
  });
  const [activeUser, setActiveUser] = useState(null);
  const [userSearchVariables, setUserSearchVariables] = useState<GetUserSearchesV2QueryVariables>({
    client_id: client.id,
    sort: SearchSortFieldsV2.FullName,
    user_status: UserStatus.Active,
    user_type: UserType.Admin,
    q: '',
    size: 5,
    next_id: '',
  });

  const [debouncedUserSearchVariables] = useDebounce(userSearchVariables, 250);
  const userSearchesV2 = useGetUserSearchesV2(debouncedUserSearchVariables);
  const [createTokenInput, setCreateTokenInput] = useState({
    client_id: client.id,
    user_id: '',
    expires_at: moment().add(3, 'years').unix(),
    not_valid_before: moment().unix(),
    rate_limit_max_requests: 600,
    rate_limit_window_duration: 600,
  });
  const notify = useNotify();
  const createScimTokenMutation = useCreateScimTokenMutation({
    onSuccess() {
      notify.info({ message: 'Token Created' });
      void searchScimTokensQuery.refetch();
    },
  });

  function handleSelectUser(
    event: ChangeEvent<HTMLSelectElement>,
    user: UserProfileSearchFragment | string
  ) {
    let user_id = null;
    if (typeof user === 'string') {
      user = null;
    } else {
      user_id = user.id;
    }
    setActiveUser(user);
    setCreateTokenInput({
      ...createTokenInput,
      user_id,
    });
  }

  function renderUserAutocomplete() {
    return (
      <Autocomplete
        fullWidth
        options={userSearchesV2.results}
        getOptionLabel={option => (typeof option == 'string' ? 'None' : option.email_address)}
        value={activeUser}
        onChange={handleSelectUser}
        loading={userSearchesV2.isInitialLoad}
        renderInput={params => (
          <TextField
            variant="standard"
            {...params}
            label="Select User"
            InputLabelProps={{ shrink: true }}
            placeholder={activeUser?.email_address || 'Select User'}
            value={userSearchVariables.q}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setUserSearchVariables({
                ...userSearchVariables,
                q: event.target.value,
              });
            }}
          />
        )}
      />
    );
  }

  function renderTokensTable() {
    if (searchScimTokensQuery.isLoading) {
      return <Loaders.Standard />;
    }
    const items = searchScimTokensQuery.data?.searchScimTokens?.items;
    if (!items || !items.length) {
      return <EmptyState name="searchUsers" />;
    }
    return items.map(token => (
      <ScimTokenRow
        key={token.id}
        token={token}
        client={client}
        onDeleteToken={() => searchScimTokensQuery.refetch()}
      />
    ));
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid xs={4}>{renderUserAutocomplete()}</Grid>
        <Grid xs={2}>
          <Select
            fullWidth
            value={createTokenInput.expires_at}
            setValue={expires_at => setCreateTokenInput({ ...createTokenInput, expires_at })}
            label="Expire At"
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            variant="standard"
            label="Request Limit"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={createTokenInput.rate_limit_max_requests}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setCreateTokenInput({
                ...createTokenInput,
                rate_limit_max_requests: parseInt(event.target.value),
              })
            }
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            variant="standard"
            label="Last Used"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value=""
            disabled
            InputProps={{ style: { background: '#FFF' } }}
          />
        </Grid>
        <Grid xs={2}>
          <Box pt={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                createScimTokenMutation.isLoading ||
                !(createTokenInput.user_id && createTokenInput.expires_at > 0)
              }
              onClick={() => createScimTokenMutation.mutate({ token: createTokenInput })}
            >
              Generate Token
            </Button>
          </Box>
        </Grid>
      </Grid>
      {renderTokensTable()}
    </Fragment>
  );
};

export default ScimTokenForm;
