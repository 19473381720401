import React, { FC } from 'react';
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';

import Setting from './Setting';

const Onboarding: FC = () => {
  return (
    <Grid container>
      <Grid xs={3}>
        <Typography variant="subtitle2">Onboarding</Typography>
      </Grid>
      <Grid xs>
        <Grid container direction="column" wrap="nowrap" spacing={2}>
          <Grid>
            <Setting
              value="departments_in_signup_required"
              title="Require departments during Signup"
              description="if there are departments in onboarding, it is required to select one"
            />
          </Grid>
          <Grid>
            <Setting
              value="locations_in_signup_required"
              title="Require locations during Signup"
              description="if there are locations in onboarding, it is required to select one"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Onboarding;
