import Amplify from '@aws-amplify/core';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { EncryptStorage } from 'encrypt-storage';

import { ClientCommonFragment } from 'api/public';
import {
  SESSION_KEY_ES_CLIENT,
  SESSION_KEY_ES_UUID,
  LOCAL_STORAGE_KEY_ES_CLIENT,
} from 'app-constants';
import { getSubdomainUrl } from 'helpers';

import {
  OAUTH_DOMAIN,
  BASE_URL,
  IDENTITY_POOL_ID,
  AWS_REGION,
  USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID,
  PROTECTED_GRAPHQL_ENDPOINT_REALTIME,
} from 'settings';

interface IAmplifyOAuthConfig {
  domain: string;
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: string;
  scope: string[];
}

interface IAmplifyAuth {
  identityPoolId: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  oauth: IAmplifyOAuthConfig;
  storage: EncryptStorage | Storage;
}

interface IClientMetadata {
  client_id?: string;
  is_multi_client?: string;
}

interface IAmplifyConfig {
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: string;
  Auth: IAmplifyAuth;
  clientMetadata?: IClientMetadata;
}

function createStorage() {
  for (const item in localStorage) {
    if (typeof item === 'string' && item.match(/^CognitoIdentityServiceProvider.*idToken$/g)) {
      return localStorage;
    }
  }

  let esUuid = localStorage.getItem(SESSION_KEY_ES_UUID);
  if (!esUuid) {
    esUuid = uuidv4();
    localStorage.setItem(SESSION_KEY_ES_UUID, esUuid);
  }
  return new EncryptStorage(esUuid, { prefix: '@' + esUuid, stateManagementUse: true });
}

export const AMPLIFY_CONFIG: IAmplifyConfig = {
  aws_appsync_graphqlEndpoint: PROTECTED_GRAPHQL_ENDPOINT_REALTIME,
  aws_appsync_region: AWS_REGION,
  aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AWS_IAM,
  Auth: {
    identityPoolId: IDENTITY_POOL_ID,
    oauth: {
      domain: OAUTH_DOMAIN,
      redirectSignIn: BASE_URL,
      redirectSignOut: BASE_URL,
      responseType: 'code',
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    },
    region: AWS_REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    storage: createStorage(),
  },
  clientMetadata: {
    is_multi_client: 'true',
  },
};

export const createClientSpecificAmplifyConfig = (
  client?: ClientCommonFragment
): IAmplifyConfig => {
  if (!client) {
    return AMPLIFY_CONFIG;
  }
  const amplifyConfig = { ...AMPLIFY_CONFIG, clientMetadata: { client_id: client.id } };
  const { sso_configuration, subdomain } = client;
  if (!sso_configuration) {
    return amplifyConfig;
  }
  const defaultAuth = amplifyConfig.Auth;
  amplifyConfig.Auth = {
    ...amplifyConfig.Auth,
    identityPoolId: sso_configuration.identity_pool_id || defaultAuth.identityPoolId,
    region: sso_configuration.region || defaultAuth.region,
    userPoolId: sso_configuration.user_pool_id || defaultAuth.userPoolId,
    userPoolWebClientId: sso_configuration.app_client_id || defaultAuth.userPoolWebClientId,
    oauth: {
      ...amplifyConfig.Auth.oauth,
      redirectSignIn: getSubdomainUrl(subdomain),
      redirectSignOut: sso_configuration.redirect_sign_out || defaultAuth.oauth.redirectSignOut,
    },
  };
  return amplifyConfig;
};

export const configureForClient = (client?: ClientCommonFragment) => {
  const prevClient = loadClientFromSession();
  if (!isEqual(prevClient, client)) {
    sessionStorage.setItem(SESSION_KEY_ES_CLIENT, JSON.stringify(client));
    localStorage.setItem(LOCAL_STORAGE_KEY_ES_CLIENT, JSON.stringify(client));
    const amplifyConfig = createClientSpecificAmplifyConfig(client);
    Amplify.configure(amplifyConfig);
  }
};

function loadClientFromSession() {
  return JSON.parse(
    sessionStorage.getItem(SESSION_KEY_ES_CLIENT) ||
      localStorage.getItem(LOCAL_STORAGE_KEY_ES_CLIENT) ||
      'null'
  );
}

Amplify.configure(createClientSpecificAmplifyConfig(loadClientFromSession()));
