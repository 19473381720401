import React, { ChangeEvent, FC, Fragment, useEffect } from 'react';
import { Divider, MenuItem, Select, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { findIndex } from 'lodash';

import { Content, ContentUrlParamOptions } from 'api';

interface Props {
  urlParamOptions: ContentUrlParamOptions[];
}

const UrlTrackingOptions: FC<Props> = ({ urlParamOptions }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<Content>();
  const { url_param_values } = values;

  useEffect(() => setParamOptions(), []);

  function getIndexForKey(key: string) {
    const index = findIndex(url_param_values, ['key', key]);
    return index >= 0 ? index : undefined;
  }

  function getKey(keyIndex: number) {
    return url_param_values[keyIndex] && url_param_values[keyIndex].key;
  }

  function getValue(keyIndex: number) {
    return url_param_values[keyIndex] && url_param_values[keyIndex].value;
  }

  function setParamOptions() {
    urlParamOptions.forEach((paramOptions, index) => {
      const { key, default_option_value, options } = paramOptions;
      const keyIndex = getIndexForKey(key) || index;
      const optionKey = getKey(keyIndex) || key;
      const optionValue = getValue(keyIndex) || default_option_value || options[0];

      url_param_values[keyIndex] = { key: optionKey, value: optionValue };
    });

    setFieldValue('url_param_values', url_param_values);
  }

  function renderSelect(urlParams: ContentUrlParamOptions, index: number) {
    const { options } = urlParams;
    const value = (url_param_values[index] && url_param_values[index].value) || urlParams[0] || {};

    function onChange(event: ChangeEvent<HTMLInputElement>) {
      setFieldValue(`url_param_values[${index}].value`, event.target.value);
    }

    return (
      <Select variant="standard" fullWidth value={value} onChange={onChange}>
        {options.map(valueOption => (
          <MenuItem key={valueOption} value={valueOption}>
            {valueOption}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <Fragment>
      {urlParamOptions.map((options, idx) => (
        <Grid container key={idx} marginTop={2}>
          <Grid xs={1} />
          <Grid xs={11} container direction="column" spacing={2} wrap="nowrap">
            <Divider />
            <Grid container spacing={2} alignItems="center" marginY={1} marginX={0}>
              <Grid container alignItems="center">
                <Grid xs={1}>
                  <Typography variant="subtitle1">{t('posting:key')}</Typography>
                </Grid>
                <Grid>
                  <Typography>{options.key}</Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid xs={1}>
                  <Typography variant="subtitle1">{t('posting:value')}</Typography>
                </Grid>

                <Grid xs={10}>{renderSelect(options, idx)}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};

export default UrlTrackingOptions;
