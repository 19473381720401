import React, { createContext, FC } from 'react';
import { BlockList, useGetBlocklistQuery } from 'api/public';

interface Props {
  client_id: string;
}

export const BlockListContext = createContext<BlockList>({
  terms: [],
});

export const BlockListProvider: FC<Props> = ({ client_id, children }) => {
  const { data } = useGetBlocklistQuery({ client_id });

  return (
    <BlockListContext.Provider value={{ terms: data?.getBlockList?.terms || [] }}>
      {children}
    </BlockListContext.Provider>
  );
};
