import { UseInfiniteQueryOptions } from 'react-query';

import {
  GetManualNotificationsQuery,
  GetManualNotificationsQueryVariables,
  ManualNotificationsItemFragment,
  useGetManualNotificationsQuery,
} from 'api';
import { LoadMoreQuery } from 'types/app';
import { useInfiniteQuery } from 'app-react-query/hooks';
import { useNotify } from 'hooks';

type Query = GetManualNotificationsQuery;
type Variables = GetManualNotificationsQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = LoadMoreQuery<ManualNotificationsItemFragment>;

/**
 * TODO: Pagination for this query is busted server-side
 * The first result of the second page is always the same as the last result of the first page
 */
const useGetManualNotifications = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();

  const queryResult = useInfiniteQuery<Query, Variables>(
    useGetManualNotificationsQuery,
    ({ pageParam }: { pageParam: Variables }) => ({
      scheduled: variables.scheduled,
      data: { ...variables.data, ...pageParam },
    }),
    {
      initialData: {
        pages: [
          {
            getManualNotificationItems: {
              count: 0,
              items: [],
              paginate: false,
            },
          },
        ],
        pageParams: [],
      },
      onError: notify.queryError,
      getNextPageParam: lastPage => {
        const { getManualNotificationItems } = lastPage;
        const { items, id, created_at } = getManualNotificationItems;

        const endOfList = !id && !created_at;
        const noResults = !items.length;

        if (noResults || endOfList) {
          return false;
        }

        return { id, created_at };
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = queryResult;
  const results = data.pages.map(page => page.getManualNotificationItems.items).flat();
  const { count } = data.pages[0].getManualNotificationItems;

  return {
    results,
    count,
    isEmpty: !results.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !results.length && isFetching,
    isLoading: isFetchingNextPage,
    onLoadMore: () => fetchNextPage(),
  };
};

export default useGetManualNotifications;
