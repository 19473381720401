import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import numeral from 'numeral';

import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pt';

import * as en from 'locales/en';

const additionalLanguages = ['de', 'es', 'fr', 'ja', 'ko', 'pt'];

i18n.on('languageChanged', (language: string) => {
  const twoLetterCode = language && language.split('-')[0];
  const hasLanguage = additionalLanguages.includes(twoLetterCode);

  moment.locale([language, 'en']);

  if (!hasLanguage) {
    return;
  }

  void import(`locales/${twoLetterCode}`).then(locales => {
    Object.keys(locales).forEach(locale => {
      i18n.addResources(twoLetterCode, locale, locales[locale]);
    });
  });
});

void i18n.use(LanguageDetector).init({
  resources: { en },
  fallbackLng: 'en',
  load: 'languageOnly',
  lowerCaseLng: true,
  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      if (format === 'lowercase') {
        return value.toLowerCase();
      }
      if (format === 'uppercase') {
        return value.toUpperCase();
      }
      if (format === 'numeric') {
        return numeral(value).format();
      }
      return value;
    },
  },
});

export default i18n;
