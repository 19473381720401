import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Paper, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { LoaderComponents, Loaders } from 'components';

import { PARTNER_LINE_CHART_HEIGHT, STAT_VALUE_HEIGHT } from '../constants';

const PartnerActivityLoader: FC = () => {
  const { t } = useTranslation();

  function renderStat(title: string) {
    return (
      <Grid xs={12} md={6} lg={3}>
        <Typography display="inline" variant="subtitle2" color="textSecondary">
          {title}
        </Typography>
        <Grid container alignItems="flex-end" spacing={1}>
          <Grid>
            <Typography variant="h2" style={{ height: STAT_VALUE_HEIGHT }}>
              <LoaderComponents.Block width={STAT_VALUE_HEIGHT} height={STAT_VALUE_HEIGHT} />
            </Typography>
          </Grid>
        </Grid>
        <Box marginTop={2} position="relative">
          <Loaders.LineChart height={PARTNER_LINE_CHART_HEIGHT} />
        </Box>
      </Grid>
    );
  }

  return (
    <Box padding={2}>
      <Grid container>
        <Grid xs>
          <Paper>
            <Box paddingLeft={3} paddingTop={3} marginBottom={2}>
              <Typography variant="h6">{t('help:activity_external_shares')}</Typography>
            </Box>
            <Box paddingLeft={3} paddingRight={3} paddingBottom={2}>
              <Grid container spacing={3}>
                {renderStat(t('common:share_plural'))}
                {renderStat(t('common:impression_plural'))}
                {renderStat(t('common:click_plural'))}
                {renderStat(t('common:engagement_plural'))}
              </Grid>
            </Box>
            <Divider />
            <Box paddingLeft={1} height={48} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartnerActivityLoader;
