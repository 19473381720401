import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import { useGetUserClientsQuery, UserClientStatus } from 'api';
import { ES_SHARE_URL_QUERY_PARAM } from 'app-constants';
import { useNotify } from 'hooks';
import { Layouts, Loaders, PaddedPaper } from 'components';
import { getAppImage } from 'helpers';
import * as paths from 'paths';
import cookieStorage from 'cookie-storage';

import { AvailableWorkspace, ExistingWorkspace, InactiveWorkspace } from '../components';

const MAX_WIDTH = 640;

const styles = {
  wrapper: {
    height: 116,
    position: 'relative',
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: -52,
    height: 168,
    background: `url(${getAppImage('illustrations/workspace.png').src}) no-repeat`,
    backgroundSize: 'contain',
  },
  dialogContent: {
    padding: `0 2`,
  },
  button: {
    width: 100,
  },
  createButton: {
    marginTop: 4,
    marginRight: 2,
  },
} as const;

const Workspaces: FC = () => {
  const { t } = useTranslation();
  const notify = useNotify();

  const navigate = useNavigate();
  const [confirming, setConfirming] = useState(false);

  const { data: userClientsData, isLoading: userClientsLoading } = useGetUserClientsQuery(
    {},
    {
      onError: notify.queryError,
    }
  );

  if (userClientsLoading) {
    return <Loaders.Startup />;
  }

  const activeClients = getWithStatus([UserClientStatus.Active]).map(w => w.client);
  const availableClients = getWithStatus([UserClientStatus.SignUpAvailable]).map(w => w.client);
  const inactiveUserClients = getWithStatus([
    UserClientStatus.UserDisabled,
    UserClientStatus.InvitationRequired,
  ]);

  // Just logged in OR already logged in
  const shareUrl =
    cookieStorage.shareUrl || new URLSearchParams(location.search).get(ES_SHARE_URL_QUERY_PARAM);
  const autoLaunch = shareUrl && activeClients.length === 1;

  function getWithStatus(allowedStatuses: UserClientStatus[]) {
    if (!userClientsData.getUserClients) {
      return [];
    }

    const { items } = userClientsData.getUserClients;
    return items.filter(userClient => allowedStatuses.includes(userClient.user_client_status));
  }

  function handleCreateClient() {
    const confirm = activeClients.length || availableClients.length;

    if (confirm) {
      setConfirming(true);
    } else {
      navigate(paths.clientCreate);
    }
  }

  function renderActiveWorkspaces() {
    if (!activeClients.length) {
      return null;
    }
    return (
      <Grid container direction="column" spacing={2}>
        <Grid>
          <Typography variant="h2">{t('auth:yourWorkplacesHeader')}</Typography>
        </Grid>
        <Grid>
          {activeClients.map(client => (
            <Grid key={client.id}>
              <ExistingWorkspace client={client} autoLaunch={autoLaunch} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }

  function renderAvailableWorkspaces() {
    if (!(availableClients.length || inactiveUserClients.length)) {
      return null;
    }
    return (
      <Box pb={7}>
        <Box pb={2}>
          <Typography variant="h2">{t('auth:yourAvailableWorkplacesHeader')}</Typography>
        </Box>
        <Grid container direction="column" spacing={2}>
          {availableClients.map(client => (
            <AvailableWorkspace key={client.id} client={client} />
          ))}
          {inactiveUserClients.map(userClient => (
            <InactiveWorkspace key={userClient.client.id} {...userClient} />
          ))}
        </Grid>
      </Box>
    );
  }

  function renderCreateNewWorkspace() {
    if (!(activeClients.length || availableClients.length)) {
      return null;
    }

    return (
      <Box pb={7}>
        <Typography variant="h2">{t('auth:workspaceCreate')}</Typography>
        <Typography>{t('auth:workspaceCreatePitch')}</Typography>
        <Box paddingTop={12} />
        <Paper sx={styles.wrapper}>
          <Box sx={styles.background} />
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid>
              <Button
                sx={{ ...styles.button, ...styles.createButton }}
                onClick={handleCreateClient}
                size="large"
                variant="contained"
                color="primary"
              >
                {t('common:create')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  }

  function renderNoWorkspacesBlocker() {
    if (activeClients.length || availableClients.length) {
      return null;
    }

    return (
      <PaddedPaper>
        <Box textAlign="center">
          <img width="50%" {...getAppImage('illustrations/pipe.png')} />
        </Box>
        <Typography gutterBottom align="center" variant="h6">
          {t('auth:workspaceNotAvailableHeader')}
        </Typography>
        <Typography align="center" color="textSecondary">
          {t('auth:workspacesNotAvailableMessage')}
        </Typography>
        <Box paddingBottom={6} />
      </PaddedPaper>
    );
  }

  function renderNoWorkspacesCreate() {
    if (activeClients.length || availableClients.length) {
      return null;
    }

    return (
      <Box pb={7}>
        <PaddedPaper>
          <Box paddingTop={3} paddingBottom={3} textAlign="center">
            <img width="75%" {...getAppImage('illustrations/ideas.png')} />
          </Box>
          <Typography gutterBottom align="center" variant="h6">
            {t('auth:workspaceNoneHeader')}
          </Typography>
          <Typography align="center" color="textSecondary">
            {t('auth:workspaceNoneSubHead')}
          </Typography>
          <Box textAlign="center" paddingTop={4} paddingBottom={4}>
            <Button onClick={handleCreateClient} size="large" variant="contained" color="primary">
              {t('auth:workspaceNoneButton')}
            </Button>
          </Box>
        </PaddedPaper>
      </Box>
    );
  }

  function renderConfirmDialog() {
    return (
      <Dialog open={confirming} onClose={() => setConfirming(false)} fullWidth maxWidth="xs">
        <DialogTitle>
          <Typography variant="subtitle2">{t('user:areYouSure')}</Typography>
          <IconButton onClick={() => setConfirming(false)} size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Typography>{t('auth:confirmCreateWorkspace')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            type="button"
            variant="contained"
            color="primary"
            onClick={() => navigate(paths.clientCreate)}
          >
            {t('common:Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Fragment>
      <Layouts.BareAppBar logout />
      <Box display="flex" justifyContent="center">
        <Box
          width={{ lg: MAX_WIDTH }}
          maxWidth={{ xs: MAX_WIDTH, sm: MAX_WIDTH, md: MAX_WIDTH }}
          pt={11}
        >
          {renderActiveWorkspaces()}
          {renderAvailableWorkspaces()}
          {renderCreateNewWorkspace()}
          {renderNoWorkspacesCreate()}
          {renderNoWorkspacesBlocker()}
        </Box>
      </Box>
      {renderConfirmDialog()}
    </Fragment>
  );
};

export default Workspaces;
