import React, { FC } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import * as yup from 'yup';

import { useCsCreateUserMutation } from 'api';
import { Loaders, PasswordRequirements, ValidatedTextFieldNext } from 'components';
import { useNotify } from 'hooks';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  clientId: string;
}

const CreateDialog: FC<Props> = ({ open, setOpen, clientId }) => {
  const notify = useNotify();

  const initialValues = {
    email_address: '',
    password: '',
  };

  const validationSchema = yup.object().shape({
    email_address: yup.string().email().required(),
  });

  const { mutate, isLoading } = useCsCreateUserMutation({
    onError: notify.mutationError,
    onSuccess: handleCompleted,
  });

  function handleCompleted() {
    notify.info({ message: 'User Created' });
    setOpen(false);
  }

  function handleSubmit(values: typeof initialValues) {
    mutate({
      email_address: values.email_address,
      client_id: clientId,
      password: values.password,
    });
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={() => setOpen(false)} open={open}>
      <DialogTitle>Specify Email Address for New User</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <FormikForm>
            <ValidatedTextFieldNext
              fullWidth
              name="email_address"
              placeholder="name@domain.com"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
            />
            <Box pt={2}>
              <ValidatedTextFieldNext
                fullWidth
                disableError
                name="password"
                type="password"
                placeholder="Password"
                autoComplete="new-password"
                InputLabelProps={{ shrink: true }}
              />
              <Box marginTop={2}>
                <PasswordRequirements name="password" />
              </Box>
            </Box>
            <Box pt={2}>
              <Grid container justifyContent="flex-end">
                <Grid>
                  <Button disabled={isLoading} type="submit" variant="contained" color="primary">
                    {isLoading ? <Loaders.Standard height={24} /> : 'Create User'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </FormikForm>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CreateDialog;
