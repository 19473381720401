import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FormikForm, Formik } from 'formik';
import { Button, Dialog, DialogContent, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { isEmpty } from 'lodash';

import { CreateClientInvitationInput, useCreateClientInvitationMutation } from 'api';
import { useNotify } from 'hooks';
import { clientInvitesSchema } from 'features/setup/validations';

import { Loaders } from '../index';
import InviteInput from './InviteInput';
import theme from 'theme';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const styles = {
  inviteLink: {
    marginLeft: 1,
  },
  input: {
    margin: theme.spacing(4, 0, 6),
  },
  content: {
    marginTop: 2,
  },
  cancelButton: {
    marginRight: 2,
  },
} as const;

const initialValues: CreateClientInvitationInput = {
  email_addresses: [],
};

const InviteDialog: FC<IProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const notify = useNotify();

  const { mutate, isLoading } = useCreateClientInvitationMutation({
    onError: notify.mutationError,
    onSuccess: () => {
      onClose();
      notify.info({ message: t('invites:inviteSuccess') });
    },
  });

  function handleFormSubmit(values: CreateClientInvitationInput) {
    return mutate({ data: values });
  }

  return (
    <Dialog open={open} onBackdropClick={onClose}>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={clientInvitesSchema}
      >
        {({ submitForm, isValid, values }) => (
          <FormikForm
            onKeyDown={event => {
              if (event.key == 'Enter') {
                event.preventDefault();
                event.stopPropagation();
              }
            }}
          >
            <DialogContent>
              <Grid container direction="column" alignItems="center" sx={styles.content}>
                <Grid xs>
                  <Typography variant="h6" align="center">
                    {t('invites:intro')}
                  </Typography>
                </Grid>
                <Grid xs={10}>
                  <Typography color="textSecondary" align="center">
                    {t('invites:body')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={styles.input}>
                <InviteInput />
              </Grid>

              <Grid container alignItems="center" justifyContent="space-between">
                <Grid xs={5} onClick={onClose} sx={styles.cancelButton}>
                  <Button variant="outlined" size="large" fullWidth>
                    {t('common:cancel')}
                  </Button>
                </Grid>

                <Grid xs={5}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    disabled={isEmpty(values.email_addresses) || !isValid || isLoading}
                    size="large"
                    fullWidth
                  >
                    {isLoading ? <Loaders.Standard height={26} /> : t('common:send')}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default InviteDialog;
