import { array, object, string } from 'yup';
import i18n from 'config/i18n';

const invalidEmail: string = i18n.t('auth:invalidEmail');
const emailRequired: string = i18n.t('admin:emailRequired');
const jobTitleRequired: string = i18n.t('user:jobTitleRequired');

export const clientInvitesSchema = object().shape({
  email_addresses: array()
    .of(string().email(invalidEmail).required(emailRequired))
    .min(1, emailRequired)
    .required(),
});

export const jobTitleSchema = object().shape({
  job_title: string().min(1).required(jobTitleRequired),
});
