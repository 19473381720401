import React, { FC, SyntheticEvent } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import moment from 'moment-timezone';

interface IProps {
  defaultValue: string;
  onChange: (timezone: string) => void;
}

const OPTIONS = moment.tz.names();

const TimezoneSelect: FC<IProps> = ({ onChange, defaultValue }) => (
  <Autocomplete
    disableClearable
    options={OPTIONS}
    defaultValue={defaultValue}
    onChange={(event: SyntheticEvent, nextValue: string) => onChange(nextValue)}
    renderInput={params => <TextField {...params} variant="outlined" />}
  />
);

export default TimezoneSelect;
