import { useState } from 'react';
import { clone, remove } from 'lodash';

import { ReportingFilter } from 'api';

interface UseReportingFilter {
  filter: ReportingFilter;
  flattenedFilter: string[];
  setFilter: (filter: ReportingFilter) => void;
  clearFilter: () => void;
  removeFilterItem: (id: string) => void;
}

export default (): UseReportingFilter => {
  const [filter, setFilter] = useState<ReportingFilter>({
    department_ids: [],
    location_ids: [],
    segment_ids: [],
    group_ids: [],
  });

  function getFlattenedFilter() {
    let flattenedFilter: UseReportingFilter['flattenedFilter'] = [];
    for (const entry in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, entry)) {
        flattenedFilter = flattenedFilter.concat(filter[entry]);
      }
    }
    return flattenedFilter;
  }

  function handleClearFilter() {
    setFilter({
      department_ids: [],
      location_ids: [],
      segment_ids: [],
      group_ids: [],
    });
  }

  function handleRemoveFilterItem(id: string) {
    const tempFilter = clone(filter);

    for (const entry in tempFilter) {
      if (Object.prototype.hasOwnProperty.call(tempFilter, entry)) {
        tempFilter[entry] = remove(tempFilter[entry], (idToRemove: string) => idToRemove !== id);
      }
    }

    setFilter(tempFilter);
  }

  return {
    filter,
    setFilter,
    flattenedFilter: getFlattenedFilter(),
    clearFilter: handleClearFilter,
    removeFilterItem: handleRemoveFilterItem,
  };
};
