import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import theme from 'theme';
import { Box } from '@mui/material';

interface Props {
  size?: number;
  chartSize?: number;
  placeholderMode?: boolean;
}

const styles = {
  root: {
    position: 'relative',
  },
  pie: {
    borderWidth: 12,
    borderStyle: 'solid',
    borderRadius: '50%',
    ...theme.mixins.absoluteCenter,
  },
};

const PieChartLoader: FC<Props> = ({ size, chartSize, placeholderMode }) => {
  const theme = useTheme();
  const borderColor = theme.palette.grey[placeholderMode ? 100 : 200];

  return (
    <Box sx={styles.root} style={{ height: size }}>
      <Box style={{ borderColor, height: chartSize, width: chartSize }} sx={styles.pie} />
    </Box>
  );
};

PieChartLoader.defaultProps = {
  size: 200,
  chartSize: 160,
  placeholderMode: false,
};

export default PieChartLoader;
