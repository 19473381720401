import React, { FC, Fragment } from 'react';
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';

import MultiValue from './MultiValue';

import Setting from './Setting';

const Privileges: FC = () => {
  return (
    <Fragment>
      <Grid container>
        <Grid xs={3}>
          <Typography variant="subtitle2">Compliance</Typography>
        </Grid>
        <Grid xs>
          <Setting
            value="compliance_notification_enabled"
            title="Compliance Notifications Enabled"
            description="compliance notifications enabled"
          />
        </Grid>
      </Grid>
      <MultiValue
        title=""
        placeholder="Add Compliance Notification Email"
        dataKey="compliance_notification_emails"
      />
    </Fragment>
  );
};

export default Privileges;
