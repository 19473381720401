import React, { CSSProperties, FC } from 'react';
import { Divider as MuiDivider } from '@mui/material';

import useStyles from './styles';

interface Props {
  sx?: CSSProperties;
}

const Divider: FC<Props> = ({ sx }) => {
  const styles = useStyles;

  return <MuiDivider sx={{ ...styles.divider, ...sx }} />;
};

export default Divider;
