import React, { FC } from 'react';
import { FieldArrayRenderProps } from 'formik';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { S3ObjectInput } from 'api';

import Thumbnail from './Thumbnail';

interface Props {
  arrayHelpers: FieldArrayRenderProps;
  images: S3ObjectInput[];
}

const DraggableThumbnails: FC<Props> = ({ images, arrayHelpers }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      {images.map((photo, idx) => (
        <Thumbnail key={idx} index={idx} photo={photo} {...arrayHelpers} />
      ))}
    </DndProvider>
  );
};

export default DraggableThumbnails;
