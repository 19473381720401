import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { SharesItemFragment } from 'api';
import { Capability, CustomSVG, Numeric } from 'components';

interface Props {
  shareItem: SharesItemFragment;
}

const SharePerformance: FC<Props> = ({ shareItem }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Grid marginRight={2}>
        <Tooltip arrow title={t('common:click_plural')} placement="top">
          <Grid container alignItems="center">
            <CustomSVG color="action" name="adsClick" />
            <Box marginLeft={0.5}>
              <Typography display="inline">
                <Numeric value={shareItem.clicks} />
              </Typography>
            </Box>
          </Grid>
        </Tooltip>
      </Grid>
      <Capability name="Admin.Impressions.View.Access">
        <Grid marginRight={2}>
          <Tooltip arrow title={t('common:impression_plural')} placement="top">
            <Grid container alignItems="center">
              <CustomSVG color="action" name="visibility" />
              <Box marginLeft={0.5}>
                <Typography display="inline">
                  <Numeric value={shareItem.impressions} />
                </Typography>
              </Box>
            </Grid>
          </Tooltip>
        </Grid>
      </Capability>
      <Grid>
        <Tooltip arrow title={t('common:engagement_plural')} placement="top">
          <Grid container alignItems="center">
            <CustomSVG color="action" name="bolt" />
            <Box marginLeft={0.5}>
              <Typography display="inline">
                <Numeric value={shareItem.engagements} />
              </Typography>
            </Box>
          </Grid>
        </Tooltip>
      </Grid>
    </Fragment>
  );
};

export default SharePerformance;
