import React, { FC, Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSlate } from 'slate-react';
import { Popover, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Data as EmojiData } from 'emoji-mart';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';

import { useSelectionContext } from '../Contexts';

const EmojiGroup: FC = () => {
  const editor = useSlate();
  const ref = useRef();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { actions: selectionActions } = useSelectionContext();

  function handleInsertEmoji(emoji: typeof EmojiData) {
    selectionActions.ensureEditorFocus();
    editor.insertText(emoji.native);
    setOpen(false);
  }

  function handleClose() {
    selectionActions.ensureEditorFocus();
    setOpen(false);
  }

  function handleChange() {
    selectionActions.ensureEditorFocus();
    setOpen(true);
  }

  return (
    <Fragment>
      <ToggleButtonGroup onChange={handleChange}>
        <ToggleButton
          title={t('components:editorInsertEmoji')}
          selected={open}
          ref={ref}
          value="emoji"
        >
          <InsertEmoticonIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Picker theme="light" data={data} onEmojiSelect={handleInsertEmoji} />
      </Popover>
    </Fragment>
  );
};

export default EmojiGroup;
