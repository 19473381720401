import React, { FC, Fragment, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Input,
  Menu,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import moment from 'moment';
import { Form } from 'formik';

import Flatpickr from './Flatpickr';
import { DatePickerOptions } from './types';
import theme from 'theme';

interface Props extends DatePickerOptions {
  value: number;
  anchorEl?: HTMLElement;
  onSave?: (time: number) => void;
  onClose: () => void;
  onChange?: (newTime: number) => void;
  anchorToMenu?: boolean;
  classes?: object;
}

const styles = {
  timeSelector: {
    backgroundColor: 'common.white',
    color: 'common.black',
    textAlign: 'center',
    '&:focus': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
} as const;

const DateTime: FC<Props> = props => {
  const {
    anchorEl,
    children,
    onClose,
    onSave,
    onChange,
    value,
    enableClear,
    anchorToMenu,
    ...flatPickrOptions
  } = props;
  const {
    dateFormat,
    disabled,
    enableTime,
    enableTimezone,
    inline,
    minDate,
    maxDate,
    title,
    subtitle,
    submitButtonText,
  } = flatPickrOptions;
  const { t } = useTranslation();

  const [tempTime, setTempTime] = useState(value || null);
  const [tempAm, setTempAm] = useState<string>(moment.unix(tempTime).format('A'));

  const clientTz = moment.tz.guess();
  const time = moment.unix(tempTime || value).toDate();
  const options = {
    minDate: minDate || (maxDate ? null : 'today'),
    maxDate,
    inline,
    dateFormat: dateFormat ? dateFormat : 'U',
  };

  useEffect(() => {
    if (onChange && tempTime) {
      onChange(tempTime);
    }
  }, [tempTime]);

  function handleDateChange(selectedDates: Date[]) {
    const newMoment = toRoundedMoment(selectedDates[0]);
    if (tempTime) {
      const tempMoment = moment.unix(tempTime);
      newMoment.hours(tempMoment.hours());
      newMoment.minutes(tempMoment.minutes());
    }
    const newTime = boundedUnixTimestamp(newMoment.unix());
    setTempTime(newTime);
  }

  function handleDateSave() {
    onSave(tempTime || value);
  }

  function handleClose(event: KeyboardEvent) {
    event.key === 'Tab' ? event.stopPropagation() : onClose();
  }

  function handleTimeUpdate(hours: string, minutes: string) {
    const hr = Number(hours);
    const min = Number(minutes);
    let hours24 = hr;

    if (hr < 12 && tempAm === 'PM') {
      hours24 += 12;
    } else if (hr === 12 && tempAm === 'AM') {
      hours24 = 0;
    }

    let newTime = moment.unix(tempTime).hours(hours24).minutes(min).unix();
    newTime = boundedUnixTimestamp(newTime);
    setTempTime(newTime);
  }

  function handleAmPmUpdate() {
    const tempMoment = moment.unix(tempTime);
    const newAmPm = tempAm === 'AM' ? 'PM' : 'AM';

    tempMoment.hour((tempMoment.hour() % 12) + (newAmPm === 'PM' ? 12 : 0));

    let newTime = tempMoment.unix();
    newTime = boundedUnixTimestamp(newTime);

    setTempTime(newTime);
    setTempAm(moment.unix(newTime).format('A'));
  }

  function renderButtons() {
    if (enableClear) {
      return (
        <Box marginY={1}>
          <Grid container alignItems="center" spacing={1}>
            <Grid xs={6}>
              <Button fullWidth onClick={() => onSave(null)}>
                {t('common:clear')}
              </Button>
            </Grid>
            <Grid xs={6}>
              <Button
                fullWidth
                disabled={!tempTime && !value}
                type="submit"
                size="large"
                variant="contained"
                color="primary"
              >
                {submitButtonText ? submitButtonText : t('common:save')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      );
    }

    return (
      <Box marginY={1}>
        <Button
          fullWidth
          disabled={!tempTime && !value}
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          onClick={handleDateSave}
        >
          {submitButtonText ? submitButtonText : t('common:save')}
        </Button>
      </Box>
    );
  }

  function toRoundedMoment(date: Date) {
    const m = moment(date);
    let minute = m.minutes();
    while (minute % 5) {
      minute++;
    }
    m.minutes(minute);
    return m;
  }

  function roundedBound(val: string | Date) {
    const date = val === 'today' ? new Date() : new Date(val);
    return toRoundedMoment(date).unix();
  }

  function boundedUnixTimestamp(unix: number) {
    if (options.minDate) {
      const minTime = roundedBound(options.minDate);
      unix = Math.max(unix, minTime);
    }
    if (options.maxDate) {
      const maxTime = roundedBound(options.maxDate);
      unix = Math.min(unix, maxTime);
    }
    return unix;
  }

  function renderHeader() {
    if (!title || !subtitle) {
      return null;
    }

    return (
      <Fragment>
        {title && <Typography variant="h6">{title}</Typography>}
        {subtitle && <Typography color="textSecondary">{subtitle}</Typography>}

        <Box marginTop={2} marginBottom={1}>
          <Divider />
        </Box>
      </Fragment>
    );
  }

  function renderTimezone() {
    if (!tempTime || !enableTimezone) {
      return null;
    }

    return (
      <FormControl variant="standard" fullWidth>
        <Box marginBottom={2}>
          <Input
            readOnly
            fullWidth
            value={clientTz}
            inputProps={{ style: { textAlign: 'center' } }}
          />
        </Box>
      </FormControl>
    );
  }

  function renderTimePicker() {
    if (!tempTime || !enableTime) {
      return null;
    }

    const tempHour = moment.unix(tempTime).format('h');
    const tempMin = moment.unix(tempTime).format('mm');

    return (
      <Box marginTop={1} marginBottom={2}>
        <Divider />
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={4}>
            <FormControl variant="standard" fullWidth>
              <Input
                sx={styles.timeSelector}
                fullWidth
                type="number"
                inputProps={{
                  min: '1',
                  max: '12',
                  style: { textAlign: 'center' },
                  tabIndex: 1,
                }}
                value={moment.unix(tempTime).format('h')}
                onChange={event => handleTimeUpdate(event.target.value, tempMin)}
              />
            </FormControl>
          </Grid>
          <Grid>:</Grid>
          <Grid xs={4}>
            <FormControl variant="standard" fullWidth>
              <Input
                sx={styles.timeSelector}
                fullWidth
                value={moment.unix(tempTime).format('mm')}
                type="number"
                inputProps={{
                  min: '00',
                  max: '59',
                  style: { textAlign: 'center' },
                  tabIndex: 2,
                }}
                onChange={event => handleTimeUpdate(tempHour, event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid xs={3}>
            <Button
              fullWidth
              value={tempAm}
              sx={styles.timeSelector}
              style={{ textAlign: 'center' }}
              onClick={handleAmPmUpdate}
              tabIndex={3}
            >
              {tempAm}
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </Box>
    );
  }

  function renderFlatpickr() {
    return (
      <Fragment>
        {renderHeader()}
        <Flatpickr value={time} onChange={handleDateChange} options={options} disabled={disabled} />
        {renderTimePicker()}
        {renderTimezone()}
      </Fragment>
    );
  }

  function renderMinimalFlatpickr() {
    return (
      <Box padding={2}>
        {renderHeader()}
        <Flatpickr value={time} onChange={handleDateChange} options={options} disabled={disabled} />
        {renderTimePicker()}
        {renderTimezone()}

        {children ? children : renderButtons()}
      </Box>
    );
  }

  return (
    <Form
      onSubmit={event => {
        event.preventDefault();
        event.stopPropagation();
        handleDateSave();
      }}
    >
      {anchorToMenu ? (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl && !disabled}
          onClose={handleClose}
          data-testid="datePicker"
        >
          <Box padding={2}>
            {renderFlatpickr()}
            {children ? children : renderButtons()}
          </Box>
        </Menu>
      ) : (
        renderMinimalFlatpickr()
      )}
    </Form>
  );
};

DateTime.defaultProps = {
  enableTime: false,
  enableClear: false,
  enableTimezone: false,
  inline: true,
  dateFormat: 'U',
  disabled: false,
  minDate: '',
  maxDate: '',
  title: '',
  subtitle: '',
  submitButtonText: '',
};

export default DateTime;
