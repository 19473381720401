import React, { FC, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

/*
 * Shared layout for all root domain routes
 */
export const RootLayout: FC = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window?.analytics?.page();
  }, [location]);

  return <Fragment>{children}</Fragment>;
};
