import React, { FC, useEffect } from 'react';

import { ClientInvitation, useEnsureInvitedUserConfirmedMutation } from 'api/public';
import { Loaders } from 'components';
import { useNotify } from 'hooks';

import { TOKEN_PARAM } from 'features/auth/constants';

interface IProps {
  children: (props?: ClientInvitation) => JSX.Element;
}

const EnsureInvitedUserConfirmed: FC<IProps> = ({ children }) => {
  const url = new URL(window.location.href);
  const token = url.searchParams.get(TOKEN_PARAM);
  const notify = useNotify();
  const {
    mutate: ensureInvitedUserConfirmed,
    isLoading,
    data,
  } = useEnsureInvitedUserConfirmedMutation({
    onError: notify.mutationError,
  });

  useEffect(() => {
    if (token) {
      ensureInvitedUserConfirmed({ id: token });
    }
  }, []);

  if (!token) {
    return children();
  }

  if (isLoading) {
    return <Loaders.Standard />;
  }

  if (data) {
    const clientInvitation = data.ensureInvitedUserConfirmed;
    return children(clientInvitation);
  }
  return <Loaders.Standard />; // not yet started loading...
};

export default EnsureInvitedUserConfirmed;
