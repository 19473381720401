import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps } from 'formik';
import { FormControl, FormHelperText, Input, InputLabel, MenuItem, Select } from '@mui/material';
import { get } from 'lodash';

import palette from '../theme/palette';

interface IProps {
  name: string;
  i18nKey?: string;
  items: any[];
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  persistLabel?: boolean;
  disabled?: boolean;
  placeholder?: string;
  inputProps?: Record<string, unknown>;
  disableLabel?: boolean;
}

interface IFormValue {
  [name: string]: string;
}

const styles = {
  placeholder: {
    color: 'grey.500',
  },
  input: {
    backgroundColor: palette.grey[100],
    padding: 2,
  },
} as const;

const ValidatedSelect: FC<IProps> = ({
  name,
  i18nKey,
  persistLabel,
  required,
  disabled,
  disableLabel,
  margin,
  items,
  placeholder,
  inputProps,
}) => {
  const { t } = useTranslation();

  return (
    <Field name={name}>
      {({ field, form }: FieldProps<IFormValue>) => {
        const { errors, touched } = form;
        const hasError = !!(get(errors, name) && get(touched, name));
        const nameError = get(touched, name) && get(errors, name);

        return (
          <FormControl
            variant="standard"
            error={hasError}
            required={required}
            fullWidth
            margin={margin}
            disabled={disabled}
          >
            {!disableLabel && (
              <InputLabel shrink={persistLabel || hasError}>{t(i18nKey)}</InputLabel>
            )}
            <Select
              variant="standard"
              sx={placeholder && !field.value ? { select: styles.placeholder } : null}
              displayEmpty
              value={field.value || ''}
              inputProps={{
                ...inputProps,
                sx: styles.input,
              }}
              onChange={field.onChange}
              input={<Input name={name} disableUnderline={!hasError} />}
              MenuProps={{ MenuListProps: { disablePadding: true } }}
            >
              <MenuItem disabled={required} value="">
                {placeholder || t(i18nKey)}
              </MenuItem>
              {items.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
            {hasError && <FormHelperText>{nameError}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};

ValidatedSelect.defaultProps = {
  margin: 'normal',
  required: false,
  persistLabel: false,
  disabled: false,
  inputProps: {},
  disableLabel: false,
};

export default ValidatedSelect;
