import { PROTECTED_GRAPHQL_ENDPOINT } from 'settings';

import jwtToken from './utilities/get-jwt-token';
import getOperationType from './utilities/get-operation-type';
import findInObject from './utilities/find-in-object';
import uploadFile from './utilities/upload-file';

import { FileField } from './types';

const CUSTOM_USER_AGENT = 'EveryoneSocial Auth Link';

export const transport = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    const headers = {
      'x-amz-user-agent': CUSTOM_USER_AGENT,
      Authorization: await jwtToken(),
    };

    const operationType = getOperationType(query);
    const isMutation = operationType === 'mutation';
    const objectsToUpload = isMutation ? findInObject<TVariables>(variables) : ({} as FileField);
    const objectsToUploadLength = Object.keys(objectsToUpload).length;

    if (objectsToUploadLength) {
      const uploadPromises = Object.entries(objectsToUpload).map(async ([, fileField]) => {
        await uploadFile(fileField);
      });
      await Promise.all(uploadPromises).catch(message => {
        throw new Error(message);
      });
    }

    const body = JSON.stringify({
      query,
      variables,
    });

    const res = await fetch(PROTECTED_GRAPHQL_ENDPOINT, { method: 'POST', headers, body });
    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0] || 'Error..';
      throw new Error(message);
    }

    return json.data;
  };
};
