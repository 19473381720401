import React, { FC, Fragment, useEffect } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import { usePrevious } from 'hooks';

interface IProps {
  onChange: (values: FormikValues) => void;
}

/**
 * This component is used for manual tracking of the onChange event
 * of a Formik Form. It is only necessary when you have to combine
 * two forms with one submit button. In all likelihood, you don't need
 * this component
 */
const FormikNotifier: FC<IProps> = ({ onChange, children }) => {
  const { values } = useFormikContext();
  const [debouncedCallback] = useDebouncedCallback(onChange, 100);
  const hasMounted = usePrevious('hasMounted');

  useEffect(() => {
    if (!hasMounted) {
      return;
    }

    debouncedCallback(values);
  }, [values]);

  return <Fragment>{children}</Fragment>;
};

export default FormikNotifier;
