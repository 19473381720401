import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Button, CardContent, Unstable_Grid2 as Grid } from '@mui/material';

import { useContent } from 'hooks';

import { Date } from '../../widgets';

const Waiting: FC = () => {
  const { content_id, publish_at } = useContent();

  return (
    <CardContent>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid xs>
          <Date contentId={content_id} publishAt={publish_at} />
        </Grid>
        <Grid>
          <Button disabled variant="contained">
            <Trans i18nKey="content:waitingForApproval" />
          </Button>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default Waiting;
