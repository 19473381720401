import React, { FC } from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ValidatedCheckbox } from 'components';
import { useProtectedClient } from 'hooks';

const ShareableCheckbox: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();
  const client = useProtectedClient();

  return (
    <Tooltip disableHoverListener={!client.allow_sharing} title={t('posting:sharingTurnedOff')}>
      <span>
        <ValidatedCheckbox
          disabled={!client.allow_sharing || disabled}
          name="is_shareable"
          label={t('posting:shareable')}
          color="primary"
        />
      </span>
    </Tooltip>
  );
};

export default ShareableCheckbox;
