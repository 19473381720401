import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { lighten } from '@mui/material/styles';

import { SocialIcon } from 'components';
import theme from 'theme';

import { ShareItemNetworkMeta } from '../types';

const styles = {
  title: {
    marginTop: 0.5,
  },
  connect: {
    color: 'primary.main',
    background: lighten(theme.palette.primary.main, 0.8),
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.77),
      color: theme.palette.primary.dark,
    },
  },
} as const;

interface Props {
  networkMeta: ShareItemNetworkMeta;
  onConnect: () => void;
}

const NetworkDisconnected: FC<Props> = ({ networkMeta, onConnect }) => {
  const { t } = useTranslation();
  const { provider, name } = networkMeta;

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid>
            <SocialIcon state="disabled" provider={provider} />
          </Grid>
          <Grid>
            <Typography variant="subtitle2" sx={styles.title}>
              {name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Button sx={styles.connect} color="primary" onClick={onConnect}>
          {t('common:connect')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default NetworkDisconnected;
