import React, { FC } from 'react';
import { Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { ReleaseToggleFragment } from 'api';

import Loader from '../Client/Loader';
import { updateReleaseToggle } from '../../Links';

interface IProps {
  releaseToggles: ReleaseToggleFragment[];
  isLoading: boolean;
}

const ReleaseToggleTable: FC<IProps> = ({ isLoading, releaseToggles }) => {
  function renderHeadCell(text: string) {
    return (
      <Typography title={text} noWrap variant="body2">
        {text}
      </Typography>
    );
  }

  function renderRow(releaseToggle: ReleaseToggleFragment) {
    return (
      <TableRow key={releaseToggle.release_id}>
        <TableCell>
          <Link underline={'always'} component={updateReleaseToggle(releaseToggle.release_id)}>
            {releaseToggle.description}
          </Link>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{renderHeadCell('Description')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading && <Loader rows={4} />}
        {releaseToggles.map(renderRow)}
      </TableBody>
    </Table>
  );
};

export default ReleaseToggleTable;
