import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link, Typography, TypographyTypeMap } from '@mui/material';

import { ContentShareFragment } from 'api';
import { generateLink } from 'Links';
import { convertHTMLToText } from 'utilities';
import { isPhotoOnly, isVideoOnly } from 'features/content/helpers';

interface Props {
  content: ContentShareFragment;
  variant?: TypographyTypeMap['props']['variant'];
  color?: TypographyTypeMap['props']['color'];
}

const MAX_BODY_LENGTH = 255;

const Body: FC<Props> = ({ content, color, variant }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { content_id } = content;
  let { body } = content.content_object;

  if (!body) {
    if (isPhotoOnly(content)) {
      return <Typography>{t('admin:imageOnlyPost')}</Typography>;
    }

    if (isVideoOnly(content)) {
      return <Typography>{t('admin:videoOnlyPost')}</Typography>;
    }

    return <Typography>{t('admin:linkOnlyPost')}</Typography>;
  }

  if (body && body.length > MAX_BODY_LENGTH) {
    body = `${body.substring(0, MAX_BODY_LENGTH)}...`;
  }

  return (
    <Link
      color={color}
      underline="none"
      sx={{ fontSize: theme.mixins.clamp(2) }}
      variant={variant}
      component={generateLink(`/content/${content_id}`)}
    >
      {convertHTMLToText(body)}
    </Link>
  );
};

Body.defaultProps = {
  variant: 'subtitle2',
  color: 'textPrimary',
};

export default Body;
