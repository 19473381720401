import React, { FC } from 'react';
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';

import Setting from './Setting';

const Privileges: FC = () => {
  return (
    <Grid container>
      <Grid xs={3}>
        <Typography variant="subtitle2">User Privileges</Typography>
      </Grid>
      <Grid xs>
        <Grid container direction="column" wrap="nowrap" spacing={2}>
          <Grid>
            <Setting
              value="allow_user_signup"
              title="User Signups"
              description="SSO JIT Provisioning / users can sign up without an invitation"
            />
          </Grid>
          <Grid>
            <Setting
              value="allow_group_creation"
              title="Group Creation"
              description="users can create groups"
            />
          </Grid>
          <Grid>
            <Setting
              value="allow_sharing"
              title="Allow Sharing"
              description="allow users to share content"
            />
          </Grid>
          <Grid>
            <Setting
              value="allow_external_share"
              title="Allow External Shares"
              description="allow external shares"
            />
          </Grid>
          <Grid>
            <Setting
              value="moderator_can_mark_as_important"
              title="Moderator can Mark Important"
              description="allow moderators to mark content as important."
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Privileges;
