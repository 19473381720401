import React, { FC } from 'react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { ContentInput } from 'api';
import { useReleases } from 'hooks';
import { useLocation } from 'react-router-dom';

import * as paths from 'paths';

import PhotoUpload from '../Thumbnails/PhotoUpload';
import { DraggableThumbnails, SelectableThumbnails } from '../Thumbnails';
import SharingThumbnails from 'features/sharing/components/Thumbnails';

interface Props {
  disabled?: boolean;
}

const PhotoStrip: FC<Props> = () => {
  const releases = useReleases();
  const { values } = useFormikContext<ContentInput>();
  const { pathname } = useLocation();

  const hasVariations = releases.includes('shareVariations');
  const { photos } = values.content_object;
  const isExternalShare = pathname === paths.share;

  function renderThumbnails(arrayHelpers: FieldArrayRenderProps) {
    if (hasVariations) {
      if (isExternalShare) {
        return <SharingThumbnails images={photos} />;
      }

      return <DraggableThumbnails arrayHelpers={arrayHelpers} images={photos} />;
    }

    return <SelectableThumbnails photos={photos} />;
  }

  return (
    <FieldArray name="content_object.photos">
      {arrayHelpers => (
        <Grid container>
          {renderThumbnails(arrayHelpers)}
          <Grid>
            <PhotoUpload arrayHelpers={arrayHelpers} />
          </Grid>
        </Grid>
      )}
    </FieldArray>
  );
};

export default PhotoStrip;
