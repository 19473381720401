const styles = {
  animation: {
    animation: 'animation-shimmering 1.5s linear infinite',
    backgroundColor: 'grey.100',
    backgroundImage: `linear-gradient(-90deg, ${'grey.100'} 0%, ${'grey.200'} 50%, ${'grey.100'} 100%)`,
    backgroundPosition: '-300px 0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '300px 100%',
  },
  divider: {
    backgroundColor: 'grey.100',
  },
} as const;

export default styles;
