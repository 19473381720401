import React, { CSSProperties, FC, useMemo, useState } from 'react';
import { createEditor } from 'slate';
import { RenderPlaceholderProps, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { Box, ClickAwayListener, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { getIn, useFormikContext, useField } from 'formik';
import isHTML from 'is-html';

import SlateInstance from './SlateInstance';
import { withEmojis, withLinks } from './plugins';
import { SlateValue } from './types';
import { deserialize } from './serialization';
import { EmojiContextProvider, SelectionContextProvider } from './Contexts';
import theme from 'theme';

interface Props {
  fieldName: string;
  placeholder: string;
  blockListFieldName?: string;
  label?: string;
  renderPlaceholder?: (props: RenderPlaceholderProps) => JSX.Element;
  sx?: CSSProperties;
  disabled?: boolean;
}

const styles = {
  editor: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 0.5,
    padding: 2,
    paddingBottom: 0,
  },
  focused: {
    '&:after': {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 0.5,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      content: '""',
      position: 'absolute',
      transform: 'scaleX(1)',
      pointerEvents: 'none',
    },
  },
  label: {
    marginTop: -2,
  },
  error: {
    border: `2px solid ${theme.palette.error.main}`,

    '&:after': {
      border: `1px solid ${theme.palette.error.main}`,
      borderRadius: 4,
    },
  },
} as const;

const SlateEditor: FC<Props> = props => {
  const { disabled, fieldName, label, placeholder, blockListFieldName, renderPlaceholder } = props;
  const { values } = useFormikContext();
  const [, meta, helpers] = useField(fieldName);

  const initialState = getInitialState();
  const [slateValue, setSlateValue] = useState<SlateValue[]>(initialState);
  const [focused, setFocused] = useState(false);

  const editor = useMemo(() => withHistory(withLinks(withEmojis(withReact(createEditor())))), []);

  const hasError = !!meta.touched && !!meta.error;
  const errorText = meta.error;

  const editorClasses = {
    ...styles.editor,
    ...(hasError && styles.error),
    ...(!hasError && focused && styles.focused),
  };

  function getInitialValue(text: string): SlateValue[] {
    return [
      {
        type: 'paragraph',
        children: [{ text: text || '' }],
      },
    ];
  }

  function getInitialState() {
    const fieldValue = getIn(values, fieldName);
    let initialValue = getInitialValue(fieldValue);

    if (isHTML(fieldValue)) {
      const document = new DOMParser().parseFromString(getIn(values, fieldName), 'text/html');
      initialValue = deserialize(document.body) as SlateValue[];
    }

    return initialValue;
  }

  function handleClickAway() {
    setFocused(false);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl
        variant="standard"
        error={hasError}
        fullWidth
        focused={focused}
        disabled={disabled}
      >
        {!!label && (
          <InputLabel sx={styles.label} shrink>
            {label}
          </InputLabel>
        )}
        <Box sx={editorClasses}>
          <SelectionContextProvider editor={editor}>
            <EmojiContextProvider editor={editor}>
              <SlateInstance
                editor={editor}
                slateValue={slateValue}
                fieldName={fieldName}
                placeholder={placeholder}
                blockListFieldName={blockListFieldName}
                renderPlaceholder={renderPlaceholder}
                onChange={value => {
                  setSlateValue(value);
                }}
                onFocus={() => {
                  setFocused(true);
                  helpers.setTouched(true);
                }}
              />
            </EmojiContextProvider>
          </SelectionContextProvider>
        </Box>
        {hasError && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </ClickAwayListener>
  );
};

export default SlateEditor;
