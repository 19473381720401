import React, { FC } from 'react';
import { Paper, Unstable_Grid2 as Grid } from '@mui/material';

import { LoaderComponents } from 'components';

const Loader: FC = () => {
  const { Block, Image } = LoaderComponents;

  return (
    <Paper sx={{ paddingY: 1, paddingX: 2 }}>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid>
            <Image width={50} height={50} shape="circular" />
          </Grid>
          <Grid xs>
            <Block width="70%" />
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs>
            <Image width="100%" height={300} />
          </Grid>
        </Grid>
        <Grid>
          <Grid container direction="column" spacing={2} wrap="nowrap">
            <Grid xs>
              <Block width="90%" />
            </Grid>
            <Grid xs>
              <Block width="60%" />
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid container alignItems="center">
            <Grid xs>
              <Block width="40%" />
            </Grid>
            <Grid>
              <Image shape="circular" width={50} height={50} />
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid container direction="column" wrap="nowrap" spacing={1}>
            <Grid>
              <Grid container alignItems="center" spacing={1}>
                <Grid>
                  <Image shape="circular" width={30} height={30} />
                </Grid>
                <Grid xs>
                  <Block width="100%" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Loader;
