import React, { Dispatch, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FormikForm, Formik } from 'formik';
import { Dialog as MuiDialog } from '@mui/material';
import { find } from 'lodash';

import {
  Network,
  ScheduledShareUpdateInputItem,
  SharesItemFragment,
  useUpdateScheduledSharesMutation,
} from 'api';
import { useGlobalClient, useNotify, useSocialConnections } from 'hooks';
import { getSocialAccountName } from 'helpers';
import { getClientCommentCharLimit } from 'features/posting/helpers';

import { Action } from '../../types';
import { useUpdateSharesItems } from '../../updaters';

import Form from './Form';

interface Props {
  open: boolean;
  shareItem: SharesItemFragment;
  setAction: Dispatch<Action>;
}

const Dialog: FC<Props> = ({ open, shareItem, setAction }) => {
  const notify = useNotify();
  const client = useGlobalClient();
  const { t } = useTranslation();
  const { connections } = useSocialConnections();

  const { id, network, network_id, content, user_commentary, share_at } = shareItem;
  const charLimit = getClientCommentCharLimit(network as Network, content, client);
  const account = find(connections, ['id', network_id]);
  const socialAccountName = getSocialAccountName(account);

  const initialValues: ScheduledShareUpdateInputItem = {
    id,
    share_at,
    user_commentary,
  };

  const updateSharesItemsOptions = useUpdateSharesItems({
    scheduled: true,
    group_by_session: false,
    data: {
      limit: 25,
    },
  });

  const { mutate } = useUpdateScheduledSharesMutation({
    ...updateSharesItemsOptions,
    onSuccess: () => notify.info({ message: t('sharing:shareUpdated') }),
  });

  function updateScheduledShare(values: ScheduledShareUpdateInputItem) {
    mutate({
      data: {
        items: [{ ...values }],
      },
    });

    setAction(Action.None);
  }

  return (
    <MuiDialog open={open} fullWidth maxWidth="sm">
      <Formik initialValues={initialValues} onSubmit={updateScheduledShare}>
        <FormikForm>
          <Form
            limit={charLimit}
            name={socialAccountName}
            setAction={setAction}
            content={content}
            network={network as Network}
          />
        </FormikForm>
      </Formik>
    </MuiDialog>
  );
};

export default Dialog;
