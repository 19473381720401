import React, { ChangeEvent, FC } from 'react';
import { Unstable_Grid2 as Grid, MenuItem, Select, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import { SortMode } from 'api';
import { ClientInput } from '../../types';

const Feeds: FC = () => {
  const { setFieldValue, values } = useFormikContext<ClientInput>();
  const sortMode = values.sort_mode || '';

  function handleSortModeChange(event: ChangeEvent<HTMLInputElement>) {
    setFieldValue('sort_mode', event.target.value || null);
  }

  return (
    <Grid container>
      <Grid xs={3}>
        <Typography variant="subtitle2">Feeds</Typography>
      </Grid>
      <Grid xs>
        <Grid container alignItems="center" spacing={2}>
          <Grid xs={6}>
            <Typography variant="subtitle2">Sorting</Typography>
          </Grid>
          <Grid xs={6}>
            <Select
              variant="standard"
              fullWidth
              displayEmpty
              value={sortMode}
              onChange={handleSortModeChange}
            >
              <MenuItem value={''}>Set by user</MenuItem>
              <MenuItem value={SortMode.Trending}>Trending</MenuItem>
              <MenuItem value={SortMode.Chronological}>Chronological</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Feeds;
