import React, { FC, ChangeEvent } from 'react';
import { FormControlLabel, Unstable_Grid2 as Grid, Switch, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { remove, uniq } from 'lodash';

import { Feature } from 'api';

import { ClientInput } from '../../types';

const Features: FC = () => {
  const { values, setFieldValue } = useFormikContext<ClientInput>();
  const features = values.features || [];
  const availableFeatures = Object.keys(Feature);

  function isChecked(feature: string) {
    const featureToCheck = Feature[feature];
    return features.includes(featureToCheck);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const featureToCheck = Feature[event.currentTarget.name];
    const hasFeature = features.includes(featureToCheck);

    hasFeature
      ? remove(features, feature => feature === featureToCheck)
      : features.push(featureToCheck);
    setFieldValue('features', uniq(features));
  }

  return (
    <Grid container>
      <Grid xs={3}>
        <Typography variant="subtitle2">Features</Typography>
      </Grid>
      <Grid xs>
        <Grid container spacing={2}>
          {availableFeatures.map(feature => (
            <Grid key={feature}>
              <FormControlLabel
                label={feature}
                control={
                  <Switch name={feature} checked={isChecked(feature)} onChange={handleChange} />
                }
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Features;
