import Cookies from 'js-cookie';

import { getRootDomain } from 'utilities';

export const USER_EMAIL_COOKIE = 'es_user_email_address';
export const CONNECTION_CTA_COOKIE = 'es_connection_cta';
export const TRIAL_NOTIFICATION_COOKIE = 'es_trial_dismissed';
export const PUSH_NOTIFICATION_COOKIE = 'es_push_notification';
export const LOGOUT_COOKIE = 'es_logout';
export const SHARE_URL_COOKIE = 'es_share_url';
export const NEXT_PATH_COOKIE = 'es_next_path';

const WELCOME_COOKIE_DAYS = 30;
const DEFAULT_COOKIE_DAYS = 365;
const PUSH_NOTIFICATION_DAYS = 7;
const TEMP_COOKIE_DAYS = 1;

const getSetterOptions = (days = DEFAULT_COOKIE_DAYS) => {
  const domain = getRootDomain();
  const options = { expires: days, domain: `.${domain}`, secure: true };
  (options as any).SameSite = 'None';
  return options;
};

export default {
  set userEmail(email: string) {
    Cookies.set(USER_EMAIL_COOKIE, email, getSetterOptions());
  },
  set connectionCta(value: string) {
    Cookies.set(CONNECTION_CTA_COOKIE, value, getSetterOptions(WELCOME_COOKIE_DAYS));
  },
  set pushNotification(value: string) {
    Cookies.set(PUSH_NOTIFICATION_COOKIE, value, getSetterOptions(PUSH_NOTIFICATION_DAYS));
  },
  set trialDismissed(value: string) {
    Cookies.set(TRIAL_NOTIFICATION_COOKIE, value, getSetterOptions());
  },
  set logout(value: string) {
    Cookies.set(LOGOUT_COOKIE, value, getSetterOptions(TEMP_COOKIE_DAYS));
  },
  set shareUrl(value: string) {
    Cookies.set(SHARE_URL_COOKIE, value, getSetterOptions(TEMP_COOKIE_DAYS));
  },
  set nextPath(value: string) {
    Cookies.set(NEXT_PATH_COOKIE, value, getSetterOptions(TEMP_COOKIE_DAYS));
  },
  get userEmail() {
    return Cookies.get(USER_EMAIL_COOKIE);
  },
  get connectionCta() {
    return Cookies.get(CONNECTION_CTA_COOKIE);
  },
  get pushNotification() {
    return Cookies.get(PUSH_NOTIFICATION_COOKIE);
  },
  get trialDismissed() {
    return Cookies.get(TRIAL_NOTIFICATION_COOKIE);
  },
  get logout() {
    return Cookies.get(LOGOUT_COOKIE);
  },
  get shareUrl() {
    return Cookies.get(SHARE_URL_COOKIE);
  },
  get nextPath() {
    return Cookies.get(NEXT_PATH_COOKIE);
  },
  remove(cookie: string) {
    const domain = getRootDomain();
    Cookies.remove(cookie, { domain: `.${domain}` });
  },
  removeAll() {
    const domain = getRootDomain();
    const options = { domain: `.${domain}` };

    /**
     * LOGOUT_COOKIE intentionally not removed
     * with removeAll() because we use it for
     * redirects. It is removed after the user
     * comes back from an SSO logout
     */
    Cookies.remove(USER_EMAIL_COOKIE, options);
    Cookies.remove(CONNECTION_CTA_COOKIE, options);
    Cookies.remove(PUSH_NOTIFICATION_COOKIE, options);
    Cookies.remove(TRIAL_NOTIFICATION_COOKIE, options);
    Cookies.remove(SHARE_URL_COOKIE, options);
    Cookies.remove(NEXT_PATH_COOKIE, options);
  },
};
