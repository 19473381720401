import React, { FC } from 'react';
import { useMatch } from 'react-router-dom';
import { find } from 'lodash';

import { ContentApproval, UserFeedFollowGroupFragment } from 'api';
import { useCapabilities, useContent, useCurrentUser } from 'hooks';
import * as paths from 'paths';

import { Approval, Meta, Waiting } from '../partials';
import { isPostCreator } from '../helpers';

interface IProps {
  selectedGroup: UserFeedFollowGroupFragment;
}

const ContentActions: FC<IProps> = ({ selectedGroup }) => {
  const currentUser = useCurrentUser();
  const content = useContent();
  const { needs, isAdmin } = useCapabilities();

  const isRemovedView = !!useMatch(paths.groupRemoved);
  const isApprovedView = !!useMatch(paths.groupApproval);
  const isScheduledView = !!useMatch(paths.groupScheduled);

  const isModerator = needs('Access.Moderator', selectedGroup?.id);

  // Permalink
  if (!selectedGroup) {
    return <Meta />;
  }

  const groupApproval = find(content.group_id_approvals, ['group_id', selectedGroup.id]);

  // Timeline
  if (!groupApproval) {
    return <Meta />;
  }

  const isScheduled = content?.schedule?.publish;
  const isSubmitted = groupApproval.approval === ContentApproval.Submitted;
  const isRemoved = groupApproval.approval === ContentApproval.Removed;

  // Scheduled
  if (isScheduledView && isScheduled) {
    return <Meta />;
  }

  // Removed View
  if (isRemovedView && isRemoved) {
    return <Approval selectedGroup={selectedGroup} showRemove={false} />;
  }

  // Moderator
  if ((isApprovedView || isAdmin || isModerator) && isSubmitted) {
    return <Approval selectedGroup={selectedGroup} showRemove={isSubmitted} />;
  }

  // Creator Waiting for Approval
  const isCreator = isPostCreator(content, currentUser);
  if ((isCreator || !isAdmin || !isModerator) && isSubmitted) {
    if (isScheduled) {
      return <Approval selectedGroup={selectedGroup} showRemove={false} />;
    }

    return <Waiting />;
  }

  // Group or Timeline Feed
  return <Meta />;
};

export default ContentActions;
