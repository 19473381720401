import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';

import { ClientDepartmentFragment, useGetClientDepartmentsByStatusQuery } from 'api';
import { useNotify } from 'hooks';

import { PopupIcon } from './components';
import useAutoCompleteStyles from './styles/useAutoCompleteStyles';

type Props = Omit<
  AutocompleteProps<ClientDepartmentFragment, false, boolean, false>,
  'renderInput' | 'options'
>;

interface Values {
  department_id: string;
}

const SingleDepartmentPicker: FC<Props> = props => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const notify = useNotify();
  const { errors, values, setFieldValue } = useFormikContext<Values>();

  const { data, isLoading } = useGetClientDepartmentsByStatusQuery(
    {},
    {
      onError: notify.queryError,
    }
  );

  const autocompleteClasses = useAutoCompleteStyles;
  const options = data?.getClientDepartmentsByStatus?.client_departments ?? [];
  const selectedOption = options.find(option => option.id === values.department_id) || null;

  function toggleOpen() {
    setOpen(!open);
  }

  function handleChange(event: ChangeEvent, nextValue: ClientDepartmentFragment) {
    setFieldValue('department_id', nextValue?.id ?? '');
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...params}
        variant="outlined"
        error={!!errors.department_id}
        placeholder={t('components:selectDepartment')}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <Fragment>
              {isLoading && <CircularProgress color="inherit" size={24} />}
              {params.InputProps.endAdornment}
            </Fragment>
          ),
        }}
        helperText={errors.department_id}
      />
    );
  }

  return (
    <Autocomplete
      open={open}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      loading={isLoading}
      sx={autocompleteClasses}
      options={options}
      getOptionLabel={option => option.title}
      popupIcon={<PopupIcon open={open} />}
      onChange={handleChange}
      value={selectedOption}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography color="textSecondary" padding={1}>
            {option.title}
          </Typography>
        </Box>
      )}
      renderInput={renderInput}
      {...props}
    />
  );
};

export default SingleDepartmentPicker;
