import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import Linkify from 'react-linkify';
import { useTheme } from '@mui/material/styles';

import { useContent } from 'hooks';
import { Events } from 'analytics';
import { RenderHTML } from 'components';
import { getRenderingEngine } from 'utilities';
import { isTextOnly } from '../helpers';

const LARGE_TEXT_THRESHOLD = 80;
const TRUNCATE_THRESHOLD = 255;

const Body: FC = () => {
  const theme = useTheme();
  const content = useContent();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const { body } = content.content_object;

  if (!body) {
    return null;
  }

  const textOnly = isTextOnly(content);
  const isLargeText = textOnly && body.length < LARGE_TEXT_THRESHOLD;
  const isClamped = body.length > TRUNCATE_THRESHOLD;
  const renderingEngine = getRenderingEngine();
  const canRenderClamp = renderingEngine === 'Blink';

  const showReadMore = isClamped && canRenderClamp && !expanded;
  const variant = isLargeText ? 'h6' : 'subtitle2';

  function handleReadMore() {
    setExpanded(true);
    window?.analytics?.track(Events.PostReadMoreClicked);
  }

  return (
    <Fragment>
      <Typography
        component="div"
        variant={variant}
        sx={showReadMore ? { fontSize: theme.mixins.clamp(3) } : {}}
      >
        <Linkify properties={{ target: '_blank' }}>
          <RenderHTML html={body} />
        </Linkify>
      </Typography>
      {showReadMore && (
        <Link
          sx={{ textDecoration: 'underline', fontWeight: theme.typography.fontWeightBold }}
          component="button"
          variant="subtitle2"
          onClick={handleReadMore}
          underline="hover"
        >
          {t('components:readMore')}
        </Link>
      )}
    </Fragment>
  );
};

export default Body;
