import { CSSProperties } from 'react';
import theme from 'theme';

export interface StyleProps {
  classes?: {
    tableBodyRow?: CSSProperties;
  };
}

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableHeaderRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  loaderRow: {
    padding: 2,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  tableBodyRow: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderBottom: '1px solid #F0F0F0',
    '&:hover': {
      zIndex: 2,
      boxShadow: `0 2px 3px ${theme.palette.grey[500]}`,
      backgroundColor: 'common.white',
      '& > :last-child': {
        display: 'flex',
      },
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    '& td:first-of-type': {
      borderBottom: 'none',
    },
  },
  paddingNone: {
    padding: 0,
  },
  paddingDense: {
    padding: theme.spacing(),
  },
  paddingCheckbox: {
    padding: 0,
  },
  paddingNormal: {
    padding: 2,
  },
  sortableCell: {
    display: 'flex',
    '& > svg': {
      marginLeft: 0.5,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  actionCell: {
    display: 'none',
    position: 'absolute',
    right: 2,
    height: '100%',
    zIndex: 2,
    backgroundColor: 'inherit',
  },
} as const;

export default styles;
