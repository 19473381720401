import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from '@mui/material';

export const OAuthTokenError = (notify: any) => {
  notify.info({
    duration: 8000,
    component: (
      <Trans i18nKey="'groups:oAuthTokenError'">
        Connect a Twitter account to add Twitter sources to this group. You can do that in your{' '}
        {renderAccountSettingsLink()}
      </Trans>
    ),
  });
};

function renderAccountSettingsLink() {
  return (
    <Link color="primary" href="/account" target="_blank" underline="hover">
      Account Settings
    </Link>
  );
}
