import { UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  DeleteApiTokenMutation,
  DeleteApiTokenMutationVariables,
  GetApiTokensQuery,
  useGetApiTokensQuery,
} from 'api';
import { useNotify } from 'hooks';

type Mutation = DeleteApiTokenMutation;
type MutationVariables = DeleteApiTokenMutationVariables;
type QueryData = GetApiTokensQuery;

const useDeleteApiToken = () => {
  const queryClient = useQueryClient();
  const queryKey = useGetApiTokensQuery.getKey({});
  const notify = useNotify();

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async ({ data }) => {
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const { getAPITokens } = draftState;
          draftState.getAPITokens = getAPITokens.filter(item => item.token_id !== data.token_id);
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useDeleteApiToken;
