import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Paper, Tab, Tabs, Typography } from '@mui/material';

import { IndividualStat, ReachDailyStat, ReachOverviewStat, ReachSummary } from 'api';
import { DatePickers, Queries } from 'components';

import { useDateRange, useNetworkCapabilities } from 'hooks';

import { AreaChart, ChartLoader, IAreaChartConfig, TabLabel } from '../shared';
import { EXTERNAL_CHART_HEIGHT, TABBED_CHART_EXCESS_HEIGHT } from '../constants';
import useStyles from '../analyticsStyles';

const TotalReachOverviewStats: FC = () => {
  const { t } = useTranslation();
  const { hasTwitter } = useNetworkCapabilities();
  const classes = useStyles;

  const { startDate, setStartDate, endDate, setEndDate, dateRange, setDateRange } = useDateRange();

  function renderReachWithCapability(queryResults: ReachOverviewStat) {
    const { summary, data } = queryResults;

    return (
      <Fragment>
        {renderSummaryTabs(!hasTwitter ? summary.linkedin_reach : summary.reach)}
        <Box sx={classes.tabbedChartWrapper}>{renderChart(summary, data)}</Box>
      </Fragment>
    );
  }

  function renderSummaryTabs(totalReach: IndividualStat) {
    return (
      <Tabs value="reach" TabIndicatorProps={{ sx: classes.hiddenIndicator }}>
        <Tab
          sx={{ root: classes.tabRoot, alignItems: 'flex-start', justifyContent: 'flex-start' }}
          label={
            <TabLabel
              stat={totalReach}
              i18nKey="common:total_reach"
              tooltipI18nKey="help:admin_external_followers"
            />
          }
          value="reach"
        />
      </Tabs>
    );
  }

  function renderChart(summary: ReachSummary, data: ReachDailyStat[]) {
    let config: IAreaChartConfig[] = [
      { name: 'Twitter', dataKey: 'twitter_reach', value: summary.twitter_reach },
      { name: 'LinkedIn', dataKey: 'linkedin_reach', value: summary.linkedin_reach },
    ];

    if (!hasTwitter) {
      config = config.filter(chart => chart.name !== 'Twitter');
    }

    return <AreaChart height={EXTERNAL_CHART_HEIGHT} data={data} config={config} />;
  }

  return (
    <Fragment>
      <Typography paragraph sx={classes.hidden} color="textSecondary" variant="h5">
        {t('analytics:total_reach')}
      </Typography>
      <Paper>
        <Queries.TotalReachOverviewStats
          loader={
            <ChartLoader
              type="chartArea"
              height={EXTERNAL_CHART_HEIGHT + TABBED_CHART_EXCESS_HEIGHT}
            />
          }
          startDate={startDate}
          endDate={endDate}
        >
          {totalReachOverviewStats => renderReachWithCapability(totalReachOverviewStats)}
        </Queries.TotalReachOverviewStats>
        <Divider />
        <DatePickers.Range
          dateRange={dateRange}
          startDate={startDate}
          endDate={endDate}
          onSetStart={setStartDate}
          onSetEnd={setEndDate}
          onSetRange={setDateRange}
          transparent
        />
      </Paper>
    </Fragment>
  );
};

export default TotalReachOverviewStats;
