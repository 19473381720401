import React, { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArrayRenderProps, getIn, useFormikContext } from 'formik';
import { Button, IconButton, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { filter } from 'lodash';

import { Content, ContentCommentary, Network } from 'api';
import { DeleteIcon, MentionsEditor, SocialIcon } from 'components';
import { useProtectedClient, useReleases } from 'hooks';
import theme from 'theme';

import { getClientCommentCharLimit } from 'features/posting/helpers';
import { MAX_SHARE_COPY_COUNT } from 'features/posting/constants';

interface Props extends FieldArrayRenderProps {
  network: Network;
  disabled?: boolean;
}

interface ShareCopyProps extends ContentCommentary {
  index: number;
}

const styles = {
  icon: {
    marginLeft: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  textField: {
    backgroundColor: 'transparent',
    marginBottom: theme.spacing(1),
  },
  disclosure: {
    padding: theme.spacing(0, 2, 1, 2),
  },
  textLimitReached: {
    color: theme.palette.error.main,
  },
} as const;

const ShareCopy: FC<Props> = ({ disabled, network, push, remove }) => {
  const releases = useReleases();
  const { t } = useTranslation();
  const client = useProtectedClient();
  const { values, setFieldValue } = useFormikContext<Content>();

  const hasVariations = releases.includes('shareVariations');
  const hasLinkedInMentions = releases.includes('linkedinCompanyMentions');

  const limit = getClientCommentCharLimit(network, values, client);
  const shouldShowMentionsEditor = network === Network.Linkedin && hasLinkedInMentions;

  const [shareCopies, setShareCopies] = useState<ShareCopyProps[]>(getShareCopies);

  useEffect(() => {
    setShareCopies(getShareCopies());
  }, [values.commentary]);

  function getShareCopies() {
    const updatedCommentaries = values.commentary.map((commentary, index) => ({
      ...commentary,
      index,
    }));
    return filter(updatedCommentaries, ['network', network]);
  }

  function onClickAddShareCopy() {
    if (shareCopies.length >= MAX_SHARE_COPY_COUNT) {
      return null;
    }

    return push({ network, commentary: '' });
  }

  function onClickRemove(index: number) {
    if (shareCopies.length > 1) {
      remove(index);
      return;
    }

    setFieldValue(`commentary[${index}]`, { network, commentary: '' });
  }

  function renderShareCopy(shareCopy: ShareCopyProps, idx: number) {
    const { index } = shareCopy;
    const fieldName = `commentary[${index}].commentary`;
    const mentionsFieldName = `commentary[${index}].mentions`;
    const blockListFieldName = `commentary[${index}].blockList`;
    const value = getIn(values, fieldName) || '';

    return (
      <Grid container alignItems="center" key={shareCopy.network + index}>
        <Grid xs={1}>
          {idx === 0 && (
            <SocialIcon
              state={
                disabled ? 'disabled' : value || shareCopies.length > 1 ? 'active' : 'disabled'
              }
              provider={network}
            />
          )}
        </Grid>
        <Grid xs={10}>
          <Grid container direction="column" sx={styles.textField}>
            <Grid>
              <MentionsEditor
                fieldName={fieldName}
                mentionsFieldName={mentionsFieldName}
                blockListFieldName={blockListFieldName}
                placeholder={t(`sharing:${network}`)}
                publicQuery={false}
                showTextCounter={true}
                disableMentions={!shouldShowMentionsEditor}
                network={network}
                disabled={disabled}
              />
            </Grid>
            {value.length > limit && (
              <Grid marginTop={0.5}>
                <Typography sx={styles.textLimitReached}>
                  {t('sharing:commentaryTooLong', { max: limit })}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!!value && (
          <Grid xs={1}>
            <IconButton
              onClick={() => onClickRemove(index)}
              size="small"
              sx={styles.icon}
              disabled={disabled}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  }

  function renderAddShareCopyButton() {
    const isValue = !!shareCopies[shareCopies.length - 1]?.commentary;
    if (shareCopies.length === MAX_SHARE_COPY_COUNT || !isValue || disabled) {
      return null;
    }

    return (
      <Grid container alignItems="center" marginBottom={2}>
        <Grid xs={1} />
        <Grid xs={10}>
          <Button variant="outlined" color="secondary" fullWidth onClick={onClickAddShareCopy}>
            <Typography color="textSecondary">{t('posting:shareCopyButton')}</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Fragment>
      {shareCopies.map(renderShareCopy)}
      {hasVariations && renderAddShareCopyButton()}
    </Fragment>
  );
};

export default ShareCopy;
