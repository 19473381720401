import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { CustomSVG } from 'components';

interface Props {
  onClick: (event: MouseEvent) => void;
}

const ExportData: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      size="large"
      variant="contained"
      color="secondary"
      sx={{ backgroundColor: 'grey.200' }}
      onClick={onClick}
      startIcon={<CustomSVG name="download" />}
    >
      {t('admin:exportData')}
    </Button>
  );
};

export default ExportData;
