import React, { ChangeEvent, FC } from 'react';
import { useField } from 'formik';
import { StandardTextFieldProps, TextField, TextFieldProps } from '@mui/material';
import theme from 'theme';

interface Props extends Omit<StandardTextFieldProps, 'variant'> {
  enableTrim?: boolean;
  disableError?: boolean;
  variant?: TextFieldProps['variant'];
  valueFn?: (value: unknown) => string;
}

const ValidatedTextFieldNext: FC<Props> = props => {
  const { name, onChange, enableTrim, disableError, variant, valueFn, placeholder, ...rest } =
    props;
  const [field, meta] = useField(name);

  const value = valueFn ? valueFn(field.value) : field.value;
  const hasError = !!meta.error && !!meta.touched;
  const errorText =
    hasError && !disableError
      ? {
          helperText: meta.error,
        }
      : {};

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (enableTrim) {
      event.target.value = event.target.value.trim();
    }

    field.onChange(event);
    onChange?.(event);
  }

  return (
    <TextField
      variant={variant}
      error={!!meta.error && !!meta.touched}
      value={value || ''}
      inputProps={{ name: field.name, ...rest.inputProps }}
      onBlur={field.onBlur}
      onChange={handleChange}
      placeholder={placeholder}
      label={rest.label}
      {...errorText}
      {...rest}
      // @ts-ignore
      sx={{
        Input: {
          backgroundColor: theme.palette.grey[100],
          padding: theme.spacing(2),
          ...rest.InputProps?.sx,
        },
      }}
    />
  );
};

ValidatedTextFieldNext.defaultProps = {
  enableTrim: false,
  variant: 'standard',
};

export default ValidatedTextFieldNext;
