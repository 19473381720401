import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { InboxFragment, useMarkInboxAsReadMutation } from 'api';
import { Notifications as NotificationsLink } from 'Links';
import { EmptyState } from 'components';
import { useNotify } from 'hooks';

import { useMarkInboxAsRead } from '../updaters';
import Notification from '../components/Notification';
import theme from 'theme';

interface Props {
  inbox: InboxFragment[];
  onClick(): void;
}

const NotificationsPopup: FC<Props> = ({ onClick, inbox }) => {
  const notify = useNotify();
  const { t } = useTranslation();

  const mutationOptions = useMarkInboxAsRead();
  const { mutate: markInboxAsRead } = useMarkInboxAsReadMutation({
    onError: notify.mutationError,
    ...mutationOptions,
  });

  function renderHeader() {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent={inbox.length ? 'space-between' : 'center'}
        sx={{
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          padding: theme => theme.spacing(1.5, 3),
        }}
      >
        <Grid>
          <Typography variant="subtitle2">{t('common:notification_plural')}</Typography>
        </Grid>
        {!!inbox.length && (
          <Grid>
            <Link component="button" onClick={() => markInboxAsRead({})} underline="hover">
              {t('components:markRead')}
            </Link>
          </Grid>
        )}
      </Grid>
    );
  }

  function renderFooter() {
    return (
      <Box
        sx={{
          padding: theme => theme.spacing(1.5, 3),
          outline: 'none',
          borderTop: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Grid container alignItems="center" justifyContent="space-around">
          <Grid>
            <Link component={NotificationsLink} onClick={onClick} underline="hover">
              {t('components:seeAll')}
            </Link>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ width: 300 }}>
      {renderHeader()}
      {!inbox.length && <EmptyState fullWidth name="notificationPop" />}
      {inbox.map(item => (
        <Notification onClick={onClick} key={item.id} size="regular" item={item} />
      ))}
      {renderFooter()}
    </Box>
  );
};

export default NotificationsPopup;
