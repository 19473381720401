import React, { FC, MouseEvent, useState } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { FieldArrayRenderProps, useFormikContext } from 'formik';

import { ContentInput, S3Object } from 'api';
import { IMGIX_HOST } from 'settings';
import { queryString } from 'utilities';
import { getImageSrc } from 'helpers';
import { useContentVariations } from 'hooks';
import { THUMBNAIL_SIZE } from 'app-constants';
import theme from 'theme';

import { usePostContext } from 'features/posting/PostContext';
import { ACTIVE_THUMBNAIL_COUNT } from 'features/posting/constants';

const styles = {
  thumbnail: {
    cursor: 'move',
  },
  icon: {
    position: 'absolute',
    margin: 1.25,
    background: 'common.white',
    borderRadius: 3,
    '&:hover': {
      cursor: 'pointer',
    },
    '&:active': {
      cursor: 'pointer',
    },
  },
  removeIcon: {
    cursor: 'pointer',
    backgroundColor: 'grey.400',
    color: 'common.white',
    margin: theme.spacing(1.25, 7.25),
  },
  hover: {
    filter: 'grayscale(20%)',
  },
  inactiveThumbnail: {
    opacity: '50%',
    '&:hover': {
      cursor: 'disabled',
    },
  },
} as const;

interface Props extends FieldArrayRenderProps {
  photo: S3Object;
  isDragging: boolean;
  index?: number;
}

const Image: FC<Props> = ({ photo, isDragging, index, ...helpers }) => {
  const { setPhotoIndex } = useContentVariations();
  const { state, dispatch } = usePostContext();
  const { setFieldTouched } = useFormikContext<ContentInput>();

  const { selectedPhotoIndexes } = state;

  const [mouseHover, setMouseHover] = useState(false);

  const isDraggable = selectedPhotoIndexes.length > index;
  const inactive =
    selectedPhotoIndexes.length === ACTIVE_THUMBNAIL_COUNT && index >= ACTIVE_THUMBNAIL_COUNT;
  const params = {
    h: THUMBNAIL_SIZE,
    w: THUMBNAIL_SIZE,
    fit: 'crop',
    auto: 'format',
    border: '3,FFFFFFFF',
    'border-radius': 4,
  };

  const imageUrl = `${IMGIX_HOST}/${photo.key}?${queryString(params)}`;
  const imageSrc = getImageSrc(imageUrl);

  function onClickIcon(event: MouseEvent<SVGSVGElement>) {
    const targetIndex = selectedPhotoIndexes.length - 1;
    if (event.currentTarget.dataset.active === 'active') {
      helpers.move(index, targetIndex);
      dispatch({ type: 'updatePhotoIndexes', value: targetIndex });
      setFieldTouched('content_object.photos', true);
    } else {
      helpers.move(index, targetIndex + 1);
      dispatch({ type: 'updatePhotoIndexes', value: targetIndex + 1 });
      setFieldTouched('content_object.photos', true);
    }
  }

  function renderIcon() {
    const isPhotoSelected = selectedPhotoIndexes.includes(index);

    if (isPhotoSelected) {
      return (
        <CheckCircleRoundedIcon
          data-active="active"
          color="primary"
          sx={styles.icon}
          onClick={onClickIcon}
        />
      );
    }

    if (selectedPhotoIndexes.length === ACTIVE_THUMBNAIL_COUNT) {
      return null;
    }

    return (
      <RadioButtonUncheckedRoundedIcon
        data-active="inactive"
        sx={styles.icon}
        color="disabled"
        onClick={onClickIcon}
      />
    );
  }

  return (
    <Grid
      id={photo.key}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
      sx={{
        ...(isDraggable && [styles.thumbnail]),
        ...(mouseHover && [styles.hover]),
        ...(inactive && [styles.inactiveThumbnail]),
      }}
      onClick={() => setPhotoIndex(index)}
    >
      {!isDragging && renderIcon()}
      <img id={photo.key} {...imageSrc} />
    </Grid>
  );
};

Image.defaultProps = {
  isDragging: false,
  index: 0,
};

export default Image;
