import React, { FC, Fragment, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Menu, MenuItem, Typography } from '@mui/material';

import { GetFeedGroupFragment, GroupFragment } from 'api';
import { generateLink } from 'Links';
import { useContent } from 'hooks';

import { getApprovedGroups } from '../helpers';

interface Props {
  groups?: GetFeedGroupFragment[];
}

const SHOWN_GROUPS = 2;

const styles = {
  moreText: {
    display: 'inline-block',
    marginLeft: 1,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  menuItem: {
    '&:hover, &:focus': {
      textDecoration: 'underline',
      backgroundColor: 'common.white',
    },
  },
} as const;

const Groups: FC<Props> = ({ groups }) => {
  const { t } = useTranslation();
  const content = useContent();

  groups = groups?.length ? groups : getApprovedGroups(content) ?? [];

  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function renderPrimaryGroup() {
    if (!content.primary_group_id) {
      return null;
    }

    const primaryGroup = groups.find(group => group.id === content.primary_group_id);

    return (
      primaryGroup && (
        <Fragment key={primaryGroup.id}>
          <Link
            variant="body2"
            component={generateLink(`/group/${primaryGroup.id}`)}
            underline="hover"
          >
            {primaryGroup.title}
          </Link>
        </Fragment>
      )
    );
  }

  function renderRepostPrompt() {
    return content.primary_group_id && groups.length > 1 && ` -  ${t('posting:repost')}: `;
  }

  function renderGroupsList() {
    groups = groups.filter(group => group.id !== content.primary_group_id);
    const lastIndex = Math.min(groups.length - 1, SHOWN_GROUPS - 1);
    const firstTwo = groups.slice(0, SHOWN_GROUPS);

    return firstTwo.map(({ id, title }, index) => (
      <Fragment key={id}>
        <Link component={generateLink(`/group/${id}`)} underline="hover">
          {title}
        </Link>
        {index !== lastIndex && <span>, </span>}
      </Fragment>
    ));
  }

  function renderMenuItem(group: GroupFragment) {
    const { id, title } = group;

    const itemProps = {
      key: id,
      component: generateLink(`/group/${id}`),
    };

    return (
      <MenuItem sx={styles.menuItem} {...itemProps}>
        {title}
      </MenuItem>
    );
  }

  function renderGroupsMenu() {
    const remainingGroups = groups.slice(SHOWN_GROUPS);

    return (
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}>
        <div>{remainingGroups.map(renderMenuItem)}</div>
      </Menu>
    );
  }

  function renderMoreLink() {
    const needsMore = groups.length > SHOWN_GROUPS;
    return (
      needsMore && (
        <Typography component="span" onClick={handleClick} sx={styles.moreText}>
          {t('content:xMoreGroups', { groups: groups.length - SHOWN_GROUPS })}
        </Typography>
      )
    );
  }

  if (!groups || !groups.length) {
    return null;
  }

  return (
    <Typography component="span">
      {renderPrimaryGroup()}
      {renderRepostPrompt()}
      {renderGroupsList()}
      {renderMoreLink()}
      {renderGroupsMenu()}
    </Typography>
  );
};

Groups.defaultProps = {
  groups: [],
};

export default Groups;
