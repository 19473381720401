import gql from 'graphql-tag';
import { Inbox } from 'api/fragments';

export default gql`
  subscription INBOX_ITEM_CREATED($user_id: ID!) {
    inboxItemCreated(to_user_id: $user_id) {
      ...Inbox
    }
  }
  ${Inbox}
`;
