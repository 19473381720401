import React, { ChangeEvent, ClipboardEvent, FC, KeyboardEvent, useRef, useState } from 'react';
import {
  Chip,
  darken,
  FormControl,
  FormHelperText,
  Input,
  lighten,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { CreateClientInvitationInput } from 'api';
import palette from 'theme/palette';
import theme from 'theme';

const styles = {
  wrapper: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 4,
    padding: 2,
    minHeight: 15,
    '& > *': {
      margin: 0.5,
    },
  },
  focused: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  error: {
    border: `2px solid ${theme.palette.error.main}`,
    padding: `15px`,
  },
  input: {
    minHeight: 'auto',
  },
  inputFocused: {
    '&:after': {
      border: `${0} !important`,
    },
  },
  chipError: {
    color: palette.red,
    backgroundColor: lighten(palette.red, 0.9),
    '&:focus': {
      backgroundColor: lighten(palette.red, 0.6),
    },
  },
  iconError: {
    color: palette.red,

    '&:hover': {
      color: darken(palette.red, 0.1),
    },
  },
} as const;

const InviteInput: FC = () => {
  const { t } = useTranslation();
  const { values, errors, isValid, setFieldValue } =
    useFormikContext<CreateClientInvitationInput>();

  const inputEl = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  function getPlaceholder() {
    if (!isEmpty(values.email_addresses) || inputValue) {
      return null;
    }

    return t('invites:inputPlaceholderGeneric');
  }

  function getErrorMessage() {
    const error = get(errors, 'email_addresses');
    if (typeof error === 'string') {
      return errors.email_addresses;
    }

    if (error.length > 1) {
      return error[error.length - 1];
    }

    return error[0];
  }

  function onChangeInput(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value !== ' ') {
      setInputValue(event.target.value);
    }
  }

  function onClickForm() {
    inputEl.current.focus();
  }

  function onPasteClipboard(event: ClipboardEvent) {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain');
    const emails = text.trim().split(/[ ,]+/);
    setFieldValue('email_addresses', [...values.email_addresses, ...emails]);
  }

  function onKeyDown(
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    arrayHelpers: ArrayHelpers
  ): null {
    switch (event.key) {
      case ' ':
      case 'Enter': {
        if (inputValue == '' || inputValue === ' ') {
          return null;
        }

        arrayHelpers.push(inputValue);
        setInputValue('');
        break;
      }
      case 'Backspace': {
        if (inputValue == '') {
          if (!isEmpty(values.email_addresses)) {
            arrayHelpers.remove(-1);
            break;
          }
        }
        break;
      }
    }

    return null;
  }

  return (
    <FieldArray name="email_addresses" validateOnChange>
      {arrayHelpers => (
        <FormControl variant="standard" fullWidth error={!isEmpty(errors)} onClick={onClickForm}>
          <Grid
            container
            sx={{
              ...styles.wrapper,
              ...(isFocused ? styles.focused : {}),
              ...(!isValid ? styles.error : {}),
            }}
          >
            {values.email_addresses.map((email, idx) => (
              <Grid key={`${email}${idx}`}>
                <Chip
                  tabIndex={-1}
                  label={email}
                  size="small"
                  sx={{
                    root: !isValid && errors.email_addresses[idx] ? styles.chipError : {},
                  }}
                  onDelete={() => arrayHelpers.remove(idx)}
                  deleteIcon={
                    <CloseRoundedIcon
                      sx={!isValid && errors.email_addresses[idx] ? styles.iconError : {}}
                    />
                  }
                />
              </Grid>
            ))}
            <Grid xs>
              <Input
                fullWidth
                inputRef={inputEl}
                sx={{ input: styles.input, ...styles.inputFocused }}
                value={inputValue}
                onChange={onChangeInput}
                onFocus={() => setIsFocused(true)}
                placeholder={getPlaceholder()}
                onKeyDown={event => onKeyDown(event, arrayHelpers)}
                inputProps={{
                  onPaste: onPasteClipboard,
                }}
              />
            </Grid>
          </Grid>
          <FormHelperText>{!isValid && getErrorMessage()}</FormHelperText>
        </FormControl>
      )}
    </FieldArray>
  );
};

export default InviteInput;
