import React, { FC, Fragment, useState } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';

import { useGetOiCtaIntelQuery } from 'api';
import { useNotify, useProtectedClient } from 'hooks';
import theme from 'theme';

interface Props {
  variant: 'small' | 'large';
}

const styles = {
  yellow: {
    backgroundColor: '#FEFF06',
  },
} as const;

const IntelligenceHeader: FC<Props> = ({ variant }) => {
  const client = useProtectedClient();
  const notify = useNotify();

  // This is the start of today utc (independent of the user's timezone). We will have a static
  // timestamp that will allow the cache on the backend to be used for the same day. Allows
  // for better performance of the query.
  const startOfToday = moment().utc().startOf('day');
  const [endTime] = useState(startOfToday.unix());
  const [startTime] = useState(startOfToday.subtract('1', 'year').unix());

  const loaderStyle = variant === 'large' ? theme.typography.h2 : theme.typography.h6;
  const typographyProps = variant === 'large' ? 'h2' : 'h6';

  const { data: intelData, isLoading: isIntelLoading } = useGetOiCtaIntelQuery(
    {
      client_id: client.id,
      start: startTime,
      end: endTime,
    },
    {
      onError: notify.queryError,
    }
  );

  if (isIntelLoading) {
    return renderIntelLoader();
  }

  function renderIntelLoader() {
    return (
      <Fragment>
        <Skeleton sx={loaderStyle} />
        <Skeleton sx={loaderStyle} />
      </Fragment>
    );
  }

  const { distinct_user_engagements = 0, intelligent_company_extrapolation = 0 } =
    intelData?.getIntelligence || {};

  const formattedUserCount = numeral(distinct_user_engagements).format();
  const formattedCompanyCount = numeral(intelligent_company_extrapolation).format();

  return (
    <Typography variant={typographyProps} align="center">
      <Trans
        i18nKey="insights:total_engagements_12_months"
        count={distinct_user_engagements}
        values={{
          people: formattedUserCount,
          companies: formattedCompanyCount,
        }}
      >
        <span style={styles.yellow}>{{ formattedUserCount }} person</span> from{' '}
        <span style={styles.yellow}>{{ formattedCompanyCount }} company</span> engaged with your
        team’s shares over the last <span style={styles.yellow}>12 months!</span>
      </Trans>
    </Typography>
  );
};

export default IntelligenceHeader;
