import React, { FC } from 'react';
import { Box, Typography, TypographyTypeMap } from '@mui/material';

interface Props {
  color?: TypographyTypeMap['props']['color'];
}

const Separator: FC<Props> = ({ color }) => {
  return (
    <Box marginLeft={0.5} marginRight={0.5}>
      <Typography color={color}>-</Typography>
    </Box>
  );
};

Separator.defaultProps = {
  color: 'textPrimary',
};

export default Separator;
