import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

import {
  Client,
  ClientProtectedFragment,
  useGetUserClientsQuery,
  UserClient,
  UserClientStatus,
} from 'api';
import { useNotify } from 'hooks';

interface Props {
  onChange: (client: ClientProtectedFragment) => void;
}

const WorkspacePicker: FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();
  const notify = useNotify();

  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ClientProtectedFragment>(null);
  const { data, isLoading } = useGetUserClientsQuery(
    {},
    {
      onError: notify.queryError,
      onSuccess: ({ getUserClients }) => {
        const activeClients = getWithStatus(getUserClients.items, [UserClientStatus.Active]).map(
          w => w.client
        );

        if (!activeClients.length) {
          onChange(null);
        }
      },
    }
  );

  const options = getWithStatus(data?.getUserClients?.items, [UserClientStatus.Active]).map(
    w => w.client
  );

  useEffect(() => {
    if (options.length) {
      setSelectedOption(options[0]);
      onChange(options[0]);
    }
  }, [options.length]);

  function toggleOpen() {
    setOpen(!open);
  }

  function getWithStatus(items: UserClient[], allowedStatuses: UserClientStatus[]) {
    if (!items) {
      return [];
    }

    return items.filter(userClient => allowedStatuses.includes(userClient.user_client_status));
  }

  function handleChange(event: ChangeEvent<HTMLDivElement>, nextValue: ClientProtectedFragment) {
    setSelectedOption(nextValue);
    onChange(nextValue);
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...params}
        variant="outlined"
        placeholder={t('components:selectWorkspace')}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <Fragment>
              {isLoading && <CircularProgress color="inherit" size={12} />}
              {params.InputProps.endAdornment}
            </Fragment>
          ),
        }}
      />
    );
  }

  return (
    <Autocomplete
      size="small"
      open={open}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      loading={isLoading}
      options={options}
      onChange={handleChange}
      getOptionLabel={option => option?.title}
      disableClearable
      value={selectedOption}
      renderOption={(option: Client) => (
        <Box paddingTop={1} paddingBottom={1}>
          <Typography variant="body2" noWrap>
            {option.title}
          </Typography>
          <Typography color="textSecondary" noWrap>
            {option.subdomain}
          </Typography>
        </Box>
      )}
      renderInput={renderInput}
    />
  );
};

export default WorkspacePicker;
