import React, { FC } from 'react';
import { Box } from '@mui/material';

const styles = {
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
} as const;

interface Props {
  disabled?: boolean;
}

const DisabledView: FC<Props> = ({ children, disabled }) => {
  return <Box sx={{ ...(disabled && styles.disabled) }}>{children}</Box>;
};

DisabledView.defaultProps = {
  disabled: false,
};

export default DisabledView;
