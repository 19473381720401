import React, { FC, Fragment } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';

import { LoaderComponents } from 'components';

const ListLoader: FC = () => {
  const { Block, Content, Image, List, Row } = LoaderComponents;

  return (
    <List rows={8}>
      {rowIndex => (
        <Fragment key={rowIndex}>
          <Row padding="16px 24px" spacing={2}>
            <Grid>
              <Block height={40} />
            </Grid>
            <Grid>
              <Image width={110} height={110} />
            </Grid>
            <Grid>
              <Content index={rowIndex} height={40} maxWidth={400} />
            </Grid>
          </Row>
        </Fragment>
      )}
    </List>
  );
};

export default ListLoader;
