import React, { FC } from 'react';
import { Table, TableCell, TableRow } from '@mui/material';

import { UserProfileFragment } from 'api';
import moment from 'moment';

interface IProps {
  user: UserProfileFragment;
}

const UserInfo: FC<IProps> = ({ user }) => {
  function row(key: string) {
    let value = user[key];
    if (key.endsWith('_at')) {
      value = moment.unix(value).format('YYYY-MM-DD hh:mm:ss');
    }
    if (typeof value == 'object') {
      value = JSON.stringify(value);
    }
    return (
      <TableRow key={key}>
        <TableCell>{key}</TableCell>
        <TableCell>{value}</TableCell>
      </TableRow>
    );
  }

  return <Table>{Object.keys(user).map(row)}</Table>;
};

export default UserInfo;
