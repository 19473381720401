import React, { FC } from 'react';
import { Button } from '@mui/material';
import { alpha } from '@mui/material/styles';

import theme from 'theme';

interface Props {
  variant: 'small' | 'large';
  text: string;
}

const FORM_LINK = 'https://everyonesocial.com/oi-abm-data-request/';

const styles = {
  button: {
    borderRadius: 32,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      color: alpha(theme.palette.common.white, 0.7),
      backgroundColor: alpha(theme.palette.error.main, 0.7),
    },
  },
} as const;

const InsightsCTAButton: FC<Props> = ({ variant, text }) => {
  const largeVariantStyle = {
    padding: '16px 32px',
    fontSize: theme.typography.pxToRem(24),
    ...styles.button,
  };

  const smallVariantStyle = {
    padding: '8px 16px',
    fontSize: theme.typography.pxToRem(16),
    ...styles.button,
  };

  const variantStyle = variant === 'large' ? largeVariantStyle : smallVariantStyle;

  return (
    <Button href={FORM_LINK} target="_blank" sx={variantStyle}>
      <strong>{text}</strong>
    </Button>
  );
};

export default InsightsCTAButton;
