import { InfiniteData, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  GetInboxItemsQuery,
  GetInboxItemsQueryVariables,
  InboxFragment,
  useGetInboxItemsQuery,
} from 'api';
import { INBOX_ITEM_CREATED } from 'api/subscriptions';
import { useCurrentUser } from 'hooks';
import { useSubscription } from 'app-react-query/hooks';

interface IUseOnInboxItemCreated {
  queryVariables: GetInboxItemsQueryVariables;
  onDataComplete?(data: InboxFragment): void;
}

type QueryData = InfiniteData<GetInboxItemsQuery>;

const useOnInboxItemCreated = ({ queryVariables, onDataComplete }: IUseOnInboxItemCreated) => {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();

  useSubscription<{ inboxItemCreated: InboxFragment }>({
    query: INBOX_ITEM_CREATED,
    variables: { user_id: currentUser.id },
    onData: ({ inboxItemCreated }) => {
      const queryKey = useGetInboxItemsQuery.getKey(queryVariables);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const [page] = draftState.pages;
          page.getInboxItems = {
            ...page.getInboxItems,
            inbox: [inboxItemCreated].concat(page.getInboxItems.inbox),
          };
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      onDataComplete?.(inboxItemCreated);
    },
  });
};

export default useOnInboxItemCreated;
