import React, { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { darken } from '@mui/material/styles';
import {
  Button,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';

import {
  SocialAccount,
  useBatchUpdateSocialConnectionMutation,
  useDeleteSocialConnectionMutation,
} from 'api';
import { Alert, SocialIcon } from 'components';
import { connectionPopup, getSocialAccountName } from 'helpers';
import { useDeleteSocialConnection, useUpdateSocialConnection } from 'updaters';
import { useReleases } from 'hooks';
import palette from 'theme/palette';
import theme from 'theme';

interface IProps {
  account: SocialAccount;
  connectedAccounts?: boolean;
}

const styles = {
  refreshButton: {
    marginRight: theme.spacing(),
    color: palette.red,
    '&:hover': {
      color: darken(palette.red, 0.1),
      backgroundColor: 'transparent',
    },
  },
  link: {
    margin: theme.spacing(1, 0, 0),
  },
  switch: {
    marginRight: 4,
  },
  disconnect: {
    padding: theme.spacing(1, 1.5),
  },
} as const;

const Connection: FC<IProps> = ({ account, connectedAccounts, children }) => {
  const { t } = useTranslation();
  const releases = useReleases();

  const hasSmartScheduler = releases.includes('SmartScheduler');
  const { id, provider, expires_soon, smart_schedule } = account;

  const deleteMutationOptions = useDeleteSocialConnection();
  const { mutate: deleteSocialConnection } =
    useDeleteSocialConnectionMutation(deleteMutationOptions);

  const updateMutationOptions = useUpdateSocialConnection();
  const { mutate: updateSocialConnection } =
    useBatchUpdateSocialConnectionMutation(updateMutationOptions);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [smartSchedule, setSmartSchedule] = useState(connectedAccounts && smart_schedule);

  useEffect(() => {
    setSmartSchedule(smart_schedule);
  }, [smart_schedule]);

  function handleDisconnect() {
    setDeleteOpen(false);
    deleteSocialConnection({ id });
  }

  function handleToggleSwitch() {
    setSmartSchedule(!smartSchedule);

    updateSocialConnection({
      data: {
        items: [{ token_id: id, smart_schedule: !smartSchedule }],
      },
    });
  }

  function renderDisconnect() {
    return (
      <Button variant="contained" color="secondary" onClick={() => setDeleteOpen(true)}>
        <Typography variant="body2">{t('common:disconnect')}</Typography>
      </Button>
    );
  }

  function renderRefresh() {
    return (
      <Button
        color="primary"
        variant="outlined"
        sx={styles.refreshButton}
        onClick={() => connectionPopup(provider)}
      >
        {t('common:refresh')}
      </Button>
    );
  }

  return (
    <Fragment>
      <ListItem disableGutters key={id}>
        <ListItemIcon>
          <SocialIcon provider={provider} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={<Typography variant="body2">{getSocialAccountName(account)}</Typography>}
        />
        <ListItemSecondaryAction>
          {expires_soon && renderRefresh()}
          {hasSmartScheduler && connectedAccounts && smart_schedule !== undefined && (
            <Switch checked={smartSchedule} onChange={handleToggleSwitch} sx={styles.switch} />
          )}
          {renderDisconnect()}
        </ListItemSecondaryAction>
      </ListItem>
      {children}
      <Divider sx={{ marginTop: 2 }} />

      <Alert
        open={deleteOpen}
        onConfirm={handleDisconnect}
        onCancel={() => setDeleteOpen(false)}
        message={t('user:disconnectAlert')}
        confirm={t('common:delete')}
      />
    </Fragment>
  );
};

Connection.defaultProps = {
  connectedAccounts: false,
};

export default Connection;
