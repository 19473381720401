import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Unstable_Grid2 as Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { useContent } from 'hooks';
import { Buttons, CustomSVG } from 'components';
import { ContentApproval, ReactionType, useAddReactionAnalyticsMutation } from 'api';
import * as paths from 'paths';

import { getContentApprovalFromContent } from '../helpers';

const styles = {
  scheduledButton: {
    borderRadius: 16,
    backgroundColor: 'primary.light',
    color: 'primary.main',
  },
  statusMessageText: {
    marginRight: 1,
  },
} as const;

const Share: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const content = useContent();
  const { content_id } = content;
  const approval = getContentApprovalFromContent(content);

  const { mutate: addReactionAnalytics } = useAddReactionAnalyticsMutation();

  function onClickButton() {
    navigate(`/content/${content_id}`);

    addReactionAnalytics({
      content_id,
      reaction_type: ReactionType.Share,
    });
  }

  function onClickShared() {
    navigate(paths.activity, { state: 'sharedButton' });
  }

  function renderStatusMessage() {
    if (approval === ContentApproval.Approved) {
      return null;
    }

    return <Typography sx={styles.statusMessageText}>{t(`content:${approval}`)}</Typography>;
  }

  function renderButton() {
    if (approval === ContentApproval.Submitted) {
      return null;
    }

    if (content.has_requesting_user_shared) {
      return (
        <Buttons.SubAction name={t('common:shared')} variant="outlined" size="medium">
          <MenuList>
            <MenuItem onClick={onClickShared}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ variant: 'subtitle2' }}
                primary={t('content:viewPerformance')}
              />
            </MenuItem>
            <MenuItem onClick={onClickButton}>
              <ListItemIcon>
                <ForwardIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ variant: 'subtitle2' }}
                primary={t('content:shareAgain')}
              />
            </MenuItem>
          </MenuList>
        </Buttons.SubAction>
      );
    }

    return (
      <Button
        data-testid="post-actions-share"
        variant="contained"
        color="primary"
        onClick={onClickButton}
        endIcon={<CustomSVG name="share" size={20} />}
        disabled={approval !== ContentApproval.Approved}
      >
        {t('content:share')}
      </Button>
    );
  }

  return (
    <Grid container alignItems="center">
      <Grid>{renderStatusMessage()}</Grid>
      <Grid>{renderButton()}</Grid>
    </Grid>
  );
};

export default Share;
