import React, { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useGetReleaseToggleDetailsQuery } from 'api';
import { Paper, Typography, Box } from '@mui/material';

import { useNotify } from 'hooks';
import { Layouts } from 'components';
import { ReleaseToggleForm } from '../components/Releases';
import Standard from 'components/Loaders/Standard';

const ReleaseToggleDetails: FC = () => {
  const { release_toggle_id } = useParams<{ release_toggle_id: string }>();
  const notify = useNotify();

  const { isLoading, data } = useGetReleaseToggleDetailsQuery(
    {
      release_toggle_id: release_toggle_id,
    },
    {
      onError: notify.queryError,
    }
  );

  const releaseToggle = data?.getReleaseToggleDetails;

  function renderMain() {
    return (
      <Paper>
        {isLoading && <Standard />}
        {releaseToggle && (
          <Fragment>
            <Box m={4}>
              <Typography variant="h3">{releaseToggle.description}</Typography>
            </Box>

            <ReleaseToggleForm
              clients={releaseToggle.clients}
              release_toggle_id={releaseToggle.release_id}
            />
          </Fragment>
        )}
      </Paper>
    );
  }

  return <Layouts.App1Column desktopSize={12} main={renderMain()} />;
};

export default ReleaseToggleDetails;
