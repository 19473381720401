import React, { FC, ReactNode } from 'react';

import { DailyLeaderboardEventStat, useDailyLeaderboardStatsQuery } from 'api';
import { LoaderTypes } from 'types/app';
import { QueryManager } from 'components';
import { useProtectedClient } from 'hooks';

interface IProps {
  loader?: LoaderTypes;
  children: (data: DailyLeaderboardEventStat) => ReactNode;
  endDate: string;
  startDate: string;
  leaderboardConfigId: string;
}

const DailyLeaderboardStats: FC<IProps> = ({
  children,
  loader,
  startDate,
  endDate,
  leaderboardConfigId,
}) => {
  const client = useProtectedClient();
  const queryResult = useDailyLeaderboardStatsQuery({
    client_id: client.id,
    start_date: startDate,
    end_date: endDate,
    leaderboard_config_id: leaderboardConfigId,
  });

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.dailyLeaderboardStats) {
          return null;
        }
        return children(data.dailyLeaderboardStats);
      }}
    </QueryManager>
  );
};

export default DailyLeaderboardStats;
