import React, { FC, Fragment } from 'react';
import { Card, CardContent, Divider, Unstable_Grid2 as Grid } from '@mui/material';

import { useContent, useGlobalClient } from 'hooks';

import { getEmbed, hasCreator } from '../helpers';
import { Provider } from '../widgets';
import { MetaPublic, UserPublic } from '../partials';
import theme from 'theme';

const styles = {
  card: {
    position: 'relative',
    marginBottom: 3,
    [theme.breakpoints.down('xl')]: {
      marginBottom: 2,
    },
  },

  cardBody: {
    paddingTop: 0,
    paddingBottom: 12,
  },

  cardHeader: {
    paddingBottom: '0.5',
    paddingTop: 1,
  },
};

const EmbedPublic: FC = () => {
  const client = useGlobalClient();
  const content = useContent();

  const hasPostCreator = hasCreator(content);
  const component = getEmbed(content);
  const isDeletedUser = content?.user?.full_name === 'Deleted User';

  return (
    <Card sx={styles.card}>
      {hasPostCreator && (
        <Fragment>
          {client.public_post_details && <UserPublic isDeletedUser={isDeletedUser} />}
          <Divider />
        </Fragment>
      )}
      <CardContent sx={styles.cardHeader}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid>
            <Provider content={content} />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>{component}</CardContent>
      <MetaPublic />
    </Card>
  );
};

export default EmbedPublic;
