import React, { FC, ReactNode } from 'react';
import { Element as SlateElement } from 'slate';
import { useSelected } from 'slate-react';
import theme from 'theme';

interface ElementProps {
  children: ReactNode;
  element: SlateElement;
  attributes: Record<string, unknown>;
}

const styles = {
  element: {
    marginTop: 0,
  },
  link: {
    color: 'primary.main',
    textDecoration: 'none',
  },
  blockquote: {
    borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.grey[50]}`,
    paddingLeft: theme.spacing(),
  },
  shadow: {
    boxShadow: 'rgb(221, 221, 221) 0px 0px 0px 3px',
  },
} as const;

const Element: FC<ElementProps> = ({ attributes, children, element }) => {
  const selected = useSelected();
  const shadowStyle = selected ? styles.shadow : {};

  const InlineChromiumBugfix = () => (
    <span contentEditable={false} style={{ fontSize: 0 }}>
      {String.fromCodePoint(160) /* Non-breaking space */}
    </span>
  );

  switch (element.type) {
    case 'paragraph':
      return (
        <p {...attributes} style={styles.element}>
          {children}
        </p>
      );
    case 'bulleted-list':
      return (
        <ul {...attributes} style={styles.element}>
          {children}
        </ul>
      );
    case 'numbered-list':
      return (
        <ol {...attributes} style={styles.element}>
          {children}
        </ol>
      );
    case 'list-item':
      return (
        <li {...attributes} style={styles.element}>
          {children}
        </li>
      );
    case 'title':
      return (
        <h1 {...attributes} style={styles.element}>
          {children}
        </h1>
      );
    case 'subtitle':
      return (
        <h2 {...attributes} style={styles.element}>
          {children}
        </h2>
      );
    case 'block-quote':
      return (
        <blockquote {...attributes} style={{ ...styles.blockquote, ...styles.element }}>
          {children}
        </blockquote>
      );
    case 'link':
      return (
        <a
          {...attributes}
          href={element.url}
          style={{ ...shadowStyle, ...styles.link, ...styles.element }}
        >
          <InlineChromiumBugfix />
          {children}
          <InlineChromiumBugfix />
        </a>
      );
    default:
      throw new Error('SlateEditor encountered an unhandled element type');
  }
};

export default Element;
