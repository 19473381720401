import React, { FC, Fragment, ReactNode } from 'react';
import { noop } from 'lodash';
import { Box, Unstable_Grid2 as Grid, Link, MenuItem, Typography } from '@mui/material';
import moment from 'moment';

import { Inbox, useMarkInboxItemAsReadMutation } from 'api';
import { getSubdomain } from 'helpers';
import { generateLink } from 'Links';
import theme from 'theme';

import { useMarkInboxItemAsRead } from '../updaters';

const styles = {
  root: {
    position: 'relative',
    height: 'auto',
    whiteSpace: 'normal',
    padding: theme.spacing(1.5, 3),
    cursor: 'default',
    '&:hover, &:focus': {
      outline: 0,
    },

    '& a:hover': {
      textDecoration: 'none',
    },
  },
  regular: {
    fontSize: '13px',
    lineHeight: '16px',
  },
  large: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  unread: {
    position: 'absolute',
    top: 18,
    width: theme.spacing(1),
    height: theme.spacing(1),
    backgroundColor: theme.custom.palette.red,
    borderRadius: '50%',
  },
  regularUnread: {
    left: 10,
    width: theme.spacing(1),
    height: theme.spacing(1),
  },
  largeUnread: {
    left: 0,
    width: 12,
    height: 12,
  },
};

interface Props {
  size: 'regular' | 'large';
  item: Inbox;
  onClick?: () => void;
}

interface IChildren {
  children: ReactNode;
}

const Notification: FC<Props> = ({ item, size, onClick }) => {
  const mutationOptions = useMarkInboxItemAsRead();
  const { created_at, item_read, message, url } = item;
  const { mutate } = useMarkInboxItemAsReadMutation(mutationOptions);

  function getWrapper() {
    if (!url) {
      return ({ children }: IChildren) => <Fragment>{children}</Fragment>;
    }

    const { hostname, pathname } = new URL(url);

    return ({ children }: IChildren) => {
      return hostname === getSubdomain() ? (
        <Link component={generateLink(pathname)} underline="hover">
          {children}
        </Link>
      ) : (
        <Link href={url} target="_blank" rel="noopener noreferrer" underline="hover">
          {children}
        </Link>
      );
    };
  }

  function handleMarkAsRead() {
    onClick();
    mutate({ id: item.id });
  }

  const Wrapper = getWrapper();

  return (
    <MenuItem sx={styles.root} onClick={handleMarkAsRead}>
      <Wrapper>
        <Grid container>
          <Grid>{!item_read && <Box sx={{ ...styles.unread, ...styles[`${size}Unread`] }} />}</Grid>

          <Grid xs={12}>
            <Typography
              sx={{ color: theme.palette.common.black, ...styles[`${size}`] }}
              color="textPrimary"
              variant="body2"
            >
              {message}
            </Typography>

            <Typography
              sx={{ color: theme.custom.palette.grey[400], ...styles[`${size}`] }}
              variant="subtitle2"
              color="textSecondary"
            >
              {moment.unix(created_at).fromNow()}
            </Typography>
          </Grid>
        </Grid>
      </Wrapper>
    </MenuItem>
  );
};

Notification.defaultProps = {
  onClick: noop,
};

export default Notification;
