import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import palette from '../../../../theme/palette';

interface IProps {
  onClick: () => void;
}

const ClearFiltersButton: FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      startIcon={<RefreshIcon />}
      variant="outlined"
      onClick={onClick}
      size="large"
      sx={{ border: `1px solid ${palette.grey[400]}`, color: palette.black }}
    >
      {t('analytics:clearFilters')}
    </Button>
  );
};

export default ClearFiltersButton;
