import { CSSProperties } from 'react';
import { TableCellProps } from '@mui/material/TableCell';

import {
  GroupsSortFields,
  PostsSortFields,
  SharesSortFields,
  SortDirections,
  UsersSortFields,
} from 'api';

export type SortTypes = GroupsSortFields | UsersSortFields | PostsSortFields | SharesSortFields;
export type MixedHeadCell = ISortableHeadCell | IUnsortableHeadCell;

interface IHeadCell {
  align: TableCellProps['align'];
  label: string;
  width: CSSProperties['width'];
}

export interface ISortableHeadCell extends IHeadCell {
  id: SortTypes;
  initialSort: SortDirections;
}

export interface IUnsortableHeadCell extends IHeadCell {
  id: string;
}

export enum ROIChartType {
  Impressions = 'Impressions',
  Reach = 'Reach',
  Clicks = 'Clicks',
  Engagements = 'Engagements',
}
