import React, { FC } from 'react';
import moment from 'moment';
import { Link } from '@mui/material';

import { Content } from 'api';
import { generateLink } from 'Links';

const styles = {
  root: {
    display: 'inline-block',
  },
} as const;

interface Props {
  contentId: Content['content_id'];
  publishAt: Content['publish_at'];
}
const Date: FC<Props> = ({ contentId, publishAt }) => (
  <Link component={generateLink(`/content/${contentId}`)} sx={styles.root}>
    {moment.unix(publishAt).fromNow()}
  </Link>
);

export default Date;
