import React, { FC, memo } from 'react';
import { TableCell, TableSortLabel } from '@mui/material';

import { DataTableColumn, SortAction, SortDirections, SortParam } from './types';
import useStyles from './styles';

interface Props {
  column: DataTableColumn<any>;
  sortBy?: SortParam;
  onSort?: SortAction;
}

const HeaderCellMemoized: FC<Props> = memo(function HeaderCell(props: Props) {
  const styles = useStyles;
  const { column, onSort, sortBy } = props;
  const { width, headerCell } = column;
  const { sort, render, padding } = headerCell;
  const { direction } = sortBy;

  const isAscending = direction === SortDirections.Asc;
  const isSortable = sort?.field;

  const cellStyles = {
    ...(isSortable ? styles.sortableCell : {}),
    ...(padding === 'none' ? styles.paddingNone : {}),
    ...(padding === 'checkbox' ? styles.paddingCheckbox : {}),
    ...(padding === 'dense' ? styles.paddingDense : {}),
    ...(padding === 'normal' || !padding ? styles.paddingNormal : {}),
    borderBottom: 'none',
  };

  function onClickCellSort() {
    const nextDirection = isAscending ? SortDirections.Desc : SortDirections.Asc;
    onSort([{ direction: nextDirection, field: sort.field }]);
  }

  if (!isSortable) {
    return (
      <TableCell
        style={{ width }}
        sx={cellStyles}
        align={padding === 'checkbox' ? 'center' : 'left'}
      >
        {render}
      </TableCell>
    );
  }

  return (
    <TableCell
      style={{ width }}
      onClick={!sort ? null : onClickCellSort}
      sx={cellStyles}
      align={padding === 'checkbox' ? 'center' : 'left'}
    >
      <TableSortLabel
        active={!!sort && sort.field === sortBy?.field}
        direction={isAscending ? 'asc' : 'desc'}
        onClick={onClickCellSort}
      >
        {render}
      </TableSortLabel>
    </TableCell>
  );
});

export default HeaderCellMemoized;
