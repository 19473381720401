import { useEffect } from 'react';
import { Observable } from 'zen-observable-ts';
import { DocumentNode } from 'graphql';
import { OperationDefinitionNode } from 'graphql/language';
import AmplifyGraphQLAPI from '@aws-amplify/api-graphql';

interface IUseSubscription<TData> {
  query: DocumentNode;
  variables: Record<string, unknown>;
  onError?(error: Error): void;
  onData?(data: TData): void;
}

type ObservableType<TData> = Observable<{ value: { data: TData } }>;

function useSubscription<TData>({ query, variables, onError, onData }: IUseSubscription<TData>) {
  const [operationDef = {}] = query.definitions.filter(def => def.kind === 'OperationDefinition');
  const { operation: operationType } = operationDef as OperationDefinitionNode;

  if (operationType !== 'subscription') {
    throw new Error('useSubscription only supports GraphQL subscriptions');
  }

  useEffect(() => {
    const observable = AmplifyGraphQLAPI.graphql({ query, variables }) as ObservableType<TData>;
    const subscription = observable.subscribe({
      next: payload => onData(payload.value.data),
      error: onError,
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);
}

export default useSubscription;
