import React, { ChangeEvent, FC } from 'react';
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';

import { ValidatedSwitch } from 'components';

interface IProps {
  value: string;
  title: string;
  description: string;
  checkedFn?: (value: unknown) => boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: boolean) => void;
}

const Setting: FC<IProps> = ({ value, title, description, checkedFn, onChange }) => (
  <Grid container alignItems="center">
    <Grid xs={11}>
      <Typography variant="body2" display="inline">
        {title}
      </Typography>
      <Typography color="textSecondary" display="inline">
        {' '}
        - {description}
      </Typography>
    </Grid>
    <Grid>
      <ValidatedSwitch name={value} color="primary" checkedFn={checkedFn} onChange={onChange} />
    </Grid>
  </Grid>
);

export default Setting;
