import React, { FC, Fragment } from 'react';
import { Unstable_Grid2 as Grid, TableRow, TableCell } from '@mui/material';
import { times } from 'lodash';

import { LoaderComponents } from 'components';

interface IProps {
  rows: number;
  sentinelRef: (node: Element) => void;
}

const Loader: FC<IProps> = ({ rows, sentinelRef }) => {
  const { Content, Image } = LoaderComponents;

  return (
    <Fragment>
      {times(rows, rowIndex => (
        <TableRow ref={sentinelRef} key={rowIndex}>
          <TableCell>
            <Image height={32} width={32} shape="circular" />
          </TableCell>
          <TableCell colSpan={5}>
            <Grid container spacing={2} alignItems="center">
              <Grid>
                <Content index={rowIndex} height={25} maxWidth={400} />
              </Grid>
              <Grid>
                <Content index={rowIndex} height={25} maxWidth={400} />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
};

export default Loader;
