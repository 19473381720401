import { array, bool, lazy, mixed, number, object, string } from 'yup';
import i18n from 'config/i18n';

import {
  BlockedTerm,
  ChannelRepostStatus,
  Network,
  PostType,
  UserFeedFollowGroupFragment,
} from 'api';
import { ExternalShareOption, IPostUIState, PostOption } from './types';
import { blockListSchema } from '../sharing/validations';

interface IOptions {
  uiState: IPostUIState;
}

const required: string = i18n.t('common:required');

/**
 * the ts-ignores are due to Yup types not defining the
 * options object which is passed to each schema
 */
const PostSchema = object({
  group_ids: array().of(string()).compact().required(),
  content_source_id: string().required(),
  is_engageable: bool().required(),
  is_shareable: bool().required(),
  is_important: bool().required(),
  is_pinned: bool().required(),
  lock_commentary: bool().required(),
  is_important_message: string(),
  primary_group_id: string().nullable().required(),
  content_tags: array().of(
    object({
      title: string().required(),
      tag_id: string().required(),
    })
  ),

  content_object: object({
    post_type: mixed().oneOf([PostType.Manual, PostType.Url, PostType.Quickshare]),
    // @ts-ignore
    body: lazy((value, { uiState }: IOptions) => {
      return (
        uiState?.option === PostOption.Text ? string().required() : string().notRequired()
      ).test('blocklist', i18n.t('posting:blocklistWarning'), (value, testContext) => {
        const { body_blocklist } = testContext.parent;
        if (!body_blocklist || !body_blocklist.length || !value) {
          return true;
        }
        const hasBlockedTerms = body_blocklist.some(({ term }: BlockedTerm) =>
          value.includes(term)
        );
        return !hasBlockedTerms;
      });
    }),
    body_blocklist: blockListSchema,

    // @ts-ignore
    url: lazy((value, { uiState }: IOptions) => {
      return uiState?.option === PostOption.Link
        ? string().url().required()
        : string().url().notRequired();
    }),
    // @ts-ignore
    title: lazy((value, { uiState }: IOptions) => {
      return (
        uiState?.option === PostOption.Text ? string().required() : string().notRequired()
      ).test('blocklist', i18n.t('posting:blocklistTitleWarning'), (value, testContext) => {
        const { title_blocklist } = testContext.parent;
        if (!title_blocklist || !title_blocklist.length || !value) {
          return true;
        }
        const hasBlockedTerms = title_blocklist.some(({ term }: BlockedTerm) =>
          value.includes(term)
        );
        return !hasBlockedTerms;
      });
    }),
    title_blocklist: blockListSchema,
    photos: array().test('custom-test', '', function (value) {
      const { media } = this.parent;
      // @ts-ignore
      const { uiState } = this.options;

      const isSelectedPhoto = !!value?.length && !!uiState?.selectedPhotoIndexes.length;

      if (uiState?.option === PostOption.ImageVideo) {
        return isSelectedPhoto || !!media;
      }
      return isSelectedPhoto || !!media || uiState?.option !== PostOption.ImageVideo;
    }),
    media: object({
      video: object({
        key: string().required(),
        bucket: string().required(),
        region: string().required(),
      }).required(),
    })
      .nullable(true)
      .test('custom-test', '', function (value) {
        const { photos } = this.parent;
        // @ts-ignore
        const { uiState } = this.options;
        if (uiState?.option === PostOption.ImageVideo) {
          return !!value || !!photos;
        }
        return !!value || !!photos.length || uiState?.option !== PostOption.ImageVideo;
      }),
    url_metadata_override: object({
      title: string(),
      description: string(),
    }),
  }),
  commentary: array().of(
    object({
      network: mixed().oneOf([
        Network.Twitter,
        Network.Linkedin,
        Network.Facebook,
        Network.Instagram,
      ]),
      blockList: blockListSchema,
      commentary: string().test(
        'blockList',
        i18n.t('sharing:blocklistWarning'),
        (value, testContext) => {
          const { blockList } = testContext.parent;
          if (!blockList || !blockList.length || !value) {
            return true;
          }
          const hasBlockedTerms = blockList.some(({ term }: BlockedTerm) => value.includes(term));
          return !hasBlockedTerms;
        }
      ),
    })
  ),
  disclosures: array().of(
    object({
      network: mixed().oneOf([
        Network.Twitter,
        Network.Linkedin,
        Network.Facebook,
        Network.Instagram,
      ]),
      disclosure_id: string(),
    })
  ),
  schedule: object({
    publish: number().nullable(true),
    expire: number().nullable(true),
  }),
  // @ts-ignore
  ms_teams_channels: lazy((value, { uiState }: IOptions) => {
    return uiState?.msteams
      ? array().of(
          object({
            channel_id: string().required(),
            channel_name: string(),
            status: string().oneOf([ChannelRepostStatus.Pending, ChannelRepostStatus.Published]),
            team_id: string().required(),
          })
        )
      : array().nullable(true);
  }),
  // @ts-ignore
  slack_channels: lazy((value, { uiState }: IOptions) => {
    return uiState?.slack
      ? array().of(
          object({
            channel_id: string().required(),
            channel_name: string(),
            status: string().oneOf([ChannelRepostStatus.Pending, ChannelRepostStatus.Published]),
          })
        )
      : array().nullable(true);
  }),
});

const ExternalSharePostSchema = object({
  group_ids: array().defined(),
  content_source_id: string().required(),
  content_object: object({
    post_type: mixed().oneOf([PostType.Quickshare]),
    // @ts-ignore
    url: lazy((value, { uiState }: IOptions) => {
      return uiState?.shareOption === ExternalShareOption.LinkShare
        ? string().url().required()
        : string().url().notRequired();
    }),
    photos: array().test('custom-test', '', function (value) {
      const { media } = this.parent;
      // @ts-ignore
      const { uiState } = this.options;
      const isSelectedPhoto = !!value.length && !!uiState?.selectedPhotoIndexes.length;

      if (uiState?.shareOption === ExternalShareOption.ImageShare) {
        return isSelectedPhoto || !!media;
      }
      return isSelectedPhoto || !!media || uiState?.shareOption !== ExternalShareOption.ImageShare;
    }),
    media: object({
      video: object({
        key: string().required(),
        bucket: string().required(),
        region: string().required(),
      }).required(),
    })
      .nullable(true)
      .test('custom-test', '', function (value) {
        const { photos } = this.parent;
        // @ts-ignore
        const { uiState } = this.options;
        return !!value || !!photos.length || uiState?.option !== ExternalShareOption.ImageShare;
      }),
    url_metadata_override: object({
      title: string(),
      description: string(),
    }),
  }),
});

const CreateGroupDialogSchema = (list: UserFeedFollowGroupFragment[]) => {
  const groupTitles = list.map(item => item.title);
  return object().shape({
    title: string()
      .required(required)
      .notOneOf(groupTitles, i18n.t('groups:groupExistErrorMessage')),
  });
};

export { PostSchema, ExternalSharePostSchema, CreateGroupDialogSchema };
