import React, { FC, Fragment } from 'react';
import { Divider } from '@mui/material';

import LinkGroup from './LinkGroup';
import MarkGroup from './MarkGroup';
import BlockGroup from './BlockGroup';
import EmojiGroup from './EmojiGroup';

const styles = {
  divider: {
    marginLeft: -'2',
    marginRight: -'2',
    backgroundColor: 'grey.400',
  },
} as const;

const Toolbar: FC = () => {
  return (
    <Fragment>
      <Divider sx={styles.divider} />
      <MarkGroup />
      <BlockGroup />
      <LinkGroup />
      <EmojiGroup />
    </Fragment>
  );
};

export default Toolbar;
