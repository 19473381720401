import React, { FC, Fragment, MouseEvent, useEffect, useState } from 'react';
import { Box, IconButton, Menu } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import { InboxFragment } from 'api';
import { useOnInboxItemCreated } from 'features/notifications/subscriptions';
import { useNotify } from 'hooks';
import { Notification, NotificationsPopup } from 'features/notifications';
import { useInboxItems } from 'features/notifications/hooks';

import { NOTIFICATION_POPUP_LIMIT } from 'app-constants';
import theme from 'theme';

const styles = {
  unread: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: theme.spacing(1),
    height: theme.spacing(1),
    backgroundColor: theme.custom.palette.red,
    borderRadius: '50%',
  },
} as const;

interface IProps {
  onLoaded(): void;
}

const Notifications: FC<IProps> = ({ onLoaded }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const notify = useNotify();
  const variables = { limit: NOTIFICATION_POPUP_LIMIT };

  const { results } = useInboxItems(variables);

  useOnInboxItemCreated({
    queryVariables: variables,
    onDataComplete(inboxItemCreated: InboxFragment) {
      renderToast(inboxItemCreated);
    },
  });

  useEffect(() => {
    onLoaded();
  }, []);

  function hasUnread() {
    return results.some(item => !item.item_read);
  }

  function handleMenuOpen(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function renderToast(notification: InboxFragment) {
    notify.info({
      duration: 6000,
      component: <Notification size="regular" item={notification} />,
    });
  }

  return (
    <Fragment>
      <IconButton onClick={handleMenuOpen} size="large">
        {hasUnread() && <Box sx={styles.unread} />}
        <NotificationsNoneIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleMenuClose}>
        <NotificationsPopup inbox={results} onClick={handleMenuClose} />
      </Menu>
    </Fragment>
  );
};

export default Notifications;
