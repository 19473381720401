import React, { FC } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface IProps {
  visible: boolean;
  onToggle: () => void;
}

const VisibilityAdornment: FC<IProps> = ({ visible, onToggle }) => (
  <InputAdornment position="end">
    <IconButton onClick={onToggle} size="large">
      {visible ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>
);

export default VisibilityAdornment;
