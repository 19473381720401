import { find, random, size } from 'lodash';

import {
  CreateGroupInput,
  GroupColor,
  GroupColorMap,
  GroupContentApprovalType,
  GroupIcon,
  GroupIconColor,
  GroupModeratorFragment,
  UserFeedFollowFragment,
  UserFeedFollowGroupFragment,
} from 'api';

export const moderatorIsOwner = (moderator: GroupModeratorFragment) =>
  moderator.user_id === moderator.added_by_user_id;

export const getGroupHexColor = (groupColors: GroupColorMap[], color: GroupColor) => {
  return find(groupColors, ['group_color', color]).hex;
};

export const getCreateGroupInitialValues = (): CreateGroupInput => {
  const randomNumberColor = random(0, size(GroupColor) - 1);
  const randomNumberIconColor = random(0, size(GroupIconColor) - 1);
  const randomNumberIcon = random(0, size(GroupIcon) - 1);

  return {
    title: '',
    description: '',
    content_approval_type: GroupContentApprovalType.Auto,
    allow_user_posts: true,
    read_only: false,
    color: Object.values(GroupColor)[randomNumberColor],
    icon_color: Object.values(GroupIconColor)[randomNumberIconColor],
    icon: Object.values(GroupIcon)[randomNumberIcon],
    location_id: null,
    department_id: null,
    is_private: false,
  };
};

export const isRequiredGroup = (
  group: UserFeedFollowGroupFragment,
  required: UserFeedFollowFragment[]
) => {
  for (const item of required) {
    if (item.group && item.group.id == group.id && item.required) {
      return true;
    }
  }
  return false;
};
