import { OperationDefinitionNode, parse } from 'graphql/language';

const getOperationType = (query: string) => {
  const parsedQuery = parse(query);
  const primaryDefinition = parsedQuery.definitions.filter(
    definition => definition.kind === 'OperationDefinition'
  ) as OperationDefinitionNode[];
  return primaryDefinition?.[0]?.operation || '';
};

export default getOperationType;
