import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArrayRenderProps } from 'formik';
import { Box, Button, Typography } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import uploadFile from 'app-react-query/utilities/upload-file';

import { S3ObjectInput } from 'api';
import { AspectRatios } from 'types/app';
import { Loaders, UploadDialog, Uploader, UploadInput } from 'components';
import { useNotify, useReleases } from 'hooks';
import { THUMBNAIL_SIZE } from 'app-constants';

import { usePostContext } from 'features/posting/PostContext';
import { ACTIVE_THUMBNAIL_COUNT } from '../../constants';

interface Props {
  arrayHelpers: FieldArrayRenderProps;
}

const styles = {
  uploadButton: {
    width: THUMBNAIL_SIZE,
    height: THUMBNAIL_SIZE,

    '&:hover': {
      background: 'grey[200]',
    },
  },
} as const;

const PhotoUpload: FC<Props> = ({ arrayHelpers }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const { state, dispatch } = usePostContext();
  const releases = useReleases();
  const hasVariations = releases.includes('shareVariations');

  const [isLoading, setIsLoading] = useState(false);

  const { selectedPhotoIndexes } = state;
  const { insert, push } = arrayHelpers;

  async function handleSavePhoto(s3Object: S3ObjectInput) {
    setIsLoading(true);
    try {
      await uploadFile(s3Object);
      !hasVariations && dispatch({ type: 'clearPhotoIndexes' });
      if (selectedPhotoIndexes.length >= ACTIVE_THUMBNAIL_COUNT) {
        push(s3Object);
      } else {
        insert(selectedPhotoIndexes.length, s3Object);
        dispatch({ type: 'updatePhotoIndexes', value: selectedPhotoIndexes.length });
      }

      setIsLoading(false);
    } catch (e) {
      notify.mutationError();
    }
  }

  return (
    <Uploader onSave={handleSavePhoto}>
      {uploaderProps => (
        <Fragment>
          <UploadInput onUpload={uploaderProps.onUpload}>
            <Button sx={styles.uploadButton}>
              {isLoading ? (
                <Loaders.Button size={24} />
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <PublishIcon />
                  <Typography variant="caption">{t('posting:upload')}</Typography>
                </Box>
              )}
            </Button>
          </UploadInput>
          <UploadDialog
            aspectRatio={AspectRatios.none}
            i18nKeyTitle="posting:photo"
            i18nKeyReplace="components:replaceImage"
            {...uploaderProps}
          />
        </Fragment>
      )}
    </Uploader>
  );
};

export default PhotoUpload;
