import React, { CSSProperties, FC, ReactNode } from 'react';
import { times } from 'lodash';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';

import { LoaderComponents } from 'components';

import {
  Data,
  DataTableActions,
  DataTableColumn,
  Datum,
  Pagination,
  SortAction,
  SortParam,
} from './types';
import HeaderCell from './HeaderCell';
import BodyRow from './BodyRow';
import useStyles from './styles';

interface Props {
  data: Data;
  columns: DataTableColumn<any>[];
  actions: DataTableActions<any>;
  pagination: Pagination;
  rowSelection: ReactNode;
  sortBy?: SortParam;
  onSort?: SortAction;
  sx?: CSSProperties;
}

const DataTable: FC<Props> = props => {
  const { pagination, columns, actions, data, rowSelection, sortBy, onSort, sx } = props;
  const { sentinelRef, isExhausted, isInitialLoad } = pagination;
  const minWidth = columns.reduce((acc, column) => acc + column.width, 0);
  const styles = useStyles;

  function renderLoader(ref: Pagination['sentinelRef'], rows: number) {
    return times(rows, rowIndex => (
      <TableRow ref={ref} key={rowIndex}>
        <TableCell colSpan={columns.length}>
          <LoaderComponents.Content index={rowIndex} height={20} maxWidth={400} />
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <TableContainer component={Paper} sx={styles.root}>
      <Table style={{ minWidth }} sx={styles.table}>
        <TableHead>
          <TableRow sx={styles.tableHeaderRow}>
            {columns.map((column, idx) => (
              <HeaderCell column={column} key={idx} onSort={onSort} sortBy={sortBy} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isInitialLoad && renderLoader(null, 8)}
          {rowSelection}
          {data.map((datum: Datum, index: number) => (
            <BodyRow
              key={index}
              index={index}
              columns={columns}
              actions={actions}
              datum={datum}
              sx={sx}
            />
          ))}
          {!isExhausted && renderLoader(sentinelRef, 1)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DataTable.defaultProps = {
  sortBy: {} as SortParam,
  onSort: () => undefined,
};

export default DataTable;
