import { ContentApproval, ContentFragment } from 'api';
import { useFollowed, useProtectedClient } from 'hooks';

import { foundReadOnlyGroup } from '../../posting/helpers';
import { isShareable } from '../helpers';

export default (content: ContentFragment) => {
  const { groupRules } = useFollowed();
  const { allow_sharing } = useProtectedClient();
  const { group_id_approvals, primary_group_id } = content;

  let isContentShareable = true;
  let groupIds = [];
  const targetGroup = group_id_approvals?.find(
    approvals => approvals?.group_id === primary_group_id
  );
  const isSubmitted = targetGroup?.approval === ContentApproval.Submitted;

  if (!content?.group_ids) {
    if (content?.groups) {
      groupIds = content.groups.map(group => group.id);
    } else {
      return false;
    }
  } else {
    groupIds = content.group_ids;
  }

  if ('is_shareable' in content) {
    isContentShareable = isShareable(content);
  }

  let readOnlyGroup;
  if (content.groups?.length) {
    readOnlyGroup = content.groups.find(g => g.read_only);
  } else {
    readOnlyGroup = foundReadOnlyGroup(groupIds, groupRules);
  }

  return isContentShareable && !readOnlyGroup && allow_sharing && !isSubmitted;
};
