import React, { FC, Fragment } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { useContent, useGlobalClient, useSharePhoto } from 'hooks';

import { getPhoto, getVideo, hasCreator, hasPhoto, hasVideo } from '../helpers';
import { MetaPublic, UserPublic } from '../partials';
import { ExternalLink, Photo, Provider, Video } from '../widgets';
import SharingThumbnails from 'features/sharing/components/Thumbnails';
import theme from 'theme';

const styles = {
  card: {
    marginBottom: 3,
    padding: theme.spacing(),
    [theme.breakpoints.down('xl')]: {
      marginBottom: 0,
      marginTop: 0,
    },
  },
  cardHeader: {
    paddingTop: 0.5,
  },
  break: theme.mixins.breakWord,
} as const;

const ManualPublic: FC = () => {
  const client = useGlobalClient();
  const content = useContent();
  const { selectedPhotoIndex } = useSharePhoto();

  const { content_object } = content;
  const { photos, title } = content_object;
  const hasPostCreator = hasCreator(content);
  const isDeletedUser = content?.user?.full_name === 'Deleted User';

  function getContent() {
    if (hasVideo(content)) {
      const video = getVideo(content);
      return <Video video={video} autoPlay />;
    }

    if (hasPhoto(content)) {
      const photo = getPhoto(content, selectedPhotoIndex);
      return (
        <Fragment>
          <Photo disableViewer photo={photo} />
          <Box paddingX={2} paddingTop={1}>
            <Grid container spacing={1}>
              <SharingThumbnails images={photos} />
            </Grid>
          </Box>
        </Fragment>
      );
    }

    return null;
  }

  function renderCardHeader() {
    const provider = (
      <ExternalLink>
        <Provider content={content} />
      </ExternalLink>
    );

    return <CardHeader sx={styles.cardHeader} title={provider} />;
  }

  return (
    <Card sx={styles.card}>
      {hasPostCreator && (
        <Fragment>
          {client.public_post_details && <UserPublic isDeletedUser={isDeletedUser} />}
          <Divider />
        </Fragment>
      )}
      {renderCardHeader()}

      <Box marginBottom={title ?? 1}>{getContent()}</Box>
      <MetaPublic />

      {title && (
        <CardContent>
          <Typography variant="h4" sx={styles.break}>
            {title}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
};

export default ManualPublic;
