import React, { FC, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Unstable_Grid2 as Grid } from '@mui/material';

import BareAppBar from './BareAppBar';
import { useCapabilities, useGlobalClient } from 'hooks';
import theme from 'theme';

const styles = {
  info: {
    maxWidth: 480,
    [theme.breakpoints.up('md')]: {
      marginRight: 16,
    },
  },
  form: {
    maxWidth: 480,
  },
} as const;

interface Props {
  form: ReactNode;
  clientInfo: ReactNode;
}

const ExpressLayout: FC<Props> = ({ clientInfo, form }) => {
  const { t } = useTranslation();
  const client = useGlobalClient();
  const { needs } = useCapabilities();

  function redirect() {
    window.location.href = `https://${client.subdomain}`;
  }

  if (!needs('Invitation.Function.ExpressActivation')) {
    redirect();
    return null;
  }

  function renderTermsLink() {
    return (
      <Link
        href="https://everyonesocial.com/user-terms-of-service"
        target="_blank"
        underline="hover"
      >
        {t('auth:userTerms')}
      </Link>
    );
  }

  function renderPrivacyPolicyLink() {
    return (
      <Link href="https://everyonesocial.com/privacy-policy" target="_blank" underline="hover">
        {t('auth:privacyPolicy')}
      </Link>
    );
  }

  function renderContactLink() {
    return (
      <Link href="https://everyonesocial.com/contact" target="_blank" underline="hover">
        {t('auth:contactUs')}
      </Link>
    );
  }

  return (
    <Fragment>
      <BareAppBar />
      <Grid container alignItems="center" justifyContent="center" spacing={2} minHeight="100vh">
        <Grid xs={12} container justifyContent="center" alignItems="center" marginTop={8}>
          <Grid sx={styles.info}>{clientInfo} </Grid>

          <Grid sx={styles.form}>{form}</Grid>
        </Grid>
        <Grid container>
          <Grid>
            {renderTermsLink()} & {renderPrivacyPolicyLink()}
          </Grid>
          <Grid>{renderContactLink()}</Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ExpressLayout;
