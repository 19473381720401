import React, { FC, Fragment } from 'react';
import { useCapabilities } from 'hooks';

interface Props {
  name: string;
  value?: string | string[];
}

const Capability: FC<Props> = ({ name, value, children }) => {
  const { needs } = useCapabilities();
  return needs(name, value) ? <Fragment>{children}</Fragment> : null;
};

export default Capability;
