import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const OAuthScopePost: FC = () => {
  const { t } = useTranslation();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={2} alignItems="center">
          <Grid>
            <CheckCircleOutlinedIcon />
          </Grid>
          <Grid>
            <Typography variant="subtitle2">{t('api:doSubHead1')}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography paragraph color="textSecondary">
          {t('api:doSubHead1Bullet1')}
        </Typography>
        <Typography paragraph color="textSecondary">
          {t('api:doSubHead1Bullet2')}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default OAuthScopePost;
