import React from 'react';
import { Editor } from 'slate';

interface KeyControls {
  event: React.KeyboardEvent<HTMLDivElement>;
  mentionState: any;
  setMentionState: (mentionState: any) => void;
  insertMention: (editor: Editor, results: any, dropdown: boolean) => void;
  editor: Editor;
}

export const onKeyDown = (commands: KeyControls) => {
  const { event, mentionState, setMentionState, insertMention, editor } = commands;
  if (mentionState.target) {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setMentionState({
          ...mentionState,
          index: mentionState.index >= mentionState.results.length - 1 ? 0 : mentionState.index + 1,
        });
        break;
      case 'ArrowUp':
        event.preventDefault();
        setMentionState({
          ...mentionState,
          index: mentionState.index <= 0 ? mentionState.results.length - 1 : mentionState.index - 1,
        });
        break;
      case 'Tab':
      case 'Enter':
        if (mentionState.target && mentionState.results[mentionState.index]) {
          event.preventDefault();
          insertMention(editor, mentionState.results[mentionState.index], true);
        }
        break;
      case 'Escape':
        event.preventDefault();
        setMentionState({
          ...mentionState,
          target: null,
        });
        break;
    }
  }
};
