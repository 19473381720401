import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import { ContentInput } from 'api';
import { useFollowed } from 'hooks';
import { foundReadOnlyGroup } from 'features/posting/helpers';

const ShareableCheckbox: FC = () => {
  const { t } = useTranslation();
  const { groupRules } = useFollowed();
  const { values } = useFormikContext<ContentInput>();
  const foundReadOnly = foundReadOnlyGroup(values.group_ids, groupRules);

  return (
    foundReadOnly && <Typography color="textSecondary">{t('posting:readOnlyWarning')}</Typography>
  );
};

export default ShareableCheckbox;
