import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Unstable_Grid2 as Grid } from '@mui/material';
import Clipboard from 'clipboard';
import LinkIcon from '@mui/icons-material/Link';

import { ShareContentDestination, ShareType } from 'api';
import { useContent, useNotify, useSharePreview } from 'hooks';
import { PaddedPaper } from 'components';
import { callShareContentMutation } from 'app-react-query/imperative';
import theme from 'theme';

const styles = {
  button: {
    color: 'text.primary',
  },

  text: theme.mixins.visuallyHidden,
};

const ShareDirectly: FC = () => {
  const content = useContent();
  const notify = useNotify();
  const sharePreview = useSharePreview();
  const { t } = useTranslation();

  const contentClipboard = useRef<Clipboard>(null);
  const contentButtonRef = useRef(null);
  const contentTextRef = useRef<HTMLSpanElement>(null);

  const direct = sharePreview.shortened_urls?.direct;

  useEffect(() => {
    initContentClipboard();
    return () => contentClipboard.current?.destroy();
  }, []);

  function initContentClipboard() {
    const clipboard = new Clipboard(contentButtonRef.current, {
      text: () => sharePreview.shortened_urls?.direct?.short_link || '',
    });

    async function onSuccess() {
      notify.info({ i18n: { i18nKey: 'sharing:copied' } });

      const variables = {
        content_id: content.content_id,
        items: [
          {
            share_type: ShareType.Frontend,
            destination: ShareContentDestination.Direct,
          },
        ],
      };

      await callShareContentMutation(variables);
    }

    clipboard.on('success', () => void onSuccess());
  }

  return (
    <PaddedPaper padding={2}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid>
          <Box component="span" sx={styles.text} ref={contentTextRef}>
            {direct?.short_link || ''}
          </Box>
          <Button ref={contentButtonRef} sx={styles.button} startIcon={<LinkIcon color="action" />}>
            {t('sharing:shareDirectly')}
          </Button>
        </Grid>
      </Grid>
    </PaddedPaper>
  );
};

export default ShareDirectly;
