import React, { FC } from 'react';

import { getAppImage } from 'helpers';
import { APP_IMAGES } from 'app-constants';
import theme from 'theme';
import { Box } from '@mui/material';

const styles = {
  logo: {
    marginBottom: 3,

    [theme.breakpoints.down('lg')]: {
      marginTop: 3,
    },
  },
} as const;

const Logo: FC = () => {
  return (
    <Box sx={styles.logo}>
      <img {...getAppImage(APP_IMAGES.Logo)} />
    </Box>
  );
};

export default Logo;
