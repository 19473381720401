import React, { FC, Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import moment from 'moment';
import theme from 'theme';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

import { DateRange } from 'types/app';
import Flatpickr from './Flatpickr';

interface Props {
  dateRange: DateRange;
  startDate: string;
  endDate: string;
  onSetStart: (start: string) => void;
  onSetEnd: (end: string) => void;
  onSetRange: (range: DateRange) => void;
  includeToday?: boolean;
  transparent?: boolean;
}

const styles = {
  selectRoot: {
    minWidth: theme.spacing(18),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(20),

    '&.MuiInput-root': {
      '&.Mui-focused': {
        '&:after': {
          borderRadius: theme.spacing(20),
        },
      },
    },
  },
  dialogContent: {
    padding: 0,
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  transparent: {
    backgroundColor: 'transparent',

    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1),
  },
  menuItem: {
    color: 'text.primary',
    ...theme.typography.body2,
  },
  menuItemHeaderFirst: {
    display: 'block',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    marginBottom: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '&$menuItemDisabled': {
      opacity: 1,
    },
  },
  menuItemHeaderLast: {
    marginTop: theme.spacing(),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
};

const Range: FC<Props> = ({
  startDate,
  endDate,
  onSetStart,
  onSetEnd,
  dateRange,
  onSetRange,
  includeToday,
  transparent,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [hasCustomRange, setHasCustomRange] = useState(false);

  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const diffCount = endMoment.diff(startMoment, 'days');
  const maxDate = moment().add(includeToday ? 0 : -1, 'day');
  const [datePickerRange, setDatePickerRange] = useState([
    startMoment.toDate(),
    endMoment.toDate(),
  ]);

  useEffect(() => {
    if (dateRange == DateRange.LastXDays) {
      setHasCustomRange(true);
    }
  }, [dateRange]);

  function handleDateSelectChange(event: SelectChangeEvent<DateRange>) {
    onSetRange(event.target.value as DateRange);
    let _start;
    switch (event.target.value) {
      case DateRange.Last7Days:
        _start = moment(maxDate).subtract(7, 'days').startOf('day');
        break;
      case DateRange.Last30Days:
        _start = moment(maxDate).subtract(30, 'days').startOf('day');
        break;
      case DateRange.Last60Days:
        _start = moment(maxDate).subtract(60, 'days').startOf('day');
        break;
      case DateRange.Last90Days:
        _start = moment(maxDate).subtract(90, 'days').startOf('day');
        break;
      case DateRange.LastXDays:
        setOpenModal(true);
        setHasCustomRange(true);
        return;
    }
    setHasCustomRange(false);
    onSetStart(_start.format('YYYY-MM-DD'));
    const _end = maxDate.endOf('day');
    onSetEnd(_end.format('YYYY-MM-DD'));
    setDatePickerRange([_start.toDate(), _end.toDate()]);
  }

  function handleDateSelectOpen() {
    setOpen(true);
  }

  function handleDateSelectClose() {
    setOpen(false);
  }

  function handleModalClose() {
    setOpenModal(false);
  }

  function handleModalSave() {
    onSetStart(moment(datePickerRange[0]).startOf('day').format('YYYY-MM-DD'));
    onSetEnd(moment(datePickerRange[1]).endOf('day').format('YYYY-MM-DD'));
    setOpenModal(false);
    setHasCustomRange(true);
  }

  function renderRange() {
    return `${startMoment.format('ll')} - ${endMoment.format('ll')}`;
  }

  return (
    <Fragment>
      <FormControl variant="standard">
        <Select
          variant="standard"
          open={open}
          onOpen={handleDateSelectOpen}
          onClose={handleDateSelectClose}
          value={dateRange}
          onChange={handleDateSelectChange}
          sx={{ ...styles.selectRoot, ...(transparent && styles.transparent) }}
          IconComponent={ArrowDropDownRoundedIcon}
          inputProps={{ sx: styles.transparent }}
        >
          <MenuItem disabled value="" sx={{ ...styles.menuItemHeaderFirst }}>
            <Grid container direction="column">
              <Grid>
                <Typography color="textSecondary" variant="overline">
                  {t('analytics:range_day', { count: diffCount })}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2" color="textPrimary">
                  {renderRange()}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem sx={styles.menuItem} value={DateRange.Last7Days}>
            {t('analytics:last_X_days', { count: 7 })}
          </MenuItem>
          <MenuItem sx={styles.menuItem} value={DateRange.Last30Days}>
            {t('analytics:last_X_days', { count: 30 })}
          </MenuItem>
          <MenuItem sx={styles.menuItem} value={DateRange.Last60Days}>
            {t('analytics:last_X_days', { count: 60 })}
          </MenuItem>
          <MenuItem sx={styles.menuItem} value={DateRange.Last90Days}>
            {t('analytics:last_X_days', { count: 90 })}
          </MenuItem>
          <MenuItem
            onClick={() => setOpenModal(true)}
            value={DateRange.LastXDays}
            sx={{ ...styles.menuItemHeaderLast, ...styles.menuItem }}
          >
            {hasCustomRange ? renderRange() : t('analytics:custom_date_range')}
          </MenuItem>
        </Select>
      </FormControl>
      <Dialog open={openModal} onClose={handleModalClose}>
        <DialogContent sx={styles.dialogContent}>
          <Flatpickr
            options={{
              maxDate: maxDate.format(),
              mode: 'range',
              inline: true,
              defaultDate: [startDate, endDate],
            }}
            onChange={setDatePickerRange}
          />
          <Box sx={styles.actions}>
            <Button onClick={handleModalClose}>{t('common:cancel')}</Button>
            <Button color="primary" onClick={handleModalSave}>
              {t('common:apply')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default memo(Range);
