export const subpathListClients = 'client-list';
export const subpathCreateClient = 'create-client';
export const subpathUpdateClient = 'update-client/:client_id';
export const subpathUserList = 'user-list';
export const subpathBilling = 'billing';
export const subpathFeatureToggles = 'feature-toggles';
export const subpathCapabilityToggles = 'capability';
export const subpathReleaseToggles = 'release';
export const subpathCapabilityDetail = ':capability_id';
export const subpathReleaseDetail = ':release_toggle_id';

export const listClients = '/client-success/client-list';
export const createClient = '/client-success/create-client';
export const updateClient = '/client-success/update-client/:client_id';

export const userList = '/client-success/user-list';

export const featureToggles = '/client-success/feature-toggles';
export const capabilityToggles = '/client-success/feature-toggles/capability';
export const releaseToggles = '/client-success/feature-toggles/release';
export const capabilityDetail = '/client-success/feature-toggles/capability/:capability_id';
export const releaseToggleDetail = '/client-success/feature-toggles/release/:release_toggle_id';

export const billing = '/client-success/billing';

export const CLIENTS_TAB_ROUTES = [listClients, createClient, updateClient];
export const USER_TAB_ROUTES = [userList];
export const FEATURE_FLAG_ROUTES = [featureToggles, capabilityDetail, releaseToggleDetail];
export const BILLING_TAB_ROUTES = [billing];

export const PROTECTED_ROUTES = [...CLIENTS_TAB_ROUTES, ...USER_TAB_ROUTES, ...FEATURE_FLAG_ROUTES];
