// because of https://docs.slatejs.org/concepts/12-typescript#multiple-document-models
/* eslint-disable */

import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Descendant, Editor, Node, Range, Text, Transforms } from 'slate';
import { Editable, ReactEditor, RenderPlaceholderProps, Slate } from 'slate-react';
import { useField, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { Box } from '@mui/material';

import { GetMentionsQuery, MentionObject, Network } from 'api';
import { useBlockList, useContent, useGlobalClient, useReleases } from 'hooks';
import { TextCounter } from 'components';

import MentionsOptions from './MentionsOptions';
import { serializeToPlainText } from '../serialization';
import { SlateValue } from '../types';
import { useMentionService } from './hooks/useMentionService';
import { useGetLinkedInTokenId } from './hooks/useGetLinkedInTokenId';
import { onKeyDown } from './KeyControls';
import { Element, Leaf } from './CustomElements';
import { deserialize } from './deserialize';
import { getHighlightedBlocklistRanges } from '../helpers';
import { getClientCommentCharLimit, getTextLength } from 'features/posting/helpers';
import { findWordMatch } from 'features/sharing/helpers';
import { Share } from 'features/sharing/types';
import { MentionElement, SlateMentionsValue } from './types';
import theme from 'theme';

const styles = {
  field: {
    backgroundColor: theme.palette[400],
    padding: theme.spacing(2, 2),
    minHeight: '83px',
    borderRadius: 4,
  },
  fieldWithCounter: {
    backgroundColor: theme.palette[600],
    padding: theme.spacing(2, 6.5, 2, 2),
    minHeight: '83px',
    maxWidth: '500px',
    borderRadius: 4,
  },
  textCounter: {
    position: 'absolute',
    top: '50%',
    right: '15px',
  },
  isTextReadOnly: {
    opacity: 0.4,
  },
} as const;

interface Props {
  editor: Editor;
  fieldName: string;
  mentionsFieldName: string;
  blockListFieldName: string;
  placeholder: string;
  renderPlaceholder?(props: RenderPlaceholderProps): JSX.Element;
  onFocus(): void;
  disabled?: boolean;
  publicQuery: boolean;
  showTextCounter: boolean;
  disableMentions: boolean;
  network?: Network;
}

const MentionsInstance: FC<Props> = ({
  disabled,
  editor,
  onFocus,
  placeholder,
  renderPlaceholder,
  fieldName,
  mentionsFieldName,
  blockListFieldName,
  publicQuery,
  showTextCounter,
  disableMentions,
  network,
}) => {
  const content = useContent();
  const client = useGlobalClient();
  const releases = useReleases();
  const { terms } = useBlockList();

  const { setFieldValue, setFieldTouched } = useFormikContext<Share>();
  const [field] = useField(fieldName);
  const [blockListField] = useField(blockListFieldName);
  const [mentionsField] = useField(mentionsFieldName);

  const limit = getClientCommentCharLimit(network, content, client);
  const hasBlocklist = releases.includes('blocklist');
  const blockListTerms = blockListField.value || [];

  const [slateValue, setSlateValue] = useState<SlateMentionsValue[]>(
    deserialize(field.value, mentionsField.value)
  );

  const [mentionState, setMentionState] = useState({
    target: null,
    currentPath: null,
    index: 0,
    search: '',
    results: [],
    triggeredDropdown: false,
  });

  useEffect(() => {
    if (field.value !== serializeToPlainText(slateValue as SlateValue[])) {
      const point = { path: [0, 0], offset: 0 };
      editor.selection = { anchor: point, focus: point };
      editor.children = deserialize(field.value, mentionsField.value);

      setSlateValue(deserialize(field.value, mentionsField.value));
    }

    hasBlocklist && setFieldValue(blockListFieldName, findWordMatch(terms, field.value));
  }, [field.value]);

  useEffect(() => {
    setMentionState({
      ...mentionState,
      triggeredDropdown: false,
    });
  }, [mentionState.triggeredDropdown]);

  useEffect(() => {
    setFieldValue(fieldName, serializeToPlainText(slateValue as SlateValue[]));
  }, [slateValue]);

  const token_id = useGetLinkedInTokenId({ publicQuery });
  const { isLoading } = useMentionService({
    mentionState,
    setMentionState,
    token_id,
    publicQuery,
  });

  const highlightBlockListTerms = useCallback(
    ([node, path]) => {
      const ranges: Range[] = [];

      if (!hasBlocklist || !blockListTerms.length) {
        return ranges;
      }
      return getHighlightedBlocklistRanges(node, path, blockListTerms);
    },
    [blockListTerms, field.value]
  );

  const renderLeaf = useCallback(props => {
    return <Leaf {...props} />;
  }, []);

  const renderElement = useCallback(props => {
    return <Element {...props} />;
  }, []);

  function insertMention(
    editor: Editor,
    info: GetMentionsQuery['getMention']['items'][number],
    dropdown: boolean
  ) {
    const mentionElement: MentionElement = {
      type: 'mention',
      info: { ...info, identifier: `urn:li:organization:${info.identifier}` },
      children: [{ type: 'paragraph', text: info.name }],
    };

    Transforms.insertNodes(editor, mentionElement, {
      at: mentionState.currentPath,
    });

    Transforms.removeNodes(editor, {
      //@ts-ignore
      match: node => Text.isText(node) && node.type === 'searchNode',
      at: [editor?.selection?.anchor?.path[0]],
    });

    Transforms.move(editor);
    ReactEditor.focus(editor);

    const { anchor } = editor.selection || {};
    if (anchor) {
      const point = { path: anchor.path, offset: anchor.offset + 1 };
      Transforms.insertText(editor, ' ', {
        at: point,
      });
    }

    setMentionState({
      ...mentionState,
      index: 0,
      target: null,
      currentPath: null,
      results: [],
      search: '',
      triggeredDropdown: dropdown,
    });
  }

  function onChangeValue(value: Descendant[]) {
    if (!value.length) {
      return;
    }

    setSlateValue(value as SlateMentionsValue[]);
    const { selection } = editor;
    if (!selection?.anchor?.path) {
      return;
    }
    //@ts-ignore
    const { children } = value[selection?.anchor?.path[0]] as Descendant;
    const containsAsperand = children.findIndex(
      (value: any) => value.text && value.text.includes('@') && value.type !== 'searchNode'
    );
    const searchNode = children.findIndex((value: any) => value.type === 'searchNode');
    let node = children[children.length - 1];

    if (containsAsperand >= 0) {
      node = children[containsAsperand];
    } else if (searchNode >= 0) {
      node = children[searchNode];
    }

    if (selection && Range.isCollapsed(selection)) {
      const path = selection?.anchor?.path;

      if (node.type === 'searchNode') {
        if (!disableMentions) {
          if (node.text.includes('@')) {
            let n = node.text.replace('@', '');
            const offset = 0;
            const focusOffset = n?.length ?? 0;
            const anchor = { path, offset };
            const focus = { path, offset: focusOffset + 1 };
            const target = { anchor, focus };

            return setMentionState({
              ...mentionState,
              target,
              currentPath: path,
              search: n,
            });
          } else {
            Transforms.removeNodes(editor, {
              //@ts-ignore
              match: node => Text.isText(node) && node.type === 'searchNode',
              at: editor.selection,
            });
            return setMentionState({
              ...mentionState,
              target: null,
              results: [],
              search: '',
            });
          }
        }
      }

      if (Text.isText(node)) {
        const { text } = node;
        if (!disableMentions) {
          if (text.includes('@')) {
            const currentAsperandIndex = text.indexOf('@');
            const offset = currentAsperandIndex;
            const anchor = { path, offset };
            const focus = { path, offset: offset + 1 };
            const target = { anchor, focus };
            Transforms.setNodes(editor, { bold: true, type: 'searchNode' } as Partial<Node>, {
              match: n => Text.isText(n),
              split: true,
              at: target,
            });
            setMentionState({
              ...mentionState,
              target,
              index: 0,
            });
          } else {
            Transforms.setNodes(editor, { bold: false, type: 'text' } as Partial<Node>, {
              match: n => Text.isText(n),
              split: true,
              at: editor.selection,
            });
            setMentionState({
              ...mentionState,
              target: null,
              results: [],
              search: '',
            });
          }
        }
      }
    }
  }

  function handleRenderPlaceholder({ attributes, children }: RenderPlaceholderProps) {
    const materialUIAttributes = {
      ...attributes,
      style: {
        ...attributes.style,
        opacity: 'action.disabledOpacity',
      },
    };

    return renderPlaceholder ? (
      renderPlaceholder({ attributes: materialUIAttributes, children })
    ) : (
      <span {...materialUIAttributes}>{children}</span>
    );
  }

  function onBlur() {
    let position = 0;
    const mentions: MentionObject[] = [];
    // Use Editor instead of slate value because transforms update the editor more reliably
    // than our stand in slate value, should provide more consistency, slate value is more
    // for the initial value of the form.
    editor.children.forEach(line => {
      //@ts-ignore
      line.children.forEach(node => {
        if (node.type === 'mention') {
          const { name, identifier } = node.info;

          length = name.length;

          const mention: MentionObject = {
            identifier,
            length,
            provider: Network.Linkedin,
            start: position,
          };

          mentions.push(mention);

          position = position + length;
        }

        if (Text.isText(node)) {
          const { text } = node;
          position = position + text.length;
        }
      });
    });

    setFieldValue(mentionsFieldName, mentions);
  }

  const debounceChangeHandler = useCallback(
    debounce(onChangeValue, 300, { leading: false, trailing: true }),
    [mentionState.search]
  );

  return (
    <Fragment>
      <Slate editor={editor} value={slateValue as SlateValue[]} onChange={debounceChangeHandler}>
        <Editable
          onKeyDown={event => {
            setFieldTouched(fieldName);
            return onKeyDown({ event, mentionState, setMentionState, insertMention, editor });
          }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          renderPlaceholder={handleRenderPlaceholder}
          style={{
            ...(showTextCounter ? styles.fieldWithCounter : styles.field),
            ...(disabled && styles.isTextReadOnly),
          }}
          readOnly={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          decorate={highlightBlockListTerms}
        />
        {showTextCounter && (
          <Box sx={styles.textCounter}>
            <TextCounter
              length={getTextLength(Network.Linkedin, field.value || '')}
              limit={limit}
            />
          </Box>
        )}
      </Slate>
      <MentionsOptions
        loading={isLoading}
        mentionState={mentionState}
        setMentionState={setMentionState}
        insertMention={insertMention}
        editor={editor}
        publicQuery={publicQuery}
        shouldPromptAuth={(publicQuery && !!token_id === false) || !!token_id === false}
      />
    </Fragment>
  );
};

export default MentionsInstance;
