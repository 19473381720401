import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

interface Props {
  i18nKey: string;
  disableMargin?: boolean;
}

const styles = {
  tip: {
    marginTop: 2,
  },
  disableMargin: {
    margin: 0,
  },
} as const;

const Tip: FC<Props> = ({ i18nKey, disableMargin }) => {
  const { t } = useTranslation();

  return (
    <Box sx={disableMargin ? styles.disableMargin : styles.tip}>
      <Typography display="inline" color="primary" variant="caption">
        <em>{t('common:tip')}</em>
      </Typography>{' '}
      <Typography display="inline" variant="overline" color="textSecondary">
        <em>{t(i18nKey)}</em>
      </Typography>
    </Box>
  );
};

Tip.defaultProps = {
  i18nKey: '',
  disableMargin: false,
};

export default Tip;
