import React, { FC, ReactNode } from 'react';
import { Box, GridSize, Unstable_Grid2 as Grid } from '@mui/material';
import theme from 'theme';

const styles = {
  container: {
    margin: '0 auto',
    padding: theme.spacing(3, 0),
    maxWidth: 1300,
    [theme.breakpoints.down('xl')]: {
      paddingLeft: 2,
      paddingRight: 2,
    },
  },
} as const;

interface Props {
  main: ReactNode;
  mobileSize?: GridSize;
  tabletSize?: GridSize;
  desktopSize?: GridSize;
}

const App1Column: FC<Props> = ({ main, desktopSize, mobileSize, tabletSize }) => {
  return (
    <Box sx={styles.container}>
      <Grid container justifyContent="center">
        <Grid xs={mobileSize} md={tabletSize} lg={desktopSize}>
          <div>{main}</div>
        </Grid>
      </Grid>
    </Box>
  );
};

App1Column.defaultProps = {
  desktopSize: 12,
  tabletSize: 12,
  mobileSize: 12,
};

export default App1Column;
