import React, { FC } from 'react';
import { GridProps, Unstable_Grid2 as Grid } from '@mui/material';

const GridItem: FC<GridProps> = params => <Grid {...params} />;

GridItem.defaultProps = {
  xs: 12,
  sm: 9,
  md: 6,
};

export default GridItem;
