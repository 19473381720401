import { KeyboardEvent } from 'react';
import { Editor, Element as SlateElement, Node, Range, Text, Path, Transforms } from 'slate';
import { Marks } from './types';
import { EMOJI_WHITESPACE_REGEX, LIST_TYPES } from './constants';

interface GetRangeAndMatch {
  range: Range;
  match: string;
}

export function nodeHasType(node: Node, blockType: SlateElement['type']) {
  return !Editor.isEditor(node) && SlateElement.isElement(node) && node.type === blockType;
}

export function isMarkActive(editor: Editor, mark: Marks) {
  const marks = Editor.marks(editor);
  return marks ? marks[mark] === true : false;
}

export function toggleMark(editor: Editor, mark: Marks) {
  return isMarkActive(editor, mark)
    ? Editor.removeMark(editor, mark)
    : Editor.addMark(editor, mark, true);
}

export function isBlockActive(editor: Editor, blockType: SlateElement['type']) {
  const [match] = Editor.nodes(editor, {
    match: node => nodeHasType(node, blockType),
  });

  return !!match;
}

export function toggleBlock(editor: Editor, blockType: SlateElement['type']) {
  const isList = LIST_TYPES.some(listType => listType === blockType);
  const isActive = isBlockActive(editor, blockType);
  const newType = isActive ? 'paragraph' : isList ? 'list-item' : blockType;

  Transforms.unwrapNodes(editor, {
    match: node => LIST_TYPES.some(listType => nodeHasType(node, listType)),
    split: true,
  });

  Transforms.setNodes(editor, { type: newType });

  if (!isActive && isList) {
    const block = { type: blockType, children: [] } as any;
    Transforms.wrapNodes(editor, block);
  }
}

export function unwrapLink(editor: Editor) {
  Transforms.unwrapNodes(editor, {
    match: node => nodeHasType(node, 'link'),
  });
}

export function wrapLink(editor: Editor, url: string) {
  if (isBlockActive(editor, 'link')) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: SlateElement = {
    url,
    type: 'link',
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
}

export function getRangeAndMatch(
  editor: Editor,
  match: RegExp,
  distance: number
): GetRangeAndMatch {
  const [start] = Range.edges(editor.selection);

  const wordBefore = Editor.before(editor, start, { unit: 'word', distance });
  const before = wordBefore && Editor.before(editor, wordBefore);
  const beforeRange = before && Editor.range(editor, before, start);
  const beforeText = beforeRange && Editor.string(editor, beforeRange);
  const beforeMatch = beforeText && match.exec(beforeText);

  const after = Editor.after(editor, start);
  const afterRange = Editor.range(editor, start, after);
  const afterText = Editor.string(editor, afterRange);
  const afterMatch = EMOJI_WHITESPACE_REGEX.exec(afterText);

  if (!beforeMatch || !afterMatch) {
    if (distance !== 1) {
      return getRangeAndMatch(editor, match, distance - 1);
    } else {
      return { range: null, match: '' };
    }
  }

  return {
    range: beforeRange,
    match: beforeMatch[1],
  };
}

export function getHighlightedBlocklistRanges(
  node: Node,
  path: Path,
  blocklistedWords: { term: string }[]
): Range[] {
  const ranges: Range[] = [];

  if (Text.isText(node)) {
    const { text } = node;
    const textLength = text.length;
    for (const item of blocklistedWords) {
      let startIndex = 0;
      while ((startIndex = text.indexOf(item.term, startIndex)) !== -1) {
        const endIndex = startIndex + item.term.length;
        const isStartBoundary = startIndex === 0 || !/\w/.test(text[startIndex - 1]);
        const isEndBoundary = endIndex === textLength || !/\w/.test(text[endIndex]);
        if (isStartBoundary && isEndBoundary) {
          ranges.push({
            anchor: { path, offset: startIndex },
            focus: { path, offset: endIndex },
            // @ts-ignore
            highlight: true,
          });
        }
        startIndex = endIndex;
      }
    }
  }
  return ranges;
}

export function escapeFormat(editor: Editor, event: KeyboardEvent) {
  const isBulletedActive = isBlockActive(editor, 'bulleted-list');
  const isNumberedActive = isBlockActive(editor, 'numbered-list');
  const isBlockQuoteActive = isBlockActive(editor, 'block-quote');

  const isEscapableActive = isBulletedActive || isNumberedActive || isBlockQuoteActive;

  if (!isEscapableActive) {
    return;
  }

  const path = Editor.path(editor, editor.selection);
  const pathText = Editor.string(editor, path);

  if (pathText) {
    return;
  }

  event.preventDefault();
  toggleBlock(editor, 'paragraph');
}
