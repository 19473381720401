import { transport } from './protected-transport';
import {
  UserSuggestDocument,
  UserSuggestQuery,
  UserSuggestQueryVariables,
  ShareContentDocument,
  ShareContentMutation,
  ShareContentMutationVariables,
} from 'api';

export async function callUserSuggestQuery(variables: UserSuggestQueryVariables) {
  return await transport<UserSuggestQuery, UserSuggestQueryVariables>(
    UserSuggestDocument,
    variables
  )();
}

export async function callShareContentMutation(variables: ShareContentMutationVariables) {
  return await transport<ShareContentMutation, ShareContentMutationVariables>(
    ShareContentDocument,
    variables
  )();
}
