import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Unstable_Grid2 as Grid,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { AuthenticationType, CsUpdateClientInput, SsoConfiguration } from 'api';
import { ValidatedSelect, ValidatedTextFieldNext } from 'components';

import { ClientInput } from '../../types';
import IdentityProviderForm from './IdentityProviderForm';
import ServiceProviderMetadata from './ServiceProviderMetadata';
import AttrMappingForm from './AttrMappingForm';
import ScimTokenForm from '../ScimToken/ScimTokenForm';

const OPTIONS = [
  { id: AuthenticationType.Auth, title: 'Auth' },
  { id: AuthenticationType.Sso, title: 'SSO' },
  { id: AuthenticationType.Passwordless, title: 'Passwordless' },
];

const Authentication: FC = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<ClientInput>();
  const clientId = (values as CsUpdateClientInput).id;
  const [samlIdpDialogOpen, setSamlIdpDialogOpen] = useState(false);
  const [samlSpDialogOpen, setSamlSpDialogOpen] = useState(false);
  const [attrDialogOpen, setAttrDialogOpen] = useState(false);
  const [scimTokenDialogOpen, setScimTokenDialogOpen] = useState(false);

  function handleCompleted(sso_configuration: SsoConfiguration) {
    setFieldValue('sso_provider', sso_configuration.provider);
    setSamlIdpDialogOpen(false);
  }

  return (
    <Grid container>
      <Grid xs={3}>
        <Typography variant="subtitle2">Authentication</Typography>
      </Grid>
      <Grid xs={9} container spacing={2}>
        <Grid xs={12}>
          <ValidatedSelect
            persistLabel
            required
            items={OPTIONS}
            name="authentication_type"
            i18nKey="clientSuccess:authenticationType"
          />
        </Grid>

        <Grid xs={12}>
          <ValidatedTextFieldNext
            fullWidth
            name="sso_provider"
            placeholder={t('clientSuccess:provider')}
            label={t('clientSuccess:provider')}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid xs={12} container spacing={1}>
          <Grid>
            <Button variant="contained" color="primary" onClick={() => setSamlIdpDialogOpen(true)}>
              IDP SAML Meta
            </Button>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAttrDialogOpen(true)}
              disabled={!values.sso_provider}
            >
              Map Attributes
            </Button>
          </Grid>
          <Grid>
            <Button variant="contained" color="primary" onClick={() => setSamlSpDialogOpen(true)}>
              SP SAML Meta
            </Button>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setScimTokenDialogOpen(true)}
            >
              SCIM Tokens
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={samlIdpDialogOpen}
          onClose={() => setSamlIdpDialogOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>SAML IDP Meta</DialogTitle>
          <DialogContent>
            <IdentityProviderForm client_id={clientId} onCompleted={handleCompleted} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={attrDialogOpen}
          onClose={() => setAttrDialogOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>Map Attributes</DialogTitle>
          <DialogContent>
            <AttrMappingForm client_id={clientId} onCompleted={() => setAttrDialogOpen(false)} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={samlSpDialogOpen}
          onClose={() => setSamlSpDialogOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>SAML SP Metadata</DialogTitle>
          <DialogContent>
            <ServiceProviderMetadata client={values} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={scimTokenDialogOpen}
          onClose={() => setScimTokenDialogOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>SCIM Token</DialogTitle>
          <DialogContent>
            <ScimTokenForm client={values as CsUpdateClientInput} />
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default Authentication;
