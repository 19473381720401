import { useEffect, useState } from 'react';

import { queryString } from 'utilities';
import { FACEBOOK_APP_ID } from 'settings';

const FACEBOOK_URL = 'https://www.facebook.com/';

/**
 * Facebook is weird and returns random stuff for different scenarios
 * https://developers.facebook.com/docs/sharing/reference/share-dialog
 */
const FACEBOOK_RETURN_CANCEL_CODE = '4201';
const FACEBOOK_DIALOG_CANCEL_RESPONSE = '';
const FACEBOOK_DIALOG_SUCCESS_RESPONSE: string[] = [];

const useFacebookShare = () => {
  const [facebookStatus, setFacebookStatus] = useState(null);

  useEffect(() => {
    if (window.document.referrer !== FACEBOOK_URL) {
      return;
    }

    handleFacebookReturn();
  }, []);

  function handleFacebookReturn() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const hasErrorCode = urlSearchParams.get('error_code') === FACEBOOK_RETURN_CANCEL_CODE;

    if (hasErrorCode) {
      urlSearchParams.delete('error_code');
      urlSearchParams.delete('error_message');
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${urlSearchParams.toString()}`
      );
      setFacebookStatus('canceled');
    }

    if (!hasErrorCode) {
      window.history.replaceState({}, '', `${window.location.pathname}${window.location.search}`);
      setFacebookStatus('success');
    }
  }

  function facebookPopup(shareUrl: string) {
    const fbUIVariables = {
      app_id: FACEBOOK_APP_ID,
      display: 'popup',
      method: 'share',
      href: shareUrl,
    };

    window?.FB?.ui(fbUIVariables, (response: string | string[]) => {
      if (response === FACEBOOK_DIALOG_CANCEL_RESPONSE) {
        setFacebookStatus('canceled');
      }
      if (Array.isArray(response) && response.length === FACEBOOK_DIALOG_SUCCESS_RESPONSE.length) {
        setFacebookStatus('success');
      }
    });
  }

  function facebookRedirect(shareUrl: string) {
    const queryParams = {
      app_id: FACEBOOK_APP_ID,
      display: 'iframe',
      href: window.encodeURIComponent(shareUrl),
      redirect_uri: window.encodeURIComponent(window.location.href),
    };

    window.location.href = `${FACEBOOK_URL}dialog/share?${queryString(queryParams)}`;
  }

  return { facebookPopup, facebookRedirect, facebookStatus };
};

export default useFacebookShare;
