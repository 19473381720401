import React, { FC } from 'react';
import MediaQuery from 'react-responsive';
import { useTheme } from '@mui/material/styles';

const OFFSET = 0.05;

const Desktop: FC = (props: any) => {
  const theme = useTheme();
  const { lg } = theme.breakpoints.values;

  return <MediaQuery {...props} minWidth={lg} />;
};

const Mobile: FC = (props: any) => {
  const theme = useTheme();
  const { xs, lg } = theme.breakpoints.values;

  return <MediaQuery {...props} minWidth={xs} maxWidth={lg - OFFSET} />;
};

const Tablet: FC = (props: any) => {
  const theme = useTheme();
  const { sm, lg } = theme.breakpoints.values;

  return <MediaQuery {...props} minWidth={sm} maxWidth={lg - OFFSET} />;
};

const Phone: FC = (props: any) => {
  const theme = useTheme();
  const { xs, sm } = theme.breakpoints.values;

  return <MediaQuery {...props} minWidth={xs} maxWidth={sm - OFFSET} />;
};

export { Desktop, Tablet, Mobile, Phone };
