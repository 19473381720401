import React, { FC } from 'react';
import { Button, ButtonProps, Typography } from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle';

const SidebarButton: FC<ButtonProps> = ({ onClick, startIcon, children, ...rest }) => {
  return (
    <Button
      fullWidth
      variant="outlined"
      color="primary"
      onClick={onClick}
      startIcon={startIcon}
      sx={{ display: 'flex', justifyContent: 'flex-start', backgroundColor: 'common.white' }}
      {...rest}
    >
      <Typography color="black" variant="body2">
        {children}
      </Typography>
    </Button>
  );
};

SidebarButton.defaultProps = {
  startIcon: <AddCircle color="primary" />,
};

export default SidebarButton;
