import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Unstable_Grid2 as Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

import { Network, ShareContentDestination, ShareType } from 'api';
import { PaddedPaper } from 'components';
import { queryString } from 'utilities';
import { useContent, useCurrentUser, useProtectedClient, useSharePreview } from 'hooks';
import { callShareContentMutation } from 'app-react-query/imperative';

const styles = {
  button: {
    color: 'primary.main',
  },
} as const;

const SendToEmail: FC = () => {
  const currentUser = useCurrentUser();
  const client = useProtectedClient();
  const content = useContent();
  const sharePreview = useSharePreview();
  const { t } = useTranslation();

  const { email_domains } = client;
  const [emailDomain] = email_domains;

  const shortLink = getShortLink();
  const { title, description } = getMetadata();
  const { commentary } = content;
  const emailParams = {
    from: currentUser.email_address,
    subject: title,
    body: '',
  };

  let linkedinCommentary = null;
  if (commentary) {
    linkedinCommentary = commentary.find(comment => comment.network === Network.Linkedin);
  }

  if (linkedinCommentary) {
    emailParams.body = `${linkedinCommentary.commentary}%0A%0A${shortLink}`;
  } else {
    emailParams.body = `${description}%0A%0A${shortLink}`;
  }

  const mailtoLink = `mailto:colleague@${emailDomain}?${queryString(emailParams)}`;

  function getShortLink() {
    return sharePreview.shortened_urls?.email?.short_link ?? '';
  }

  function getMetadata() {
    const { content_object } = content;
    const { url_metadata } = content_object;

    return url_metadata || { title: '', description: '' };
  }

  async function handleEmailTracking() {
    const variables = {
      content_id: content.content_id,
      items: [
        {
          share_type: ShareType.Frontend,
          destination: ShareContentDestination.Email,
        },
      ],
    };

    await callShareContentMutation(variables);
  }

  return (
    <PaddedPaper padding={2}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid>
          <Button
            target="_blank"
            href={mailtoLink}
            onClick={handleEmailTracking}
            sx={styles.button}
            startIcon={<EmailIcon color="primary" />}
          >
            {t('sharing:sendToEmail')}
          </Button>
        </Grid>
      </Grid>
    </PaddedPaper>
  );
};

export default SendToEmail;
