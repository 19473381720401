import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { ReportingFilter, SortDirections, SortedUser, UsersSortFields, UserType } from 'api';
import { Numeric, Queries, TableCellWithOverflow } from 'components';
import { generateLink } from 'Links';
import { usePrevious, useScrollToRef } from 'hooks';

import { ISortableHeadCell } from '../types';
import { DateCell, EmptyStatRow, SortableHeadCell, TableLoader, TablePagination } from '../shared';
import { ROWS_PER_PAGE_OPTIONS } from '../constants';
import useStyles from '../analyticsStyles';

interface IProps {
  startDate: string;
  endDate: string;
  filter: ReportingFilter;
}

const UserDashboardStatTable: FC<IProps> = ({ startDate, endDate, filter }) => {
  const classes = useStyles;
  const hasMounted = usePrevious('hasMounted');
  const { t } = useTranslation();
  const { ref, scrollTo } = useScrollToRef();

  const [sortValue, setSortValue] = useState(UsersSortFields.CreatedAt);
  const [sortDirection, setSortDirection] = useState(SortDirections.Desc);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const variables = {
    start_date: startDate,
    end_date: endDate,
    sort_direction: sortDirection,
    per_page: rowsPerPage,
    sort: sortValue,
    snapshot: false,
    filter,
    page,
  };

  const headCells: ISortableHeadCell[] = [
    {
      id: UsersSortFields.Name,
      align: 'inherit',
      label: t('common:name'),
      initialSort: SortDirections.Asc,
      width: 300,
    },
    {
      id: UsersSortFields.UserType,
      align: 'inherit',
      label: t('common:type'),
      initialSort: SortDirections.Asc,
      width: 'auto',
    },
    {
      id: UsersSortFields.CreatedAt,
      align: 'inherit',
      label: t('analytics:createdOn'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: UsersSortFields.OnboardingCompleted,
      align: 'inherit',
      label: t('analytics:onboarded'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: UsersSortFields.LastAccessedAt,
      align: 'inherit',
      label: t('analytics:last_active'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: UsersSortFields.Reach,
      align: 'right',
      label: t('common:reach'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: UsersSortFields.Posts,
      align: 'right',
      label: t('common:post_plural'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: UsersSortFields.Shares,
      align: 'right',
      label: t('common:share_plural'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
    {
      id: UsersSortFields.Clicks,
      align: 'right',
      label: t('common:click_plural'),
      initialSort: SortDirections.Desc,
      width: 'auto',
    },
  ];

  useEffect(() => {
    hasMounted && scrollTo();
  }, [page, rowsPerPage]);

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleSort(id: UsersSortFields, direction: SortDirections) {
    setPage(0);
    setSortValue(id);
    setSortDirection(direction);
  }

  function renderUserType(userType: UserType) {
    const isCS = userType === UserType.Cs;
    const textTransform = isCS ? 'uppercase' : 'capitalize';

    return <Typography style={{ textTransform }}>{userType}</Typography>;
  }

  function renderHeadCell(cellProps: ISortableHeadCell) {
    return (
      <SortableHeadCell
        key={cellProps.id}
        {...cellProps}
        onSort={handleSort}
        sortValue={sortValue}
        sortDirection={sortDirection}
      />
    );
  }

  function renderStat(sortedUser: SortedUser) {
    return (
      <TableRow sx={{ root: classes.tableBodyRow }} key={sortedUser.user_id}>
        <TableCellWithOverflow>
          <Link
            noWrap
            target="_blank"
            title={sortedUser.name}
            component={generateLink(`/profile/${sortedUser.user_id}`)}
            variant="body2"
            underline="hover"
          >
            {sortedUser.name}
          </Link>
        </TableCellWithOverflow>
        <TableCell>{renderUserType(sortedUser.user_type)}</TableCell>
        <DateCell dateValue={sortedUser.created_at} />
        <TableCell>{sortedUser.onboarding_completed && <CheckIcon color="disabled" />}</TableCell>
        <DateCell dateValue={sortedUser.last_accessed_at} />
        <TableCell align="right">
          <Numeric value={sortedUser.reach} />
        </TableCell>
        <TableCell align="right">
          <Numeric value={sortedUser.posts} />
        </TableCell>
        <TableCell align="right">
          <Numeric value={sortedUser.shares} />
        </TableCell>
        <TableCell align="right">
          <Numeric value={sortedUser.clicks} />
        </TableCell>
      </TableRow>
    );
  }

  function renderEmpty() {
    return <EmptyStatRow colSpan={headCells.length} />;
  }

  return (
    <Fragment>
      <Typography ref={ref} paragraph variant="h5" color="textSecondary">
        {t('common:user_plural')}
      </Typography>
      <Queries.AnalyticsSortedTable type="sortedUsers" variables={variables}>
        {({ loading, stats }) => (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>{headCells.map(renderHeadCell)}</TableRow>
              </TableHead>
              <TableBody>
                {loading && <TableLoader rows={rowsPerPage} colSpan={headCells.length} />}
                {!loading && (stats.length ? stats.map(renderStat) : renderEmpty())}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    colSpan={headCells.length}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    disabled={!stats.length || stats.length < rowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Queries.AnalyticsSortedTable>
    </Fragment>
  );
};

export default UserDashboardStatTable;
