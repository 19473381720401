import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { Dimensions } from 'components/index';

import useComponentStyles from './components/styles';
import { Box } from '@mui/material';

interface Props {
  height?: number;
  thickness?: number;
  values?: number[];
  placeholderMode?: boolean;
}

const styles = {
  root: {
    position: 'relative',
  },
  segment: {
    position: 'absolute',
    transformOrigin: 'left bottom',
  },
} as const;

const LineChartLoader: FC<Props> = ({ height, thickness, values, placeholderMode }) => {
  const theme = useTheme();
  const componentClasses = useComponentStyles;
  const topMostPoint = Math.max(...values);

  const segmentClasses = {
    ...styles.segment,
    ...(!placeholderMode ? componentClasses.animation : {}),
  };

  function getDegrees(rads: number) {
    return rads * (180 / Math.PI);
  }

  return (
    <Box style={{ height }} sx={styles.root}>
      <Dimensions>
        {dimensions => {
          const base = dimensions.width / values.length;

          return values.map((value, index) => {
            const isLast = values[index + 1] === undefined;
            const left = index * base;
            const bottom = height * (value / topMostPoint);

            const nextPoint = height * ((isLast ? 20 : values[index + 1]) / topMostPoint);
            const rise = bottom - nextPoint;

            const hypotenuse = Math.sqrt(base * base + rise * rise);
            const angle = getDegrees(Math.asin(rise / hypotenuse));

            const pointStyle = { left, bottom };
            const segmentStyle = {
              left,
              bottom,
              height: thickness,
              width: `calc(${hypotenuse} * 1px)`,
              transform: `rotate(calc(${angle} * 1deg))`,
              backgroundColor: theme.palette.grey[placeholderMode ? 100 : 300],
            };

            return (
              <div key={index}>
                <div style={pointStyle} />
                <Box sx={segmentClasses} style={segmentStyle} />
              </div>
            );
          });
        }}
      </Dimensions>
    </Box>
  );
};

LineChartLoader.defaultProps = {
  height: 200,
  thickness: 2,
  values: [60, 20, 30, 60, 35, 40, 90, 10],
  placeholderMode: false,
};

export default LineChartLoader;
