import React, { FC, KeyboardEvent } from 'react';
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ValidatedTextFieldNext } from 'components';

const EmailOptions: FC = () => {
  const { t } = useTranslation();
  function handleKeyPress(event: KeyboardEvent<HTMLDivElement>) {
    if (event.key === '@' || event.key === ' ') {
      event.preventDefault();
    }
  }

  return (
    <Grid xs={12} container>
      <Grid xs={3}>
        <Typography variant="subtitle2">Email Options</Typography>
      </Grid>
      <Grid xs={9} container spacing={3}>
        <Grid xs={4}>
          <ValidatedTextFieldNext
            fullWidth
            name="email_sending_domain"
            placeholder={t('clientSuccess:emailSendingDomain')}
            label={t('clientSuccess:emailSendingDomain')}
            onKeyPress={handleKeyPress}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid xs>
          <ValidatedTextFieldNext
            fullWidth
            name="email_from_address"
            placeholder={t('clientSuccess:emailFromAddress')}
            label={t('clientSuccess:emailFromAddress')}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid xs={12}>
          <ValidatedTextFieldNext
            fullWidth
            name="email_address"
            placeholder={t('clientSuccess:emailAddress')}
            label={t('clientSuccess:emailAddress')}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmailOptions;
