import { UseMutationOptions, useQueryClient } from 'react-query';

import {
  BatchCreateGroupsMutation,
  BatchCreateGroupsMutationVariables,
  useGetFollowedQuery,
} from 'api';
import { useBootstrapQueryKey, useCurrentUser, useNotify } from 'hooks';
import { FOLLOW_QUERIES_LIMIT } from 'app-constants';

type Mutation = BatchCreateGroupsMutation;
type MutationVariables = BatchCreateGroupsMutationVariables;

const useBatchCreateGroups = () => {
  const notify = useNotify();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const followedQueryKey = useGetFollowedQuery.getKey({
    data: { user_id: currentUser.id, limit: FOLLOW_QUERIES_LIMIT },
  });
  const bootstrapQueryKey = useBootstrapQueryKey();

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onError: notify.mutationError,
    onSettled: () => {
      void queryClient.refetchQueries(bootstrapQueryKey);
      void queryClient.refetchQueries(followedQueryKey);
    },
  };

  return mutationOptions;
};

export default useBatchCreateGroups;
