import { ForwardRefExoticComponent, ReactNode, Dispatch, SetStateAction } from 'react';
import { TransProps } from 'react-i18next';

import {
  PublicUserProfileFragment,
  UserProfileFragment,
  UserFeedFollowGroupFragment,
  ToggleCapabilityGroupMutation,
} from 'api';

export type UserProfile = PublicUserProfileFragment | UserProfileFragment;

export enum AspectRatios {
  square = 2 / 2,
  wide = 16 / 9,
  none = NaN,
}

export interface ContentLayoutProps {
  selectedGroup?: UserFeedFollowGroupFragment;
}

export type LoaderTypes = 'standard' | 'widget' | 'none' | ReactNode;

// Values are corresponding routes
export enum SuggestionTypes {
  Group = 'group',
  Profile = 'profile',
}

// Values are corresponding routes
export enum SearchResultTypes {
  Group = 'group',
  Profile = 'profile',
  Content = 'content',
}

export interface IFilter {
  component: ForwardRefExoticComponent<any>;
  isSelected: boolean;
  textNode: string;
  subMenu?: IFilter[];
  onClick?: () => void;
  capability?: string;
}

export interface OrganicInsightsFilter {
  component: ForwardRefExoticComponent<any>;
  isSelected: boolean;
  textNode: string;
}

export type ClientSettingParams = {
  department_id: string;
  location_id: string;
};

export interface INotify {
  duration?: number;
  component?: ReactNode;
  message?: any;
  i18n?: TransProps<string>;
  show?: boolean;
  type?: NotifyTypes;
}

export enum INotifyTypes {
  Error = 'error',
  Info = 'info',
  QueryError = 'queryError',
  MutationError = 'mutationError',
  SubscriptionError = 'subscriptionError',
}

export type NotifyTypes = 'error' | 'info' | 'queryError' | 'mutationError' | 'subscriptionError';

export enum DateRange {
  Last7Days = 'last-7-days',
  Last30Days = 'last-30-days',
  Last60Days = 'last-60-days',
  Last90Days = 'last-90-days',
  LastXDays = 'last-X-days',
}

export interface UseDateRange {
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
  dateRange: DateRange;
  setDateRange: Dispatch<SetStateAction<DateRange>>;
  calculateDateRange: (startDate: string, endDate: string) => DateRange;
}

interface BaseQuery<TResults> {
  results: TResults[];
  count: number;
  isEmpty: boolean;
  isExhausted: boolean;
  isInitialLoad: boolean;
}

export interface InfiniteQuery<TResults> extends BaseQuery<TResults> {
  sentinelRef(node: Element): void;
}

export interface LoadMoreQuery<TResults> extends BaseQuery<TResults> {
  isLoading: boolean;
  onLoadMore(): void;
}

/**
 * Derived from @aws-amplify/auth package
 * https://github.com/aws-amplify/amplify-js/tree/main/packages/auth
 */
export enum AWSAmplifyAuthEvents {
  // OAuth
  codeFlow = 'codeFlow',
  implicitFlow = 'implicitFlow',
  oAuthSignOut = 'oAuthSignOut',

  // Auth
  configured = 'configured',
  tokenRefresh = 'tokenRefresh',
  tokenRefresh_failure = 'tokenRefresh_failure',
  signUp_failure = 'signUp_failure',
  signUp = 'signUp',
  signIn_failure = 'signIn_failure',
  signIn = 'signIn',
  completeNewPassword_failure = 'completeNewPassword_failure',
  signOut = 'signOut',
  forgotPassword_failure = 'forgotPassword_failure',
  forgotPassword = 'forgotPassword',
  forgotPasswordSubmit_failure = 'forgotPasswordSubmit_failure',
  forgotPasswordSubmit = 'forgotPasswordSubmit',
  parsingCallbackUrl = 'parsingCallbackUrl',
  cognitoHostedUI = 'cognitoHostedUI',
  cognitoHostedUI_failure = 'cognitoHostedUI_failure',
  customOAuthState = 'customOAuthState',
  customState_failure = 'customState_failure',
}

export enum CheckedMode {
  OFF,
  PAGE,
  ALL,
}
export type CheckedIds = string[];
export type SetCheckedState = CheckedIds | CheckedMode;

export interface UseCheckedState {
  checkedIds: CheckedIds;
  checkedMode: CheckedMode;
  hasChecked: boolean;
  resetCheckedState: () => void;
  setCheckedState: (option: SetCheckedState) => void;
}

export interface UseCapabilityToggle {
  isActive: boolean;
  isLoading: boolean;
  isDirty: boolean;
  onChange(nextValue: boolean): void;
  save(): Promise<ToggleCapabilityGroupMutation> | Promise<void>;
}

export enum GridLayoutSizes {
  Mini,
  Small,
  Medium,
  Large,
}

export enum FeedViewOptions {
  List = 'List',
  Grid = 'Grid',
}
