import { useContext } from 'react';

import { Network } from 'api';
import { SocialConnectionsContext } from 'providers';

export default () => {
  const socialConnectionsContext = useContext(SocialConnectionsContext);
  const { connections, linkedInPages, socialAccounts, setSocialAccounts } =
    socialConnectionsContext;

  const twitterAccounts = connections.filter(conn => conn.provider === Network.Twitter);
  const linkedinAccounts = connections.filter(conn => conn.provider === Network.Linkedin);

  return {
    connections,
    twitterAccounts,
    linkedinAccounts,
    linkedInPages,
    socialAccounts,
    setSocialAccounts,
  };
};
