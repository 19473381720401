import { INotify } from 'types/app';

export const enum Event {
  Show,
}

type onShowCallback = (options: INotify) => void;
type Callback = onShowCallback;

interface EventManager {
  events: Map<Event, Callback[]>;
  on(event: Event, callback: Callback): EventManager;
  off(event: Event, callback: Callback): EventManager;
  emit(event: Event, options: INotify): void;
}

const eventManager: EventManager = {
  events: new Map(),

  on(event, callback) {
    this.events.has(event) || this.events.set(event, []);
    this.events.get(event).push(callback);
    return this;
  },

  off(event, callback) {
    if (callback) {
      const nextCallback = this.events.get(event).filter(cb => cb !== callback);
      this.events.set(event, nextCallback);
      return this;
    }

    this.events.delete(event);
    return this;
  },

  emit(event, options) {
    if (!this.events.has(event)) {
      return;
    }

    this.events.get(event).forEach(callback => {
      callback(options);
    });
  },
};

export default eventManager;
