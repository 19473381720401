import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Box, Button, Typography } from '@mui/material';

import {
  CreateClientSelfServeInput,
  SegmentEventType,
  useCreateClientSelfServeMutation,
  useCreateLoginTokenForCurrentUserInClientMutation,
} from 'api';
import { Layouts, Loaders, PaddedPaper, ValidatedTextFieldNext } from 'components';
import { useCognitoUser, useNotify } from 'hooks';
import { getAppImage, getSubdomainUrl } from 'helpers';
import { useTrackingQuery } from 'app-react-query/hooks';
import * as paths from 'paths';

import { clientCreateSchema } from '../validations';

const ClientCreate: FC = () => {
  const notify = useNotify();
  const { t } = useTranslation();
  const { authenticatedUser } = useCognitoUser();
  const idToken = authenticatedUser.getSignInUserSession().getIdToken().payload;
  const cognitoUsername = idToken['cognito:username'] || idToken.sub;

  const track = useTrackingQuery();

  const { mutateAsync: createClient, isLoading: createClientLoading } =
    useCreateClientSelfServeMutation({
      onError: notify.mutationError,
      onSuccess: data => {
        window.analytics?.identify(data.createClientSelfServe.user.id);
        track(
          SegmentEventType.SignupLoginExistingUserCreateClientClientCreated,
          data.createClientSelfServe.user.id
        );
      },
    });

  const { mutateAsync: createLoginToken, isLoading: createLoginTokenLoading } =
    useCreateLoginTokenForCurrentUserInClientMutation({
      onError: notify.mutationError,
    });

  const isLoading = createClientLoading || createLoginTokenLoading;

  const initialValues: CreateClientSelfServeInput = {
    title: '',
    full_name: '',
    email_address: authenticatedUser.getUsername(),
    cognito_user_name: cognitoUsername,
  };

  async function handleSubmit(values: CreateClientSelfServeInput) {
    const { createClientSelfServe } = await createClient({ data: values });
    const { client } = createClientSelfServe;

    const { createLoginTokenForCurrentUserInClient } = await createLoginToken({
      client_id: client.id,
    });

    const url = getSubdomainUrl(client.subdomain);

    window.location.href = `${url}${paths.setupWorkspaceGroups}/?token=${createLoginTokenForCurrentUserInClient}`;
  }

  function renderImage() {
    return <img width={500} height={500} {...getAppImage('illustrations/boat.png', 500)} />;
  }

  function renderMain() {
    return (
      <PaddedPaper elevation={0} padding="64px 48px">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={clientCreateSchema}
        >
          <Layouts.OnboardingBox component={Form}>
            <Typography align="center" paragraph variant="h2">
              {t('onboarding:clientCreateHeader')}
            </Typography>
            <Typography align="center" paragraph color="textSecondary">
              {t('onboarding:clientCreateSubHead')}
            </Typography>
            <Box paddingTop={3}>
              <ValidatedTextFieldNext
                fullWidth
                name="title"
                label={t('onboarding:clientCreateTitlePlaceholder')}
                placeholder={t('onboarding:clientCreateTitlePlaceholder')}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box paddingTop={2}>
              <ValidatedTextFieldNext
                fullWidth
                name="full_name"
                label={t('onboarding:yourFullName')}
                placeholder={t('onboarding:yourFullName')}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box paddingTop={3}>
              <Button
                fullWidth
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? <Loaders.Standard height={26} /> : t('common:continue')}
              </Button>
            </Box>
          </Layouts.OnboardingBox>
        </Formik>
      </PaddedPaper>
    );
  }

  return <Layouts.Onboarding image={renderImage()} main={renderMain()} />;
};

export default ClientCreate;
