import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Paper, Tab, Tabs, Typography } from '@mui/material';

import { InternalOverviewStat } from 'api';
import { DatePickers, Queries } from 'components';
import { useDateRange } from 'hooks';

import { ChartLoader, ILineChartConfig, LineChart, TabLabel } from '../shared';
import { INTERNAL_CHART_HEIGHT, TABBED_CHART_EXCESS_HEIGHT } from '../constants';
import useStyles from '../analyticsStyles';

enum StatType {
  Posts = 'posts',
  Comments = 'comments',
  Users = 'users',
  Reactions = 'reactions',
  Groups = 'groups',
}

const InternalOverviewStats: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles;

  const [tab, setTab] = useState<StatType>(StatType.Users);
  const { startDate, setStartDate, endDate, setEndDate, dateRange, setDateRange } = useDateRange();

  function handleTabChange(event: ChangeEvent<HTMLDivElement>, value: StatType) {
    setTab(value);
  }

  function renderSummaryTabs(queryResults: InternalOverviewStat) {
    const { summary } = queryResults;
    const { posts, users, reactions, groups } = summary;

    const tabs = [
      { i18nKey: 'common:user_plural', value: StatType.Users, stat: users },
      { i18nKey: 'common:group_plural', value: StatType.Groups, stat: groups },
      { i18nKey: 'common:post_plural', value: StatType.Posts, stat: posts },
      { i18nKey: 'common:like_plural', value: StatType.Reactions, stat: reactions },
    ];

    return (
      <Tabs value={tab} onChange={handleTabChange} TabIndicatorProps={{ sx: classes.indicator }}>
        {tabs.map((tab, idx) => (
          <Tab
            key={idx}
            sx={{
              root: classes.tabRoot,
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
            label={<TabLabel stat={tab.stat} i18nKey={tab.i18nKey} />}
            value={tab.value}
          />
        ))}
      </Tabs>
    );
  }

  function renderChart(queryResults: InternalOverviewStat, statType: StatType) {
    const { data } = queryResults;
    const config: ILineChartConfig[] = [];

    switch (statType) {
      case StatType.Groups:
        config.push(
          {
            label: t('common:active'),
            dataKey: 'active_groups_count',
          },
          {
            label: t('common:inactive'),
            dataKey: 'inactive_groups_count',
          }
        );
        break;
      case StatType.Users:
        config.push(
          {
            label: t('common:active'),
            dataKey: 'daily_active_users',
          },
          {
            label: t('common:inactive'),
            dataKey: 'daily_inactive_users',
          }
        );
        break;
      case StatType.Posts:
        config.push(
          {
            label: t('common:branded'),
            dataKey: 'branded_posts',
          },
          {
            label: t('common:nonBranded'),
            dataKey: 'non_branded_posts',
          }
        );
        break;
      case StatType.Comments:
        config.push(
          {
            label: t('common:branded'),
            dataKey: 'branded_comments_count',
          },
          {
            label: t('common:nonBranded'),
            dataKey: 'non_branded_comments_count',
          }
        );
        break;
      case StatType.Reactions:
        config.push(
          {
            label: t('common:branded'),
            dataKey: 'branded_reactions_count',
          },
          {
            label: t('common:nonBranded'),
            dataKey: 'non_branded_reactions_count',
          }
        );
        break;
    }

    return <LineChart height={INTERNAL_CHART_HEIGHT} data={data} config={config} />;
  }

  return (
    <Fragment>
      <Typography paragraph variant="h5" color="textSecondary">
        {t('analytics:internal_overview_stats')}
      </Typography>
      <Paper>
        <Queries.InternalOverviewStats
          loader={
            <ChartLoader
              type="chartLine"
              height={INTERNAL_CHART_HEIGHT + TABBED_CHART_EXCESS_HEIGHT}
            />
          }
          startDate={startDate}
          endDate={endDate}
        >
          {internalStats => (
            <Fragment>
              {renderSummaryTabs(internalStats)}
              <Box sx={classes.tabbedChartWrapper}>
                {tab === StatType.Users && renderChart(internalStats, tab)}
                {tab === StatType.Groups && renderChart(internalStats, tab)}
                {tab === StatType.Posts && renderChart(internalStats, tab)}
                {tab === StatType.Comments && renderChart(internalStats, tab)}
                {tab === StatType.Reactions && renderChart(internalStats, tab)}
              </Box>
            </Fragment>
          )}
        </Queries.InternalOverviewStats>
        <Divider />
        <DatePickers.Range
          dateRange={dateRange}
          startDate={startDate}
          endDate={endDate}
          onSetStart={setStartDate}
          onSetEnd={setEndDate}
          onSetRange={setDateRange}
          transparent
        />
      </Paper>
    </Fragment>
  );
};

export default InternalOverviewStats;
