import React, { FC } from 'react';

import { PaddedPaper } from 'components';

const styles = {
  container: {
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
} as const;

const TabBody: FC = ({ children }) => {
  return <PaddedPaper sx={styles.container}>{children}</PaddedPaper>;
};

export default TabBody;
