// Extend Yup
import * as yup from 'yup';
import { EMPTY_SLATE_EDITOR } from '../app-constants';
import { isNullOrUndefined, pluralize } from 'utilities';

yup.addMethod(yup.string, 'hasHtml', function (message) {
  const emptyHtml = EMPTY_SLATE_EDITOR;
  // @ts-ignore
  return this.test('has-html', message, value => {
    return value !== emptyHtml;
  });
});

yup.addMethod(yup.string, 'minLowercase', function (length = 1, message) {
  const msg =
    message || '${path} must contain at least ${length} lowercase ' + pluralize('letter', length);
  // @ts-ignore
  return this.test({
    name: 'minLowercase',
    exclusive: true,
    message: msg,
    params: { length },
    test(value: any) {
      return isNullOrUndefined(value) || (value.match(/[a-z]/g) || []).length >= length;
    },
  });
});

yup.addMethod(yup.string, 'minUppercase', function (length = 1, message) {
  const msg =
    message || '${path} must contain at least ${length} uppercase ' + pluralize('letter', length);
  // @ts-ignore
  return this.test({
    name: 'minUppercase',
    exclusive: true,
    message: msg,
    params: { length },
    test(value: any) {
      return isNullOrUndefined(value) || (value.match(/[A-Z]/g) || []).length >= length;
    },
  });
});

yup.addMethod(yup.string, 'minNumbers', function (length = 1, message) {
  const msg = message || '${path} must contain at least ${length} ' + pluralize('number', length);
  // @ts-ignore
  return this.test({
    name: 'minNumbers',
    exclusive: true,
    message: msg,
    params: { length },
    test(value: any) {
      return isNullOrUndefined(value) || (value.match(/[0-9]/g) || []).length >= length;
    },
  });
});

yup.addMethod(yup.string, 'minSymbols', function (length = 1, message) {
  const msg = message || '${path} must contain at least ${length} ' + pluralize('symbol', length);
  // @ts-ignore
  return this.test({
    name: 'minSymbols',
    exclusive: true,
    message: msg,
    params: { length },
    test(value: any) {
      return (
        isNullOrUndefined(value) ||
        (value.match(/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/g) || []).length >= length
      );
    },
  });
});

yup.addMethod(yup.number, 'noWhitespace', function () {
  return this.transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value));
});

yup.addMethod(yup.string, 'isMatchingSubdomain', function (subdomains: string[]) {
  // @ts-ignore
  return this.test({
    name: 'isMatchingSubdomain',
    exclusive: true,
    message: `Email must be from one of the following subdomains: ${subdomains.join(', ')}`,
    params: { subdomains },
    test(value: string) {
      if (!value) return false;
      const domain = value.split('@')[1];
      return domain && subdomains.some(subdomain => domain === subdomain);
    },
  });
});
