import React, { FC } from 'react';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';

import { Content, Network } from 'api';
import { Capability, ValidatedCheckbox } from 'components';
import { useNetworkCapabilities } from 'hooks';

import Widget from './WidgetContainer';
import { ShareCopy, Switch } from '../Partials';
import { usePostContext } from '../../PostContext';

interface Props {
  disabled?: boolean;
}

const ShareCopyWidget: FC<Props> = ({ disabled }) => {
  const { state, dispatch } = usePostContext();
  const { values, setFieldValue } = useFormikContext<Content>();
  const { hasTwitter, hasLinkedin } = useNetworkCapabilities();

  function onChangeSwitch() {
    if (state.commentary) {
      setFieldValue('lock_commentary', false);
    }

    dispatch({ type: 'commentary' });
  }

  function renderBody() {
    return (
      <FieldArray name="commentary">
        {arrayHelpers => (
          <Grid container marginTop={2}>
            <Grid xs={1} />
            <Grid xs={11} container>
              {hasTwitter && (
                <Grid xs={12}>
                  <ShareCopy network={Network.Twitter} {...arrayHelpers} disabled={disabled} />
                </Grid>
              )}
              {hasLinkedin && (
                <Grid xs={12}>
                  <ShareCopy network={Network.Linkedin} {...arrayHelpers} disabled={disabled} />
                </Grid>
              )}
              <Capability name="Sharing.Function.ShareToFacebook">
                <Grid xs={12}>
                  <ShareCopy network={Network.Facebook} {...arrayHelpers} disabled={disabled} />
                </Grid>
              </Capability>
              <Capability name="Sharing.Function.ShareToInstagram">
                <Grid xs={12}>
                  <ShareCopy network={Network.Instagram} {...arrayHelpers} disabled={disabled} />
                </Grid>
              </Capability>
              <Capability name="Post.Create.Function.RequiredShareCopy">
                <Grid xs={12} container alignItems="center">
                  <Grid xs={1} />
                  <Grid>
                    <ValidatedCheckbox
                      name="lock_commentary"
                      fullWidth
                      i18nKey="posting:lockCommentary"
                      color="primary"
                      onChange={event => {
                        setFieldValue('lock_commentary', !event.target.checked);
                      }}
                      checkedFn={() => !values.lock_commentary}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              </Capability>
            </Grid>
          </Grid>
        )}
      </FieldArray>
    );
  }

  return (
    <Widget
      disabled={disabled}
      titleKey="posting:commentaryTitle"
      subtitleKey="posting:guidelineCommentary"
      icon={
        <PostAddRoundedIcon
          color={disabled ? 'disabled' : state.commentary ? 'inherit' : 'disabled'}
        />
      }
      toggle={
        <Switch
          checked={state.commentary}
          onChange={onChangeSwitch}
          color="primary"
          data-testid="post-radio-shareCopy"
          disabled={disabled}
        />
      }
    >
      {state.commentary && renderBody()}
    </Widget>
  );
};

export default ShareCopyWidget;
