import React, { ChangeEvent, FC } from 'react';
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import Setting from './Setting';
import { ClientInput } from '../../types';

const PublicSharing: FC = () => {
  const { setFieldValue } = useFormikContext<ClientInput>();

  return (
    <Grid container>
      <Grid xs={3}>
        <Typography variant="subtitle2">Public Sharing</Typography>
      </Grid>
      <Grid xs>
        <Grid container direction="column" wrap="nowrap" spacing={2}>
          <Grid>
            <Setting
              value="public_post_page"
              title="Public Post Page"
              description="allow content pages to be viewable to anonymous visitors"
              onChange={(event: ChangeEvent<HTMLInputElement>, value: boolean) => {
                setFieldValue('public_post_page', value);
              }}
            />
          </Grid>
          <Grid>
            <Setting
              value="public_post_details"
              title="Public Post Details"
              description="show details such as user information and user written content"
              onChange={(event: ChangeEvent<HTMLInputElement>, value: boolean) => {
                setFieldValue('public_post_details', value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PublicSharing;
