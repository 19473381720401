import React, { FC } from 'react';
import { Avatar, Box, Button, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getImageSrc } from 'helpers';
import palette from 'theme/palette';
import { popup } from 'utilities';
import theme from 'theme';
import { usePublicPostContext } from 'features/content/PublicPostContext';

const styles = {
  portal: {
    zIndex: 1500,
    borderRadius: theme.spacing(0.5),
    boxShadow: '0 1px 5px rgba(0,0,0,.2)',
    backgroundColor: 'common.white',
    height: '300px',
    width: '100%',
    overflowY: 'scroll',
  },
  portalNoResults: {
    top: '-9999px',
    left: '-9999px',
    zIndex: 1500,
    padding: theme.spacing(),
    boxShadow: '0 1px 5px rgba(0,0,0,.2)',
    backgroundColor: 'common.white',
  },
  mentionOption: {
    zIndex: 200,
    padding: theme.spacing(),
    background: 'common.white',
  },
  blueUnderlinedText: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: theme.custom.networks.Company,
    '&:hover': {
      color: theme.custom.networks.Company,
    },
  },
  selectedMentionOption: {
    zIndex: 200,
    padding: theme.spacing(),
    background: palette.blueLight,
    cursor: 'pointer',
  },
  mentionText: {
    paddingLeft: '5px',
  },
} as const;

interface Props {
  mentionState: any;
  setMentionState: any;
  loading: boolean;
  editor: any;
  insertMention: any;
  shouldPromptAuth: any;
  publicQuery: boolean;
}

const MentionsOptions: FC<Props> = ({
  mentionState,
  setMentionState,
  loading,
  editor,
  insertMention,
  shouldPromptAuth,
  publicQuery,
}) => {
  const { t } = useTranslation();
  const { state } = usePublicPostContext();
  const { content } = state;

  function handleMouseOver(currentIndex: number) {
    setMentionState({
      ...mentionState,
      index: currentIndex,
    });
  }

  function handleMouseClick() {
    if (mentionState.target && mentionState.results[mentionState.index]) {
      insertMention(editor, mentionState.results[mentionState.index], true);
    }
  }

  function handleInlineAuth() {
    publicQuery
      ? popup(`connect/linkedin/${content.content_id}`, 'Connection Popup', 650, 650)
      : (window.location.href = '/account');
  }

  function renderContent() {
    if (shouldPromptAuth) {
      return (
        <Grid container alignItems="center" sx={styles.mentionOption}>
          <Grid>
            <Button sx={styles.blueUnderlinedText} onClick={handleInlineAuth}>
              {t('sharing:mentionsPleaseAuth')}
            </Button>
          </Grid>
        </Grid>
      );
    }

    if (loading) {
      return (
        <Grid container alignItems="center" sx={styles.mentionOption}>
          <Grid>{t('sharing:mentionsLoading')}</Grid>
        </Grid>
      );
    }

    if (!loading && !mentionState.results.length) {
      return (
        <Grid container alignItems="center" sx={styles.mentionOption}>
          <Grid>{t('sharing:mentionsNoResults')}</Grid>
        </Grid>
      );
    }

    if (!loading && mentionState.results.length) {
      return mentionState.results.map((result: any, i: any) => {
        return (
          <Grid
            container
            alignItems="center"
            key={result.identifier}
            onMouseOver={() => handleMouseOver(i)}
            onClick={handleMouseClick}
            sx={i === mentionState.index ? styles.selectedMentionOption : styles.mentionOption}
          >
            <Grid>
              <Avatar {...getImageSrc(result?.logo)} />
            </Grid>
            <Grid sx={styles.mentionText}>{result?.name}</Grid>
          </Grid>
        );
      });
    }
  }

  if (!loading && mentionState.target === null) {
    return null;
  }

  return (
    <Box
      sx={
        loading || !mentionState.results.length || shouldPromptAuth
          ? styles.portalNoResults
          : styles.portal
      }
    >
      {renderContent()}
    </Box>
  );
};

export default MentionsOptions;
