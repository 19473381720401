import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Unstable_Grid2 as Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { isBoolean } from 'lodash';

import { Content, ContentInput } from 'api';
import { Loaders } from 'components';
import { useFollowed, useGlobalClient } from 'hooks';
import { getPostableGroupsToApprove } from 'features/content/helpers';

import { PostSchema } from '../../validations';
import { usePostContext } from '../../PostContext';
import { getClientCommentCharLimit } from 'features/posting/helpers';
import { useUploadCount } from '../ImageVideo/UploadCountProvider';

const FormButtons: FC = () => {
  const { t } = useTranslation();
  const { state } = usePostContext();
  const { postable } = useFollowed();

  const navigate = useNavigate();
  const { dirty, values, isSubmitting, setFieldValue, touched, initialValues } =
    useFormikContext<ContentInput>();
  const groupsToApprove = getPostableGroupsToApprove(initialValues, postable.list);
  const client = useGlobalClient();

  const invalidCommentary = !!values.commentary.find(c => {
    const limit = getClientCommentCharLimit(c.network, values as Content, client);
    return (c.commentary || '').length > limit;
  });
  const { uploadCount } = useUploadCount();
  const isValid =
    !invalidCommentary &&
    PostSchema.isValidSync(values, {
      uiState: state,
    });

  let disabled = !isValid || !dirty || !!uploadCount || isSubmitting;
  if (isBoolean(touched['content_object']?.['photos'])) {
    if (isValid) {
      disabled = false;
    }
  }

  /**
   * We are coercing group_ids in various places within the GroupDropdown and the SingleGroupDropdown Components.
   * This makes it easier to work in those components especially since they share the same Formik
   * context. For now we must add back the primary group id that we omitted to add in the SingleGroupDropdown
   * context. This ensures a predictable handshake between client/server.
   */
  if (isSubmitting && values.primary_group_id) {
    const hasPrimaryGroupId = values.group_ids.find(group => group === values.primary_group_id);
    !hasPrimaryGroupId &&
      setFieldValue('group_ids', [values.primary_group_id, ...values.group_ids]);
  }

  function getSaveText() {
    if (!values.content_id) {
      return t('common:post');
    }

    /**
     * TODO: need to find a way to decide if the content is a RSS feed
     * TODO: primary_group_id won't work in this case since we set fallback
     **/
    if (groupsToApprove.length) {
      return t('content:saveAndApprove');
    }

    return t('common:save');
  }

  return (
    <Grid container justifyContent="flex-end" marginTop={2}>
      <Grid>
        <Button variant="text" size="large" onClick={() => navigate(-1)}>
          {t('common:cancel')}
        </Button>
      </Grid>
      <Grid xs="auto">
        <Button
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          disabled={disabled}
          type="submit"
        >
          {isSubmitting || !!uploadCount ? <Loaders.Standard height={26} /> : getSaveText()}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormButtons;
