import useInfiniteScroll from 'react-infinite-scroll-hook';
import { UseInfiniteQueryOptions } from 'react-query';
import { useInfiniteQuery } from 'app-react-query/hooks';

import { InfiniteQuery } from 'types/app';
import { useNotify } from 'hooks';
import {
  ExternalShare,
  ExternalSharesQuery,
  ExternalSharesQueryVariables,
  useExternalSharesQuery,
} from 'api';

type Query = ExternalSharesQuery;
type Variables = ExternalSharesQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = InfiniteQuery<ExternalShare>;

const useGetExternalShares = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();

  const queryResult = useInfiniteQuery<Query, Variables>(
    useExternalSharesQuery,
    () => ({ ...variables }),
    {
      initialData: {
        pages: [{ externalShares: { results: [], page: null, per_page: 0 } }],
        pageParams: [],
      },
      onError: notify.queryError,
      getNextPageParam: lastPage => {
        const { externalShares } = lastPage;
        const { results, page } = externalShares;
        const endOfList = !page;
        const noResults = !results.length;

        if (noResults || endOfList) {
          return false;
        }

        return { page };
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = queryResult;
  const results = data?.pages?.map(page => page.externalShares.results).flat() ?? [];
  const totalCount = data?.pages?.[0]?.externalShares.results.length ?? 0;

  const [sentinelRef] = useInfiniteScroll({
    hasNextPage,
    loading: isFetchingNextPage,
    onLoadMore: fetchNextPage,
  });

  return {
    sentinelRef,
    results,
    count: totalCount,
    isEmpty: !results.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !results.length && isFetching,
  };
};

export default useGetExternalShares;
