import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import { CustomSVG } from 'components';
import useStyles from '../analyticsStyles';
import { Box } from '@mui/material';

interface Props {
  height: number;
  type: 'chartArea' | 'chartLine';
}

const ChartLoader: FC<Props> = ({ height, type }) => {
  const classes = useStyles;
  const theme = useTheme();
  const fontSize = theme.typography.pxToRem(height / 4);

  return (
    <Box style={{ height }} sx={classes.loadingWrapper}>
      <CustomSVG style={{ fontSize }} sx={classes.loading} name={type} />
    </Box>
  );
};

export default ChartLoader;
