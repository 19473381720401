import { UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  CreateGroupModeratorMutation,
  CreateGroupModeratorMutationVariables,
  GetGroupModeratorsQuery,
  GroupModeratorFragment,
  useGetGroupModeratorsQuery,
} from 'api';
import { useCurrentUser, useNotify } from 'hooks';

type Mutation = CreateGroupModeratorMutation;
type MutationVariables = CreateGroupModeratorMutationVariables;
type QueryData = GetGroupModeratorsQuery;

const useCreateModerator = () => {
  const notify = useNotify();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async ({ group_id, user_id }) => {
      const queryKey = useGetGroupModeratorsQuery.getKey({ group_id });
      await queryClient.cancelQueries(queryKey);

      const newModerator: GroupModeratorFragment = {
        group_id,
        user_id,
        created_at: Date.now(),
        added_by_user_id: currentUser.id,
        user: null,
      };

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.getModeratorsByGroup.push(newModerator);
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },

    onError: (error, { group_id }, baseState: QueryData) => {
      const queryKey = useGetGroupModeratorsQuery.getKey({ group_id });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }

      notify.mutationError();
    },
    onSettled: ({ createGroupModerator }, error, { group_id, user_id }) => {
      const queryKey = useGetGroupModeratorsQuery.getKey({ group_id });

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const index = draftState.getModeratorsByGroup.findIndex(moderator => {
            return moderator.user_id === user_id;
          });
          draftState.getModeratorsByGroup[index] = createGroupModerator;
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }
    },
  };

  return mutationOptions;
};

export default useCreateModerator;
