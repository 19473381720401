import React, { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';

import { useContent } from 'hooks';
import { CustomSVG } from 'components';
import theme from 'theme';

const styles = {
  button: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  icon: {
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.grey[600],
    },
  },
  shared: {
    color: 'primary.main',
    '&:hover': {
      color: 'primary.dark,',
    },
  },
  text: {
    lineHeight: 'inherit',
  },
} as const;

const ShareCountIcon: FC = () => {
  const { share_count } = useContent();

  return (
    <IconButton sx={styles.button}>
      <CustomSVG name="share" size={24} sx={styles.icon} />
      <Box marginLeft={0.5}>
        <Typography sx={styles.text} variant="subtitle2">
          {share_count}
        </Typography>
      </Box>
    </IconButton>
  );
};

export default ShareCountIcon;
