import { ComponentType } from 'react';

import * as paths from 'paths';
import * as LayoutComponents from './Components';
import * as PublicLayoutComponents from '../public/Components';

interface MainRoute {
  layoutPaths: string[];
  component: ComponentType;
}

export const mainRoutes: MainRoute[] = [
  { layoutPaths: [paths.index], component: LayoutComponents.TimelineLayout },
  { layoutPaths: [paths.account], component: LayoutComponents.AccountLayout },
  { layoutPaths: [paths.network], component: LayoutComponents.NetworkLayout },
  { layoutPaths: [paths.activity], component: LayoutComponents.ActivityLayout },
  { layoutPaths: [paths.connectionSuccess], component: LayoutComponents.ConnectionsSuccess },
  { layoutPaths: [paths.connectionFailure], component: LayoutComponents.ConnectionsFailure },
  {
    layoutPaths: [paths.content, paths.contentLike],
    component: LayoutComponents.ContentLayout,
  },
  {
    layoutPaths: [paths.contentShare],
    component: LayoutComponents.ContentShareLayout,
  },
  { layoutPaths: [paths.groupCreate], component: LayoutComponents.CreateGroupLayout },
  { layoutPaths: [paths.search], component: LayoutComponents.SearchLayout },
  { layoutPaths: [paths.groupEdit], component: LayoutComponents.EditGroupLayout },
  { layoutPaths: [paths.notifications], component: LayoutComponents.NotificationsLayout },
  {
    layoutPaths: [paths.leaderboard, paths.leaderboardView],
    component: LayoutComponents.LeaderboardLayout,
  },
  {
    layoutPaths: [
      paths.group,
      paths.groupMembers,
      paths.groupApproval,
      paths.groupScheduled,
      paths.groupRemoved,
    ],
    component: LayoutComponents.GroupViewLayout,
  },
  {
    layoutPaths: [paths.profile, paths.profileGroups],
    component: LayoutComponents.ProfileLayout,
  },
  {
    layoutPaths: [
      paths.groups,
      paths.groupsDepartment,
      paths.groupsLocation,
      paths.groupsModerating,
      paths.groupsIOwn,
      paths.groupsFollowing,
      paths.groupsAll,
    ],
    component: LayoutComponents.GroupsIndexLayout,
  },
  {
    layoutPaths: [paths.team, paths.teamEveryone, paths.teamDepartment, paths.teamLocation],
    component: LayoutComponents.TeamLayout,
  },
  {
    layoutPaths: [paths.insights, paths.insightsCompanies, paths.insightsPeople],
    component: LayoutComponents.OrganicInsightsLayout,
  },
  {
    layoutPaths: [
      paths.adminAnalytics,
      paths.adminAnalyticsOverview,
      paths.adminAnalyticsGroups,
      paths.adminAnalyticsUsers,
      paths.adminAnalyticsPosts,
      paths.adminAnalyticsShares,
      paths.adminAnalyticsLeaderboards,
      paths.adminPostManagement,
      paths.adminUserManagement,
      paths.adminInvite,
      paths.adminGroups,
      paths.adminInvitations,
      paths.adminMessages,
      paths.adminLeaderboards,
      paths.adminLeaderboardsCreate,
      paths.adminLeaderboardsEdit,
      paths.adminContentEmails,
      paths.adminContentEmailCreate,
      paths.adminContentEmailEdit,
      paths.adminSettings,
      paths.adminSettingsAccount,
      paths.adminSettingsBlocklist,
      paths.adminSettingsLocations,
      paths.adminSettingsCompanyPages,
      paths.adminSettingsDisclosures,
      paths.adminSettingsPolicies,
      paths.adminSettingsReferrals,
      paths.adminSettingsIntegrations,
      paths.adminSettingsAssignments,
      paths.adminSettingsAssignmentsCreate,
      paths.adminSettingsAssignmentsEdit,
      paths.adminSettingsShareTracking,
      paths.adminSettingsUserSegments,
      paths.adminSettingsSharing,
      paths.adminSettingsStickers,
      paths.adminUpgrade,
      paths.adminBilling,
    ],
    component: LayoutComponents.AdminLayout,
  },
  {
    layoutPaths: [paths.postCreate, paths.postEdit],
    component: LayoutComponents.PostLayout,
  },
  {
    layoutPaths: [paths.share],
    component: LayoutComponents.ExternalShareLayout,
  },
  {
    layoutPaths: [paths.unsubscribe],
    component: PublicLayoutComponents.UnsubscribeLayout,
  },
  {
    layoutPaths: [paths.setupProfile],
    component: LayoutComponents.SetupProfileLayout,
  },
  {
    layoutPaths: [paths.setupWorkspace],
    component: LayoutComponents.SetupWorkspaceLayout,
  },
  {
    layoutPaths: [paths.setupWorkspaceGroups],
    component: LayoutComponents.SetupWorkspaceGroupsLayout,
  },
  {
    layoutPaths: [paths.setupWorkspaceInvites],
    component: LayoutComponents.SetupWorkspaceInvitesLayout,
  },
];
