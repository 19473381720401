import { UseMutationOptions, useQueryClient } from 'react-query';

import {
  BootstrapQuery,
  DeleteSocialConnectionMutation,
  DeleteSocialConnectionMutationVariables,
} from 'api';
import { useBootstrapQueryKey, useNotify } from 'hooks';
import produce from 'immer';

type QueryData = BootstrapQuery;
type Mutation = DeleteSocialConnectionMutation;
type MutationVariables = DeleteSocialConnectionMutationVariables;

const useDeleteSocialConnection = () => {
  const queryClient = useQueryClient();
  const queryKey = useBootstrapQueryKey();
  const notify = useNotify();

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: ({ id }) => {
      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const { bootstrap } = draftState;
          bootstrap.user_social_accounts = bootstrap.user_social_accounts.filter(
            social => social.id !== id
          );
        });

      if (baseState) {
        queryClient.setQueryData(queryKey, nextState);
      }

      return baseState;
    },
    onError: () => {
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useDeleteSocialConnection;
