import React, { FC, useEffect } from 'react';

import { CustomSVG } from 'components';

import { usePostContext } from 'features/posting/PostContext';
import Widget from './WidgetContainer';
import { Switch } from '../';

const Sharing: FC = () => {
  const { state, dispatch } = usePostContext();
  const { requiresApproval, showShareWidget } = state;

  useEffect(() => {
    if (requiresApproval) {
      dispatch({ type: 'share', value: false });
    }
  }, [requiresApproval]);

  if (!showShareWidget) {
    return null;
  }

  function onChangeSwitch() {
    dispatch({ type: 'share', value: !state.share });
  }

  return (
    <Widget
      titleKey="posting:shareSocialNetwork"
      subtitleKey="posting:guidelineNetwork"
      icon={<CustomSVG name="share" color={state.share ? 'inherit' : 'disabled'} />}
      toggle={
        <Switch
          checked={state.share}
          onChange={onChangeSwitch}
          color="primary"
          data-testid="post-radio-sharing"
        />
      }
    />
  );
};

export default Sharing;
