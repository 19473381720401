import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useContent, useCurrentUser, useGlobalClient } from 'hooks';
import { SocialButton } from 'components/Buttons';
import { Events } from 'analytics';
import { ReactionType, useAddReactionAnalyticsMutation } from 'api';

import { ExternalLink } from './index';
import { getNetwork } from '../helpers';

const Engage: FC = () => {
  const content = useContent();
  const currentUser = useCurrentUser();
  const client = useGlobalClient();
  const { t } = useTranslation();

  const network = getNetwork(content);
  const networkLowercase = network.toLowerCase();
  const { content_id } = content;

  const { mutate: addReactionAnalytics } = useAddReactionAnalyticsMutation();

  if (!content.is_engageable) {
    return null;
  }

  function onClickSocialButton() {
    handleTracking();

    addReactionAnalytics({
      content_id,
      reaction_type: ReactionType.Engagement,
    });
  }

  function handleTracking() {
    const searchParams = new URLSearchParams(location.search);

    const properties = {
      network: networkLowercase,
      client_id: client.id,
      content_id: content.content_id,
      user_id: currentUser.id,
      is_branded: content.is_branded,
      linkedin: networkLowercase === 'linkedin',
      twitter: networkLowercase === 'twitter',
      facebook: networkLowercase === 'facebook',
      instagram: networkLowercase === 'instagram',
      youtube: networkLowercase === 'youtube',
      reddit: networkLowercase === 'reddit',
      tiktok: networkLowercase === 'tiktok',
      weibo: networkLowercase === 'weibo',
      wechat: networkLowercase === 'wechat',
      referral_source: searchParams.get('es_source'),
    };

    window?.analytics?.track(Events.ClickEngage, properties);
  }

  return (
    <ExternalLink>
      <SocialButton
        onClick={onClickSocialButton}
        network={networkLowercase}
        text={t(`common:engage`)}
      />
    </ExternalLink>
  );
};

export default Engage;
