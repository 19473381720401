import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { DailyGroupStat, ReportingFilter } from 'api';
import { ConditionalNumberTooltip, PaddedPaper, Queries } from 'components';
import { formatStat } from 'helpers';

import { Change, PieChart, PieChartLoader } from '../shared';
import { PIE_CHART_HEIGHT } from '../constants';

interface Props {
  startDate: string;
  endDate: string;
  filter: ReportingFilter;
}

const ActiveGroupStatsChart: FC<Props> = ({ startDate, endDate, filter }) => {
  const { t } = useTranslation();

  function renderChart(queryResults: DailyGroupStat) {
    const { summary } = queryResults;
    const { change, active_groups, inactive_groups } = summary;
    const count = active_groups + inactive_groups;

    const data = [
      { name: t('common:active'), value: active_groups },
      { name: t('common:inactive'), value: inactive_groups },
    ];

    return (
      <Fragment>
        <ConditionalNumberTooltip value={count}>
          <Typography variant="h2">{formatStat(count)}</Typography>
        </ConditionalNumberTooltip>
        <Change countChange={change} />
        <PieChart height={PIE_CHART_HEIGHT} data={data} />
      </Fragment>
    );
  }

  return (
    <PaddedPaper padding={16}>
      <Typography variant="body2">{t('analytics:total_groups')}</Typography>
      <Queries.AnalyticsDailyStats
        type="dailyGroupStats"
        loader={<PieChartLoader />}
        filter={filter}
        startDate={startDate}
        endDate={endDate}
      >
        {stats => renderChart(stats)}
      </Queries.AnalyticsDailyStats>
    </PaddedPaper>
  );
};

export default ActiveGroupStatsChart;
