import { useQueryClient, UseMutationOptions } from 'react-query';
import produce from 'immer';

import {
  UpdateUserProfileMutation,
  UpdateUserProfileOnboardingMutation,
  UpdateUserProfileMutationVariables,
  UpdateUserProfileOnboardingMutationVariables,
  BootstrapQuery,
} from 'api';
import { useBootstrapQueryKey, useNotify } from 'hooks';

type Mutation = UpdateUserProfileMutation | UpdateUserProfileOnboardingMutation;
type MutationVariables =
  | UpdateUserProfileMutationVariables
  | UpdateUserProfileOnboardingMutationVariables;
type QueryData = BootstrapQuery;

const useUpdateCurrentUser = <
  TMutation extends Mutation,
  TVariables extends MutationVariables
>() => {
  const queryClient = useQueryClient();
  const notify = useNotify();
  const queryKey = useBootstrapQueryKey();

  const mutationOptions: UseMutationOptions<TMutation, unknown, TVariables> = {
    onMutate: ({ data }) => {
      const baseState = queryClient.getQueryData<QueryData>(queryKey);

      const nextState =
        baseState &&
        produce(baseState, draftState => {
          const { bootstrap } = draftState;
          bootstrap.user = { ...bootstrap.user, ...data };
        });

      if (baseState) {
        queryClient.setQueryData(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useUpdateCurrentUser;
