import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { IconButton, Portal, Snackbar, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { INotify, INotifyTypes } from 'types/app';
import { DEFAULT_NOTIFY_OPTIONS } from 'app-constants';
import { USER_DISABLED } from 'features/auth/constants';
import eventManager, { Event } from 'event-manager';
import * as paths from 'paths';
import { mixins } from '../theme/mixins';
import theme from 'theme';

const styles = {
  contentRoot: {
    top: mixins.toolbar.minHeight,
  },
  info: {
    backgroundColor: 'common.white',
    borderLeft: `8px solid ${theme.palette.primary.main}`,
  },
  error: {
    backgroundColor: 'common.white',
    borderLeft: `8px solid ${theme.palette.error.main}`,
  },
  message: mixins.breakWord,
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  incident: {
    marginTop: 1,
  },
} as const;

const Notify: FC = () => {
  const { t } = useTranslation();
  const [notifyState, setNotifyState] = useState<INotify>(DEFAULT_NOTIFY_OPTIONS);

  const { type, i18n, message, component, show, duration } = notifyState;
  const isError =
    type === INotifyTypes.Error ||
    type === INotifyTypes.QueryError ||
    type === INotifyTypes.MutationError ||
    type === INotifyTypes.SubscriptionError;
  const hasMessage = !!message || !!component || !!i18n || isError;

  const rootstyles = {
    root: styles.contentRoot,
    ...(isError && styles.error),
    ...(type === 'info' && styles.info),
  };

  useEffect(() => {
    eventManager.on(Event.Show, options => {
      if (message !== USER_DISABLED) {
        setNotifyState(options);
      }
    });
  }, []);

  function handleClose() {
    setNotifyState({
      ...notifyState,
      show: false,
    });
  }

  function getSnackbarProps() {
    if (isError) {
      return {};
    }

    return {
      autoHideDuration: duration,
      onClose: handleClose,
    };
  }

  function renderMessage(children: ReactNode | string) {
    return (
      <Grid container alignItems="center">
        <Grid xs>
          <Typography variant="body2" sx={styles.message}>
            {children}
          </Typography>
          {isError && (
            <Typography variant="body2" sx={styles.incident}>
              {t('errors:incident')}
            </Typography>
          )}
        </Grid>
        {isError && (
          <Grid>
            <IconButton key="close" color="inherit" onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  }

  function renderQueryError() {
    return (
      <Trans i18nKey="errors:query">
        Oh snap! We’ve encountered an error on this page. We’ve been notified and will investigate.
      </Trans>
    );
  }

  function renderMutationError() {
    return (
      <Trans i18nKey="errors:mutation">
        Well this is awkward. Something went wrong during your request. We’ve been notified and will
        investigate.
      </Trans>
    );
  }

  function renderNotify() {
    if (type === INotifyTypes.QueryError) {
      return renderMessage(renderQueryError());
    }

    if (type === INotifyTypes.MutationError) {
      return renderMessage(renderMutationError());
    }

    if (type === INotifyTypes.SubscriptionError) {
      return renderMessage(<Trans i18nKey="errors:subscription" />);
    }

    if (i18n) {
      return renderMessage(<Trans {...i18n} />);
    }

    if (message) {
      return renderMessage(String(message));
    }

    if (component) {
      return component;
    }

    return null;
  }

  if (message === USER_DISABLED) {
    sessionStorage.setItem('authError', 'user_profile_suspended');
    return <Navigate to={paths.authError} replace />;
  }

  return (
    <Portal>
      <Snackbar
        open={hasMessage && show}
        ContentProps={{ sx: rootstyles }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={renderNotify()}
        {...getSnackbarProps()}
      />
    </Portal>
  );
};

export default Notify;
