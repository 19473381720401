import React, { ChangeEvent, FC, HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { find, map, pull } from 'lodash';
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Checkbox,
  Chip,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';

import { SegmentStatus, useGetUserSegmentsQuery, UserSegmentFragment } from 'api';
import { useNotify } from 'hooks';
import { PickerInput, PopupIcon } from './components';
import useAutoCompleteStyles from './styles/useAutoCompleteStyles';

type CustomProps<T> = Omit<
  AutocompleteProps<T, boolean, boolean, boolean>,
  'renderInput' | 'options'
>;
type SegmentIds = Array<UserSegmentFragment['id']>;
type Options = Array<Partial<UserSegmentFragment>>;

interface IProps extends CustomProps<UserSegmentFragment> {
  hideCompany?: boolean;
  isCompanySelected?: boolean;
  isOnboardedUsers?: boolean;
  selectedSegmentsIds: SegmentIds;
  onSegmentChange: (segmentIds: SegmentIds, entireCompany: boolean) => void;
  onLoadSegments?: (segments: UserSegmentFragment[]) => void;
}

const COMPANY_ID = 'all_users';

const SegmentPicker: FC<IProps> = props => {
  const {
    isCompanySelected,
    isOnboardedUsers,
    selectedSegmentsIds,
    onSegmentChange,
    onLoadSegments,
    hideCompany,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  const notify = useNotify();
  const autocompleteClasses = useAutoCompleteStyles;

  const { isLoading, data } = useGetUserSegmentsQuery(
    { segment_status: SegmentStatus.Active },
    {
      onError: notify.queryError,
    }
  );

  useEffect(() => {
    if (data && onLoadSegments) {
      onLoadSegments(data.getUserSegments);
    }
  }, [isLoading]);

  const companySegment = {
    id: COMPANY_ID,
    title: isOnboardedUsers ? t('components:allActiveUsers') : t('components:registeredUsers'),
  };

  const options = getOptions();
  const selectedOptions = getSelectedOptions();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function getOptions(): Options {
    if (!data || !data.getUserSegments) {
      return [];
    }

    if (hideCompany) {
      return data.getUserSegments;
    }

    return [companySegment, ...data.getUserSegments];
  }

  function getSelectedOptions() {
    const selectedSegments = selectedSegmentsIds.reduce((list, id) => {
      const segment = find(options, ['id', id]);
      if (!segment) {
        return list.concat([]);
      }
      return list.concat([segment]);
    }, []);

    if (isCompanySelected) {
      return [companySegment, ...selectedSegments];
    }

    return selectedSegments;
  }

  function toggleOpen() {
    setOpen(!open);
  }

  function handleChange(event: ChangeEvent, nextValue: Options) {
    const ids = map(nextValue, 'id');
    const entireCompany = ids.includes(COMPANY_ID);
    setInputValue('');
    onSegmentChange(pull(ids, COMPANY_ID), entireCompany);
  }

  function renderTags(value: Options, getTagProps: AutocompleteGetTagProps) {
    return value.map((segment, index) => (
      <Chip
        key={segment.id}
        label={segment.title}
        deleteIcon={<CloseIcon />}
        {...getTagProps({ index })}
      />
    ));
  }

  function renderOption(
    props: HTMLAttributes<HTMLLIElement>,
    option: UserSegmentFragment,
    { selected }: AutocompleteRenderOptionState
  ) {
    return (
      <Grid component="li" container alignItems="center" {...props}>
        <Grid xs>{option.title}</Grid>
        <Grid>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
        </Grid>
      </Grid>
    );
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <PickerInput
        working={isLoading}
        placeholder="components:segmentSuggest"
        onChange={setInputValue}
        params={params}
      />
    );
  }

  return (
    <Autocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      open={open}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      sx={autocompleteClasses}
      options={options}
      loading={isLoading}
      onChange={handleChange}
      getOptionLabel={({ title }) => title}
      popupIcon={<PopupIcon open={open} />}
      renderTags={renderTags}
      renderOption={renderOption}
      renderInput={renderInput}
      value={selectedOptions}
      inputValue={inputValue}
      {...rest}
    />
  );
};

SegmentPicker.defaultProps = {
  hideCompany: false,
  isCompanySelected: false,
  isOnboardedUsers: false,
};

export default SegmentPicker;
