import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link, Paper, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { PublicUserProfileFragment } from 'api';
import { getFullName } from 'helpers';
import { generateLink } from 'Links';
import { UserAvatar } from 'components';

interface IProps {
  user: PublicUserProfileFragment;
}

const styles = {
  paper: {
    padding: 2,
    // minHeight: 230,
  },
  icon: {
    color: 'grey.500',
  },
} as const;

const UserCard: FC<IProps> = ({ user }) => {
  const { t } = useTranslation();

  function renderProfileButton() {
    return (
      <Button component={generateLink(`/profile/${user.id}`)} fullWidth variant="outlined">
        {t('components:viewProfile')}
      </Button>
    );
  }

  return (
    <Paper sx={styles.paper}>
      <Grid container alignItems="center" direction="column">
        <Grid marginBottom={2}>
          <UserAvatar link user={user} size={100} />
        </Grid>
        <Grid xs={12} container justifyContent="center">
          <Box textOverflow="ellipsis" whiteSpace="noWrap" overflow="hidden">
            <Link
              title={getFullName(user)}
              component={generateLink(`/profile/${user.id}`)}
              variant="subtitle2"
              underline="hover"
            >
              {getFullName(user)}
            </Link>
          </Box>
        </Grid>
        <Grid xs={12} container justifyContent="center" marginBottom={user.job_title ? 0 : 2.5}>
          <Box textOverflow="ellipsis" whiteSpace="noWrap" overflow="hidden">
            <Typography title={user.job_title} paragraph noWrap>
              {user.job_title}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} container alignItems="flex-end">
          {renderProfileButton()}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserCard;
