import React, { FC } from 'react';
import { Link } from '@mui/material';
import { Trans } from 'react-i18next';

import { ClientProtectedFragment } from 'api';
import { ClientPublicFragment } from 'api/public';
import { ValidatedCheckbox } from 'components';
import { SocialMediaPolicy } from 'Links';
import { pxToRem } from 'theme';

const styles = {
  labelRoot: {
    marginTop: 12,
  },
  label: {
    lineHeight: '1rem',
    fontSize: pxToRem(13),
  },
} as const;

interface Props {
  client: ClientProtectedFragment | ClientPublicFragment;
}

const Terms: FC<Props> = ({ client }) => {
  const { social_media_policy_title, social_media_policy_enabled } = client;

  function renderSocialMediaPolicyLink() {
    return (
      <Link component={SocialMediaPolicy} color="primary" target="_blank" underline="hover">
        {social_media_policy_title}
      </Link>
    );
  }

  function renderCookieLink() {
    return (
      <Link
        color="primary"
        href="https://everyonesocial.com/cookie-policy/"
        target="_blank"
        underline="hover"
      >
        Cookie Policy
      </Link>
    );
  }

  function renderTermsLink() {
    return (
      <Link
        color="primary"
        href="https://everyonesocial.com/user-terms-of-service/"
        target="_blank"
        underline="hover"
      >
        User Terms
      </Link>
    );
  }

  function renderPrivacyPolicyLink() {
    return (
      <Link
        color="primary"
        href="https://everyonesocial.com/privacy-policy/"
        target="_blank"
        underline="hover"
      >
        Privacy Policy
      </Link>
    );
  }

  function renderTermsAndPrivacy() {
    return (
      <Trans i18nKey="auth:acceptTerms">
        By checking this box and using EveryoneSocial you agree to our
        {renderTermsLink()}, {renderCookieLink()}, and {renderPrivacyPolicyLink()}
      </Trans>
    );
  }

  function renderSocialTerms() {
    return (
      <Trans
        i18nKey="auth:acceptTermsSocialMediaPolicy"
        values={{ title: social_media_policy_title }}
      >
        By checking this box and using EveryoneSocial you agree to our
        {renderTermsLink()}, {renderCookieLink()}, {renderPrivacyPolicyLink()}, and{' '}
        {renderSocialMediaPolicyLink()}.
      </Trans>
    );
  }

  return (
    <ValidatedCheckbox
      labelClasses={{ root: styles.labelRoot, label: styles.label }}
      name="acceptTerms"
      label={social_media_policy_enabled ? renderSocialTerms() : renderTermsAndPrivacy()}
    />
  );
};

export default Terms;
