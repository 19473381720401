import React, { FC } from 'react';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useContent, useSharePhoto } from 'hooks';
import { getAppImage, getImageSrc } from 'helpers';
import { APP_IMAGES } from 'app-constants';

import { getImageUrl } from 'features/content/helpers';
import theme from 'theme';

const styles = {
  image: {
    height: 100,
    width: 100,
  },
  imageBorder: {
    height: 100,
    width: 100,
    border: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    overflow: 'hidden',
  },
  noImage: {
    position: 'absolute',
    textAlign: 'center',
  },
} as const;

const PostImage: FC = () => {
  const content = useContent();
  const { t } = useTranslation();
  const { selectedPhotoIndex } = useSharePhoto();

  const imageUrl = getImageUrl(content, selectedPhotoIndex, { width: 100, height: 100 });

  if (!imageUrl) {
    return (
      <Grid container alignItems="center" justifyContent="center" sx={styles.imageBorder}>
        <Typography sx={styles.noImage}>{t('admin:noPreview')}</Typography>
        <img style={styles.image} {...getAppImage(APP_IMAGES.SocialLoading)} />
      </Grid>
    );
  }

  return (
    <Box sx={styles.imageBorder}>
      <img {...getImageSrc(imageUrl)} />
    </Box>
  );
};

export default PostImage;
