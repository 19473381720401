import i18n from 'config/i18n';
import { ReactNode } from 'react';
import { GridSize } from '@mui/material';
import { compact, escapeRegExp } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import numeral from 'numeral';
import * as yup from 'yup';
import cookieStorage from 'cookie-storage';

import {
  BillingStatusType,
  ClientDepartment,
  ClientLocation,
  ClientProtectedFragment,
  ContentFragment,
  FeaturePlanType,
  GroupFragment,
  GroupSuggestion,
  MinimalUserProfile,
  Network,
  PartnerDetails as PartnerDetailsProtected,
  ProfileSuggestion,
  PublicUserProfile,
  PublicUserProfileFragment,
  ReachStat,
  S3ObjectInput,
  S3VideoObjectInput,
  SearchAggregates,
  SearchResultsObject,
  SocialAccount,
  SuggestionResultsObject,
  UserProfileFragment,
  UserProfileSearchFragment,
  UserType,
} from 'api';
import { PartnerDetails as PartnerDetailsPublic } from './api/public';
import { ClientSettingParams, IFilter, SearchResultTypes, SuggestionTypes } from 'types/app';
import { getRootUrl, isRoot, popup, queryString } from 'utilities';
import {
  NO_NAME,
  ROOT_DOMAIN_LABEL_FOR_LOGOUT,
  SHORTENED_NUMBER_CUTOFF,
  QUOTES,
} from 'app-constants';
import {
  AWS_REGION,
  CSV_BUCKET,
  DOMAIN,
  IMAGES_BUCKET,
  IMGIX_HOST,
  SSO_DOMAIN,
  VIDEOS_BUCKET,
} from 'settings';

const USER_IMAGES_DIRECTORY = 'user_images';
const USER_CSV_DIRECTORY = 'user_csv';
const APP_IMAGES_DIRECTORY = 'webapp';

export const getFullName = (
  user:
    | PublicUserProfile
    | ProfileSuggestion
    | UserProfileSearchFragment
    | MinimalUserProfile
    | PublicUserProfileFragment
) => {
  if (!user) {
    return NO_NAME;
  }
  const u = user as any;
  return u.full_name || u.email_address || u.title || NO_NAME;
};

export const getRandomProfileImage = (index?: number) => {
  const NUM_PROFILE_IMAGES = 6;
  index = index
    ? (index % NUM_PROFILE_IMAGES) + 1
    : Math.floor(Math.random() * NUM_PROFILE_IMAGES) + 1;
  const imageUrl = `${IMGIX_HOST}/static/${APP_IMAGES_DIRECTORY}/profile-${index}.png?w=100&h=100auto=format`;

  return getImageSrc(imageUrl);
};

export const generateS3Image = (
  blob: Blob,
  file: File,
  user: UserProfileFragment
): S3ObjectInput => {
  const key = `${USER_IMAGES_DIRECTORY}/${user.id}/${uuidv4()}`;

  return {
    key,
    localUri: blob as unknown as string, // backend doesn't know Blob
    mimeType: file.type,
    bucket: IMAGES_BUCKET,
    region: AWS_REGION,
  };
};

export const generateS3CSV = (file: File, user: UserProfileFragment): S3ObjectInput => {
  const key = `${USER_CSV_DIRECTORY}/${user.id}/${uuidv4()}.csv`;
  return {
    key,
    localUri: file as unknown as string,
    mimeType: 'text/csv',
    bucket: CSV_BUCKET,
    region: AWS_REGION,
  };
};

export function generateS3Video(file: File): S3VideoObjectInput {
  const extension = file.name.split('.').pop();
  const key = `${uuidv4()}${extension && '.'}${extension}`;

  return {
    key,
    localUri: file as unknown as string,
    bucket: VIDEOS_BUCKET,
    region: AWS_REGION,
  };
}

export const isClientSuccessUser = (user: UserProfileFragment) => {
  return user.user_type === UserType.Cs;
};

export const columnSplitToGridSizes = (columnSplit: string) => {
  const columns = columnSplit.split('/');

  return columns.map(column => {
    return parseInt(column, 10) as GridSize;
  });
};

export const getSocialAccountName = (account: SocialAccount) => {
  if (!account) {
    return '';
  }

  const { identifier, provider, profile_name } = account;
  return provider === Network.Twitter ? `@${identifier}` : profile_name;
};

export const getSuggestionResultType = (suggestion: SuggestionResultsObject) => {
  if ((suggestion as GroupSuggestion).color) {
    return SuggestionTypes.Group;
  }

  if ((suggestion as ProfileSuggestion).title) {
    return SuggestionTypes.Profile;
  }

  throw new Error('Suggestion Result Type Not Found');
};

export const getSearchResultsType = (suggestion: SearchResultsObject) => {
  if ((suggestion as ContentFragment).content_id) {
    return SearchResultTypes.Content;
  }

  if ((suggestion as GroupFragment).title) {
    return SearchResultTypes.Group;
  }

  if ((suggestion as PublicUserProfileFragment).full_name) {
    return SearchResultTypes.Profile;
  }

  throw new Error('Search Result Type Not Found');
};

export const getTotalReach = (stats: ReachStat[]) => {
  return stats.reduce((current: number, stat) => current + stat.reach, 0);
};

export const getSelectedFilterTitle = (
  filters: IFilter[],
  searchAggregates: SearchAggregates,
  params: Partial<ClientSettingParams>
) => {
  const { departments, locations } = searchAggregates;
  const { department_id, location_id } = params;

  let title: ReactNode = null;

  filters.forEach(filter => {
    if (filter.isSelected) {
      title = filter.textNode;
    }
  });

  departments.forEach((department: ClientDepartment) => {
    if (department.id === department_id) {
      title = department.title;
    }
  });

  locations.forEach((location: ClientLocation) => {
    if (location.id === location_id) {
      title = location.title;
    }
  });

  return title;
};

export const getImageSrc = (imageUrl: string) => ({
  src: imageUrl,
  srcSet: `${imageUrl} 1x, ${imageUrl}&dpr=2 2x, ${imageUrl}&dpr=3 3x`,
});

export const getAppImage = (filename: string, height = 1.0) => {
  const url = `${IMGIX_HOST}/static/${APP_IMAGES_DIRECTORY}/${filename}?auto=format&fit=clip&height=${height}`;
  return getImageSrc(url);
};

export const connectionPopup = (provider: string) => {
  const url = `/connect/${provider}`;
  popup(url, 'Connection Popup', 650, 650);
};

export const getSubdomain = () => {
  const hostname = window.location.hostname;
  const prefix = hostname.split('.')[0];

  return `${prefix}.${DOMAIN}`;
};

export const getSubdomainUrl = (subdomain: string) => {
  const subdomainUrl = window.location.hostname.split('.');
  if (subdomainUrl.length === 3) {
    subdomainUrl.splice(0, 1);
  }
  subdomainUrl.splice(0, 0, subdomain.split('.')[0]);
  return `${window.location.protocol}//${subdomainUrl.join('.')}`;
};

export const getEmbedWidgetSrc = (client: ClientProtectedFragment, content: ContentFragment) => {
  const subdomain = getSubdomain();
  const params = queryString({
    content_id: content.content_id,
    client_id: client.id,
  });

  return `https://${subdomain}/embed-widget/index.html?${params}`;
};

export const getContentUrlRegex = (client: ClientProtectedFragment) => {
  const { subdomain } = client;
  const prefix = subdomain.split('.')[0];
  const safePrefix = escapeRegExp(prefix);
  const safeDomain = escapeRegExp(DOMAIN);

  return new RegExp(
    `(https://${safePrefix}.${safeDomain}/content/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$`
  );
};

export const formatStat = (count: number, format = '0.0a') => {
  return count > SHORTENED_NUMBER_CUTOFF ? numeral(count).format(format) : numeral(count).format();
};

export const getReadablePlanType = (featurePlanType: FeaturePlanType) => {
  switch (featurePlanType) {
    case FeaturePlanType.E:
      return i18n.t('common:enterprise');
    case FeaturePlanType.T:
      return i18n.t('common:team_plural');
    case FeaturePlanType.F:
      return i18n.t('common:starter');
    default:
      // Assume null are legacy enterprise accounts
      return i18n.t('common:enterprise');
  }
};

export const isTrialingTeamsWorkspace = (client: ClientProtectedFragment) =>
  client.feature_plan_type === FeaturePlanType.T &&
  client.billing_status === BillingStatusType.Trialing;
export const isFreeWorkspace = (client: ClientProtectedFragment) =>
  client.feature_plan_type === FeaturePlanType.F &&
  client.billing_status === BillingStatusType.Active;
export const isTeamsWorkspace = (client: ClientProtectedFragment) =>
  client.feature_plan_type === FeaturePlanType.T &&
  client.billing_status === BillingStatusType.Active;

export const getNextLogoutUrl = (remainingSubdomains: string, nextSubdomain: string) => {
  const remainingSubdomainCount = compact(remainingSubdomains.split(',')).length;
  const isRootNextTarget = nextSubdomain === ROOT_DOMAIN_LABEL_FOR_LOGOUT;

  // Account for users with only one workspace
  if (isRootNextTarget && !remainingSubdomainCount) {
    return `${getRootUrl()}/logout?subdomains=${nextSubdomain}`;
  }

  // We're finished cycling through subdomains
  if (isRootNextTarget && isRoot()) {
    return `/`;
  }

  // Redirect to the next subdomain
  const params = `logout?subdomains=${remainingSubdomains}`;
  return `${isRootNextTarget ? getRootUrl() : getSubdomainUrl(nextSubdomain)}/${params}`;
};

export const yupPasswordValidation = yup
  .string()
  .min(12, 'min')
  .max(256, 'max')
  .minLowercase(1, 'minLowercase')
  .minUppercase(1, 'minUppercase')
  .minNumbers(1, 'minNumbers')
  .minSymbols(1, 'minSymbols')
  .required('required');

export const redirectToWorkvivoJwtSso = (jwt: string) => {
  let url = new URL(location.href);
  if (url.pathname == '/') {
    url = new URL(location.protocol + '//' + location.host + cookieStorage.nextPath);
  }
  const params = url.searchParams;
  params.delete('jwt');
  params.delete('Keyid');
  cookieStorage.nextPath = url.pathname + '?' + params.toString();
  const newLocation = `https://${
    url.hostname.split('.')[0]
  }.${SSO_DOMAIN}/saml/sso/workvivo/oidc-proxy/app-start?jwt=${jwt}`;
  location.replace(newLocation);
};

export function getPartnerName() {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('partner_name') || '';
}

export function getJwt() {
  const searchParams = new URLSearchParams(window.location.search);
  let jwt = searchParams.get('jwt');
  const nextPath = cookieStorage.nextPath;
  if (!jwt && nextPath && nextPath.indexOf('?') >= 0) {
    jwt = new URLSearchParams(nextPath.substring(nextPath.indexOf('?'))).get('jwt');
  }
  return jwt;
}

export function getPartnerRedirectUrl(
  partnerDetails: PartnerDetailsPublic | PartnerDetailsProtected
) {
  if (!partnerDetails?.redirect_uris) {
    return '';
  }

  const searchParams = new URLSearchParams(window.location.search);
  const redirectUrl = searchParams.get('redirect_uri');
  const validatedPartnerUrl = partnerDetails.redirect_uris.find(uri => uri === redirectUrl);

  return validatedPartnerUrl || '';
}

export function getRandomQuote() {
  const min = 0;
  const max = QUOTES.length - 1;
  const number = Math.floor(Math.random() * (max - min)) + min;

  return QUOTES[number];
}

export const formatNumber = (input: string | number) => {
  const number = typeof input === 'number' ? input : parseFloat(input);
  return isNaN(number) ? '' : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parseTextWithHashtag = (text: string) => {
  const hashtagRegex = /\{hashtag\|\\#\|([^}]+)\}/g;

  return text.replace(hashtagRegex, (match, p1) => `#${p1.trim()}`);
};

export const parseTextWithCompany = (text: string) => {
  const companyRegex = /@\[(.*?)\]\(urn:li:(organization|person):\w+\)/g;

  return text.replace(companyRegex, (match, p1) => p1.trim());
};

export const parseTextWithBackslash = (text: string) => {
  return text.replace(/\\/g, '');
};

export const validateImageUrl = (url: string) => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};
