import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import IframeResizer from 'iframe-resizer-react';

import { oEmbedResponseRich } from 'types/oembed';

import { TIKTOK_OEMBED_ENDPOINT } from '../constants';
import EmbedPlaceholder from './EmbedPlaceholder';
import EmbedWrapper from './EmbedWrapper';
import { queryString } from 'utilities';

// @ts-ignore
import contentWindow from 'iframe-resizer/js/iframeResizer.contentWindow.min.js?raw';

const styles = {
  embed: {
    display: 'block',
    width: '100%',
    margin: '0 auto',
    border: 0,
  },
} as const;

interface Props {
  url: string;
}

const EmbedFacebook: FC<Props> = ({ url }) => {
  const ref = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [html, setHtml] = useState('');

  useEffect(() => {
    void loadEmbed();
  }, []);

  async function loadEmbed() {
    try {
      const params = queryString({ url });

      const fetchResponse = await fetch(`${TIKTOK_OEMBED_ENDPOINT}?${params}`);
      const embedResponse: oEmbedResponseRich = await fetchResponse.json();

      const style = '<style>.tiktok-embed { margin: 0 !important; }</style>';
      const script = `<script>${contentWindow}</script>`;
      const { html } = embedResponse;

      setHtml(`${style}${script}${html}`);
      setIsLoaded(true);
    } catch (error) {
      throw new Error(error);
    }
  }

  return (
    <Fragment>
      {!isLoaded && <EmbedPlaceholder />}
      <EmbedWrapper visible={isLoaded}>
        <IframeResizer
          sizeWidth
          style={styles.embed}
          checkOrigin={false}
          forwardRef={ref}
          srcDoc={html}
          bodyMargin={0}
          bodyPadding={0}
          minHeight={400}
        />
      </EmbedWrapper>
    </Fragment>
  );
};

export default EmbedFacebook;
