import React, { FC } from 'react';
import { CardMedia } from '@mui/material';

import { Content, ContentInput } from 'api';
import { getImageSrc } from 'helpers';
import { resizeImage } from 'utilities';
import { useDimensions } from 'hooks';

import { getImage } from '../helpers';

import { IMGIX_HOST } from 'settings';

interface IProps {
  content: Content | ContentInput;
  onClickReaction?: () => void;
}

const Image: FC<IProps> = ({ content, onClickReaction }) => {
  const image = getImage(content);
  const { width } = useDimensions();

  function isMissingImage() {
    return !image || !image.width || !image.height;
  }

  function getImageProps() {
    if (!image.bucket) {
      return { src: image.url_original };
    }

    const imageUrl = `${IMGIX_HOST}/${image.file_key}?w=${width}&auto=format`;

    return getImageSrc(imageUrl);
  }

  if (isMissingImage()) {
    return null;
  }

  const imageSize = resizeImage(image.width, image.height, width);
  return (
    <CardMedia component="img" style={imageSize} {...getImageProps()} onClick={onClickReaction} />
  );
};

export default Image;
