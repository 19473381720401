import React, { createContext, FC, useContext, useState } from 'react';
import { Editor, Selection, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { DEFAULT_SELECTION } from '../constants';

interface Props {
  editor: Editor;
}

interface ContextValue {
  selection: Selection;
  actions: {
    ensureEditorFocus: VoidFunction;
  };
  callbacks: {
    onEditorBlur: VoidFunction;
  };
}

const SelectionContext = createContext<ContextValue>({
  selection: DEFAULT_SELECTION,
  actions: {
    ensureEditorFocus: () => undefined,
  },
  callbacks: {
    onEditorBlur: () => undefined,
  },
});

/**
 * Ensures a selection is always available by storing last known selection in state
 * instead of relying on editor.selection which may be null for a myriad of reasons
 */
const SelectionContextProvider: FC<Props> = ({ editor, children }) => {
  const [selection, setLastSelection] = useState<Selection>(DEFAULT_SELECTION);

  function ensureEditorFocus() {
    Transforms.select(editor, selection);
    ReactEditor.focus(editor);
  }

  function onEditorBlur() {
    setLastSelection(editor.selection);
  }

  const contextValue: ContextValue = {
    selection,
    actions: {
      ensureEditorFocus,
    },
    callbacks: {
      onEditorBlur,
    },
  };

  return <SelectionContext.Provider value={contextValue}>{children}</SelectionContext.Provider>;
};

const useSelectionContext = () => {
  return useContext(SelectionContext);
};

export { SelectionContextProvider, useSelectionContext };
