import React, { ChangeEvent, FC } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { Box, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { ClientInput } from '../../types';
import ClientCompanyAlias from 'clientSuccess/components/Client/ClientCompanyAlias';
import theme from '../../../theme';

const OrganicInsights: FC = () => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<ClientInput>();

  function onFocus() {
    if (values.yearly_enrichment_quota === 0) {
      setFieldValue('yearly_enrichment_quota', '');
    }
  }

  function onBlur() {
    // @ts-ignore
    if (values.yearly_enrichment_quota === '') {
      setFieldValue('yearly_enrichment_quota', 0);
    }
  }

  return (
    <Grid xs={12} container>
      <Grid xs={3}>
        <Typography variant="subtitle2">{t('insights:organicInsights')}</Typography>
      </Grid>
      <Grid xs={9} container>
        <Grid xs={11}>
          <Grid xs={9}>
            <TextField
              label={t('insights:enrichmentTitle')}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="standard"
              type="number"
              value={values.yearly_enrichment_quota}
              InputProps={{ startAdornment: <Box paddingLeft={2}>$</Box> }}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setFieldValue('yearly_enrichment_quota', event.target.value)
              }
              onFocus={onFocus}
              onBlur={onBlur}
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: theme.palette.grey[100],
                },

                Input: {
                  padding: theme.spacing(2),
                },
              }}
            />
          </Grid>
          <Grid xs={9} marginTop={2}>
            <ClientCompanyAlias />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganicInsights;
