import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { CheckedMode } from 'types/app';
import theme from 'theme';

interface IProps {
  noun: string;
  count: number;
  total: number;
  mode: CheckedMode;
  onSelectAll(mode: CheckedMode): void;
  disableMatchAll?: boolean;
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    transition: theme.transitions.create('height'),
    overflow: 'hidden',
  },
  visibleRow: {
    height: 48,
  },
} as const;

const RowSelection: FC<IProps> = ({ noun, count, total, mode, onSelectAll, disableMatchAll }) => {
  const { t } = useTranslation();

  const tableRowClasses = { ...styles.root, ...(mode !== CheckedMode.OFF && styles.visibleRow) };

  function renderPageSelected() {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid>
          <Typography variant="body2">
            {t('components:rowSelectionSelected', { count, noun })}
          </Typography>
        </Grid>
        <Grid>
          {!disableMatchAll ? (
            <Button color="primary" onClick={() => onSelectAll(CheckedMode.ALL)}>
              {t('components:rowSelectionSelectAll', { count: total, noun })}
            </Button>
          ) : (
            <Button color="primary" onClick={() => onSelectAll(CheckedMode.OFF)}>
              {t('components:rowSelectionClear')}
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }

  function renderAllSelected() {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid>
          <Typography variant="body2">
            {t('components:rowSelectionAllSelected', { count: total, noun })}
          </Typography>
        </Grid>
        <Grid>
          <Button color="primary" onClick={() => onSelectAll(CheckedMode.OFF)}>
            {t('components:rowSelectionClear')}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box sx={tableRowClasses}>
      {!disableMatchAll && mode === CheckedMode.ALL && renderAllSelected()}
      {mode === CheckedMode.PAGE && renderPageSelected()}
    </Box>
  );
};

export default RowSelection;
