import React, { FC } from 'react';
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ValidatedSwitch, ValidatedTextFieldNext } from 'components';

const MenuItem: FC = () => {
  const { t } = useTranslation();

  return (
    <Grid xs={12} container>
      <Grid xs={3}>
        <Typography variant="subtitle2">{t('components:menuItem')}</Typography>
      </Grid>
      <Grid xs={9} container alignItems="baseline" spacing={2}>
        <Grid xs={11}>
          <ValidatedTextFieldNext
            name="menu_item.title"
            fullWidth
            label={t('common:title')}
            placeholder={t('common:title')}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid xs>
          <ValidatedSwitch name="menu_item_enabled" color="primary" />
        </Grid>

        <Grid xs={11}>
          <ValidatedTextFieldNext
            fullWidth
            label={t('common:link')}
            placeholder={t('common:link')}
            name="menu_item.link"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MenuItem;
