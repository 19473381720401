import React, { FC } from 'react';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { useContent } from 'hooks';
import { getProviderDisplay, getTitle } from 'features/content/helpers';
import theme from 'theme';

import PostImage from './PostImage';

const styles = {
  title: theme.mixins.clamp(2),
  provider: theme.mixins.clamp(1),
} as const;

const PartnerSharePreview: FC = () => {
  const content = useContent();

  return (
    <Grid container spacing={2} alignItems="center" sx={{ background: theme.palette.common.white }}>
      <Grid>
        <PostImage />
      </Grid>
      <Grid>
        <Typography variant="h6" sx={styles.title}>
          {getTitle(content)}
        </Typography>
        <Typography color="textSecondary" sx={styles.provider}>
          {getProviderDisplay(content)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PartnerSharePreview;
