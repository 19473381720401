import React, { FC } from 'react';
import { Paper, PaperProps } from '@mui/material';

interface Props extends PaperProps {
  padding?: number | string;
}

const PaddedPaper: FC<Props> = ({ children, padding, ...rest }) => {
  return (
    <Paper style={{ padding }} {...rest}>
      {children}
    </Paper>
  );
};

PaddedPaper.defaultProps = {
  padding: 16,
};

export default PaddedPaper;
