import React, { FC, Fragment } from 'react';
import { GlobalStyles } from '@mui/material';

import Connections from './Connections';
import Routes from './Routes';
import { getJwt, redirectToWorkvivoJwtSso } from 'helpers';
import { PartnerShareLoader } from 'features/sharing';

const globalStyles = {
  'html, body, #root': {
    height: '100%',
  },
};

const Public: FC = () => {
  const jwt = getJwt();

  if (jwt) {
    try {
      redirectToWorkvivoJwtSso(jwt);
      return <PartnerShareLoader />;
    } catch (error) {
      throw new Error(`could_not_parse_token:${jwt}`);
    }
  }

  return (
    <Fragment>
      <GlobalStyles styles={globalStyles} />
      <Connections />
      <Routes />
    </Fragment>
  );
};

export default Public;
