import React, { lazy, Suspense } from 'react';
import { Loaders } from 'components';

const LazyAuthLayout = lazy(() => import('features/auth/containers/Controller'));
const LazySSOLayout = lazy(() => import('features/auth/containers/SSO'));
const LazySocialMediaPolicyLayout = lazy(
  () => import('features/auth/containers/SocialMediaPolicy')
);
const LazyUnsubscribeLayout = lazy(() => import('features/auth/containers/Unsubscribe'));
const LazyPublicPermalinkLayout = lazy(() => import('features/content/containers/PublicPermalink'));

const LazyConnectionsSuccessLayout = lazy(() => import('features/connections/Success'));
const LazyConnectionsFailureLayout = lazy(() => import('features/connections/Failure'));

const LazyPartnerSharePublicLayout = lazy(
  () => import('features/sharing/containers/PartnerShare/PublicLoader')
);
const LazyPartnerActivityPublicLayout = lazy(
  () => import('features/activity/containers/ActivityPartnerPublic')
);

const LazyExpressActivationPublicLayout = lazy(
  () => import('features/express/containers/ExpressActivation')
);

export const AuthLayout = () => (
  <Suspense fallback={<Loaders.Startup />}>
    <LazyAuthLayout />
  </Suspense>
);
export const SSOLayout = () => (
  <Suspense fallback={<Loaders.Startup />}>
    <LazySSOLayout />
  </Suspense>
);
export const UnsubscribeLayout = () => (
  <Suspense fallback={<Loaders.Startup />}>
    <LazyUnsubscribeLayout />
  </Suspense>
);
export const SocialMediaPolicyLayout = () => (
  <Suspense fallback={<Loaders.Startup />}>
    <LazySocialMediaPolicyLayout />
  </Suspense>
);
export const PublicPermalinkLayout = () => (
  <Suspense fallback={<Loaders.Startup />}>
    <LazyPublicPermalinkLayout />
  </Suspense>
);
export const ConnectionsSuccess = () => (
  <Suspense fallback={<Loaders.Startup />}>
    <LazyConnectionsSuccessLayout />
  </Suspense>
);
export const ConnectionsFailure = () => (
  <Suspense fallback={<Loaders.Startup />}>
    <LazyConnectionsFailureLayout />
  </Suspense>
);
export const PartnerSharePublicLayout = () => (
  <Suspense fallback={<Loaders.Startup />}>
    <LazyPartnerSharePublicLayout />
  </Suspense>
);
export const PartnerActivityPublicLayout = () => (
  <Suspense fallback={<Loaders.Startup />}>
    <LazyPartnerActivityPublicLayout />
  </Suspense>
);

export const ExpressActivationPublicLayout = () => (
  <Suspense fallback={<Loaders.Startup />}>
    <LazyExpressActivationPublicLayout />
  </Suspense>
);
