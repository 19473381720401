import React, { FC } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, IconButtonProps } from '@mui/material';

interface Props extends IconButtonProps {
  onClick: () => void;
  lighter?: boolean;
}

const styles = {
  icon: {
    color: 'grey.400',
  },
} as const;

const RemoveIcon: FC<Props> = ({ onClick, lighter, classes }) => {
  return (
    <IconButton onClick={onClick} sx={lighter ? styles.icon : {}} classes={classes} size="large">
      <CancelIcon />
    </IconButton>
  );
};

export default RemoveIcon;
