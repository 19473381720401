import React, { FC, Fragment, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';

import { GroupIdApprovalInput, useUpdateContentApprovalsMutation } from 'api';
import { Alert } from 'components';
import { content as contentPath, index as indexPath } from 'paths';
import { useContent, useNotify } from 'hooks';
import { useRemoveContent } from 'features/content/updaters';

interface IKeys {
  alertMessage: string;
  alertConfirm: string;
  menuItem: string;
  notify: string;
  values?: Record<string, unknown>;
}

interface IProps {
  groupIdApprovals: GroupIdApprovalInput[];
  i18nKeys: IKeys;
}

const GroupApprovals: FC<IProps> = ({ groupIdApprovals, i18nKeys }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isContentPath = !!useMatch(contentPath);
  const notify = useNotify();
  const content = useContent();
  const [open, setOpen] = useState(false);

  const groupId = groupIdApprovals[0]?.group_id;
  const approvalsMutationOptions = useRemoveContent(groupId);
  const { mutate } = useUpdateContentApprovalsMutation({
    ...approvalsMutationOptions,
    onSuccess: () => {
      notify.info({ i18n: { i18nKey: i18nKeys.notify, values: i18nKeys.values } });
      redirectHomeIfNeeded();
    },
  });

  function redirectHomeIfNeeded() {
    if (isContentPath) {
      navigate(indexPath);
    }
  }

  function toggleConfirm() {
    setOpen(!open);
  }

  function handlePostDelete() {
    const { content_id } = content;

    toggleConfirm();
    mutate({
      content_id,
      group_id_approvals: groupIdApprovals,
    });
  }

  return (
    <Fragment>
      <MenuItem onClick={toggleConfirm}>{t(i18nKeys.menuItem, i18nKeys.values)}</MenuItem>
      <Alert
        open={open}
        onConfirm={handlePostDelete}
        onCancel={toggleConfirm}
        destructive
        message={t(i18nKeys.alertMessage, i18nKeys.values)}
        confirm={t(i18nKeys.alertConfirm, i18nKeys.values)}
      />
    </Fragment>
  );
};

export default GroupApprovals;
