import React, { FC, MouseEvent, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, lighten, Menu as MuiMenu, MenuItem } from '@mui/material';
import MoreHorzIcon from '@mui/icons-material/MoreHoriz';

import { ContentApproval, GroupContentApprovalType } from 'api';
import { Capability } from 'components';
import { useCapabilities, useContent, useCurrentUser, useMonitorPost, useReleases } from 'hooks';
import { useCanShare } from 'features/content/hooks';
import * as Links from 'Links';
import * as paths from 'paths';
import theme from 'theme';

import {
  getApprovedGroups,
  getPendingGroups,
  getSubmittedGroups,
  hasCreator,
  isPostCreator,
} from '../../helpers';
import GroupApprovals from './GroupApprovals';
import DeletePost from './DeletePost';
import CopyPostEmbedCode from './CopyPostEmbedCode';
import CopyLink from './CopyLink';
import AuditLog from './AuditLog';

interface Props {
  theme?: 'default' | 'dark';
  size?: 'default' | 'small';
}

/**
 * Show Menu:
 *
 * 1. If content has a user (creator or viewer)
 * 2. If current user moderates any groups in `group_id_approvals`
 * 3. If current user is an admin and post is assigned to a group
 *
 * Hide Menu:
 *
 * 1. If content is `autoapproved` and current user isn't a moderator of an assigned group
 * 2. If content is being displayed on the "Removed" feed
 *
 * Options:
 *
 * 2. If moderator of a group in `group_id_approvals` with status `approved` or `autoapproved`, then show remove from group
 * 3. If moderator of a group in `group_id_approvals` with status `submitted` then show approve to group
 * 4. If creator, then show "Edit Post" and "Delete Post"
 * 5. If admin, show remove from group(s), show approve in group(s), show "Edit Post" and "Delete Post"
 * 6. If current user is moderator of selected group, show "Edit Post"
 * 7. If post can be shared, then show embed post option
 * 8. If post has an edit, show the Audit Log menu item
 *
 * After Action:
 *
 * 1. If approval and viewing chosen group, then do nothing
 * 2. If removal and viewing chosen group, then remove post
 * 3. If content permalink, then redirect home
 */

const styles = {
  dark: {
    backgroundColor: lighten(theme.palette.common.black, 0.2),
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  },
  small: {
    padding: theme.spacing(0.5),
  },
} as const;

const PostMenu: FC<Props> = ({ theme, size }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { needs, isAdmin, isModerator } = useCapabilities();
  const { state } = useMonitorPost();
  const currentUser = useCurrentUser();
  const content = useContent();
  const { groups, primary_group_id } = content;

  const group = groups?.find(group => group.id === primary_group_id);
  const releases = useReleases();
  const canShare = useCanShare(content);
  const hasEmbedPost = canShare && releases.includes('embedWidget');
  const has4EyeModeration = releases.includes('4EyeModeration');

  const is4EyesModeration =
    !!group && group?.content_approval_type === GroupContentApprovalType.FourEyes;
  const isCreator = isPostCreator(content, currentUser);
  const isViewer = !isCreator && hasCreator(content);
  const isRemovedView = !!useMatch(paths.groupRemoved);
  const isPending = !!getPendingGroups(content).length;

  const themeClass = theme === 'dark' ? styles.dark : '';
  const sizeClass = size === 'small' ? styles.small : '';
  const displayClasses = { ...themeClass, ...sizeClass };

  if (state.size) {
    return null;
  }

  if (isRemovedView) {
    return null;
  }

  if (!isCreator && !isViewer && !isModerator) {
    return null;
  }

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function renderRemovals() {
    if (has4EyeModeration && is4EyesModeration) {
      return null;
    }

    const approvedGroups = getApprovedGroups(content);
    const submittedGroups = getSubmittedGroups(content);
    const groupsThatCanBeRemoved = approvedGroups.concat(submittedGroups);

    return groupsThatCanBeRemoved.map(group => (
      <Capability key={group.id} name="Group.View.Removed" value={group.id}>
        <GroupApprovals
          i18nKeys={{
            alertMessage: 'content:postRemoveGroup',
            alertConfirm: 'common:remove',
            menuItem: 'content:postRemoveGroup',
            notify: 'content:postRemovedGroup',
            values: { title: group.title },
          }}
          groupIdApprovals={[{ group_id: group.id, approval: ContentApproval.Removed }]}
        />
      </Capability>
    ));
  }

  function renderApprovals() {
    if (has4EyeModeration && is4EyesModeration) {
      return null;
    }

    const submittedGroups = getSubmittedGroups(content);

    return submittedGroups.map(group => (
      <Capability key={group.id} name="Group.View.Approval" value={group.id}>
        <GroupApprovals
          key={group.id}
          i18nKeys={{
            alertMessage: 'content:postApproveGroup',
            alertConfirm: 'common:approve',
            menuItem: 'content:postApproveGroup',
            notify: 'content:postApproveGroup',
            values: { title: group.title },
          }}
          groupIdApprovals={[{ group_id: group.id, approval: ContentApproval.Approved }]}
        />
      </Capability>
    ));
  }

  function renderEditMenuItems() {
    const items = [];
    const hasEditPermission =
      needs('Post.Editing.Function.Edit', content.group_ids) || (isCreator && !isPending);

    if (hasEditPermission) {
      items.push(
        <MenuItem key={0} component={Links.PostEdit(content.content_id)}>
          {t('content:postEdit')}
        </MenuItem>
      );
    }

    if (isCreator || isAdmin) {
      items.push(<DeletePost key={1} />);
    }

    return items;
  }

  return (
    <div>
      <IconButton sx={displayClasses} data-testid="post-actions-more-actions" onClick={handleClick}>
        <MoreHorzIcon />
      </IconButton>
      <MuiMenu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        <CopyLink />
        {hasEmbedPost && <CopyPostEmbedCode />}
        {renderEditMenuItems()}
        {renderApprovals()}
        {renderRemovals()}
        <AuditLog />
      </MuiMenu>
    </div>
  );
};

PostMenu.defaultProps = {
  theme: 'default',
  size: 'default',
};

export default PostMenu;
