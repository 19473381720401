import React, { FC, Fragment, KeyboardEvent, useState } from 'react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Button, Unstable_Grid2 as Grid, IconButton, TextField, Typography } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';

import { ClientInput } from '../../types';

type KE = KeyboardEvent<HTMLDivElement>;

interface IProps {
  title: string;
  placeholder: string;
  dataKey: string;
}

const MultiValue: FC<IProps> = ({ title, placeholder, dataKey }) => {
  const [domain, setDomain] = useState('');
  const { errors, touched, values, setValues } = useFormikContext<ClientInput>();

  const data = values[dataKey];
  const error = touched[dataKey] && errors[dataKey];

  function handleAdd() {
    if (!domain.length) {
      return;
    }

    // FieldArray has a bug in it that causes a validation error - so I am not using it here.
    // arrayHelpers.insert(0, domain);
    const newData = (data || []).slice();
    newData.splice(0, 0, domain);
    const newValues = { ...values };
    newValues[dataKey] = newData;
    setValues(newValues);

    setDomain('');
  }

  function handleKeyDown(event: KE) {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    handleAdd();
  }

  function renderDomain(arrayHelpers: FieldArrayRenderProps, index: number) {
    return (
      <Grid key={index} container alignItems="center">
        <Grid xs>
          <TextField variant="standard" margin="normal" disabled fullWidth value={data[index]} />
        </Grid>
        <Grid>
          <IconButton onClick={() => arrayHelpers.remove(index)} size="large">
            <RemoveCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid xs={3}>
        <Typography variant="subtitle2">{title}</Typography>
      </Grid>
      <Grid xs>
        <FieldArray name={dataKey}>
          {arrayHelpers => (
            <Fragment>
              <Grid container alignItems="center" spacing={2}>
                <Grid xs>
                  <TextField
                    value={domain}
                    onChange={event => setDomain(event.target.value)}
                    fullWidth
                    variant="outlined"
                    color="primary"
                    placeholder={placeholder}
                    error={!!error}
                    helperText={error}
                    onKeyDown={event => handleKeyDown(event)}
                  />
                </Grid>
                <Grid xs={2}>
                  <Button
                    component="span"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleAdd()}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {data &&
                data.map((datum: string, index: number) => renderDomain(arrayHelpers, index))}
            </Fragment>
          )}
        </FieldArray>
      </Grid>
    </Grid>
  );
};

export default MultiValue;
