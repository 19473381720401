import React, { FC } from 'react';
import { Paper, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

const styles = {
  root: {
    padding: '2',
    marginBottom: 2,
    backgroundColor: 'error.dark',
    color: 'common.white',
  },
} as const;

const FormErrors: FC = () => {
  const { errors } = useFormikContext();

  const errorsArray = Object.keys(errors);

  if (!errorsArray.length) {
    return null;
  }

  function renderError(error: string, index: number) {
    return (
      <Typography paragraph color="inherit" key={index}>{`${error}: ${JSON.stringify(
        errors[error]
      )}`}</Typography>
    );
  }

  return (
    <Paper sx={styles.root} square>
      {errorsArray.map(renderError)}
    </Paper>
  );
};

export default FormErrors;
