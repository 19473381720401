import React, { FC, Fragment } from 'react';
import { useMatch } from 'react-router-dom';
import {
  CardActions,
  CardContent,
  Divider,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import moment from 'moment';
import theme from 'theme';
import { useTranslation } from 'react-i18next';

import { Separator, UserAvatarGroup } from 'components';
import { useContent } from 'hooks';
import * as paths from 'paths';

import {
  Commentary,
  Date,
  Engage,
  Groups,
  Pin,
  Pinned,
  Reactions,
  Reposts,
  Share,
  ShareCountModal,
} from '../widgets';
import { getApprovedGroups, isEngageable } from '../helpers';
import { useCanShare } from '../hooks';
import { ContentApproval } from '../../../api';

const styles = {
  actions: {
    borderTop: `8px solid ${theme.palette.background.default}`,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
} as const;

const Meta: FC = () => {
  const { t } = useTranslation();
  const content = useContent();
  const isContent = useMatch(paths.content);
  const showEngage = isEngageable(content);
  const showShare = useCanShare(content) && !isContent;
  const approvedGroups = getApprovedGroups(content);

  const { approval, content_id, publish_at, shared_by, share_count } = content;
  const isScheduled = approval === ContentApproval.Submitted && content?.schedule?.publish;
  const scheduledTime = moment.unix(content?.schedule?.publish).format('LL [at] h:mm A');

  return (
    <Fragment>
      <CardContent>
        <Grid container alignItems="center" marginBottom={1}>
          <Grid container xs={12} marginBottom={1}>
            <Pin />
            <Groups groups={approvedGroups} />
            {!!approvedGroups.length && <Separator />}
            <Date contentId={content_id} publishAt={publish_at} />
          </Grid>

          {!isContent && !!shared_by?.length && (
            <Grid xs={12} marginBottom={1} marginTop={2}>
              <UserAvatarGroup users={content.shared_by} size={32} />
            </Grid>
          )}

          <Grid xs={12} container alignItems="center" justifyContent="space-between">
            <Grid xs={3} container spacing={2} alignItems="center">
              <Grid>
                <Reactions />
              </Grid>
              {!!share_count && (
                <Grid>
                  <ShareCountModal />
                </Grid>
              )}
            </Grid>

            {(showShare || showEngage) && (
              <Grid xs={9} container justifyContent="flex-end" spacing={1}>
                {showShare && (
                  <Grid>
                    <Share />
                  </Grid>
                )}
                {showEngage && (
                  <Grid>
                    <Engage />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>

      {isScheduled && (
        <CardActions sx={styles.actions}>
          <Typography color="textSecondary">
            {t('content:scheduledTime', { time: scheduledTime })}
          </Typography>
        </CardActions>
      )}

      <Divider />

      <Reposts />
      <Pinned />
      {!isContent && <Commentary />}
    </Fragment>
  );
};

export default Meta;
