import React, { FC } from 'react';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { ContentShareFragment } from 'api';
import { getProviderDisplay, isUrl } from 'features/content/helpers';

import UrlMetadata from './UrlMetadata';
import Body from './Body';

interface Props {
  content: ContentShareFragment;
}

const Post: FC<Props> = ({ content }) => {
  const name = getProviderDisplay(content);

  return (
    <Grid container direction="column">
      <Grid>
        <Typography>{name}</Typography>
      </Grid>
      <Grid>{isUrl(content) ? <UrlMetadata content={content} /> : <Body content={content} />}</Grid>
    </Grid>
  );
};

export default Post;
