import React, { FC } from 'react';
import { Card, CardContent, Divider, Unstable_Grid2 as Grid } from '@mui/material';

import { ContentLayoutProps } from 'types/app';
import { useContent } from 'hooks';
import theme from 'theme';

import { getEmbed, hasCreator } from '../helpers';
import { Important, PostMenu, Provider } from '../widgets';
import { ContentActions, User } from '../partials';

const styles = {
  card: {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  cardBody: {
    paddingTop: 0,
    paddingBottom: 12,
  },
  cardHeader: {
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
  },
} as const;

const Embed: FC<ContentLayoutProps> = ({ selectedGroup }) => {
  const content = useContent();
  const hasPostCreator = hasCreator(content);
  const component = getEmbed(content);
  const isDeletedUser = content?.user?.full_name === 'Deleted User';

  return (
    <Card sx={styles.card}>
      <Important />
      <User selectedGroup={selectedGroup} isDeletedUser={isDeletedUser} />
      <Divider />
      <CardContent sx={styles.cardHeader}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid>
            <Provider content={content} />
          </Grid>
          <Grid>{!hasPostCreator && <PostMenu />}</Grid>
        </Grid>
      </CardContent>
      <CardContent>{component}</CardContent>
      <ContentActions selectedGroup={selectedGroup} />
    </Card>
  );
};

export default Embed;
