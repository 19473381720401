import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  Link,
  List,
  Typography,
} from '@mui/material';
import { isNull } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik, FormikValues } from 'formik';

import {
  SocialAccount,
  UpdateSocialConnectionInputs,
  useBatchUpdateSocialConnectionMutation,
  useBootstrapQuery,
  useUpdateUserProfileMutation,
} from 'api';
import { useCurrentUser, useNotify, useReleases, useSocialConnections } from 'hooks';
import { getAppImage } from 'helpers';
import { Loaders } from 'components';
import * as Links from 'Links';
import { useUpdateSocialConnection } from 'updaters';

import SmartScheduleConnection from 'features/notifications/components/SmartScheduleConnection';
import { useQueryClient } from 'react-query';

const styles = {
  button: {
    width: 160,
  },
  iconButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
} as const;

enum ActiveLoader {
  Disable = 'disable',
  Activate = 'activate',
  None = 'none',
}

const SmartSchedulerDialog: FC = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const releases = useReleases();
  const hasSmartScheduler = releases.includes('SmartScheduler');

  const { connections, linkedInPages } = useSocialConnections();
  const { id, smart_schedule_acknowledgement, smart_schedule_viewed } = useCurrentUser();

  const [activeLoader, setActiveLoader] = useState<ActiveLoader>(ActiveLoader.None);
  const [showDialog, setShowDialog] = useState(false);

  const { mutate: updateUserProfile, isLoading } = useUpdateUserProfileMutation({
    onError: notify.mutationError,
    onSuccess: handleRefetch,
  });

  const updateMutationOptions = useUpdateSocialConnection();
  const { mutate: updateSocialConnection } =
    useBatchUpdateSocialConnectionMutation(updateMutationOptions);

  useEffect(() => {
    setShowDialog(
      hasSmartScheduler &&
        !!connections.length &&
        !isNull(smart_schedule_viewed) &&
        !smart_schedule_viewed
    );

    if (!showDialog || smart_schedule_acknowledgement) {
      return;
    }
  }, [connections.length]);

  useEffect(() => {
    !smart_schedule_acknowledgement &&
      updateUserProfile({
        data: {
          id,
          smart_schedule_acknowledgement: true,
          smart_schedule_acknowledged: moment().unix(),
        },
      });
  }, []);

  const initialValues: SocialAccount[] = [...connections] || [];

  async function handleRefetch() {
    try {
      await queryClient.refetchQueries(useBootstrapQuery.getKey({ user_id: id }));
    } catch (error) {
      notify.queryError();
    } finally {
      activeLoader === ActiveLoader.Activate ? showSaveNotify() : showDisableNotify();
      setActiveLoader(ActiveLoader.None);
    }
  }

  function getBatchUpdatePayload(values: SocialAccount[], smartSchedule?: boolean) {
    return values.map(item => {
      const payload: UpdateSocialConnectionInputs = {
        token_id: item.id,
        smart_schedule: smartSchedule ?? item.smart_schedule,
      };
      const linkedin_pages = linkedInPages.filter(page => page.id === item.id);
      if (linkedin_pages.length) {
        payload.linkedin_pages = linkedin_pages;
      }
      return payload;
    });
  }

  function handleUserProfileUpdate() {
    updateUserProfile({
      data: { id, smart_schedule_viewed: true, smart_schedule_acknowledgement: true },
    });
    setShowDialog(false);
  }

  function handleDisable() {
    setActiveLoader(ActiveLoader.Disable);

    const items = getBatchUpdatePayload(connections, false);
    updateSocialConnection(
      { data: { items } },
      {
        onSuccess: () => handleUserProfileUpdate(),
        onError: () => {
          notify.mutationError();
          setActiveLoader(ActiveLoader.None);
        },
      }
    );
  }

  function handleActivate(values: SocialAccount[]) {
    setActiveLoader(ActiveLoader.Activate);

    const items = getBatchUpdatePayload(values);
    updateSocialConnection(
      {
        data: { items },
      },
      {
        onSuccess: () => handleUserProfileUpdate(),
        onError: () => {
          notify.mutationError();
          setActiveLoader(ActiveLoader.None);
        },
      }
    );
  }

  function showDisableNotify() {
    notify.info({
      component: (
        <Trans key="components:smartSchedulerDisableNotify">
          Smart Scheduler has not been activated for your connected accounts. You can always adjust
          this in{' '}
          <Link underline="always" component={Links.Account}>
            Account Settings
          </Link>
          .
        </Trans>
      ),
    });
  }

  function showSaveNotify() {
    notify.info({
      component: (
        <Trans key="components:smartSchedulerSaveNotify">
          Smart Scheduler has been activated for any connected accounts you selected! We will notify
          you when content is scheduled. You can always adjust this in{' '}
          <Link underline="always" component={Links.Account}>
            Account Settings
          </Link>
          .
        </Trans>
      ),
    });
  }

  function shouldDisable(values: FormikValues) {
    return values.every((value: SocialAccount) => !value.smart_schedule);
  }

  if (!showDialog && smart_schedule_viewed) {
    return null;
  }

  return (
    <Dialog fullWidth open={showDialog} onClose={handleDisable} maxWidth="xs">
      <IconButton sx={styles.iconButton} onClick={handleDisable} size="large">
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleActivate}>
          {({ values }) => (
            <Form>
              <Box textAlign="center">
                <img src={getAppImage('schedule.png', 180).src} alt="Smart Scheduler" />
              </Box>
              <Typography paragraph align="center" variant="h2">
                {t('components:smartSchedulerTitle')}
              </Typography>
              <Typography align="center">
                <Trans key="components:smartSchedulerDescription">
                  Stay active on social media even while you’re busy! Smart Scheduler will schedule
                  the best and most relevant content for you to share across your preferred networks
                  on a regular basis.{' '}
                  <Link
                    underline="always"
                    target="_blank"
                    href="https://help.everyonesocial.app/hc/en-us/articles/8531065142807"
                  >
                    Learn More
                  </Link>{' '}
                  about how Smart Scheduler works.
                </Trans>
              </Typography>
              <Box paddingY={3} paddingX={4}>
                <Grid container>
                  <Grid xs>
                    <Typography>{t('common:account')}</Typography>
                  </Grid>
                  <Grid>
                    <Typography>{t('common:enable')}</Typography>
                  </Grid>
                </Grid>
                <Box marginTop={1} marginBottom={1}>
                  <Divider />
                </Box>
                <List>
                  {connections.map((_, index) => (
                    <SmartScheduleConnection key={index} index={index} />
                  ))}
                </List>
                <Box marginTop={1} marginBottom={1}>
                  <Divider />
                </Box>
              </Box>
              <Box marginBottom={3}>
                <Grid container justifyContent="space-evenly">
                  <Grid>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={handleDisable}
                      sx={styles.button}
                      disabled={activeLoader !== ActiveLoader.None || isLoading}
                    >
                      {activeLoader === ActiveLoader.Disable ? (
                        <Loaders.Standard height={26} />
                      ) : (
                        t('components:smartSchedulerDisableAll')
                      )}
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      sx={styles.button}
                      disabled={
                        activeLoader !== ActiveLoader.None || isLoading || shouldDisable(values)
                      }
                    >
                      {activeLoader === ActiveLoader.Activate ? (
                        <Loaders.Standard height={26} />
                      ) : (
                        t('components:smartSchedulerSaveAndActivate')
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default SmartSchedulerDialog;
