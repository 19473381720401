import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { Network } from 'api';
import { useConnectAccount } from 'hooks';
import { SocialIcon } from 'components';

import { getShareConnectionOption } from '../../helpers';
import theme from 'theme';

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    borderRadius: 2,
    boxShadow: '0 1px 15px 0 rgba(162,162,162,0.12)',
  },
  tableRow: {
    verticalAlign: 'top',
  },
  tableCell: {
    borderBottom: 0,
  },
} as const;

interface Props {
  partnerName: string;
  networkName: string;
  network: Network;
  open: boolean;
  onClose: () => void;
}

const Interstitial: FC<Props> = ({ partnerName, networkName, network, open, onClose }) => {
  const { t } = useTranslation();
  const connectAccount = useConnectAccount(network, getShareConnectionOption());

  return (
    <Dialog open={open} fullScreen sx={{ paper: { backgroundColor: theme.palette.grey[300] } }}>
      <Grid container justifyContent="center">
        <Grid>
          <Box padding={2} maxWidth={480}>
            <Box marginTop={4} marginBottom={6}>
              <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid>
                  <Box sx={styles.wrapper}>
                    <SocialIcon provider={network} size={64} />
                  </Box>
                </Grid>
                <Grid>
                  <SwapHorizIcon fontSize="large" />
                </Grid>
                <Grid>
                  <Box sx={styles.wrapper}>
                    <img
                      alt="EveryoneSocial Icon"
                      height={64}
                      width={64}
                      src="/images/everyonesocial-icon.png"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Typography variant="h4">
              {t('api:partnerShareHeadline', { partnerName, networkName })}
            </Typography>
            <Box marginY={4}>
              <Table>
                <TableBody>
                  <TableRow sx={styles.tableRow}>
                    <TableCell sx={styles.tableCell}>
                      <SettingsEthernetIcon />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Typography variant="subtitle2">{t('api:partnerShareSubHead1')}</Typography>
                      <Typography>{t('api:partnerShareSubHead1Bullet1')}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={styles.tableRow}>
                    <TableCell sx={styles.tableCell}>
                      <AccountBoxIcon />{' '}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Typography variant="subtitle2">{t('api:partnerShareSubHead2')}</Typography>
                      <Typography>{t('api:partnerShareSubHead2Bullet1')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Grid container alignItems="center" justifyContent="center" direction="column">
              <Grid item xs={12}>
                <Button onClick={connectAccount} variant="contained" color="primary" size="large">
                  {t('common:continue')}
                </Button>
              </Grid>
              <Grid>
                <Button onClick={onClose}>{t('common:cancel')}</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Interstitial;
