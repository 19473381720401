import useInfiniteScroll from 'react-infinite-scroll-hook';
import { InfiniteData, UseInfiniteQueryOptions } from 'react-query';

import { useGetFeedQuery, GetFeedQuery, GetFeedQueryVariables, ContentFragment } from 'api';
import { InfiniteQuery } from 'types/app';
import { useInfiniteQuery } from 'app-react-query/hooks';
import { useMonitorPost, useNotify } from 'hooks';
import { PAGINATION_ROOT_MARGIN } from 'app-constants';

type Query = GetFeedQuery;
type Variables = GetFeedQueryVariables;
type Options = UseInfiniteQueryOptions<Query>;
type ReturnType = InfiniteQuery<ContentFragment>;

const REFETCH_INTERVAL = 1000 * 5;

const useGetFeed = (variables: Variables, options?: Options): ReturnType => {
  const notify = useNotify();
  const { state, dispatch } = useMonitorPost();

  function getResultIds(resultsData: InfiniteData<GetFeedQuery>) {
    return (
      resultsData?.pages
        ?.map(page => page.getFeed.results.map(result => result.content_id))
        .flat() ?? []
    );
  }

  function getResults(resultsData: InfiniteData<GetFeedQuery>) {
    return resultsData?.pages?.map(page => page.getFeed.results).flat() ?? [];
  }

  /**
   * This method ensures a created post is present in the feed
   * before replacing results with incoming server state
   */
  function handleIsDataEqual(
    oldData: InfiniteData<GetFeedQuery>,
    newData: InfiniteData<GetFeedQuery>
  ) {
    if (!state.size) {
      return false;
    }

    const oldIds = getResultIds(oldData);
    const newIds = getResultIds(newData);

    // We're assuming the number of in-flight posts is never more than the size of a page
    const isPaginatedResult = newIds.length - oldIds.length > state.size;
    if (isPaginatedResult) {
      return false;
    }

    // Check for monitored id in new data
    let matchFound = false;
    for (const id of state.values()) {
      if (newIds.includes(id)) {
        matchFound = true;
        dispatch({ type: 'unwatch', id });
      }
    }

    return !matchFound;
  }

  if (state.size) {
    options = {
      ...options,
      refetchInterval: REFETCH_INTERVAL,
      isDataEqual: handleIsDataEqual,
    };
  }

  const queryResult = useInfiniteQuery<Query, Variables>(
    useGetFeedQuery,
    ({ pageParam }: { pageParam: Variables }) => ({ ...variables, ...pageParam }),
    {
      onError: notify.queryError,
      getNextPageParam: lastPage => {
        const { getFeed } = lastPage;
        const { results, offset, id_lt } = getFeed;

        const noResults = !results.length;
        const endOfList = !offset && !id_lt;

        if (noResults || endOfList) {
          return false;
        }

        return { offset, id_lt };
      },
      ...options,
    }
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = queryResult;
  const feedItems = getResults(data);

  const [sentinelRef] = useInfiniteScroll({
    hasNextPage,
    loading: isFetchingNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: PAGINATION_ROOT_MARGIN,
  });

  return {
    sentinelRef,
    results: feedItems,
    count: feedItems.length,
    isEmpty: !feedItems.length && !isFetching,
    isExhausted: !hasNextPage,
    isInitialLoad: !feedItems.length && isFetching,
  };
};

export default useGetFeed;
