import React, { FC, useEffect, useRef, useState } from 'react';
import { UseMutateFunction } from 'react-query';
import { Box, IconButton, Typography } from '@mui/material';
import Favorite from '@mui/icons-material/Favorite';

import { CreateReactionMutation, DeleteReactionMutation, ReactionType } from 'api';
import palette from 'theme/palette';

type CreateReaction = UseMutateFunction<
  CreateReactionMutation,
  unknown,
  { content_id: string; reaction_type: ReactionType }
>;
type DeleteReaction = UseMutateFunction<
  DeleteReactionMutation,
  unknown,
  { content_id: string; reaction_type: ReactionType }
>;

interface HandlerState {
  createReaction?: CreateReaction;
  deleteReaction?: DeleteReaction;
  isLoading: boolean;
  isIdle: boolean;
  isSuccess: boolean;
}

interface Props {
  liked: boolean;
  onClick: () => void;
  handlerState: HandlerState;
  count: number;
}

const styles = {
  icon: {
    fontSize: 24,
  },
  favorite: {
    fill: palette.red,
  },
  favoriteTransition: {
    fill: 'error.dark',
  },
  favoriteBorder: {
    fill: palette.grey[400],
    '&:hover': {
      fill: palette.red,
    },
  },
  favoriteBorderTransition: {
    fill: palette.grey[400],
    '&:hover': {
      fill: palette.red,
    },
  },
  favoriteChanged: {
    animation: 'heart 600ms ease-in-out',
  },
  button: {
    padding: 0,

    '&:hover': {
      background: 'transparent',
    },
  },
  text: {
    lineHeight: 'inherit',
  },
} as const;

const Heart: FC<Props> = ({ count, liked, onClick, handlerState }) => {
  const [changed, setChanged] = useState(false);
  const hasMountRan = useRef(false);
  useEffect(() => {
    if (!hasMountRan.current) {
      hasMountRan.current = true;
      return () => {
        hasMountRan.current = liked;
      };
    }

    setChanged(liked);
    return () => {
      hasMountRan.current = liked;
    };
  }, [liked]);

  const favoriteBorderstyles = { ...styles.icon, ...styles.favoriteBorder };
  const favoritestyles = {
    ...styles.icon,
    ...styles.favorite,
    ...(changed ? styles.favoriteChanged : {}),
  };
  const favoriteBorderstylesTransition = { ...styles.icon, ...styles.favoriteBorderTransition };
  const favoritestylesTransition = {
    ...styles.icon,
    ...styles.favoriteTransition,
    ...(changed ? styles.favoriteChanged : {}),
  };

  const renderBody = () => {
    if (handlerState.isLoading) {
      return <Favorite sx={liked ? favoriteBorderstylesTransition : favoritestylesTransition} />;
    }

    if (handlerState.isIdle || handlerState.isSuccess) {
      return <Favorite sx={liked ? favoritestyles : favoriteBorderstyles} />;
    }

    return null;
  };

  return (
    <IconButton
      data-testid="post-actions-like"
      sx={styles.button}
      onClick={handlerState.isLoading ? () => void 0 : onClick}
      size="large"
    >
      {renderBody()}
      {!!count && (
        <Box marginLeft={0.5}>
          <Typography sx={styles.text} variant="subtitle2">
            {count}
          </Typography>
        </Box>
      )}
    </IconButton>
  );
};

export default Heart;
