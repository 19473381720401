const styles = {
  paper: {
    margin: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  hasPopupIcon: {},
  popupIndicator: {
    padding: 0,
  },
  popupIndicatorOpen: {
    transform: 'none',
  },
  endAdornment: {
    top: 'calc(50% - 16px)',
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"]': {
      '$hasPopupIcon, &': {
        paddingRight: 13,
        minHeight: 60,
      },
      '& $endAdornment': {
        right: 20,
      },
    },
  },
  clearIndicator: {
    marginRight: 1,
    backgroundColor: 'grey.300',
    '&:hover': {
      backgroundColor: 'grey.300',
    },
  },
  option: {
    color: 'text.secondary',
    fontWeight: 'fontWeightRegular',
    '&[aria-selected="true"]': {
      color: 'text.primary',
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      color: 'text.primary',
    },
    '&:active': {
      color: 'text.primary',
      backgroundColor: 'transparent',
    },
  },
} as const;

export default styles;
