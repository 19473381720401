import React, { FC } from 'react';
import { useField } from 'formik';
import { Typography } from '@mui/material';

import { ShareItem } from '../types';

interface Props {
  index: number;
}

const Count: FC<Props> = ({ index }) => {
  const [field] = useField<ShareItem>(`items.${index}`);

  const { value } = field;
  const { user_commentary, state } = value;

  const difference = state.limit - (user_commentary || '').length;
  const currentCount = user_commentary?.length ?? 0;

  return (
    <Typography
      variant="overline"
      sx={{
        ...(difference < 0 && { color: 'error.main' }),
        ...(difference <= 20 && { color: 'orange' }),
        ...(difference >= 21 && { color: 'grey.600' }),
      }}
    >
      {currentCount}/{state.limit}
    </Typography>
  );
};

export default Count;
