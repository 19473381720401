const ua = window.navigator.userAgent;
const obsoleteBrowser = ua.indexOf('MSIE '); // IE 10 or older
const outdatedBrowser = ua.indexOf('Trident/'); //IE 11

if (obsoleteBrowser > 0) {
  const child = document.getElementById('root');
  child.parentNode.removeChild(child);
  document.getElementById('obsolete').style.display = 'block';
}

if (outdatedBrowser > 0) {
  document.getElementById('outdated').style.display = 'block';
}
