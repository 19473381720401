import React, { FC, createContext } from 'react';
import { useCheckedState } from '../hooks';

const CheckedStateContext = createContext(null);

const CheckedStateProvider: FC = ({ children }) => {
  const checkedState = useCheckedState();

  return (
    <CheckedStateContext.Provider value={checkedState}>{children}</CheckedStateContext.Provider>
  );
};

export { CheckedStateContext, CheckedStateProvider };
