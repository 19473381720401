import React, { FC } from 'react';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { Network, SharesItemFragment } from 'api';
import { SocialIcon } from 'components';

interface Props {
  shareItem: SharesItemFragment;
}

const Account: FC<Props> = ({ shareItem }) => {
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid>
        <SocialIcon provider={shareItem.network as Network} />
      </Grid>
      <Grid>
        <Typography>{shareItem.network_profile_name}</Typography>
      </Grid>
    </Grid>
  );
};

export default Account;
