import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSlate } from 'slate-react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded';
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded';
import FormatUnderlinedRoundedIcon from '@mui/icons-material/FormatUnderlinedRounded';

import { isMarkActive, toggleMark } from '../helpers';
import { Marks } from '../types';
import { useSelectionContext } from '../Contexts';

const MarkGroup: FC = () => {
  const editor = useSlate();
  const { t } = useTranslation();
  const { actions: selectionActions } = useSelectionContext();

  function isActiveMark(format: Marks) {
    return isMarkActive(editor, format);
  }

  function handleToggleMark(event: MouseEvent<HTMLElement>, formats: Marks[]) {
    selectionActions.ensureEditorFocus();
    formats.forEach(format => toggleMark(editor, format));
  }

  return (
    <ToggleButtonGroup onChange={handleToggleMark}>
      <ToggleButton title={t('components:editorBold')} selected={isActiveMark('bold')} value="bold">
        <FormatBoldRoundedIcon />
      </ToggleButton>
      <ToggleButton
        title={t('components:editorItalic')}
        selected={isActiveMark('italic')}
        value="italic"
      >
        <FormatItalicRoundedIcon />
      </ToggleButton>
      <ToggleButton
        title={t('components:editorUnderline')}
        selected={isActiveMark('underline')}
        value="underline"
      >
        <FormatUnderlinedRoundedIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default MarkGroup;
