import React, { FC } from 'react';
import { Typography } from '@mui/material';

import { Content, ContentInput } from 'api';
import { getProviderDisplay } from '../helpers';

interface IProps {
  content: ContentInput | Content;
}

const Provider: FC<IProps> = ({ content }) => {
  const name = getProviderDisplay(content);
  return <Typography>{name}</Typography>;
};

export default Provider;
