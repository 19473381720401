import React, { createContext, ReactElement, useContext, useState } from 'react';

export interface UploadCount {
  uploadCount: number;
  inc: () => void;
  dec: () => void;
}

const UploadCountContext = createContext<UploadCount>(null);

export interface UploadCountProps {
  children: ReactElement | ReactElement[];
}

export const UploadCountProvider = ({ children }: UploadCountProps) => {
  const [uploadCount, setUploadCount] = useState(0);

  function inc() {
    setUploadCount(value => value + 1);
  }

  function dec() {
    setUploadCount(value => Math.max(value - 1, 0));
  }

  return (
    <UploadCountContext.Provider value={{ uploadCount, inc, dec }}>
      {children}
    </UploadCountContext.Provider>
  );
};

export const useUploadCount = () => useContext(UploadCountContext);
