import React, { createContext, Dispatch, SetStateAction, FC, useState } from 'react';
import { filter } from 'lodash';

import { ContentCommentary, Network } from 'api';
import { useContent, useReleases } from 'hooks';
import { randomNumberGenerator } from 'utilities';

interface Value {
  networkCommentaries: ContentCommentary[];
  networkShareCopyIndex: number;
  setNetworkShareCopyIndex: Dispatch<SetStateAction<number>>;
}

export const ShareCopyContext = createContext<Value>({
  networkCommentaries: [],
  networkShareCopyIndex: 0,
  setNetworkShareCopyIndex: () => undefined,
});

interface Props {
  network: Network;
}

export const ShareCopyProvider: FC<Props> = ({ network, children }) => {
  const content = useContent();
  const releases = useReleases();
  const { commentary } = content;

  const networkCommentaries = filter(commentary, ['network', network]);
  const [networkShareCopyIndex, setNetworkShareCopyIndex] = useState(getInitialCommentaryIndex());

  function getInitialCommentaryIndex() {
    const hasVariations = releases.includes('shareVariations');
    return hasVariations ? randomNumberGenerator(networkCommentaries?.length || 0) : 0;
  }

  const values = {
    networkCommentaries,
    networkShareCopyIndex,
    setNetworkShareCopyIndex,
  };

  return <ShareCopyContext.Provider value={values}>{children}</ShareCopyContext.Provider>;
};
