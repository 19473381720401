import { InfiniteData, UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  FeedKey,
  FollowMutation,
  FollowMutationVariables,
  GetGroupFollowersQuery,
  GetGroupFollowersQueryVariables,
  GroupRole,
  useGetGroupFollowersQuery,
  UserFeedFollowFragment,
} from 'api';
import { useNotify, useQueryVariables } from 'hooks';

type Mutation = FollowMutation;
type MutationVariables = FollowMutationVariables;
type QueryData = InfiniteData<GetGroupFollowersQuery>;
type QueryVariables = GetGroupFollowersQueryVariables;

const useFollow = () => {
  const queryClient = useQueryClient();
  const notify = useNotify();

  const variables = useQueryVariables<QueryVariables>();
  const queryKey = useGetGroupFollowersQuery.getKey(variables);

  const mutationOptions: UseMutationOptions<Mutation, unknown, MutationVariables> = {
    onMutate: async ({ target_user_id }) => {
      await queryClient.cancelQueries(queryKey);

      const newFeedFollow: UserFeedFollowFragment = {
        followed_by_user: {
          id: target_user_id,
          full_name: '',
          job_title: '',
          department_title: '',
          user_status: null,
          user_type: null,
          follower_count: 0,
          email_address: '',
        },
        user_id: target_user_id,
        postable: false,
        created_at: Date.now(),
        feed: FeedKey.Group,
        group_role: GroupRole.Member,
      };

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.pages[0].feedFollowing.feed_follows.splice(0, 0, newFeedFollow);
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
    onSettled: ({ follow }, error, mutationArgs) => {
      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.pages.forEach(page => {
            const index = page.feedFollowing.feed_follows.findIndex(feedFollow => {
              return feedFollow.user_id === mutationArgs.target_user_id;
            });

            page.feedFollowing.feed_follows[index] = follow;
          });
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }
    },
  };

  return mutationOptions;
};

export default useFollow;
