import React, { FC, ReactNode } from 'react';

import { GetGroupQueryVariables, GroupFragment, useGetGroupQuery } from 'api';
import { LoaderTypes } from 'types/app';

import { EmptyState, QueryManager } from 'components';

interface Props {
  variables: GetGroupQueryVariables;
  children: (data: GroupFragment) => ReactNode;
  loader: LoaderTypes;
}

const GetGroup: FC<Props> = ({ loader, children, variables }) => {
  const queryResult = useGetGroupQuery(variables);

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.getGroup) {
          return <EmptyState name="privateGroup" />;
        }

        return children(data.getGroup);
      }}
    </QueryManager>
  );
};

export default GetGroup;
