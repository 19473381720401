import { cloneDeepWith, unescape } from 'lodash';
import { QueryClient } from 'react-query';
import { matchPath } from 'react-router-dom';
import UAParser from 'ua-parser-js';
import * as yup from 'yup';

let scrollBlocks: string[] = [];

export const isRoot = () => {
  return window.location.hostname.split('.').length === 2;
};

export const getRootDomain = () => {
  if (isRoot()) {
    return window.location.hostname;
  }

  return window.location.hostname.split('.').splice(1).join('.');
};

export const getRootUrl = () => {
  const rootDomain = getRootDomain();
  return `${window.location.protocol}//${rootDomain}`;
};

export const popup = (url: string, title: string, width: number, height: number) => {
  let windowParams = `scrollbars=yes, width=${width}, height=${height}`;
  try {
    const { top } = window;
    const x = Math.round((top.outerWidth - width) / 2 + top.screenX);
    const y = Math.round((top.outerHeight - height) / 2 + top.screenY);
    windowParams += `, top=${y}, left=${x}`;
  } catch (e) {
    // We are in an iframe from a different domain and don't have access to `top` - there will be no x and y param
  }

  const newWindow = window.open(url, title, windowParams);
  if (newWindow?.focus) {
    newWindow.focus();
  }

  return newWindow;
};

export const queryString = (params: Record<string, unknown>) => {
  return Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
};

/**
 * Given source dimensions and maxWidth, return the scaled height and maxWidth
 * This isn't perfect because `maxHeight` is theoretically Infinity
 */
export const resizeImage = (srcWidth: number, srcHeight: number, maxWidth: number) => {
  const portraitRatio = +(srcHeight / srcWidth).toFixed(2);
  const height = Math.round(portraitRatio * maxWidth);

  return { height, width: maxWidth };
};

export const isPathMatch = (routes: string[]) => {
  return routes.some(routeToTest => {
    const matchedPath = matchPath(routeToTest, window.location.pathname);
    return !!matchedPath;
  });
};

export const isEven = (index: number) => index % 2 === 0;

export const validateUrl = (url: string) =>
  yup
    .string()
    .test('is-url', 'Invalid URL', value => {
      if (!value) return true;
      const urlPattern = /^(https?):\/\/|\w+\.\w+/;
      return urlPattern.test(value);
    })
    .required()
    .isValidSync(url);

export const validateImageContent = (file: File) => {
  const imageElement = new Image();
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    imageElement.onload = () => {
      resolve(true);
    };

    imageElement.onerror = () => {
      reject(false);
    };

    fileReader.onload = event => {
      imageElement.src = event.target.result as string;
    };

    fileReader.readAsDataURL(file);
  });
};

export const getRenderingEngine = () => {
  const parser = new UAParser();
  return parser.getEngine().name;
};

export const optimisticUpdateReactQueryCache = (
  queryClient: QueryClient,
  newItem: object,
  idAttr?: string
) => {
  if (!idAttr) {
    idAttr = 'id';
  }
  for (const query of queryClient.getQueryCache().getAll()) {
    let change = false;
    const newData = cloneDeepWith(query.state.data, value => {
      if (value && typeof value === 'object' && value[idAttr] === newItem[idAttr]) {
        change = true;
        return newItem;
      }
      return undefined;
    });
    if (change) {
      query.setData(newData);
    }
  }
};

export const getOrdinalSuffixOf = (number: number) => {
  const modulus10 = number % 10;
  const modulus100 = number % 100;

  if (modulus10 == 1 && modulus100 !== 11) {
    return 'st';
  }
  if (modulus10 == 2 && modulus100 !== 12) {
    return 'nd';
  }
  if (modulus10 == 3 && modulus100 !== 13) {
    return 'rd';
  }

  return 'th';
};

export const randomNumberGenerator = (length: number) => {
  return Math.floor(Math.random() * length);
};

export const pluralize = (word: string, num: number) => {
  return num === 1 ? word : `${word}s`;
};

export const isNullOrUndefined = function (value: any) {
  return value === null || value === undefined;
};

export const escapeRegex = (string: string) => {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const toggleScroll = (key: string, block: boolean) => {
  scrollBlocks = scrollBlocks.filter(k => k !== key);

  if (block) {
    document.body.style.overflow = 'hidden';
    scrollBlocks.push(key);
  } else if (!scrollBlocks.length) {
    document.body.style.overflow = null;
  }
};

export const isHTML = (str: string) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
};

export const convertHTMLToText = (str: string) => {
  if (!str) {
    return '';
  }

  return unescape(str.replace(/<\/?[^>]+(>|$)/g, ''));
};

export const getCustomGridWidth = (size: number) => {
  return `${Math.round((size / 12) * 10e7) / 10e5}%`;
};
