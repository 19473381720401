import React, { FC } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { CreateGroupInput } from 'api';
import { ValidatedTextField } from 'components';
import theme from 'theme';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

const styles = {
  content: {
    padding: theme.spacing(5),
    '&:first-of-type': {
      paddingTop: theme.spacing(5),
    },
  },
  button: {
    borderRadius: 4,
    flex: 2,
  },
  actions: {
    margin: theme.spacing(2, 0, 0, 0),
    justifyContent: 'center',
  },
  input: {
    width: theme.spacing(50),
    margin: theme.spacing(2, 0),
  },
  gutter: {
    flex: 1,
  },
} as const;

const GroupCreateAlert: FC<Props> = ({ isOpen, onCancel }) => {
  const { t } = useTranslation();

  const { isValid, submitForm } = useFormikContext<CreateGroupInput>();

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogContent sx={styles.content}>
        <Typography gutterBottom align="center" variant="subtitle2">
          {t('groups:createNewGroup')}
        </Typography>

        <Box sx={styles.input}>
          <ValidatedTextField name="title" i18nKey={t('groups:enterGroupName')} removeLabel />
        </Box>

        <DialogActions sx={{ root: styles.actions }}>
          <Grid sx={styles.gutter} />
          <Button variant="text" onClick={onCancel} size="large" sx={{ flex: 2 }}>
            {t('common:cancel')}
          </Button>

          <Button
            disabled={!isValid}
            onClick={submitForm}
            size="large"
            color="primary"
            variant="contained"
            autoFocus
            sx={styles.button}
          >
            {t('common:save')}
          </Button>
          <Grid sx={styles.gutter} />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default GroupCreateAlert;
