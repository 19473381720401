import gql from 'graphql-tag';

export default gql`
  fragment ActivityStat on ActivityStat {
    comments
    reactions
    posts
    shares
    engagements
    clicks
    impressions
    calendar_day
  }
`;
