import React, { FC } from 'react';
import { ListItemText, MenuItem, Typography } from '@mui/material';
import theme from 'theme';

interface Props {
  text: string;
  onClick: (q: string) => void;
}

const styles = {
  listItem: {
    paddingTop: 1,
    paddingBottom: 1,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  weight: {
    fontWeight: 'fontWeightRegular',
  },
} as const;

const Suggestion: FC<Props> = ({ text, onClick }) => (
  <MenuItem key={text} sx={styles.listItem} onClick={() => onClick(text)}>
    <ListItemText disableTypography>
      <Typography title={text} variant="subtitle2" noWrap sx={styles.weight}>
        {text}
      </Typography>
    </ListItemText>
  </MenuItem>
);

export default Suggestion;
