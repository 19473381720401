import React, { FC } from 'react';
import { Box } from '@mui/material';
import theme from 'theme';

const styles = {
  root: {
    display: 'inline-flex',
    background: theme.palette.background.default,
    borderRadius: 20,
  },
} as const;

const RangePickerWrapper: FC = ({ children }) => {
  return <Box sx={styles.root}>{children}</Box>;
};

export default RangePickerWrapper;
