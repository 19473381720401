import React, { FC, Fragment } from 'react';
import { CardContent, CardHeader, Link, Typography } from '@mui/material';

import { UserFeedFollowGroupFragment } from 'api';
import { UserAvatar } from 'components';
import { getFullName } from 'helpers';
import { useContent } from 'hooks';
import { generateLink } from 'Links';

import { hasCreator } from '../helpers';
import { Body, PostMenu } from '../widgets';

interface Props {
  selectedGroup: UserFeedFollowGroupFragment;
  isDeletedUser: boolean;
}

const User: FC<Props> = ({ isDeletedUser }) => {
  const content = useContent();
  const { user, content_object } = content;
  const { body } = content_object;
  const hasPostCreator = hasCreator(content);

  function renderName() {
    if (isDeletedUser) {
      return <Typography variant="subtitle2">{getFullName(user)}</Typography>;
    }

    return (
      <Link
        title={getFullName(user)}
        component={generateLink(`/profile/${user.id}`)}
        variant="subtitle2"
        noWrap
        underline="hover"
      >
        {getFullName(user)}
      </Link>
    );
  }

  if (!hasPostCreator) {
    if (!body) {
      return null;
    }

    return (
      <CardContent>
        <Body />
      </CardContent>
    );
  }

  return (
    <Fragment>
      <CardHeader
        avatar={<UserAvatar link size={40} user={!isDeletedUser ? user : { id: '' }} />}
        title={renderName()}
        action={<PostMenu />}
      />
      {body && (
        <CardContent>
          <Body />
        </CardContent>
      )}
    </Fragment>
  );
};

export default User;
