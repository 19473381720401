import React, { ChangeEvent, FC, Fragment } from 'react';
import { Link, MenuItem, Select, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, getIn, useFormikContext } from 'formik';
import { findIndex } from 'lodash';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';

import { Capability, SocialIcon } from 'components';
import { Content, Network } from 'api';
import { useCapabilities, useNetworkCapabilities, useProtectedClient } from 'hooks';
import * as Links from 'Links';

import { usePostContext } from '../../PostContext';
import { Switch } from '../';
import Widget from './WidgetContainer';

interface Props {
  disabled?: boolean;
}

const Disclosures: FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();
  const { isAdmin } = useCapabilities();
  const { values, setFieldValue } = useFormikContext<Content>();
  const { disclosures } = useProtectedClient();
  const { state, dispatch } = usePostContext();
  const { hasTwitter } = useNetworkCapabilities();

  function indexOfNetwork(network: Network) {
    const selectedDisclosures = values.disclosures || [];
    let index = findIndex(selectedDisclosures, ['network', network]);
    if (index < 0) {
      index = selectedDisclosures.length;
      setFieldValue(`disclosures[${index}]`, { network, disclosure_id: '' });
    }
    return index;
  }

  function onChangeSwitch() {
    if (state.disclosures) {
      setFieldValue(`disclosures.0`, { network: Network.Twitter, commentary: '' });
      setFieldValue(`disclosures.1`, { network: Network.Linkedin, commentary: '' });
      setFieldValue(`disclosures.2`, { network: Network.Facebook, commentary: '' });
      setFieldValue(`disclosures.3`, { network: Network.Instagram, commentary: '' });
    }

    dispatch({ type: 'disclosures' });
  }

  function renderNetwork(network: Network) {
    const index = indexOfNetwork(network);
    const fieldName = `disclosures[${index}].disclosure_id`;
    const value = getIn(values, fieldName) || '';

    function onChangeSelect(event: ChangeEvent<HTMLInputElement>) {
      setFieldValue(`disclosures[${index}]`, { network, disclosure_id: event.target.value });
    }

    return (
      <Grid xs={12} container alignItems="center" marginBottom={2}>
        <Grid xs={1}>
          <SocialIcon
            state={disabled ? 'disabled' : !value ? 'disabled' : 'active'}
            provider={network}
          />
        </Grid>
        <Grid xs={10}>
          <Field name={`disclosures[${index}]`}>
            {() => (
              <Select
                fullWidth
                variant="outlined"
                displayEmpty
                value={value}
                onChange={onChangeSelect}
                MenuProps={{ MenuListProps: { disablePadding: true } }}
                disabled={disabled}
              >
                <MenuItem value="">{t('posting:none')}</MenuItem>
                {disclosures.map(disclosure => (
                  <MenuItem key={disclosure.id} value={disclosure.id}>
                    {disclosure.disclosure}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Field>
        </Grid>
      </Grid>
    );
  }

  function renderBody() {
    return (
      <Grid container marginTop={2}>
        <Grid xs={1} />
        <Grid xs={11} container>
          <Fragment>{hasTwitter && renderNetwork(Network.Twitter)}</Fragment>

          <Fragment>{renderNetwork(Network.Linkedin)}</Fragment>

          <Capability name="Sharing.Function.ShareToFacebook">
            {renderNetwork(Network.Facebook)}
          </Capability>
          <Capability name="Sharing.Function.ShareToInstagram">
            {renderNetwork(Network.Instagram)}
          </Capability>
          {isAdmin && (
            <Grid xs={12} container alignItems="center" marginBottom={1}>
              <Grid xs={1} />
              <Grid>
                {disabled ? (
                  <Typography color="primary" sx={disabled && { opacity: 0.5 }}>
                    {t('posting:manageDisclosures')}
                  </Typography>
                ) : (
                  <Link
                    color="primary"
                    component={Links.AdminSettingsDisclosures}
                    underline="hover"
                  >
                    {t('posting:manageDisclosures')}
                  </Link>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <Widget
      titleKey="posting:disclosures"
      subtitleKey="posting:guidelineDisclosures"
      icon={<PlaylistAddRoundedIcon color={state.disclosures ? 'inherit' : 'disabled'} />}
      toggle={
        <Switch
          checked={state.disclosures}
          onChange={onChangeSwitch}
          color="primary"
          data-testid="post-radio-disclosures"
          disabled={disabled}
        />
      }
      disabled={disabled}
    >
      {state.disclosures && renderBody()}
    </Widget>
  );
};

export default Disclosures;
