import { queryString } from 'utilities';
import { LOCAL_STORAGE_KEY_PARTNER_SHARE_NETWORKS } from 'app-constants';

import { ShareDisplayOption } from 'features/sharing/types';

const MESSAGE = 'EveryoneSocial';

const usePartnerNotify = (redirectUrl: string, displayOption: ShareDisplayOption) => {
  function getSuccessParams() {
    const networks = localStorage.getItem(LOCAL_STORAGE_KEY_PARTNER_SHARE_NETWORKS) || '';
    const params = {
      networks,
      state: 'success',
      time: Math.round(Date.now() / 1000),
    };

    localStorage.removeItem(LOCAL_STORAGE_KEY_PARTNER_SHARE_NETWORKS);
    return params;
  }

  function getErrorParams(reason: string) {
    localStorage.removeItem(LOCAL_STORAGE_KEY_PARTNER_SHARE_NETWORKS);

    return {
      state: 'error',
      reason,
    };
  }

  function redirect(params: Record<string, unknown>) {
    window.location.href = `${encodeURI(redirectUrl)}?${queryString(params)}`;
  }

  function postMessageToOpener(params: Record<string, unknown>) {
    window?.opener?.postMessage({ message: MESSAGE, ...params }, '*');
    window.close();
  }

  function postMessageToParent(params: Record<string, unknown>) {
    window?.parent?.postMessage({ message: MESSAGE, ...params }, '*');
  }

  function notify(reason?: string) {
    const params = !reason ? getSuccessParams() : getErrorParams(reason);

    if (displayOption === ShareDisplayOption.Page) {
      redirect(params);
    }

    if (displayOption === ShareDisplayOption.Popup) {
      postMessageToOpener(params);
    }

    if (displayOption === ShareDisplayOption.iFrame) {
      postMessageToParent(params);
    }
  }

  return notify;
};

export default usePartnerNotify;
