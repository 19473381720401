import React, { FC } from 'react';
import { useField } from 'formik';
import { Switch, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { SocialIcon } from 'components';

import { ShareItem } from '../../types';

interface Props {
  index: number;
}

const PartnerShareDirect: FC<Props> = ({ index }) => {
  const [field] = useField<ShareItem>(`items.${index}`);
  const { name, value, onChange } = field;
  const { active, networkMeta } = value.state;

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid>
        <SocialIcon state={active ? 'active' : 'disabled'} provider={networkMeta.provider} />
      </Grid>
      <Grid xs>
        <Typography variant="subtitle2">Facebook</Typography>
      </Grid>
      <Grid>
        <Switch name={`${name}.state.active`} checked={active} onChange={onChange} />
      </Grid>
    </Grid>
  );
};

export default PartnerShareDirect;
