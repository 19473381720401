import React, { FC, MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '@mui/material';

import { useSendSignOnEmailMutation } from 'api/public';
import { PaddedPaper } from 'components';
import { useGlobalClient, useNotify } from 'hooks';
import { getAppImage } from 'helpers';
import cookieStorage from 'cookie-storage';

const styles = {
  root: {
    maxWidth: 500,
  },
} as const;

const CheckEmail: FC = () => {
  const client = useGlobalClient();
  const notify = useNotify();
  const { t } = useTranslation();

  const { mutate } = useSendSignOnEmailMutation({
    onError: notify.mutationError,
    onSuccess: () => notify.info({ message: t('auth:checkEmailResend') }),
  });

  function handleMutate(event: MouseEvent) {
    event.preventDefault();
    mutate({ email: cookieStorage.userEmail, client_id: client?.id ?? null });
  }

  function renderMain() {
    return (
      <PaddedPaper elevation={0} sx={styles.root} padding="64px 48px">
        <Box textAlign="center">
          <img width={200} height={200} {...getAppImage('illustrations/airplane.png', 200)} />
        </Box>
        <Typography paragraph align="center" variant="h2">
          {t('auth:checkEmailHeader')}
        </Typography>
        <Typography paragraph align="center">
          {t('auth:checkEmailMessage', { email: cookieStorage.userEmail })}
        </Typography>
        <Typography align="center">
          <Trans i18nKey="auth:checkEmailCheck">
            Didn&apos;t get the email?&nbsp;
            <Link color="primary" href="#" onClick={handleMutate} underline="hover">
              Resend the email
            </Link>
            .
          </Trans>
        </Typography>
      </PaddedPaper>
    );
  }

  return renderMain();
};

export default CheckEmail;
