import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { DailyUserStat, ReportingFilter } from 'api';
import { ConditionalNumberTooltip, PaddedPaper, Queries } from 'components';
import { formatStat } from 'helpers';

import { Change, PieChart, PieChartLoader } from '../shared';
import { PIE_CHART_HEIGHT } from '../constants';

interface Props {
  startDate: string;
  endDate: string;
  filter: ReportingFilter;
}

const ActiveUserStatsChart: FC<Props> = ({ startDate, endDate, filter }) => {
  const { t } = useTranslation();

  function renderChart(queryResults: DailyUserStat) {
    const { summary } = queryResults;
    const { change, active_users, inactive_users } = summary;
    const count = active_users + inactive_users;

    const data = [
      { name: t('common:active'), value: active_users },
      { name: t('common:inactive'), value: inactive_users },
    ];

    return (
      <Fragment>
        <ConditionalNumberTooltip value={count}>
          <Typography variant="h2">{formatStat(count)}</Typography>
        </ConditionalNumberTooltip>
        <Change countChange={change} />
        <PieChart height={PIE_CHART_HEIGHT} data={data} />
      </Fragment>
    );
  }

  return (
    <PaddedPaper>
      <Typography variant="body2">{t('analytics:registered_users')}</Typography>
      <Queries.AnalyticsDailyStats
        type="dailyUserStats"
        loader={<PieChartLoader />}
        filter={filter}
        startDate={startDate}
        endDate={endDate}
      >
        {stats => renderChart(stats)}
      </Queries.AnalyticsDailyStats>
    </PaddedPaper>
  );
};

export default ActiveUserStatsChart;
