import i18n from 'config/i18n';
import { INotify, INotifyTypes } from 'types/app';

export const USER_FEED_NAME: string = i18n.t('components:timeline');
export const USER_FEED_ID = '0';

export const CONNECTION_SUCCESS_MESSAGE = 'connectionSuccess';
export const CONNECTION_FAILURE_MESSAGE = 'connectionFailure';
export const README_SUCCESS_MESSAGE = 'readmeSuccess';
export const OAUTH_SUCCESS_MESSAGE = 'oAuthSuccess';

export const NO_NAME: string = i18n.t('components:unknownName');
export const DEFAULT_SORT = true;

// TypeScript helpers
export const NULL = null as null;

export const APP_IMAGES = {
  Icon: 'icon.png',
  Invalid: '404.png',
  Logo: 'logo.png',
  ReadOnly: 'read-only.png',
  SocialLoading: 'social-loading.png',
  Welcome1: 'welcome1.png',
  Welcome2: 'welcome2.png',
  Error1: 'error-1.png',
  Error2: 'error-2.png',
  Error3: 'error-3.png',
  Error4: 'error-4.png',
};

export const TWITTER_LIMIT = 280;
export const FACEBOOK_LIMIT = 1000;
export const LINKEDIN_LIMIT = 3000;
export const TWITTER_LIMIT_WITH_LINK = 255;
export const INSTAGRAM_LIMIT = 2200;
export const DEFAULT_LIMIT = 280;

export const FOLLOW_QUERIES_LIMIT = 25;
export const GROUP_MEMBERS_LIMIT = 50;
export const FEED_LIMIT_LIST = 10;
export const FEED_LIMIT_GRID = 6;

export const INVITE_REFERRAL_LIMIT = 160;

export const DEFAULT_NOTIFY_OPTIONS: INotify = {
  component: null,
  message: null,
  i18n: null,
  duration: 2000,
  show: false,
  type: INotifyTypes.Info,
};

export const PAGINATION_ROOT_MARGIN = '0px 0px 400px 0px';

export const NOTIFICATION_POPUP_LIMIT = 7;
export const NOTIFICATION_INBOX_LIMIT = 24;

export const EMPTY_SLATE_EDITOR = '<p></p>';

export const PRE_SELF_SERVE_USER_CREATED_AT_TIME = 1630459908;

export const ROOT_DOMAIN_LABEL_FOR_LOGOUT = 'es-root-epXilnud';

export const SHORTENED_NUMBER_CUTOFF = 100000;
export const CONTENT_THUMBNAIL_SIZE = 72;
export const THUMBNAIL_SIZE = 86;

export const ES_SHARE_URL_QUERY_PARAM = 'share-url';
export const NEGATIVE_REGEX = new RegExp(/[^A-Za-z0-9_ ]/);

export const LEADERBOARD_WIDGET_LIMIT = 5;
export const GRID_VIEW_TIMELINE_RENDER_LIMIT = 5;

export const SOCIAL_CONNECTION_SOURCE_TYPES = {
  web_source: 'web sharing',
  express_activation: 'express_activation',
};

export const QUOTES = [
  `"There is no joy in possession without sharing." ― Erasmus`,
  `"We have what we need, if we use what we have." — Edgar Cahn`,
  `"Share your knowledge. It is a way to achieve immortality." — The Dalai Lama`,
  `"There is no delight in owning anything unshared.” — Seneca`,
  `"The miracle is this: The more we share the more we have.” — Leonard Nimoy`,
  `"Everyone needs help from everyone.” — Bertolt Brecht`,
  `"Life is all about sharing. If we are good at something, pass it on." – Mary Berry`,
];

export const LOCAL_STORAGE_KEY_FEED_VIEW = 'esFeedView';
export const LOCAL_STORAGE_KEY_PARTNER_SHARE_NETWORKS = 'esPartnerShareNetworks';
export const LOCAL_STORAGE_KEY_ES_CLIENT = 'esClient';
export const LOCAL_STORAGE_KEY_ACCESS_KEY = 'access_key';
export const LOCAL_STORAGE_KEY_FEED_SORT = 'timelineSort';

export const SESSION_KEY_ES_CLIENT = 'esClient';
export const SESSION_KEY_ES_UUID = 'esUuid';

export const MAX_ADVANCED_FILTER_QUERIES = 6;
