import React, { ChangeEvent, FC } from 'react';
import { useFormikContext } from 'formik';
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';

import { ValidatedSwitch } from 'components';
import { ClientStatus } from 'api';
import { ClientInput } from '../../types';

const Status: FC = () => {
  const { setFieldValue } = useFormikContext<ClientInput>();

  function checkedFn(value: ClientStatus) {
    return value === ClientStatus.Active;
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>, value: boolean) {
    const status = value ? ClientStatus.Active : ClientStatus.Disabled;
    setFieldValue('client_status', status);
  }

  return (
    <Grid container>
      <Grid xs={3}>
        <Typography variant="subtitle2">Status</Typography>
      </Grid>
      <Grid xs>
        <ValidatedSwitch
          name="client_status"
          color="primary"
          label="Active"
          onChange={handleChange}
          checkedFn={checkedFn}
        />
      </Grid>
    </Grid>
  );
};

export default Status;
