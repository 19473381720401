import React, { FC } from 'react';
import { GridSpacing, Unstable_Grid2 as Grid } from '@mui/material';

interface Props {
  padding?: string;
  spacing?: GridSpacing;
}

const Row: FC<Props> = ({ padding, spacing, children }) => (
  <Grid container alignItems="center" wrap="nowrap" padding={padding} spacing={spacing}>
    {children}
  </Grid>
);

Row.defaultProps = {
  padding: '0px',
};

export default Row;
