import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { Network, RoiOverviewStat } from 'api';
import { ConditionalNumberTooltip, Numeric, ValidatedTextFieldNext } from 'components';
import { formatStat } from 'helpers';

import { ROIChartType } from '../../types';

const styles = {
  cell: {
    borderBottom: 'none',
  },
  smallCell: {
    width: 40,
  },
} as const;

interface Props {
  chartType: ROIChartType;
  loading: boolean;
  hideFacebook: boolean;
  result: RoiOverviewStat;
}

const NetworkInput: FC<Props> = ({ chartType, loading, hideFacebook, result }) => {
  const { t } = useTranslation();
  const smallCellClasses = { ...styles.cell, ...styles.smallCell };

  function renderROITable(network: Network) {
    return (
      <Fragment>
        <Typography align="left" color="textSecondary">
          {t(`admin:${network}`)}
        </Typography>
        <Table padding="none">
          <TableBody>
            <TableRow>
              <TableCell sx={styles.cell}>
                <ValidatedTextFieldNext
                  type="tel"
                  name={`${chartType.toLowerCase()}_${network}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ paddingLeft: 2 }}>
                        $
                      </InputAdornment>
                    ),
                    sx: { paddingLeft: 0 },
                  }}
                />
              </TableCell>
              <TableCell align="center" sx={smallCellClasses}>
                <Typography color="textSecondary">x</Typography>
              </TableCell>
              <TableCell align="center" sx={styles.cell}>
                {!loading && (
                  <Typography color="textSecondary">
                    <Numeric value={result.summary[`${network}_count`]} />
                  </Typography>
                )}
              </TableCell>
              <TableCell align="center" sx={smallCellClasses}>
                <Typography color="textSecondary">=</Typography>
              </TableCell>
              <TableCell align="right" sx={styles.cell}>
                {!loading && (
                  <ConditionalNumberTooltip prefix="$" value={result.summary[network]}>
                    <Typography color="textSecondary" sx={styles.cell}>
                      ${formatStat(result.summary[network])}
                    </Typography>
                  </ConditionalNumberTooltip>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Fragment>
    );
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography variant="subtitle2">
          {t(`analytics:roi_${chartType.toLowerCase()}_inputs`)}
        </Typography>
      </Grid>
      <Grid xs={12} marginY={2}>
        {renderROITable(Network.Twitter)}
      </Grid>
      <Grid xs={12} marginBottom={2}>
        {renderROITable(Network.Linkedin)}
      </Grid>
      {!hideFacebook && <Grid xs={12}>{renderROITable(Network.Facebook)}</Grid>}
    </Grid>
  );
};

export default NetworkInput;
