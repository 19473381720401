import React, { FC, ReactNode } from 'react';

import { LoaderTypes } from 'types/app';
import { ClientDepartmentList, useGetClientDepartmentsByStatusQuery } from 'api';
import { QueryManager } from 'components';

interface IProps {
  loader?: LoaderTypes;
  children: (data: ClientDepartmentList) => ReactNode;
}

const GetClientDepartments: FC<IProps> = ({ children, loader }) => {
  const queryResult = useGetClientDepartmentsByStatusQuery();

  return (
    <QueryManager loader={loader} {...queryResult}>
      {({ data }) => {
        if (!data?.getClientDepartmentsByStatus) {
          return children({ client_departments: [], count: 0, paginate: false });
        }

        return children(data.getClientDepartmentsByStatus);
      }}
    </QueryManager>
  );
};

export default GetClientDepartments;
