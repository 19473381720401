import React, { Dispatch, FC, Fragment, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

import { DatePickers, Note, SocialIcon, TextCounter, ValidatedTextFieldNext } from 'components';
import { ContentFragment, Network, ScheduledShareUpdateInputItem } from 'api';

import { Action } from '../../types';

interface Props {
  limit: number;
  setAction: Dispatch<Action>;
  name: string;
  network: Network;
  content: ContentFragment;
}

const styles = {
  title: {
    marginBottom: 0,
  },
  content: {
    padding: 2,
  },
  actions: {
    marginTop: 0,
    marginLeft: 1,
    marginRight: 1,
    paddingTop: 0,
  },
  divider: {
    marginTop: 1,
    marginBottom: 2,
  },
  date: {
    cursor: 'pointer',
  },
} as const;

const Form: FC<Props> = ({ limit, name, setAction, content, network }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const formik = useFormikContext<ScheduledShareUpdateInputItem>();
  const { values, setFieldValue } = formik;
  const { share_at, user_commentary } = values;

  const currentLength = user_commentary.length;
  const isDisabled = currentLength > limit;
  const mom = moment.unix(share_at);

  function handleDateChange(newTime: number) {
    setFieldValue('share_at', newTime);
    handleClose();
  }

  function handleCancel() {
    formik.resetForm();
    setAction(Action.None);
  }

  function handleOpen(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <Fragment>
      <DialogTitle sx={styles.title}>
        <Typography variant="h6">{t('sharing:editScheduledShare')}</Typography>
        <IconButton onClick={handleCancel} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container spacing={2} direction="column">
          <Grid>
            <Typography color="primary" variant="subtitle2" onClick={handleOpen} sx={styles.date}>
              {mom.format('ddd, MMM D, YYYY')} {t('common:at')} {mom.format('h:mmA')}
            </Typography>
            <DatePickers.DateTime
              anchorToMenu
              enableTime
              anchorEl={anchorEl}
              value={share_at}
              onClose={handleClose}
              onSave={handleDateChange}
            />
          </Grid>
          <Divider sx={styles.divider} />
          <Grid>
            <Grid container alignItems="center" spacing={1}>
              <Grid>
                <SocialIcon provider={network} />
              </Grid>
              <Grid>
                <Typography variant="subtitle2">{name}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <ValidatedTextFieldNext
              fullWidth
              multiline
              rows="6"
              margin="none"
              name="user_commentary"
              placeholder={t(`sharing:${network}`)}
              disabled={content.lock_commentary}
              InputLabelProps={{ shrink: true }}
            />
            {content.lock_commentary && <Note i18nKey="sharing:shareLockdownTip" />}
            <Box marginTop={1}>
              <TextCounter length={currentLength} limit={limit} />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          disabled={isDisabled}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
        >
          {t('common:save')}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default Form;
