import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List as MuiList, ListSubheader } from '@mui/material';

import { ClientDepartment } from 'api';
import { SidebarItem } from 'components';
import { generateLink } from '../Links';

interface Props {
  context: 'groups' | 'team';
  departments: ClientDepartment[];
}

const DepartmentFilters: FC<Props> = props => {
  const { context, departments } = props;
  const { t } = useTranslation();
  const params = useParams<{ department_id: string }>();

  function isDepartmentSelected(filter: ClientDepartment) {
    const { department_id } = params;
    return filter.id === department_id;
  }

  function renderFilter(department: ClientDepartment) {
    return (
      <SidebarItem
        color="inherit"
        key={department.id}
        link={generateLink(`/${context}/department/${department.id}`)}
        isSelected={isDepartmentSelected(department)}
        textNode={department.title}
      />
    );
  }

  if (!departments.length) {
    return null;
  }

  return (
    <MuiList
      component="nav"
      subheader={<ListSubheader disableSticky>{t('common:department_plural')}</ListSubheader>}
    >
      {departments.map(renderFilter)}
    </MuiList>
  );
};

export default DepartmentFilters;
