import React, { FC, Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSlate } from 'slate-react';
import { Field, FieldProps, Form as FormikForm, Formik } from 'formik';
import {
  Box,
  Button,
  Input,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import LinkOffRoundedIcon from '@mui/icons-material/LinkOffRounded';
import theme from 'theme';

import { isBlockActive, unwrapLink, wrapLink } from '../helpers';
import { useSelectionContext } from '../Contexts';

const styles = {
  body: {
    padding: theme.spacing(),
  },
  inputRoot: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    backgroundColor: 'common.white',
    borderColor: 'grey.300',
  },
  input: {
    padding: theme.spacing(),
  },
  button: {
    padding: 0,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
} as const;

const LinkInput: FC<FieldProps> = ({ field, ...props }) => {
  return (
    <Input autoFocus sx={{ root: styles.inputRoot, input: styles.input }} {...field} {...props} />
  );
};

const LinkGroup: FC = () => {
  const editor = useSlate();
  const ref = useRef();
  const { t } = useTranslation();
  const { actions: selectionActions } = useSelectionContext();

  const [open, setOpen] = useState(false);
  const isActive = isBlockActive(editor, 'link');

  function handleSubmit(values: { url: string }) {
    selectionActions.ensureEditorFocus();

    if (values.url) {
      wrapLink(editor, values.url);
    }

    setOpen(false);
  }

  function handleClose() {
    selectionActions.ensureEditorFocus();
    setOpen(false);
  }

  function handleChange() {
    selectionActions.ensureEditorFocus();
    return isActive ? unwrapLink(editor) : setOpen(true);
  }

  return (
    <Fragment>
      <ToggleButtonGroup onChange={handleChange}>
        <ToggleButton
          title={isActive ? t('components:editorRemoveLink') : t('components:editorInsertLink')}
          value="link"
          selected={isActive}
          ref={ref}
        >
          {isActive ? <LinkOffRoundedIcon /> : <LinkRoundedIcon />}
        </ToggleButton>
      </ToggleButtonGroup>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box sx={styles.body}>
          <Formik initialValues={{ url: '' }} onSubmit={handleSubmit}>
            <FormikForm>
              <Typography display="inline">{t('components:editorEnterLink')}</Typography>
              <Field name="url" placeholder="https://site.com" component={LinkInput} />
              <Button sx={styles.button} color="primary" type="submit">
                {t('common:save')}
              </Button>
            </FormikForm>
          </Formik>
        </Box>
      </Popover>
    </Fragment>
  );
};

export default LinkGroup;
