import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import { FieldProps, useField } from 'formik';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';
import theme from 'theme';

interface Props {
  name: string;
  i18nKey: string;
  persistLabel?: boolean;
  hidden?: boolean;
  type?: string;
  inputProps?: Record<string, unknown>;
  InputProps?: Record<string, unknown>;
  valueFn?: (value: unknown) => string;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    onChange: FieldProps['field']['onChange']
  ) => void;
  TextFieldProps?: Record<string, unknown>;
  color?: 'primary' | 'secondary';
  autoComplete?: string;
  removeLabel?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  largeText?: boolean;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  variant?: BaseTextFieldProps['variant'];
}

const styles = {
  hidden: theme.mixins.visuallyHidden,
  primary: {},
  secondary: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
  },
  largeText: {
    fontSize: 4,
    fontWeight: 'bold',
  },
} as const;

const ValidatedTextField: FC<Props> = props => {
  const {
    name,
    i18nKey,
    hidden,
    onChange,
    type,
    valueFn,
    persistLabel,
    inputProps,
    InputProps,
    TextFieldProps,
    color,
    autoComplete,
    removeLabel,
    autoFocus,
    placeholder,
    largeText,
    onKeyPress,
    variant,
  } = props;

  const { t } = useTranslation();
  const [field, meta] = useField(name);

  const formattedNameError = Array.isArray(meta.error) ? meta.error.join(', ') : meta.error;
  const value = valueFn(field.value);
  const hasError = !!meta.error;

  return (
    <TextField
      variant={variant}
      fullWidth
      sx={{ ...(hidden && styles.hidden), '& .MuiInputBase-root': { padding: 0 } }}
      autoComplete={autoComplete || field.name}
      autoFocus={autoFocus}
      margin="normal"
      type={type}
      error={hasError}
      value={value}
      label={!removeLabel ? t(i18nKey) : null}
      placeholder={placeholder || t(i18nKey)}
      inputProps={{ name: field.name, ...inputProps }}
      InputLabelProps={persistLabel ? { shrink: true } : { shrink: hasError }}
      InputProps={{
        ...InputProps,
        sx: {
          ...(color === 'secondary' && styles.secondary),
          ...(color !== 'secondary' && styles.primary),
          ...(largeText && styles.largeText),
        },
      }}
      onChange={event => {
        onChange(event, field.onChange);
      }}
      onKeyPress={onKeyPress}
      helperText={formattedNameError}
      {...TextFieldProps}
    />
  );
};

ValidatedTextField.defaultProps = {
  hidden: false,
  type: 'text',
  InputProps: {},
  TextFieldProps: {},
  persistLabel: false,
  removeLabel: false,
  onChange: (event: ChangeEvent, onChange: FieldProps['field']['onChange']) => onChange(event),
  valueFn: (value: string) => value || '',
};

export default ValidatedTextField;
