import { useState, useEffect } from 'react';
import store from 'store';

export default (key: string, defaultValue: any) => {
  function getDefault() {
    return localStorage.getItem(key) !== null ? store.get(key) : defaultValue;
  }

  const [value, setValue] = useState(getDefault());

  useEffect(() => {
    store.set(key, value);
  }, [value]);

  return [value, setValue];
};
