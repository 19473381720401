import React, { FC, useState } from 'react';
import {
  FormControl,
  Unstable_Grid2 as Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';

import { uniqueId } from 'lodash';

import DateTimePicker from './DateTime';
import { DatePickerOptions } from './types';

interface Props extends DatePickerOptions {
  value: number;
  setValue: (val: number) => void;
  allowNull?: boolean;
  label?: string;
  fullWidth?: boolean;
  defaultValue?: string;
  clearable?: boolean;
}

const Select: FC<Props> = ({
  value,
  setValue,
  allowNull,
  label,
  fullWidth,
  defaultValue,
  ...flatPickrOptions
}) => {
  const { enableTime, enableTimezone, disabled } = flatPickrOptions;
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateId] = useState(uniqueId('dateSelect'));

  const clientTz = moment.tz.guess();

  function onSave(time: number) {
    if (time || allowNull) {
      setValue(time);
    }
    setAnchorEl(null);
  }

  function handleClear() {
    setValue(null);
  }

  function renderMenuItem() {
    if (value) {
      const time = enableTimezone
        ? moment.unix(value).tz(clientTz).format('LLL z')
        : moment.unix(value).format(enableTime ? 'LLL' : 'lll');

      return <MenuItem value="item">{time}</MenuItem>;
    }

    return <MenuItem value="item">{defaultValue}</MenuItem>;
  }

  return (
    <FormControl variant="standard" fullWidth={fullWidth} sx={{ root: { padding: 16 } }}>
      <Grid container>
        <Grid xs>
          {!!label && <InputLabel htmlFor={dateId}>{label}</InputLabel>}
          <MuiSelect
            variant="standard"
            value="item"
            fullWidth
            readOnly
            disabled={disabled}
            onClick={event => setAnchorEl(event.currentTarget)}
            inputProps={{
              id: dateId,
            }}
          >
            {renderMenuItem()}
          </MuiSelect>
        </Grid>
        {allowNull && value && (
          <Grid>
            <IconButton onClick={handleClear} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <DateTimePicker
        anchorToMenu
        value={value}
        anchorEl={anchorEl}
        onSave={onSave}
        onClose={() => onSave(null)}
        {...flatPickrOptions}
      />
    </FormControl>
  );
};

Select.defaultProps = {
  allowNull: false,
  label: '',
  fullWidth: false,
  defaultValue: '',
  clearable: false,
  enableTime: false,
  enableTimezone: false,
  disabled: false,
};

export default Select;
