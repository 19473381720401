import { InfiniteData, UseMutationOptions, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  CreateGroupModeratorMutationVariables,
  DeleteGroupModeratorMutationVariables,
  GetGroupFollowersQuery,
  GetGroupFollowersQueryVariables,
  GroupRole,
  useGetGroupFollowersQuery,
} from 'api';
import { useNotify, useQueryVariables } from 'hooks';

type MutationVariables =
  | CreateGroupModeratorMutationVariables
  | DeleteGroupModeratorMutationVariables;
type QueryData = InfiniteData<GetGroupFollowersQuery>;
type QueryVariables = GetGroupFollowersQueryVariables;

const useUpdateGroupRole = <TMutation>(role: GroupRole) => {
  const queryClient = useQueryClient();
  const notify = useNotify();

  const variables = useQueryVariables<QueryVariables>();
  const queryKey = useGetGroupFollowersQuery.getKey(variables);

  const mutationOptions: UseMutationOptions<TMutation, unknown, MutationVariables> = {
    onMutate: async mutationArgs => {
      await queryClient.cancelQueries(queryKey);

      const baseState = queryClient.getQueryData<QueryData>(queryKey);
      const nextState =
        baseState &&
        produce(baseState, draftState => {
          draftState.pages.map(page => {
            const { feed_follows } = page.feedFollowing;

            feed_follows.filter(feedFollow => {
              if (feedFollow.user_id === mutationArgs.user_id) {
                feedFollow.group_role = role;
              }
            });
          });
        });

      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, nextState);
      }

      return baseState;
    },
    onError: (error, mutationArgs, baseState: QueryData) => {
      if (baseState) {
        queryClient.setQueryData<QueryData>(queryKey, baseState);
      }
      notify.mutationError();
    },
  };

  return mutationOptions;
};

export default useUpdateGroupRole;
