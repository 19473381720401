import React, { FC } from 'react';
import { Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import theme, { pxToRem } from 'theme';

const styles = {
  change: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(3),
    fontSize: pxToRem(15),
  },
  changeIcon: {
    marginRight: theme.spacing(0.5),
  },
} as const;

interface IProps {
  countChange: number;
}

const Change: FC<IProps> = ({ countChange }) => {
  if (countChange === 0) {
    return (
      <Typography color="textSecondary" sx={styles.change}>
        {'--'}%
      </Typography>
    );
  }

  if (countChange > 0) {
    return (
      <Typography color="textSecondary" sx={styles.change}>
        <ArrowUpwardIcon fontSize="inherit" color="primary" sx={styles.changeIcon} />
        {countChange}%
      </Typography>
    );
  }

  return (
    <Typography color="textSecondary" sx={styles.change}>
      <ArrowDownwardIcon fontSize="inherit" color="error" sx={styles.changeIcon} />
      {countChange}%
    </Typography>
  );
};

export default Change;
