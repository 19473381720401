/**
 * Lifted from Amazon's work on complexObjectLink for AppSync and Apollo
 * https://github.com/awslabs/aws-mobile-appsync-sdk-js/blob/fc173bfe3ce262b1dba422021fc57097c4926b7b/packages/aws-appsync/src/link/complex-object-link.ts#L80-L127
 */

import { FileField } from '../types';

// Corresponds to S3ObjectInput and S3VideoObjectInput
const complexObjectFields = [
  { name: 'bucket', type: 'string' },
  { name: 'key', type: 'string' },
  { name: 'region', type: 'string' },
  { name: 'mimeType', type: 'string' },
  { name: 'localUri', type: ['object', 'string'] },
];

const findInObject = <TVariables>(obj: TVariables): FileField => {
  const testFn = (val: FileField) => {
    return complexObjectFields.every(field => {
      const hasValue = val[field.name];
      const types: string[] = Array.isArray(field.type) ? field.type : [field.type];
      return (
        hasValue &&
        types.reduce((prev, curr) => {
          return prev || typeof val[field.name] === curr;
        }, false)
      );
    });
  };

  const recursiveFindInObject = (
    nextObject: FileField,
    path = '',
    acc = {} as FileField
  ): FileField => {
    if (!nextObject) {
      return acc;
    }

    if (testFn(nextObject)) {
      acc[path] = { ...nextObject };
    }

    if (typeof nextObject === 'object') {
      Object.keys(nextObject).forEach(key => {
        const val: FileField = nextObject[key];

        if (Array.isArray(val)) {
          val.forEach((v, i) => recursiveFindInObject(v, `${path}.${key}[${i}]`, acc));
        } else if (typeof val === 'object') {
          recursiveFindInObject(val, `${path}${path && '.'}${key}`, acc);
        }
      });
    }

    return recursiveFindInObject(null, path, acc);
  };

  return recursiveFindInObject(obj as unknown as FileField);
};

export default findInObject;
