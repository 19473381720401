import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  GlobalStyles,
  Unstable_Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { PaddedPaper } from 'components';
import { popup } from 'utilities';
import theme from 'theme';

const styles = {
  pre: {
    maxWidth: 400,
    overflow: 'auto',
    padding: 2,
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
    height: 150,
  },
} as const;

const globalStyles = {
  body: {
    backgroundColor: theme.palette.common.white,
  },
};

const SharingSandbox: FC = () => {
  const [options, setOptions] = useState({
    partnerName: '',
    contentId: '',
    subdomain: '',
    display: 'page',
    connection: 'page',
    redirectUrl: '',
  });

  const { partnerName, contentId, subdomain, display, connection, redirectUrl } = options;
  const isDisabled = !partnerName || !contentId || !subdomain;

  function handleChange(field: string, event: ChangeEvent<HTMLInputElement>) {
    setOptions({ ...options, [field]: event.target.value });
  }

  function handlePreview() {
    const url = `https://${subdomain}.${window.location.hostname}/partner-share/${contentId}?&partner_name=${partnerName}&display=${display}&connection=${connection}&redirect_url=${redirectUrl}`;

    switch (display) {
      case 'page':
        window.open(url, '_blank');
        break;
      case 'iframe':
        break;
      case 'popup':
        popup(url, 'Share', 650, 650);
        break;
    }
  }

  return (
    <Fragment>
      <GlobalStyles styles={globalStyles} />
      <Grid container>
        <Grid>
          <PaddedPaper padding={16}>
            <Box width={400}>
              <Grid container direction="column" spacing={2}>
                <Grid>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    label="Partner Name"
                    value={partnerName}
                    onChange={handleChange.bind(null, 'partnerName')}
                  />
                </Grid>
                <Grid>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    label="Content ID"
                    value={contentId}
                    onChange={handleChange.bind(null, 'contentId')}
                  />
                </Grid>
                <Grid>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    label="Client Subdomain"
                    value={subdomain}
                    onChange={handleChange.bind(null, 'subdomain')}
                  />
                </Grid>
                <Grid>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Display Option</InputLabel>
                    <Select
                      variant="standard"
                      value={display}
                      onChange={handleChange.bind(null, 'display')}
                    >
                      <MenuItem value="page">Page</MenuItem>
                      <MenuItem value="iframe">iFrame</MenuItem>
                      <MenuItem value="popup">Popup</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Connection Option</InputLabel>
                    <Select
                      variant="standard"
                      value={connection}
                      onChange={handleChange.bind(null, 'connection')}
                    >
                      <MenuItem value="page">Page</MenuItem>
                      <MenuItem value="popup">Popup</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid>
                  <TextField
                    variant="standard"
                    fullWidth
                    disabled={display === 'page'}
                    InputLabelProps={{ shrink: true }}
                    label="Redirect URL"
                    value={redirectUrl}
                    onChange={handleChange.bind(null, 'redirectUrl')}
                  />
                </Grid>
                <Grid>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel shrink>Generated Share Link</InputLabel>
                    <pre style={styles.pre}>
                      https://{subdomain || 'SUBDOMAIN'}.{window.location.hostname}/partner-share/
                      {contentId || 'CONTENT_ID'}?<br />
                      &nbsp;&nbsp;&partner_name={partnerName || 'PARTNER_NAME'}
                      <br />
                      &nbsp;&nbsp;&display={display}
                      <br />
                      &nbsp;&nbsp;&connection={connection}
                      {display === 'page' && (
                        <Fragment>
                          <br />
                          &nbsp;&nbsp;&redirect_uri={redirectUrl}
                        </Fragment>
                      )}
                    </pre>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid>
                  <Button
                    disabled={isDisabled}
                    onClick={handlePreview}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Preview
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </PaddedPaper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SharingSandbox;
