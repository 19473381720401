import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import {
  AuthenticationType,
  ClientProtectedFragment,
  useCreateLoginTokenForCurrentUserInClientMutation,
} from 'api';
import { useNotify } from 'hooks';
import { Loaders, PaddedPaper } from 'components';
import { getSubdomainUrl } from 'helpers';

interface Props {
  client: ClientProtectedFragment;
  autoLaunch: boolean;
}

const ExistingWorkspaceLauncher: FC<Props> = ({ client, autoLaunch }) => {
  const { t } = useTranslation();
  const notify = useNotify();

  const subdomainUrl = getSubdomainUrl(client.subdomain);
  const isSSOClient = client.authentication_type === AuthenticationType.Sso;
  const buttonProps = isSSOClient ? { onClick: handleSSOLaunch } : { onClick: handleLaunch };

  useEffect(() => {
    if (autoLaunch) {
      isSSOClient ? handleSSOLaunch() : handleLaunch();
    }
  }, []);

  const { mutate, isLoading } = useCreateLoginTokenForCurrentUserInClientMutation({
    onError: notify.mutationError,
    onSuccess: ({ createLoginTokenForCurrentUserInClient }) => {
      window.location.href = `${subdomainUrl}?token=${createLoginTokenForCurrentUserInClient}`;
    },
  });

  function handleLaunch() {
    mutate({ client_id: client.id });
  }

  function handleSSOLaunch() {
    window.location.href = subdomainUrl;
  }

  if (autoLaunch) {
    return <Loaders.Startup />;
  }

  return (
    <Grid>
      <PaddedPaper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container xs spacing={2} alignItems="center">
            <Grid>
              <Typography variant="subtitle2">{client.title}</Typography>
              <Typography color="textSecondary">{client.subdomain}</Typography>
            </Grid>
          </Grid>
          <Grid>
            <Button
              sx={{ width: 100 }}
              disabled={isLoading}
              size="large"
              variant="contained"
              color="primary"
              {...buttonProps}
            >
              {isLoading ? <Loaders.Standard height={26} width={60} /> : t('auth:workspaceButton')}
            </Button>
          </Grid>
        </Grid>
      </PaddedPaper>
    </Grid>
  );
};

export default ExistingWorkspaceLauncher;
