import React, { ChangeEvent, forwardRef, KeyboardEvent, RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, InputProps, TextField } from '@mui/material';
import { noop } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import theme from 'theme';

interface IProps {
  query: string;
  focused: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearch?: (value: string) => void;
  onClear?: () => void;
  onFocus?: () => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  inputClasses?: InputProps['classes'];
}

const styles = {
  icon: {
    fontSize: 28,
    color: theme.palette.grey[500],
  },
  inactive: {
    color: 'grey.100',
  },
  iconFocused: {
    color: theme.palette.grey[600],
  },
  deleteIcon: {
    transform: 'rotate(45deg)',
    color: 'grey.300',
    padding: 0,
  },
  transparent: {
    color: 'transparent',
  },
  root: {
    ['& input']: {
      paddingY: 1,
      paddingLeft: 1,
      paddingRight: 0,
    },
  },
  input: {
    paddingLeft: 2,
    paddingRight: 0,
    borderRadius: 8,
    '&:hover': {
      borderColor: 'grey.300',
    },
  },
} as const;

const Search = forwardRef<RefObject<HTMLInputElement>, IProps>((props, forwardedRef) => {
  const { query, focused, onChange, onClear, onFocus, onKeyDown, onSearch } = props;
  const { t } = useTranslation();
  const inputEl = useRef(null);

  function handleInputKeyDown(event: KeyboardEvent) {
    const { key } = event;

    if (key === 'Enter' && !!query) {
      return handleSearch();
    }

    onKeyDown(event);
  }

  function handleClear() {
    inputEl?.current?.focus();
    onClear();
  }

  function handleSearch() {
    inputEl?.current?.blur();
    onSearch(query);
  }

  function renderStartAdornment() {
    return (
      <SearchIcon sx={{ ...styles.icon, ...(focused ? styles.iconFocused : styles.inactive) }} />
    );
  }

  function renderEndAdornment() {
    return (
      <IconButton disabled={!query} onClick={handleClear} size="large">
        <AddCircleIcon sx={query ? styles.deleteIcon : styles.transparent} />
      </IconButton>
    );
  }

  return (
    <TextField
      size="small"
      fullWidth
      value={query}
      variant="outlined"
      placeholder={t('common:search')}
      inputRef={forwardedRef || inputEl}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={handleInputKeyDown}
      InputProps={{
        startAdornment: renderStartAdornment(),
        endAdornment: renderEndAdornment(),
        sx: styles.input,
      }}
      sx={styles.root}
    />
  );
});

Search.defaultProps = {
  onClear: noop,
  onFocus: noop,
  onKeyDown: noop,
  onSearch: noop,
  inputClasses: {},
};

export default Search;
