import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Slide, Toolbar, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { alpha } from '@mui/material/styles';
import moment from 'moment';

import { useProtectedClient } from 'hooks';
import * as Links from 'Links';
import theme from 'theme';

const styles = {
  background: {
    width: '100%',
    backgroundColor: alpha(theme.palette.error.main, 0.1),
  },
} as const;

const TrialBanner: FC = () => {
  const { t } = useTranslation();

  const client = useProtectedClient();

  const { billing_teams_trial_start, billing_teams_trial_end } = client;
  const startMoment = moment.unix(billing_teams_trial_start);
  const endMoment = moment.unix(billing_teams_trial_end);
  const daysLeft = endMoment.diff(startMoment, 'days');

  return (
    <Slide in mountOnEnter unmountOnExit>
      <Toolbar sx={styles.background} variant="dense">
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Typography noWrap color="error" variant="subtitle2">
            <Trans i18nKey="components:trialBannerInProgress" values={{ days: daysLeft }}>
              You have {{ daysLeft }} {t('common:day', { count: daysLeft })} left on your free trial
              of the Teams plan. &nbsp;
              <Link underline="always" color="error" component={Links.AdminUpgrade}>
                Upgrade now
              </Link>
              .
            </Trans>
          </Typography>
        </Grid>
      </Toolbar>
    </Slide>
  );
};

export default TrialBanner;
