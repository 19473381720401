import React, { FC, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Box, Toolbar, Unstable_Grid2 as Grid } from '@mui/material';
import { times } from 'lodash';

import { LoaderComponents, Loaders } from 'components';
import { PartnerShareLoader } from 'features/sharing';
import { PartnerActivityLoader } from 'features/activity';
import cookieStorage from 'cookie-storage';
import theme from 'theme';

const styles = {
  root: {
    maxWidth: theme.custom.layoutSingleColumnMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 6,
  },
} as const;
/**
 * Special loader for startup (cognito lookup) and initial queries
 * (bootstrap) before fixed header is rendered. The calc offset
 * prevents page jumping.
 */
const Startup: FC = () => {
  const { Content, Image } = LoaderComponents;
  const location = useLocation();

  const isConnecting = checkPath('/connect');
  const isOAuth = checkPath('/v1/oauth');
  const isPartnerShare = checkPath('/partner-share');
  const isPartnerActivity = checkPath('/partner-activity');

  if (isPartnerShare) {
    return <PartnerShareLoader />;
  }

  if (isPartnerActivity) {
    return <PartnerActivityLoader />;
  }

  if (isConnecting || isOAuth) {
    return (
      <Box height="100vh">
        <Loaders.Standard />
      </Box>
    );
  }

  function checkPath(path: string) {
    return location.pathname.includes(path) || cookieStorage.nextPath?.includes(path);
  }

  return (
    <Fragment>
      <AppBar>
        <Toolbar>
          <Grid container justifyContent="space-between" wrap="nowrap" spacing={2}>
            <Grid>
              <Content height={20} maxWidth={300} />
            </Grid>
            <Grid xs container spacing={2} justifyContent="flex-end">
              <Grid>
                <Image shape="circular" />
              </Grid>
              <Grid>
                <Image shape="circular" />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container sx={styles.root} spacing={2}>
        <Grid container xs={12} md={3}>
          {times(12, rowIndex => (
            <Grid key={rowIndex}>
              <Content index={rowIndex} height={15} maxWidth={200} />
            </Grid>
          ))}
        </Grid>
        <Grid xs={12} md={5}>
          <Loaders.Feed />
        </Grid>
        <Grid xs={12} md={4}>
          <Loaders.Widget />
        </Grid>
      </Grid>
    </Fragment>
  );
};

Startup.defaultProps = {
  appBar: true,
};

export default Startup;
