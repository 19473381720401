import React, { FC, Fragment, useRef } from 'react';
import { FieldArrayRenderProps } from 'formik';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd';
import type { Identifier } from 'dnd-core';

import { S3ObjectFragment, S3ObjectFragmentDoc } from 'api';
import { IMGIX_HOST } from 'settings';
import { queryString } from 'utilities';
import { THUMBNAIL_SIZE } from 'app-constants';

import Image from './Image';
import { ImageType } from 'features/content/widgets/Images/types';
import { usePostContext } from '../../PostContext';

interface Props extends FieldArrayRenderProps {
  photo: S3ObjectFragment;
  index: number;
}

interface DragItem {
  id: number;
  index: number;
  type: ImageType;
}

const Thumbnail: FC<Props> = ({ photo, index, ...helpers }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { state, dispatch } = usePostContext();

  const { selectedPhotoIndexes } = state;

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: S3ObjectFragmentDoc,
    collect: monitor => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item: DragItem, monitor) => {
      if (!ref.current) {
        return;
      }

      const dragIdx = item.index;
      const hoverIdx = index;

      if (
        dragIdx === hoverIdx ||
        hoverIdx >= selectedPhotoIndexes.length ||
        dragIdx >= selectedPhotoIndexes.length
      ) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();

      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (dragIdx > hoverIdx) {
        if (hoverClientX < hoverMiddleX || hoverClientX < hoverMiddleX) {
          return;
        }
      }

      helpers.swap(dragIdx, hoverIdx);
      dispatch({ type: 'swapPhotoIndexes', value: [dragIdx, hoverIdx] });
      item.index = hoverIdx;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: S3ObjectFragmentDoc,
    item: () => ({
      id: photo.key,
      index,
    }),
    options: {
      dropEffect: 'move',
    },
    previewOptions: {},
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (!photo) {
    return null;
  }

  drag(drop(ref));

  const params = {
    h: THUMBNAIL_SIZE,
    w: THUMBNAIL_SIZE,
    fit: 'crop',
    auto: 'format',
    'border-radius': 4,
  };

  const imageUrl = `${IMGIX_HOST}/${photo.key}?${queryString(params)}`;

  return (
    <Fragment>
      <DragPreviewImage connect={preview} src={imageUrl} />
      <div ref={ref} data-handler-id={handlerId}>
        <Image index={index} photo={photo} isDragging={isDragging} {...helpers} />
      </div>
    </Fragment>
  );
};

export default Thumbnail;
