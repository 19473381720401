import React, { FC, Fragment, useState } from 'react';
import { Dialog, Menu, MenuItem } from '@mui/material';

import { UserProfileFragment } from 'api';
import { useCognitoUser, useCurrentUser } from 'hooks';
import * as csPaths from 'clientSuccess/paths';

import ResetPasswordDialog from './ResetPasswordDialog';
import { Link } from 'react-router-dom';
import UserInfo from './UserInfo';

interface IProps {
  user: UserProfileFragment;
  anchorEl: Element;
  onClose(): void;
  onSetImpersonation: (user: UserProfileFragment) => void;
}

const UserMenu: FC<IProps> = ({ user, anchorEl, onClose, onSetImpersonation }) => {
  const auth = useCognitoUser();
  const currentUser = useCurrentUser();
  const isImpersonatingUser = auth.id !== currentUser.id && user?.id === currentUser.id;
  const [resettingPassword, setResettingPassword] = useState(false);
  const [userInfoOpen, setUserInfoOpen] = useState(false);

  return (
    <Fragment>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <MenuItem
          onClick={() => onSetImpersonation(user)}
          disabled={user?.user_status !== 'active'}
          color={isImpersonatingUser ? 'primary' : 'default'}
        >
          Impersonate
        </MenuItem>
        <MenuItem
          onClick={() => {
            setResettingPassword(true);
            onClose();
          }}
        >
          Reset Password
        </MenuItem>
        <Link
          to={csPaths.updateClient.replace(':client_id', user.client_id)}
          style={{ textDecoration: 'none' }}
        >
          <MenuItem>Edit Client</MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            setUserInfoOpen(true);
            onClose();
          }}
        >
          User Info
        </MenuItem>
      </Menu>
      <ResetPasswordDialog
        open={resettingPassword}
        onClose={() => setResettingPassword(false)}
        user={user}
      />
      <Dialog open={userInfoOpen} onClose={() => setUserInfoOpen(false)}>
        <UserInfo user={user} />
      </Dialog>
    </Fragment>
  );
};

export default UserMenu;
