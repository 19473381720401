import { FC, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { useBootstrapQuery } from 'api';
import { CONNECTION_SUCCESS_MESSAGE } from 'app-constants';
import { useCurrentUser } from 'hooks';

const ProtectedConnections: FC = () => {
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  useEffect(() => {
    window.addEventListener('message', event => void updateConnections(event), false);
    return () => {
      window.removeEventListener('message', event => void updateConnections(event), false);
    };
  }, []);

  async function updateConnections(event: MessageEvent) {
    const origin = window.location.origin;

    if (event.origin !== origin || !event.data) {
      return;
    }

    if (event.data?.message !== CONNECTION_SUCCESS_MESSAGE) {
      return;
    }

    await queryClient.refetchQueries(useBootstrapQuery.getKey({ user_id: currentUser.id }));
  }

  return null;
};

export default ProtectedConnections;
