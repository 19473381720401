import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import {
  AuthenticationType,
  ClientStatus,
  ContractType,
  CreateClientInput,
  Feature,
  useCreateClientMutation,
} from 'api';
import { Layouts } from 'components';
import { useNotify } from 'hooks';
import * as csPaths from 'clientSuccess/paths';

import { ClientForm } from '../components';

const CreateClient: FC = () => {
  const notify = useNotify();

  const {
    mutate: createClient,
    data,
    isLoading,
  } = useCreateClientMutation({
    onError: handleError,
    onSuccess: handleCompleted,
  });

  const initialValues: CreateClientInput = {
    title: '',
    subdomain: '',
    email_sending_domain: '',
    email_from_address: '',
    email_domains: [],
    content_domains: [],
    email_address: '',
    client_status: ClientStatus.Disabled,
    authentication_type: AuthenticationType.Auth,
    // TODO: Add Authentication Support
    // sso_configuration: { provider: '' },
    sso_provider: '',
    allow_user_invites: false,
    allow_user_signup: false,
    allow_group_creation: false,
    allow_group_discovery: false,
    departments_in_signup: false,
    departments_in_signup_required: false,
    locations_in_signup: false,
    locations_in_signup_required: false,
    support_multiple_languages: false,
    allow_user_share_commentary: false,
    allow_external_share: true,
    sort_mode: null,
    disable_comments: true,
    allow_sharing: true,
    compliance_notification_emails: [],
    compliance_notification_enabled: false,
    moderator_can_mark_as_important: false,
    first_admin_user: true,
    features: [
      Feature.ClientInvitationsV2,
      Feature.CommentNotifications,
      Feature.WebNotifications,
      Feature.FeatureFlagsV2,
    ],
    image: {
      bucket: '',
      key: '',
      region: '',
      localUri: '',
      mimeType: '',
    },
    contract_committed_user_count: 0,
    contract_type: ContractType.Traditional,
    menu_item_enabled: false,
    menu_item: { title: '', link: '' },
    yearly_enrichment_quota: 0,
    client_aliases: [],
  };

  function handleCompleted() {
    notify.info({ message: 'Client Created' });
  }

  function handleError() {
    notify.mutationError();
  }

  function handleSubmit(createClientInput: CreateClientInput) {
    createClient({ data: createClientInput });
  }

  function renderMain() {
    return (
      <ClientForm
        action="create"
        initialValues={initialValues}
        working={isLoading}
        onSubmit={values => handleSubmit(values as CreateClientInput)}
      />
    );
  }

  if (data) {
    return <Navigate to={csPaths.listClients} replace />;
  }

  return <Layouts.App1Column desktopSize={12} main={renderMain()} />;
};

export default CreateClient;
